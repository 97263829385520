export const TYPE_TACHE = {
	RECOLTE: 'RECOLTE',
	TRI : 'TRI'
}

export const TYPE_MO_TACHE = {
	GENERIQUE: 'generique',
	VARIANTE : 'variante',
	INDEPENDANTE: 'independante'
}

export const OUI_NON = {
	OUI: { value: 'OUI', label: 'Oui' },
	NON: { value: 'NON', label: 'Non' }
}

export const TRUE_FALSE = {
	TRUE: { value: 'true', label: 'Oui' },
	FALSE: { value: 'false', label: 'Non' }
}

export const ACTION_TYPE = {
	TERMINEE: 'TERMINEE',
	DEMARRAGE_IMPOSSIBLE: 'DEMARRAGE_IMPOSSIBLE',
	NONE: 'NONE'
}