import React from 'react'
import {ONGLETS_BESOINS_PRODUCTION} from '../../../utils/constants'

const besoinProductionContextType = {
	onglet: ONGLETS_BESOINS_PRODUCTION.RECUEIL,
	setOnglet: (onglet) => {}
}

export const BesoinsProductionContext = React.createContext(besoinProductionContextType)

export const useBesoinsProductionContext = () => React.useContext(BesoinsProductionContext)

const BesoinsProductionProvider = ({ children }) => {

	const [onglet, setOnglet] = React.useState(ONGLETS_BESOINS_PRODUCTION.RECUEIL)

	const handleOngletChange = React.useCallback(newOnglet => {
		setOnglet(newOnglet)
	}, [])

	return (
		<BesoinsProductionContext.Provider value={{
			onglet,
			setOnglet: handleOngletChange
		}}>
			{children}
		</BesoinsProductionContext.Provider>
	)
}


export default BesoinsProductionProvider
