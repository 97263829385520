import { RECIPENT_VALUE_FIELD, REMINDER_FORM_FIELD } from '../tool/tourPlaine.constants'

/**
 * addReminderMapToApi
 * Map Ap To Api
 */
export const addReminderMapToApi = (values, provenance) => ({
	isForChefDeCulture: values[REMINDER_FORM_FIELD.RECIPENT] ? values[REMINDER_FORM_FIELD.RECIPENT].includes(RECIPENT_VALUE_FIELD.CHIEF) : false,
	isForManager: values[REMINDER_FORM_FIELD.RECIPENT] ? values[REMINDER_FORM_FIELD.RECIPENT].includes(RECIPENT_VALUE_FIELD.MANAGER) : false,
	texte: values.reminder,
	provenance
})