import React from 'react'
import PropTypes from 'prop-types'

const FertilisationIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 58 58" width={width} height={height} {...props}>
			<g fill="none">
				<circle cx={29} cy={29} fill={color} r={29} />
				<path
					d="M29 0v58C12.984 58 0 45.016 0 29S12.984 0 29 0z"
					fill={color}
				/>
				<g fill="#fff">
					<path d="M28.33 32a38.26 38.26 0 00-15.15-17.61 2.08 2.08 0 00-2.64.31 2 2 0 00.43 3c10.2 6.44 16 16.58 16 27.83v3.37a2.07 2.07 0 001.72 2.1A2 2 0 0031 49v-3.45c0-6.62 2.54-13.17 7.56-19.48a2 2 0 00-.59-3 2.07 2.07 0 00-2.6.58A42.71 42.71 0 0030.15 32a1 1 0 01-1.82 0" />
					<path d="M33.61 26.06c-2.16-2.35-2.13-6.3.16-9.11C36.18 14 44.91 13 44.91 13s2.84 6.69-.6 10.91c0 0-2.56 3.44-8.93 3.06z" />
				</g>
			</g>
		</svg>
	)
}

FertilisationIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

FertilisationIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#24ae5f'
}

export default FertilisationIcon
