import { fetchFactory } from 'isotope-client'

export const creerUtilisateur = (values) => fetchFactory('/bo/utilisateurs', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const updateUtilisateur = (values) => fetchFactory(`/bo/utilisateurs`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const deleteUtilisateur = (idUser) => fetchFactory(`/bo/utilisateurs/${idUser}`, {
	method: 'DELETE'
})