import { Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { colors, DEFAULT_LABEL } from '../../../../../utils/constants'
import {
	getFilterOptionsFamille,
	getFilterOptionsEspece,
	getFilterOptionsCulture,
	getFilterOptionsZone,
	getFilterOptionsBloc,
	getFilterOptionsSurface
} from '../../services/planningApi'
import { getFilterValues } from '../../services/toolbar/toolbarSelector'
import { FILTER_PLANNING_FORM } from '../../utils/constant'
import { injectEspeceCultureValueList, injectFamilleCultureValueList, injectZoneSurfaceValueList } from '../../../../common/valueLists/valueListInjectors'
import AutocompleteMulti from '../../../../../components/form/AutocompleteMulti'
import { sortObjectByLabel } from '../../../../../utils/utils'

const styles = () => getStyles({
	form: {
		width: 'calc(100% - 3rem)',
		padding: '1.5rem'
	},
	formFields: {
		width: '100%'
	},
	formInput: {
		'& > div': {
			padding: 0,
			marginBottom: '2.25rem'
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	link: {
		'&.MuiTypography-root': {
			color: colors.link,
			textDecoration: 'underline',
			cursor: 'pointer',
			textAlign: 'center',
			margin: '0.5rem 0 2.25rem'
		}
	},
	iconSearch: {
		transform: 'translate(0, -50%)',
		color: '#55555A'
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	}
})

export const FILTER_PLANNING_FIELDS = {
	CULTURE: { name: 'cultures', propName: 'nom' },
	SURFACE: { name: 'surfaces', propName: 'surface' },
	ESPECE: { name: 'especes', propName: 'espece' },
	FAMILLE: { name: 'familles', propName: 'famille' },
	BLOC: { name: 'blocs', propName: 'bloc' },
	ZONE: { name: 'zones', propName: 'zone' }
}

const FilterPlanning = ({
	idFerme,
	zoneSurfaceList,
	familleCultureList,
	especeCultureList,
	handleSubmit,
	change,
	classes,
	familleValues,
	especeValues,
	zoneValues,
	blocValues
}) => {

	const onResetClick = (event) => {
		change(FILTER_PLANNING_FIELDS.CULTURE.name, [])
		change(FILTER_PLANNING_FIELDS.SURFACE.name, [])
		change(FILTER_PLANNING_FIELDS.ESPECE.name, [])
		change(FILTER_PLANNING_FIELDS.FAMILLE.name, [])
		change(FILTER_PLANNING_FIELDS.BLOC.name, [])
		change(FILTER_PLANNING_FIELDS.ZONE.name, [])
		event.preventDefault()
	}

	const valueListToOptionsList = (listToRead, valueList) => (
		listToRead && listToRead.map(element => {
			const label = (valueList.find(v => v.code === element) || {}).label || DEFAULT_LABEL

			return { label, code: element }
		})
	)

	const arrayToOptionList = (array) => (
		array && array.map(element => {
			return { label: element.toString(), code: element }
		})
	)

	const getOptionsFamille = () => {
		return getFilterOptionsFamille(idFerme).then(options => sortObjectByLabel(valueListToOptionsList(options.familles, familleCultureList)))
	}

	const getOptionsEspece = () => {
		return getFilterOptionsEspece(idFerme, (familleValues || []).map(famille => famille.code)).then(options => sortObjectByLabel(valueListToOptionsList(options.especes, especeCultureList)))
	}

	const getOptionsCulture = () => {
		return getFilterOptionsCulture(idFerme, (especeValues || []).map(espece => espece.code)).then(options => sortObjectByLabel(options.cultures))
	}

	const getOptionsZone = () => {
		return getFilterOptionsZone(idFerme).then(options => sortObjectByLabel(valueListToOptionsList(options.zones, zoneSurfaceList)))
	}

	const getOptionsBloc = () => {
		return getFilterOptionsBloc(idFerme, (zoneValues || []).map(zone => zone.code)).then(options => sortObjectByLabel(arrayToOptionList(options.blocs)))
	}

	const getOptionsSurface = () => {
		return getFilterOptionsSurface(idFerme, (blocValues || []).map(bloc => bloc.code)).then(options => sortObjectByLabel(arrayToOptionList(options.surfaces)))
	}

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Typography variant="body1" className={classes.link} onClick={onResetClick}>
				<FormattedMessage id="planning.filter.form.reset"/>
			</Typography>
			<Grid className={classes.formFields}>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.famille"/>}
						name={FILTER_PLANNING_FIELDS.FAMILLE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsFamille}
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.espece" />}
						name={FILTER_PLANNING_FIELDS.ESPECE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsEspece}
						forceGetOptions
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.culture" />}
						name={FILTER_PLANNING_FIELDS.CULTURE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsCulture}
						forceGetOptions
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.zone"/>}
						name={FILTER_PLANNING_FIELDS.ZONE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsZone}
						forceGetOptions
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.bloc"/>}
						name={FILTER_PLANNING_FIELDS.BLOC.name}
						component={AutocompleteMulti}
						getOptions={getOptionsBloc}
						forceGetOptions
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="planning.filter.form.surface"/>}
						name={FILTER_PLANNING_FIELDS.SURFACE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsSurface}
						forceGetOptions
						formName={FILTER_PLANNING_FORM}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

FilterPlanning.propType = {
	idFerme: PropTypes.string,
	zoneSurfaceList: PropTypes.array,
	familleCultureList: PropTypes.array,
	especeCultureList: PropTypes.array,
	handleSubmit: PropTypes.func,
	initialize: PropTypes.func,
	classes: PropTypes.object
}

const selector = formValueSelector(FILTER_PLANNING_FORM)

const mapStateToProps = state => ({
	initialValues: getFilterValues(state),
	familleValues: selector(state, FILTER_PLANNING_FIELDS.FAMILLE.name),
	especeValues: selector(state, FILTER_PLANNING_FIELDS.ESPECE.name),
	zoneValues: selector(state, FILTER_PLANNING_FIELDS.ZONE.name),
	blocValues: selector(state, FILTER_PLANNING_FIELDS.BLOC.name)
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FILTER_PLANNING_FORM,
		enableReinitialize: true
	}),
	injectZoneSurfaceValueList,
	injectFamilleCultureValueList,
	injectEspeceCultureValueList,
	withStyles(styles)
)(FilterPlanning)
