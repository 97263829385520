import React from 'react'
import PropTypes from 'prop-types'

const EmptyIcon = ({ height, width }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M113.707 512L55.575 391.792c-43.694-90.351-18.098-196.268 55.842-257.579 46.027-38.166 89.94-78.812 130.239-122.984L251.9 0l23.821 31.473c29.393 38.834 51.822 80.128 65.073 121.97z"
				fill="#00aa95"
			/>
			<path
				d="M468.766 149.938l-7.743-38.704c-38.269 90.207-136.426 138.139-192.689 200.383C169.942 420.469 113.707 512 113.707 512h58.256c258.928 0 334.274-174.759 296.803-362.062z"
				fill="#00cc76"
			/>
			<path
				d="M461.022 111.234l-14.112 5.649c-34.771 13.919-70.217 25.975-106.117 36.56v.001c-21.412 6.314-42.985 12.104-64.674 17.454-93.258 23.005-162.413 107.214-162.413 207.575V512c.001 0 242.122-152.806 347.316-400.766z"
				fill="#a5e887"
			/>
		</svg>
	)
}

EmptyIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

EmptyIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default EmptyIcon
