import { fetchFactory, urlUtils } from 'isotope-client'

/** Onglet Ressoures */
export const getSemaine = (semaineChoisie) => fetchFactory(urlUtils.buildUrlWithParams('/semaines/par-date-debut', {
	dateDebut: semaineChoisie
}))

export const initialiserSemaine = (semaineChoisie) => fetchFactory(urlUtils.buildUrlWithParams('/semaines/initialiser', {
	dateDebut: semaineChoisie
}))

export const checkRessources = (semaineChoisie) => fetchFactory(urlUtils.buildUrlWithParams('/taches/checkRessources', {
	dateDebut: semaineChoisie
}))

export const refreshHeaderRessources = (dynamicFilters) => fetchFactory(urlUtils.buildUrlWithParams('/taches/refreshHeaderRessources', dynamicFilters))

export const refreshDataViaTimePicker = (idRessourceCible, jour, nouvelleCharge, isDebut) => fetchFactory(
	urlUtils.buildUrlWithParams('/taches/refreshDataViaTimePicker', {
		idRessourceCible,
		jour,
		nouvelleCharge,
		isDebut
	}),
	{ method: 'PUT' }
)

/** Onglet Affectation */
export const getAllUsers = (jourChoisi) => fetchFactory(urlUtils.buildUrlWithParams('/taches/ressourcesDate', {
	date: jourChoisi
}))

export const affecterUsers = (values, jourChoisi) => fetchFactory(
	urlUtils.buildUrlWithParams('/taches/affectation', {
		date: jourChoisi
	}),
	{
		method: 'POST',
		body: JSON.stringify(values)
	}
)

export const desaffecterUsers = (values, jourChoisi) => fetchFactory(
	urlUtils.buildUrlWithParams('/taches/affectation', {
		date: jourChoisi
	}),
	{
		method: 'DELETE',
		body: JSON.stringify(values)
	}
)

export const getInfosTacheForDetailsPanel = (idTache) => fetchFactory(urlUtils.buildUrlWithParams('/taches/detailTache', {
	idTache
}))


export const moveTasksDay = (ids, day) => fetchFactory('/taches/move/day', {
	method: 'POST',
	body: JSON.stringify({ ids, day })
})

export const moveTasksWeek = (ids, isWeekBefore) => fetchFactory('/taches/move/week', {
	method: 'POST',
	body: JSON.stringify({ ids, isWeekBefore })
})

export const deleteTasks = (ids, typeSuppression) => fetchFactory('/taches/delete', {
	method: 'DELETE',
	body: JSON.stringify({ ids, typeSuppression })
})

export const addInstructions = (idTacheList, instructions) => fetchFactory('/taches/addInstructions', {
	method: 'PUT',
	body: JSON.stringify({ idTacheList, instructions })
})

export const delayTache = (idTacheList, delayDate, delayWeeks) => fetchFactory('/taches/delayTache', {
	method: 'PUT',
	body: JSON.stringify({ idTacheList, delayDate, delayWeeks })
})

export const suiviDeTacheSave = (values) => fetchFactory(`/taches/suiviTache`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getAssolements = (surface, semaineSelection) => fetchFactory(`/assolement/${surface}/${semaineSelection}`)

export const getDetailsRecolte = (idTache) => fetchFactory(`/suiviTaches/detailsRecolte/${idTache}`)

export const getDetailsTri = (idTache) => fetchFactory(`/suiviTaches/detailsTri/${idTache}`)

export const getFamillesTacheByIdFerme = () => fetchFactory('/taches/famillesTacheByIdFerme')

export const getTypeTacheByIdFerme = () => fetchFactory('/taches/typeTacheByIdFerme')

export const getRecurrences = (values) => fetchFactory('/taches/recurrence', {
	method: 'POST',
	body: JSON.stringify(values)
})
