import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { colors, DEFAULT_LABEL } from '../../../../utils/constants'
import { injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'

const styles = () => getStyles({
	infoTacheEtiquette: {
		backgroundColor: colors.light,
		fontSize: 14,
		padding: 10,
		marginTop: 4
	},
	infoTache: {
		fontSize: 14
	},
	font16: {
		fontSize: 16
	},
	margin: {
		marginTop: 5
	}
})

const InfosTaches = ({ classes, tache, formatEtiquette, typeTacheList }) => {
	const nomTache = (typeTacheList.find(type => type.code === tache.type) || {}).label || DEFAULT_LABEL

	return (
		<Grid className={formatEtiquette ? classes.infoTacheEtiquette : classes.infoTache}>
			<b className={classes.font16}>{nomTache} </b>

			{tache.nomCulture &&
			<span className={classes.font16}>- {tache.nomCulture}</span>
			}

			<br />
			<Grid className={classes.margin}>
				{tache.libelleEmplacement}
			</Grid>

		</Grid>
	)
}

export default compose(
	injectTypeTacheValueList,
	withStyles(styles)
)(InfosTaches)
