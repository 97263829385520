import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import {Grid, withStyles} from '@material-ui/core'
import BackOfficeContainer from '../BackOfficeContainer'
import {getPlanificateurParametres, updateReglagesPlanificateur} from './services/parametresPlanificateurApi'
import {getPreferences} from '../../common/user/services/userSelectors'
import {connect} from 'react-redux'
import NoResultPage from '../../../components/NoResultPage'
import Loader from '../../../components/layout/Loader'
import {compose} from 'redux'
import {injectFermeList} from '../ferme/services/fermeListInjector'
import {getFormValues} from 'redux-form'
import PlanificateurParametres from "./components/PlanificateurParametres";
import StyledTabs from "../../../components/layout/tab/StyledTabs";
import {ONGLET_PLANIFICATEUR} from "../../../utils/constants";
import StyledTab from "../../../components/layout/tab/StyledTab";
import {formToPlanificateurReglage, getParametresForInitValues} from "./planificateurParametresUtils";
import {useSnackbar} from "../../../components/layout/snackbar/SnackbarContext";
import {getStyles} from "isotope-client";
import {getNotifications} from '../../common/notification/notificationActions'
import {useSolverStatusContext} from '../../fo/besoinsProduction/SolverStatusContextProvider'

const PLANIFICATEUR_CUTLURES_FORM = 'PLANIFICATEUR_CUTLURES_FORM'
const PLANIFICATEUR_TACHES_FORM = 'PLANIFICATEUR_TACHES_FORM'

const styles = () => getStyles({
	containerSize: {
		'& .MuiPaper-elevation1': {
			boxShadow: 'none'
		}
	},
})

const PlanificateurPage = ({idFerme, culturesValues, tachesValues, classes, getNotifications}) => {
	const intl = useIntl()
	const INIT_FILTERS = {idFerme}
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [index, setIndex] = React.useState(0)
	const [parametresData, setParametresData] = React.useState(undefined)
	const [loading, setLoading] = React.useState(false)
	const [culturesInitalValues, setCulturesInitialValues] = React.useState()
	const [tachesInitalValues, setTachesInitialValues] = React.useState()
	const [isFormNotSaved, setIsFormNotSaved] = React.useState(false)

	const { snackSuccess, snackError } = useSnackbar()
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	React.useEffect(() => {
		setInitialisation()
	}, [filters])

	const setInitialisation = () => {
		setLoading(true)
		getPlanificateurParametres(filters.idFerme).then((parametres) => {
			setCulturesInitialValues(getParametresForInitValues(parametres?.culture, true))
			setTachesInitialValues(getParametresForInitValues(parametres?.tache, false))
			setParametresData(parametres)
		}).finally(() => setLoading(false))
	}

	const handleCulturesSubmit = () => {
		updateReglagesPlanificateur(filters.idFerme, formToPlanificateurReglage(culturesValues, tachesValues || getParametresForInitValues(parametresData.tache, false), filters.idFerme, parametresData))
			.then(() => {
				snackSuccess({id: 'bo.planificateur.snackbar.success'})
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				setInitialisation()
			})
			.catch(() => snackError({ id: 'global.errors.form' }))
	}

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.planificateur.title"/>
			</Typography>
		</Grid>
		<BackOfficeContainer
			onSubmit={setFilters}
			withInput={false}
			withSaveBtn={true}
			labelSaveBtn={isFormNotSaved && 'bo.planificateur.notSaved'}
			onSave={handleCulturesSubmit}>

			{loading ?
				<Loader fromBo/>
				:
				parametresData !== undefined ?
					<Grid container direction="column" className={classes.containerSize}>
						<Grid container direction="row" justify="center">
							<StyledTabs value={index} onChange={(_, index) => setIndex(index)}>
								{Object.values(ONGLET_PLANIFICATEUR)
									.map(onglet => <StyledTab key={onglet.code} label={intl.formatMessage({id: `bo.planificateur.tabs.${onglet.code}`})}/>)}
							</StyledTabs>
						</Grid>
						<div>
							{index === ONGLET_PLANIFICATEUR.PLANIFICATEUR_CULTURES.order && <PlanificateurParametres formName={PLANIFICATEUR_CUTLURES_FORM}
																													 initialValues={culturesInitalValues}
																													 isCulture={true}
																													 setIsFormNotSaved={setIsFormNotSaved}
																													 params={parametresData.culture}/>}

							{index === ONGLET_PLANIFICATEUR.PLANIFICATEUR_TACHES.order && <PlanificateurParametres formName={PLANIFICATEUR_TACHES_FORM}
																												   initialValues={tachesInitalValues}
																												   isCulture={false}
																												   setIsFormNotSaved={setIsFormNotSaved}
																												   params={parametresData.tache}/>}
						</div>
					</Grid>
					: <NoResultPage/>
			}
		</BackOfficeContainer>
	</>)
}

PlanificateurPage.propTypes = {
	idFerme: PropTypes.string,
	culturesValues: PropTypes.object,
	tachesValues: PropTypes.object,
	classes: PropTypes.object,
}

const mapStateToProps = (state) => {
	return {
		idFerme: (getPreferences(state).FERME_ID || '0'),
		culturesValues: getFormValues(PLANIFICATEUR_CUTLURES_FORM)(state),
		tachesValues: getFormValues(PLANIFICATEUR_TACHES_FORM)(state)
	}
}

const actions = {
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	injectFermeList,
	withStyles(styles)
)(PlanificateurPage)

