import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import {getStyles} from 'isotope-client'
import mapError from './mapError'
import classnames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'

const styles = () => getStyles({
	input: {
		width: '100%'
	},
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	adornment: {
		alignItems: 'initial'
	},
	bo: {
		backgroundColor: '#f7f6f6 !important',
		'&:focus': {
			backgroundColor: '#f7f6f6 !important'
		}
	}
})

const Input = (Component) => (
	{
		input: { ...inputProps },
		meta: { error, ...otherMeta },
		containerClassName,
		classes,
		adornment,
		footerInfo,
		fromBo,
		adornmentIcon,
		...otherProps
	}
) => {
	return <div className={classnames(classes.container, containerClassName)}>
		<Component
			{...otherProps}
			{...inputProps}
			className={classes.input}
			variant="filled"
			helperText={(!otherMeta || otherMeta.touched) && error}
			meta={otherMeta}
			error={(!otherMeta || otherMeta.touched) && !!error}
			InputProps={{
				endAdornment: !!adornment ? <InputAdornment position="end" classes={{ root: adornmentIcon ? '' : classes.adornment }}>{adornment}</InputAdornment> : '',
				classes: {
					root: fromBo && classes.bo
				}
			}}
		/>
		{footerInfo}
	</div>
}

Input.defaultProps = {
	containerClassName: '',
	footerInfo: <></>
}

Input.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	footerInfo: PropTypes.element,
	classes: PropTypes.object
}

export default compose(
	mapError,
	withStyles(styles)
)(Input(TextField))

