export const UPDATE_WEATHER_FORM_NAME = 'updateWeather'
export const UPDATE_WEATHER_FORM_VALUE = 'value'

export const DAY_PERIOD = {
	AM: 'am',
	PM: 'pm'
}
export const PERIOD_SWITCH = [
	{
		id: 'day',
		label: 'tourPlaine.contextePedoclimatique.action.day'
	},
	{
		id: 'week',
		label: 'tourPlaine.contextePedoclimatique.action.week'
	}
]
// Used to map
export const INSIDE_TILES = {
	WEATHER: 'weather',
	HUMIDITY: 'humidity',
	TEMPERATURE: 'temperature',
	SOIL: 'soil'
}
// Used to map
export const OUTSIDE_TILES = {
	WEATHER: 'weather',
	HUMIDITY: 'humidity',
	WIND: 'wind',
	TEMPERATURE: 'temperature',
	SOIL: 'soil'
}
export const WEATHER_TILE_TYPE = {
	WEATHER: 'weather',
	HUMIDITY: 'humidity',
	WIND: 'wind',
	TEMPERATURE: 'temperature',
	SOIL: 'soil'
}

export const WEATHER_STATUS = {
	SUNNY: 'ensoleille', // Flatikon Meteo/Ensoleillé
	CLOUDY: 'nuageux',// Flatikon Meteo/Nuageux
	RAINY: 'pluvieux', // Flatikon Meteo/Pluvieux
	NOT_RAINY: 'non_pluvieux' // Flatikon Meteo/Non pluvieux
}

export const HUMIDITY_STATUS = {
	DRY: 'sec', // Flatikon Meteo/Sec
	MOYEN: 'moyen', // Flatikon CheckIcon
	WET: 'humide' // Flatikon Meteo/Humide
}

export const WIND_STATUS = {
	WINDY: 'vent_fort',  // Flatikon Meteo/Vent fort
	BREEZY: 'vent_faible' // Flatikon CheckIcon
}
export const TEMPERATURE_STATUS = {
	HOT: 'chaud', // Flatikon Meteo/Chaud
	DOUX: 'doux', // Flatikon CheckIcon
	COLD: 'froid', // Flatikon Meteo/Froid
	FREEZE: 'gel' // Flatikon Meteo/Gel
}
export const SOIL_STATUS = {
	BON: 'sol_bon', // Flatikon CheckIcon
	DRY: 'sol_sec',  // Flatikon Meteo/Sol sec
	WET: 'sol_detrempe', // Flatikon Meteo/Sol détrempé
	FREEZE: 'sol_gele' // Flatikon Meteo/Sol gelé
}