import React from 'react'
import HeaderRessources from './ressources/HeaderRessources'
import RessourcesTable from './ressources/RessourcesTable'

const RessourcesOnglet = () => {
	const ref = React.createRef()

	return (
		<>
			<HeaderRessources reference={ref} />
			<RessourcesTable reference={ref} />
		</>
	)

}

export default RessourcesOnglet