import { IconButton, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import TriIcon from '../../../../../components/icon/TriIcon'
import MenuActions from '../../../../../components/MenuActions'
import { colors, PROFILS } from '../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../utils/utils'
import { loadAssolement } from '../../services/assolement/assolementInjector'
import { setSortOpen, setSortUsed, setSortValue } from '../../services/toolbar/toolbarAction'
import { getSortState } from '../../services/toolbar/toolbarSelector'
import { SORT } from '../../utils/constant'

const styles = () => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12
	},
	active: {
		backgroundColor: colors.selected,
		color: colors.primary
	}
})


const itemToSort = Object.values(SORT).map(item => ({
	name: item,
	action: item,
	access: getAccessCheckerRole(PROFILS.MARAICHER)
}))

const Sort = ({ sortState, setSortOpen, setSortValue, setSortUsed, refreshAssolements, classes }) => {
	const [event, setEvent] = React.useState(null)

	React.useEffect(() => {
		setSortOpen(!!event)
	}, [event])

	const onClick = (event, category, item) => {
		let isUsed
		switch (item.action) {
			case SORT.SURFACE_ET_SEMIS:
			case SORT.SEMIS:
				isUsed = true
				break
			case SORT.DEFAULT:
			default:
				isUsed = false
				break
		}

		setSortUsed(isUsed)
		setSortValue(item.action)
		refreshAssolements(undefined, undefined, item.action)
	}

	let className = classes.icon
	if (sortState.used || sortState.open) {
		className += ` ${classes.active}`
	}
	return (
		<>
			<IconButton onClick={event => setEvent({ ...event })} className={className}><TriIcon color="currentColor" /></IconButton>
			<MenuActions
				items={{ sort: itemToSort }}
				onClick={onClick}
				formattedMessageId='planning'
				placement="bottom"
				setEvent={setEvent}
				event={event}
			/>
		</>
	)
}

const mapStateToProps = state => ({
	sortState: getSortState(state)
})

const actions = {
	setSortOpen,
	setSortUsed,
	setSortValue
}

export default compose(
	connect(mapStateToProps, actions),
	loadAssolement,
	withStyles(styles)
)(Sort)