import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { injectZoneSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'

/** Composant qui permet l'affichage de la zone de surface **/

const ZoneSurface = ({ planche, zoneSurfaceList, intl }) => {
	const zoneSurface = (zoneSurfaceList.find(zone => zone.code === planche.zone) || {}).label || DEFAULT_LABEL

	return <>{zoneSurface}{planche.bloc ? ` > ${intl.formatMessage({ id: 'planning.planche.detail.bloc' }, { bloc: planche.bloc })}` : ''}</>
}

ZoneSurface.propTypes = {
	planche: PropTypes.object,
	zoneSurfaceList: PropTypes.array
}

export default compose(
	injectIntl,
	injectZoneSurfaceValueList
)(ZoneSurface)
