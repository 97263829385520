import React from 'react'
import { Grid, MenuItem, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Select from '../../../../components/form/Select'
import {getAllMateriels} from '../../../fo/planning/services/planningApi'
import Autocomplete from '../../../../components/form/Autocomplete'
import classnames from 'classnames'
import ReglageForm from "./reglageForm";
import MaterielForm from "./materielForm";

const styles = (theme) => getStyles({
	form: {
		padding: '20px 20px 0px 20px',
		width: '100%'
	},
	row: {
		marginBottom: 15
	},
	field: {
		width: '50%'
	},
	info: {
		paddingLeft: 10
	},
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary,
		paddingLeft: 10,
		paddingRight: 50
	}
})

const formattedMessageBase = 'bo.reglagesMateriels.form'
export const FIELDS = {
	TYPE_AJOUT: { name: 'typeAjout' },
	MATERIEL: { name: 'idMateriel' }
}
const TYPE_OPTIONS = {
	MATERIEL: 'materiel',
	REGLAGE: 'reglage'
}

const AddReglageMaterielForm = ({
	idFerme,
	formName,
	onSubmit,
	typeAjoutSelected,
	materielSelected,
	classes,
	handleSubmit,
	prodMinoFerme,
	change
}) => {
	const [materiels, setMateriels] = React.useState([])
	React.useEffect(() => {
		getAllMateriels(idFerme).then((result => setMateriels(
			result.map(res => ({ label: res.nom, code: res.id }))
		)))
	}, [idFerme])

	React.useEffect(() => {
		if (typeAjoutSelected === TYPE_OPTIONS.MATERIEL) {
			change('prodMino', prodMinoFerme)
		} else {
			change('prodMino', undefined)
		}
	}, [typeAjoutSelected])

	const getForm = () => {
		if (typeAjoutSelected === TYPE_OPTIONS.REGLAGE && !!materielSelected) {
			return <>
				<Grid container className={classnames(classes.title, classes.row)}>
					<FormattedMessage id={`${formattedMessageBase}.titleNewReglage`} />
				</Grid>
				<ReglageForm
					formName={formName}
					onSubmit={onSubmit}
					noDeleteOnUnmout
				/>
			</>
		}

		if (typeAjoutSelected === TYPE_OPTIONS.MATERIEL) {
			return <>
				<Grid container className={classnames(classes.title, classes.row)}>
					<FormattedMessage id={`${formattedMessageBase}.titleNewMateriel`} />
				</Grid>
				<MaterielForm
					formName={formName}
					onSubmit={onSubmit}
				/>
			</>
		}

		return <></>
	}

	return (<form onSubmit={handleSubmit} className={classes.form}>
		<Grid container className={classnames(classes.info, classes.row)}>
			<FormattedMessage id={`${formattedMessageBase}.infoAjout`} />
		</Grid>
		<Grid container className={classes.row}>
			<Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.TYPE_AJOUT.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE_AJOUT.name}`} />}
					fromBo
				>
					{Object.values(TYPE_OPTIONS).map(type => <MenuItem key={`type-option-${type}`} value={type}>
						<FormattedMessage id={`${formattedMessageBase}.fields.ajoutOptions.${type}`} />
					</MenuItem>)}

				</Field>
			</Grid>
			{typeAjoutSelected === TYPE_OPTIONS.REGLAGE && <Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.MATERIEL.name}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.MATERIEL.name}`} />}
					options={materiels}
					fromBo
				/>
			</Grid>
			}
		</Grid>
		{getForm()}
	</form>)
}

AddReglageMaterielForm.propTypes = {
	idFerme: PropTypes.string,
	formName: PropTypes.string,
	onSubmit: PropTypes.func,
	reset: PropTypes.func,
	typeAjoutSelected: PropTypes.string,
	materielSelected: PropTypes.string,
	classes: PropTypes.object
}

const mapStateToProps = (state, { formName }) => {
	const formSelector = formValueSelector(formName)

	return {
		form: formName,
		typeAjoutSelected: formSelector(state, FIELDS.TYPE_AJOUT.name),
		materielSelected: formSelector(state, FIELDS.MATERIEL.name)
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		enableReinitialize: true
	}),
	withStyles(styles)
)(AddReglageMaterielForm)
