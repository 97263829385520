import React from 'react'
import {ReactComponent as Jardinier} from '../../../../icons/jardinier.svg'
import {ReactComponent as Outils} from '../../../../icons/outils-et-ustensiles.svg'
import {ReactComponent as Chronometer} from '../../../../icons/chronometer.svg'
import {convertMinToHours, formatDuration} from '../../../../utils/utils'
import {FormattedMessage} from 'react-intl'
import Grid from '@material-ui/core/Grid'
import InfosTache from '../component/InfosTache'
import NoResultPage from '../../../../components/NoResultPage'
import {ETAT_TACHE_LIBELLE} from '../../../../utils/constants'

export const getAffectationsInline = (affectations) => (
	`${affectations.map((personne) => ` ${personne.user.firstname} (${personne.user.preferences.ALIAS}) `)}`
)

export const getMaterielsInline = (materiels) => (
	`${materiels.map(outil => ` ${outil.nom}`)}`
)

export const getInformationsTache = (classes, taches, formatEtiquette = true) => {
	const tachesTab = Array.isArray(taches) ? taches : [taches]
	const tache = tachesTab[0]

	return (
		<Grid className={formatEtiquette === false && classes.gridPadding}>
			{/*Étiquette de tache à realiser*/}
			<Grid className={classes.marginTop}>
				{
					tachesTab.length > 0
						? <InfosTache tache={tache} formatEtiquette={formatEtiquette} />
						: <NoResultPage mobileListe={ETAT_TACHE_LIBELLE.EN_COURS} />
				}
			</Grid>

			{/*Description*/}
			{((tache.description) && (tachesTab.length > 0)) &&
			<Grid className={classes.description}>
				{(tache.description.length > 0) &&
				tache.description
				}
			</Grid>
			}

			{/*Instruction*/}
			{((tache.instructions) && (tachesTab.length > 0)) &&
				<Grid className={classes.description}>
					<FormattedMessage id={'mobile.fields.instructions'}/>
					{tache.instructions}
				</Grid>
			}

			{/*Trois listes*/}
			{tachesTab.length > 0 &&
			<Grid className={classes.affectOutilsTemps}>
				<Grid className={classes.infoSurTache}>
					<Jardinier className={classes.icon} />
					{getAffectationsInline(tache.affectationLightBeanList)}
				</Grid>

				{tache.materiels.length > 0 &&
				<Grid className={classes.infoSurTache}>
					<Outils className={classes.icon} />
					{getMaterielsInline(tache.materiels)}
				</Grid>
				}

				<Grid className={classes.infoSurTache}>
					<Chronometer className={classes.icon} />
					{tache.tempsPasseParUserActuel > 0
						? <FormattedMessage id={'mobile.content.tempsNecessaireCommence'}
						                    values={{
							                    tempsNecessaire: formatDuration(tache.tempsParPersonne),
							                    tempsPasse: convertMinToHours(tache.tempsPasseParUserActuel)
						                    }} />
						: <FormattedMessage id={'mobile.content.tempsNecessaire'}
						                    values={{
							                    tempsNecessaire: formatDuration(tache.tempsParPersonne)
						                    }} />
					}
				</Grid>
			</Grid>
			}
		</Grid>
	)
}