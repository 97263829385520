import React from 'react'
import {Chart} from 'react-chartjs-2'
import {LocalDate} from '../../../../../utils/date/local-date'
import {FormattedMessage, useIntl} from 'react-intl'
import Grid from '@material-ui/core/Grid/index'
import Typography from '@material-ui/core/Typography/index'
import {fillGapsY} from '../../utils/chartUtils'

const PlanificationVsCharge = ({datas}) => {
	const intl = useIntl()

	const data = {
		labels: datas.map(d => intl.formatMessage({id: 'gestionTaches.ecranSynthese.week'}, {weekNumber: LocalDate.fromAPI(d.dateDebut).weekNumber})),
		datasets: [
			{
				type: 'line',
				label: intl.formatMessage({id: 'gestionTaches.ecranSynthese.planificationVsCharge.graph.chargeTravail'}),
				borderColor: '#00E272',
				borderWidth: 2,
				data: datas.map(d => d.chargeTravail)
			},
			{
				type: 'bar',
				label: intl.formatMessage({id: 'gestionTaches.ecranSynthese.planificationVsCharge.graph.tempsTravail'}),
				backgroundColor: '#2CAFFE',
				data: datas.map(d => d.tempsTravail),
				borderRadius: 5
			},
			{
				type: 'bar',
				label: intl.formatMessage({id: 'gestionTaches.ecranSynthese.planificationVsCharge.graph.capaciteTravail'}),
				backgroundColor: '#544FC5',
				data: datas.map(d => d.capaciteTravail),
				borderRadius: 5
			}
		]
	}

	const options = {
		scales: {
			x: {
				grid: {
					display: false
				},
				ticks: {
					font: {
						family: 'Lato'
					}
				}
			},
			y: {
				title: {
					display: true,
					text: intl.formatMessage({id: 'gestionTaches.ecranSynthese.planificationVsCharge.graph.nbHeures'}),
					font: {
						family: 'Lato'
					}
				},
				grid: {
					drawBorder: false
				}
			}
		},
		plugins: {
			legend: {
				position: 'right',
				labels: {
					usePointStyle: true,
					font: {
						family: 'Lato'
					}
				}
			},
			tooltip: {
				usePointStyle: true,
				callbacks: {
					label: (context) => {
						if (context.datasetIndex === 0) {
							return context.dataset.label + ': ' + context.formattedValue + 'h'
						} else if (context.datasetIndex === 1) {
							return context.dataset.label + ': ' + context.formattedValue + 'h planifiée (' + datas[context.dataIndex].tempsTravailTache + ' tâches)'
						} else {
							return context.dataset.label + ': ' + context.formattedValue + 'h (' + datas[context.dataIndex].capaciteTravailRessource + ' ressources)'
						}
					}
				}
			}
		}
	}

	return (
		<Grid container item direction="column" alignItems="center" style={{gap: 20, margin: 20}}>
			<Typography variant="h2">
				<b><FormattedMessage id="gestionTaches.ecranSynthese.planificationVsCharge.title"/></b>
			</Typography>
			<Grid container item alignItems="flex-start">
				<Typography style={{fontSize: 12, color: 'gray'}}>
					<FormattedMessage id="gestionTaches.ecranSynthese.planificationVsCharge.subtitle"/>
				</Typography>
			</Grid>
			<Grid container item xs={8}>
				<Chart type='bar' data={data} options={options} plugins={[fillGapsY]}/>
			</Grid>
		</Grid>
	)
}

export default PlanificationVsCharge