import React from 'react'
import CheckIcon from '../../../../../../components/icon/CheckIcon'
import CloudyIcon from '../../../../../../components/icon/CloudyIcon'
import ColdIcon from '../../../../../../components/icon/ColdIcon'
import DroughtIcon from '../../../../../../components/icon/DroughtIcon'
import DrySoilIcon from '../../../../../../components/icon/DrySoilIcon'
import FrozenGroundIcon from '../../../../../../components/icon/FrozenGroundIcon'
import FrozenIcon from '../../../../../../components/icon/FrozenIcon'
import HeatIcon from '../../../../../../components/icon/HeatIcon'
import HumidityIcon from '../../../../../../components/icon/HumidityIcon'
import NotRainIcon from '../../../../../../components/icon/NotRainIcon'
import RainIcon from '../../../../../../components/icon/RainIcon'
import SproutIcon from '../../../../../../components/icon/SproutIcon'
import SunIcon from '../../../../../../components/icon/SunIcon'
import WindIcon from '../../../../../../components/icon/WindIcon'
import { HUMIDITY_STATUS, SOIL_STATUS, TEMPERATURE_STATUS, WEATHER_STATUS, WIND_STATUS } from './contextePedoclimatique.constants'

/**
 * Return Weather Icon
 * @param status
 * @returns {JSX.Element}
 */
export const getWeatherIcon = (status, ratio = 35) => {
	switch (status) {
		case WEATHER_STATUS.CLOUDY:
			return <CloudyIcon height={ratio} width={ratio} />
		case WEATHER_STATUS.SUNNY:
			return <SunIcon height={ratio} width={ratio} />
		case WEATHER_STATUS.RAINY:
			return <RainIcon height={ratio} width={ratio} />
		case WEATHER_STATUS.NOT_RAINY:
			return <NotRainIcon height={ratio} width={ratio} />
		default:
			return null
	}
}

/**
 * Return Humidity Icon
 */
export const getHumidityIcon = (status, ratio = 35) => {
	switch (status) {
		case HUMIDITY_STATUS.DRY:
			return <DroughtIcon height={ratio} width={ratio} />
		case HUMIDITY_STATUS.WET:
			return <HumidityIcon height={ratio} width={ratio} />
		case HUMIDITY_STATUS.MOYEN:
			return <CheckIcon height={ratio} width={ratio} />
		default:
			return null
	}
}

/**
 * Return Wind Icon
 */
export const getWindIcon = (status, ratio = 35) => {
	switch (status) {
		case WIND_STATUS.WINDY:
			return <WindIcon height={ratio} width={ratio} />
		case WIND_STATUS.BREEZY:
			return <CheckIcon height={ratio} width={ratio} />
		default:
			return null
	}
}

/**
 * Return Temperature Icon
 */
export const getTemperatureIcon = (status, ratio = 35) => {
	switch (status) {
		case TEMPERATURE_STATUS.HOT:
			return <HeatIcon height={ratio} width={ratio} />
		case TEMPERATURE_STATUS.COLD:
			return <ColdIcon height={ratio} width={ratio} />
		case TEMPERATURE_STATUS.FREEZE:
			return <FrozenIcon height={ratio} width={ratio} />
		case TEMPERATURE_STATUS.DOUX:
			return <CheckIcon height={ratio} width={ratio} />
		default:
			return null
	}
}

/**
 * Return Soil Icon
 */
export const getSoilIcon = (status, ratio = 35) => {
	switch (status) {
		case SOIL_STATUS.WET:
			return <SproutIcon height={ratio} width={ratio} />
		case SOIL_STATUS.FREEZE:
			return <FrozenGroundIcon height={ratio} width={ratio} />
		case SOIL_STATUS.DRY:
			return <DrySoilIcon height={ratio} width={ratio} />
		case SOIL_STATUS.BON:
			return <CheckIcon height={ratio} width={ratio} />
		default:
			return null
	}
}