import {Grid, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import TableauDeBordContent from './components/TableauDeBordContent'
import {ActionPanelContext} from '../../../components/layout/rightPanels'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import TourPlaineProvider from '../tourPlaine/TourPlaineProvider'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'

const styles = () => getStyles({
	container: {
		position: 'relative',
		overflowY: 'hidden'
	},
	backgroundImageOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundImage: 'url(/img/login/loginBackground.jpg)',
		opacity: 0.12,
		pointerEvents: 'none',
		zIndex: 0
	}
})

const TableauDeBordPage = ({classes}) => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.TDB), [])

	return (
		<DetailPanelProvider>
			<ActionPanelProvider Context={ActionPanelContext} level={1}>
				<TourPlaineProvider>
					<Grid
						container
						direction="column"
						className={classes.container}
					>
						<TableauDeBordContent/>
						<div className={classes.backgroundImageOverlay}></div>
					</Grid>
				</TourPlaineProvider>
			</ActionPanelProvider>
		</DetailPanelProvider>
	)
}

export default withStyles(styles)(TableauDeBordPage)
