import { DEFAULT_PLANNING_WIDTH, SORT } from '../../utils/constant'
import * as actions from './toolbarAction'
import { combineReducers } from 'redux'

export const initialFilter = {}
const initialFilterState = {
	open: false,
	closing: false,
	used: false,
	currentFilter: initialFilter
}

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
const filter = (state = initialFilterState, action) => {

	switch (action.type) {
		case actions.SET_FILTER_OPEN:
			return {
				...state,
				open: !state.closing
			}
		case actions.SET_FILTER_CLOSING:
			return {
				...state,
				open: false,
				closing: true
			}
		case actions.SET_FILTER_CLOSE:
			return {
				...state,
				closing: false
			}
		case actions.SET_FILTER_USED:
			return {
				...state,
				used: action.value
			}
		case actions.SAVE_CURRENT_FILTER:
			return {
				...state,
				currentFilter: action.values
			}
		case actions.RESET_FILTER:
			return initialFilterState
		case LOCATION_CHANGE:
			return initialFilterState
		default:
			return state
	}
}

const initialSortState = {
	open: false,
	used: false,
	currentSort: SORT.SURFACE_ET_SEMIS
}

const sort = (state = initialSortState, action) => {
	switch (action.type) {
		case actions.SET_SORT_USED:
			return {
				...state,
				used: action.value
			}
		case actions.SET_SORT_OPEN:
			return {
				...state,
				open: action.value
			}
		case actions.SET_SORT_VALUE:
			return {
				...state,
				currentSort: action.value
			}
		default:
			return state
	}
}


const planningSize = (state = DEFAULT_PLANNING_WIDTH, action) => {
	switch (action.type) {
		case actions.SET_PLANNING_SIZE_VALUE:
			return action.value
		default:
			return state
	}
}

const planningToolbar = combineReducers({
	filter,
	sort,
	planningSize
})

export default planningToolbar
