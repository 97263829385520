import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isInvalid } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import CreerGroupeDeCulture from '../../../component/form/CreerGroupeDeCulture'
import { CREER_GROUPE_DE_CULTURE_FORM_NAME, PANEL_TYPE } from '../../../utils/constant'
import FormButton from '../../../../../../components/form/FormButton'
import { loadAssolement } from '../../assolement/assolementInjector'
import { createCultureGroup } from '../../cultureApi'
import { openCultureDetail } from '../detail/openCultureDetailInjector'
import * as selectors from '../../assolement/assolementSelector'
import { TYPE_DIALOG } from '../../../../../../utils/constants'
import Button from '../../../../../../components/Button'
import { DialogContext } from '../../../../../../components/layout/dialog'
import ContenuPopinAjoutGroupeCulture from '../../../component/form/ContenuPopinAjoutGroupeCulture'
import moment from 'moment'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'

/**
 * Injecteur pour l'affichage du panel de création d'un groupe de culture
 */
export const injectCreateCultureGroupPanel = (WrappedComponent) => {
	const CreateCultureGroupInjector = ({ currentAssolement, currentCampagne, assolements, panelType, ...props}) => {
		const { updatePanel: updateDetailsPanel, closePanel: closePanelDetail } = React.useContext(DetailsPanelContext)
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { snackSuccess, snackError } = useSnackbar()

		const formattedMessageBase = 'planning.cultureGroup.createGroup.dialog'

		const surface = React.useMemo(() => currentAssolement.planche ? currentAssolement.planche.typePlanche : '', [currentAssolement.planche])

		const openCreateCultureGroupDialog = values => openDialog({ id: `${formattedMessageBase}.title` },
			<ContenuPopinAjoutGroupeCulture
				formattedMessageBase={formattedMessageBase}
				values={values}
				assolements={assolements}
				surface={surface}
			/>, [
				<Button
					type="primary"
					onClick={() => {
						createCultureGroup(currentCampagne.id, {
							...values,
							dateDebut: moment(values.dateDebut, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							dateFin: moment(values.dateFin, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							surface,
							cultures: values.assolement
						}).then(() => {
							closeDialog()
							closePanelDetail()
							snackSuccess({ id: `${formattedMessageBase}.success` })
						}).catch(() => {
							snackError({ id: `${formattedMessageBase}.error` })
						})
					}}
				>
					<FormattedMessage id="actions.confirm" />
				</Button>
			], TYPE_DIALOG.ACTION, `${formattedMessageBase}.cancel`)

		React.useEffect(() => {
			if (currentAssolement && panelType === PANEL_TYPE.CREATE_CULTURE_GROUP) {
				let buttons = [
					<FormButton
						type="primary"
						formName={CREER_GROUPE_DE_CULTURE_FORM_NAME}
						getDisabledFromState={state => isInvalid(CREER_GROUPE_DE_CULTURE_FORM_NAME)(state)}
					>
						<FormattedMessage id="actions.create" />
					</FormButton>
				]

				updateDetailsPanel(
					{ id: 'planning.cultureGroup.createGroup.title' },
					<CreerGroupeDeCulture
						onSubmit={openCreateCultureGroupDialog}
						currentAssolement={currentAssolement}
					/>,
					buttons
				)
			}
		}, [currentAssolement, panelType])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		currentAssolement: selectors.getCurrentAssolement(state),
		panelType: selectors.getDetailPanelType(state)
	})

	CreateCultureGroupInjector.propTypes = {
		currentAssolement: PropTypes.object,
		currentCampagne: PropTypes.object,
		assolements: PropTypes.array,
		panelType: PropTypes.string
	}

	return compose(
		loadAssolement,
		openCultureDetail,
		connect(mapStateToProps)
	)(CreateCultureGroupInjector)
}
