import React from 'react'
import PropTypes from 'prop-types'

const WateringCanIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512.001 512.001",
		}}
		viewBox="0 0 512.001 512.001"
	>
		<path
			d="M495.994 168.606c7.747-2.582 13.492-8.867 15.369-16.812 1.876-7.946-.452-16.136-6.226-21.908l-59.998-59.981c-5.775-5.772-13.965-8.098-21.917-6.222-7.947 1.876-14.233 7.62-16.815 15.363l-18.857 56.552c-.268.802-.498 1.61-.714 2.418L188.18 300.263c-3.284 2.683-5.237 6.586-5.45 10.821-.213 4.235 1.421 8.318 4.42 11.316l65.418 65.399c2.821 2.82 6.57 4.463 10.539 4.463.251 0 .468.028.719.016a14.947 14.947 0 0 0 10.849-5.474l162.346-198.653c.803-.215 1.606-.429 2.403-.695l56.57-18.85z"
			style={{
				fill: "#859da6",
			}}
		/>
		<path
			d="M436.845 188.328c.803-.215 1.695-.518 2.491-.783l56.614-18.895c7.747-2.582 13.514-8.889 15.391-16.834 1.876-7.946-.441-16.147-6.215-21.919L445.134 69.91c-5.775-5.772-13.962-8.1-21.914-6.225-7.947 1.876-14.231 7.618-16.813 15.362l-18.856 56.551c-.268.802-.674 1.786-.891 2.595l-.205.2 50.174 50.159.216-.224z"
			style={{
				fill: "#5791de",
			}}
		/>
		<path
			d="m475.137 99.892-29.998-29.987c-5.775-5.772-13.965-8.098-21.917-6.222-7.947 1.876-14.233 7.62-16.815 15.363l-18.857 56.552c-.267.802-.498 1.61-.714 2.418L188.18 300.263c-3.284 2.683-5.237 6.586-5.45 10.82-.213 4.235 1.421 8.318 4.42 11.316l32.746 32.733 255.241-255.24z"
			style={{
				fill: "#b8cfd6",
			}}
		/>
		<path
			d="m411.55 163.479 63.578-63.578-29.995-29.991c-5.775-5.772-13.962-8.1-21.914-6.224-7.947 1.876-14.231 7.618-16.813 15.362l-18.856 56.551c-.267.802-.674 1.786-.891 2.595l-.205.2 25.096 25.085z"
			style={{
				fill: "#52c5ff",
			}}
		/>
		<path
			d="M147.469 95.024c-50.264 0-91.157 40.881-91.157 91.13 0 8.284 6.718 15 15.004 15s15.004-6.716 15.004-15c0-33.708 27.431-61.13 61.148-61.13 33.718 0 61.149 27.423 61.149 61.13 0 8.284 6.718 15 15.004 15s15.004-6.716 15.004-15c.001-50.249-40.892-91.13-91.156-91.13z"
			style={{
				fill: "#859da6",
			}}
		/>
		<path
			d="M147.469 95.024c-50.264 0-91.157 40.881-91.157 91.13 0 8.284 6.718 15 15.005 15s15.004-6.716 15.004-15c0-33.708 27.431-61.13 61.148-61.13v-30z"
			style={{
				fill: "#b8cfd6",
			}}
		/>
		<path
			d="M282.289 232.593c-9.715-29.611-37.187-49.639-68.359-49.639H81.009c-31.173 0-58.644 20.028-68.359 49.639-15.554 47.409-16.789 97.965-3.57 146.079l4.716 17.287c8.553 31.13 37.08 52.995 69.374 52.995h128.6c32.294 0 60.821-21.831 69.373-52.962l4.716-17.276c13.219-48.112 11.984-98.713-3.57-146.123z"
			style={{
				fill: "#5791de",
			}}
		/>
		<path
			d="M147.469 182.954h-66.46c-31.173 0-58.645 20.028-68.359 49.639-15.554 47.409-16.789 97.965-3.57 146.079l4.716 17.287c8.553 31.13 37.08 52.995 69.374 52.995h64.3v-266h-.001z"
			style={{
				fill: "#52c5ff",
			}}
		/>
	</svg>
)


WateringCanIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

WateringCanIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default WateringCanIcon