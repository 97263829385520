import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FormButton from '../../../../../components/form/FormButton'
import { FilterPanelContext } from '../../../../../components/layout/rightPanels'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import FilterPlanning from '../../component/form/FilterPlanning'
import { FILTER_PLANNING_FORM } from '../../utils/constant'
import { loadAssolement } from '../assolement/assolementInjector'
import { getAssolements } from '../assolement/assolementSelector'
import { saveCurrentFilter, setFilterOpen, setFilterUsed } from '../toolbar/toolbarAction'
import { getFilterToolbarState } from '../toolbar/toolbarSelector'

/**
 * Injecteur pour l'ouverture du filtre d'assolement
 */
export const injectAssolementFilter = (WrappedComponent) => {
	const FilterAssolementInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(FilterPanelContext)

		const { campagne, refreshAssolements, filterState, setFilterUsed, setFilterOpen, saveCurrentFilter } = props
		let title = <FormattedMessage id="planning.filter.title" />
		const onSubmit = (values) => {
			const notEmpty = Object.values(values).some(value => !!value && value.length)
			const formattedValues = Object.entries(values).reduce((acc, [key, value]) => ({
				...acc,
				[key]: value.map(item => item.code)
			}), {})

			setFilterUsed(notEmpty)
			setFilterOpen(false)
			closePanel()
			saveCurrentFilter(values)
			return refreshAssolements(campagne.id, formattedValues)
		}
		const buttons = [
			<FormButton type="primary" formName={FILTER_PLANNING_FORM}>
				<FormattedMessage id="planning.filter.form.submit" />
			</FormButton>
		]

		React.useEffect(() => {
			if (filterState.open) {
				openPanel(
					title,
					<FilterPlanning onSubmit={onSubmit} idFerme={campagne.idFerme} />,
					buttons
				)
			}
		}, [filterState.open])

		return <WrappedComponent {...props} />
	}


	const mapStateToProps = state => ({
		filterState: getFilterToolbarState(state),
		assolements: getAssolements(state),
		campagne: getCurrentCampagne(state)
	})

	const actions = {
		setFilterUsed,
		setFilterOpen,
		saveCurrentFilter
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(FilterAssolementInjector)
}
