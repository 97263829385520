import React from 'react'
import { ACTION_RESET, ACTION_UPDATE } from './action'

export const initialState = {
	titleIcon:  <React.Fragment />,
	title: '',
	content: <React.Fragment />,
	actions: [],
	className: {},
	isClickAwayEnabled: true
}

/**
 * Manipulation d'un panneau latéral via un réducer local
 * @param state état actuel du panneau
 * @param action action à traiter
 * @returns {{title: string, actions: Array<React.Component>, content: React.Component}}
 */
export const reducer = (state, action) => {
	switch (action.type) {
		case ACTION_UPDATE:
			return {
				...state,
				...action.payload
			}
		case ACTION_RESET:
			return initialState
		default:
			throw new Error('Invalid action')
	}
}