import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const ImplantationIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M341.042 46.87c-6.407-5.254-15.858-4.316-21.11 2.089C301.161 71.853 284.359 93.015 271 116.59V15.2c0-8.284-6.716-15-15-15s-15 6.716-15 15v101.39c-13.359-23.574-30.161-44.737-48.932-67.631-5.252-6.406-14.703-7.343-21.11-2.089-6.406 5.252-7.342 14.704-2.089 21.11 41.133 50.169 68.719 88.497 71.146 151.053l-30.905 60.678c-8.326 16.348-7.747 35.588 1.55 51.469 9.103 15.549 25.194 25.264 43.023 25.986a58.297 58.297 0 004.637 0c17.853-.731 33.943-10.453 43.041-26.006 9.289-15.878 9.864-35.114 1.538-51.456l-30.914-60.675c2.428-62.553 30.014-100.881 71.146-151.049 5.252-6.406 4.317-15.858-2.089-21.11zm-64.873 246.454c3.664 7.192 3.401 15.673-.702 22.688-3.972 6.789-10.669 10.864-18.356 11.179-.721.028-1.433.032-2.194.001-7.7-.313-14.395-4.383-18.367-11.168-4.108-7.017-4.372-15.502-.708-22.697l20.161-39.583 20.166 39.58z"
				fill={color}
			/>
			<path
				d="M497 269.622H362.885c-28.771 0-39.215 23.269-39.215 38.909 0 37.098-30.394 67.278-67.753 67.278-37.991 0-67.75-29.324-67.75-66.771-.011-15.845-10.464-39.417-39.215-39.417H15c-8.284 0-15 6.716-15 15v182.24c0 24.78 20.159 44.939 44.938 44.939h422.124c24.779 0 44.938-20.159 44.938-44.938v-182.24c0-8.284-6.716-15-15-15zm-15 197.24c0 8.236-6.701 14.938-14.938 14.938H44.938C36.701 481.8 30 475.099 30 466.862v-10.079h452v10.079zm0-40.079H30V299.622h118.951c2.196 0 8.869 0 9.215 9.594.043 26.032 10.239 50.37 28.72 68.546 18.391 18.087 42.906 28.048 69.03 28.048 26.116 0 50.649-10.069 69.079-28.354 18.451-18.304 28.63-42.716 28.675-68.754.349-9.08 6.788-9.08 9.215-9.08H482v127.161z"
				fill={color}
			/>
		</svg>
	)
}

ImplantationIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

ImplantationIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default ImplantationIcon
