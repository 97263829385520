import React from 'react'
import {change, Field, reduxForm} from 'redux-form'
import {compose} from 'redux'
import {connect} from 'react-redux'
import Input from '../../../../../../components/form/Input'
import {Grid} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {formatPositiveInteger} from '../../../../../../utils/utils'
import {FormattedMessage} from 'react-intl'
import {colors} from '../../../../../../utils/constants'
import {LocalDate} from "../../../../../../utils/date/local-date";

export const fields = {
    QUANTITE_KG: 'quantiteKg',
    NB_PLANCHES: 'nbPlanches'
}

const styles = () => getStyles({
    fieldContainer: {
        marginBottom: 0
    },
    input: {
        width: '30%',

        '& .MuiFilledInput-input': {
            paddingTop: 12
        },

        '& .MuiInputAdornment-root': {
            alignItems: 'center',
			marginLeft: 0
        }
    },
	unites: {
		fontSize: 12,
		paddingLeft: 2,
		paddingRight: 2,
		color: colors.placeholder
	}
})

export const getQuantiteHebdo = (row) => {
	if (row.quantiteKg && row.dateDebutRecolte && row.dateFinRecolte) {
		// on arrondit à l'entier supérieur car la date début de récolte est toujours un lundi et la date fin de récolte toujours un dimanche
		// donc sinon le nbSemaines n'est pas entier car une semaine entière correspond à du lundi au lundi
		const nbSemaines = Math.ceil(LocalDate.fromAPI(row.dateFinRecolte)._diff(LocalDate.fromAPI(row.dateDebutRecolte), 'weeks'))
		return (row.quantiteKg / (nbSemaines > 0 ? nbSemaines : 1)).toFixed(1)
	}
	return 0
}

export const getQuantiteHebdoSolution = (row) => {
	if (row.quantiteKgSolution && row.dateDebutRecolteSolution && row.dateFinRecolteSolution) {
		// on arrondit à l'entier supérieur car la date début de récolte est toujours un lundi et la date fin de récolte toujours un dimanche
		// donc sinon le nbSemaines n'est pas entier car une semaine entière correspond à du lundi au lundi
		const nbSemaines = Math.ceil(LocalDate.fromAPI(row.dateFinRecolteSolution)._diff(LocalDate.fromAPI(row.dateDebutRecolteSolution), 'weeks'))
		return (row.quantiteKgSolution / (nbSemaines > 0 ? nbSemaines : 1)).toFixed(1)
	}
	return 0
}

export const getRendement = (culture, itks, fermeLongueurPlanche) => {
	const itk = itks.find(itk => itk.idCulture === culture)
	return fermeLongueurPlanche / (itk.espacement / 100) * itk.nbGraines * itk.nbRang * itk.rendementTheo
}

const QuantiteForm = ({
    row,
    itks,
  	fermeLongueurPlanche,
    changeValue,
    changeValues,
    campagne,
    change,
    classes
}) => {
    return (
        <form>
            <Grid container direction="row" alignItems="center" justify="space-between">
                {!campagne.finished && <Grid item className={classes.input}>
                    <Field
                        name={fields.QUANTITE_KG}
                        component={Input}
                        containerClassName={classes.fieldContainer}
                        adornment="kg"
                        fromBo
						type="number"
						format={formatPositiveInteger}
						onChange={event => {
							let quantiteKg = formatPositiveInteger(event.target.value)
							if (row.culture) {
								const nbPlanches = Math.round(quantiteKg / getRendement(row.culture, itks, fermeLongueurPlanche))
								quantiteKg = Math.round(nbPlanches * getRendement(row.culture, itks, fermeLongueurPlanche))
								changeValues(['quantiteKg', 'nbPlanches'], row, {quantiteKg, nbPlanches})
								change(fields.NB_PLANCHES, nbPlanches)
							} else {
								changeValue('quantiteKg', row, quantiteKg)
							}
						}}
						inputProps={{ min: 0 }}
                    />
                </Grid>}
                <Grid item>
                    <span><b>{row.quantiteKg ?? 0}</b> <span className={classes.unites}><FormattedMessage id="besoinsProduction.unites.kg"/></span></span>
                </Grid>
                <Grid item>
                    <span><b>{getQuantiteHebdo(row)}</b> <span className={classes.unites}><FormattedMessage id="besoinsProduction.unites.kgParSemaine"/></span></span>
				</Grid>
				<Grid item className={campagne.finished ? null : classes.input}>
					{campagne.finished ? <span><b>{row.nbPlanches}</b> <span className={classes.unites}><FormattedMessage id="besoinsProduction.unites.planches" values={{planche: row.nbPlanches}}/></span></span> : <Field
						name={fields.NB_PLANCHES}
                        component={Input}
                        containerClassName={classes.fieldContainer}
                        adornment="planches"
                        fromBo
						type="number"
						format={formatPositiveInteger}
						onChange={event => {
							let nbPlanches = formatPositiveInteger(event.target.value)
							if (row.culture) {
								const quantiteKg = Math.round(nbPlanches * getRendement(row.culture, itks, fermeLongueurPlanche))
								changeValues(['quantiteKg', 'nbPlanches'], row, {quantiteKg, nbPlanches})
								change(fields.QUANTITE_KG, quantiteKg)
							} else {
								changeValue('nbPlanches', row, nbPlanches)
							}
						}}
						inputProps={{ min: 0 }}
                    />}
                </Grid>
            </Grid>
        </form>
    )
}

const mapStateToProps = (state, {row, formName}) => ({
	form: formName,
	initialValues: {
		[fields.QUANTITE_KG]: row.quantiteKg ?? 0,
		[fields.NB_PLANCHES]: row.nbPlanches ?? 0
	}
})

const actions = {
    change
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm(),
    withStyles(styles)
)(QuantiteForm)
