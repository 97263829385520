import React, { useEffect } from 'react'
import { DetailsPanel, DetailsPanelContext } from '../rightPanels'
import { ACTION_RESET, ACTION_UPDATE } from './service/action'
import { initialState, reducer } from './service/reducer'

const DetailPanelProvider = ({ children }) => {
	const [detailsState, detailsDispatch] = React.useReducer(reducer, initialState)

	const updateDetails = React.useCallback((title, content, actions, persistOnCollapse) => {
		return detailsDispatch({
			type: ACTION_UPDATE,
			payload: {
				title,
				content,
				actions,
				persistOnCollapse
			}
		})
	}, [])
	const resetDetails = React.useCallback(() => detailsDispatch({ type: ACTION_RESET }), [])

	const [openDetails, setOpenDetails] = React.useState(false)
	const [collapsedDetails, setCollapsedDetails] = React.useState(false)

	const updateDetailsPanel = (title, content, actions, persistOnCollapse) => {
		updateDetails(title, content, actions, persistOnCollapse)
	}
	const openDetailsPanel = () => {
		setOpenDetails(true)
		setCollapsedDetails(false)
	}
	const closeDetailsPanel = (shouldResetDetails = true) => {
		if (shouldResetDetails) {
			resetDetails()
		}
		setOpenDetails(false)
	}

	useEffect(() => {
		return () => {
			// OnUnMount
			resetDetails()
		}
	}, [])

	return (
		<DetailsPanelContext.Provider
			value={{ ...detailsState, openPanel: openDetailsPanel, closePanel: closeDetailsPanel, updatePanel: updateDetailsPanel }}>
			<DetailsPanel open={openDetails} collapsed={collapsedDetails} setCollapsed={setCollapsedDetails} />
			{children}
		</DetailsPanelContext.Provider>

	)
}

export default DetailPanelProvider
