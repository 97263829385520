import { MenuItem } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, getFormValues } from 'redux-form'
import Checkbox from '../../../../../../components/form/Checkbox'
import Select from '../../../../../../components/form/Select'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'
import { formFields } from './FertilizationForm'

const styles = () => getStyles({
	formInput: {
		'& > div': {
			padding: 0
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	labelCheckbox: {
		fontSize: '1rem',
		margin: 0
	},
	checkbox: {
		marginTop: '1.5rem'
	},
	menuItem: {
			color: 'white !important',
			backgroundColor: 'rgba(128,65,128, 0.4) !important'
	}
})


const getCurrentPlanche = (formValues, planches) => {
	const selectedPlanches = formValues[formFields.planches]
	if (!selectedPlanches || selectedPlanches.length !== 1 || formValues[formFields.allPlanches]) {
		return
	}
	return planches[selectedPlanches[0]]
}

const Localisation = ({ campagne, formValues, assolement, isUpdate, allowToSelectAll, plancheApiFunction, classes }) => {
	const [planches, setPlanches] = React.useState({ [assolement.planche.id]: assolement.planche })

	React.useEffect(() => {
		if (!isUpdate && plancheApiFunction) {
			plancheApiFunction(campagne.id).then(planchesSet => setPlanches(planchesSet.reduce((acc, curr) => ({
				...acc,
				[curr.id]: curr
			}), {})))
		}
	}, [campagne.id])

	const planche = getCurrentPlanche(formValues, planches)

	return (
		<div className={classes.formInput}>
			{!isUpdate && <Field
				name={formFields.planches}
				label={<FormattedMessage id={'planning.cultureForm.fertilization.label.planche'}/>}
				component={Select}
				disabled={formValues[formFields.allPlanches]}
				multiple
			>
				{Object.keys(planches).map(id => (
						<MenuItem key={id} value={id} classes={{ selected: classes.menuItem }}>
							{planches[id].nom}
						</MenuItem>
					)
				)}
			</Field>}
			{planche && <>
				<p><b><ZoneSurface planche={planche}/></b></p>
				<TypeSurface planche={planche}/></>}

			{allowToSelectAll && !isUpdate && <div className={classes.checkbox}>
				<Field
					name={formFields.allPlanches}
					label={<p className={classes.labelCheckbox}>
						<FormattedMessage id={'planning.cultureForm.fertilization.label.allPlanches'}/>
					</p>}
					component={Checkbox}
				/>
			</div>}

		</div>
	)
}

Localisation.propType = {
	assolement: PropTypes.object,
	plancheApiFunction: PropTypes.func,
	isUpdate: PropTypes.bool
}

const mapStateToProps = (state, { form }) => ({
	formValues: getFormValues(form)(state) || {},
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(Localisation)
