import { createSelector } from 'reselect'

const getLocalState = (state) => state.taches

export const getCurrentTache = createSelector(
	getLocalState,
	state => state.currentTache
)

export const getDetailPanelType = createSelector(
	getLocalState,
	state => state.detailPanelType
)

