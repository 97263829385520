import fetch from 'isomorphic-fetch'

const SERVER_URL =''

export function fetchUser(token) {
	return fetch(`${SERVER_URL}/auth/user`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: token
		}
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
		})
}

export function refresh(data) {
	return fetch(`${SERVER_URL}/auth/refresh`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: data.refreshToken
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			}
			return
		})
}

export function login(data) {
	return fetch(`${SERVER_URL}/auth/login-veolia`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			if (response.ok) {
				return response.json()
			} else {
				throw response
			}
		})
}

export const storeLocalUser = ({token, refreshToken}) => {
	localStorage.setItem('token', token)
	localStorage.setItem('refreshToken', refreshToken)
}

export const removeLocalUser = () => {
	localStorage.removeItem('token')
	localStorage.removeItem('refreshToken')
}
