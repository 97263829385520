import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { getStyles } from 'isotope-client'
import Paper from '@material-ui/core/Paper'

const styles = () => getStyles({
	title: {
		padding: '75px 150px',
		lineHeight: 1.4,
		fontSize: 24,
		textAlign: 'center'
	},
	container: {
		width: 630,
		margin: 'auto',
		marginTop: 180
	}
})

const AccueilBoPage = ({ classes }) => {
	return (
		<Paper className={classes.container}>
			<Typography variant="h2" classes={{ root: classes.title }}>
				<FormattedMessage id="bo.accueil.title" />
			</Typography>
		</Paper>
	)
}


export default withStyles(styles)(AccueilBoPage)
