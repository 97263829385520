import Grid from '@material-ui/core/Grid/index'
import EditAttributesIcon from '@material-ui/icons/EditAttributes'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { isInvalid, SubmissionError } from 'redux-form'
import FormButton from '../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { ACTION_ONGLET_AFFECTATION, colors } from '../../../../utils/constants'
import { LocalDate } from '../../../../utils/date/local-date'
import { DateFormat } from '../../../../utils/date/model/date'
import AffectationForm, { AFFECTATION_FORM } from '../form/AffectationForm'
import { useGestionTacheContext } from '../GestionTacheProvider'
import { affecterUsers, desaffecterUsers } from '../gestionTachesApi'
import { refreshAffectationsTable } from '../services/actions'

export const injectAffectation = (WrappedComponent) => {
	const AffectationInjector = ({ refreshAffectationsTable, ...props }) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { snackError, snackSuccess } = useSnackbar()
		const { refreshHeaderData } = useGestionTacheContext()

		const affecterDesaffecterTache = React.useCallback((jourSelection, selectedRows, users) => {
			const selectedRowsTab = Array.isArray(selectedRows) ? selectedRows : [selectedRows]
			const tachesId = selectedRowsTab.map((tache) => tache.id)

			const onSubmit = (values) => {
				const data = {
					users: (values.usersAutocomplete || []).map((user) => user.value),
					taches: tachesId
				}
				const date = LocalDate.fromString(jourSelection).format(DateFormat.SHORT_DATE_WITH_DASH)

				if (values.affectOrDesaffect === ACTION_ONGLET_AFFECTATION.AFFECTER) {
					return affecterUsers(data, date)
						.then(() => {
							refreshAffectationsTable()
							refreshHeaderData(jourSelection)
							closePanel()
							snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.affectation' })
						})
						.catch((e) => {
							snackError({ id: 'gestionTaches.ecranAffectations.snackbar.errors.affectation' })
							throw new SubmissionError(e)
						})
				} else {
					return desaffecterUsers(data, date)
						.then(() => {
							refreshAffectationsTable()
							refreshHeaderData(jourSelection)
							closePanel()
							snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.desaffectation' })
						})
						.catch((e) => {
							snackError({ id: 'gestionTaches.ecranAffectations.snackbar.errors.desaffectation' })
							throw new SubmissionError(e)
						})
				}
			}

			openPanel(
				<Grid container alignItems="center"><EditAttributesIcon style={{color: colors.primary, paddingRight: 10}}/><FormattedMessage id="gestionTaches.ecranAffectations.panels.titre.affectation" /></Grid>,
				<AffectationForm
					users={users}
					taches={selectedRows}
					onSubmit={onSubmit} />,
				[<FormButton
					type="primary"
					formName={AFFECTATION_FORM}
					getDisabledFromState={state => isInvalid(AFFECTATION_FORM)(state)}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>]
			)

		}, [closePanel, openPanel, refreshAffectationsTable, snackSuccess, snackError, refreshHeaderData])

		return <WrappedComponent {...props} affecterDesaffecterTache={affecterDesaffecterTache} />
	}

	AffectationInjector.propTypes = {
		jourSelection: PropTypes.element,
		selectedRows: PropTypes.element,
		affectOrDesaffect: PropTypes.element
	}

	const actions = {
		refreshAffectationsTable: refreshAffectationsTable
	}

	return connect(undefined, actions)(AffectationInjector)
}
