import {MenuItem, withStyles} from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Select from '../../../../../components/form/Select'
import {getStyles} from 'isotope-client'
import {Field, reduxForm} from 'redux-form'
import {FormattedDate} from 'react-intl'
import 'moment/locale/fr'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import {capitalize} from '../../../../../utils/utils'
import {resetFilter} from '../../../planning/services/toolbar/toolbarAction'
import PropTypes from 'prop-types'
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat} from "../../../../../utils/date/model/date";

const styles = () => getStyles({
	container: {
		marginBottom: 0
	},
	select: {
		paddingTop: 10,
		textTransform: 'capitalize'
	}
})

const getOptions = (semaineSelection) => {
	const options = []
	for (let i = 0; i < 7; i++) {
		options.push(LocalDate.fromString(semaineSelection).plus(i).format(DateFormat.REVERSE_SHORT_DATE))
	}
	return options
}

const JOUR_SELECT_FORM = 'JOUR_SELECT_FORM'

const JourSelect = ({ semaineSelection, classes, resetFilter, initialValues }) => {

	const { setJourSelection } = useGestionTacheContext()

	const options = React.useMemo(() => getOptions(semaineSelection), [semaineSelection])

	const onChange = React.useCallback((event, value) => {
		setJourSelection(value)
		resetFilter()
	}, [setJourSelection])

	React.useEffect(() => {
		setJourSelection(initialValues.choixJour)
	}, [initialValues])

	return (
		<Field
			component={Select}
			onChange={onChange}
			name="choixJour"
			selectClassName={classes.select}
			containerClassName={classes.container}
		>
			{options.map((jour, key) => (
				<MenuItem value={jour} key={key}>
					<FormattedDate
						value={jour}
						weekday="long"
						day="2-digit"
						month="2-digit"
						year="numeric"
					>
						{text => capitalize(text)}
					</FormattedDate>
				</MenuItem>
			))}
		</Field>
	)
}

JourSelect.propTypes = {
	classes: PropTypes.object,
	initialValues: PropTypes.object,
	resetFilter: PropTypes.func
}

const mapStateToProps = (state, {semaineSelection, jourSelection}) => {
	const options = getOptions(semaineSelection)
	const jour = LocalDate.fromString(jourSelection).format(DateFormat.REVERSE_SHORT_DATE)
	return {
		initialValues: {
			choixJour: options.includes(jour) ? jour : options[0]
		}
	}
}

const actions = {
	resetFilter
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: JOUR_SELECT_FORM,
		enableReinitialize: true
	}),
	withStyles(styles)
)(JourSelect)
