import React from 'react'
import PropTypes from 'prop-types'

const WindIcon = ({ height, width, color}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 448 448",
		}}
		viewBox="0 0 448 448"
	>
		<path
			d="M384 112c-35.297 0-64 28.711-64 64 0 8.836 7.156 16 16 16s16-7.164 16-16c0-17.649 14.359-32 32-32s32 14.351 32 32-14.359 32-32 32H17.266c-8.844 0-16 7.164-16 16s7.156 16 16 16H384c35.297 0 64-28.711 64-64s-28.703-64-64-64z"
			style={{
				fill: color,
			}}
		/>
		<path
			d="M16 176h208c35.297 0 64-28.711 64-64s-28.703-64-64-64-64 28.711-64 64c0 8.836 7.156 16 16 16s16-7.164 16-16c0-17.649 14.359-32 32-32s32 14.351 32 32-14.359 32-32 32H16c-8.844 0-16 7.164-16 16s7.156 16 16 16zM224 272H16c-8.844 0-16 7.164-16 16s7.156 16 16 16h208c17.641 0 32 14.351 32 32s-14.359 32-32 32-32-14.351-32-32c0-8.836-7.156-16-16-16s-16 7.164-16 16c0 35.289 28.703 64 64 64s64-28.711 64-64-28.703-64-64-64z"
			style={{
				fill: color,
			}}
		/>
	</svg>
)


WindIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

WindIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default WindIcon