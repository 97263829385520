import Grid from '@material-ui/core/Grid'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import FormattedMessageWithBold from "../../../components/FormattedMessageWithBold";
import {LocalDate} from "../../../utils/date/local-date";
import {DateFormat} from "../../../utils/date/model/date";

const styles = () => getStyles({
	form: {
		padding: '20px 20px 0px 20px',
		width: '100%',
		fontFamily: 'Lato'
	},
	cultures: {
		paddingTop: 15,
		paddingBottom: 15
	}
})

const PopinPlancheIndisponible = ({ classes, assolementList }) => {
	return (
			<Grid className={classes.form} container>
				<Grid container>
					<Grid item>
						<label>
							<FormattedMessageWithBold id={`tourPlaine.suiviParBloc.boardUnavailable.popin.${assolementList?.some(assolement => assolement.enCours) ? 'descriptionEnCours' : 'description'}`} values={{dateDebut: LocalDate.fromAPI(assolementList[0]?.dateDebut).format(DateFormat.SHORT_DATE), dateFin: LocalDate.fromAPI(assolementList[0]?.dateFin).format(DateFormat.SHORT_DATE)}}/>
						</label>
					</Grid>
					<Grid container className={classes.cultures} direction={"column"}>
						<ul>
							{assolementList.map((assolement) =>
								<li>
									<Grid item>
										<FormattedMessageWithBold id="tourPlaine.suiviParBloc.boardUnavailable.popin.cultureValue" values={{culture: assolement?.cultureName, dateDebut: LocalDate.fromAPI(assolement?.semisDebut).format(DateFormat.SHORT_DATE_2_DIGITS_2_MONTH), dateFin: LocalDate.fromAPI(assolement?.semisFin).format(DateFormat.SHORT_DATE_2_DIGITS_2_MONTH)}}/>
									</Grid>
								</li>
							)}
						</ul>
					</Grid>
					<Grid container>
						<FormattedMessage id={`tourPlaine.suiviParBloc.boardUnavailable.popin.${assolementList?.some(assolement => assolement.enCours) ? 'infoEnCours' : 'infoSuppression'}`}/>
					</Grid>
				</Grid>
			</Grid>
	);
}

PopinPlancheIndisponible.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PopinPlancheIndisponible);