import { ZONE_TYPE } from '../../../tool/tourPlaine.constants'
import { DAY_PERIOD, WEATHER_TILE_TYPE } from '../tool/contextePedoclimatique.constants'

/**
 * Constant for API
 */

const DAY_PERIOD_GET_API = {
	AM: 'matin',
	PM: 'apresMidi'
}

const DAY_PERIOD_API_SET = {
	AM: 'MATIN',
	PM: 'APRES_MIDI'
}

export const WEATHER_TILE_TYPE_API = {
	WEATHER: 'meteo',
	HUMIDITY: 'humiditeAir',
	WIND: 'vent',
	TEMPERATURE: 'temperature',
	SOIL: 'natureSol'
}

/**
 * Pedoclimatique
 */

/**
 * Map Api To App
 * @param values
 * @returns {*}
 */
export const contextePedoclimatiqueToApp = (values) => {
	const result = values.reduce((acc, curr) => {
		const monday = curr[0]
		const newCurrent = {
			...acc,
			[monday.date]: curr.reduce((days, currDay) => {
				const { date, ...rest } = currDay

				const getLowerCaseValue = (period, zone, type) => {
					if (rest[period][zone] && rest[period][zone][type]) {
						return rest[period][zone][type].toLowerCase()
					}
					return undefined
				}

				return ({
					...days,
					[date]: {
						[DAY_PERIOD.AM]: {
							[ZONE_TYPE.INSIDE]: {
								[WEATHER_TILE_TYPE.WEATHER]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.WEATHER),
								[WEATHER_TILE_TYPE.HUMIDITY]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.HUMIDITY),
								[WEATHER_TILE_TYPE.TEMPERATURE]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.TEMPERATURE),
								[WEATHER_TILE_TYPE.SOIL]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.SOIL)
							},
							[ZONE_TYPE.OUTSIDE]: {
								[WEATHER_TILE_TYPE.WEATHER]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.WEATHER),
								[WEATHER_TILE_TYPE.HUMIDITY]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.HUMIDITY),
								[WEATHER_TILE_TYPE.WIND]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.WIND),
								[WEATHER_TILE_TYPE.TEMPERATURE]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.TEMPERATURE),
								[WEATHER_TILE_TYPE.SOIL]: getLowerCaseValue(DAY_PERIOD_GET_API.AM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.SOIL)
							}
						},
						[DAY_PERIOD.PM]: {
							[ZONE_TYPE.INSIDE]: {
								[WEATHER_TILE_TYPE.WEATHER]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.WEATHER),
								[WEATHER_TILE_TYPE.HUMIDITY]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.HUMIDITY),
								[WEATHER_TILE_TYPE.TEMPERATURE]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.TEMPERATURE),
								[WEATHER_TILE_TYPE.SOIL]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.INSIDE, WEATHER_TILE_TYPE_API.SOIL)
							},
							[ZONE_TYPE.OUTSIDE]: {
								[WEATHER_TILE_TYPE.WEATHER]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.WEATHER),
								[WEATHER_TILE_TYPE.HUMIDITY]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.HUMIDITY),
								[WEATHER_TILE_TYPE.WIND]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.WIND),
								[WEATHER_TILE_TYPE.TEMPERATURE]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.TEMPERATURE),
								[WEATHER_TILE_TYPE.SOIL]: getLowerCaseValue(DAY_PERIOD_GET_API.PM, ZONE_TYPE.OUTSIDE, WEATHER_TILE_TYPE_API.SOIL)
							}
						}
					}
				})
			}, [])
		}
		return newCurrent
	}, {})
	return result
}

/**
 * Map Ap To Api
 */
export const contextePedoclimatiqueToApi = (values) => ({
	date: values.dayKey,
	moment: values.dayPeriod === DAY_PERIOD.AM ? DAY_PERIOD_API_SET.AM : DAY_PERIOD_API_SET.PM, // MATIN APRES_MIDI
	lieu: values.zone.toUpperCase(),
	type: values.type.toUpperCase(),
	value: values.value.toUpperCase(),
	journee: values.fullday
})