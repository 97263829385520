import React from 'react'
import { ValueListPage } from 'isotope-client'
import { VALUE_LIST_SHORTCUTS } from '../../../utils/constants'
import * as userSelectors from '../user/services/userSelectors'
import { loadListFoByShortcut } from './valueListActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const ReloadValueList = ({ match, userLanguage, loadListFoByShortcut }) => {
	React.useEffect(() => {
		return () => Object.values(VALUE_LIST_SHORTCUTS).forEach(shortcut => loadListFoByShortcut(shortcut, userLanguage))
	}, [userLanguage])

	return <ValueListPage match={match} />
}

const mapStateToProps = state => ({
	userLanguage: userSelectors.getUserLanguage(state)
})

const actions = {
	loadListFoByShortcut
}

ReloadValueList.propTypes = {
	userLanguage: PropTypes.string,
	loadListFoByShortcut: PropTypes.func
}

export default connect(mapStateToProps, actions)(ReloadValueList)
