import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectEspeceCultureValueList, injectFamilleCultureValueList, injectVarieteCultureValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'
import { joinNode } from '../../../utils/utils'

/** Composant qui permet l'affichage des informations d'une culture **/

const InfoCulture = ({
	culture,
	familleCultureList,
	especeCultureList,
	varieteCultureList
}) => {
	const familleCulture = (familleCultureList.find(famille => famille.code === culture.famille) || {}).label || DEFAULT_LABEL
	const especeCulture = (especeCultureList.find(espece => espece.code === culture.espece) || {}).label || DEFAULT_LABEL
	const cultureInfo = [familleCulture, especeCulture]

	if (culture.variete) {
		const varieteCulture = (varieteCultureList.find(variete => variete.code === culture.variete) || {}).label || DEFAULT_LABEL
		cultureInfo.push(varieteCulture)
	}

	return joinNode(cultureInfo, ' > ')
}

InfoCulture.propTypes = {
	culture: PropTypes.object,
	familleCultureList: PropTypes.array,
	especeCultureList: PropTypes.array,
	varieteCultureList: PropTypes.array
}

export default compose(
	injectFamilleCultureValueList,
	injectEspeceCultureValueList,
	injectVarieteCultureValueList
)(InfoCulture)
