import { createContext, useContext } from 'react'
import { TOUR_PLAINE_TABS } from './tool/tourPlaine.constants'
import moment from 'moment'
import { DateFormat } from '../../../utils/dateConstants'

const tourPlaineContext = {
	isLoading: false,
	// For the Tabs
	currentTab: TOUR_PLAINE_TABS.CONTEXTE_PEDOCLIMATIQUE,
	setTab: (newTab) => {},
	currentActionTab: undefined,
	setActionTab: (newTab) => {},

	// For the global selector
	setWeek: (newWeek) => {},
	currentWeek: moment().startOf('isoWeeks').format(DateFormat.YYYY_MM_DD),
	weekOptions: [],

	saveTour: () => {},

	data: {},
	saveWeatherContextePedoclimatique: (dayKey, dayPeriod, zone, type, value, fullday) => {},
	shouldDuplicateWeatherContextePedoclimatique: (dayKey, dayPeriod, type, value, fullday) => {},

	isSuiviParBlockLoading: false,
	loadSuiviBlock: () => {},
	loadSuiviBlockNotifs: (blockId) => {},
	initialBlockId: undefined,
	resetInitialBlockId:() => {},

	// Ref datas
	bioAggressors: []
}

/**
 * TourPlaineContext
 */
const TourPlaineContext = createContext(tourPlaineContext)

export default TourPlaineContext

export const useTourPlaineContext = () => useContext(TourPlaineContext)
