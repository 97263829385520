import { ClickAwayListener, Divider, ListItemIcon, MenuItem, Popper, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AccessChecker from './security/AccessChecker'

const styles = (theme) => getStyles({
	icon: {
		color: theme.palette.text.main,
		minWidth: 35
	},
	popper: {
		zIndex: 1300,
		background: 'white',
		border: '1px solid rgb(214, 214, 214)',
		padding: '1em 0'
	}
})

const MenuActions = ({
	items,
	baseElement,
	formattedMessageId,
	onClick,
	setActive,
	iconColor,
	globallyDisabled,
	children,
	event,
	setEvent,
	color,
	placement,
	withOffset,
	classes
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const menuItemClicked = (event, category, item) => {
		onClick(event, category, item, baseElement)
		event.stopPropagation()
		handleClose()
	}

	React.useEffect(() => {
		if (event) {
			handleClick(event)
		}
	}, [event])

	const handleClick = (event) => {
		if (children && children.props.onClick) {
			children.props.onClick(event)
		}
		setAnchorEl(event.currentTarget)
		if (setActive) {
			setActive(baseElement.id)
		}

		event.stopPropagation && event.stopPropagation()
	}

	const handleClose = () => {
		if (setEvent) {
			setEvent(null)
		}
		setAnchorEl(null)
	}

	let offset = "0, 0"
	if(withOffset && event){
		offset =  `${event.nativeEvent.offsetX}, 0`
	}

	return (
		<>
			{children && React.cloneElement(children, { ...children.props, onClick: handleClick })}
			<Popper
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				className={classes.popper}
				placement={placement}
				modifiers={{
					flip: {
						enabled: false,
					},
					preventOverflow: {
						enabled: true,
						boundariesElement: 'scrollParent',
					},
					offset: {
						offset: offset
					}
				}}
			>
				<ClickAwayListener
					mouseEvent="onMouseDown"
					onClickAway={handleClose}
				>
					<div>

						{Object.keys(items).map((category, index) => {
							const menuItems = items[category].map((item, indexItem) =>
								<AccessChecker access={item.access} key={`menuItem-${indexItem}`}>
									<MenuItem
										key={`${index}-${item.name}`}
										onClick={(event) => menuItemClicked(event, category, item)}
										disabled={globallyDisabled || item.isDisable}
									>

										{item.icon && <ListItemIcon className={classes.icon} style={{ color: iconColor || color }}>
											{item.icon}
										</ListItemIcon>}
										<FormattedMessage id={`${formattedMessageId}.${category}.${item.name}`} values={{ etape: item.type, value: item.value }}/>
									</MenuItem>
								</AccessChecker>
							)
							if (menuItems.length > 0 && index < Object.keys(items).length - 1) {
								menuItems.push(<Divider key={category} />)
							}
							return menuItems
						})}
					</div>
				</ClickAwayListener>
			</Popper>
		</>
	)
}

MenuActions.propType = {
	items: PropTypes.objectOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		icon: PropTypes.node.isRequired,
		action: PropTypes.any,
		access: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]).isRequired,
		isDisable: PropTypes.bool
	})),
	baseElement: PropTypes.any,
	onClick: PropTypes.func,
	setActive: PropTypes.func,
	globallyDisabled: PropTypes.bool,
	color: PropTypes.string,
	placement: PropTypes.string
}

MenuActions.defaultProps = {
	placement: 'right-start'
}


export default withStyles(styles)(MenuActions)
