import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import SectionDetail from './SectionDetail'
import classNames from 'classnames'

const styles = () => getStyles({
	detail: {
		margin: '1.5em',
		width: '100%'
	}
})

const Detail = ({ sections, classes, containerClassName }) => (
	<div className={classNames(classes.detail, containerClassName)}>
		{sections.map((section, index) => <SectionDetail key={index} {...section} />)}
	</div>
)

Detail.propType = {
	sections: PropTypes.objectOf(PropTypes.instanceOf(SectionDetail)),
	containerClassName: PropTypes.string
}

Detail.defaultProps = {
	sections: [],
	containerClassName: ''
}

export default withStyles(styles)(Detail)