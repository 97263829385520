import React from 'react'
import {ONGLET_GESTION_DES_RESSOURCES, ONGLET_GESTION_RESSOURCE} from '../../../utils/constants'
import {compose} from 'redux';
import {injectTypeContratValueList} from '../../common/valueLists/valueListInjectors';

const gestionRessourceContextType = {
	onglet: ONGLET_GESTION_RESSOURCE.RESSOURCE,
	setOnglet: (onglet) => {
	},

	ongletGestion: ONGLET_GESTION_DES_RESSOURCES.INFORMATIONS,
	setOngletGestion: (ongletGestion) => {
	},

	selectedContrats: new Set(),
	setSelectedContrats: (contrat) => {
	},

	refreshTab: null,
	setRefreshTab: () => {
	},

	selectedDisponibilite: new Set(),
	setSelectedDisponibilite: (disponibilite) => {
	},

	textInput: null,
	setTextInput: (textInput) => {
	},

	ressource: null,
	setRessource: (ressource) => {
	},
}

export const GestionRessourceContext = React.createContext(gestionRessourceContextType)

export const useGestionRessourceContext = () => React.useContext(GestionRessourceContext)

const GestionRessourceProvider = ({ children, typeContratValueList }) => {

	const [onglet, setOnglet] = React.useState(ONGLET_GESTION_RESSOURCE.RESSOURCE)
	const [ongletGestion, setOngletGestion] = React.useState(ONGLET_GESTION_DES_RESSOURCES.INFORMATIONS)
	const [textInput, setTextInput] = React.useState();
	const [ressource, setRessource] = React.useState();
	const [refreshTab, setRefreshTab] = React.useState(Math.random());
	const [selectedContrats, setSelectedContrats] = React.useState(new Set([...typeContratValueList]?.map(contrat => contrat?.label)));
	const [selectedDisponibilite, setSelectedDisponibilite] = React.useState(new Set(['DISPONIBLE', 'INDISPONIBLE']));

	const handleOngletGestionChange = React.useCallback(newOnglet => {
		setOngletGestion(newOnglet)
	}, [])

	const handleUpdateTab = React.useCallback(value => {
		setRefreshTab(value)
	}, [])

	React.useEffect(() => {
		setSelectedContrats(new Set([...typeContratValueList]?.map(contrat => contrat?.label)))
	}, [typeContratValueList])

	return (
		<GestionRessourceContext.Provider value={{
			onglet,
			setTextInput,
			textInput,
			ressource,
			setRessource,
			ongletGestion,
			setOngletGestion : handleOngletGestionChange,
			setRefreshTab: handleUpdateTab,
			refreshTab,
			setSelectedContrats,
			setSelectedDisponibilite,
			selectedDisponibilite,
			selectedContrats,
		}}>
			{children}
		</GestionRessourceContext.Provider>
	)
}


export default compose(injectTypeContratValueList)(GestionRessourceProvider)
