import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectTypeTacheValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

const styles = () => getStyles({
	infoInPanel: {
		maxWidth: 240,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	tableLabel: {
		cursor: 'pointer'
	},
	test:{
		tooltip:{
			fontSize: 40
		}
	}
})

/** Composant qui permet l'affichage des informations d'une tache dans une table **/

const InfoTache = ({
	tache,
	typeTacheList,
	inTable,
	classes
}) => {
	const typeTache = (typeTacheList.find(famille => famille.code === tache.type) || {}).label || DEFAULT_LABEL
	const nomCulture = typeof tache.culture === 'string' ? tache.culture : (tache && tache.culture) && tache.culture.nom

	return (
		<Grid className={!inTable && classes.infoInPanel}>
			{inTable
				? <label className={classes.tableLabel}><b>{typeTache}</b>{nomCulture ? ` - ${nomCulture}` : ''}</label>

				: <Tooltip TransitionComponent={Zoom} className={classes.test} arrow title={`${typeTache}${nomCulture ? ` - ${nomCulture}` : ''}`} placement="bottom">
					<label><b>{typeTache}</b>{nomCulture ? ` - ${nomCulture}` : ''}</label>
				</Tooltip>
			}
		</Grid>
	)
}

InfoTache.propTypes = {
	tache: PropTypes.object,
	typeTacheList: PropTypes.array,
	inTable: PropTypes.bool,
	classes: PropTypes.object
}

export default compose(
	withStyles(styles),
	injectTypeTacheValueList
)(InfoTache)
