import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/Button'
import { colors } from '../../../../utils/constants'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { getPreferences } from '../../../common/user/services/userSelectors'
import PropTypes from 'prop-types'
import { getDemandeModificationDate } from '../../besoinsProduction/service/besoinsProductionApi'
import { setActiveCampagne } from '../../../common/campagne/campagneAction'


const styles = () => getStyles({
	root: {
		zIndex: 100,
		paddingTop: 20
	},
	container: {
		fontFamily: 'Lato',
		fontStyle: 'italic',
		textAlign: 'left',
		fontSize: 14,
		padding: 0
	},
	actionBtn: {
		borderRadius: 15,
		minWidth: 50,
		width: '45%',
		height: 108,
		borderColor: colors.lightPrimary,
		color: colors.text,
		fontWeight: 400,
		fontSize: 15
	},
	buttonContent: {
		padding: '10px',
		height: '100%',
		textAlign: 'left'
	},
	icon: {
		color: colors.primary,
		height: 40,
		width: 40
	},
	text: {
		paddingBottom: 15
	},
	besoinDate: {
		fontStyle: 'italic'
	}
})

const BesoinsBloc = ({ classes, goToPage, idFerme, setActiveCampagne }) => {
	const [campagnes, setCampagnes] = useState()

	useEffect(() => {
		getDemandeModificationDate(idFerme).then((campagneList) => setCampagnes(campagneList))
	}, [])

	const ButtonBesoins = ({ campagne, campagneDate }) => {
		return (
			<Button
				type="secondary"
				variant="text"
				keepCase
				withBorder={true}
				className={classes.actionBtn}
				onClick={() => {
					setActiveCampagne(campagne)
					goToPage('/besoins-production')
				}}
			>
				<Grid container direction={'column'} className={classes.buttonContent}>
					<Typography variant="h5">
						{campagne?.nom || <FormattedMessage id="tdb.blocs.production.vide" />}
					</Typography>
					<Typography variant="body1">
						<FormattedMessage id={`tdb.blocs.production.campagnes.${campagne?.finished ? 'cloture' : 'cours'}`} />
					</Typography>
					<Typography variant="body2">
						<FormattedMessage id="tdb.blocs.production.maj" />
					</Typography>
					<Typography className={classes.besoinDate} variant="body2">
						{campagneDate}
					</Typography>
				</Grid>
			</Button>
		)
	}

	return (
		<Box container className={classes.root}>
			<Grid container justify={'flex-start'} alignItems={'center'} direction={'row'} className={classes.container}>
				<Typography className={classes.text} variant="p">
					<FormattedMessage id="tdb.blocs.production.description" />
				</Typography>
				<Grid container justify={'space-around'} alignItems={'center'} direction={'row'}>
					{(campagnes?.campagnePrev?.id && campagnes?.campagneActu?.id) ? (campagnes?.campagnePrev?.dateDebut > campagnes?.campagneActu?.dateDebut ?
							<>
								<ButtonBesoins campagne={campagnes?.campagneActu} campagneDate={campagnes?.dateCampagneActuMaj} />
								<ButtonBesoins campagne={campagnes?.campagnePrev} campagneDate={campagnes?.dateCampagnePrevMaj} />
							</>
							:
							<>
								<ButtonBesoins campagne={campagnes?.campagnePrev} campagneDate={campagnes?.dateCampagnePrevMaj} />
								<ButtonBesoins campagne={campagnes?.campagneActu} campagneDate={campagnes?.dateCampagneActuMaj} />
							</>) :
						<>
							<ButtonBesoins campagne={campagnes?.campagneActu?.id ? campagnes?.campagneActu : campagnes?.campagnePrev} campagneDate={campagnes?.dateCampagneActuMaj ? campagnes?.dateCampagneActuMaj : campagnes?.dateCampagnePrevMaj} />
						</>
					}

				</Grid>
			</Grid>
		</Box>
	)
}

BesoinsBloc.propTypes = {
	idFerme: PropTypes.string
}

const mapStateToProps = (state) => ({
	idFerme: (getPreferences(state).FERME_ID || '0')
})

const actions = {
	goToPage: (url) => dispatch => dispatch(push(url)),
	setActiveCampagne
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles))(BesoinsBloc)