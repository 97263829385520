import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, { BO_FILTER_FORM } from '../BackOfficeContainer'
import {createMateriel, createReglage, getMateriels, updateMateriel, updateReglage} from './services/reglagesMaterielsApi'
import { getPreferences } from '../../common/user/services/userSelectors'
import { connect } from 'react-redux'
import BoListView from '../components/BoListView'
import BuildIcon from '@material-ui/icons/Build';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import NoResultPage from '../../../components/NoResultPage'
import {getMaterielForInitValues, getReglageForInitValues} from './reglagesMaterielsUtils'
import { formValueSelector, reset, SubmissionError, change } from 'redux-form'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'
import FormButton from '../../../components/form/FormButton'
import { TYPE_DIALOG } from '../../../utils/constants'
import { DialogContext } from '../../../components/layout/dialog'
import { FIELDS } from './components/AddReglageMaterielForm'
import Loader from '../../../components/layout/Loader'
import { compose } from 'redux'
import { injectFermeList } from '../ferme/services/fermeListInjector'
import MaterielForm from "./components/materielForm";
import ReglageForm from "./components/reglageForm";
import AddReglageMaterielForm from "./components/AddReglageMaterielForm";

const REGLAGE_MATERIEL_FORM = 'REGLAGE_MATERIEL_FORM'
const ADD_REGLAGE_MATERIEL_FORM = 'ADD_REGLAGE_MATERIEL_FORM'

const ReglagesMaterielsPage = ({ idFerme, reset, fermeList, change }) => {
	const intl = useIntl()
	const INIT_FILTERS = { idFerme }
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [materiels, setMateriels] = React.useState([])
	const [selection, setSelection] = React.useState(undefined)
	const [loading, setLoading] = React.useState(false)
	const [refresh, setRefresh] = React.useState(false)
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)

	React.useEffect(() => {
		setInitialisation()
		setSelection(undefined)
	}, [filters])

	React.useEffect(() => {
		setInitialisation()
	}, [refresh])

	const prodMinoFerme = useMemo(() => {
		const ferme = fermeList.find(ferme => ferme.id === filters.idFerme);
		return ferme ? ferme.prodMino : null;
	}, [filters.idFerme, fermeList]);

	const setInitialisation = () => {
		setLoading(true)
		getMateriels(filters)
			.then((materielList) =>
				setMateriels(materielList.map(materiel => formatTuileMateriel(materiel)))
			)
			.finally(() => setLoading(false))
	}

	const formatTuileMateriel = (materiel) => ({
		uniqueId: `materiel-${materiel.id}`,
		icon: BuildIcon,
		title: materiel.nom,
		data: materiel,
		children: materiel.reglages.map(reglage => formatTuileReglage(reglage, materiel))
	})

	const formatTuileReglage = (reglage) => ({
		uniqueId: `reglage-${reglage.id}`,
		icon: SquareFootIcon,
		title: `${intl.formatMessage({ id: 'bo.reglagesMateriels.reglage' })} ${reglage.nom}`,
		data: {
			...reglage,
			isReglage: true,
		},
		children: []
	})

	const getTitleSelection = (selection) => {
		const dataSelection = selection.data
		return dataSelection.isReglage ? selection.title : selection.title
	}

	// Formattage des values pour materiel / reglage
	const initialValues = React.useMemo(() => {
		if (selection && selection.data) {
			return selection.data.isReglage ? getReglageForInitValues(selection.data) : getMaterielForInitValues(selection.data)
		}
		return {}
	}, [selection])

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.reglagesMateriels.popin.title" />,
			<AddReglageMaterielForm
				formName={ADD_REGLAGE_MATERIEL_FORM}
				onSubmit={handleCreation}
				idFerme={filters.idFerme}
				prodMinoFerme={prodMinoFerme}
			/>,
			[
				<FormButton
					type="primary"
					formName={ADD_REGLAGE_MATERIEL_FORM}
					getDisabledFromState={state => !formValueSelector(ADD_REGLAGE_MATERIEL_FORM)(state, FIELDS.TYPE_AJOUT.name)}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const handleUpdateReglage = (values) =>
		updateReglage(selection.data.idMateriel, values)
			.then((newReglage) => {
				snackSuccess({ id: 'bo.reglagesMateriels.snackbar.updateReglage' })
				setRefresh(!refresh)
				setSelection(formatTuileReglage(newReglage))
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	const handleCreation = (values) => {
		const isCreationReglage = values.typeAjout === 'reglage'
		const promise = isCreationReglage ? createReglage(values) : createMateriel(filters.idFerme, values)

		return promise
			.then(() => {
				snackSuccess({ id: `bo.reglagesMateriels.snackbar.add${isCreationReglage ? 'Reglage' : 'Materiel'}` })
				setSelection(undefined)
				reset(BO_FILTER_FORM)
				// Gestion du filter ferme après reset
				change(BO_FILTER_FORM, 'idFerme', filters.idFerme)
				setFilters({
					...INIT_FILTERS,
					idFerme: filters.idFerme
				})
				closeDialog()
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})
	}

	const handleUpdateMateriel = (values) =>
		updateMateriel(filters.idFerme, values)
			.then((newMateriel) => {
				snackSuccess({ id: 'bo.reglagesMateriels.snackbar.updateMateriel' })
				setRefresh(!refresh)
				setSelection(formatTuileMateriel(newMateriel))
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.reglagesMateriels.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={openPopinCreation}><FormattedMessage id="bo.reglagesMateriels.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer inputLabel="bo.reglagesMateriels.inputQuery" onSubmit={setFilters}>
			{loading ?
				<Loader fromBo />
				:
				materiels.length > 0 ?
					<BoListView
						titleLeft={intl.formatMessage({ id: 'bo.reglagesMateriels.titleLeft' })}
						titleRight={selection ? getTitleSelection(selection) : ''}
						tuileList={materiels}
						selection={selection}
						handleSelection={setSelection}
						formName={REGLAGE_MATERIEL_FORM}
					>
						{selection && selection.data && <>
							{selection.data.isReglage ?
								<ReglageForm
									formName={REGLAGE_MATERIEL_FORM}
									onSubmit={handleUpdateReglage}
									initialValues={initialValues}
									idFerme={idFerme}
								/>
								:
								<MaterielForm
									formName={REGLAGE_MATERIEL_FORM}
									onSubmit={handleUpdateMateriel}
									initialValues={initialValues}
									selection={selection}
									isReglable={selection?.data?.reglable}
								/>
							}
						</>}
					</BoListView>
					: <NoResultPage />
			}
		</BackOfficeContainer>
	</>)
}

ReglagesMaterielsPage.propTypes = {
	idFerme: PropTypes.string,
	reset: PropTypes.func,
	fermeList: PropTypes.array
}

const mapStateToProps = (state) => ({
	idFerme: (getPreferences(state).FERME_ID || '0')
})

const actions = {
	reset,
	change
}

export default compose(
	connect(mapStateToProps, actions),
	injectFermeList
)(ReglagesMaterielsPage)

