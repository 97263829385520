import {Box, Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React, {useEffect, useState} from 'react'
import {compose} from "redux";
import {FormattedMessage} from 'react-intl'
import {push} from 'connected-react-router'
import {connect} from "react-redux";
import {getNotes, updateNote} from "../../tourPlaine/service/tourPlaineApi";
import Checkbox from "@material-ui/core/Checkbox";
import {useSnackbar} from "../../../../components/layout/snackbar/SnackbarContext";
import {colors} from "../../../../utils/constants";

const styles = () => getStyles({
	root: {
		zIndex: 100,
		paddingTop: 15
	},
	container: {
		fontFamily: 'Lato',
		textAlign: 'left',
		fontSize: 14,
		padding: 0,
	},
	text: {
		fontStyle: "italic"
	},
	textHeader: {
		fontStyle: "italic",
		paddingBottom: 5
	},
	listContainer: {
		overflowY: 'auto',
		height: 220,
	},
	noteContainer: {
		paddingTop: 10,
	},
	infosContainer : {
		paddingBottom: 10,
		borderBottom: `1px solid ${colors.borderPlan}`,
	}
})

const CommentaireBloc = ({ classes, goToPage, setRefreshNote, refreshNote }) => {
	const [noteList, setNoteList] = useState([])
	const [isHistorique, setIsHistorique] = useState(false)
	const {snackError} = useSnackbar()

	useEffect(() => {
		getNotes()
			.then((notes) => setNoteList(notes.filter((note) => note.vue === isHistorique)))
	}, [refreshNote, isHistorique])

	const updateNoteToSeen = (noteId, isSeen) => {
		updateNote(noteId, isSeen)
			.then(setRefreshNote(!refreshNote))
			.catch(() => snackError({id: 'snackbar.error.exportProduction'}))
	}

	return(
	<Box container className={classes.root}>
		<Grid container justify={"flex-start"} alignItems={"center"} direction={"row"} className={classes.container}>
			<Typography className={classes.text} variant="p">
				<FormattedMessage id="tdb.blocs.commentaires.description"/>
			</Typography>
			<Grid container className={classes.listContainer} justify={"flex-start"} alignContent={"flex-start"} direction={"row"}>
				{noteList.map((note) =>
					<Grid container direction={"row"}  className={classes.noteContainer} key={note.id}>
						<Grid xs={12} md={1} lg={1} container alignItems={"flex-start"} justify={"flex-start"}>
							<Checkbox checked={note.vue} key={note.id} onClick={() => updateNoteToSeen(note.id, !note.vue)} style={{padding: 0}}/>
						</Grid>
						<Grid xs={12} md={11} lg={11} container direction={"column"} className={classes.infosContainer}>
							<Typography className={classes.textHeader} variant="p">
								{note.dateCreation} - <b>{note.userCreation.firstname} {note.userCreation.lastname}</b>
							</Typography>
							<Typography variant="body2">
								<b>{note.provenance}</b>
							</Typography>
							<Typography variant="p">
								{note.texte}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid container direction={"row"} alignItems={'center'}>
				<Checkbox checked={isHistorique} onClick={() => setIsHistorique(!isHistorique)} style={{
					paddingLeft: 0,
					color: colors.text,
					'&.Mui-checked': {
						color: colors.text,
					},
				}}/>
				<Typography variant="p">
					<b><FormattedMessage id="tdb.blocs.commentaires.historique"/></b>
				</Typography>
			</Grid>
		</Grid>
	</Box>
)}

const actions = {
	goToPage: (url) => dispatch => dispatch(push(url))
}

export default compose(
	connect(null, actions),
	withStyles(styles))(CommentaireBloc)