import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Grid, withStyles } from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, { BO_FILTER_FORM } from '../BackOfficeContainer'
import BoListView from '../components/BoListView'
import GroupeCultureForm from './components/GroupeCultureForm'
import NoResultPage from '../../../components/NoResultPage'
import { createGroupe, createGroupeAssolement, deleteGroupe, deleteGroupeAssolement, getGroupeList, updateGroupe, updateGroupeAssolement } from './services/groupeApi'
import CultureGroupIcon from '../../../components/icon/CultureGroupIcon'
import { connect } from 'react-redux'
import { getPreferences } from '../../common/user/services/userSelectors'
import PropTypes from 'prop-types'
import ItineraireTechniqueUniIcon from '../../../components/icon/ItineraireTechniqueUniIcon'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'
import { TYPE_DIALOG } from '../../../utils/constants'
import { DialogContext } from '../../../components/layout/dialog'
import FormButton from '../../../components/form/FormButton'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import ItkForm from '../cultureItk/components/ItkForm'
import { getAssolementForInitValues } from '../cultureItk/cultureItkUtils'
import InfoCulture from '../../common/information/InfoCulture'
import { DeleteForever } from '@material-ui/icons'
import AddGroupeCultureForm, { FIELDS } from './components/AddGroupeCultureForm'
import { localCompare } from '../../../utils/utils'
import { formValueSelector, reset, SubmissionError, change } from 'redux-form'
import Loader from '../../../components/layout/Loader'

const FORM_NAME_CREATION = 'CreationGroupeCultureForm'
const FORM_NAME_UPDATE = 'UpdateGroupeCultureForm'
const PREFIXE_GROUPE = 'groupe-'
const PREFIXE_ITK = 'itk-'

const styles = () => getStyles({
	popinText: {
		padding: 20
	},
	blocButton: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: 20
	},
	button: {
		padding: '0 20px 0 20px'
	}
})

const formatTuiles = (groupes) =>
	groupes.map(groupe => ({
		uniqueId: `${PREFIXE_GROUPE}${groupe.id}`,
		icon: CultureGroupIcon,
		title: groupe.nom,
		description: groupe.description,
		data: groupe,
		children: groupe.groupesAssolement && groupe.groupesAssolement.map(assolement => formatTuileAssolement(assolement, groupe))
	}))

const formatTuileAssolement = (assolement, groupe) => ({
	uniqueId: `${PREFIXE_ITK}${assolement.id}`,
	icon: ItineraireTechniqueUniIcon,
	title: assolement.culture.nom,
	description: <InfoCulture culture={assolement.culture} />,
	data: assolement,
	idGroup: groupe.id,
	typeSurface: groupe.typeSurface
})

const GroupeCulturePage = ({
	idFerme,
	reset,
	change,
	classes
}) => {
	const INIT_FILTERS = { idFerme }
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [selection, setSelection] = React.useState(undefined)
	const [groupes, setGroupes] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)

	React.useEffect(() => {
		setLoading(true)
		getGroupeList(filters)
			.then((groupes) =>
				setGroupes(formatTuiles(groupes))
			)
			.finally(() => setLoading(false))
	}, [filters])

	const handleUpdateGroupe = (values) => {
		return updateGroupe(values)
			.then(groupe => {
				snackSuccess({ id: 'bo.groupeCulture.snackbar.update.successGroupe' })
				const formattedGroupe = formatTuiles([groupe])[0]
				setGroupes(state => {
					const newGroupes = [...state]
					const indexGroupe = newGroupes.findIndex(groupe => groupe.data.id === values.id)
					if (indexGroupe !== -1) {
						newGroupes[indexGroupe] = formattedGroupe
						newGroupes.sort(localCompare('title'))
					}
					return newGroupes
				})
				setSelection(formattedGroupe)
			})
			.catch((e) => {
				snackError({ id: 'bo.groupeCulture.snackbar.update.error' })
				throw new SubmissionError(e)
			})
	}

	const handleDeleteGroupe = (idGroup) => {
		return deleteGroupe(idGroup)
			.then(() => {
				snackSuccess({ id: 'bo.groupeCulture.snackbar.delete.success' })
				closeDialog()
				setGroupes(state => {
					const newGroupes = [...state]
					const indexGroupe = newGroupes.findIndex(groupe => groupe.data.id === idGroup)
					if (indexGroupe !== -1) {
						newGroupes.splice(indexGroupe, 1)
					}
					return newGroupes
				})
				setSelection(undefined)
			})
			.catch(() => snackError({ id: 'bo.groupeCulture.snackbar.delete.error' }))
	}

	const handleDeleteAssolement = (idAssolement, idGroup) => {
		return deleteGroupeAssolement(idAssolement)
			.then(() => {
				snackSuccess({ id: 'bo.groupeCulture.snackbar.delete.success' })
				setGroupes(state => {
					const newGroupes = [...state]
					const indexGroupe = newGroupes.findIndex(groupe => groupe.data.id === idGroup)
					if (indexGroupe !== -1) {
						const indexAssolement = newGroupes[indexGroupe].children.findIndex(child => child.data.id === idAssolement)
						const newChild = [...newGroupes[indexGroupe].children]
						newChild.splice(indexAssolement, 1)
						newGroupes[indexGroupe].children = newChild
					}
					return newGroupes
				})
				setSelection(undefined)
			})
			.catch(() => snackError({ id: 'bo.groupeCulture.snackbar.delete.error' }))
	}

	const openPopinDelete = (id, nom) => {
		openDialog(
			<FormattedMessage id="bo.groupeCulture.popin.delete.title" />,
			<FormattedMessage id="bo.groupeCulture.popin.delete.text" values={{ nom }}>
				{text => <div className={classes.popinText}>{text}</div>}
			</FormattedMessage>,
			[
				<Button
					type="primary"
					onClick={() => handleDeleteGroupe(id)}
				>
					<FormattedMessage id="actions.delete" />
				</Button>
			], TYPE_DIALOG.ACTION
		)
	}

	const handleCreation = values => {
		const isCreationGroupe = values.typeAjout === 'groupe'
		const promise = isCreationGroupe ? createGroupe(values, filters.idFerme) : createGroupeAssolement(values)

		return promise
			.then(() => {
				closeDialog()
				snackSuccess({ id: `bo.groupeCulture.snackbar.creation.success${isCreationGroupe ? 'Groupe' : 'Assolement'}` })
				setSelection(undefined)
				reset(BO_FILTER_FORM)
				// Gestion du filter ferme après reset
				change(BO_FILTER_FORM, 'idFerme', filters.idFerme)
				setFilters({
					...INIT_FILTERS,
					idFerme: filters.idFerme
				})

			})
			.catch((e) => {
				snackError({ id: 'bo.groupeCulture.snackbar.creation.error' })
				throw new SubmissionError(e)
			})
	}

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.groupeCulture.popin.creation.title" />,
			<AddGroupeCultureForm
				formName={FORM_NAME_CREATION}
				onSubmit={handleCreation}
				groupeList={groupes.map(groupe => ({ label: groupe.data.nom, code: groupe.data.id, typeSurface: groupe.data.typeSurface }))}
			/>,
			[
				<FormButton
					type="primary"
					formName={FORM_NAME_CREATION}
					getDisabledFromState={state => !formValueSelector(FORM_NAME_CREATION)(state, FIELDS.TYPE_AJOUT.name)}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const handleUpdateAssolement = (values, idGroup) =>
		updateGroupeAssolement({
			...values,
			idGroup
		})
			.then(newAssolement => {
				snackSuccess({ id: 'bo.groupeCulture.snackbar.update.successAssolement' })
				setGroupes(state => {
					const newGroupes = [...state]
					const indexGroupe = newGroupes.findIndex(groupe => groupe.data.id === values.idGroup || groupe.data.groupesAssolement.some(assolement => assolement.id === values.id))
					if (indexGroupe !== -1) {
						const indexAssolement = newGroupes[indexGroupe].children.findIndex(child => child.data.id === values.id)
						newGroupes[indexGroupe].children[indexAssolement] = formatTuileAssolement(newAssolement, newGroupes[indexGroupe].data)
					}
					return newGroupes
				})
				setSelection(state => ({
					...formatTuileAssolement(newAssolement, {}),
					idGroup: state.idGroup,
					typeSurface: state.typeSurface
				}))
			})
			.catch((e) => {
				snackError({ id: 'bo.groupeCulture.snackbar.update.error' })
				throw new SubmissionError(e)
			})

	return (
		<>
			<Grid container justify="space-between">
				<Typography variant="h2">
					<FormattedMessage id="bo.groupeCulture.title" />
				</Typography>
				<Button type="secondary" keepCase onClick={openPopinCreation}>
					<FormattedMessage id="bo.groupeCulture.newBtn" />
				</Button>
			</Grid>
			<BackOfficeContainer
				inputLabel="bo.groupeCulture.inputQuery"
				onSubmit={values => {
					setFilters(values)
					setSelection(undefined)
				}}
			>
				{loading ?
					<Loader fromBo />
					: groupes.length > 0 ?
						<BoListView
							titleLeft={<FormattedMessage id="bo.groupeCulture.titleLeft" />}
							titleRight={selection && selection.data ? selection.title : ''}
							tuileList={groupes}
							selection={selection}
							handleSelection={setSelection}
							formName={FORM_NAME_UPDATE}
						>
							{
								selection && selection.data && selection.uniqueId &&
								(selection.uniqueId.includes(PREFIXE_GROUPE) ?
										<GroupeCultureForm
											initialValues={{ ...selection.data, zone: selection.data.zone.split(';'), surface: selection.data.typeSurface }}
											onSubmit={handleUpdateGroupe}
											deleteGroupe={() => openPopinDelete(selection.data.id, selection.data.nom)}
											idGroupe={selection.data.id}
											formName={FORM_NAME_UPDATE}
										/>
										:
										<>
											<ItkForm
												formName={FORM_NAME_UPDATE}
												initialValues={getAssolementForInitValues(selection.data, selection.typeSurface)}
												onSubmit={(values) => handleUpdateAssolement(values, selection.idGroup)}
												fromGroupeCulture
											/>
											<Grid item className={classes.blocButton}>
												<Button
													type="secondary"
													onClick={() => handleDeleteAssolement(selection.data.id, selection.idGroup)}
													startIcon={<DeleteForever color="currentColor" />}
													className={classes.button}
												>
													<FormattedMessage id="bo.groupeCulture.deleteButton.assolement" />
												</Button>
											</Grid>
										</>
								)
							}
						</BoListView>
						: <NoResultPage />
				}
			</BackOfficeContainer>
		</>
	)
}

GroupeCulturePage.propTypes = {
	idFerme: PropTypes.string,
	reset: PropTypes.func,
	change: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	idFerme: getPreferences(state).FERME_ID
})

const actions = {
	reset,
	change
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(GroupeCulturePage)
