import { DeleteForever, Room, SettingsEthernet } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import CommentaireIcon from '../../../../../components/icon/CommentaireIcon'
import MenuActions from '../../../../../components/MenuActions'
import { PROFILS, ETAPE_ETAT, ETAPE_TYPE } from '../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../utils/utils'
import ItineraireTechniqueUniIcon from '../../../../../components/icon/ItineraireTechniqueUniIcon'
import DetailEtapeIcon from '../../../../../components/icon/DetailEtapeIcon'
import { injectDeleteEtape } from '../../services/actions/form/deleteEtapeInjector'
import { injectSuiviEtape } from '../../services/actions/form/suiviEtapeInjector'
import TypeEtape from '../../../../common/information/TypeEtape'
import { openEtapeDetail } from '../../services/actions/detail/openEtapeDetailInjector'

const ACCESS_MARAICHER = getAccessCheckerRole(PROFILS.MARAICHER)
const ACCESS_CHEF = getAccessCheckerRole(PROFILS.CHEF)


const getItems = (assolement, etape, campagne) => {
	const items = {}
	const isTaskDone = etape.etat === ETAPE_ETAT.TERMINE
	const isTaskDoing = etape.etat === ETAPE_ETAT.EN_COURS
	const taskDoneList = assolement.assolementEtapeList
		.filter(task => task.etat === ETAPE_ETAT.TERMINE || task.etat === ETAPE_ETAT.EN_COURS)
	if (assolement.culture && taskDoneList.length === 0 && !campagne.preview) {
		items['primary'] = [{
			name: 'itineraireTechnique',
			icon: <ItineraireTechniqueUniIcon color="currentColor" />,
			action: 'move',
			access: ACCESS_CHEF
		}]
	}
	if(etape.type === ETAPE_TYPE.NM_DUREE_ASSOLEMENT || etape.type === ETAPE_TYPE.NM_DUREE_ASSOLEMENT_SELECTED){
		return items
	}
	const etapeType = <TypeEtape typeEtape={etape.type} />

	items.secondary = [{
		name: `detail`,
		icon: <DetailEtapeIcon color="currentColor" height="1.2rem" />,
		action: 'detail',
		access: ACCESS_MARAICHER,
		type: etapeType
	}]


	if (!isTaskDone && !isTaskDoing && !campagne.preview) {
		items.secondary.push({
			name: `move`,
			icon: <Room />,
			action: 'move',
			access: ACCESS_CHEF,
			type: etapeType
		})
	}

	if (etape.type === ETAPE_TYPE.RECOLTE && !isTaskDone && !campagne.preview) {
		items.secondary.push({
			name: `adjust`,
			icon: <SettingsEthernet width="1em" height="1em" />,
			action: 'adjust',
			access: ACCESS_CHEF,
			type: etapeType
		})
	}
	if (!campagne.preview){
		items.secondary.push({
			name: `suivi`,
			icon: <CommentaireIcon color="currentColor" height="1.3rem" />,
			action: 'suivi',
			access: ACCESS_MARAICHER,
			type: etapeType
		})
	}

	const cantDelete = new Set([ETAPE_TYPE.SEMI_EN_CONTENANT, ETAPE_TYPE.SEMI_DIRECT])
	if (!isTaskDone && !cantDelete.has(etape.type) && !campagne.preview) {
		items.secondary = [...(items.secondary || []), {
			name: `delete`,
			icon: <DeleteForever />,
			action: 'delete',
			access: ACCESS_CHEF,
			type: etapeType
		}]
	}
	return items
}

const longTasks = new Set([ETAPE_TYPE.NM_DUREE_ASSOLEMENT_SELECTED, ETAPE_TYPE.NM_DUREE_ASSOLEMENT, ETAPE_TYPE.RECOLTE])


const ContextMenuActions = ({
	campagne,
	event,
	setEvent,
	etape,
	assolement,
	openEtapeDetail,
	openEtapeDelete,
	openEtapeSuivi,
	setEnableMovingRow,
	setEnableMovingTask,
	setEnableResizingTask
}) => {
	const items = getItems(assolement, etape, campagne)
	if ((!items.primary || !items.primary.length) && (!items.secondary || !items.secondary.length)) {
		return null
	}

	const onClick = (event, category, item) => {
		switch (item.action) {
			case 'move':
				category === 'primary' ? setEnableMovingRow() : setEnableMovingTask()
				break
			case 'detail':
				openEtapeDetail(assolement, etape.id)
				break
			case 'adjust':
				setEnableResizingTask()
				break
			case 'delete':
				openEtapeDelete(etape)
				break
			case 'suivi':
				openEtapeSuivi(assolement, etape, () => openEtapeDetail(assolement, etape.id))
				break
			default:
				break
		}
	}
	return (
		<MenuActions
			items={items}
			formattedMessageId="planning.contextMenu"
			onClick={onClick}
			event={event}
			setEvent={setEvent}
			placement="bottom-start"
			withOffset={longTasks.has(etape.type)}
		/>
	)
}

ContextMenuActions.propType = {
	event: PropTypes.object.isRequired,
	campagne: PropTypes.object.isRequired,
	setEvent: PropTypes.func.isRequired,
	etape: PropTypes.object.isRequired,
	assolement: PropTypes.object.isRequired,
	setEnableMovingRow: PropTypes.func.isRequired,
	setEnableMovingTask: PropTypes.func.isRequired,
	setEnableResizingTask: PropTypes.func.isRequired
}

export default compose(
	openEtapeDetail,
	injectDeleteEtape,
	injectSuiviEtape
)(ContextMenuActions)
