import React from 'react'
import PropTypes from 'prop-types'

const PlanOneIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#9bd069"
			d="M.245 40.625c-1.836-11.537 6.866-22.073 18.543-22.449 40.255-1.297 129.226 2.323 190.367 52.825 23.376 19.308 33.564 41.653 37.274 61.781 10.628 57.659-31.998 109.287-90.644 109.74-20.467.158-44.334-5.626-67.71-24.934C26.934 167.086 6.575 80.4.245 40.625z"
		/>
		<path
			fill="#7dbe3b"
			d="M246.429 132.782c-3.71-20.129-13.898-42.473-37.274-61.781C148.014 20.499 59.043 16.879 18.788 18.176 7.112 18.552-1.591 29.088.245 40.625c.415 2.61.891 5.421 1.435 8.411 43.592-.301 123.977 6.294 180.541 53.016 23.376 19.308 33.564 41.653 37.274 61.781 4.062 22.038.345 43.194-9.073 60.949 27.525-20.046 42.87-54.765 36.007-92z"
		/>
		<path
			fill="#9bd069"
			d="M511.521 106.718c2.324-9.336-4.069-18.598-13.623-19.734-32.938-3.918-106.196-7.255-159.943 29.848-20.549 14.185-30.492 31.797-34.963 48.05-12.808 46.556 18.507 91.939 66.595 96.468 16.782 1.581 36.775-1.473 57.324-15.658 53.747-37.103 76.597-106.786 84.61-138.974z"
		/>
		<path
			fill="#7dbe3b"
			d="M497.898 86.984a326.423 326.423 0 0 0-15.824-1.487c-9.323 34.329-32.557 97.686-82.911 132.446-20.549 14.185-40.542 17.239-57.324 15.658-11.687-1.101-22.382-4.615-31.695-9.972 11.778 20.488 33.092 35.238 59.443 37.72 16.782 1.581 36.775-1.473 57.324-15.658 53.747-37.103 76.597-106.786 84.61-138.973 2.324-9.336-4.069-18.597-13.623-19.734z"
		/>
		<path
			fill="#7dbe3b"
			d="M101.316 106.352c.526.337 52.963 34.013 81.26 65.035 45.013 49.349 69.779 113.798 69.736 181.476l-.052 85.188h15.544l-.038-63.182c-.034-55.397 20.235-108.148 57.076-148.537 24.85-27.244 55.062-49.216 89.798-65.308a7.726 7.726 0 1 0-6.495-14.021c-36.61 16.959-68.478 40.146-94.719 68.915-22.935 25.144-39.859 54.759-50.012 86.789-9.256-52.808-33.099-101.913-69.422-141.735-29.657-32.514-82.133-66.222-84.352-67.641a7.727 7.727 0 0 0-10.671 2.348 7.727 7.727 0 0 0 2.347 10.673z"
		/>
		<path
			fill="#c6865c"
			d="m437.817 472.747-.245-.279c-13.83-15.627-32.766-21.964-50.739-19.014-3.153-5.824-6.94-11.332-11.401-16.373-12.304-13.904-27.7-22.18-43.686-24.84-17.248-22.856-42.9-37.368-71.565-37.368s-54.317 14.512-71.565 37.368c-15.987 2.66-31.382 10.936-43.686 24.84-4.46 5.04-8.248 10.549-11.401 16.373-17.973-2.95-36.909 3.386-50.739 19.014l-.245.279c-3.454 3.948-4.91 8.811-4.788 13.552h364.85c.12-4.741-1.336-9.604-4.79-13.552z"
		/>
		<path
			fill="#b16e3d"
			d="m437.817 472.747-.246-.279c-13.83-15.627-32.766-21.964-50.739-19.014-3.153-5.824-6.94-11.332-11.401-16.373-12.304-13.903-27.7-22.179-43.687-24.84-17.248-22.856-42.9-37.368-71.565-37.368-6.728 0-13.29.799-19.616 2.319 18.909 4.54 35.711 15.51 48.548 30.78 2.282 2.715 5.425 4.546 8.866 5.404 14.007 3.494 27.327 11.393 38.222 23.705a85.386 85.386 0 0 1 6.561 8.431c3.073 4.499 8.125 7.226 13.573 7.239 15.206.035 30.403 6.606 42.005 19.716l.246.279c3.454 3.948 4.91 8.811 4.788 13.552h39.232c.123-4.74-1.333-9.603-4.787-13.551z"
		/>
		<path
			fill="#804f2b"
			d="M479.172 494.025H31.597a7.726 7.726 0 1 1 0-15.452h447.575c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
		/>
	</svg>
)


PlanOneIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

PlanOneIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default PlanOneIcon