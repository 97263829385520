import Checkbox from '@material-ui/core/Checkbox'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, SubmissionError } from 'redux-form'
import Comment from '../../../../../../components/Comment'
import FormButton from '../../../../../../components/form/FormButton'
import CommentaireIcon from '../../../../../../components/icon/CommentaireIcon'
import CultureIcon from '../../../../../../components/icon/CultureIcon'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import RecolteIcon from '../../../../../../components/icon/RecolteIcon'
import { PAGE, usePageContext } from '../../../../../../components/layout/PageContext'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { colors } from '../../../../../../utils/constants'
import { listeCulturesConcernees } from '../../../../../../utils/utils'
import InfoCulture from '../../../../../common/information/InfoCulture'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'
import Detail from '../../../component/detail/Detail'
import CommentairePlanche from '../../../component/form/CommentairePlanche'
import CommentaireSuivi from '../../../component/form/CommentaireSuivi'
import PreviousCulture from '../../../component/PreviousCulture'
import { COMMENT_FIELDS, COMMENTAIRE_PLANCHE_FORM_NAME, COMMENTAIRE_SUIVI_FORM_NAME } from '../../../utils/constant'
import { loadAssolement } from '../../assolement/assolementInjector'
import { saveCommentairePlanche, saveCommentaireSuivi } from '../../commentaires/commentairesActions'

//TODO  RG_PLALIST_073 SPRINT 2 : conditionner case a cocher + retourner sa valeur
//TODO RG_PLALIST_075 : conditionner VALIDER si case a cocher true

const getTypeVariation = variation => {
	if (variation > 0) {
		return 'SURPLUS'
	}
	if (variation < 0) {
		return 'PERTE'
	}
	return 'NULLE'
}

/**
 * Injecteur pour l'ouverture du panel d'édition d'un commentaire de suivi
 */
export const injectCommentaireSuivi = (WrappedComponent) => {
	const CommentaireSuiviInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { openCultureDetail, saveCommentaireSuivi, refreshAssolements } = props
		const { page } = usePageContext()

		const handleSaveCommentaireSuivi = (selectedAssolementsID, values, open, idCommentaire, onSuccess) => {
			return saveCommentaireSuivi(selectedAssolementsID, values, idCommentaire)
				.then(({ assolement }) => {
					closePanel()
					if (open && page === PAGE.PLANNING) {
						openCultureDetail(assolement[0])
					}
					refreshAssolements()

					if (onSuccess) {
						onSuccess(true)
					}
				})
				.catch((e) => {
					throw new SubmissionError(e)
				})
		}

		const openPanelCommentaireSuivi = (assolement, open, commentaire, onSuccess) => {
			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]
			const selectedAssolementsID = selectedAssolements.map((assolement) => assolement.id)
			const isEdition = typeof commentaire !== 'undefined'

			const commentInit = isEdition ? {
				typeCommentaire: commentaire.typeCommentaire,
				typeVariation: getTypeVariation(commentaire.variationRendement),
				variation: Math.abs(commentaire.variationRendement),
				commentaire: commentaire.commentaire,
				dateApplication: commentaire.dateApplication
			} : {
				dateApplication: moment().format('YYYY-MM-DD')
			}

			const idComment = isEdition ? commentaire.id : undefined
			const sections = []
			const buttons = []

			buttons.push(
				<FormButton
					type="primary"
					formName={COMMENTAIRE_SUIVI_FORM_NAME}
					getDisabledFromState={state => !formValueSelector(COMMENTAIRE_SUIVI_FORM_NAME)(state, COMMENT_FIELDS.TYPE_COMMENTAIRE_SUIVI)}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>
			)

			if (selectedAssolements && page === PAGE.PLANNING) {
				const { culture, planche } = selectedAssolements[0]

				sections.push(
					{
						icon: <CultureIcon />,
						title: culture.nom,
						path: <InfoCulture culture={culture} />,
						content: <em>{culture.cultivar}</em>,
						comment: <Comment comment={culture.commentaire} />
					},
					{
						icon: <PlaceIcon />,
						title: planche.nom,
						path: <ZoneSurface planche={planche} />,
						content: <TypeSurface planche={planche} />
					},
					{
						icon: <CommentaireIcon color={colors.iconeDetail} />,
						title: <FormattedMessage id="planning.suivi.detail.name" />,
						content: <CommentaireSuivi
							onSubmit={values => handleSaveCommentaireSuivi(selectedAssolementsID, values, open, idComment, onSuccess)}
							initialValues={commentInit}
						/>
					})

			} else if (selectedAssolements && page === PAGE.LISTE) {

				sections.push(
					{
						icon: <CultureIcon />,
						title: <FormattedMessage id="listAssolement.forms.h2.deplacementEtape" />,
						content: <ul>{listeCulturesConcernees(selectedAssolements)}</ul>
					},
					{
						icon: <RecolteIcon />,
						title: <FormattedMessage id="listAssolement.forms.h2.etapeRecolte" />,
						content: <>
							<Checkbox name='markAsReady' defaultChecked color="primary" />
							<FormattedMessage id="listAssolement.forms.checkBox" />
						</>
					},
					{
						icon: <CommentaireIcon color={colors.iconeDetail} />,
						title: <FormattedMessage id="planning.suivi.detail.name" />,
						content: <CommentaireSuivi
							onSubmit={values => handleSaveCommentaireSuivi(selectedAssolementsID, values, open, idComment, onSuccess)}
							initialValues={commentInit}
						/>
					})
			} else if (selectedAssolements && page === PAGE.TOUR_PLAINE) {
				sections.push(
					{
						icon: <CultureIcon />,
						title: <FormattedMessage id="listAssolement.forms.h2.deplacementEtape" />,
						content: <ul>{listeCulturesConcernees(selectedAssolements)}</ul>
					},
					{
						icon: <CommentaireIcon color={colors.iconeDetail} />,
						title: <FormattedMessage id="planning.suivi.detail.name" />,
						content: <CommentaireSuivi
							onSubmit={values => handleSaveCommentaireSuivi(selectedAssolementsID, values, open, idComment, onSuccess)}
							initialValues={commentInit}
						/>
					})
			}

			return openPanel(
				{ id: `commentForm.titles.suivi.${isEdition ? 'edit' : 'new'}` },
				<Detail sections={sections} />,
				buttons
			)
		}

		return <WrappedComponent {...props} openPanelCommentaireSuivi={React.useCallback(openPanelCommentaireSuivi, [page])} />
	}

	const actions = {
		saveCommentaireSuivi
	}

	CommentaireSuiviInjector.propTypes = {
		submit: PropTypes.func,
		openCultureDetail: PropTypes.func,
		refreshAssolements: PropTypes.func,
		saveCommentaireSuivi: PropTypes.func
	}

	return compose(
		loadAssolement,
		connect(undefined, actions)
	)(CommentaireSuiviInjector)
}

/**
 * Injecteur pour l'ouverture du panel d'édition d'un commentaire de suivi
 */
export const injectCommentairePlanche = (WrappedComponent) => {
	const CommentairePlancheInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { openSurfaceDetail, saveCommentairePlanche } = props

		const handleSaveCommentairePlanche = (assolement, idPlanche, idCampagne, values, open, idCommentaire) => {
			return saveCommentairePlanche(idPlanche, idCampagne, values, idCommentaire)
				.then((result) => {
					closePanel()
					if (open) {
						// Update de la planche de l'assolement
						const planche = assolement.planche
						openSurfaceDetail({
							...assolement,
							planche: {
								...planche,
								commentaires: [
									...planche.commentaires.filter(comment => comment.id !== result.commentaire.id),
									result.commentaire
								]
							}
						})
					}
				})
		}

		const openCommentairePlanche = (assolement, planche, campagne, open, commentaire) => {
			const isEdition = typeof commentaire !== 'undefined'
			const commentInit = isEdition ? {
				commentaire: commentaire.commentaire
			} : undefined

			const idComment = isEdition ? commentaire.id : undefined

			const sections = []
			const buttons = []
			if (planche) {
				buttons.push(
					<FormButton
						type="primary"
						formName={COMMENTAIRE_PLANCHE_FORM_NAME}
						getDisabledFromState={state => !formValueSelector(COMMENTAIRE_PLANCHE_FORM_NAME)(state, 'commentaire')}
					>
						<FormattedMessage id="actions.validate" />
					</FormButton>
				)

				sections.push(
					{
						icon: <PlaceIcon />,
						title: planche.nom,
						path: <ZoneSurface planche={planche} />,
						content: <>
							<div style={{ marginTop: 8, marginBottom: 20 }}><TypeSurface planche={planche} /></div>
							<PreviousCulture campagneDateDebut={campagne.dateDebut} plancheId={planche.id} />
							<CommentairePlanche
								onSubmit={values => handleSaveCommentairePlanche(assolement, planche.id, campagne.id, values, open, idComment)}
								initialValues={commentInit}
							/>
						</>
					}
				)
			}

			return openPanel(
				{ id: `commentForm.titles.planche.${isEdition ? 'edit' : 'new'}` },
				<Detail sections={sections} />,
				buttons
			)
		}

		return <WrappedComponent {...props} openCommentairePlanche={openCommentairePlanche} />
	}

	const actions = {
		saveCommentairePlanche
	}

	CommentairePlancheInjector.propTypes = {
		submit: PropTypes.func,
		saveCommentairePlanche: PropTypes.func
	}

	return compose(
		connect(undefined, actions)
	)(CommentairePlancheInjector)
}
