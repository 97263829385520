import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../../../components/form/Input'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { COMMENT_FIELDS, COMMENTAIRE_PLANCHE_FORM_NAME } from '../../utils/constant'

const styles = () => getStyles({
	container: {
		paddingRight: 0,
		paddingLeft: 0
	}
})

const CommentairePlanche = ({ handleSubmit, classes }) => {
	const intl = useIntl()
	return (
		<form onSubmit={handleSubmit}>
			<Field
				name={COMMENT_FIELDS.COMMENTAIRE}
				component={Input}
				multiline
				label={intl.formatMessage({ id: 'commentForm.fields.commentairePlanche' })}
				containerClassName={classes.container}
			/>
		</form>
	)
}

CommentairePlanche.propTypes = {
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	reduxForm({
		form: COMMENTAIRE_PLANCHE_FORM_NAME
	}),
	withStyles(styles)
)(CommentairePlanche)
