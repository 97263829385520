import {Fab, Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {change, Field, FieldArray, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {IRRIGATION_ADD_FIELDS, IRRIGATION_ADD_FORM_NAME} from '../../tool/suiviParBloc.constants'
import moment from 'moment'
import {DateFormat} from '../../../../../../../utils/dateConstants'
import AddIcon from '@material-ui/icons/Add'
import Input from '../../../../../../../components/form/Input'
import TextField from '../../../../../../../components/form/Input'
import FormattedMessageWithBold from "../../../../../../../components/FormattedMessageWithBold";

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		padding: '20px 25px',
		rowGap: 25,
		maxHeight: 'calc(100vh - 220px)'
	},
	formHeader: {
		padding: '10px 15px',
		rowGap: 25
	},
	rowContainer: {
		columnGap: 25
	},
	label: {
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	dateText: {
		fontSize: 18,
		lineHeight: '22px',
		fontWeight: '600',
		color: '#000'
	},
	informationText: {
		fontSize: 16,
		fontStyle: 'italic',
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	formBody: {
		rowGap: 15
	},
	field: {
		'& .MuiFilledInput-root': {
			backgroundColor: 'transparent'
		}
	},
	periodLabel: {
		fontWeight: '400',
		fontSize: 12,
		lineHeight: '15px',
		color: '#000'
	}
})

/**
 * Validate
 * @param values
 * @returns {{}}
 */
const validate = (values) => {
	const errors = {}

	const fieldsError = []
	values[IRRIGATION_ADD_FIELDS.SLOTS].forEach((row, index) => {
		const rowError = {}
		if (!row[IRRIGATION_ADD_FIELDS.START]) {
			rowError[IRRIGATION_ADD_FIELDS.START] = {id: 'global.errors.mandatory'}
			fieldsError[index] = rowError
		}

		if (!row[IRRIGATION_ADD_FIELDS.END]) {
			rowError[IRRIGATION_ADD_FIELDS.END] = {id: 'global.errors.mandatory'}
			fieldsError[index] = rowError
		}

		if (row[IRRIGATION_ADD_FIELDS.START] && row[IRRIGATION_ADD_FIELDS.END]) {
			const start = row[IRRIGATION_ADD_FIELDS.START]
			const end = row[IRRIGATION_ADD_FIELDS.END]

			const startHours = parseInt(start.split(':')[0])
			const startMinutes = parseInt(start.split(':')[1])

			const endHours = parseInt(end.split(':')[0])
			const endMinutes = parseInt(end.split(':')[1])

			const isBefore = moment().set({h: startHours, m: startMinutes}).isBefore(moment().set({h: endHours, m: endMinutes}))

			if (!isBefore) {
				rowError[IRRIGATION_ADD_FIELDS.END] = {id: 'global.errors.isBefore'}
				fieldsError[index] = rowError
			}
		}
	})

	if (fieldsError.length) {
		errors[IRRIGATION_ADD_FIELDS.SLOTS] = fieldsError
	}

	return errors
}

/**
 * IrrigationContentAddForm
 * @param handleSubmit
 * @param change
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const IrrigationAddForm = ({handleSubmit, classes, block, weekDay, dataDay, array}) => {
	const renderFieldArray = ({fields}) => {
		return (
			fields.map((fieldName, index, fields) => {
					return (
						<Grid key={index} direction="row" alignItems="center" justify="center" container>
							<Grid item xs/>
							<Field
								name={`${fieldName}.${IRRIGATION_ADD_FIELDS.START}`}
								component={Input}
								type="time"
								style={{width: 150}}
								renderInput={(params) => <TextField {...params} />}
								containerClassName={classes.field}
							/>
							<Typography className={classes.periodLabel}>
								-
							</Typography>
							<Field
								name={`${fieldName}.${IRRIGATION_ADD_FIELDS.END}`}
								component={Input}
								type="time"
								style={{width: 150}}
								renderInput={(params) => <TextField {...params} />}
								containerClassName={classes.field}
							/>
							<Grid item xs container justify="flex-end">
								{
									index + 1 === fields.length &&
									(
										<Fab
											color="primary"
											onClick={() => {
												array.push(IRRIGATION_ADD_FIELDS.SLOTS, {[IRRIGATION_ADD_FIELDS.START]: undefined, [IRRIGATION_ADD_FIELDS.END]: undefined})
											}}
											className={classes.icon}>
											<AddIcon/>
										</Fab>
									)
								}
							</Grid>
						</Grid>
					)
				}
			)
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid container className={classes.formHeader}>
					<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
						<Typography className={classes.label}>
							<FormattedMessage id="tourPlaine.suiviParBloc.irrigation.panel.datePrefix"/>
						</Typography>
						<Typography className={classes.dateText}>
							{moment(weekDay.id, DateFormat.YYYY_MM_DD).locale('fr').format(DateFormat.dddd_Do_MMMM)}
						</Typography>
					</Grid>
					<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
						<Typography className={classes.informationText}>
							<FormattedMessageWithBold
								id="tourPlaine.suiviParBloc.irrigation.panel.information"
								values={{
									blockName: block.title
								}}
								style={{
									fontSize: 16,
									fontStyle: 'italic'
								}}
							/>
						</Typography>
					</Grid>
				</Grid>
				<Grid item container alignItems="center" justify="flex-start" className={classes.formBody}>
					{dataDay.map(data => (
						<Grid key={data.id} direction="row" alignItems="center" justify="center" container style={{gap: 10}}>
							<Grid item>
								<Typography className={classes.periodLabel}>
									{data.start}
								</Typography>
							</Grid>
							<Grid item>
								<Typography className={classes.periodLabel}>
									-
								</Typography>
							</Grid>
							<Grid item>
								<Typography className={classes.periodLabel}>
									{data.end}
								</Typography>
							</Grid>
						</Grid>
					))}
					<FieldArray
						name={IRRIGATION_ADD_FIELDS.SLOTS}
						component={renderFieldArray}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

IrrigationAddForm.propType = {
	block: PropTypes.object,
	weekDay: PropTypes.object,
	dataDay: PropTypes.object,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	array: PropTypes.object
}

const mapStateToProps = (state, {...props}) => {
	return ({
		initialValues: {
			[IRRIGATION_ADD_FIELDS.SLOTS]: [{[IRRIGATION_ADD_FIELDS.START]: undefined, [IRRIGATION_ADD_FIELDS.END]: undefined}]
		},
		...props
	})
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: IRRIGATION_ADD_FORM_NAME,
		destroyOnUnmount: true,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(IrrigationAddForm)
