import React from 'react'
import { Typography } from '@material-ui/core'
import SuiviAvancementBlocs from './SuiviAvancementBlocs'
import SuiviRessourcesTable from './SuiviRessourcesTable'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import { getSuiviAvancement } from '../suiviAvancementApi'

const PageAvancementAllUsers = ({ classes, setIdUserSelectionne, jourActuel }) => {

	const [dataSuivi, setDataSuivi] = React.useState({
		tachesPlanifiees: 0,
		tachesARealiser: 0,
		tachesEnCours: 0,
		tachesTerminees: 0,
		chargeTravailTotal: 'PT0S',
		tempsPasseARealiser: 'PT0S',
		tempsPasseEnCours: 'PT0S',
		tempsPasseTerminees: 'PT0S',
		chargeTravailARealiser: 'PT0S',
		chargeTravailEnCours: 'PT0S',
		chargeTravailTerminees: 'PT0S'
	})

	React.useEffect(() => {
		getSuiviAvancement(jourActuel.format('YYYY-MM-DD'))
			.then(res => setDataSuivi(res))
	}, [jourActuel])

	return (
		<div className={classes.root}>
			<div>
				<Typography className={classes.title}>
					<FormattedMessage id="suiviAvancement.suiviGlobal.title" />
				</Typography>
				<SuiviAvancementBlocs dataSuivi={dataSuivi} />
				<Typography className={classnames(classes.title, classes.title2)}>
					<FormattedMessage id="suiviAvancement.suiviRessources.titleGlobal" />
				</Typography>
				<SuiviRessourcesTable setIdUserSelectionne={setIdUserSelectionne} />
			</div>
		</div>
	)
}

export default PageAvancementAllUsers