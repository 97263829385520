import Grid from '@material-ui/core/Grid'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {FormSwitch, getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {compose} from 'redux'
import classnames from "classnames";
import {connect} from "react-redux";
import {change, Field, reduxForm} from 'redux-form'
import PropTypes from "prop-types";
import {colors} from "../../../../utils/constants";

const styles = () => getStyles({
	form: {
		padding: '20px 20px 0px 20px',
		width: '100%',
		fontFamily: 'Lato'
	},
	text: {
		marginBottom: 25,
		fontSize: 14
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	select: {
		paddingLeft: 0
	},
	rowSwitch: {
		marginTop: 10,
		paddingLeft: 15,
		backgroundColor: "#00000",
		'& .MuiIconButton-root': {
			color: colors.whiteSwitch,
			bottom: 0
		}
	},
})

export const DESACTIVE_ITK_FORM = 'DESACTIVE_ITK_FORM'

const PopinDesactive = ({ classes, handleSubmit, itkDesactive }) => {
	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid container>
				<Grid container className={classes.row}>
					<Grid item>
						<label className={classes.label}><FormattedMessage id="bo.cultureItk.popinDesactive.description" /></label>
					</Grid>
					<Grid item className={classes.rowSwitch}>
						{Object.entries(itkDesactive)
							.map(([key]) => (
								<Field
									key={key}
									label={<FormattedMessage id="bo.cultureItk.popinDesactive.textValue" values={{ name: key.split('_')[0] }} />}
									containerClassName={classnames(classes.inputs)}
									name={key}
									component={FormSwitch}
									fromBo
								/>
							))}
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
}

PopinDesactive.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

const actions = {
	change
}

const mapStateToProps = (state, {itkDesactive}) => {
	return {
		initialValues: itkDesactive
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: DESACTIVE_ITK_FORM
	})
)(PopinDesactive);

