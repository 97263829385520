import React, { useState } from 'react'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import { Grid, IconButton, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { toI18N } from '../../../utils/utils'

const styles = theme => getStyles({
	container: {
		border: '1px solid #D6D6D6',
		borderRadius: 7,
		padding: 5,
		marginBottom: 15,
		cursor: 'pointer',
		backgroundColor: '#fff'
	},
	selected: {
		backgroundColor: '#f1ecf2'
	},
	icon: {
		color: theme.palette.primary.main,
		padding: 5,
		fontSize: '30px !important'
	},
	info: {
		paddingLeft: 10
	},
	bold: {
		fontWeight: 'bold'
	},
	title: {
		fontWeight: 400,
		lineHeight: '20px',
		fontSize: 16,
	},
	description: {
		fontWeight: 400,
		lineHeight: '12px',
		fontSize: 10,
	}
})

/**
 * Composant data
 * Lit un objet de type :
 *  uniqueId: 'bloc-1',
	icon: DashboardIcon,
	title: 'Bloc 1',
	subTitle: 'Bloc 1 -subtitle',
	description: 'Bloc description',
	data: {},
	children: []
 */
/**
 * SimpleCell inspiré du composant data du BO
 * @param data
 * @param selection
 * @param handleSelection
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleCell = ({ data, selection, handleSelection, classes }) => {
	const [collapse, setCollapse] = useState(false)
	const Element = data.icon
	const hasChildren = data.children && data.children.length > 0
	const isSelected = selection && selection.uniqueId === data.uniqueId

	return (<>
		<Grid
			item
			container
			className={classnames(classes.container, { [classes.selected]: isSelected })}
			onClick={() => {
				if (hasChildren) {
					setCollapse(!collapse)
				}
				handleSelection(data)
			}}
			alignItems="center"
		>
			{
				data.icon
				&& (
					<Grid item xs={1}>
						<Element className={classes.icon} color="primary" width={30} height={30} />
					</Grid>
				)
			}
			<Grid item xs={10} container direction="column" className={classes.info}>
				<Typography className={classes.title}><span className={classes.bold}>{data.title}</span>{data.subtitle && <span>{` ${data.subtitle}`}</span>}</Typography>
				<Typography className={classes.description}>{toI18N(data.description)}</Typography>
			</Grid>
			{hasChildren && <Grid item xs={1}>
				<IconButton className={classes.icon} onClick={(e) => {
					e.stopPropagation()
					setCollapse(!collapse)
				}}>
					{collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</Grid>}
		</Grid>
		{collapse && data.children && data.children.map(child => <ConnectedSimpleCell data={child} handleSelection={handleSelection} selection={selection} />)}
	</>)
}

SimpleCell.propTypes = {
	data: PropTypes.object,
	selection: PropTypes.object,
	handleSelection: PropTypes.func,
	classes: PropTypes.object
}

const ConnectedSimpleCell = withStyles(styles)(SimpleCell)

export default ConnectedSimpleCell
