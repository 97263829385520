import {Grid, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Detail from '../../planning/component/detail/Detail'
import {ACTION_ONGLET_AFFECTATION} from '../../../../utils/constants'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import {ReactComponent as Jardinier} from '../../../../icons/jardinier.svg'
import {injectTypeTacheValueList} from "../../../common/valueLists/valueListInjectors";
import {LocalDate} from "../../../../utils/date/local-date";
import {DateFormat} from "../../../../utils/date/model/date";
import Radio from "../../../../components/form/Radio";
import {connect} from "react-redux";
import FormattedMessageWithBold from "../../../../components/FormattedMessageWithBold";
import Typography from "@material-ui/core/Typography/index";
import PickingIcon from "../../../../components/icon/PickingIcon";
import {distinct} from "../../../../utils/arrayUtils";


export const AFFECTATION_FORM = 'AFFECTATION_FORM'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	},
	form: {
		width: '100%',
		margin: 15
	},
	details: {
		width: 'initial !important'
	},
	formInput: {
		'& > div': {
			padding: 0
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	icon: {
		height: 24,
		width: 24
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 180,
				'& span': {
					fontSize: '12px !important'
				}
			}
		}
	},
})

const validate = values => {
	const errors = {}
	if (!values['usersAutocomplete'] || (values['usersAutocomplete'] && values['usersAutocomplete'].length === 0)) {
		errors['usersAutocomplete'] = {id: 'gestionTaches.ecranAffectations.panels.validate.champVide'}
	}
	return errors
}

const getSelectedRessources = (users, taches) => users.length > 0 ? distinct(taches.flatMap(t => t.ressources).map(r => users.find(u => u.code === r)).filter(r => !!r)) : []

const AffectationForm = ({handleSubmit, classes, taches, typeTacheList, users, affectOrDesaffect}) => {
	const getUsers = () => Promise.resolve(users)

	const sections = [
		{
			icon: <PickingIcon className={classes.icon}/>,
			title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.taches"/>,
			content:
				<ul>
					{
						taches.map(item => {
							const task = typeTacheList.find(typeTache => typeTache.code === item.type)
							return (
								<li style={{paddingBottom: '5px'}} key={item.id}>
									<FormattedMessage
										id={'tourPlaine.form.moveTask.sectionOne.body.taskPlanned'}
										values={{
											task: task.label,
											date: LocalDate.fromAPI(item.date).toDisplayDate(DateFormat.SHORT_DATE),
											crop: item.culture,
											board: item.lieu,
											size: item.surface
										}}
									/>
								</li>
							)
						})
					}
				</ul>
		},
		{
			icon: <Jardinier className={classes.icon}/>,
			title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.ressources"/>,
			content:
				<Grid className={classes.formInput}>
					<Grid>
						<Field name='affectOrDesaffect' component={Radio}
							   choices={[{
								   value: ACTION_ONGLET_AFFECTATION.AFFECTER,
								   label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.affectation" />
							   }, {
								   value: ACTION_ONGLET_AFFECTATION.DESAFFECTER,
								   label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.desaffectation" />
							   }]}
							   row
							   style={{ justifyContent: 'space-between' }}
							   containerClass={classes.radio}
						/>
					</Grid>

					<Grid>
						{affectOrDesaffect === ACTION_ONGLET_AFFECTATION.AFFECTER && getSelectedRessources(users, taches).length > 0 &&
							<Typography style={{paddingLeft: 5, paddingBottom: 10, fontSize: 12}}>
								<FormattedMessageWithBold id="gestionTaches.ecranAffectations.panels.content.ressources" values={{ressources: getSelectedRessources(users, taches).map(r => r.label).join(', ')}}/>
							</Typography>}
						<Field
							label={affectOrDesaffect === ACTION_ONGLET_AFFECTATION.AFFECTER
								? <FormattedMessage id="gestionTaches.ecranAffectations.panels.multiSelectLabel.userAffectation" />
								: <FormattedMessage id="gestionTaches.ecranAffectations.panels.multiSelectLabel.userDesaffectation" />}
							name="usersAutocomplete"
							component={AutocompleteMulti}
							getOptions={getUsers}
							forceGetOptions
							formName={AFFECTATION_FORM}
						/>
					</Grid>
				</Grid>
		}
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details}/>
		</form>
	)

}

AffectationForm.propType = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, {taches, users}) => {
	const formSelector = formValueSelector(AFFECTATION_FORM)
	const affectOrDesaffect = formSelector(state, 'affectOrDesaffect')

	if (affectOrDesaffect === ACTION_ONGLET_AFFECTATION.DESAFFECTER) {
		return {
			affectOrDesaffect,
			initialValues: {
				affectOrDesaffect: ACTION_ONGLET_AFFECTATION.DESAFFECTER,
				usersAutocomplete: getSelectedRessources(users, taches)
			}
		}
	} else {
		return {
			affectOrDesaffect,
			initialValues: {
				affectOrDesaffect: ACTION_ONGLET_AFFECTATION.AFFECTER
			}
		}
	}
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	injectTypeTacheValueList,
	reduxForm({
		form: AFFECTATION_FORM,
		validate,
		enableReinitialize: true
	})
)(AffectationForm)
