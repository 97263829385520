import React from 'react'
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from 'react-intl'
import {withStyles} from "@material-ui/core";
import {colors} from "../../../utils/constants";
import {push} from 'connected-react-router'
import {getStyles} from "isotope-client";
import Button from "@material-ui/core/Button";
import {compose} from "redux";
import {connect} from "react-redux";

/**
 * Styles
 */
const styles = () => getStyles({
	notification: {
		height: 45,
		backgroundColor: colors.alerte,
		color: colors.whiteSwitch,
		fontFamily: 'Lato',
		fontSize: 15
	},
	container: {
		paddingLeft: 30,
		paddingRight: 30,
		'& .MuiButton-root': {
			color: colors.whiteSwitch
		}
	},
})

const AlerteBarContent = ({classes, goToPage, messageId, buttonMessageId, messageValues, link, Icon}) => {
	return (
		<Grid container alignItems="center" justify="center" className={classes.notification}>
			<Grid container alignItems="center" justify="space-between" className={classes.container}>
				<Grid container item xs={link ? 9 : 12} alignItems="center" justify={link ? "flex-start" : "center"}>
					{Icon && <Icon style={{paddingRight: 15}}/>}
					<FormattedMessage id={`alerte.${messageId}`} values={messageValues}/>
				</Grid>
				{link && (
					<Grid container item xs={link ? 3 : 0} justify={"flex-end"}>
						<Button onClick={() => goToPage(link)}>
							<FormattedMessage id={`alerte.${buttonMessageId}`}/>
						</Button>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}

const actions = {
	goToPage: (url) => dispatch => dispatch(push(url)),
}

export default compose(
	connect(null, actions),
	withStyles(styles)
)(AlerteBarContent)
