import React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = ({ height, width }) => (
	<svg
		width={height}
		height={width}
		viewBox="0 0 512.063 512.063"
	>
		<ellipse
			cx={256.032}
			cy={256.032}
			fill="#00df76"
			rx={255.949}
			ry={256.032}
		/>
		<path
			fill="#00ab5e"
			d="M256.032 0c-.116 0-.231.004-.347.004v512.055c.116 0 .231.004.347.004 141.357 0 255.949-114.629 255.949-256.032S397.389 0 256.032 0z"
		/>
		<path
			fill="#fff5f5"
			d="M111.326 261.118 214.85 364.642c4.515 4.515 11.836 4.515 16.351 0l169.957-169.957c4.515-4.515 4.515-11.836 0-16.351l-30.935-30.935c-4.515-4.515-11.836-4.515-16.351 0L230.255 271.014c-4.515 4.515-11.836 4.515-16.351 0l-55.397-55.397c-4.426-4.426-11.571-4.526-16.119-.226l-30.83 29.149c-4.732 4.475-4.837 11.973-.232 16.578z"
		/>
		<path
			fill="#dfebf1"
			d="M370.223 147.398c-4.515-4.515-11.836-4.515-16.351 0l-98.187 98.187v94.573l145.473-145.473c4.515-4.515 4.515-11.836 0-16.352z"
		/>
	</svg>
)


CheckIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

CheckIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default CheckIcon