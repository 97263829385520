import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const CommentaireOutlinedIcon = ({ height, width, color }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			style={{
				msTransform: "rotate(360deg)",
				WebkitTransform: "rotate(360deg)",
				verticalAlign: "-.125em",
			}}
			transform="rotate(360)"
		>
			<path
				d="M9 22a1 1 0 01-1-1v-3H4a2 2 0 01-2-2V4a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H9m1-6v3.08L13.08 16H20V4H4v12h6m3-6h-2V6h2v4m0 4h-2v-2h2v2z"
				fill={color}
			/>
			<path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
		</svg>
	)
}

CommentaireOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

CommentaireOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default CommentaireOutlinedIcon
