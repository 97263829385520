import React from 'react'
import Grid from '@material-ui/core/Grid'
import CultureBloc from './CultureBloc'

const CultureFieldArray = ({ fields, groupesAssolement, ...props }) => {
	React.useEffect(() => {
		groupesAssolement && groupesAssolement.forEach((groupeAssolement, index) => fields.push({}))
	}, [])

	return (
		fields.map((field, index) => (
			<Grid key={index}>
				<CultureBloc
					groupeAssolement={groupesAssolement[index]}
					{...props}
					field={field}
				/>
			</Grid>
		))
	)

}

export default CultureFieldArray