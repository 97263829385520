export const fillGapsX = {
	// Permet de rajouter des espaces entre les blocs d'un dataset affiché à l'horizontal
	id: 'fillGapsX',
	beforeDatasetDraw(chart, args) {
		if (args.index > 0 && chart.isDatasetVisible(args.index)) {
			args.meta.data.forEach(item => {
				const width = item.width
				if (width > 0) {
					item.base = item.x - width + 1;
				}
			})
		}
	}
}

export const fillGapsY = {
	// Permet de rajouter des espaces entre les blocs d'un dataset affiché à la verticale
	id: 'fillGapsY',
	beforeDatasetDraw(chart, args) {
		if (args.index > 0 && chart.isDatasetVisible(args.index)) {
			args.meta.data.forEach(item => {
				const height = item.height
				if (height > 0) {
					item.base = height + item.y - 1;
				}
			})
		}
	}
}