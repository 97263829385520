import React from 'react'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {FilterPanelContext} from '../../../components/layout/rightPanels'
import ListAssolementComponents from './ListAssolementComponents'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'


const ListAssolement = () => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.LISTE), [])

	return (
		<DialogProvider>
			<DetailPanelProvider>
				<ActionPanelProvider Context={FilterPanelContext} level={1}>
					<ActionPanelProvider>
						<ListAssolementComponents/>
					</ActionPanelProvider>
				</ActionPanelProvider>
			</DetailPanelProvider>
		</DialogProvider>
	)
}

export default ListAssolement
