import React from 'react'
import { connect } from 'react-redux'
import { CONNEXIONS_TABLE_NAME, FILTER_CONNEXIONS_FORM } from './constants'
import FilterConnexionsForm from './FilterConnexionsForm'
import FormButton from '../../../../components/form/FormButton'
import { FilterPanelContext } from '../../../../components/layout/rightPanels'
import { FormattedMessage } from 'react-intl'
import { saveCurrentFilter, setFilterOpen, setFilterUsed } from '../../planning/services/toolbar/toolbarAction'
import { dataTableActions } from 'isotope-client'
import { getFilterToolbarState } from '../../planning/services/toolbar/toolbarSelector'

/**
 * Injecteur pour l'ouverture du filtre des connexions
 */
export const injectConnexionsFilter = (WrappedComponent) => {
	const FilterConnexionsInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(FilterPanelContext)
		const { filterState, setFilterOpen, setFilterUsed, saveCurrentFilter, refreshTable } = props

		let title = <FormattedMessage id="suiviConnexions.filter.title" />

		const onSubmit = (values) => {
			const notEmpty = Object.values(values).some(value => !!value || value.length)

			setFilterUsed(notEmpty)
			setFilterOpen(false)
			closePanel()
			saveCurrentFilter(values)
			refreshTable()
		}

		const buttons = [
			<FormButton type="primary" formName={FILTER_CONNEXIONS_FORM}>
				<FormattedMessage id="suiviConnexions.filter.submit" />
			</FormButton>
		]

		React.useEffect(() => {
			if (filterState.open) {
				openPanel(
					title,
					<FilterConnexionsForm onSubmit={onSubmit} />,
					buttons
				)
			}
		}, [filterState.open])

		return <WrappedComponent {...props} />
	}


	const mapStateToProps = state => ({
		filterState: getFilterToolbarState(state)
	})

	const actions = {
		setFilterOpen,
		setFilterUsed,
		saveCurrentFilter,
		refreshTable: () => dataTableActions.refresh(CONNEXIONS_TABLE_NAME),
	}

	return connect(mapStateToProps, actions)(FilterConnexionsInjector)
}
