import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import Detail from '../detail/Detail'
import Grid from '@material-ui/core/Grid'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import { listeCulturesConcerneesDetailsRecolte } from '../../../../../utils/utils'
import { FormattedMessage } from 'react-intl'
import Radio from '../../../../../components/form/Radio'
import { CHOIX_POINT_DEPART_MODIFICATION, CHOIX_TYPE_MODIFICATION } from '../../../../../utils/constants'
import { compose } from 'redux'
import Input from '../../../../../components/form/Input'
import { formValueSelector, reduxForm, Field } from 'redux-form'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { connect } from 'react-redux'
import RecolteIcon from '../../../../../components/icon/RecolteIcon'
import Typography from '@material-ui/core/Typography'

export const MODIFICATION_ETAPE_RECOLTE = 'MODIFICATION_ETAPE_RECOLTE'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 170
			}
		}
	},
	form: {
		width: '100%',
		margin: 15
	},
	details: {
		width: 'initial !important'
	}

})

const formattedMessageMenuBase = 'planning.cultureForm'

const validate = (values, { campagne }) => {

	const errors = {}
	const requiredFields = [
		'pointDepartModification',
		'typeDeModification',
		'nbSemainesAllongement',
		'nbSemainesReduction'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})

	if ((values['nbSemainesAllongement'] <= 0) || (!Number.isInteger(parseFloat(values['nbSemainesAllongement'])))) {
		errors['nbSemainesAllongement'] = { id: `listAssolement.forms.contentError.nbSemaineInvalide` }
	}

	if ((values['nbSemainesReduction'] <= 0) || (!Number.isInteger(parseFloat(values['nbSemainesReduction'])))) {
		errors['nbSemainesReduction'] = { id: `listAssolement.forms.contentError.nbSemaineInvalide` }
	}

	return errors
}

const ModifierEtapeRecolte = ({ selectedAssolements, handleSubmit, classes, typeDeModification, pointDepartModification }) => {

	const sections = [
		{
			icon: <CultureIcon />,
			title: <FormattedMessage id="listAssolement.forms.h2.deplacementEtape" />,
			content: <ul>{listeCulturesConcerneesDetailsRecolte(selectedAssolements)}</ul>
		}, {
			icon: <RecolteIcon />,
			title: <FormattedMessage id="listAssolement.forms.h2.etapeRecolte" />,
			content:
				<React.Fragment>

					<Grid>
						<Typography variant="body1">
							<FormattedMessage id={'listAssolement.forms.content.allongementReductionTache'} />
						</Typography>
						<Field name="pointDepartModification" component={Radio}
						       choices={[{
							       value: CHOIX_POINT_DEPART_MODIFICATION.DEBUT,
							       label: <FormattedMessage id={'listAssolement.forms.content.duDebutDeLaRecolte'} />
						       }, {
							       value: CHOIX_POINT_DEPART_MODIFICATION.FIN,
							       label: <FormattedMessage id={'listAssolement.forms.content.deLaFinDeLaRecolte'} />
						       }]}
						       row
						       style={{ justifyContent: 'space-between' }}
						       containerClass={classes.radio}
						/>
					</Grid>

					<Grid>
						<Typography variant="body1">
							<FormattedMessage id={'listAssolement.forms.content.allongementReductionTache'} />
						</Typography>
						<Field name="typeDeModification" component={Radio}
						       choices={[{
							       value: CHOIX_TYPE_MODIFICATION.ALLONGER,
							       label: <FormattedMessage id={'listAssolement.forms.content.allonger'} />
						       }, {
							       value: CHOIX_TYPE_MODIFICATION.REDUIRE,
							       label: <FormattedMessage id={'listAssolement.forms.content.reduire'} />
						       }]}
						       row
						       style={{ justifyContent: 'space-between' }}
						       containerClass={classes.radio}
						/>
					</Grid>

					<Grid>
						{typeDeModification === CHOIX_TYPE_MODIFICATION.ALLONGER && <Field
							required
							name="nbSemainesAllongement"
							component={Input}
							type="number"
							adornment={<FormattedMessage id={'listAssolement.forms.content.adornment'} />}
							style={{ width: '48%', marginRight: '50%' }}
						/>}

						{typeDeModification === CHOIX_TYPE_MODIFICATION.REDUIRE && <Field
							required
							name="nbSemainesReduction"
							component={Input}
							type="number"
							adornment={<FormattedMessage id={'listAssolement.forms.content.adornment'} />}
							style={{ width: '48%', marginLeft: '52%' }}
						/>}
					</Grid>
				</React.Fragment>
		}
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details} />
		</form>
	)

}
//Récupération des valeurs du formulaire redux (values de mui)
const selector = formValueSelector(MODIFICATION_ETAPE_RECOLTE)

ModifierEtapeRecolte.propType = {
	selectedAssolements: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
	pointDepartModification: selector(state, "pointDepartModification"),
	typeDeModification: selector(state, "typeDeModification"),
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	reduxForm({
		form: MODIFICATION_ETAPE_RECOLTE,
		initialValues: {
			pointDepartModification: CHOIX_POINT_DEPART_MODIFICATION.FIN
		},
		validate
	})
)(ModifierEtapeRecolte)