import React, { useMemo } from 'react'
import { LocalDateTime } from '../../../utils/date/local-date-time'
import { useSolverStatusContext } from '../../../modules/fo/besoinsProduction/SolverStatusContextProvider'
import { PAGE, usePageContext } from '../PageContext'
import { ONGLETS_BESOINS_PRODUCTION, PROFILS, ROLE_WEIGHT, SOLVER_STATUS } from '../../../utils/constants'
import AlerteBarContent from './AlerteBarContent'
import { hasRole } from '../../security/RoleChecker'
import * as userSelectors from '../../../modules/common/user/services/userSelectors'
import { connect } from 'react-redux'
import WarningRounded from '@material-ui/icons/WarningRounded'
import { useBesoinsProductionContext } from '../../../modules/fo/besoinsProduction/BesoinsProductionProvider'
import { hasAccess } from '../../security/AccessChecker'

const AlerteBar = ({ userAuthorities }) => {
	const { solverInfos } = useSolverStatusContext()
	const { page } = usePageContext()
	const { onglet } = useBesoinsProductionContext()

	const loading = !solverInfos
	const isTDP = page === PAGE.TOUR_PLAINE
	const isBesoinProduction = page === PAGE.BESOINS_PRODUCTION
	const isSolution = onglet === ONGLETS_BESOINS_PRODUCTION.RESULTATS
	const isVueListe = page === PAGE.LISTE
	const isPlanning = page === PAGE.PLANNING
	const isPlanningOrVueListeOrTDB = isPlanning || isVueListe || page === PAGE.TDB
	const isBo = page === PAGE.BO

	const hasSolveurEnCours = solverInfos?.status === SOLVER_STATUS.PREPARING || solverInfos?.status === SOLVER_STATUS.RUNNING

	const isSolveurOutdated = solverInfos?.status === SOLVER_STATUS.OUTDATED

	const datePC = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateSauvegarde)
	const dateBP = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateDerniereSauvegardeDemande)
	const dateS = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateGeneration)
	const dateTDP = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateValidationTDP)
	const dateFinSauvegarde = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateFinSauvegarde)
	const datePlanning = LocalDateTime.fromAPINotNullOrEpoch(solverInfos?.dateValidationPlanning)

	const compareSolutionNonApprouvee = useMemo(() => {
		return dateS?.gt(dateBP) && dateBP?.gt(datePC)
	}, [dateS, dateBP, datePC])

	const compareLancerPlanification = useMemo(() => {
		return dateBP?.gt(datePC) && (datePC?.gte(dateS) || dateBP?.gte(dateS))
	}, [dateS, dateBP, datePC])

	const compareSolutionNonApprouveeWithTDP = useMemo(() => {
		return dateS?.gt(dateTDP) && dateTDP?.gt(datePC)
	}, [dateS, dateTDP, datePC])

	const compareLancerPlanificationWithTDP = useMemo(() => {
		return dateTDP?.gt(datePC) && (datePC?.gte(dateS) || dateTDP?.gte(dateS))
	}, [dateS, dateTDP, datePC])

	const compareLancerPlanificationWithPlanning = useMemo(() => {
		return datePlanning?.gt(datePC) && datePlanning?.gt(dateFinSauvegarde) && datePlanning.gt(dateS)
	}, [datePC, datePlanning, dateFinSauvegarde, dateS])

	const compareLancerPlanificationRegroupement = useMemo(() => {
		return dateBP?.gt(datePC) && dateTDP.gt(datePC)
	}, [dateTDP, dateBP, datePC])

	const isPlanningSaveEnCours = useMemo(() => {
		return datePC.gt(dateS) && datePC.gt(dateFinSauvegarde)
	}, [datePC, dateS, dateFinSauvegarde])

	if (!loading) {
		let alertes = []

		// les barres d'alerte ne sont affichés qu'aux chef de culture et aux superviseurs car ce sont les seuls à avoir accès aux besoins de production
		if (hasAccess(PROFILS.CHEF, userAuthorities) || hasAccess(PROFILS.SUPERVISEUR, userAuthorities)) {
			/* RG_ALERTE_1 RG_ALERTE_3
			Les besoins de production ont été modifiés et le planning des cultures doit être mis à jour. Une solution a été proposée mais n’a pas encore été approuvée.
			*/
			if ((isTDP || isPlanningOrVueListeOrTDB) && compareSolutionNonApprouvee && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="productionModifieSolutionNonApprouve"
						buttonMessageId="buttonSolution"
						link="/besoins-production?onglet=resultats"
					/>
				)
			}
			/* RG_ALERTE_2 RG_ALERTE_4
			Les besoins de production ont été modifiés et le planning des cultures doit être mis à jour.
			*/
			if ((isTDP || isPlanningOrVueListeOrTDB) && compareLancerPlanification) {
				alertes.push(
					<AlerteBarContent
						messageId="productionModifie"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}


			/* RG_ALERTE_5 RG_ALERTE_8
			Un tour de plaine a été réalisé et terminé et le planning des cultures n’a pas été mis à jour après ce tour de plaine. Une solution a été proposée mais n’a pas été confirmée
			*/
			if (((isBesoinProduction && isSolution) || isPlanningOrVueListeOrTDB) && compareSolutionNonApprouveeWithTDP && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="tourDePlaineTermineSolutionNonApprouve"
						buttonMessageId="buttonSolution"
						link={isPlanningOrVueListeOrTDB ? '/besoins-production?onglet=resultats' : ''}
					/>
				)
			}

			/* RG_ALERTE_6 RG_ALERTE_9
			Un tour de plaine a été réalisé et terminé et le planning des cultures n’a pas été mis à jour après ce tour de plaine
			*/
			if (((isBesoinProduction && isSolution) || isPlanningOrVueListeOrTDB) && compareLancerPlanificationWithTDP && !hasSolveurEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="tourDePlaineTermine"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}


			/* RG_ALERTE_10
			Le planning des cultures a été mis à jour, il faut remettre la solution à jour
			*/
			if ((isBesoinProduction && isSolution) && compareLancerPlanificationWithPlanning && !hasSolveurEnCours && !isPlanningSaveEnCours) {
				alertes.push(
					<AlerteBarContent
						messageId="PlanningRecent"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}


			/* RG_ALERTE_7
			Les besoins de production ont été modifiés et le planning des cultures doit être mis à jour
			*/
			if (isPlanningOrVueListeOrTDB && compareLancerPlanificationRegroupement) {
				alertes = [] // Clear les alertes pour afficher que celle-ci
				alertes.push(
					<AlerteBarContent
						messageId="productionModifie"
						buttonMessageId="buttonModifie"
						link="/besoins-production?onglet=recueil"
					/>
				)
			}
		}

		if (isPlanningSaveEnCours && !isSolveurOutdated) {
			alertes.push(
				<AlerteBarContent
					Icon={WarningRounded}
					messageId="enregistrementEnCours"
				/>
			)
		}

		if ((isBo || isTDP || isBesoinProduction || isPlanning || isVueListe) && hasSolveurEnCours) {
			// si l'utilisateur est un superviseur alors on affiche la ferme concernée, sinon pas
			if (hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities)) {
				solverInfos.fermeEnCours.forEach(f => {
					alertes.push(
						<AlerteBarContent
							Icon={WarningRounded}
							messageId={'solveurEnCoursSuperviseur'}
							messageValues={{ ferme: f }}
						/>
					)
				})
			} else {
				alertes.push(
					<AlerteBarContent
						Icon={WarningRounded}
						messageId={'solveurEnCours'}
					/>
				)
			}
		}

		return <>{alertes.map(alerte => alerte)}</>
	}
	return null
}

const mapStateToProps = (state) => ({
	userAuthorities: userSelectors.getAuthorities(state)
})

export default connect(mapStateToProps)(AlerteBar)
