import React from 'react'
import { Route } from 'react-router-dom'
import AccueilPage from './accueil/AccueilPage'
import Calcul from './calcul/Calcul'
import Planning from './planning/Planning'
import ListAssolement from './listAssolement/ListAssolement'
import { loadDefaultValueList } from '../common/valueLists/valueListInjectors'
import SnackbarProvider from '../../components/layout/snackbar/SnackbarContext'
import GestionTache from './gestionTache/GestionTache'
import SuiviConnexions from './administration/connexions/SuiviConnexions'
import SuiviAvancement from './suiviAvancement/SuiviAvancement'
import Mobile from './mobile/Mobile'
import { BrowserView, isBrowser, MobileView } from 'react-device-detect'
import BesoinsProduction from "./besoinsProduction/BesoinsProduction";
import TourPlaine from "./tourPlaine/TourPlaine";
import TableauDeBordPage from "./tableauDeBord/TableauDeBordPage";
import { PROFILS } from "../../utils/constants";
import * as userSelectors from "../common/user/services/userSelectors";
import { hasAccess } from "../../components/security/AccessChecker";
import { compose } from "redux";
import { connect } from "react-redux";
import { ArcElement, BarController, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js'
import GestionRessource from "./gestionRessource/GestionRessource";
import DetailRessourceOnglet from "./gestionRessource/component/DetailRessourceOnglet";
import AjoutRessourceOnglet from "./gestionRessource/component/AjoutRessourceOnglet";

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	ArcElement
);

const FrontOffice = ({ userAuthorities }) => <SnackbarProvider>
	<BrowserView>
		<Route exact path={isBrowser ? '/(accueil|)' : '/accueil'} component={(hasAccess(PROFILS.CHEF, userAuthorities) || hasAccess(PROFILS.MANAGER, userAuthorities)) ? TableauDeBordPage : AccueilPage}/>
		{(hasAccess(PROFILS.CHEF, userAuthorities) || hasAccess(PROFILS.SUPERVISEUR, userAuthorities)) &&
			<Route exact path="/besoins-production" component={BesoinsProduction} key={crypto.randomUUID()}/>
			/* La key permet au composant de se rerendre quand on y accède depuis la notification */
		}
		{(hasAccess(PROFILS.CHEF, userAuthorities) || hasAccess(PROFILS.SUPERVISEUR, userAuthorities) || hasAccess(PROFILS.MANAGER, userAuthorities)) &&
			<div>
				<Route exact path="/planning" component={Planning}/>
				<Route exact path="/liste-assolements" component={ListAssolement}/>
				<Route exact path="/tour-plaine" component={TourPlaine}/>
				<Route exact path="/planification-taches" component={GestionTache}/>
			</div>
		}
		{(hasAccess(PROFILS.SUPERVISEUR, userAuthorities) || hasAccess(PROFILS.MANAGER, userAuthorities)) &&
			<div>
				<Route exact path="/suivi-avancement" component={SuiviAvancement}/>
				<Route exact path="/gestion-ressources" component={GestionRessource}/>
				<Route exact path="/ajout-ressource" component={AjoutRessourceOnglet}/>
				<Route exact path="/detail-ressource/:id" component={DetailRessourceOnglet}/>
				<Route exact path="/suivi-connexions" component={SuiviConnexions}/>
			</div>
		}
		<Route exact path="/calcul" component={Calcul}/>
	</BrowserView>
	<MobileView>
		<Route component={Mobile}/>
	</MobileView>
</SnackbarProvider>


const mapStateToProps = state => ({
	userAuthorities: userSelectors.getAuthorities(state),
})

export default compose(
	connect(mapStateToProps),
	loadDefaultValueList
)(FrontOffice)