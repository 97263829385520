import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getList } from './fermeSelectors'
import { getFermeList } from './fermeActions'

export const injectFermeList = (WrappedComponent) => {
	const FermeInjector = (props) => {
		React.useEffect(() => {
			if (!props.fermeList || props.fermeList.length === 0) {
				props.getFermeList()
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		fermeList: getList(state) || []
	})

	const actions = {
		getFermeList
	}

	FermeInjector.propTypes = {
		fermeList: PropTypes.array,
		getFermeList: PropTypes.func
	}

	return connect(mapStateToProps, actions)(FermeInjector)
}
