import React from 'react'
import {withStyles} from '@material-ui/core'
import {FormattedDate, FormattedMessage} from 'react-intl'
import Grid from '@material-ui/core/Grid'
import {getStyles} from 'isotope-client'
import {Field, reduxForm} from 'redux-form'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../../../components/form/Input'
import {colors} from '../../../../../../utils/constants'
import {getQuantiteHebdo} from '../quantite/QuantiteForm'

const styles = () => getStyles({
	form: {
		width: '100%',
		padding: '10px 15px',
		fontFamily: 'Lato',

		'& p' : {
			marginLeft: 25,

			'& > span': {
				marginLeft: 25,

				'& > span': {
					fontSize: 11,
					color: colors.empty,
					margin: 6
				}
			}
		}
	},
	field: {
		marginTop: 10
	}
})

export const COMMENTAIRE_FORM = 'CommentaireForm'

const field = 'commentaire'

const CommentaireForm = ({
	classes,
	row,
	cultures,
	handleSubmit
}) => {
	const culture = cultures.find(c => c.id === row.culture)

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid container direction="column">
				{ culture && <p>
					<FormattedMessage id="besoinsProduction.recueil.popin.fields.culture"/> : <span><b>{culture.nom}</b></span>
				</p>}
				{row.quantiteKg && <p><FormattedMessage id="besoinsProduction.recueil.popin.fields.quantite"/> : <span><b>{row.quantiteKg}</b> <span><FormattedMessage id="besoinsProduction.unites.kg"/></span></span><span><b>{getQuantiteHebdo(row)}</b> <span><FormattedMessage id="besoinsProduction.unites.kgParSemaineFull"/></span></span></p>}
				{row.dateDebutRecolte && <p><FormattedMessage id="besoinsProduction.recueil.popin.fields.periode"/> : <span><span><FormattedMessage id="besoinsProduction.date.debut"/></span> <b><FormattedDate value={row.dateDebutRecolte}/></b> <span><FormattedMessage id="besoinsProduction.date.fin"/></span> <b><FormattedDate value={row.dateFinRecolte}/></b></span></p>}
				<Field
					name={field}
					component={Input}
					label={<FormattedMessage id="besoinsProduction.recueil.popin.commentaire.fields.commentaire"/>}
					fromBo
					containerClassName={classes.field}
				/>
			</Grid>
		</form>
	)
}

CommentaireForm.propTypes = {
	classes: PropTypes.object,
	row: PropTypes.object,
	culture: PropTypes.object,
	handleSubmit: PropTypes.func,
}

export default compose(
	reduxForm({
		form: COMMENTAIRE_FORM
	}),
	withStyles(styles)
)(CommentaireForm)
