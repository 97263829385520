import { fetchFactory, urlUtils } from 'isotope-client'

export const createRessource = values => fetchFactory('/ressource', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const updateRessource = values => fetchFactory('/ressource', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const getRessourceById = ressourceId => fetchFactory(`/ressource/${ressourceId}`, {
	method: 'GET'
})

export const isRessourceHaveTache = ressourceId => fetchFactory(`/ressource/inactive/${ressourceId}`, {
	method: 'GET'
})

export const getAllUsersByFermeId =  (idFerme, idRessource) => fetchFactory(urlUtils.buildUrlWithParams('/bo/utilisateurs', { idFerme, isFromRessource: true, idRessource }), {
	method: 'GET',
})

export const createAbsence = values => fetchFactory('/ressource/absence', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const editAbsence = values => fetchFactory('/ressource/absence', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const deleteAbsence = absenceId => fetchFactory(`/ressource/absence/${absenceId}`, {
	method: 'DELETE'
})
