import {MenuItem, withStyles} from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Field, reduxForm} from 'redux-form'
import Select from '../../../../../components/form/Select'
import {setActiveCampagne} from '../../../../common/campagne/campagneAction'
import {getAllCampagnes} from '../../services/planningApi'
import {getStyles} from 'isotope-client'
import moment from 'moment'
import {resetFilter} from '../../services/toolbar/toolbarAction'
import {getCurrentCampagne} from '../../../../common/campagne/campagneSelector';
import {PAGE, usePageContext} from '../../../../../components/layout/PageContext';
import {FormattedMessage} from 'react-intl'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import {push} from 'connected-react-router'
import IconButton from "../../../../../components/IconButton";
import {colors} from "../../../../../utils/constants";

const CAMPAGNE_SELECT = 'CAMPAGNE_SELECT'
const FIELD = 'campagne'

const styles = () => getStyles({
	container: {
		marginBottom: 0
	},
	select: {
		paddingTop: 10
	},
	bouton: {
		color: 'white',
		backgroundColor: colors.primary,
		boxShadow: '0px 2px 4px gray',
		width: 35,
		height: 35,

		'&:hover': {
			backgroundColor: colors.lightPrimary
		}
	}
})

const CampagneSelect = ({setActiveCampagne, change, classes, resetFilter, campagne, goToResultatSolveur}) => {
	const [campagnes, setCampagnes] = React.useState([])
	const {page} = usePageContext()

	React.useEffect(() => {
		const oldCampagne = campagne
		getAllCampagnes().then(campagnes => {
			if (oldCampagne.preview) {
				setCampagnes(campagnes.filter(campagne => !campagne.finished))
			} else {
				setCampagnes(campagnes)
			}
			const today = moment()
			const campagneActuelle = campagnes.filter(c => moment(c.dateDebut).isSameOrBefore(today) && moment(c.dateFin).isSameOrAfter(today))
			if (!oldCampagne || !oldCampagne.id || campagnes.findIndex(c => c.id === oldCampagne.id) === -1) {
				const campagne = campagneActuelle.length > 0 ? campagneActuelle[0] : campagnes[campagnes.length - 1]
				if (campagne && campagne.id) {
					change(FIELD, campagne.id)
					setActiveCampagne(campagne)
				}
			} else {
				change(FIELD, oldCampagne.id)
			}
		})
	}, [])

	const onChange = (event, value) => {
		getAllCampagnes().then((campagnes) => {
				resetFilter()
				const newCampagne = campagnes.find(campagne => campagne.id === value)
				setActiveCampagne({...newCampagne, preview: campagne.preview})
			}
		)
	}

	return (
		<>
			{campagne.preview && page === PAGE.PLANNING && <>
				<IconButton
					type="secondary"
					onClick={goToResultatSolveur}
					className={classes.bouton}
				>
					<ArrowRightAlt style={{transform: 'rotate(180deg)'}}/>
				</IconButton>
				<span style={{marginLeft: 30, marginRight: 20}}><FormattedMessage id="planning.preview"/></span>
			</>}
			<Field
				component={Select}
				onChange={onChange}
				name={FIELD}
				selectClassName={classes.select}
				containerClassName={classes.container}
			>
				{campagnes.map(campagne => (
					<MenuItem value={campagne.id} key={campagne.id}>
						{campagne.nom}
					</MenuItem>
				))}
			</Field>
		</>
	)
}

const action = {
	setActiveCampagne,
	resetFilter,
	goToResultatSolveur: () => dispatch => dispatch(push('/besoins-production?onglet=resultats'))
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})


export default compose(
	connect(mapStateToProps, action),
	reduxForm({
		form: CAMPAGNE_SELECT
	}),
	withStyles(styles)
)(CampagneSelect)
