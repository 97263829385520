import React from 'react'
import PropTypes from 'prop-types'

const FrozenGroundIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 35 35",
		}}
		viewBox="0 0 35 35"
	>
		<g clipPath="url(#a)">
			<path
				fill="#72C09B"
				d="M.017 2.777a1.327 1.327 0 0 1 1.267-1.535c2.752-.088 8.834.16 13.013 3.612 1.598 1.32 2.295 2.847 2.549 4.223.726 3.941-2.188 7.47-6.197 7.502-1.399.01-3.03-.385-4.628-1.705C1.84 11.422.449 5.496.017 2.777Z"
			/>
			<path
				fill="#5C9D70"
				d="M16.846 9.077c-.254-1.376-.95-2.904-2.548-4.223-4.18-3.453-10.262-3.7-13.014-3.612A1.327 1.327 0 0 0 .017 2.777c.028.178.06.37.098.575 2.98-.02 8.475.43 12.341 3.624 1.598 1.32 2.295 2.848 2.548 4.224a6.37 6.37 0 0 1-.62 4.166c1.882-1.37 2.93-3.744 2.462-6.29Z"
			/>
			<path
				fill="#72C09B"
				d="M34.967 7.295a1.093 1.093 0 0 0-.931-1.349c-2.252-.268-7.26-.496-10.934 2.04-1.404.97-2.084 2.174-2.39 3.285-.875 3.183 1.265 6.285 4.553 6.595 1.147.108 2.514-.101 3.918-1.07 3.675-2.537 5.236-7.3 5.784-9.5Z"
			/>
			<path
				fill="#5C9D70"
				d="M34.036 5.946a22.326 22.326 0 0 0-1.082-.102c-.637 2.347-2.225 6.678-5.668 9.054-1.404.97-2.77 1.179-3.918 1.07a5.331 5.331 0 0 1-2.167-.68c.805 1.4 2.262 2.408 4.064 2.578 1.147.108 2.514-.101 3.918-1.07 3.675-2.537 5.236-7.3 5.784-9.5a1.093 1.093 0 0 0-.931-1.35Z"
			/>
			<path
				fill="#5C9D70"
				d="M6.926 7.27c.036.023 3.62 2.325 5.555 4.446 3.077 3.373 4.77 7.78 4.767 12.406l-.004 5.823h1.063l-.003-4.32c-.002-3.786 1.384-7.392 3.902-10.153 1.699-1.862 3.764-3.364 6.139-4.464a.528.528 0 1 0-.444-.959c-2.503 1.16-4.682 2.744-6.475 4.711a15.94 15.94 0 0 0-3.42 5.933 19.291 19.291 0 0 0-4.745-9.689C11.234 8.781 7.647 6.477 7.495 6.38a.528.528 0 0 0-.57.89Z"
			/>
			<path
				fill="#C6865C"
				d="m29.929 32.317-.017-.02c-.945-1.068-2.24-1.5-3.468-1.3a5.985 5.985 0 0 0-.78-1.118c-.84-.95-1.893-1.517-2.986-1.698-1.18-1.563-2.933-2.555-4.892-2.555-1.96 0-3.713.992-4.892 2.555-1.093.181-2.146.747-2.987 1.698a5.984 5.984 0 0 0-.779 1.119c-1.229-.202-2.523.231-3.469 1.3l-.016.019a1.34 1.34 0 0 0-.328.926h24.941a1.341 1.341 0 0 0-.327-.926Z"
			/>
			<path
				fill="#B16E3D"
				d="m29.929 32.317-.017-.02c-.945-1.068-2.24-1.5-3.468-1.3a5.984 5.984 0 0 0-.78-1.118c-.84-.95-1.893-1.517-2.986-1.698-1.18-1.563-2.933-2.555-4.892-2.555-.46 0-.909.055-1.341.159 1.292.31 2.44 1.06 3.319 2.104.155.185.37.31.605.37.958.238 1.869.778 2.613 1.62.163.183.312.376.449.576.21.308.555.494.928.495 1.04.002 2.078.451 2.871 1.348l.017.019c.236.27.336.602.327.926h2.682a1.34 1.34 0 0 0-.327-.926Z"
			/>
			<path
				fill="#804F2B"
				d="M32.756 33.771H2.16a.528.528 0 1 1 0-1.056h30.596a.528.528 0 1 1 0 1.056Z"
			/>
			<g clipPath="url(#b)">
				<mask
					id="c"
					width={15}
					height={15}
					x={0}
					y={17}
					maskUnits="userSpaceOnUse"
					style={{
						maskType: "luminance",
					}}
				>
					<path fill="#fff" d="M15 17H0v15h15V17Z" />
				</mask>
				<g mask="url(#c)">
					<path
						fill="#B5DBFF"
						d="M10.811 26.239V18.82a1.821 1.821 0 1 0-3.642 0v7.418a3.168 3.168 0 1 0 3.642 0Z"
					/>
					<path
						fill="#D5EAFF"
						d="M8.99 30.962a2.187 2.187 0 0 1-.764-4.238v-7.903a.765.765 0 0 1 1.529 0v7.903a2.187 2.187 0 0 1-.765 4.238Z"
					/>
					<path
						fill="#00E9FF"
						d="M8.226 25.755v.97a2.187 2.187 0 1 0 1.529 0v-.97h-1.53Z"
					/>
					<path
						fill="#00DBFF"
						d="M9.755 26.724v-.969h-1.53v.97s1.058.49 1.058 2.05c0 .866-.507 1.617-1.24 1.971a2.19 2.19 0 0 0 3.136-1.972c0-.916-.57-1.732-1.424-2.05Z"
					/>
					<path
						fill="#2F8CD6"
						d="M13.334 22.28H12.02a.226.226 0 1 1 0-.453h1.314a.226.226 0 0 1 0 .453ZM12.617 20.445h-.597a.226.226 0 1 1 0-.452h.597a.226.226 0 0 1 0 .452ZM12.828 18.61h-.808a.226.226 0 1 1 0-.452h.808a.226.226 0 1 1 0 .453ZM12.828 25.95h-.808a.226.226 0 1 1 0-.454h.808a.226.226 0 1 1 0 .453ZM12.617 24.114h-.597a.226.226 0 1 1 0-.452h.597a.226.226 0 0 1 0 .453Z"
					/>
					<path
						fill="#00DBFF"
						d="m5.88 21.509-.387-.217.284-.116a.226.226 0 1 0-.172-.419l-.617.253-.81-.454.81-.453.617.253a.226.226 0 1 0 .172-.419l-.284-.116.386-.217a.226.226 0 0 0-.22-.395l-.387.216.049-.302a.226.226 0 1 0-.447-.073l-.107.658-.825.462v-.946l.522-.415a.226.226 0 1 0-.282-.354l-.24.19v-.442a.226.226 0 1 0-.453 0v.443l-.24-.191a.226.226 0 1 0-.282.354l.522.415v.946l-.826-.462-.107-.658a.226.226 0 1 0-.446.073l.049.302-.386-.216a.226.226 0 0 0-.222.395l.387.217-.284.116a.226.226 0 1 0 .171.419l.617-.253.81.453-.81.454-.617-.253a.226.226 0 0 0-.171.419l.284.116-.387.217a.226.226 0 0 0 .222.395l.386-.217-.05.303a.226.226 0 1 0 .447.073l.107-.658.826-.462v.946l-.522.415a.226.226 0 1 0 .282.354l.24-.19v.442a.226.226 0 1 0 .453 0v-.443l.24.191a.225.225 0 0 0 .318-.036.226.226 0 0 0-.036-.318l-.522-.415v-.946l.825.462.107.658a.226.226 0 1 0 .447-.072l-.05-.304.387.217a.225.225 0 0 0 .308-.087.226.226 0 0 0-.087-.308Z"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h35v35H0z" />
			</clipPath>
			<clipPath id="b">
				<path fill="#fff" d="M0 17h15v15H0z" />
			</clipPath>
		</defs>
	</svg>
)


FrozenGroundIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

FrozenGroundIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default FrozenGroundIcon