import React from 'react'
import Grid from '@material-ui/core/Grid/index'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import Stats from './synthese/Stats'
import PlanificationVsCharge from './synthese/PlanificationVsCharge'
import TachesReportOuAnticipation from './synthese/TachesReportOuAnticipation'
import TachesParFamille from './synthese/TachesParFamille'
import {getSyntheseDatas, getSyntheseDetailDatas} from '../services/api'
import LoaderAdvanced from 'react-loader-advanced'
import Switch from '../../../../components/Switch'
import Typography from '@material-ui/core/Typography/index'
import Button from '@material-ui/core/Button'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import {FormattedMessage, useIntl} from 'react-intl'
import {useGestionTacheContext} from '../GestionTacheProvider'
import {colors, ETAT_TACHE_UPPERCASE, ONGLET_GESTION_TACHE} from '../../../../utils/constants'
import {LocalDate} from "../../../../utils/date/local-date";
import {injectPlanificationFilter} from "../injectors/filterInjector";
import {compose} from "redux";
import {saveCurrentFilter, setFilterUsed} from "../../planning/services/toolbar/toolbarAction";
import {connect} from "react-redux";
import {FILTER_FIELDS} from "../form/FilterForm";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => getStyles({
	withBorder: {
		border: '0.5px solid lightgray'
	},
	link: {
		color: theme.palette.primary.main,
		textTransform: 'none'
	}
})

const TACHE_TYPE_SWITCH = [
	{
		id: 'tachesPlanifiees',
		tachesPlanifiees: true,
		label: 'gestionTaches.ecranSynthese.details.type.tachesPlanifiees'
	},
	{
		id: 'tachesNonPlanifiees',
		tachesPlanifiees: false,
		label: 'gestionTaches.ecranSynthese.details.type.tachesNonPlanifiees'
	}
]

const SyntheseOnglet = ({classes, saveCurrentFilter, setFilterUsed}) => {

	const [datas, setDatas] = React.useState(undefined)
	const [detailDatas, setDetailDatas] = React.useState(undefined)
	const [tachesPlanifiees, setTachesPlanifiees] = React.useState(TACHE_TYPE_SWITCH[0].tachesPlanifiees)
	const {setOnglet} = useGestionTacheContext()
	const intl = useIntl()

	React.useEffect(() => {
		getSyntheseDatas().then(setDatas)
	}, [])

	React.useEffect(() => {
		getSyntheseDetailDatas(tachesPlanifiees).then(setDetailDatas)
	}, [tachesPlanifiees])

	return (
		<LoaderAdvanced
			show={!datas || !detailDatas}
			message={<CircularProgress/>}
			backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.5)'}}
		>
			<Grid container item direction="column" style={{padding: '1%', gap: 20}}>
				{datas && datas.semainesNonInitialisees && datas.semainesNonInitialisees.length > 0 && <Typography style={{color: colors.error}}>
					<FormattedMessage
						id="gestionTaches.ecranSynthese.error"
						values={{
							semaines: datas.semainesNonInitialisees
								.map(semaine => intl.formatMessage({id: 'gestionTaches.ecranSynthese.errorDetail'}, {semaine: LocalDate.fromAPI(semaine).weekNumber}))
								.join(' et '),
							quantity: datas.semainesNonInitialisees.length
						}}
					/>
				</Typography>}
				{datas && <Grid container item>
					<Grid container item direction="column" justify="center" lg={4} md={12} style={{gap: 15, marginBottom: 20}}>
						<Stats datas={datas.stats}/>
					</Grid>
					<Grid container item lg={8} md={12} className={classes.withBorder}>
						<PlanificationVsCharge datas={datas.planificationVsCharge}/>
					</Grid>
				</Grid>}
				{detailDatas && <Grid container item direction="column" className={classes.withBorder} style={{padding: 20}}>
					<Grid container item>
						<Grid container item justify="flex-start" alignItems="center" xs={4}>
							<Switch
								items={TACHE_TYPE_SWITCH}
								setValue={(item) => setTachesPlanifiees(item.tachesPlanifiees)}
								defaultValue={TACHE_TYPE_SWITCH[0]}
								additionalStyle={{width: 130, padding: 0}}
							/>
						</Grid>
						<Grid container item justify="center" alignItems="center" xs={4}>
							<Typography variant="h2"><b><FormattedMessage id="gestionTaches.ecranSynthese.details.title"/></b></Typography>
						</Grid>
						<Grid container item justify="flex-end" alignItems="center" xs={4}>
							<Button onClick={() => {
								if (tachesPlanifiees) {
									saveCurrentFilter({
										[FILTER_FIELDS.STATUT.name]: [
											ETAT_TACHE_UPPERCASE.A_REALISER,
											ETAT_TACHE_UPPERCASE.EN_COURS,
											ETAT_TACHE_UPPERCASE.EN_ATTENTE
										]
									})
								} else {
									saveCurrentFilter({
										[FILTER_FIELDS.STATUT.name]: [ETAT_TACHE_UPPERCASE.A_PLANIFIER]
									})
								}
								setFilterUsed(true)
								setOnglet(ONGLET_GESTION_TACHE.PLANIFICATION)
							}} className={classes.link}>
								<ZoomInIcon/>
								<Typography className={classes.link}>
									<FormattedMessage id="gestionTaches.ecranSynthese.details.link"/>
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid container item>
						<Grid container item lg={6} md={12}>
							<TachesReportOuAnticipation datas={detailDatas.tachesReportAnticipation}/>
						</Grid>
						<Grid container item lg={6} md={12}>
							<TachesParFamille datas={detailDatas.tachesParFamille}/>
						</Grid>
					</Grid>
				</Grid>}
			</Grid>
		</LoaderAdvanced>
	)
}

const actions = {
	saveCurrentFilter,
	setFilterUsed
}

export default compose(
	connect(undefined, actions),
	withStyles(styles),
	injectPlanificationFilter
)(SyntheseOnglet)