import { LOGIN_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH, GET_USER_SUCCESS } from './loginActions'
import { removeLocalUser as removeLocalUserApi } from './loginApi'

export default function (localStorage) {
	const token = localStorage.getItem('token')
	const refreshToken = localStorage.getItem('refreshToken')

	const initialState = {
		token,
		refreshToken,
		authenticated: false
	}

	return (state = initialState, action = {}) => {
		switch (action.type) {
		case LOGIN_SUCCESS:
		case TOKEN_REFRESH:
			return {
				...state,
				token: action.token,
				refreshToken: action.refreshToken,
				authenticated: true
			}
		case GET_USER_SUCCESS: {
			const user = action.user
			return {
				...state,
				...user
			}
		}
		case LOGOUT_SUCCESS:
			removeLocalUserApi()
			return {}
		default:
			return state
		}
	}
}
