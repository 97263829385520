import React from 'react'
import PropTypes from 'prop-types'
import { injectTypeSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'

/** Composant qui permet l'affichage du type de surface **/

const TypeSurface = ({ planche, typeSurfaceList }) => {
	const typeSurface = (typeSurfaceList.find(type => type.code === planche.typePlanche) || {}).label || DEFAULT_LABEL

	return <>{typeSurface} - {planche.surface} m²</>
}

TypeSurface.propTypes = {
	planche: PropTypes.object,
	typeSurfaceList: PropTypes.array
}

export default injectTypeSurfaceValueList(TypeSurface)
