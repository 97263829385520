import React from 'react'
import {DatePicker} from '@material-ui/pickers'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {LocalDate} from '../../utils/date/local-date'

const styles = () => getStyles({
	weekNumber: {
		position: 'absolute',
		left: -26,
		top: 9,
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: 13,
		fontWeight: 500,
		opacity: 0.5,
		pointerEvents: 'none'
	}
})

const DateField = ({
					   input: {onBlur, value, ...inputProps},
					   meta: {submitting, touched, invalid},
					   onInputChange,
					   classes,
					   shouldDisableDate,
					   periods,
					   isDebut,
					   ...rest
}) => {
	const intl = useIntl()

	const baseStyle = {
		width: 33,
		height: 33,
		margin: '0 2px',
		padding: 0,
		fontSize: 11,
		fontWeight: 500
	}

	const styleDay = {
		...baseStyle,
		color: 'rgba(0, 0, 0, 0.87)'
	}

	const styleDaySelect = {
		...baseStyle,
		color: '#FFFF',
		backgroundColor: '#804180'
	}

	const styleDayOut = {
		...baseStyle,
		color: 'rgba(0, 0, 0, 0.87)',
		opacity: 0,
		pointerEvents: 'none'
	}

	const styleDisabled = {
		...baseStyle,
		color: 'rgba(0, 0, 0, 0.38)',
		pointerEvents: 'none'
	}

	const onChange = date => {
		let dateString = Date.parse(date) ? date.toISOString() : null
		inputProps.onChange(dateString)
		if (onInputChange) {
			onInputChange(dateString)
		}
	}

	const renderDay = (day, selectedDate, isInCurrentMonth) => {
		const momentDay = moment(day.getTime())
		const isSelected = +day === +selectedDate
		const isDisabled = shouldDisableDate(day, isDebut)
		const week = LocalDate.fromJSWithTimezone(day, 'UTC+2').weekNumber
		const isInPeriod = periods?.some(period => week >= period.debut && week <= period.fin)

		const getStyle = () => {
			let style
			if (isSelected) {
				style = styleDaySelect
			} else {
				if (isDisabled) {
					style = styleDisabled
					if (isInPeriod) {
						style = {
							...style,
							backgroundColor: "#ecf1ed"
						}
					}
				} else {
					style = styleDay
					if (isInPeriod) {
						style = {
							...style,
							backgroundColor: "#e8f5e9"
						}
					}
				}
			}
			return style
		}

		return <div style={{position: 'relative'}} onClick={(event) => {
			if (isDisabled) {
				event.stopPropagation()
			}
		}}>
			{day.getDay() === 1 && <div className={classes.weekNumber}>{intl.formatMessage({id: 'planning.calendar.shortLabelWeek'})}{momentDay.format('ww')}</div>}
			{
				isInCurrentMonth ?
					<button className="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day" tabIndex="0" type="button" style={getStyle()}>
						<span className="MuiIconButton-label">
						<p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">{day.getDate()}</p>
						</span>
						<span className="MuiTouchRipple-root"></span>
					</button>
					: <button className="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-hidden" tabIndex="-1" type="button" style={styleDayOut}>
						<span className="MuiIconButton-label">
							<p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">{day.getDate()}</p>
						</span>
						<span className="MuiTouchRipple-root"></span>
					</button>
			}
		</div>
	}

	return <DatePicker
		autoOk
		{...inputProps}
		{...rest}
		format={"dd/MM/yyyy"}
		value={value ? new Date(value) : null}
		disabled={submitting}
		onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
		error={touched && invalid}
		renderDay={renderDay}
		onChange={onChange}
	/>

}

export default withStyles(styles)(DateField)