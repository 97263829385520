import { combineReducers } from 'redux'
import { MAX_PAGE_NUMBER } from '../../utils/constant'
import * as commentActions from '../commentaires/commentairesActions'
import { SAVE_COMMENTAIRE_SUIVI_SUCCESS } from '../commentaires/commentairesActions'
import { GET_ETAPES_PLANCHE } from './assolementAction'
import * as actions from './assolementAction'
import { SELECT_ASSOLEMENT } from './assolementAction'
import { SET_ACTIVE_CAMPAGNE } from '../../../../common/campagne/campagneAction'

const loading = (state = false, action) => {
	switch (action.type) {
		case actions.GET_ASSOLEMENTS_REQUEST:
			return true
		case actions.GET_ASSOLEMENTS_SUCCESS:
		case actions.GET_ASSOLEMENTS_ERROR:
			return false
		default:
			return state
	}
}

const loadingMore = (state = false, action) => {
	switch (action.type) {
		case actions.ADD_ASSOLEMENTS_REQUEST:
			return true
		case actions.ADD_ASSOLEMENTS_SUCCESS:
		case actions.ADD_ASSOLEMENTS_ERROR:
			return false
		default:
			return state
	}
}

const handleTaskThatChangeCampagne = (action, state, replaceAssolement) => state.reduce((acc, curr) => {
	const idAssolementOrigine = action.idAssolement || action.assolement.id
	if (idAssolementOrigine === curr.id) {
		acc.push(replaceAssolement(curr))
		if (idAssolementOrigine !== action.assolement.id) {
			acc.push({
				...curr,
				deleted: true
			})
		}
	} else {
		acc.push(curr)
	}
	return acc
}, [])

const assolementSortedList = (state = [], action) =>{
	switch (action.type) {
		case actions.GET_ASSOLEMENTS_SUCCESS:
			return action.assolements
		case actions.CLEAR_ASSOLEMENT_LIST:
			return []
		case actions.ADD_ASSOLEMENTS_SUCCESS:
			const page = action.page
			const isScrollingDown = action.currentPage.end < action.page.number
			let start, end
			const pageNumber = action.currentPage.end - action.currentPage.start + 1
			if(isScrollingDown) {
				start = pageNumber === 1 ? 0 : page.size
				end = pageNumber * page.size
			} else {
				start = 0
				end = pageNumber === 1 ? page.size : (pageNumber - 1) * page.size
			}
			const shortenState = state.slice(start, end)
			if(isScrollingDown){
				return [...shortenState, ...action.assolements]
			}
			return [...action.assolements, ...shortenState]
		case actions.MODIFIER_PERIODE_ASSOLEMENT_SUCCESS:
		case actions.MODIFIER_ASSOLEMENT_SUCCESS:
		case SAVE_COMMENTAIRE_SUIVI_SUCCESS:
		case commentActions.DELETE_COMMENTAIRE_SUIVI_SUCCESS:
		case actions.MODIFIER_PERIODE_ETAPE_LIST_SUCCESS:
			return handleTaskThatChangeCampagne(action, state, () => action.assolement)
		case actions.SUIVI_ETAPE_SUCCESS:
		case commentActions.DELETE_COMMENTAIRE_ETAPE_SUCCESS:
			return state.map(assolement => {
				if(assolement.id === action.assolementEtape.idAssolement){
					return {
						...assolement,
						assolementEtapeList: assolement.assolementEtapeList.map(etape => (etape.id === action.assolementEtape.id)?action.assolementEtape: etape)
					}
				}
				return assolement
			})
		case actions.DELETE_ASSOLEMENT_SUCCESS:
			return state.map(assolement => (action.listAssolementsID.includes(assolement.id))?{...assolement, deleted: true}:assolement)
		case actions.DELETE_ASSOLEMENT_ETAPE_SUCCESS:
			return handleTaskThatChangeCampagne(action, state, () => ({...action.assolement}))
		case commentActions.SAVE_COMMENTAIRE_PLANCHE_SUCCESS:
			return updatePlanche(state, action.idPlanche, (planche) => ({
				...planche,
				commentaires: [
					...planche.commentaires.filter(comment => comment.id !== action.commentaire.id),
					action.commentaire
				]
			}))
		case commentActions.DELETE_COMMENTAIRE_PLANCHE_SUCCESS:
			return updatePlanche(state, action.idPlanche, (planche) => ({
				...planche,
				commentaires: planche.commentaires.filter(comment => comment.id !== action.idCommentaire)
			}))
		case GET_ETAPES_PLANCHE:
			return action.assolementsFiltered
		default:
			return state
	}
}

const updatePlanche = (state, plancheId, updateFct) => state.map(asso => asso.planche.id === plancheId ? {
	...asso,
	planche: updateFct(asso.planche)
} : asso)

const currentAssolement = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_ASSOLEMENTS_SUCCESS:
			if (!state || !state.id) {
				return state
			}
			const newAsso = action.assolements.find(asso => asso.id === state.id) || {}
			return !newAsso.planche ? state : newAsso
		case SELECT_ASSOLEMENT:
		case actions.MODIFIER_PERIODE_ASSOLEMENT_SUCCESS:
		case actions.MODIFIER_ASSOLEMENT_SUCCESS:
		case SAVE_COMMENTAIRE_SUIVI_SUCCESS:
		case commentActions.DELETE_COMMENTAIRE_SUIVI_SUCCESS:
		case actions.MODIFIER_PERIODE_ETAPE_LIST_SUCCESS:
			return action.assolement || state
		case commentActions.SAVE_COMMENTAIRE_PLANCHE_SUCCESS: {
			if (state.planche && state.planche.id === action.idPlanche) {
				const planche = state.planche
				return {
					...state,
					planche: {
						...planche,
						commentaires: [
							...planche.commentaires.filter(comment => comment.id !== action.commentaire.id),
							action.commentaire
						]
					}
				}
			}
			return state
		}
		case commentActions.DELETE_COMMENTAIRE_PLANCHE_SUCCESS:{
			if (state.planche && state.planche.id === action.idPlanche) {
				const planche = state.planche
				return {
					...state,
					planche: {
						...planche,
						commentaires: [
							...planche.commentaires.filter(comment => comment.id !== action.idCommentaire)
						]
					}
				}
			}
			return state
		}
		case actions.UPDATE_TASK:
			// cas de la liste des etapes ou il n'y a pas forcement de d'assolemment selectionné
			if(!state.assolementEtapeList){
				return state
			}
			return {
				...state,
				assolementEtapeList: state.assolementEtapeList.map(task => task.id === action.newTask.id? action.newTask : task)
			}
		case actions.DELETE_ASSOLEMENT_SUCCESS:
			return {}
		default:
			return state
	}
}

const currentEtape = (state = null, action) => {
	switch (action.type) {
		case SELECT_ASSOLEMENT:
			return action.idEtape
		default:
			return state
	}
}

const detailPanelType = (state = null, action) => {
	switch (action.type) {
		case SELECT_ASSOLEMENT:
			return action.panelType
		default:
			return state
	}
}

const comparaison = (state = false, action) => {
	switch (action.type) {
		case actions.SET_COMPARE:
			return action.value
		case SET_ACTIVE_CAMPAGNE:
			return false
		default:
			return state
	}
}

const activeRow = (state = null, action) => {
	switch (action.type) {
		case actions.SET_ACTIVE_ROW:
			return action.id
		default:
			return state
	}
}
const initialState = {
	start:0,
	end:0,
}
const currentPage = (state = initialState, action) => {
	const {content, number, ...pageDescr} = action.page || {}
	switch (action.type) {
		case actions.GET_ASSOLEMENTS_SUCCESS:
			return {
				...pageDescr,
				start: number,
				end: number,
				position: action.position
			}
		case actions.ADD_ASSOLEMENTS_SUCCESS:
			if (state.end < action.page.number) {
				return {
					...pageDescr,
					start: Math.max(0, number - (MAX_PAGE_NUMBER - 1)),
					end: action.page.number
				}
			}
			return {
				...pageDescr,
				start: action.page.number,
				end: action.page.number + MAX_PAGE_NUMBER - 1
			}
		default:
			return state
	}
}

const assolementReferenceNotOnPlanche = (state = [], action) => {
	switch (action.type) {
		case actions.GET_ASSOLEMENTS_SUCCESS:
			return action.assolementReference
		case actions.ADD_ASSOLEMENTS_SUCCESS:
			const newState = [...state]
			action.assolementReference.forEach(assoRef => {
				if(newState.find(assoState => assoState.id === assoRef.id)){
					return
				}
				newState.push(assoRef)
			})
			return newState
		default:
			return state
	}
}

const assolements = combineReducers({
	loading,
	loadingMore,
	assolementSortedList,
	currentAssolement,
	currentEtape,
	detailPanelType,
	comparaison,
	activeRow,
	assolementReferenceNotOnPlanche,
	currentPage
})

export default assolements
