import React from 'react'
import PropTypes from 'prop-types'
import {Fab, Grid, Typography, withStyles} from '@material-ui/core'
import {FormSwitch, getStyles} from 'isotope-client'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {compose} from 'redux'
import Input from '../../../../../components/form/Input'
import {FormattedMessage, useIntl} from 'react-intl'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import {connect} from 'react-redux'
import classnames from 'classnames'
import {formatInteger} from '../../../../../utils/utils'
import {colors, ONGLETS_BESOINS_PRODUCTION} from '../../../../../utils/constants'
import IconButton from '../../../../../components/IconButton'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'

const styles = () => getStyles({
	form: {
		padding: '30px 30px',
		width: '100%',

		'& .MuiFilledInput-input': {
			paddingTop: 12
		},

		'& .MuiIconButton-root': {
			padding: 9,
			color: '#FAFAFA'
		},

		'& .MuiFormControlLabel-root': {
			display: 'flex',
			flexDirection: 'row-reverse'
		}
	},
	fieldContainer: {
		marginBottom: 0
	},
	searchContainer: {
		width: '100%'
	},
	linesContainer: {
		width: '100px'
	},
	icon: {
		width: 32,
		height: 32,
		minHeight: 32
	},
	plusIcon: {
		color: `${colors.primary} !important`,
		border: '1px solid #804180',
		marginLeft: 20
	},
	boutonRetour: {
		color: 'white',
		backgroundColor: colors.primary,
		boxShadow: '0px 2px 4px gray',
		width: 35,
		height: 35,

		'&:hover': {
			backgroundColor: colors.lightPrimary
		}
	}
})

const FIELDS = {
	COMMENCE_PAR: { name: 'commencePar' },
	NB_LIGNES: { name: 'nbLignes' },
	PERIODE_WITH_ALERTE: { name: 'periodeWithAlerte' },
	QUANTITE_WITH_ALERTE: { name: 'quantiteWithAlerte' }
}

const BesoinsProductionFilters = (
	{
		children,
		addLignes,
		nbLignes,
		handleSubmit,
		onglet,
		classes,
		periodeWithAlerte,
		quantiteWithAlerte,
		campagne,
		replacementText,
		changeCampagneCloturee
	}
) => {
	const intl = useIntl()

	React.useEffect(() => handleSubmit(), [periodeWithAlerte, quantiteWithAlerte])

	return (
		<>
			{replacementText ? <Grid container>{replacementText}</Grid>: <form onSubmit={handleSubmit} className={classes.form}>
				<Grid container alignItems="center">
					{campagne?.finished && onglet === ONGLETS_BESOINS_PRODUCTION.RECUEIL && <Grid container item direction="row" alignItems="center" justify="flex-start" style={{gap: 30, minWidth: 300, maxWidth: 300}}>
						<IconButton
							type="secondary"
							onClick={() => changeCampagneCloturee(undefined)}
							className={classes.boutonRetour}
						>
							<ArrowRightAlt style={{transform: 'rotate(180deg)'}}/>
						</IconButton>
						<Typography variant="h2">{campagne.nom}</Typography>
					</Grid>}
					{onglet === ONGLETS_BESOINS_PRODUCTION.RESULTATS && <Grid container item xs direction="row" alignItems="center" justify="flex-start" style={{gap: 30, minWidth: 500, maxWidth: 500}}>
						<Field
							label={<FormattedMessage id={`besoinsProduction.filter.${FIELDS.PERIODE_WITH_ALERTE.name}`} />}
							name={FIELDS.PERIODE_WITH_ALERTE.name}
							component={FormSwitch}
						/>
						<Field
							label={<FormattedMessage id={`besoinsProduction.filter.${FIELDS.QUANTITE_WITH_ALERTE.name}`} />}
							name={FIELDS.QUANTITE_WITH_ALERTE.name}
							component={FormSwitch}
						/>
					</Grid>}
					<Grid container item xs alignItems="center">
						<Field
							name={FIELDS.COMMENCE_PAR.name}
							type="text"
							component={Input}
							placeholder={intl.formatMessage({ id: 'besoinsProduction.filter.placeholder' })}
							containerClassName={classnames(classes.fieldContainer, classes.searchContainer)}
							fromBo
							adornment={
								<Fab color="primary" onClick={handleSubmit} className={classes.icon}>
									<SearchIcon />
								</Fab>
							}
							adornmentIcon
						/>
						<button type="submit" style={{display: 'none'}}></button> {/* Pour déclencher la recherche avec la touche Entrée */}
					</Grid>
					{!campagne?.finished && onglet === ONGLETS_BESOINS_PRODUCTION.RECUEIL && <Grid container item alignItems="center" justify="flex-end" style={{minWidth: 300, maxWidth: 300}}>
						<Typography><FormattedMessage id="besoinsProduction.filter.ajouter"/></Typography>
						<Field
							name={FIELDS.NB_LIGNES.name}
							type="number"
							component={Input}
							format={formatInteger}
							containerClassName={classnames(classes.fieldContainer, classes.linesContainer)}
							fromBo
							inputProps={{ min: 0 }}
						/>
						<Typography><FormattedMessage id="besoinsProduction.filter.lignes"/></Typography>
						<IconButton onClick={() => addLignes(nbLignes)} className={classes.plusIcon}>
							<AddIcon />
						</IconButton>
					</Grid>}
				</Grid>
			</form>}
			{children}
		</>
	)
}

BesoinsProductionFilters.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node,
	nbLignes: PropTypes.number,
	handleSubmit: PropTypes.func,
	addLignes: PropTypes.func,
	campagne: PropTypes.object
}

const mapStateToProps = (state, {onglet}) => {
	const formName = `filters-${onglet.code}`
	return {
		form: formName,
		[FIELDS.NB_LIGNES.name]: +formValueSelector(formName)(state, FIELDS.NB_LIGNES.name),
		[FIELDS.PERIODE_WITH_ALERTE.name]: formValueSelector(formName)(state, FIELDS.PERIODE_WITH_ALERTE.name),
		[FIELDS.QUANTITE_WITH_ALERTE.name]: formValueSelector(formName)(state, FIELDS.QUANTITE_WITH_ALERTE.name),
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		initialValues: {
			[FIELDS.NB_LIGNES.name]: 5,
			[FIELDS.PERIODE_WITH_ALERTE.name]: false,
			[FIELDS.QUANTITE_WITH_ALERTE.name]: false
		}
	}),
	withStyles(styles)
)(BesoinsProductionFilters)
