import { ETAPE_TYPE } from '../../../../utils/constants'

export const EN_TERRE = [ETAPE_TYPE.SEMI_DIRECT, ETAPE_TYPE.SEMI_EN_CONTENANT]


export const COMMENTAIRE_PLANCHE_FORM_NAME = 'commentairePlanche'
export const COMMENTAIRE_SUIVI_FORM_NAME = 'commentaireSuivi'
export const FERTILIZATION_FORM = "FERTILIZATION_FORM"
export const DEPLACER_CULTURE_FORM_NAME = 'DeplacerCultureForm'
export const EDIT_ITK_FORM_NAME = 'EditItineraireForm'
export const SUIVI_ITK_DETAIL = 'SuiviItkDetailForm'
export const CREER_GROUPE_DE_CULTURE_FORM_NAME = 'CreerGroupeDeCultureForm'

export const COMMENT_FIELDS = {
	TYPE_COMMENTAIRE_SUIVI: 'typeCommentaire',
	TYPE_VARIATION_IMPACT_PROD: 'typeVariation',
	VARIATION: 'variation',
	COMMENTAIRE: 'commentaire',
	DATE_APPLICATION: 'dateApplication'
}

export const SUIVI_ETAPE_FIELDS = {
	ETAT: 'etat',
	COMMENTAIRE: 'commentaire'
}

export const OPEN_PANEL = true
export const DO_NOT_OPEN_PANEL = false

export const IMPACT_PRODUCTION = 'IMPACT_PRODUCTION'

export const PANEL_TYPE = {
	CULTURE_DETAIL: 'CULTURE_DETAIL',
	SURFACE_DETAIL: 'SURFACE_DETAIL',
	ETAPE_DETAIL: 'ETAPE_DETAIL',
	CREATE_CULTURE_GROUP: 'CREATE_CULTURE_GROUP'
}

export const FILTER_PLANNING_FORM = "FILTER_PLANNING_FORM"

export const SORT = {
	SURFACE_ET_SEMIS: 'SURFACE_ET_SEMIS',
	SEMIS: 'SEMIS',
	DEFAULT: 'DEFAULT'
}

export const CHARGES_TRAVAIL = 'CHARGES_TRAVAIL'

export const EXPORT_ITEMS = [
	{
		name: 'PRODUCTION_CIBLE',
		action: 'PRODUCTION_CIBLE',
		isRevise: false
	},
	{
		name: 'PRODUCTION_ATTENDUE',
		action: 'PRODUCTION_ATTENDUE',
		isRevise: false
	},
	{
		name: 'PRODUCTION_REVISEE',
		action: 'PRODUCTION_REVISEE',
		isRevise: true
	},
	{
		name: 'PRODUCTION_REELLE',
		action: 'PRODUCTION_REELLE',
		isRevise: true
	},
	{
		name: 'BROUILLON',
		action: 'PLANNING_BROUILLON',
		isRevise: false
	},
	{
		name: 'REVISE',
		action: 'PLANNING_REVISE',
		isRevise: true
	},
	{
		name: 'VALIDE',
		action: 'PLANNING_VALIDE',
		isRevise: true
	},
	{
		name: CHARGES_TRAVAIL,
		action: CHARGES_TRAVAIL,
		isRevise: true
	}
]

export const MAX_PAGE_NUMBER = 2

export const DEFAULT_PLANNING_WIDTH = 6

export const COLLAPSED_PANEL_WIDTH = 60

export const PLANNING_LEFT_PANEL_WIDTH = 460

export const PLANNING_VISIBLE_WIDTH = window.innerWidth - COLLAPSED_PANEL_WIDTH - PLANNING_LEFT_PANEL_WIDTH

export const MIN_ROW_SIZE = 20

export const ACTION_TYPE = {
	CREATE: 'create',
	DELETE: 'delete',
	MOVE: 'move',
	RESIZE: 'resize'
}
