import FenceIcon from '../../../../../../components/icon/FenceIcon'
import GardenerIcon from '../../../../../../components/icon/GardenerIcon'
import PestIcon from '../../../../../../components/icon/PestIcon'
import PlantOneIcon from '../../../../../../components/icon/PlantOneIcon'
import WateringCanIcon from '../../../../../../components/icon/WateringCanIcon'
import BugReportIcon from '@material-ui/icons/BugReport'
import PetsIcon from '@material-ui/icons/Pets'

export const BLOCK_DEFAULT_VALUE = {
	imageUri: undefined,
	listBlock: []
}

export const BLOCK_ACTION_TYPE = {
	CROPS: { code: 'crops', icon: PlantOneIcon, label: { id: 'tourPlaine.suiviParBloc.actionTab.crops' }, notif: 0 },
	TASK: { code: 'tasks', icon: GardenerIcon, label: { id: 'tourPlaine.suiviParBloc.actionTab.tasks' }, notif: 0 },
	IRRIGATON: { code: 'irrigation', icon: WateringCanIcon, label: { id: 'tourPlaine.suiviParBloc.actionTab.irrigation' }, notif: 0 },
	BIO_AGGRESSORS: { code: 'bioAggressors', icon: PestIcon, label: { id: 'tourPlaine.suiviParBloc.bio.title' }, notif: 0 },
	BOARDS_UNAVAILABLE: { code: 'boardsUnavailable', icon: FenceIcon, label: { id: 'tourPlaine.suiviParBloc.actionTab.boardsUnavailable' }, notif: 0 }
}

/**
 * TASKS
 */
export const SVB_TASKS_CONTENT_FORM_NAME = 'SVB_TASKS_CONTENT_FORM_NAME'
export const SVB_TASKS_CONTENT_FORM_FIELDS = {
	TASK_HARVEST: 'SVB_TASK_TRANSPLANTING',
	TASK_WAITING: 'SVB_TASK_WAITING'
}

/**
 * BioAggressors
 * @type {string}
 */
export const BIO_AGGRESSOR_FORM_NAME = 'bioAggressor'
export const BOARD_UNDER_PRESSURE_VALUE = 'boardUnderPressure'

export const BIO_AGGRESSOR_TYPE = {
	MAMMAL: {
		code: 'MAMMIFERE',
		icon: PetsIcon
	},
	SOIL_BORNE: {
		code: 'TERRICOLE',
		icon: BugReportIcon
	}
}

export const BIO_AGGRESSOR_ADD_FORM_NAME = 'bioAggressorAdd'
export const BIO_AGGRESSOR_ADD_FIELDS = {
	BOARDS: 'boards',
	START: 'start',
	END: 'end',
	BIO_AGGRESSORS: 'bioAggressor'
}
export const BIO_AGGRESSOR_UPDATE_FORM_NAME = 'bioAggressorUpdate'
export const BIO_AGGRESSOR_UPDATE_FIELDS = {
	BIO_AGGRESSORS: 'bioAggressor',
	START: 'start',
	END: 'end'
}

/**
 * Irrigation
 */

export const IRRIGATION_ADD_FORM_NAME = 'irrigationAdd'
export const IRRIGATION_ADD_FIELDS = {
	SLOTS: 'slots',
	START: 'start',
	END: 'end'
}

/**
 * BoardUnavailable
 */
export const BOARD_UNAVAILABLE_FORM_NAME = {
	BOARD_UNAVAILABLE_FORM: 'boardUnavailable',
	BOARD_UNAVAILABLE_ADD_FORM: 'boardUnavailableAdd',
	BOARD_UNAVAILABLE_UPDATE_FORM: 'boardUnavailableUpdate'
}

export const BOARD_UNAVAILABLE_FIELDS = {
	BOARD_AVAILABLE_FIELD: 'boardAvailable',
	BOARDS: 'boards',
	START: 'start',
	END: 'end',
	COMMENT: 'comment'
}