import Grid from '@material-ui/core/Grid/index'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../components/Button'
import { DialogContext } from '../../../../../components/layout/dialog'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import { ASSOLEMENT_ETAT, TYPE_DIALOG } from '../../../../../utils/constants'
import { checkState } from '../../../../../utils/utils'
import { setActiveCampagne } from '../../../../common/campagne/campagneAction'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { getNotifications } from '../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../besoinsProduction/SolverStatusContextProvider'
import { reCalculate } from '../../../calcul/utils/utils'
import { loadAssolement } from '../assolement/assolementInjector'
import { validerPlanning } from '../planningApi'

/**
 * Injecteur pour la validation du planning
 */
export const injectValidatePlanning = (WrappedComponent) => {
	const ValidatePlanningInjector = (props) => {
		const { snackError, snackSuccess } = useSnackbar()
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()
		const { currentCampagne, setActiveCampagne , refreshAssolements, getNotifications } = props

		const onSuccess = (taskInProgress) => {
			if (taskInProgress.finished) {
				closeDialog()
				setActiveCampagne({
					...currentCampagne,
					etat: ASSOLEMENT_ETAT.REVISE
				})
				snackSuccess({ id: 'snackbar.update.validerPlanning' })
				reCalculate(currentCampagne.id)
				refreshAssolements()
				return true
			}
			return false
		}

		const onFailure = () => {
			snackError({ id: 'snackbar.error.validerPlanning' })
			return true
		}

		const openValidatePlanning = () => openDialog({ id: 'planning.validerPlanning.title' },
			<Grid style={{ margin: '1.5em' }}>
				<Grid><FormattedMessage id="planning.validerPlanning.text1"/></Grid><br/>
				<Grid><FormattedMessage id="planning.validerPlanning.text2"/></Grid><br/>
				<Grid><FormattedMessage id="planning.validerPlanning.text3"/></Grid>
			</Grid>, [
				<Button
					type="primary"
					onClick={() => {
							return validerPlanning(currentCampagne.id)
								.then((taskInProgress) => checkState(taskInProgress.id, onSuccess, onFailure, 1000, [refreshSolverInfosForCurrentCampagne, getNotifications]))
								.catch(onFailure)
						}
					}
				>
					<FormattedMessage id="actions.confirm"/>
				</Button>
			], TYPE_DIALOG.ACTION)

		return <WrappedComponent {...props}
		                         openValidatePlanning={React.useCallback(openValidatePlanning, [currentCampagne.id])}/>
	}

	const mapStateToProps = state => ({
		currentCampagne: getCurrentCampagne(state) || {}
	})

	const actions = {
		setActiveCampagne,
		getNotifications
	}

	ValidatePlanningInjector.propTypes = {
		currentCampagneId: PropTypes.string,
		validerPlanning: PropTypes.func
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(ValidatePlanningInjector)
}
