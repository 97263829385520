import React from 'react'
import PropTypes from 'prop-types'

const FenceIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512.001 512.001",
		}}
		viewBox="0 0 512.001 512.001"
	>
		<path
			d="M100.005 72.742a15 15 0 0 0-23.922 0l-55.849 73.547C18.262 148.894 17 152.073 17 155.34v275.284c0 8.284 7.106 14.584 15.391 14.584h111.308c8.284 0 15.302-6.3 15.302-14.584V155.34c0-3.268-1.218-6.446-3.19-9.051l-55.806-73.547z"
			style={{
				fill: "#cfa05f",
			}}
		/>
		<path
			d="M88.044 66.793a14.999 14.999 0 0 0-11.961 5.949l-55.849 73.547C18.262 148.894 17 152.073 17 155.34v275.284c0 8.284 7.106 14.584 15.391 14.584H88l.044-378.415z"
			style={{
				fill: "#facc8c",
			}}
		/>
		<path
			d="M268.005 72.742a15 15 0 0 0-23.922 0l-55.849 73.547c-1.972 2.605-3.234 5.784-3.234 9.051v275.284c0 8.284 7.106 14.584 15.391 14.584h111.308c8.284 0 15.302-6.3 15.302-14.584V155.34c0-3.268-1.218-6.446-3.19-9.051l-55.806-73.547z"
			style={{
				fill: "#cfa05f",
			}}
		/>
		<path
			d="M256.044 66.793a14.999 14.999 0 0 0-11.961 5.949l-55.849 73.547c-1.972 2.605-3.234 5.784-3.234 9.051v275.284c0 8.284 7.106 14.584 15.391 14.584H256l.044-378.415z"
			style={{
				fill: "#facc8c",
			}}
		/>
		<path
			d="M436.005 72.742a15 15 0 0 0-23.922 0l-55.849 73.547c-1.972 2.605-3.234 5.784-3.234 9.051v275.284c0 8.284 7.106 14.584 15.391 14.584h111.308c8.284 0 15.302-6.3 15.302-14.584V155.34c0-3.268-1.218-6.446-3.19-9.051l-55.806-73.547z"
			style={{
				fill: "#cfa05f",
			}}
		/>
		<path
			d="M424.044 66.793a14.999 14.999 0 0 0-11.961 5.949l-55.849 73.547c-1.972 2.605-3.234 5.784-3.234 9.051v275.284c0 8.284 7.106 14.584 15.391 14.584H424l.044-378.415z"
			style={{
				fill: "#facc8c",
			}}
		/>
		<path
			d="M512 251.265c0-19.361-15.695-35.057-35.057-35.057H35.057C15.695 216.209 0 231.904 0 251.265v66.887c0 19.361 15.695 35.057 35.057 35.057h441.887c19.361 0 35.057-15.695 35.057-35.057L512 251.265z"
			style={{
				fill: "#a67955",
			}}
		/>
		<path
			d="M256.044 216.209H35.057C15.695 216.209 0 231.904 0 251.265v66.887c0 19.361 15.695 35.057 35.057 35.057h220.987v-137z"
			style={{
				fill: "#dea06d",
			}}
		/>
	</svg>
)


FenceIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

FenceIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default FenceIcon