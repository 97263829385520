import React from 'react'
import { ActionPanelContext, DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import { PANEL_TYPE } from '../../../utils/constant'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectAssolement } from '../../assolement/assolementAction'

/**
 * Injecteur pour l'ouverture du panel de création d'un groupe de culture
 */
export const openCreateCultureGroup = (WrappedComponent) => {
	const OpenCreateCultureGroupInjector = ({ selectAssolement, ...props }) => {
		const { openPanel: openPanelDetail, closePanel: closePanelDetail } = React.useContext(DetailsPanelContext)
		const { closePanel: closePanelAction} = React.useContext(ActionPanelContext)

		const openCreateCultureGroup = (assolement) => {
			closePanelAction()
			openPanelDetail()
			selectAssolement(assolement, PANEL_TYPE.CREATE_CULTURE_GROUP)
		}

		const closeCreateCultureGroup = () => {
			closePanelDetail()
		}

		return <WrappedComponent
			{...props}
			openCreateCultureGroup={React.useCallback(openCreateCultureGroup, [])}
			closeCreateCultureGroup={React.useCallback(closeCreateCultureGroup, [])}
		/>
	}

	const actions = {
		selectAssolement
	}

	OpenCreateCultureGroupInjector.propTypes = {
		selectAssolement: PropTypes.func
	}

	return connect(undefined, actions)(OpenCreateCultureGroupInjector)
}
