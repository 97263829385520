/**
 * Formattage des values de l'espece pour l'édition
 * @param espece
 */
export const getEspeceForInitValues = (espece) => {
	return {
		id: espece.id,
		idFerme: espece.idFerme,
		name: espece.name,
		aspersionInterdite: espece.aspersionInterdite,
		codeEspece: espece.codeEspece,
		filetInsectes: espece.filetInsectes,
		rotationMinimum: espece.rotationMinimum,
		exclusionRotation: espece.exclusionRotation,
		scoreGourmandise: espece.scoreGourmandise,
		tuteuree: espece.tuteuree,
	}
}

/**
 * Formattage des values de l'espece avec la selection pour la création
 * @param selection
 */
export const getEspeceSelectionForInitValues = (selection) => {
	return {
		name: selection.label,
		codeEspece: selection.code,
		filetInsectes: false,
		exclusionRotation: false,
		tuteuree: false,
		aspersionInterdite: false,
	}
}

/**
 * Formattage des values de la famille pour l'édition
 * @param famille
 */
export const getFamilleForInitValues = (famille) => {
	return {
		id: famille.id,
		name: famille.name,
		codeFamille: famille.codeFamille,
		rotationMinimum: famille.rotationMinimum,
		rotationRecommandee: famille.rotationRecommandee,
	}
}

/**
 * Formattage des values de la famille avec la selection pour la création
 * @param selection
 */
export const getFamilleSelectionForInitValues = (selection) => {
	return {
		name: selection.label,
		codeFamille: selection.code,
	}
}