import React from 'react'
import PropTypes from 'prop-types'

const PestIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#8c8585"
			d="M235.672 188.554a7.694 7.694 0 0 1-3.772-.988c-6.519-3.656-11.842-10.14-15.393-18.752-2.488-6.033-3.294-11.065-3.377-11.62a7.728 7.728 0 0 1 15.277-2.33c.631 3.981 3.844 15.181 11.051 19.222a7.728 7.728 0 0 1-3.786 14.468zM276.359 188.554a7.726 7.726 0 0 1-3.785-14.467c7.209-4.043 10.423-15.251 11.052-19.227.666-4.203 4.611-7.089 8.815-6.441 4.207.651 7.097 4.567 6.461 8.777-.083.553-.89 5.586-3.378 11.619-3.551 8.612-8.873 15.096-15.391 18.752a7.714 7.714 0 0 1-3.774.987z"
		/>
		<path
			fill="#9e9797"
			d="M256 169.152c-16.757 0-30.341 13.584-30.341 30.341v14.826h60.682v-14.826c0-16.757-13.584-30.341-30.341-30.341z"
		/>
		<path
			fill="#8c8585"
			d="M286.339 199.494v14.828h-26.785v-14.828c0-11.95-6.912-22.283-16.946-27.228A30.23 30.23 0 0 1 256 169.155c16.761.001 30.339 13.578 30.339 30.339z"
		/>
		<path
			fill="#6c7fd8"
			d="M256 34.83C133.851 34.83 34.83 133.851 34.83 256S133.851 477.17 256 477.17 477.17 378.149 477.17 256 378.149 34.83 256 34.83zm0 399.382c-98.267 0-178.212-79.946-178.212-178.212S157.733 77.788 256 77.788 434.212 157.733 434.212 256 354.267 434.212 256 434.212z"
		/>
		<path
			fill="#4f66d0"
			d="M256 34.83c-4.492 0-8.963.134-13.392.402C358.524 42.155 450.385 138.343 450.385 256s-91.861 213.845-207.777 220.768c4.43.268 8.901.402 13.392.402 122.149 0 221.17-99.021 221.17-221.17S378.149 34.83 256 34.83z"
		/>
		<path
			fill="#99e6fc"
			d="M256 112.395c-7.692 0-13.927-6.235-13.927-13.927V13.927C242.073 6.235 248.308 0 256 0s13.927 6.235 13.927 13.927v84.541c0 7.692-6.235 13.927-13.927 13.927zM256 512c-7.692 0-13.927-6.235-13.927-13.927v-84.541c0-7.692 6.235-13.927 13.927-13.927s13.927 6.235 13.927 13.927v84.541c0 7.692-6.235 13.927-13.927 13.927zM399.605 256c0-7.692 6.235-13.927 13.927-13.927h84.541c7.692 0 13.927 6.235 13.927 13.927s-6.235 13.927-13.927 13.927h-84.541c-7.692 0-13.927-6.235-13.927-13.927zM0 256c0-7.692 6.235-13.927 13.927-13.927h84.541c7.692 0 13.927 6.235 13.927 13.927s-6.235 13.927-13.927 13.927H13.927C6.235 269.927 0 263.692 0 256z"
		/>
		<path
			fill="#8c8585"
			d="M198.862 231.377h-.01l-31.741-.042c-9.059-.011-17.354-4.593-22.189-12.254l-8.361-13.248a7.728 7.728 0 0 1 13.068-8.248l8.361 13.248a10.77 10.77 0 0 0 9.141 5.048l31.741.042c4.268.006 7.722 3.47 7.716 7.736s-3.463 7.718-7.726 7.718zM137.345 336.23a7.726 7.726 0 0 1-7.503-9.596l12.816-51.44a35.449 35.449 0 0 1 34.451-26.921h21.754a7.726 7.726 0 1 1 0 15.452h-21.754a20.022 20.022 0 0 0-19.457 15.204l-12.816 51.44a7.73 7.73 0 0 1-7.491 5.861z"
		/>
		<path
			fill="#8c8585"
			d="M176.888 362.285a7.725 7.725 0 0 1-7.726-7.726v-45.153c0-16.377 13.324-29.701 29.7-29.701a7.726 7.726 0 1 1 0 15.452c-7.856 0-14.247 6.391-14.247 14.248v45.153a7.726 7.726 0 0 1-7.727 7.727z"
		/>
		<path
			fill="#fe646f"
			d="M256 344.568c-31.556 0-57.138-25.581-57.138-57.138v-63.78c0-9.736 7.893-17.629 17.629-17.629h52.231c12.9 0 26.05 7.893 26.05 17.629 0 .001 15.469 95.577-38.772 120.918z"
		/>
		<path
			fill="#8c8585"
			d="M313.138 231.377a7.726 7.726 0 0 1-.01-15.452l31.741-.042a10.77 10.77 0 0 0 9.141-5.048l8.361-13.248a7.727 7.727 0 0 1 13.068 8.248l-8.361 13.248c-4.835 7.661-13.13 12.242-22.189 12.254l-31.741.042c-.003-.002-.007-.002-.01-.002zM374.655 336.23a7.729 7.729 0 0 1-7.491-5.86l-12.816-51.44a20.02 20.02 0 0 0-19.457-15.204h-21.754a7.726 7.726 0 1 1 0-15.452h21.754a35.448 35.448 0 0 1 34.451 26.921l12.816 51.44a7.726 7.726 0 0 1-7.503 9.595z"
		/>
		<path
			fill="#8c8585"
			d="M335.112 362.285a7.725 7.725 0 0 1-7.726-7.726v-45.153c0-7.857-6.391-14.248-14.247-14.248a7.726 7.726 0 1 1 0-15.452c16.376 0 29.7 13.324 29.7 29.701v45.153a7.727 7.727 0 0 1-7.727 7.725z"
		/>
		<path
			fill="#fd4755"
			d="M295.509 206.022h-26.787c9.736 0 17.629 7.893 17.629 17.629v63.78c0 26.942-18.652 49.514-43.744 55.543A57.231 57.231 0 0 0 256 344.568c31.556 0 57.138-25.582 57.138-57.138v-63.78c0-9.735-7.893-17.628-17.629-17.628z"
		/>
		<path
			fill="#8c8585"
			d="M278.433 248.274h-14.706v-14.706a7.726 7.726 0 1 0-15.452 0v14.706h-14.706a7.726 7.726 0 1 0 0 15.452h14.706v14.706a7.726 7.726 0 1 0 15.452 0v-14.706h14.706a7.726 7.726 0 1 0 0-15.452z"
		/>
	</svg>
)


PestIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

PestIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default PestIcon