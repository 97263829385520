import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import {getStyles} from 'isotope-client'
import mapError from './mapError'
import classnames from 'classnames'
import {default as MuiAutocomplete} from '@material-ui/lab/Autocomplete'
import {FormattedMessage} from 'react-intl'
import Chip from '@material-ui/core/Chip'
import {colors} from '../../utils/constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import {formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import withTheme from "@material-ui/core/styles/withTheme";

const styles = (theme) => getStyles({
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	option: {
		'&[aria-selected="true"]': {
			color: colors.input,
			backgroundColor: theme.palette.primary.light2,
		}
	},
	bo: {
		backgroundColor: '#f7f6f6 !important'
	}
})

const AutocompleteMulti = (Component) => (props) => {
	return <AutocompleteMultiFiltered
		Component={Component}
		{...props}
	/>
}

const AutocompleteMultiFiltered = ({ Component, input: { onChange, ...inputProps }, meta: { error, ...otherMeta }, staticOptions, containerClassName, classes, getOptions, forceGetOptions, value, fromBo, theme, ...otherProps}
) => {
	const [options, setOptions] = React.useState(staticOptions || [])
	const [loading, setLoading] = React.useState(false)
	const [inputValue, setInputValue] = React.useState('')

	return <div className={classnames(classes.container, containerClassName)}>
		<Component
			options={options}
			getOptionLabel={option => option.label}
			{...(onChange ? { onChange: (event, value) => onChange(value) } : {})}
			getOptionSelected={(option, value) => option.code === value.code}
			noOptionsText={(!options || options.length === 0) && (!inputValue || inputValue === "") ? <FormattedMessage id="field.noInput" /> : <FormattedMessage id="field.noValue" />}
			multiple
			value={value}
			onOpen={() => {
				if (forceGetOptions) {
					setLoading(true)
					getOptions().then(setOptions).finally(() => setLoading(false))
				}
			}}
			onInputChange={(event, value) => {
				if (getOptions && value !== "") {
					setInputValue(value)
					setLoading(true)
					getOptions(value).then(setOptions).finally(() => setLoading(false))
				}
			}}
			classes={{
				inputRoot: fromBo && classes.bo,
				option: classes.option
			}}
			renderInput={params => {
				return (
					<TextField
						{...otherProps}
						{...inputProps}
						{...params}
						onBlur={() => {}}
						variant="filled"
						helperText={(!otherMeta || otherMeta.touched) && error}
						meta={otherMeta}
						error={(!otherMeta || otherMeta.touched) && !!error}
					/>
				)
			}}
			loading={loading}
			loadingText={<CircularProgress size={30} />}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						size="small"
						label={option.label}
						title={option.label}
						style={{
							backgroundColor: theme.palette.primary.light2,
							color: colors.text
						}}
						{...getTagProps({ index })}
					/>
				))
			}
		/>
	</div>
}

AutocompleteMulti.defaultProps = {
	containerClassName: '',
	staticOptions: []
}

AutocompleteMulti.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	staticOptions: PropTypes.array,
	classes: PropTypes.object,
	getOptions: PropTypes.func,
	forceGetOptions: PropTypes.bool
}

const mapStateToProps = (state, { formName, input: { name } }) => ({
	value: formName ? (formValueSelector(formName)(state, name) || []) : undefined
})

export default compose(
	mapError,
	withStyles(styles),
	withTheme,
	connect(mapStateToProps)
)(AutocompleteMulti(MuiAutocomplete))

