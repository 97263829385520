import React, {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import DataTableWithLoader from "../../../../components/layout/DataTableWithLoader";
import {Field, reduxForm} from 'redux-form'
import {SP_PLANIFICATEUR_DATATABLE_KEYS, SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS} from "../../../fo/tourPlaine/component/suiviPepiniere/tool/suiviPepiniere.constants";
import {DATATABLE_SORT_ORDER} from "../../../../utils/constants";
import {Grid, MenuItem, withStyles} from "@material-ui/core";
import {capitalize, formatPositiveInteger, localStringAndNumberCompare} from "../../../../utils/utils";
import {getStyles} from "isotope-client";
import Input from "../../../../components/form/Input";
import Checkbox from "../../../../components/form/Checkbox";

import Select from "../../../../components/form/Select";
import FormattedMessageWithBold from "../../../../components/FormattedMessageWithBold";
import * as userSelectors from "../../../common/user/services/userSelectors";
import {getAuthorizeEmails} from "../services/parametresPlanificateurApi";

const styles = theme => getStyles({
	title: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.text.primary,
	},
	techniqueText: {
		fontFamily: 'Lato',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: 14,
		'& .MuiInputAdornment-root' : {
			width: 60,
			justifyContent: 'flex-end'
		}
	},
	techniqueField: {
		width: 50,
	},
	colonne: {
		paddingTop: 15,
	},
	description: {
		paddingBottom: 20,
		paddingTop: 20,
		paddingLeft: 15,
	},
	techniqueDescription: {
		fontWeight: 400,
		fontStyle: 'italic',
		paddingTop: 10,
		fontSize: 14
	}
})

const FIELDS = {
	POIDS: {name: 'poids'},
	DESCRIPTION: {name: 'description'},
	WEEK_REWARD_FILET_INSECTE: {name: 'nbWeekOverlapRewardFiletAntiInsecte'},
	WEEK_REWARD_VOILE_THERMIQUE: {name: 'nbWeekOverlapRewardVoileThermique'},
	MIN_RATIO_PROD: {name: 'minRatioManquementProductionAcceptable'},
	MAX_RATIO_SURPLUS: {name: 'maxRatioSurplusProductionAcceptable'},
	POURCENTAGE_PROPOSITION_RECETTE: {name: 'pourcentageAAjouterPropositionRecolte'},
	MAX_CUMUL_GOURMANDISE_PC: {name: 'maxCumulGourmandisePC'},
	MAX_CUMUL_GOURMANDISE_SA: {name: 'maxCumulGourmandiseSA'},
	MAX_NB_WEEK_SC: {name: 'maxNbWeekSuccessionSC'},
	MAX_NB_WEEK_SD: {name: 'maxNbWeekSuccessionSD'},
	SOLVER_CULTURE_CONSTRUCTION_LIMIT_SECONDS: {name: 'solverCultureConstructionSpentLimitSeconds'},
	SOLVER_CULTURE_LOCAL_SEARCH_LIMIT_SECONDS_PHASE: {name: 'solverCultureLocalSearchLimitSecondsPhase'},
	SOLVER_CULTURE_LOCAL_SEARCH_LIMIT_SECONDS_UNIMPROVED: {name: 'solverCultureLocalSearchLimitSecondsUnimproved'}
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = {id: 'global.errors.mandatory'}
		}
	})
	return errors
}

const PlanificateurParametres = ({params, classes, handleSubmit, isCulture, setIsFormNotSaved, pristine, user}) => {
	const [sortConstraints, setSortConstraints] = useState({key: SP_PLANIFICATEUR_DATATABLE_KEYS.CATEGORY, order: DATATABLE_SORT_ORDER.ASC})
	const [sortParams, setSortParams] = useState({order: DATATABLE_SORT_ORDER.ASC})
	const [authorize, setAuthorize] = useState(false)

	let parametres;

	if (params?.params) {
		parametres = Object.entries(params?.params).map(([param, value]) => ({param, value}))
	}

	useEffect(() => {
		 getAuthorizeEmails()
			.then((emails) => setAuthorize(emails.some((email) => user.email === email)))
	}, [])

	const paramsHeaders = useMemo(() => [
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.PARAM,
			name: <FormattedMessage id="bo.planificateur.params.table.headers.nom"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
				width: 100
			},
			sortable: true,

			sorted: sortParams.key === SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.PARAM ? sortParams.order : undefined,
			render: row => <FormattedMessage id={"bo.planificateur.params.table.param." + row?.param}/>,
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.PARAM,
			tableHeaderColumnProps: {
				className: classes.colonne,
				width: 450
			},
			name: <FormattedMessage id="bo.planificateur.params.table.headers.description"/>,

			render: row => <FormattedMessage id={"bo.planificateur.params.table.paramDescription." + row?.param}/>,
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.VALUE,
			name: <FormattedMessage id="bo.planificateur.params.table.headers.value"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
				width: 20
			},
			sortable: true,
			sorted: sortParams.key === SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS.VALUE ? sortParams.order : undefined,
			render: row => <Field
				name={row?.param}
				component={Input}
				format={formatPositiveInteger}
				adornment={(row?.param.includes('Week') && <FormattedMessage id="bo.planificateur.params.table.param.adornmentWeek"/>) || ((row?.param.includes('Ratio') || row?.param.includes('pourcentage')) && <FormattedMessage id="bo.planificateur.params.table.param.adornmentPourcentage"/>)}
				type="number"
				fromBo
			/>,
		}
	], [parametres, sortParams])

	const constraintsHeaders = useMemo(() => [
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.CATEGORY,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.category"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			sortable: true,
			sorted: sortConstraints.key === SP_PLANIFICATEUR_DATATABLE_KEYS.CATEGORY ? sortConstraints.order : undefined,
			render: row => <p>{capitalize(row?.category?.toLowerCase())}</p>
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.DESCRIPTION,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.description"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			render: row => <p>{row?.description}</p>
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.KEY,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.key"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			sortable: true,
			sorted: sortConstraints.key === SP_PLANIFICATEUR_DATATABLE_KEYS.KEY ? sortConstraints.order : undefined,
			render: row => <p>{row?.key}</p>
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.LEVEL,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.level"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			sortable: true,
			sorted: sortConstraints.key === SP_PLANIFICATEUR_DATATABLE_KEYS.LEVEL ? sortConstraints.order : undefined,
			render: row => <Field
				name={`level-${row?.key}`}
				component={Select}
				disabled={row?.parametrable === "NONE"}
				fromBo
			>
				<MenuItem key='SOFT' value='SOFT'>SOFT</MenuItem>
				<MenuItem key='MEDIUM' value='MEDIUM'>MEDIUM</MenuItem>
				<MenuItem key='HARD' value='HARD'>HARD</MenuItem>
			</Field>
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.VALUE,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.value"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			sortable: true,
			sorted: sortConstraints.key === SP_PLANIFICATEUR_DATATABLE_KEYS.VALUE ? sortConstraints.order : undefined,
			render: row => <Field
				name={`poids-${row?.key}`}
				component={Input}
				format={formatPositiveInteger}
				disabled={row?.parametrable === "NONE"}
				fromBo
				type="number"
			/>
		},
		{
			key: SP_PLANIFICATEUR_DATATABLE_KEYS.ACTIVE,
			name: <FormattedMessage id="bo.planificateur.contraintes.table.headers.enabled"/>,
			tableHeaderColumnProps: {
				className: classes.colonne,
			},
			render: row => <Field
				name={`actif-${row?.key}`}
				component={Checkbox}
				disabled={row?.parametrable === "NONE" || row?.parametrable === "VALUE_ONLY"}
			/>
		}
	], [params.constraints, sortConstraints])

	const getDisplayConstraintsData = useMemo(() => params?.constraints.sort(localStringAndNumberCompare(sortConstraints.key, sortConstraints.order === DATATABLE_SORT_ORDER.DESC)), [sortConstraints, params.constraints])
	const getDisplayParamsData = useMemo(() => {
		return isCulture ? parametres?.sort(localStringAndNumberCompare(sortParams.key, sortParams.order === DATATABLE_SORT_ORDER.DESC)) : parametres
	}, [sortParams, parametres])

	React.useEffect(() => setIsFormNotSaved(!pristine), [pristine])

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Typography className={classes.description}>
					<FormattedMessage id={'bo.planificateur.contraintes.description'}/>
				</Typography>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.title}>
							<FormattedMessage id={'bo.planificateur.contraintes.title'}/>
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<DataTableWithLoader
							nom="ConstraintsTable"
							data={getDisplayConstraintsData}
							loading={false}
							noPagination
							headers={constraintsHeaders}
							onFilterChange={key => {
								setSortConstraints(prev => {
									if (prev.key === key) {
										return ({
											...prev,
											order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
										})
									}
									return ({
										key,
										order: DATATABLE_SORT_ORDER.ASC
									})
								})
							}}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				{params?.params && <ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.title}>
							<FormattedMessage id={'bo.planificateur.params.title'}/>
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<DataTableWithLoader
							nom="ParamsTable"
							data={getDisplayParamsData}
							loading={false}
							noPagination
							headers={paramsHeaders}
							onFilterChange={key => {
								setSortParams(prev => {
									if (prev.key === key) {
										return ({
											...prev,
											order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
										})
									}
									return ({
										key,
										order: DATATABLE_SORT_ORDER.ASC
									})
								})
							}}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>}
				{authorize && (
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography className={classes.title}>
							<FormattedMessage id={'bo.planificateur.terminationConfig.title'}/>
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container direction="column">
							{[
								FIELDS.SOLVER_CULTURE_CONSTRUCTION_LIMIT_SECONDS,
								FIELDS.SOLVER_CULTURE_LOCAL_SEARCH_LIMIT_SECONDS_PHASE,
								FIELDS.SOLVER_CULTURE_LOCAL_SEARCH_LIMIT_SECONDS_UNIMPROVED
							].map(field => (
								<Grid item className={classes.techniqueText} key={field.name}>
									<Grid container direction="column">
										<Grid container>
												<span>
													<FormattedMessageWithBold id={"bo.planificateur.terminationConfig." + field.name + '.name' }/>
												</span>
										</Grid>
										<Typography className={classes.techniqueDescription}>
											<FormattedMessage id={'bo.planificateur.terminationConfig.' + field.name + '.description'}/>
										</Typography>
									</Grid>
									<Field
										name={field.name}
										className={classes.techniqueField}
										component={Input}
										format={formatPositiveInteger}
										fromBo
										adornment={<FormattedMessage id={'bo.planificateur.terminationConfig.adornmentSeconde'}/>}
										type="number"
									/>
								</Grid>
							))}
							<Grid item className={classes.techniqueText}>
								<Grid container direction={"column"}>
									<Grid container>
										<span>
											<FormattedMessageWithBold id='bo.planificateur.terminationConfig.pourcentageAAjouterPropositionRecolte.name'/>
										</span>
									</Grid>
									<Typography className={classes.techniqueDescription}>
										<FormattedMessage id={'bo.planificateur.terminationConfig.pourcentageAAjouterPropositionRecolte.description'}/>
									</Typography>
								</Grid>
								<Field
									name={FIELDS.POURCENTAGE_PROPOSITION_RECETTE.name}
									className={classes.techniqueField}
									format={formatPositiveInteger}
									component={Input}
									fromBo
									adornment={<FormattedMessage id={'bo.planificateur.terminationConfig.adornmentPourcentage'}/>}
									type="number"
								/>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>)
				}
			</form>
		</>
	)
}

PlanificateurParametres.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	params: PropTypes.object,
	isCulture: PropTypes.bool,
	pristine: PropTypes.bool
}

const mapStateToProps = (state, {formName}) => {
	return {
		form: formName,
		user: userSelectors.getUser(state),
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		validate,
		destroyOnUnmount: false,
		enableReinitialize: true
	}),
	withStyles(styles)
)(PlanificateurParametres)
