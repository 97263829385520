import * as PropTypes from 'prop-types'

export const tacheShape = PropTypes.shape({
	id: PropTypes.string,
	tache: PropTypes.string,
	culture: PropTypes.string,
	lieu: PropTypes.string,
	materiel: PropTypes.arrayOf(PropTypes.string),
	chargeTotale: PropTypes.string,
	chargeUnitaire: PropTypes.string,
	nbPersonnes: PropTypes.number,
	utilisateurs: PropTypes.arrayOf(PropTypes.string)
})