import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import {formatPositiveInteger} from "../../../../utils/utils";

const styles = theme => getStyles({
	bigField: {
		width: '100%'
	},
	field: {
		width: '50%'
	},
	row: {
		marginBottom: 15
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	}
})

const formattedMessageBase = 'bo.familleEspece.form'

const FIELDS = {
	CODE_FAMILLE: { name: 'codeFamille' },
	NAME: { name: 'name' },
	ROTATION_MINIMUM: { name: 'rotationMinimum' },
	ROTATION_RECOMMANDEE: { name: 'rotationRecommandee' },
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})
	return errors
}

const FamilleForm = ({
	classes,
	handleSubmit
}) => {

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.ROTATION_MINIMUM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.famille.${FIELDS.ROTATION_MINIMUM.name}`} />}
						fromBo
						type="number"
						format={formatPositiveInteger}
						inputProps={{ min: 0 }}
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.famille.${FIELDS.ROTATION_MINIMUM.name}Adornment`} />}					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.ROTATION_RECOMMANDEE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.famille.${FIELDS.ROTATION_RECOMMANDEE.name}`} />}
						fromBo
						type="number"
						format={formatPositiveInteger}
						inputProps={{ min: 0 }}
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.famille.${FIELDS.ROTATION_RECOMMANDEE.name}Adornment`} />}					/>
				</Grid>
			</Grid>
		</form>
	)
}

FamilleForm.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state, { formName }) => {
	return {
		form: formName,
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(FamilleForm)
