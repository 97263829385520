import { Button, withStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { colors } from '../utils/constants'
import FixedLineText from './FixedLineText'

const styles = (theme) => getStyles({
	commentContainer: {
		display: 'flex'
	},
	icon: {
		color: colors.icon,
		margin: '0 0.1em'
	},
	seeButton: {
		float: 'right',
		color: theme.palette.primary.main,
		'& span': {
			textTransform: 'initial'
		}
	}
})

const Comment = ({ comment, maxLines, classes }) => {
	const [open, setOpen] = React.useState(false)
	const [tooLong, setTooLong] = React.useState(false)

	if (!comment) {
		return null
	}

	const toggleOpen = () => setOpen(prevState => !prevState)

	return (
		<div>
			<div className={classes.commentContainer}>
				<InfoOutlined className={classes.icon}/>
				<FixedLineText open={open} text={comment} setTooLong={setTooLong} maxLines={maxLines} />
			</div>
			{tooLong &&
			<Button className={classes.seeButton} onClick={toggleOpen}>
				<FormattedMessage id={`planning.buttons.see${open ? 'Less' : 'More'}`}/>
			</Button>
			}
		</div>
	)
}

Comment.propType = {
	comment: PropTypes.string,
	maxLines: PropTypes.integer
}

Comment.defaultProps = {
	maxLines: 2
}

export default withStyles(styles)(Comment)
