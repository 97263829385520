import React from 'react'
import { withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import Autocomplete from '../../../../../../components/form/Autocomplete'
import { Field } from 'redux-form'
import CultureIcon from '../../../../../../components/icon/CultureIcon'
import { fields } from './../NouveauGroupeDeCulture'
import PropTypes from 'prop-types'
import { colors } from '../../../../../../utils/constants'

const styles = () => getStyles({
	titleField: {
		marginTop: 30,
		marginBottom: 19,
		marginLeft: 10,
		fontWeight: 'bold',
		fontSize: 18
	},
	icon: {
		marginRight: 10,
		marginTop: 5
	},
	optionLabel : {
		fontWeight: 'bold'
	},
	optionDescription: {
		marginLeft: 10,
		color: colors.icon
	}
})

export const getAutocompleteOptions = values => {
	const options = []
	values.forEach(value => {
		options.push({ label: value.nom, code: value.id, description: value.description, culture: value.groupesAssolement.map(assolement => assolement.culture.nom) })
	})
	return options
}

const formattedMessageMenuBase = 'planning.cultureGroup.addGroup'

const CultureGroupForm = ({ resetItkForm, groupes, classes }) => {

	return (
		<React.Fragment>
			<Grid className={classes.titleField}>
				<span className={classes.icon}><CultureIcon /></span>
				<FormattedMessage id={`${formattedMessageMenuBase}.cultures.title`} />
			</Grid>
			<Grid>
				<Field
					name={fields.GROUPE}
					component={Autocomplete}
					renderOption={option => (
							<Grid container direction="column">
								<Grid item className={classes.optionLabel}>
									{option.label}
								</Grid>
								<Grid item className={classes.optionDescription}>
									{option.description}
								</Grid>
							</Grid>
						)
					}
					filterOptions={(options, { inputValue }) =>
						options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()) || option.culture.some(c => c.toLowerCase().includes(inputValue.toLowerCase())))
					}
					label={<FormattedMessage id={`${formattedMessageMenuBase}.cultures.labelField`} />}
					options={getAutocompleteOptions(groupes)}
					onChange={() => resetItkForm(fields.GROUPE)}
				/>
			</Grid>
		</React.Fragment>
	)
}

CultureGroupForm.propTypes = {
	resetItkForm: PropTypes.func,
	groupes: PropTypes.array,
	classes: PropTypes.object
}

export default withStyles(styles)(CultureGroupForm)
