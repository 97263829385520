import React from 'react'
import PropTypes from 'prop-types'

const CloudyIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#8ac9fe"
			d="M301.658 175.048c-43.494 3.08-80.241 30.565-96.621 68.847a81.068 81.068 0 0 0-13.691-1.164c-44.154 0-79.952 35.788-79.952 79.952 0 4.976.453 9.838 1.329 14.556h-32.77C35.799 337.24 0 301.452 0 257.288c0-44.154 35.799-79.952 79.952-79.952 4.667 0 9.241.402 13.681 1.174 17.41-40.661 57.773-69.146 104.79-69.146 45.678 0 85.073 26.877 103.235 65.684z"
		/>
		<g fill="#60b7ff">
			<path d="M301.658 175.048c-43.494 3.08-80.241 30.565-96.621 68.847a81.068 81.068 0 0 0-13.691-1.164c-44.154 0-79.952 35.788-79.952 79.952 0 4.976.453 9.838 1.329 14.556H91.851a99.664 99.664 0 0 1-1.061-14.556c0-55.455 45.112-100.556 100.556-100.556.505 0 1.02 0 1.525.01a134.395 134.395 0 0 1 97.198-66.529 113.765 113.765 0 0 1 11.589 19.44z" />
			<path d="M224.157 112.29c-35.644 8.252-64.891 33.223-79.015 66.22a79.74 79.74 0 0 0-13.681-1.174c-44.154 0-79.952 35.799-79.952 79.952 0 37.571 25.899 69.074 60.822 77.645.113.773.247 1.545.391 2.308h-32.77C35.799 337.24 0 301.452 0 257.288c0-44.154 35.799-79.952 79.952-79.952 4.667 0 9.241.402 13.681 1.174 17.41-40.661 57.773-69.146 104.79-69.146 8.849 0 17.462 1.01 25.734 2.926z" />
			<path d="M301.658 175.048c-43.494 3.08-80.241 30.565-96.621 68.847a81.068 81.068 0 0 0-13.691-1.164c-44.154 0-79.952 35.788-79.952 79.952 0 4.976.453 9.838 1.329 14.556H91.851a99.664 99.664 0 0 1-1.061-14.556c0-55.455 45.112-100.556 100.556-100.556.505 0 1.02 0 1.525.01a134.395 134.395 0 0 1 97.198-66.529 113.765 113.765 0 0 1 11.589 19.44z" />
		</g>
		<path
			fill="#ecf6fe"
			d="M450.933 280.499h-27.47c-4.201-59.1-53.472-105.743-113.642-105.743-47.021 0-87.386 28.486-104.789 69.141a80.441 80.441 0 0 0-13.683-1.171c-44.157 0-79.953 35.796-79.953 79.953s35.796 79.953 79.953 79.953h259.583c33.726 0 61.067-27.341 61.067-61.067.001-33.725-27.339-61.066-61.066-61.066z"
		/>
		<path
			fill="#dbebfc"
			d="M335.551 177.686c-35.644 8.241-64.881 33.223-79.005 66.21a81.068 81.068 0 0 0-13.691-1.164c-44.154 0-79.952 35.788-79.952 79.952 0 44.154 35.799 79.952 79.952 79.952h-51.509c-44.154 0-79.952-35.799-79.952-79.952 0-44.164 35.799-79.952 79.952-79.952 4.667 0 9.241.402 13.691 1.164 17.4-40.651 57.762-69.135 104.78-69.135a113.8 113.8 0 0 1 25.734 2.925z"
		/>
	</svg>
)


CloudyIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

CloudyIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default CloudyIcon