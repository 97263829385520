import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'

/**
 * StyledTabs
 */
const StyledTabs = withStyles((theme) => ({
	indicator: (props) => {
		const isVertical = props.orientation === "vertical"
		return {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',

			'& > span': {
				minWidth: isVertical ? 3 : 220,
				backgroundColor: theme.palette.primary.main
			}
		}
	}
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span/> }}/>)

export default StyledTabs