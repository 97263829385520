/**
 * Formattage des values des parametres pour l'édition
 * @param parametres
 */
export const getParametresForInitValues = (parametres, isCulture) => {
	const constraintsParams = isCulture ? [...parametres.constraints, ...Object.entries(parametres?.params), ...Object.entries(parametres?.terminationConfig)] : [...parametres?.constraints];
	return constraintsParams.reduce((acc, param) => {
		if (Array.isArray(param) && param.length === 2) {
			const [key, value] = param;
			acc[key] = value;
		} else if (typeof param === 'object') {
			acc[`level-${param.key}`] = param.level;
			acc[`poids-${param.key}`] = param.value;
			acc[`actif-${param.key}`] = param.enabled
		}
		return acc
	}, {})
}


/**
 * Map form to API planificateur reglages
 * DataTable
 */
export const formToPlanificateurReglage = (culturesValues, tachesValues, idFerme, parametresData)  => {
	const mapParams = (values, type) => {
		const constraints = [];
		const terminationConfig = {};
		const parametres = {};

		for (const key in values) {
			if (key.startsWith('poids')) {
				const paramKey = key.substring(key.lastIndexOf('-') + 1);
				const paramData = parametresData?.[type]?.constraints.find(constraint => constraint?.key === paramKey);

				if (paramData) {
					const constraint = {
						key: paramData.key,
						value: values[`poids-${paramKey}`],
						level: values[`level-${paramKey}`],
						enabled: values[`actif-${paramKey}`],
					};
					constraints.push(constraint);
				}
			} else if (
				key.startsWith('pourcentageAAjouterPropositionRecolte') ||
				key.startsWith('solverCultureConstructionSpentLimitSeconds') ||
				key.startsWith('solverCultureLocalSearchLimitSecondsPhase') ||
				key.startsWith('solverCultureLocalSearchLimitSecondsUnimproved')
			) {
				terminationConfig[key] = values[key];
			} else if (!key.startsWith('actif')){
				parametres[key] = values[key];
			}
		}

		return { constraints, params: parametres, terminationConfig: terminationConfig };
	};

	const culture = mapParams(culturesValues, 'culture');
	const tache = mapParams(tachesValues, 'tache');

	return { culture, tache, idFerme };
};
