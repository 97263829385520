import PropTypes from 'prop-types'
import { injectCommentTypeValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'

/** Composant qui permet l'affichage du type de commentaire **/

const TypeComment = ({ typeComment, commentTypeList }) => {
	return (commentTypeList.find(type => type.code === typeComment) || {}).label || DEFAULT_LABEL
}

TypeComment.propTypes = {
	typeComment: PropTypes.string,
	commentTypeList: PropTypes.array
}

export default injectCommentTypeValueList(TypeComment)
