import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectTache } from '../services/tache/tacheAction'
import { PANEL_TYPE } from '../utils/constants'
import { ActionPanelContext, DetailsPanelContext } from '../../../../components/layout/rightPanels'

/**
 * Injecteur pour l'ouverture du panel de détail de tâches
 */
export const openTacheDetail = (WrappedComponent) => {
	const OpenTacheDetailInjector = ({ selectTache, ...props }) => {

		const { openPanel: openPanelDetail, closePanel: closePanelDetail } = React.useContext(DetailsPanelContext)
		const { closePanel: closePanelAction } = React.useContext(ActionPanelContext)

		const openTacheDetail = (tache) => {
			closePanelAction()
			openPanelDetail()
			selectTache(tache, PANEL_TYPE.TACHE_DETAIL)
		}

		const closeTacheDetail = () => {
			closePanelDetail()
		}

		return <WrappedComponent
			{...props}
			openTacheDetail={React.useCallback(openTacheDetail, [])}
			closeTacheDetail={React.useCallback(closeTacheDetail, [])}
		/>
	}

	const actions = {
		selectTache
	}

	OpenTacheDetailInjector.propTypes = {
		selectTache: PropTypes.func
	}

	return connect(undefined, actions)(OpenTacheDetailInjector)
}
