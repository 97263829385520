import {ClickAwayListener, Grid, IconButton, Paper, Slide, Typography, useMediaQuery} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import withStyles from '@material-ui/styles/withStyles/index'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setFilterOpen } from '../../../modules/fo/planning/services/toolbar/toolbarAction'
import { toI18N } from '../../../utils/utils'
import { NOT_ACTION_POINTER, DEFAULT_LEFT_CLICK } from '../../../utils/constants'

const zIndex = 1001
const styles = () => getStyles({
	slide: {
		position: 'fixed',
		right: 0,
		top: 64,
		width: 460,
		height: 'calc(100vh - 60px)',
		background: '#f7f5f8'
	},
	closeIcon: {
		position: 'absolute',
		top: 12,
		right: 12
	},
	titleContainer: {
		height: 65
	},
	title: {
		width: 348,
		height: 50,
		borderBottom: '2px solid #55555a'
	},
	content: {
		height: 'calc(100vh - 220px)',
		overflowY: 'auto'
	},
	contentTablette: {
		height: 'calc(100vh - 300px)',
		overflowY: 'auto'
	},
	actionsTablette: {
		position: 'absolute',
		bottom: -50,
		width: '100%',
		boxSizing: 'border-box'
	},
	actions: {
		height: 100,
		position: 'absolute',
		bottom: 0,
		width: '100%',
		boxSizing: 'border-box'
	},
	actionButton: {
		margin: '0 9px 0 9px',
		padding: '0 10px 0 10px'
	}
})
const onClickAway = (close, open, type="filter") => event => {
	const isLeftClick = event.button === DEFAULT_LEFT_CLICK
	const isNonActionPointer = NOT_ACTION_POINTER.has(window.getComputedStyle(event.target).cursor)
	if (open && (!isLeftClick || !isNonActionPointer)) {
		close()
	}
}

const FilterPanel = ({ Context, classes, open, level, setFilterOpen }) => {
	const isTablette = useMediaQuery('(min-width: 0px) and (max-width: 1279px)');

	const close = closePanel => () => {
		closePanel()
		setFilterOpen(false)
	}
	return (
		<Context.Consumer>
			{({ title, content, actions, closePanel }) => <Slide
				in={open}
				mountOnEnter
				direction="left"
				className={classes.slide}
				style={{ zIndex: zIndex + level }}
			>
				<Paper elevation={4}>
					<ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway(close(closePanel), open)}>
						<Grid container direction="column" style={{ position: isTablette && 'relative' }}>
							<Grid item container>
								<Grid item container direction="row" justify="center" alignContent="flex-end"
								      className={classes.titleContainer}>
									<Grid item className={classes.closeIcon}>
										<IconButton onClick={close(closePanel)}>
											<CloseIcon />
										</IconButton>
									</Grid>
									<Grid item container justify="center" alignContent="center" className={classes.title}>
										<Typography component="h3" variant="h3">{toI18N(title)}</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item container className={isTablette ? classes.contentTablette : classes.content}>
								{content}
							</Grid>
							<Grid item container justify="center" alignContent="center" className={isTablette ? classes.actionsTablette : classes.actions}>
								{actions.map((action, index) => <Grid item key={index}>
									{React.cloneElement(action, { className: classes.actionButton })}
								</Grid>)}
							</Grid>
						</Grid>
					</ClickAwayListener>
				</Paper>
			</Slide>}
		</Context.Consumer>
	)
}

FilterPanel.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	level: PropTypes.number
}

const actions = {
	setFilterOpen
}

export default compose(
	connect(null, actions),
	withStyles(styles)
)(FilterPanel)
