import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { colors } from '../../../utils/constants'
import classnames from 'classnames'
import Button from '../../../components/Button'
import PropTypes from 'prop-types'
import { toI18N } from '../../../utils/utils'

/**
 * Styles
 */
const styles = () => getStyles({
	tabs: {
		gap: 32
	},
	actionBtn: {
		borderRadius: 15,
		minWidth: 123,
		width: 123,
		height: 108,
		color: '#000000',
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		},
		fontWeight: 400,
		fontSize: 15,
		lineHeight: '18px',
		textAlign: 'center'

	},
	selectedButton: {
		color: 'white',
		backgroundColor: colors.primary
	},
	inactiveButton: {
		backgroundColor: '#FFF'
	},
	buttonContent: {
		padding: '10px',
		rowGap: 10,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyItems: 'space-between',
		alignItems: 'center'
	},
	notifContainer: {
		position: 'absolute',
		backgroundColor: '#b70f09',
		borderRadius: 20,
		minWidth: 20,
		minHeight: 20,
		top: -12,
		right: -12,
		padding: '6px'
	},
	notifText: {
		color: 'white'
	}
})

/**
 * ActionTabs
 * @param classes
 * @param actions, array objet de type :
 * code: 'bloc-1'
 * icon: DashboardIcon
 * label: { id: 'tourPlaine.suiviParBloc.actionTab.crops' }
 * notif: number of notif
 * @param selection
 * @param handleSelection
 * @returns {JSX.Element}
 * @constructor
 */
const ActionTabs = ({ classes, actions, selection, handleSelection }) => {
	return (
		<Grid item container direction="row" justify="center" alignItems="center" className={classes.tabs}>
			{
				actions.map(action => {
					const isSelected = action.code === selection.code
					const Element = action.icon
					return (
						<Button
							key={action.code}
							type="secondary"
							variant="text"
							keepCase
							withBorder={false}
							onClick={() => handleSelection(action)}
							className={classnames(classes.actionBtn, isSelected ? classes.selectedButton : classes.inactiveButton)}
						>
							<div className={classes.buttonContent}>
								<Element width={40} height={40} />
								{toI18N(action.label)}
							</div>
							{
								action.notif ? (
									<div className={classes.notifContainer}>
										<Typography className={classes.notifText}>
											{action.notif}
										</Typography>
									</div>
								) : (
									null
								)
							}
						</Button>
					)
				})
			}
		</Grid>
	)
}

ActionTabs.propTypes = {
	classes: PropTypes.object,
	actions: PropTypes.array,
	selection: PropTypes.object,
	handleSelection: PropTypes.func
}

export default compose(
	withStyles(styles)
)(ActionTabs)