import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import CommentSuiviList from '../../../../../components/CommentSuiviList'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { ASSOLEMENT_ETAT, ETAPE_TYPE } from '../../../../../utils/constants'
import { normalizeNumber } from '../../../../../utils/utils'
import { getEspacementDetail } from '../../utils/utils'

const DATE_FORMAT = {
	month: '2-digit',
	day: '2-digit'
}

const styles = () => getStyles({
	bold: {
		fontWeight: 'bold'
	},
	margin: {
		marginBottom: 5,
		marginTop: 5
	}
})

const EtapeAssolement = ({
	assolement,
	assolementEtape,
	editEtape = false,
	classes,
	openEtapeSuivi,
	openCommentDelete,
	intl
}) => {
	const dateDebut = intl.formatDate(new Date(assolementEtape.dateDebut), DATE_FORMAT)
	const dateFin = intl.formatDate(new Date(assolementEtape.dateFin), DATE_FORMAT)

	const typeSemis = assolementEtape.type === ETAPE_TYPE.SEMI_DIRECT || assolementEtape.type === ETAPE_TYPE.IMPLANTATION
	const isRevise = assolement.etat === ASSOLEMENT_ETAT.REVISE
	return (<>
		{dateFin && dateDebut && <p className={classes.margin}>
			<FormattedMessageWithBold
				id="planning.etapesAssolement.realisation"
				values={{ start: dateDebut, end: dateFin }}
			/>
		</p>}
		<p className={classes.margin}>
			<FormattedMessageWithBold
				id="planning.etapesAssolement.campagne"
				values={{
					campagne: assolement.campagne.nom,
					etat: intl.formatMessage({ id: `planning.${isRevise ? 'titleSuffixRevise' : 'titleSuffixInitial'}` })
				}}
			/>
		</p>
		{typeSemis && <p className={classes.margin}>
			<FormattedMessageWithBold {...getEspacementDetail(assolement)} />
		</p>}
		{assolementEtape.type === ETAPE_TYPE.SEMI_EN_CONTENANT && <p className={classes.margin}>
			<FormattedMessageWithBold
				id={`planning.itineraireTechnique.detail.nbGraine`}
				values={{
					nbGraine: normalizeNumber(assolement.nbGraine)
				}}
			/>
		</p>}
		{!editEtape && assolementEtape.commentaires &&
		<p>
			<CommentSuiviList comments={assolementEtape.commentaires} editComment={openEtapeSuivi}
			                  deleteComment={openCommentDelete}/>
		</p>
		}
	</>)
}

EtapeAssolement.propType = {
	assolement: PropTypes.object,
	assolementEtape: PropTypes.object.isRequired
}

export default compose(
	injectIntl,
	withStyles(styles)
)(EtapeAssolement)
