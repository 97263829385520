import Grid from '@material-ui/core/Grid'
import React, {useState} from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import Typography from "@material-ui/core/Typography";
import Switch from "../../../../components/Switch";
import {PERIOD_SWITCH} from "../utils/constants";
import {LocalDate} from "../../../../utils/date/local-date";
import {DateFormat, LocalizedDateFormat} from "../../../../utils/date/model/date";

const styles = () => getStyles({
	content: {
		padding: 20,
		fontSize: 16
	},
	switchDiv: {
		padding: 15,
		'& .MuiButtonBase-root': {
			width: 150
		}
	},
	switchText: {
		paddingTop: 20
	}
})

const PopinPlanification = ({ classes, handleSubmit, semaineCourante }) => {
	const [useWeeks, setUseWeeks] = useState(true)
	const [date] = useState(LocalDate.fromAPI(semaineCourante.debutSemaine))
	const switchAction = (item) => setUseWeeks(item.id === PERIOD_SWITCH[0].id)

	return (
		<Grid container className={classes.content}>
			<Typography>
				<FormattedMessage id="gestionTaches.toolbar.popin.description" />
			</Typography>
			<Grid container direction={"column"} alignItems={"center"} justify={"center"} className={classes.switchDiv}>
				<Switch items={PERIOD_SWITCH} setValue={switchAction} defaultValue={PERIOD_SWITCH[0]} />
				<Typography className={classes.switchText}>
				{ useWeeks ?
					<FormattedMessage id="gestionTaches.toolbar.popin.switch.weekResult" values={{
						dateStart: date.format(DateFormat.SHORT_DATE_2_DIGITS_2_MONTH),
						dateEnd: date.plus(14).format(DateFormat.SHORT_DATE_2_DIGITS_2_MONTH),
						numeroStart: date.weekNumber,
						numeroEnd: date.plus(14).weekNumber
					}} /> :
					<FormattedMessage id="gestionTaches.toolbar.popin.switch.dateResult" values={{
						dateStart: date.format(LocalizedDateFormat.LONG_DATE_WITHOUT_YEAR_STRING),
						dateEnd: date.plus(1).format(LocalizedDateFormat.LONG_DATE_WITHOUT_YEAR_STRING),
					}} />
				}
				</Typography>
			</Grid>
			<Typography>
				<FormattedMessage id="gestionTaches.toolbar.popin.informationNotification" />
			</Typography>
		</Grid>
	)
}

export default compose(
	withStyles(styles),
)(PopinPlanification)
