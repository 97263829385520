import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { colors } from '../../../../../utils/constants'
import { FormattedMessage } from 'react-intl'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		rowGap: 20,
		overflowY: 'hidden',
		maxHeight: 'calc(100vh - 220px)'
	},
	text: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '400',
		color: colors.text
	},
	ulContainer: {
		marginLeft: '-20px',
		marginBottom: 10
	}
})

/**
 * SaveTourErrorDialog
 * @param classes
 * @param tpErrors arroy of object containing block, tab, actionTab
 * @returns {JSX.Element}
 * @constructor
 */
const SaveTourErrorDialog = ({ classes, tpErrors }) => (
	<Grid className={classes.form}>
		<Grid style={{ margin: '1.5em' }}>
			<Grid><Typography className={classes.text}><FormattedMessage id="tourPlaine.dialog.cantSaveTP.description" values={{ count: tpErrors.length }} /></Typography></Grid>
			<ul className={classes.ulContainer}>
				{tpErrors.map(tpError => (
						<Typography className={classes.text} key={tpError.label}>
							<li>
								{tpError.label}
								{tpError.blocks && <FormattedMessage id="tourPlaine.dialog.cantSaveTP.timeline" values={{ step: tpError.blocks }} />}
								{tpError.actionTab && <FormattedMessage id="tourPlaine.dialog.cantSaveTP.timeline" values={{ step: tpError.actionTab }} />}
							</li>
						</Typography>
					)
				)}
			</ul>
			<Grid><Typography className={classes.text}><FormattedMessageWithBold id="tourPlaine.dialog.cantSaveTP.description2" /></Typography></Grid><br />
		</Grid>
	</Grid>
)

SaveTourErrorDialog.propType = {
	classes: PropTypes.object,
	tpErrors: PropTypes.object
}

export default compose(
	withStyles(styles)
)
(SaveTourErrorDialog)
