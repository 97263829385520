import {Fab, Grid, withStyles} from '@material-ui/core'
import React from 'react'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import {colors} from "../utils/constants";
import {connect} from 'react-redux'

const styles = () => getStyles({
	iconSearch: {
		width: 32,
		height: 32,
		minHeight: 35
	},
	textField: {
		backgroundColor: colors.light,
		borderRadius: 20,
		'&.MuiOutlinedInput-root': {
			borderRadius: 20,
		},
	},
	withoutBorder: {
		border: 'none'
	},
	search: {
		textAlign: 'center',
		width: 45,
		height: 45,
		minHeight: 45
	}
})

const SearchBar = ({ classes, placeHolder, setTextInput }) => {
	const [textValue, setTextValue] = React.useState();
	const intl = useIntl()

	return (
		<Grid container item xs={12} justifyContent="center" alignItems="center" >
			<Grid item xs={11}>
				<TextField
					placeholder={intl.formatMessage({id: placeHolder})}
					value={textValue}
					onChange={(event) => setTextValue(event.target.value)}
					variant="outlined"
					margin="normal"
					fullWidth
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							event.preventDefault()
							setTextInput(textValue)
						}
					}}
					InputProps={{
						classes: {
							notchedOutline: classes.withoutBorder,
						},
					}}
					className={classes.textField}
				/>
			</Grid>
			<Grid item xs={1} className={classes.search}>
				<Fab color="primary" onClick={() => setTextInput(textValue)}>
					<SearchIcon className={classes.iconSearch} />
				</Fab>
			</Grid>
		</Grid>
	)
}

SearchBar.propType = {
	classes: PropTypes.object
}

export default compose(
	connect(null),
	withStyles(styles),
)(SearchBar)