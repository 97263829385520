import { Box, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'

const getTextProps = (textRef) => {
	const textStyle = window.getComputedStyle(textRef.current)
	const lineHeight = +textStyle.lineHeight.replace('px', '')
	const textHeight = +textStyle.height.replace('px', '')
	//guess the number of lines using line height and block height
	return Math.round(textHeight / lineHeight)
}

const styles = () => getStyles({
	container:{
		position: 'relative'
	},
	text: {
		wordBreak: 'break-word'
	},
	textHidden: {
		position: 'absolute',
		visibility: 'hidden'
	}
})

const FixedLineText = ({ text, maxLines, setTooLong, open, classes }) => {
	const [shortText, setShortText] = React.useState(text)
	const [isChanged, setIsChanged] = React.useState(false)
	const textRef = React.useRef()

	React.useEffect(() => {
		if (maxLines && textRef) {
			const numberOfLines = getTextProps(textRef)
			if (numberOfLines > maxLines) {
				setTooLong(true)
				if (!isChanged) {
					//remove most of the comment
					setShortText(shortenText(numberOfLines))
					setIsChanged(true)
				} else {
					// remove word until it fit
					setShortText(removeLastWord)
				}
			}
		}
	}, [shortText])

	React.useEffect(() => {
		if (shortText !== text) {
			setShortText(text)
			setIsChanged(false)
			setTooLong(false)
		}
	}, [text])

	const shortenText = numberOfLines => comment => {
		// add a fake line to prevent avoid the case where there is only one word on the last line
		comment = comment.slice(0, comment.length * maxLines / (numberOfLines - 1))
		// fully remove the last word
		comment = comment.replace(/( |^)[^ ]+?$/, '') + '...'
		return comment
	}

	const removeLastWord = comment => {
		return comment.replace(/( |^)[^ ]+?\.\.\.$/, '') + '...'
	}

	const getClass = (visible) => visible ? classes.text : classes.textHidden

	return (
		<Box className={classes.container}>
			<Box className={getClass(open)}>
				{text}
			</Box>
			{/* permet de mettre à jour shortText meme si le commentaire est ouvert */}
			<Box ref={textRef} className={getClass(!open)}>
				{shortText}
			</Box>
		</Box>
	)
}

FixedLineText.propType = {
	maxLines: PropTypes.integer,
	open: PropTypes.bool,
	setTooLong: PropTypes.func,
	text: PropTypes.string
}

export default withStyles(styles)(FixedLineText)