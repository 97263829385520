import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import fr from 'date-fns/locale/fr'
import { createBrowserHistory } from 'history'
import { configureFetch, configureStorageMode, createIsotopeTheme, fetchUser as fetchUserApi, i18n, messages as messagesIsotope, saveStore } from 'isotope-client'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import React from 'react'
import ReactDOM from 'react-dom'
import { intlReducer, Provider } from 'react-intl-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import messages from './config/messages'
import reducers from './config/reducers'
import Root from './containers/Root'
import { getConfiguration } from './modules/common/configuration/configurationApi'
import ServiceWorkerWrapper from './modules/common/serviceWorker/ServiceWorkerWrapper'
import { colors } from './utils/constants'
import { customFetchFactory } from './utils/fetchFactory'
import './style/index.scss'

momentDurationFormatSetup(moment)

export const theme = (isResultat) => createIsotopeTheme({
	overrides: {
		MuiPickersToolbarText: {
			toolbarBtnSelected: {
				color: "#FFFFFF !important"
			}
		},
		MuiDialog: {
			paperWidthMd: {
				maxWidth: 675
			}
		},
		MuiChip: {
			deleteIconSmall: {
				color: colors.text
			}
		},
		MuiMenuItem: {
			root: {
				whiteSpace: 'inherit',
				'&$selected': {
					color: isResultat ? colors.primaryResultat : colors.primary
				}
			}
		},
		MuiCheckbox: {
			root: {
				color: isResultat ? colors.primaryResultat : colors.primary
			},
			colorSecondary: {
				'&$checked': {
					color: isResultat ? colors.primaryResultat : colors.primary
				}
			}
		},
		MuiPaper: {
			rounded: {
				'&:has(.MuiPaper-rounded)': {
					borderRadius: 20
				}
			}
		},
		MuiAutocomplete: {
			popupIndicator: {
				padding: '2px !important',
				marginRight: '-2px !important'
			},
			clearIndicator: {
				padding: '2px !important',
				marginRight: '-2px !important'
			}
		},
		MuiButton: {
			root: {
				borderRadius: 4,
				padding: '5px 40px'
			},
			outlined: {
				padding: '4px 40px',
				borderWidth: 2
			}
		},
		MuiRadio: {
			colorSecondary: {
				'&$checked': {
					color: colors.primary,
				}
			}
		},
		MuiSelect: {
			root: {
				backgroundColor: colors.input,
				borderTopRightRadius: '8px !important',
				borderTopLeftRadius: '8px !important',
				'&:focus': {
					backgroundColor: `${colors.input} !important`
				},
				'&:hover': {
					backgroundColor: colors.input
				}
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.input,
				borderTopRightRadius: 8,
				borderTopLeftRadius: 8,
				'&$focused': {
					backgroundColor: colors.input
				},
				'&:hover': {
					backgroundColor: colors.input
				}
			},
			underline: {
				borderBottomWidth: '0px !important'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 12
			}
		},
		MuiSvgIcon: {
			root: {
				fontSize: '1.5rem !important'
			}
		},
		MuiTableCell: {
			root: {
				fontSize: '0.8rem',
				borderBottom: 'none',
				paddingTop: 0,
				paddingBottom: 0,
				'& [class*="MuiCheckbox-root"]': {
					paddingTop: 2,
					paddingBottom: 2
				}
			},
			head: {
				textAlign: 'center'
			}
		},
		MuiTableSortLabel: {
			root: {
				marginLeft: 30
			}
		},
		MuiTableRow: {
			root: {
				'&.Mui-selected': {
					backgroundColor: colors.selectedWithOpacity
				}
			},
			head: {
				backgroundColor: colors.etapeBackground,
				height: 40
			}
		},
		page: {
			contentFrame: {
				backgroundColor: colors.background
			}
		},
		'isotope-datatable': {
			tableHeaderCol: {
				fontSize: '0.8rem'
			}
		},
		formrichtext: {
			editor: {
				paddingLeft: 10,
				width: 560
			},
			bootstrapFormLabel: {
				color: 'rgba(0, 0, 0, 0.54)'
			}
		},
		'cms-richText': {
			editor: {
				paddingLeft: 0,
				width: '100%'
			},
			bootstrapFormLabel: {
				color: colors.primary
			}
		},
		forminput: {
			bootstrapFormLabel: {
				top: '-2px'
			}
		},
		snackbar: {
			success: {
				backgroundColor: '#32d736 !important',
				color: 'white !important'
			},
			warning: {
				backgroundColor: '#b70f0a !important',
				color: 'white !important'
			},
			info: {
				backgroundColor: '#ffa834 !important',
				color: 'white !important'
			}
		}
	},
	login: {
		background: 'url(\'img/connexion.png\')'
	},
	typography: {
		fontFamily: 'Lato',
		h1: {
			fontSize: '2em',
			fontWeight: 400,
			color: colors.text
		},
		h2: {
			fontSize: '1.5em',
			fontWeight: 400,
			color: colors.text
		},
		h3: {
			fontSize: '1.25em',
			fontWeight: 'bold',
			color: colors.text,
			letterSpacing: 2
		},
		h4: {
			fontSize: '1.125em',
			fontWeight: 'bold',
			color: colors.text,
			letterSpacing: 1
		},
		h5: {
			fontSize: '1em',
			fontWeight: 'bold',
			color: colors.text
		},
		body1: {
			fontSize: '0.875em',
			fontWeight: 400,
			color: colors.text
		}
	},
	palette: {
		primary: {
			main: isResultat ? colors.primaryResultat : colors.primary,
			light: '#f5f2f8',
			light2: isResultat ? colors.lightPrimaryResultat : colors.lightPrimary,
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: colors.secondary,
			contrastText: '#FFFFFF'
		},
		text: {
			main: colors.text
		},
		error: {
			main: colors.error
		},
		valid: {
			main: colors.valid
		},
		border: colors.border,
		placeholder: colors.placeholder,
		empty: colors.emptyColor,
		input: colors.input,
		disabled: '#aaaaaa',
		table: {
			selectedRow: colors.selectedRow
		},
		tache: {
			EN_ATTENTE: colors.error,
			A_PLANIFIER: colors.blue,
			A_REALISER: colors.toDo,
			EN_COURS: colors.tacheEnCoursOuTerminee,
			TERMINEE: colors.tacheEnCoursOuTerminee,
			EN_PAUSE: colors.tacheEnCoursOuTerminee,
			EN_RETARD: colors.error,
			SUPPRIMEE: colors.etapeContrastBackground
		}
	}
})

configureStorageMode()

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user) => {

	// Initialisation de la partie i18n (react-intl)
	const formats = {
		time: i18n.timeFormats,
		number: i18n.numberFormats,
		date: {
			...i18n.dateFormats,
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}

	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	getConfiguration().then(configuration => {
		((d, s, id, cb) => {
			const fjs = d.getElementsByTagName(s)[0]
			let js = d.createElement(s)
			js.id = id
			js.src = '//apis.google.com/js/client:platform.js'
			fjs.parentNode.insertBefore(js, fjs)
			js.onload = cb
		})(document, 'script', 'google-login', () => {
			// Si on est sur l'url technique, on redirige sur l'url classique
			if (configuration.ENVIRONMENT_DOMAIN_TECHNIQUE && configuration.ENVIRONMENT_URL) {
				const winLocation = window.location
				if (winLocation && winLocation.href && winLocation.href.includes(configuration.ENVIRONMENT_DOMAIN_TECHNIQUE)) {
					const winHref = winLocation.href
					winLocation.replace(`${configuration.ENVIRONMENT_URL}${winHref.substring(winHref.indexOf(configuration.ENVIRONMENT_DOMAIN_TECHNIQUE) + configuration.ENVIRONMENT_DOMAIN_TECHNIQUE.length)}`)
				}
			}
		})
	})

	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages: {
					...messagesIsotope,
					...messages
				},
				formats: {
					...formats
				},
				defaultLocale: 'fr',
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	configureFetch({ fetchFactory: customFetchFactory })

	saveStore(store)

	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MuiThemeProvider theme={theme(false)}>
					<ServiceWorkerWrapper>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
							<Root />
						</MuiPickersUtilsProvider>
					</ServiceWorkerWrapper>
				</MuiThemeProvider>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	)
}

const token = window.localStorage.getItem('token')
if (token) {
	fetchUserApi(token).then(user => {
		if (user) {
			user.token = token
			user.authenticated = true
		}
		reactInit(user)
	}).catch((error) => {
		reactInit()
	})
} else {
	reactInit()
}

// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}
