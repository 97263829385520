import { IsotopeContext } from 'isotope-client'
import { loadLangues } from 'isotope-client/modules/langue/services/langueActions'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import Page from '../components/layout/Page'
import { loadConfiguration } from '../modules/common/configuration/configurationInjector'
import * as userSelectors from '../modules/common/user/services/userSelectors'
import FrontOffice from '../modules/fo/FrontOffice'
import { storeLocalUser } from '../modules/fo/login/services/loginApi'
import { PROFILS, ROOT_BO } from '../utils/constants'
import { refreshToken } from '../utils/fetchFactory'
import BackOffice from '../modules/bo/BackOffice'
import SolverStatusContextProvider from '../modules/fo/besoinsProduction/SolverStatusContextProvider'
import PageContextProvider from '../components/layout/PageContext'
import BesoinsProductionProvider from '../modules/fo/besoinsProduction/BesoinsProductionProvider'
import { hasAccess } from '../components/security/AccessChecker'

let refreshInterval = undefined
const App = ({ user, userAuthorities, location, loadLanguesAction }) => {
	React.useEffect(() => {
		refresh()

		refreshInterval = setInterval(() => {
			refresh()
		}, 1700 * 1000)

		return () => {
			if (refreshInterval) {
				clearInterval(refreshInterval)
			}
		}
	}, [])

	async function refresh() {
		if (user && user.token && !user.id) {
			const token = localStorage.getItem('token')
			const refToken = localStorage.getItem('refreshToken')
			refreshToken(token, refToken).then((json) => {
				if (json) {
					storeLocalUser({ token: json.token, refreshToken: json.refreshToken })
				}
			})
		}
		loadLanguesAction()
	}

	if (!user.authenticated) {
		return (<Redirect to={{
			pathname: '/login',
			state: { nextPathname: location.pathname }
		}}/>)
	} else {
		return (
			<IsotopeContext.Provider value={{ appName: '' }}>
				<PageContextProvider>
					<SolverStatusContextProvider>
						<BesoinsProductionProvider>
							<Page
								location={location}
								menu={location && location.pathname && location.pathname.startsWith(ROOT_BO) ? `${user && user.id}-bo` : `${user && user.id}-fo`}
							>
								<Switch>
									{hasAccess(PROFILS.SUPERVISEUR, userAuthorities) &&
										<Route path="/bo" component={BackOffice}/>
									}
									<Route path="/" component={FrontOffice}/>
								</Switch>
							</Page>
						</BesoinsProductionProvider>
					</SolverStatusContextProvider>
				</PageContextProvider>
			</IsotopeContext.Provider>
		)
	}
}

App.propTypes = {
	location: PropTypes.object.isRequired,
	user: PropTypes.object,
	loadLanguesAction: PropTypes.func.isRequired
}


const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state),
	userAuthorities: userSelectors.getAuthorities(state)
})

const actions = {
	loadLanguesAction: loadLangues
}

export default compose(
	connect(mapStateToProps, actions),
	loadConfiguration
)(App)
