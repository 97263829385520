import React from 'react'
import HeaderOrdonnancement from './ordonnancement/HeaderOrdonnancement'
import OrdonnancementTable from './ordonnancement/OrdonnancementTable'

const OrdonnancementOnglet = () => {
	const [selected, setSelected] = React.useState([])

	return <>
		<HeaderOrdonnancement selected={selected} />
		<OrdonnancementTable selected={selected} setSelected={setSelected} />
	</>
}

export default OrdonnancementOnglet