/**
 * groupEligibleBoardsByBioAggressor
 * group by aggressor and keep board with the same, remove the rest
 * @param boards
 * @param bioAggressors
 */
export const groupEligibleBoardsByBioAggressor = (boards, bioAggressors) => {
	const groupedByBioAggressors = bioAggressors.reduce((acc, bioAggressor) => {
		const filteredBoards = boards.filter(board => board.bioAggressors.some(ba => ba.idBioAggressor === bioAggressor.id))

		// get all the board who contains the bioAggressor
		if (filteredBoards) {
			const uniqueFormBAs = filteredBoards.map(board => {
					// get the selected bioAggressor for the board
					const boardBioAggressors = board.bioAggressors.filter(ba => ba.idBioAggressor === bioAggressor.id)

					// distinct boardBioAggressors
					const uniqueFormBA = [...new Set(boardBioAggressors.map(item => JSON.stringify(item)))]
						.map(item => JSON.parse(item))
						.map(item => ({
							key: `${item.idBioAggressor}${item.start}${item.end}`,
							bioAggressor: item.idBioAggressor,
							// id: item.id,
							start: item.start,
							end: item.end,
							boards: [{ idBoard: board.id, idBioAggressor: item.id }]
						}))

					return uniqueFormBA
				}
			).flat()

			// Merge our distinct boardBioAggressors into the accumulated
			const mergedData = uniqueFormBAs.reduce((acc, currentFormBA) => {
				const accObj = acc[currentFormBA.key]
				if (accObj) {
					// if existed merged the boards
					const mergedBoards = [...new Set([...accObj.boards.map(item => JSON.stringify(item)), ...currentFormBA.boards.map(item => JSON.stringify(item))])].map(item => JSON.parse(item))
					return {
						...acc,
						[currentFormBA.key]: {
							...currentFormBA,
							boards: mergedBoards
						}
					}
				} else {
					return {
						...acc,
						[currentFormBA.key]: currentFormBA
					}
				}
			}, {})

			return {
				...acc,
				...mergedData
			}
		}
		return acc
	}, {})

	if (boards.length === 1) {
		return Object.keys(groupedByBioAggressors).map(key => groupedByBioAggressors[key])
	}

	// Filter only those with 2 boards and more
	const eligibleBoards = Object.keys(groupedByBioAggressors).filter(key => groupedByBioAggressors[key].boards.length > 1)
	return eligibleBoards.map((eligibleBoard, index) => groupedByBioAggressors[eligibleBoard])
}

/**
 * groupBoardsUnavailableByPeriod
 * group by period keep board with the same, remove the rest
 * @param boards
 */
export const groupBoardsUnavailableByPeriod = (boards) => {
	if (boards.length === 1) {
		// if we only have one board, return the formatted object
		return boards.map(board => ({
			start: board.start,
			end: board.end,
			comment: board.comment,
			boards: [board]
		}))
	}

	const groupedByPeriod = boards.reduce((acc, board) => {
		const key = `${board.start}${board.end}`
		const accObj = acc[key]
		if (accObj) {
			return {
				...acc,
				[key]: {
					...accObj,
					comment: accObj.comment === board.comment ? accObj.comment : '',
					boards: [...accObj.boards, board]
				}
			}
		}
		return {
			...acc,
			[key]: {
				start: board.start,
				end: board.end,
				comment: board.comment,
				boards: [board]
			}
		}
	}, {})

	// Filter only those with 2 boards and more
	const eligibleBoards = Object.keys(groupedByPeriod).filter(key => groupedByPeriod[key].boards.length > 1)
	return eligibleBoards.map((eligibleBoard, index) => groupedByPeriod[eligibleBoard])
}
