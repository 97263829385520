import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { PropTypes } from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { PROFILS } from '../../../../../utils/constants'
import { injectEditItineraire } from '../../services/actions/form/editItineraireInjector'
import { getEspacementDetail } from '../../utils/utils'
import EditableField from '../EditableField'
import ItineraireTechniqueBase from './ItineraireTechniqueBase'

const styles = getStyles({
	margin: {
		marginBottom: 5,
		marginTop: 5
	}
})

const ItineraireTechnique = ({
	assolement,
	estEnTerre,
	openEditItineraire,
	isCompare,
	noEdit = false,
	classes
}) => (
	<>
		<ItineraireTechniqueBase assolement={assolement} withNbGraines />
		{assolement.espacement && (assolement.densite || assolement.nbRangs) && !isCompare &&
			<EditableField
				content={
					<FormattedMessageWithBold {...getEspacementDetail(assolement)} />}
				type="espacement"
				dataToPass={assolement}
				access={!estEnTerre ? [PROFILS.CHEF, PROFILS.SUPERVISEUR] : []}
				action={() => {
					openEditItineraire(assolement)
				}}
				noEdit={noEdit}
			/>
		}
		{assolement.espacement && (assolement.densite || assolement.nbRangs) && isCompare &&
			<p className={classes.margin}>
				<FormattedMessageWithBold {...getEspacementDetail(assolement)} />
			</p>
		}
	</>
)

ItineraireTechnique.propType = {
	assolement: PropTypes.object.isRequired,
	openEditItineraire: PropTypes.func,
	estEnTerre: PropTypes.bool,
	isCompare: PropTypes.bool,
	classes: PropTypes.object,
	noEdit: PropTypes.bool
}

ItineraireTechnique.defaultProps = {
	estEnTerre: false
}

export default compose(
	injectEditItineraire,
	withStyles(styles)
)(ItineraireTechnique)
