/**
 * Formattage des values de la categorie technique pour l'édition
 * @param categorie
 */
export const getCategorieForInitValues = (categorie) => {
	return {
		id: categorie.id,
		nom: categorie.nom,
		code: categorie.code,
		avecReglage: categorie.avecReglage,
		sansReglage: categorie.sansReglage,
		participants: categorie.participants,
		specialistes: categorie.specialistes,
		sensibilite: categorie.sensibilite
	}
}

