import { Grid, MenuItem, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { FILTER_FORM, STATUT_TACHE_LIST, TOUTE_LA_FERME } from '../utils/constants'
import { ONGLET_GESTION_TACHE } from '../../../../utils/constants'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import { injectEspeceCultureValueList, injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'
import Select from '../../../../components/form/Select'
import { getFilterOptionsBloc, getFilterOptionsCulture, getFilterOptionsSurface } from '../../planning/services/planningApi'
import { getFilterValues } from '../../planning/services/toolbar/toolbarSelector'
import Input from '../../../../components/form/Input'
import { getAllUsers, getTypeTacheByIdFerme } from '../gestionTachesApi'
import moment from 'moment'
import Checkbox from '../../../../components/form/Checkbox'
import { formatPositiveInteger, sortObjectByLabel } from '../../../../utils/utils'

const styles = (theme) => getStyles({
	form: {
		width: 'calc(100% - 3rem)',
		padding: '1.5rem',
		fontFamily: 'Lato'
	},
	formFields: {
		width: '100%'
	},
	formInput: {
		'& > div': {
			padding: 0,
			marginBottom: '2.25rem'
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	link: {
		'&[class*="MuiTypography-root"]': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
			cursor: 'pointer',
			textAlign: 'center',
			margin: '0.5rem 0 2.25rem'
		}
	},
	iconSearch: {
		transform: 'translate(0, -50%)',
		color: '#55555A'
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	inputs: {
		'&[class*="MuiGrid-root"]': {
			fontSize: 16,
			padding: 0,
			marginBottom: 20,
			'& > div': {
				padding: 0
			}
		}
	},
	labelBox: {
		fontSize: 14,
		cursor: 'pointer'
	}
})

export const FILTER_FIELDS = {
	TACHE: { name: 'taches', propName: 'tache' },
	CULTURE: { name: 'cultures', propName: 'culture' },
	LIEU: { name: 'lieux', propName: 'lieu' },
	STATUT: { name: 'status', propName: 'statut' },
	CHARGESUP: { name: 'chargeSup', propName: 'chargeSup' },
	CHARGEINF: { name: 'chargeInf', propName: 'chargeInf' },
	AFFECTATION: { name: 'affectation', propName: 'affectation' },
	ONLYNONAFFECTE: { name: 'nonAffectee', propName: 'nonAffectee' },
	RETARD: { name: 'enRetard', propName: 'enRetard' }
}

const FilterForm = ({
	idFerme,
	especeValues,
	typeTacheList,
	handleSubmit,
	change,
	classes,
	onglet,
	jourSelection
}) => {

	const [users, setUsers] = React.useState()
	const [optionsTache, setOptionsTache] = React.useState([])
	const getUsers = () => Promise.resolve(users)

	React.useEffect(() => {
		getAllUsers(moment(jourSelection).format('YYYY-MM-DD'))
			.then((res) => setUsers(sortObjectByLabel(
				res.map((ressource) => ({
					label: ressource.alias,
					code: ressource.user.id,
					value: ressource.user.id
				})))
				)
			)
	}, [jourSelection])

	React.useEffect(() => {
		getTypeTacheByIdFerme()
			.then(setOptionsTache)
	}, [])

	const onResetClick = (event) => {
		change(FILTER_FIELDS.TACHE.name, [])
		change(FILTER_FIELDS.CULTURE.name, [])
		change(FILTER_FIELDS.LIEU.name, [])
		change(FILTER_FIELDS.STATUT.name, [])
		change(FILTER_FIELDS.CHARGEINF.name, null)
		change(FILTER_FIELDS.CHARGESUP.name, null)
		change(FILTER_FIELDS.AFFECTATION.name, [])
		change(FILTER_FIELDS.ONLYNONAFFECTE.name, false)
		change(FILTER_FIELDS.RETARD.name, false)

		event.preventDefault()
	}

	const valueListToOptionsList = (valueList) => (
		valueList && valueList.map(element => {
			return { label: element.label, code: element.code }
		})
	)

	const arrayToOptionList = (array) => (
		array && array.map(element => {
			return { label: element.toString(), code: element }
		})
	)

	const getOptionsTache = () => new Promise((resolve) => resolve(valueListToOptionsList(typeTacheList).filter(type => optionsTache.includes(type.code))))

	const getOptionsCulture = () => getFilterOptionsCulture(idFerme, (especeValues || []).map(espece => espece.code)).then(options => options.cultures)

	const getOptionsLieu = () => {
		const promises = [
			getFilterOptionsBloc(idFerme),
			getFilterOptionsSurface(idFerme)
		]

		return Promise.all(promises)
			.then(res => [{ label: TOUTE_LA_FERME.FERME, code: 'ferme' }, ...arrayToOptionList([...res[0].blocs, ...res[1].surfaces])]
			)
	}

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Typography variant="body1" className={classes.link} onClick={onResetClick}>
				<FormattedMessage id="planning.filter.form.reset" />
			</Typography>

			<Grid className={classes.formFields}>

				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.tache" />}
						name={FILTER_FIELDS.TACHE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsTache}
						forceGetOptions
						formName={FILTER_FORM}
					>
					</Field>
				</Grid>

				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.culture" />}
						name={FILTER_FIELDS.CULTURE.name}
						component={AutocompleteMulti}
						getOptions={getOptionsCulture}
						forceGetOptions
						formName={FILTER_FORM}
					/>
				</Grid>

				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.lieu" />}
						name={FILTER_FIELDS.LIEU.name}
						component={AutocompleteMulti}
						getOptions={getOptionsLieu}
						forceGetOptions
						formName={FILTER_FORM}
					/>
				</Grid>

				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.statut" />}
						name={FILTER_FIELDS.STATUT.name}
						component={Select}
						multiple
						formName={FILTER_FORM}
					>
						{
							STATUT_TACHE_LIST.map(statut =>
								<MenuItem key={statut.code} value={statut.code}>
									{statut.label}
								</MenuItem>
							)
						}
					</Field>
				</Grid>

				{(onglet === ONGLET_GESTION_TACHE.AFFECTATION || onglet === ONGLET_GESTION_TACHE.PLANIFICATION || onglet === ONGLET_GESTION_TACHE.ORDONNANCEMENT) &&
					<Grid className={classes.formInput} style={{paddingBottom: 30}}>
						<Field
							name={FILTER_FIELDS.RETARD.name}
							component={Checkbox}
							label={<span className={classes.labelBox}><FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.retard" /></span>}
						/>
					</Grid>
				}

				<Grid>
					<b>{(onglet === ONGLET_GESTION_TACHE.PLANIFICATION)
						? <FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.chargeTravail" />
						: <FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.chargeTravail" />
					}</b>
					<br /><br />
				</Grid>

				<Grid container direction="row" className={classes.formInput} justify="space-between">
					<Grid item className={classes.inputs}>
						<Field
							label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.chargeSup" />}
							name={FILTER_FIELDS.CHARGESUP.name}
							component={Input}
							type="number"
							format={formatPositiveInteger}
							adornment={
								<span style={{fontSize: 12}}>
							<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.tempsPasseAdornement" />
						</span>
							}
							style={{ width: 188 }}
						/>
					</Grid>
					<Grid item className={classes.inputs}>
						<Field
							label={<FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.chargeInf" />}
							name={FILTER_FIELDS.CHARGEINF.name}
							component={Input}
							type="number"
							format={formatPositiveInteger}
							adornment={
								<span style={{fontSize: 12}}>
							<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.tempsPasseAdornement" />
						</span>
							}
							style={{ width: 188 }}
						/>
					</Grid>
				</Grid>

				{(onglet !== ONGLET_GESTION_TACHE.PLANIFICATION) &&
				<Grid className={classes.formInput}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranAffectations.panels.multiSelectLabel.suiviProd" />}
						name={FILTER_FIELDS.AFFECTATION.name}
						component={AutocompleteMulti}
						getOptions={getUsers}
						formName={FILTER_FORM}
					/>
				</Grid>
				}

				{(onglet === ONGLET_GESTION_TACHE.AFFECTATION) &&
				<Grid className={classes.formInput}>
					<Field
						name={FILTER_FIELDS.ONLYNONAFFECTE.name}
						component={Checkbox}
						label={<span className={classes.labelBox}><FormattedMessage id="gestionTaches.ecranPlanification.filter.fields.checkbox" /></span>}
					/>
				</Grid>
				}

			</Grid>
		</form>
	)
}

FilterForm.propType = {
	idFerme: PropTypes.string,
	jourSelection: PropTypes.string,
	zoneSurfaceList: PropTypes.array,
	especeCultureList: PropTypes.array,
	handleSubmit: PropTypes.func,
	initialize: PropTypes.func,
	classes: PropTypes.object,
	onglet: PropTypes.number
}

const mapStateToProps = state => ({
	initialValues: getFilterValues(state)
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FILTER_FORM,
		enableReinitialize: true
	}),
	injectEspeceCultureValueList,
	injectTypeTacheValueList,
	withStyles(styles)
)(FilterForm)
