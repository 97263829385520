import React from 'react'
import PropTypes from 'prop-types'

const DrySoilIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: 'new 0 0 35 35'
		}}
		viewBox="0 0 35 35"
	>
		<g clipPath="url(#clip0_1505_24705)">
			<path d="M35 20.5762C35 20.0094 34.5414 19.5508 33.9746 19.5508H26.337L26.0876 20.5762L24.0438 19.5508H17.5H10.7192L8.72006 20.5762L8.55812 19.5508H1.02539C0.458623 19.5508 0 20.0094 0 20.5762V25.7031L1.02539 26.7285L0 27.7539V32.8809C0 33.4476 0.458623 33.9062 1.02539 33.9062H9.68864L11.7321 32.8809L11.9818 33.9062H17.5H25.0694L25.2545 32.8809L27.3626 33.9062H33.9746C34.5414 33.9062 35 33.4476 35 32.8809V25.7031L33.9746 24.7418L35 23.6523V20.5762Z" fill="#D97F4A" />
			<path d="M25.2545 32.8809L27.3627 33.9062H33.9746C34.5414 33.9062 35 33.4476 35 32.8809V25.7031L33.9746 24.7418L35 23.6523V20.5762C35 20.0094 34.5414 19.5508 33.9746 19.5508H26.337L26.0876 20.5762L24.0438 19.5508H17.5V33.9062H25.0694L25.2545 32.8809Z" fill="#B35F49" />
			<path d="M28.3877 23.6523L26.337 19.5508H24.0439L26.5062 24.4755L23.2278 27.7539H17.5H13.5637L8.5426 26.0803L10.7192 19.5508H8.55812L6.50706 25.7031H0V27.7539H7.08087L11.9525 29.3781L9.68864 33.9062H11.9818L14.0323 29.8047H17.5H23.0185L25.0694 33.9062H27.3626L24.9 28.9816L28.1785 25.7031H35V23.6523H28.3877Z" fill="#B35F49" />
			<path d="M25.0694 33.9062H27.3626L24.9 28.9816L28.1785 25.7031H35V23.6523H28.3877L26.337 19.5508H24.0439L26.5062 24.4755L23.2278 27.7539H17.5V29.8047H23.0185L25.0694 33.9062Z" fill="#8C4A37" />
			<g clipPath="url(#clip1_1505_24705)">
				<path d="M0.0165759 1.68332C-0.108932 0.894657 0.485931 0.174423 1.28416 0.14872C4.03597 0.0600577 10.118 0.307519 14.2975 3.7598C15.8955 5.07969 16.5919 6.60718 16.8456 7.98312C17.5721 11.9246 14.6582 15.4539 10.6492 15.4849C9.25008 15.4957 7.61855 15.1003 6.02058 13.7804C1.84102 10.3281 0.449291 4.40231 0.0165759 1.68332Z" fill="#E3D29D" />
				<path d="M16.8456 7.98311C16.592 6.60711 15.8956 5.07969 14.2976 3.7598C10.118 0.307519 4.03603 0.0600577 1.28422 0.14872C0.486059 0.174423 -0.108872 0.894657 0.0166354 1.68332C0.0450045 1.86174 0.0775436 2.0539 0.114731 2.25829C3.09465 2.23771 8.58972 2.68854 12.4564 5.88243C14.0544 7.20231 14.7508 8.7298 15.0044 10.1057C15.2821 11.6122 15.028 13.0585 14.3842 14.2722C16.2658 12.9018 17.3148 10.5285 16.8456 7.98311Z" fill="#CCC48E" />
				<path d="M34.9672 6.20144C35.1261 5.56324 34.6891 4.93009 34.036 4.85244C31.7844 4.58461 26.7765 4.35649 23.1024 6.89283C21.6977 7.86251 21.018 9.06645 20.7123 10.1775C19.8368 13.36 21.9774 16.4624 25.2647 16.772C26.4119 16.8801 27.7786 16.6713 29.1833 15.7016C32.8575 13.1653 34.4195 8.40179 34.9672 6.20144Z" fill="#E3D29D" />
				<path d="M34.036 4.85238C33.7245 4.81533 33.3603 4.77903 32.9543 4.75073C32.3169 7.09744 30.7287 11.4285 27.2865 13.8047C25.8818 14.7743 24.5151 14.9831 23.3679 14.875C22.569 14.7998 21.8379 14.5595 21.2012 14.1933C22.0064 15.5939 23.4634 16.6022 25.2647 16.7719C26.4119 16.8799 27.7786 16.6712 29.1834 15.7015C32.8575 13.1652 34.4195 8.40167 34.9672 6.20139C35.1261 5.56318 34.6891 4.93011 34.036 4.85238Z" fill="#CCC48E" />
				<path d="M6.92591 6.17645C6.96187 6.19948 10.5464 8.50155 12.4808 10.6222C15.5579 13.9957 17.2508 18.4014 17.2479 23.0278H18.3769C18.3746 19.2409 19.6876 17.1392 22.206 14.3782C23.9047 12.5158 25.97 11.0138 28.3445 9.91379C28.6092 9.79122 28.7243 9.47725 28.6018 9.21256C28.4793 8.94787 28.1655 8.83276 27.9005 8.95533C25.3979 10.1146 23.2194 11.6997 21.4256 13.6663C19.8578 15.3851 18.7009 17.4096 18.0068 19.5992C17.3741 15.9892 15.7442 12.6324 13.2612 9.91024C11.2339 7.6876 7.64663 5.38334 7.49494 5.28634C7.24925 5.12925 6.92263 5.2011 6.76547 5.44685C6.60831 5.69267 6.68009 6.01929 6.92591 6.17645Z" fill="#CCC48E" />
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1505_24705">
				<rect width="35" height="35" fill="white" />
			</clipPath>
			<clipPath id="clip1_1505_24705">
				<rect width="35" height="35" fill="white" transform="translate(0 -1.09375)" />
			</clipPath>
		</defs>
	</svg>
)


DrySoilIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

DrySoilIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default DrySoilIcon