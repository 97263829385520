import React from 'react'
import PropTypes from 'prop-types'

const TractorIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#9e9797"
			d="M352.785 263.011a7.702 7.702 0 0 1-5.51-2.31l-65.943-67.075a7.728 7.728 0 0 1 11.021-10.833l65.943 67.075a7.725 7.725 0 0 1-.094 10.926 7.702 7.702 0 0 1-5.417 2.217z"
		/>
		<path
			fill="#99e6fc"
			d="m166.221 209.421-61.381 5.167 17.311-107.001c24.339 0 44.071 19.731 44.071 44.071v57.763z"
		/>
		<path
			fill="#62dbfb"
			d="M122.15 107.588c11.142 7.998 18.407 29.309 18.407 44.071v57.763h25.664v-57.763c0-24.34-19.731-44.071-44.071-44.071z"
		/>
		<path
			fill="#6c7fd8"
			d="m122.15 209.421-28.128-13.25V52.156h28.128zM363.642 266.107 291.71 55.225l32.663 8.647 65.732 192.706z"
		/>
		<path
			fill="#fe646f"
			d="M344.845 63.872H84.681c-6.076 0-11.002-4.926-11.002-11.002V34.095c0-6.076 4.926-11.002 11.002-11.002h230.641c17.604-.066 33.347 11.778 38.773 28.148 2.064 6.226-2.69 12.631-9.25 12.631z"
		/>
		<path
			fill="#fd4755"
			d="M344.843 63.871h-26.754c6.552 0 11.311-6.408 9.251-12.63-5.408-16.318-21.077-28.145-38.611-28.145h26.589c17.606-.072 33.347 11.775 38.776 28.145 2.06 6.223-2.689 12.63-9.251 12.63z"
		/>
		<path
			fill="#9e9797"
			d="M457.159 261.804h-44.065v-83.323c0-5.588 3.797-10.529 8.639-12.577 0 0 21.941 5.75 21.941 13.201z"
		/>
		<path
			fill="#eef5f6"
			d="M435.127 172.716a7.725 7.725 0 0 1-7.726-7.726v-49.324c0-8.227-3.203-15.961-9.02-21.778a7.726 7.726 0 1 1 10.926-10.928c8.736 8.735 13.547 20.35 13.547 32.705v49.324a7.728 7.728 0 0 1-7.727 7.727z"
		/>
		<path
			fill="#8c8585"
			d="M443.668 164.99h-17.082c-1.713 0-3.345.332-4.852.914 5.05 1.95 8.639 6.839 8.639 12.577v83.323h26.787v-83.323c-.001-7.451-6.041-13.491-13.492-13.491z"
		/>
		<path
			fill="#fe646f"
			d="m492.643 306.077-14.206 12.548 14.206 14.969v51.313l-40.084 32.111H24.889c-10.745 0-20.016-7.551-22.149-18.08-1.803-8.89-2.74-18.1-2.74-27.516 0-60.863 39.363-112.527 94.025-130.915v-44.339h52.385c92.304 0 138.147 68.353 150.88 91.171 2.081 3.74 7.046 4.687 10.384 2.009l56.32-45.153a28.63 28.63 0 0 1 8.097-4.564c22.489-.68 91.769 24.92 109.374 38.642a29.018 29.018 0 0 1 11.178 22.88z"
		/>
		<path
			fill="#fd4755"
			d="M297.29 287.338h-16.061c-6.387 0-12.269-3.431-15.453-8.973-13.938-24.292-55.99-82.198-146.162-82.198h26.795c92.305 0 138.148 68.353 150.881 91.171zM492.643 306.077l-14.206 12.548 14.206 14.969v51.313l-40.084 32.111h-26.795l40.095-32.111v-83.754a28.997 28.997 0 0 0-11.188-22.88c-15.535-12.115-45.915-32.286-82.579-38.642a29.145 29.145 0 0 1 11.878-1.751c43.237 2.606 79.891 26.671 97.496 40.393a29.018 29.018 0 0 1 11.178 22.88v4.924z"
		/>
		<path
			fill="#eef5f6"
			d="M473.707 415.508h-50V374.42h50c6.355 0 23.29 5.151 23.29 11.506v18.075c0 6.355-16.935 11.507-23.29 11.507z"
		/>
		<path
			fill="#c7dae0"
			d="M500.494 374.42h-26.787c6.355 0 11.506 5.151 11.506 11.506v18.075c0 6.355-5.151 11.506-11.506 11.506h26.787c6.355 0 11.506-5.151 11.506-11.506v-18.075c0-6.354-5.151-11.506-11.506-11.506z"
		/>
		<path
			fill="#8c8585"
			d="M138.109 488.908a7.725 7.725 0 0 1-7.726-7.726v-9.976a7.726 7.726 0 1 1 15.452 0v9.976a7.727 7.727 0 0 1-7.726 7.726zM78.76 471.486a7.725 7.725 0 0 1-6.492-11.905l5.393-8.392a7.726 7.726 0 1 1 12.999 8.355l-5.393 8.392a7.722 7.722 0 0 1-6.507 3.55zM38.271 424.747a7.728 7.728 0 0 1-7.033-4.519 7.725 7.725 0 0 1 3.819-10.237l9.075-4.144a7.724 7.724 0 0 1 10.237 3.819 7.725 7.725 0 0 1-3.819 10.237l-9.075 4.144c-1.04.475-2.131.7-3.204.7zM39.349 364.949c-.366 0-.736-.026-1.11-.079l-9.874-1.419c-4.223-.607-7.155-4.524-6.548-8.747s4.526-7.15 8.747-6.548l9.874 1.419a7.727 7.727 0 0 1-1.089 15.374zM62.693 313.803a7.695 7.695 0 0 1-5.056-1.887l-7.539-6.533a7.727 7.727 0 0 1 10.118-11.678l7.539 6.533a7.727 7.727 0 0 1-5.062 13.565zM109.993 283.408a7.73 7.73 0 0 1-7.41-5.551l-2.811-9.572a7.727 7.727 0 0 1 14.827-4.355l2.811 9.572a7.727 7.727 0 0 1-7.417 9.906zM166.223 283.408a7.725 7.725 0 0 1-7.416-9.906l2.811-9.572a7.725 7.725 0 0 1 9.591-5.236 7.725 7.725 0 0 1 5.236 9.591l-2.811 9.572a7.731 7.731 0 0 1-7.411 5.551zM213.523 313.803a7.704 7.704 0 0 1-5.842-2.667 7.725 7.725 0 0 1 .78-10.899l7.54-6.533c3.224-2.792 8.105-2.446 10.898.78a7.725 7.725 0 0 1-.78 10.899l-7.54 6.533a7.695 7.695 0 0 1-5.056 1.887zM236.867 364.948a7.728 7.728 0 0 1-1.09-15.375l9.875-1.419a7.727 7.727 0 0 1 2.199 15.296l-9.875 1.419a7.867 7.867 0 0 1-1.109.079zM237.945 424.746a7.705 7.705 0 0 1-3.205-.7l-9.074-4.144a7.727 7.727 0 0 1 6.42-14.056l9.074 4.144a7.727 7.727 0 0 1-3.215 14.756zM197.457 471.486a7.72 7.72 0 0 1-6.507-3.55l-5.393-8.393a7.726 7.726 0 0 1 2.323-10.677 7.726 7.726 0 0 1 10.677 2.323l5.393 8.393a7.726 7.726 0 0 1-6.493 11.904z"
		/>
		<path
			fill="#9e9797"
			d="M225.987 371.422c0 55.109-87.878 97.161-87.878 97.161l-17.526 1.088c-46.733-8.299-82.258-49.132-82.258-98.249 0-49.13 35.507-89.965 82.258-98.249l19.258 2.231c-.001 0 86.146 27.406 86.146 96.018z"
		/>
		<path
			fill="#8c8585"
			d="M138.108 271.637c-5.98 0-11.836.528-17.526 1.536 46.752 8.284 82.259 49.119 82.259 98.249s-35.507 89.965-82.259 98.249a100.406 100.406 0 0 0 17.526 1.536c55.109 0 99.784-44.675 99.784-99.784.001-55.111-44.674-99.786-99.784-99.786z"
		/>
		<circle cx={138.108} cy={371.422} r={44.086} fill="#eef5f6" />
		<path
			fill="#8c8585"
			d="M392.583 488.908a7.725 7.725 0 0 1-7.726-7.726v-11.204a7.726 7.726 0 1 1 15.452 0v11.204a7.725 7.725 0 0 1-7.726 7.726zM346.83 472.256a7.726 7.726 0 0 1-5.915-12.692l7.202-8.583a7.727 7.727 0 0 1 11.837 9.932l-7.202 8.583a7.709 7.709 0 0 1-5.922 2.76zM322.481 430.095a7.728 7.728 0 0 1-1.333-15.337l11.034-1.946c4.197-.741 8.209 2.065 8.951 6.268a7.726 7.726 0 0 1-6.267 8.951l-11.034 1.946a7.873 7.873 0 0 1-1.351.118zM340.641 387.75a7.687 7.687 0 0 1-3.856-1.037l-9.703-5.602a7.726 7.726 0 0 1-2.828-10.554 7.724 7.724 0 0 1 10.554-2.828l9.703 5.602a7.726 7.726 0 0 1-3.87 14.419zM372.073 361.382a7.73 7.73 0 0 1-7.262-5.086l-3.832-10.529a7.727 7.727 0 0 1 4.618-9.903 7.722 7.722 0 0 1 9.903 4.618l3.832 10.529a7.727 7.727 0 0 1-7.259 10.371zM413.094 361.383a7.728 7.728 0 0 1-7.26-10.371l3.832-10.529a7.725 7.725 0 0 1 9.903-4.618 7.728 7.728 0 0 1 4.618 9.903l-3.832 10.529a7.73 7.73 0 0 1-7.261 5.086zM444.527 387.75a7.726 7.726 0 0 1-3.87-14.419l9.703-5.603a7.726 7.726 0 1 1 7.726 13.382l-9.703 5.603a7.684 7.684 0 0 1-3.856 1.037zM462.686 430.094c-.446 0-.896-.039-1.351-.119L450.3 428.03a7.726 7.726 0 0 1 2.684-15.219l11.035 1.946a7.726 7.726 0 0 1-1.333 15.337zM438.338 472.257a7.712 7.712 0 0 1-5.923-2.76l-7.202-8.583a7.727 7.727 0 0 1 11.837-9.933l7.202 8.583a7.726 7.726 0 0 1-.952 10.885 7.69 7.69 0 0 1-4.962 1.808z"
		/>
		<path
			fill="#9e9797"
			d="M442.08 410.007c0 45.812-67.022 57.361-67.022 57.361-24.57-7.497-42.444-30.338-42.444-57.361 0-26.91 18.032-49.768 42.444-57.361 0 0 67.022 8.737 67.022 57.361z"
		/>
		<path
			fill="#8c8585"
			d="M392.584 350.037c-6.098 0-11.981.917-17.526 2.609 24.569 7.498 42.444 30.339 42.444 57.361s-17.875 49.864-42.444 57.361a59.953 59.953 0 0 0 17.526 2.609c33.121 0 59.97-26.85 59.97-59.97s-26.85-59.97-59.97-59.97z"
		/>
		<circle cx={392.584} cy={410.007} r={25.096} fill="#eef5f6" />
		<path
			fill="#c7dae0"
			d="M138.109 395.419c-13.232 0-23.998-10.765-23.998-23.997s10.766-23.998 23.998-23.998 23.997 10.766 23.997 23.998-10.765 23.997-23.997 23.997zm0-32.543c-4.712 0-8.545 3.833-8.545 8.545s3.833 8.545 8.545 8.545c4.711 0 8.544-3.833 8.544-8.545 0-4.711-3.833-8.545-8.544-8.545z"
		/>
		<path
			fill="#fd4755"
			d="M283.939 371.422c0 10.024-2.153 27.99-4.172 37.705a29.14 29.14 0 0 1-2.864 7.891h-23.519c5.522-1.01 10.075-5.347 11.26-11.023 1.803-8.695 3.843-25.6 3.843-34.573 0-34.717-10.817-68.311-35.078-92.809-24.251-24.518-59.266-43.443-93.973-43.783-14.752-.155-31.616 1.03-45.41 5.676v-16.143c14.361-3.987 30.802-5.141 45.565-4.986 38.807.381 77.665 20.933 104.811 48.357 27.133 27.423 39.537 64.881 39.537 103.688z"
		/>
		<path
			fill="#eef5f6"
			d="M275.596 205.454a7.728 7.728 0 0 1-5.223-13.423l20.767-19.037a7.728 7.728 0 0 1 10.443 11.391l-20.767 19.037a7.702 7.702 0 0 1-5.22 2.032z"
		/>
		<path
			fill="#fef48b"
			d="M465.854 333.593h-5.366c-7.598 0-13.758-6.16-13.758-13.758 0-7.598 6.16-13.758 13.758-13.758h5.366l9.292 11.966z"
		/>
		<path fill="#fef056" d="M465.854 306.077h26.787v27.516h-26.787z" />
	</svg>
)


TractorIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

TractorIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default TractorIcon