import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import {Grid, withStyles} from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, {BO_FILTER_FORM} from '../BackOfficeContainer'
import {createCulture, createItk, getCultureItkList, updateCulture, updateItk} from './services/cultureItkApi'
import {getPreferences} from '../../common/user/services/userSelectors'
import {connect} from 'react-redux'
import BoListView from '../components/BoListView'
import SpaIcon from '@material-ui/icons/Spa'
import BlockIcon from '@material-ui/icons/Block';
import ItineraireTechniqueUniIcon from '../../../components/icon/ItineraireTechniqueUniIcon'
import InfoCulture from '../../common/information/InfoCulture'
import ZoneItk from '../../common/information/ZoneItk'
import NoResultPage from '../../../components/NoResultPage'
import ItkForm from './components/ItkForm'
import {getCultureForInitValues, getItkForInitValues} from './cultureItkUtils'
import {change, formValueSelector, reset, SubmissionError} from 'redux-form'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import FormButton from '../../../components/form/FormButton'
import {colors, TYPE_DIALOG} from '../../../utils/constants'
import {DialogContext} from '../../../components/layout/dialog'
import AddCultureItkForm, {FIELDS} from './components/AddCultureItkForm'
import CultureForm from './components/CultureForm'
import {localCompare} from '../../../utils/utils'
import Loader from '../../../components/layout/Loader'
import { compose } from 'redux'
import { injectFermeList } from '../ferme/services/fermeListInjector'
import PopinDesactive, {DESACTIVE_ITK_FORM} from "./components/PopinDesactive";
import {getNotifications} from '../../common/notification/notificationActions'
import {useSolverStatusContext} from '../../fo/besoinsProduction/SolverStatusContextProvider'
import {getStyles} from "isotope-client";

export const CULTURE_ITK_FORM = 'CULTURE_ITK_FORM'
export const ADD_CULTURE_ITK_FORM = 'ADD_CULTURE_ITK_FORM'

const styles = () => getStyles({
	link: {
		'&.MuiTypography-root': {
			color: colors.link,
			cursor: 'pointer',
			textAlign: 'center',
		}
	},
})

const CultureItkPage = ({ idFerme, reset, fermeList, change, classes }) => {
	const intl = useIntl()
	const INIT_FILTERS = { idFerme }
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [cultures, setCultures] = React.useState([])
	const [selection, setSelection] = React.useState(undefined)
	const [itkDesactive, setItkDesactive] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	React.useEffect(() => {
		setLoading(true)
		getCultureItkList(filters)
			.then((cultureList) =>
				setCultures(cultureList.map(culture => formatTuileCulture(culture)))
			)
			.finally(() => setLoading(false))
		setSelection(undefined)
	}, [filters])

	const formatTuileCulture = (culture) => ({
		uniqueId: `culture-${culture.id}`,
		icon: SpaIcon,
		title: culture.nom,
		description: <InfoCulture culture={culture} />,
		data: culture,
		children: culture.itks.map(itk => formatTuileItk(itk, culture))
	})

	const formatTuileItk = (itk, culture) => ({
		uniqueId: `itk-${itk.id}`,
		icon: ItineraireTechniqueUniIcon,
		title: itk.designation,
		subtitle: itk.numItk ? ` - N°${itk.numItk}` : '',
		description: <ZoneItk itk={itk} />,
		data: {
			...itk,
			isItk: true,
			nomCulture: culture && culture.nom,
			idCulture: culture && culture.id,
			voileThermique: itk.voileThermique
		},
		children: []
	})

	const openPopinDesactiveItk = () => {
		openDialog(
			<FormattedMessage id="bo.cultureItk.popinDesactive.title" />,
		 	<PopinDesactive onSubmit={(values) => {
				setItkDesactive(values)
				closeDialog()
			}} itkDesactive={itkDesactive}/>,
			[
				<FormButton type="primary" formName={DESACTIVE_ITK_FORM}>
					<FormattedMessage id="bo.cultureItk.popinDesactive.confirmation" />
				</FormButton>
			],
			TYPE_DIALOG.ACTION
		)
	}

	const getTitleSelection = (selection) => {
		const dataSelection = selection.data
		return (
			<Grid container style={{ fontFamily: 'Lato' }}>
				{dataSelection.isItk ? (
					<Grid container item justify="space-between" alignItems="center">
						<Grid item xs={7}>
							{dataSelection.nomCulture} - {selection.title} :
						</Grid>
						<Grid item container
							  justify="flex-end"
							  alignItems="center"
							  xs={5}
							  style={{ color: colors.link, cursor: 'pointer' }}
							  onClick={() => openPopinDesactiveItk()}
						>
							<BlockIcon style={{ marginRight: 5 }} />
							<Typography variant="body1" className={classes.link}>
								<FormattedMessage id="bo.cultureItk.popinButton"/>
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid item>
						{`${selection.title} :`}
					</Grid>
				)}
			</Grid>
		)
	}

	// Formattage des values pour culture / ITK
	const initialValues = React.useMemo(() => {
		if (selection && selection.data) {
			return selection.data.isItk ? getItkForInitValues(selection.data) : getCultureForInitValues(selection.data)
		}
		return {}
	}, [selection])

	React.useEffect(() => {
		setItkDesactive(initialValues?.campagnes?.reduce((acc, curr) => ({...acc, [`${curr.libelleCampagne}_${curr.idCampagne}`]: !curr.enabled}), {}))
	}, [selection])

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.cultureItk.popin.title" />,
			<AddCultureItkForm
				formName={ADD_CULTURE_ITK_FORM}
				onSubmit={handleCreation}
				idFerme={filters.idFerme}
				prodMinoFerme={fermeList.find(ferme => ferme.id === filters.idFerme).prodMino}
			/>,
			[
				<FormButton
					type="primary"
					formName={ADD_CULTURE_ITK_FORM}
					getDisabledFromState={state => !formValueSelector(ADD_CULTURE_ITK_FORM)(state, FIELDS.TYPE_AJOUT.name)}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const handleUpdateItk = (values) => {
		updateItk(selection.data.idCulture, {...values, desactivationItk: values.campagnes.map(campagne => ({...campagne, enabled: !itkDesactive[`${campagne.libelleCampagne}_${campagne.idCampagne}`]}))})
			.then((newItk) => {
				snackSuccess({id: 'bo.cultureItk.snackbar.updateItk'})
				getNotifications()
				refreshSolverInfosForCurrentCampagne()

				// Mise à jour de l'itk et de la sélection
				const formattedItk = formatTuileItk(newItk)
				setCultures(state => {
					const newCultures = [...state]

					const indexCulture = newCultures.findIndex(culture => culture.data.id === newItk.idCulture)
					if (indexCulture !== -1) {
						const indexItk = newCultures[indexCulture].children.findIndex(child => child.data.id === newItk.id)
						if (indexItk !== -1) {
							newCultures[indexCulture].children[indexItk] = {
								...formattedItk,
								data: {
									...formattedItk.data,
									isItk: true,
									idCulture: newCultures[indexCulture].children[indexItk].data.idCulture,
									nomCulture: newCultures[indexCulture].children[indexItk].data.nomCulture
								}
							}
							newCultures[indexCulture].children.sort(localCompare('title'))
						}
					}

					return newCultures
				})

				setSelection(state => ({
						...formattedItk,
						data: {
							...formattedItk.data,
							isItk: true,
							idCulture: state.data.idCulture,
							nomCulture: state.data.nomCulture
						}
					})
				)
			})
			.catch(e => {
				snackError({id: 'global.errors.form'})
				throw new SubmissionError(e)
			})
	}

	const handleCreation = (values) => {
		const isCreationItk = values.typeAjout === 'itk'
		const promise = isCreationItk ? createItk(values) : createCulture(filters.idFerme, values)

		return promise
			.then(() => {
				snackSuccess({ id: `bo.cultureItk.snackbar.add${isCreationItk ? 'Itk' : 'Culture'}` })
				setSelection(undefined)
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				reset(BO_FILTER_FORM)
				// Gestion du filter ferme après reset
				change(BO_FILTER_FORM, 'idFerme', filters.idFerme)
				setFilters({
					...INIT_FILTERS,
					idFerme: filters.idFerme
				})
				closeDialog()
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})
	}

	const handleUpdateCulture = (values) =>
		updateCulture(filters.idFerme, values)
			.then((newCulture) => {
				snackSuccess({ id: 'bo.cultureItk.snackbar.updateCulture' })
				getNotifications()
				refreshSolverInfosForCurrentCampagne()

				// Mise à jour de l'itk et de la sélection
				const formattedCulture = formatTuileCulture(newCulture)
				setCultures(state => {
					const newCultures = [...state]

					const indexCulture = newCultures.findIndex(culture => culture.data.id === newCulture.id)
					if (indexCulture !== -1) {
						newCultures[indexCulture] = formattedCulture
						newCultures.sort(localCompare('title'))
					}

					return newCultures
				})

				setSelection(formattedCulture)
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.cultureItk.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={openPopinCreation}><FormattedMessage id="bo.cultureItk.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer inputLabel="bo.cultureItk.inputQuery" onSubmit={setFilters}>
			{loading ?
				<Loader fromBo />
				:
				cultures.length > 0 ?
					<BoListView
						titleLeft={intl.formatMessage({ id: 'bo.cultureItk.titleLeft' })}
						titleRight={selection ? getTitleSelection(selection) : ''}
						tuileList={cultures}
						selection={selection}
						handleSelection={setSelection}
						formName={CULTURE_ITK_FORM}
					>
						{selection && selection.data && <Grid style={{paddingTop: 15}}>
							{selection.data.isItk ?
								<ItkForm
									formName={CULTURE_ITK_FORM}
									onSubmit={handleUpdateItk}
									initialValues={initialValues}
									itkDesactive={itkDesactive}
								/>
								:
								<CultureForm
									formName={CULTURE_ITK_FORM}
									onSubmit={handleUpdateCulture}
									initialValues={initialValues}
								/>
							}
						</Grid>}
					</BoListView>
					: <NoResultPage />
			}
		</BackOfficeContainer>
	</>)
}

CultureItkPage.propTypes = {
	idFerme: PropTypes.string,
	reset: PropTypes.func,
	fermeList: PropTypes.array,
	classes: PropTypes.object,
}

const mapStateToProps = (state) => ({
	idFerme: (getPreferences(state).FERME_ID || '0')
})

const actions = {
	reset,
	change,
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	injectFermeList,
)(CultureItkPage)

