import React from 'react'
import PropTypes from 'prop-types'

const SunIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#ff9100"
			d="m445.097 466.308-72.422-72.422c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l72.422 72.422c5.859 5.859 5.859 15.352 0 21.211-5.86 5.859-15.352 5.859-21.211 0z"
		/>
		<path
			fill="#fabe2c"
			d="M118.114 139.325 45.692 66.903c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l72.422 72.422c5.859 5.859 5.859 15.352 0 21.211s-15.351 5.86-21.211 0zM45.692 466.308c-5.859-5.859-5.859-15.352 0-21.211l72.422-72.422c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-72.422 72.422c-5.859 5.859-15.351 5.859-21.211 0z"
		/>
		<path
			fill="#ff9100"
			d="M372.675 139.325c-5.859-5.859-5.859-15.352 0-21.211l72.422-72.422c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-72.422 72.422c-5.859 5.859-15.352 5.86-21.211 0zM322.504 455.761l-11.484-27.715c-3.164-7.646.469-16.436 8.115-19.6 7.632-3.149 16.436.469 19.6 8.115l11.484 27.715c3.164 7.646-.469 16.436-8.115 19.6-7.634 3.161-16.432-.456-19.6-8.115z"
		/>
		<path
			fill="#fabe2c"
			d="m173.266 95.438-11.484-27.715c-3.164-7.646.469-16.436 8.115-19.6 7.603-3.149 16.45.469 19.6 8.115l11.484 27.715c3.164 7.646-.469 16.436-8.115 19.6-7.634 3.161-16.432-.456-19.6-8.115zM48.124 342.104c-3.164-7.646.469-16.436 8.115-19.6l27.715-11.484c7.646-3.164 16.436.454 19.6 8.115 3.164 7.646-.469 16.436-8.115 19.6l-27.715 11.484c-7.634 3.161-16.432-.457-19.6-8.115z"
		/>
		<path
			fill="#ff9100"
			d="M408.446 192.865c-3.164-7.646.469-16.436 8.115-19.6l27.715-11.484c7.646-3.12 16.436.454 19.6 8.115 3.164 7.646-.469 16.436-8.115 19.6l-27.715 11.484c-7.634 3.161-16.431-.456-19.6-8.115z"
		/>
		<path
			fill="#fabe2c"
			d="M169.896 463.876c-7.646-3.164-11.279-11.953-8.115-19.6l11.484-27.715c3.164-7.646 11.953-11.279 19.6-8.115s11.279 11.953 8.115 19.6l-11.484 27.715c-3.171 7.664-11.971 11.274-19.6 8.115z"
		/>
		<path
			fill="#ff9100"
			d="M319.135 103.554c-7.646-3.164-11.279-11.953-8.115-19.6l11.484-27.715c3.164-7.661 11.982-11.279 19.6-8.115 7.646 3.164 11.279 11.953 8.115 19.6l-11.484 27.715c-3.171 7.664-11.972 11.273-19.6 8.115z"
		/>
		<path
			fill="#fabe2c"
			d="m83.954 200.98-27.715-11.484c-7.646-3.164-11.279-11.953-8.115-19.6 3.179-7.661 11.997-11.25 19.6-8.115l27.715 11.484c7.646 3.164 11.279 11.953 8.115 19.6-3.171 7.664-11.972 11.274-19.6 8.115z"
		/>
		<path
			fill="#ff9100"
			d="m444.276 350.219-27.715-11.484c-7.646-3.164-11.279-11.953-8.115-19.6 3.178-7.661 11.997-11.294 19.6-8.115l27.715 11.484c7.646 3.164 11.279 11.953 8.115 19.6-3.171 7.664-11.971 11.273-19.6 8.115z"
		/>
		<path
			fill="#fabe2c"
			d="M76 271H15c-8.291 0-15-6.709-15-15s6.709-15 15-15h61c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
		/>
		<path
			fill="#ff9100"
			d="M497 271h-61c-8.291 0-15-6.709-15-15s6.709-15 15-15h61c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
		/>
		<circle cx={256} cy={256} r={135} fill="#fabe2c" />
		<path
			fill="#ff9100"
			d="M391 256c0-74.443-60.557-135-135-135v270c74.443 0 135-60.557 135-135z"
		/>
		<path
			fill="#fabe2c"
			d="M256 421c-8.291 0-15 6.709-15 15v61c0 8.291 6.709 15 15 15s15-6.709 15-15v-61c0-8.291-6.709-15-15-15z"
		/>
		<path
			fill="#ff9100"
			d="M271 497v-61c0-8.291-6.709-15-15-15v91c8.291 0 15-6.709 15-15z"
		/>
		<path
			fill="#fabe2c"
			d="M256 0c-8.291 0-15 6.709-15 15v61c0 8.291 6.709 15 15 15s15-6.709 15-15V15c0-8.291-6.709-15-15-15z"
		/>
		<path
			fill="#ff9100"
			d="M271 76V15c0-8.291-6.709-15-15-15v91c8.291 0 15-6.709 15-15z"
		/>
	</svg>
)


SunIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

SunIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default SunIcon