import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const FinRecolteOutlinedIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M500.439 269.886a39.417 39.417 0 00-11.687-8.081V207.06c0-42.214-33.951-76.898-75.681-77.315a76.216 76.216 0 00-20.063 2.45c-17.685-15.316-39.962-23.65-63.686-23.65-23.728 0-46.007 8.34-63.694 23.669a78.194 78.194 0 00-19.439-2.473c-42.214 0-76.559 34.238-76.571 76.323l-.008 2.206h-59.15c-2.258 0-4.487.51-6.521 1.491l-78.214 37.753c-21.534 10.395-31.382 35.903-22.42 58.071l21.292 52.661c9.144 22.617 34.333 33.982 57.339 25.879l57.758-20.344 87.766 38.414a15 15 0 006.015 1.259H413c8.97 0 17.647-3.74 23.808-10.263l63.803-67.562c15.243-15.431 15.185-40.386-.172-55.743zm-300.817-63.744v-.057c0-25.553 20.892-46.341 46.568-46.341 6.076 0 12.182 1.176 17.654 3.4a15.005 15.005 0 0016.521-3.562c12.893-13.564 30.28-21.034 48.959-21.034 18.686 0 36.078 7.47 48.971 21.034a15.006 15.006 0 0016.522 3.562c5.699-2.317 11.737-3.452 17.952-3.397 25.355.254 45.981 21.48 45.981 47.315v53.745a39.534 39.534 0 00-14.231 9.152l-43.087 43.144h-56.507a172.709 172.709 0 01-72.586-25.887h76.349c21.537 0 39.466-17.282 39.968-38.525.253-10.724-3.728-20.849-11.209-28.508-7.503-7.682-17.559-11.912-28.317-11.912H199.614l.008-2.129zm279.603 98.444c-.101.101-.2.204-.298.307l-63.932 67.699a2.759 2.759 0 01-1.996.861H236.615v-.001l-90.309-39.527a15.007 15.007 0 00-10.999-.406L71.969 355.83a15.505 15.505 0 01-19.557-8.827L31.12 294.342c-3.057-7.562.303-16.261 7.647-19.806l75.124-36.261h235.238a9.5 9.5 0 016.854 2.873 9.384 9.384 0 012.678 6.836c-.12 5.089-4.594 9.231-9.974 9.231H228.924a15.002 15.002 0 00-9.664 26.474l15.49 13.049c36.425 30.681 82.712 47.579 130.337 47.579h41.355c3.981 0 7.801-1.583 10.614-4.4l48.685-48.749a9.57 9.57 0 016.78-2.826h.025a9.38 9.38 0 016.678 2.761c3.718 3.717 3.718 9.766.001 13.483z"
				fill={color}
			/>

		</svg>
	)
}

FinRecolteOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

FinRecolteOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default FinRecolteOutlinedIcon
