import {Grid, Typography} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {colors, ONGLETS_BESOINS_PRODUCTION, SOLVER_STATUS, TYPE_DIALOG} from '../../../../../utils/constants'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import {useBesoinsProductionContext} from '../../BesoinsProductionProvider'
import {StepIcon, StyledConnector, StyledLabel} from '../../../../common/stepper/Stepper'
import ShowChart from '@material-ui/icons/ShowChart'
import TableChart from '@material-ui/icons/TableChart'
import UpdateIcon from '@material-ui/icons/Update'
import Button from '../../../../../components/Button'
import ClearAll from '@material-ui/icons/ClearAll'
import Check from '@material-ui/icons/Check'
import {DialogContext} from '../../../../../components/layout/dialog'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {compose} from 'redux'
import {getCurrentCampagne} from '../../../../common/campagne/campagneSelector'
import {connect} from 'react-redux'
import {push, replace} from 'connected-react-router'
import {setActiveCampagne} from '../../../../common/campagne/campagneAction'
import {useSolverStatusContext} from '../../SolverStatusContextProvider'
import {LocalDateTime} from '../../../../../utils/date/local-date-time'
import {DateFormat, TimeFormat} from '../../../../../utils/date/model/date'
import classnames from 'classnames'
import FormattedMessageWithBold from "../../../../../components/FormattedMessageWithBold";
import {approveSolution, launchSolver} from "../../../../common/solver/solverApi";

const styles = () => getStyles({
	toolbar: {
		paddingLeft: 25
	},
	stepper: {
		padding: 0,
		marginTop: 7
	},
	content: {
		paddingTop: 15,

		'& .MuiGrid-container': {
			width: 'auto'
		}
	},
	head: {
		fontSize: 24,
		padding: 10
	},
	boutons: {
		gap: 10
	},
	rightBloc: {
		paddingRight: 25
	},
	bouton: {
		height: 36,
		minWidth: 'auto',
		paddingLeft: 20,
		paddingRight: 20
	},
	titrePopin: {
		display: 'flex',
		alignItems: 'center',
		gap: 15,
		letterSpacing: 0,
		width: 550,

		'& svg': {
			color: colors.primary
		}
	},
	contenuPopin: {
		padding: '10px 30px'
	},
	error: {
		width: '70%',
		color: colors.error,
		fontSize: 14,
		fontFamily: 'Lato',
		textAlign: 'end',
		fontWeight: 700
	}
})

const getSteps = () => {
	return [
		ONGLETS_BESOINS_PRODUCTION.RECUEIL,
		ONGLETS_BESOINS_PRODUCTION.RESULTATS
	]
}

export const HeaderStepIcon = (props) => {
	const icons = {
		1: <TableChart/>,
		2: <ShowChart/>
	}

	return (
		<StepIcon
			icons={icons}
			{...props}
		/>
	)
}

const Header = ({classes, campagne, goToPreview, setActiveCampagne, goToBesoin, campagnes, isBesoinNotSaved}) => {
	const {onglet, setOnglet} = useBesoinsProductionContext()
	const {solverInfos, refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()
	const [isApprouving, setIsApprouving] = React.useState(false)
	const steps = getSteps()
	const {openDialog, closeDialog} = React.useContext(DialogContext)

	const hasResultsToApprove = (solverInfos?.status === SOLVER_STATUS.DONE)

	const solverInError = (solverInfos?.status === SOLVER_STATUS.ERROR)

	const handleStep = (step) => {
		if (step === ONGLETS_BESOINS_PRODUCTION.RECUEIL) {
			goToBesoin()
		}
		setOnglet(step)
	}

	const openPopinSolveur = () => {
		openDialog(
			<span className={classes.titrePopin}>
				<UpdateIcon/>
				<FormattedMessage id="besoinsProduction.recueil.popin.validation.title"/>
			</span>,
			<span className={classes.contenuPopin}>
                <p>
					<FormattedMessageWithBold
						id="besoinsProduction.recueil.popin.validation.message1"
						values={{campagnes: campagnes.filter(c => !c.finished).map(c => c.nom).join(', ')}}
					/>
				</p>
                <p><FormattedMessage id="besoinsProduction.recueil.popin.validation.message2"/></p>
            </span>,
			[
				<Button withBorder={false} variant="text" onClick={() => launchSolver().then(() => {
					refreshSolverInfosForCurrentCampagne()
					closeDialog()
				})}>
					<FormattedMessage id="actions.confirm"/>
				</Button>
			], TYPE_DIALOG.ACTION, 'actions.close'
		)
	}

	const openPopinApprobation = () => {
		openDialog(
			<span className={classes.titrePopin}>
				<CheckCircleOutlineIcon/>
				<FormattedMessage id="besoinsProduction.header.popin.approbation.title"/>
			</span>,
			<span className={classes.contenuPopin}>
				<FormattedMessage
					id="besoinsProduction.header.popin.approbation.message"
					values={{campagne: campagnes.filter(c => !c.finished).map(c => c.nom).join(', ')}}
				/>
			</span>,
			[
				<Button
					variant="text"
					withBorder={false}
					onClick={() => {
						approveSolution()
						refreshSolverInfosForCurrentCampagne()
						closeDialog()
						setIsApprouving(!isApprouving)
					}}
				>
					<FormattedMessage id="actions.confirm"/>
				</Button>
			], TYPE_DIALOG.ACTION
		)
	}

	return (
		<Grid container direction="row" justify="space-between" className={classes.content}>
			<Grid item container xs={12} md={4} lg={4} direction="column" justify="center" alignItems="flex-start" className={classes.toolbar}>
				<Typography variant="h1" className={classes.head}>
					<FormattedMessage id={`besoinsProduction.header.title`}/>
				</Typography>
			</Grid>

			<Grid item container xs={12} md={4} lg={4} direction="row" justify="center" alignItems="center">
				<Stepper className={classes.stepper} alternativeLabel activeStep={onglet.order}
						 connector={<StyledConnector/>}>
					{steps.map(onglet => (
						<Step key={onglet.code}>
							<StyledLabel StepIconComponent={HeaderStepIcon} StepIconProps={{clickable: true}} large onClick={() => handleStep(onglet)}>
								<FormattedMessage id={`besoinsProduction.header.steps.${onglet.code}`}/>
							</StyledLabel>
						</Step>
					))}
				</Stepper>
			</Grid>

			{onglet === ONGLETS_BESOINS_PRODUCTION.RECUEIL ?
				<Grid item container xs={12} md={4} lg={4} direction="column" justify="center" alignItems="flex-end" className={classes.rightBloc}>
					<Button
						type="primary"
						startIcon={<UpdateIcon/>}
						onClick={openPopinSolveur}
						disabled={isBesoinNotSaved}
						className={classes.bouton}
					>
						<FormattedMessage id="besoinsProduction.header.buttons.planifier"/>
					</Button>
				</Grid>
				: (
					<Grid item container xs={12} md={3} lg={4} direction="column" alignItems="flex-end" justify="center" className={classnames(classes.rightBloc, classes.boutons)}>
						{!isApprouving && hasResultsToApprove && <Grid item container direction="row" justify="flex-end" alignItems="center" className={classes.boutons}>
							<Button
								type="secondary"
								startIcon={<ClearAll/>}
								onClick={() => {
									setActiveCampagne({...campagne, preview: true})
									goToPreview()
								}}
								className={classes.bouton}
							>
								<FormattedMessage id="besoinsProduction.header.buttons.planning"/>
							</Button>
							<Button
								type="primary"
								startIcon={<Check/>}
								onClick={() => openPopinApprobation(campagne)}
								className={classes.bouton}
							>
								<FormattedMessage id="besoinsProduction.header.buttons.approuver"/>
							</Button>
						</Grid>}
						{ solverInError &&
							<Grid item className={classes.error}>
								<Typography variant="error" className={classes.error}>
									<FormattedMessage id="besoinsProduction.header.error.errorSolution"/>
								</Typography>
							</Grid>
						}
						{solverInfos?.userGeneration && <Grid item>
							<Typography variant="body1">
								<FormattedMessageWithBold id="besoinsProduction.header.infos.generatedSolution" values={{
									user: solverInfos?.userGeneration,
									date: LocalDateTime.fromAPI(solverInfos?.dateGeneration).toDisplayDate(DateFormat.SHORT_DATE),
									heure: LocalDateTime.fromAPI(solverInfos?.dateGeneration).toDisplayDate(TimeFormat.TIME_ONLY)
								}} />
							</Typography>
						</Grid>}
						{solverInfos?.userSauvegarde && <Grid item>
							<Typography variant="body1">
								<FormattedMessageWithBold id="besoinsProduction.header.infos.savedSolution" values={{
									user: solverInfos?.userSauvegarde,
									date: LocalDateTime.fromAPI(solverInfos?.dateSauvegarde).toDisplayDate(DateFormat.SHORT_DATE),
									heure: LocalDateTime.fromAPI(solverInfos?.dateSauvegarde).toDisplayDate(TimeFormat.TIME_ONLY)
								}} />
							</Typography>
						</Grid>}
						{isApprouving && solverInfos?.userSauvegarde === null && <Grid item>
							<Typography variant="body1">
								<FormattedMessageWithBold id="besoinsProduction.header.infos.approuving"/>
							</Typography>
						</Grid>}
					</Grid>
				)}
		</Grid>
	)
}

Header.propTypes = {
	classes: PropTypes.object,
	solverStatus: PropTypes.string
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

const actions = {
	setActiveCampagne,
	goToBesoin: () => dispatch => dispatch(replace('/besoins-production')),
	goToPreview: () => dispatch => dispatch(push('/planning'))
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(Header)