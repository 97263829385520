import React from 'react'
import { getStyles } from 'isotope-client'
import ToolBar from './component/Toolbar/ToolBar'
import { withStyles } from '@material-ui/core'
import { colors } from '../../../utils/constants'
import { getSuiviAvancementRessource } from './suiviAvancementApi'
import { SUIVI_RESSOURCES_TABLE } from './component/SuiviRessourcesTable'
import moment from 'moment'
import { SUIVI_RESSOURCE_TABLE } from './component/SuiviRessourceTable'
import { dataTableActions } from 'isotope-client'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PageAvancementAllUsers from './component/PageAvancementAllUsers'
import PageAvancementOneUser from './component/PageAvancementOneUser'

const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		height: '-webkit-fill-available'
	},
	title: {
		fontSize: '18px',
		fontWeight: 'bold',
		color: colors.primary,
		marginLeft: '32px',
		marginBottom: '12px',
		height: '36px'
	},
	containerTitleWithButton: {
		display: 'flex',
		marginLeft: '32px',
		marginBottom: '25px',
		alignItems: 'center'
	},
	backButton: {
		marginRight: '24px',
		width: '105px',
		height: '36px',
		minWidth: '105px'
	},
	titleWithButton: {
		fontWeight: 'bold',
		color: colors.primary,
		fontSize: '18px'
	},
	title2: {
		paddingTop: 30
	}
})

const SuiviAvancementComponents = ({ classes, resetSelectedRowsRessources, resetSelectedRowsRessource }) => {

	const jourActuel = React.useMemo(() => moment().startOf('day'), [])
	const [idUserSelectionne, setIdUserSelectionne] = React.useState(null)
	const [dataSuiviUser, setDataSuiviUser] = React.useState({
		alias: '',
		tachesPlanifiees: 0,
		tachesARealiser: 0,
		tachesEnCours: 0,
		tachesTerminees: 0,
		chargeTravailTotal: 'PT0S',
		tempsPasseARealiser: 'PT0S',
		tempsPasseEnCours: 'PT0S',
		tempsPasseTerminees: 'PT0S',
		chargeTravailARealiser: 'PT0S',
		chargeTravailEnCours: 'PT0S',
		chargeTravailTerminees: 'PT0S'
	})

	const refreshTable = React.useCallback(() => {
		if (idUserSelectionne) {
			getSuiviAvancementRessource(jourActuel.format('YYYY-MM-DD'), idUserSelectionne)
				.then(res => setDataSuiviUser(res))
		}
		resetSelectedRowsRessources()
		resetSelectedRowsRessource()
	}, [idUserSelectionne, jourActuel, resetSelectedRowsRessource, resetSelectedRowsRessources])

	React.useEffect(refreshTable, [])

	return <div className={classes.root}>
		<ToolBar refreshTable={refreshTable} idUserSelectionne={idUserSelectionne} setDataSuiviUser={setDataSuiviUser} />

		{idUserSelectionne
			? <PageAvancementOneUser
				dataSuiviUser={dataSuiviUser}
				setDataSuiviUser={setDataSuiviUser}
				refreshTable={refreshTable}
				classes={classes}
				setIdUserSelectionne={setIdUserSelectionne}
				idUserSelectionne={idUserSelectionne}
				jourActuel={jourActuel}
			/>
			: <PageAvancementAllUsers
				classes={classes}
				setIdUserSelectionne={setIdUserSelectionne}
				jourActuel={jourActuel}
			/>
		}

	</div>
}

const actions = {
	resetSelectedRowsRessources: () => dataTableActions.selectRows(SUIVI_RESSOURCES_TABLE, []),
	resetSelectedRowsRessource: () => dataTableActions.selectRows(SUIVI_RESSOURCE_TABLE, [])
}

export default compose(
	connect(null, actions),
	withStyles(styles)
)(SuiviAvancementComponents)
