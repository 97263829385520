import { default as MUIButton } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from 'isotope-client'

const styles = theme => getStyles({
	circularProgress: {
		position: 'absolute',
		color: "rgba(255,255,255,0.5)"
	},
	loading: {
		visibility: 'hidden'
	},
	button: {
		"&:disabled": {
			color: "rgba(255,255,255,0.5)",
			backgroundColor: theme.palette.disabled,
			borderColor: theme.palette.disabled
		},
		height: 36,
		minWidth: 190,

	},
	buttonBorder: {
		borderRadius: 20,
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: theme.palette.primary.main
	},
	buttonSecondary: {
		"&:hover": {
			backgroundColor: "#DDD"
		},
		backgroundColor: "#FFF",
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	buttonCancel: {
		"&:hover": {
			backgroundColor: "#DDD"
		},
		backgroundColor: "#FFF",
		color: theme.palette.placeholder,
		borderColor: theme.palette.placeholder
	},
	labelKeepCase: {
		textTransform: "unset"
	}
})

class Button extends React.Component {

	static propTypes = {
		onClick: PropTypes.func,
		color: PropTypes.string,
		disabled: PropTypes.bool,
		loading: PropTypes.bool,
		className: PropTypes.string,
		type: PropTypes.oneOf(["primary", "secondary", "cancel"]),
		withBorder: PropTypes.bool,
	}

	static defaultProps = {
		disabled: false,
		color: 'primary',
		className: '',
		type: "primary",
		withBorder: true
	}

	constructor(props) {
		super(props)
		this.state = {
			loading: false
		}
	}

	localOnClick(onClick, event) {
		if (onClick) {
			const result = onClick(event)
			if (result && result.then) {
				this.setState({
					loading: true
				})
				this.promise = result
					.then(retour => {
						if (!this._isUnMounted) {
							if (retour || typeof retour === 'undefined') {
								this.setState({ loading: false })
							}
							return retour
						}
					})
					.catch((e) => {
						if (!this._isUnMounted) {
							this.setState({ loading: false })
							throw e
						}
					})
				return this.promise
			} else {
				return result
			}
		}
	}

	static getDerivedStateFromProps(props) {
		if (props.loading !== undefined) {
			return {
				loading: props.loading
			}
		}
		return null
	}

	componentWillUnmount() {
		this._isUnMounted = true
	}

	render() {
		const { withBorder, onClick, color, disabled, classes, children, className, loading: nonutilise, type, keepCase = false, ...otherProps } = this.props
		const { loading } = this.state
		return (
			<MUIButton
				color={color}
				className={classnames(
					classes.button,
					{
						[classes.buttonBorder]: withBorder,
						[classes.buttonSecondary]: type === "secondary",
						[classes.buttonCancel]: type === "cancel",
						[classes.labelKeepCase]: otherProps.startIcon || keepCase
					},
					className
				)}
				onClick={(event) => typeof onClick === 'function' && !loading && this.localOnClick(onClick, event)}
				disabled={disabled || loading}
				variant="contained"
				disableElevation
				{...otherProps}
			>
				<div className={loading ? classes.loading : null}>
					{children}
				</div>
				{loading && <CircularProgress color={color} className={classes.circularProgress} size={20} />}
			</MUIButton>
		)
	}
}

export default withStyles(styles)(Button)
