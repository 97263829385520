import Grid from '@material-ui/core/Grid/index'
import IconButton from '@material-ui/core/IconButton/index'
import {ClickAwayListener, useMediaQuery} from '@material-ui/core'
import Paper from '@material-ui/core/Paper/index'
import Slide from '@material-ui/core/Slide/index'
import Typography from '@material-ui/core/Typography/index'
import CloseIcon from '@material-ui/icons/Close'
import withStyles from '@material-ui/styles/withStyles/index'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {onClickAway, toI18N} from '../../../utils/utils'
import Button from '../../Button'

const zIndex = 1001
const styles = () => getStyles({
	slide: {
		position: 'fixed',
		right: 0,
		top: 64,
		width: 460,
		height: 'calc(100vh - 60px)',
		background: '#f7f5f8'
	},
	closeIcon: {
		position: 'absolute',
		top: 12,
		right: 12
	},
	titleContainer: {
		height: 65
	},
	title: {
		width: 348,
		height: 50,
		borderBottom: '2px solid #55555a',
		textAlign: 'center',
	},
	content: {
		height: 'calc(100vh - 220px)',
		overflowY: 'auto'
	},
	contentTablette: {
		height: 'calc(100vh - 300px)',
		overflowY: 'auto'
	},
	actions: {
		height: 100,
		position: 'absolute',
		bottom: 0,
		width: '100%',
		boxSizing: 'border-box'
	},
	actionsTablette: {
		position: 'absolute',
		bottom: -50,
		width: '100%',
		boxSizing: 'border-box'
	},
	actionButton: {
		margin: '0 9px 0 9px',
		padding: '0 10px 0 10px'
	}
})


const ActionPanel = ({ Context, classes, open, level }) => {
	const isTablette = useMediaQuery('(min-width: 0px) and (max-width: 1279px)');
	return (
		<Context.Consumer>
			{({ title, content, actions, titleIcon, closePanel, isClickAwayEnabled }) => <Slide
				in={open}
				mountOnEnter
				direction="left"
				className={classes.slide}
				style={{ zIndex: zIndex + level }}
			>
				<Paper elevation={4}>
					<ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway(closePanel, open, isClickAwayEnabled)}>
						<Grid container direction="column" style={{ position: isTablette && 'relative' }}>
							<Grid item container>
								<Grid item container direction="row" justify="center" alignContent="flex-end"
									  className={classes.titleContainer}>
									<Grid item className={classes.closeIcon}>
										<IconButton onClick={closePanel}>
											<CloseIcon />
										</IconButton>
									</Grid>
									<Grid item container direction="row" justify="center" alignContent="center" className={classes.title} style={{ width: titleIcon ? 375 : 348 }}>
										{titleIcon}
										<Typography style={{ paddingLeft: titleIcon ? 20 : 0 }} component="h3" variant="h3">{toI18N(title)}</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item container className={isTablette ? classes.contentTablette : classes.content}>
								{content}
							</Grid>
							<Grid item container justify="center" alignContent="center" className={isTablette ? classes.actionsTablette : classes.actions}>
								<Grid item>
									<Button
										type="cancel"
										key="cancel"
										onClick={closePanel}
										className={classes.actionButton}
									>
										<FormattedMessage id="actions.cancel" />
									</Button>
								</Grid>
								{actions.map((action, index) => <Grid item key={index}>
									{React.cloneElement(action, { className: classes.actionButton })}
								</Grid>)}
							</Grid>
						</Grid>
					</ClickAwayListener>
				</Paper>
			</Slide>
			}
		</Context.Consumer>
	)
}

ActionPanel.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	level: PropTypes.number
}

export default compose(
	withStyles(styles)
)(ActionPanel)
