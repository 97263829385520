import { fetchFactory } from 'isotope-client'
import { baseUri } from '../../../service/tourPlaineApi'

const stockMaterielBaseUri = `${baseUri}/stockMateriel`

/**
 * getStockMateriel
 * @returns {Promise<*>}
 */
export const getStockMateriel = () => fetchFactory(`${stockMaterielBaseUri}`)

export const updateStockMateriel = (values) => fetchFactory(`${stockMaterielBaseUri}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})
