import React from 'react'
import PropTypes from 'prop-types'

const PlantIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			d="M479.816 44.962a14.995 14.995 0 0 0-8.924-8.924c-36.049-13.207-76.869-4.214-103.992 22.909-27.123 27.123-36.116 67.942-22.91 103.992a14.991 14.991 0 0 0 8.925 8.925c36.05 13.207 76.87 4.214 103.993-22.909 27.123-27.124 36.115-67.944 22.908-103.993z"
			style={{
				fill: "#6ac473",
			}}
		/>
		<path
			d="M476.338 39.515a15 15 0 0 0-5.447-3.478c-36.049-13.207-76.869-4.214-103.992 22.909-27.123 27.123-36.116 67.942-22.91 103.992a14.991 14.991 0 0 0 3.478 5.447l128.871-128.87z"
			style={{
				fill: "#a9e570",
			}}
		/>
		<path
			d="M421.859 93.994c-5.858-5.858-15.355-5.858-21.213 0l-59.559 59.559a98.26 98.26 0 0 0 2.902 9.386 14.991 14.991 0 0 0 8.925 8.925 98.553 98.553 0 0 0 9.386 2.903l59.559-59.559c5.858-5.858 5.858-15.356 0-21.214z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M421.859 93.994c-5.858-5.858-15.355-5.858-21.213 0l-59.559 59.559a98.26 98.26 0 0 0 2.902 9.386 14.991 14.991 0 0 0 3.478 5.447l74.392-74.392z"
			style={{
				fill: "#57a15e",
			}}
		/>
		<path
			d="M209.649 94.037a14.995 14.995 0 0 0-8.924 8.924c-13.207 36.049-4.214 76.869 22.909 103.992s67.942 36.116 103.992 22.91a14.991 14.991 0 0 0 8.925-8.925c13.207-36.05 4.214-76.87-22.909-103.993-27.124-27.123-67.944-36.115-103.993-22.908z"
			style={{
				fill: "#6ac473",
			}}
		/>
		<path
			d="M204.202 97.515a15 15 0 0 0-3.478 5.447c-13.207 36.049-4.214 76.869 22.909 103.992s67.942 36.116 103.992 22.91a14.991 14.991 0 0 0 5.447-3.478L204.202 97.515z"
			style={{
				fill: "#a9e570",
			}}
		/>
		<path
			d="M258.681 151.994c-5.858 5.858-5.858 15.355 0 21.213l59.559 59.559a98.26 98.26 0 0 0 9.386-2.902 14.991 14.991 0 0 0 8.925-8.925 98.553 98.553 0 0 0 2.903-9.386l-59.559-59.559c-5.858-5.858-15.356-5.858-21.214 0z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M258.681 151.994c-5.858 5.858-5.858 15.355 0 21.213l59.559 59.559a98.26 98.26 0 0 0 9.386-2.902 14.991 14.991 0 0 0 5.447-3.478l-74.392-74.392z"
			style={{
				fill: "#57a15e",
			}}
		/>
		<path
			d="M344.897 1.379c-8.284 0-15 6.716-15 15v330.054c0 8.284 6.716 15 15 15s15-6.716 15-15V16.379c0-8.284-6.716-15-15-15z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M344.853 265.77c-58.251 0-105.642 47.297-105.642 105.432s47.391 105.432 105.642 105.432 105.642-47.297 105.642-105.432S403.104 265.77 344.853 265.77z"
			style={{
				fill: "#a67955",
			}}
		/>
		<path
			d="M344.853 265.77c-58.251 0-105.642 47.297-105.642 105.432s47.391 105.432 105.642 105.432V265.77z"
			style={{
				fill: "#dea06d",
			}}
		/>
		<path
			d="M501.682 383.105c-6.633-6.622-15.45-10.484-24.837-10.484h-.094c-9.394 0-18.23 4.206-24.885 10.848l-46.671 47.152h-37.947c-40.075 0-79.092-14-110.689-38h93.964c19.218 0 35.216-15.534 35.663-34.416.226-9.534-3.319-18.359-9.982-25.168-6.683-6.829-15.644-10.417-25.231-10.417H107.559c-1.535 0-3.051.085-4.434.751l-79.769 38.297c-19.551 9.42-28.491 32.467-20.354 52.552l21.714 53.57c8.302 20.494 31.169 30.778 52.057 23.432l61.655-21.682 156.511 40.74c.84.219 1.705.341 2.573.341h118.6c7.753 0 15.252-3.242 20.572-8.866l65.112-68.822c6.582-6.626 10.204-15.521 10.204-24.857 0-9.39-3.665-18.332-10.318-24.971z"
			style={{
				fill: "#d19988",
			}}
		/>
		<path
			d="M248.715 322.621H107.559c-1.535 0-3.051.085-4.434.751l-79.769 38.297C3.805 371.088-5.136 394.135 3.002 414.22l21.715 53.57c8.301 20.494 31.169 30.778 52.057 23.432l61.654-21.682 110.288 28.708V322.621h-.001z"
			style={{
				fill: "#ffbd9e",
			}}
		/>
	</svg>
)


PlantIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

PlantIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default PlantIcon