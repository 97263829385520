import React, {useEffect, useMemo, useState} from 'react'
import {compose} from 'redux'
import {Grid, Typography, withStyles} from '@material-ui/core'
import {DATATABLE_SORT_ORDER, ETAPE_TYPE} from '../../../../../../utils/constants'
import PropTypes from 'prop-types'
import {FormattedMessage, useIntl} from 'react-intl'
import Title from '../../layout/Title'
import {SP_BLOCK_ACTION_TYPE} from '../tool/suiviPepiniere.constants'
import Button from '../../../../../../components/Button'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import AddCommentIcon from '@material-ui/icons/AddComment'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import NoResultPage from '../../../../../../components/NoResultPage'
import DataTableWithLoader from '../../../../../../components/layout/DataTableWithLoader'
import {useTourPlaineContext} from '../../../TourPlaineContext'
import {localCompare, toI18N} from '../../../../../../utils/utils'
import {DateFormat} from '../../../../../../utils/dateConstants'
import moment from 'moment'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import {injectReminderForm} from '../../../service/reminderFormInjector'
import {ACTIONS_KEY, CROP_DATATABLE_KEYS, EMPTY_PLACEHOLDER, TOUR_PLAINE_TABS} from '../../../tool/tourPlaine.constants'
import {injectActionsForm} from '../../../service/actionsFormInjector'
import {getInNurseryCrops} from '../service/suiviPepiniereApi'
import {getPreferences} from '../../../../../common/user/services/userSelectors'
import {connect} from 'react-redux'
import {inProgressCropsToApp} from '../../../tool/tourPlaine.mapper'
import classnames from 'classnames'
import {contentStyles} from '../tool/suiviPepiniere.styles'
import {formatDateToWeek} from '../../../tool/tourPlaine.utils'

/**
 * CropsContent for SuiviPepiniere
 * @returns {JSX.Element}
 */
const CropsContent = ({ classes, openReminderForm, getActions, idFerme }) => {
	const { formatMessage } = useIntl()
	const { currentWeek } = useTourPlaineContext()
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [sort, setSort] = useState({ key: CROP_DATATABLE_KEYS.CROP, order: DATATABLE_SORT_ORDER.ASC })
	const [selectedRows, setSelectedRows] = useState([])

	// Call Api
	const loadData = (shouldUnselect) => {
		if (shouldUnselect) {
			setSelectedRows([])
		}

		setIsLoading(true)
		getInNurseryCrops(idFerme, currentWeek)
			.then(response => setData(inProgressCropsToApp(response)))
			.finally(() => setIsLoading(false))
	}

	const headers = useMemo(() => [
		{
			key: CROP_DATATABLE_KEYS.CROP,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.CROP}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.CROP ? sort.order : undefined,
			checked: true,
			render: row => (
				<Typography className={classes.dataTableBoldText}>
					{row.crop.name}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.STEP,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.STEP}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.STEP ? sort.order : undefined,
			render: row => {
				const stepType = Object.keys(ETAPE_TYPE).find(key => key === row.step)
				return (
					<Typography className={classnames(classes.dataTableNormalText, { [classes.italicText]: ETAPE_TYPE[stepType] === ETAPE_TYPE.CROISSANCE }) }>
						{stepType && <FormattedMessage id={`enums.etape.${ETAPE_TYPE[stepType]}`} />}
					</Typography>
				)
			}
		},
		{
			key: CROP_DATATABLE_KEYS.SEEDLING_DATE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.SEEDLING_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.SEEDLING_DATE ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{toI18N(formatDateToWeek(row.seedlingDate))}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.PLANNED_BOARD,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.PLANNED_BOARD}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 60
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.PLANNED_BOARD ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{row.board.name}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.PLANNED_PREP_DATE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.PLANNED_PREP_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.PLANNED_PREP_DATE ? sort.order : undefined,
			render: row => {
				const endDate = moment(row.endHarvestDate, DateFormat.YYYY_MM_DD)
				const prepDate = moment(row.plannedPrepDate, DateFormat.YYYY_MM_DD)
				const showPrepWaring = prepDate.isSameOrBefore(endDate)
				return (
					<div className={classes.cell}>
						{showPrepWaring && <WarningRoundedIcon />}
						<Typography className={classnames(classes.dataTableNormalText, { [classes.datatableWarningText]: showPrepWaring })}>
							{toI18N(formatDateToWeek(row.plannedPrepDate))}
						</Typography>
					</div>
				)
			}
		},
		{
			key: CROP_DATATABLE_KEYS.PLANNED_IMPLANT_DATE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.PLANNED_IMPLANT_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.PLANNED_IMPLANT_DATE ? sort.order : undefined,
			render: row => {
				const prepDate = moment(row.plannedPrepDate, DateFormat.YYYY_MM_DD)
				const implantDate = moment(row.plannedImplantDate, DateFormat.YYYY_MM_DD)
				const showPlannedImplant = implantDate.isSameOrBefore(prepDate)
				return (
					<div className={classes.cell}>
						{showPlannedImplant && <WarningRoundedIcon />}
						<Typography className={classnames(classes.dataTableNormalText, { [classes.datatableWarningText]: row.showPlannedImplant })}>
							{toI18N(formatDateToWeek(row.plannedImplantDate))}
						</Typography>
					</div>
				)
			}
		},
		{
			key: CROP_DATATABLE_KEYS.CURRENT_HARVEST,
			name: <Typography className={classes.dataTableHeaderNext}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.CURRENT_HARVEST}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.CURRENT_HARVEST ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableBoardNext}>
					{row.currentOrNextCrop ? row.currentOrNextCrop.name : EMPTY_PLACEHOLDER}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.END_HARVEST,
			name: <Typography className={classes.dataTableHeaderNext}><FormattedMessage id={`tourPlaine.suiviPepiniere.crops.table.headers.${CROP_DATATABLE_KEYS.END_HARVEST}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 120
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.END ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableBoardNext}>
					{toI18N(formatDateToWeek(row.endHarvestDate))}
				</Typography>
			)
		}], [data, sort])

	const getDisplayData = useMemo(() => {
		const desc = sort.order === DATATABLE_SORT_ORDER.DESC

		if (sort.key === CROP_DATATABLE_KEYS.CROP) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.crop.name.localeCompare(element2.crop.name))
		} else if (sort.key === CROP_DATATABLE_KEYS.BOARD) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.board.name.localeCompare(element2.board.name))
		} else if (sort.key === CROP_DATATABLE_KEYS.CURRENT_HARVEST) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * (element1.currentOrNextCrop?.name || EMPTY_PLACEHOLDER).localeCompare((element2.currentOrNextCrop?.name || EMPTY_PLACEHOLDER)))
		}


		return data.sort(localCompare(sort.key, desc))
	}, [sort, data])

	const cropActions = getActions([
			ACTIONS_KEY.DETAIL_CROP,
			ACTIONS_KEY.MOVE_BOARD_STEP,
			ACTIONS_KEY.MOVE_SEEDLING_STEP,
			ACTIONS_KEY.ADD_COMMENT,
			ACTIONS_KEY.DELETE_CROP
		],
		selectedRows,
		TOUR_PLAINE_TABS.SUIVI_PEPINIERE,
		loadData)

	const nextCropActions = getActions([
			ACTIONS_KEY.DETAIL_CROP_CURRENT_OR_NEXT,
			ACTIONS_KEY.ADD_COMMENT_CURRENT_OR_NEXT
		],
		selectedRows,
		TOUR_PLAINE_TABS.SUIVI_PEPINIERE,
		loadData)

	/**
	 * UseEffect
	 */
	useEffect(() => {
		loadData()
	}, [])

	return (
		<div className={classes.root}>
			<Grid item container className={classes.header}>
				<Title actionBlock={SP_BLOCK_ACTION_TYPE.CROPS} />
				<Grid item container direction="row" justify="flex-start" alignItems="center">
					<Grid item>
						<Typography className={classes.descriptionText}>
							<FormattedMessage id="tourPlaine.suiviPepiniere.crops.description" />
						</Typography>
					</Grid>
					<Grid item container xs direction="row" justify="flex-end" alignItems="flex-end" className={classes.actionContainer}>
						<Button
							type="secondary"
							startIcon={<AddCommentIcon />}
							onClick={() => openReminderForm(`${formatMessage({ id: 'tourPlaine.tabBar.suiviPepiniere' })} / ${formatMessage({ id: 'tourPlaine.suiviPepiniere.actionTab.crops' })}`)}
						>
							<FormattedMessage id="tourPlaine.actions.addReminder" />
						</Button>
						<ButtonWithMenu
							type="primary"
							startIcon={<MoreHorizIcon />}
							items={[
								{
									label: <FormattedMessage id="tourPlaine.actions.cultureCrop.suiviPepiniere" />,
									isTitle: true
								},
								...cropActions,
								{
									isDivider: true
								},
								{
									label: <FormattedMessage id="tourPlaine.actions.nextCrop.suiviPepiniere" />,
									isTitle: true
								},
								...nextCropActions
							]}
							disabled={!selectedRows.length}
						>
							<FormattedMessage id="tourPlaine.actions.others" />
						</ButtonWithMenu>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container className={classes.dataTableContainer}>
				<DataTableWithLoader
					nom="SuiviPepiniere_CropsContent"
					tableProps={{
						stickyHeader: true,
						className: classes.dataTable
					}}
					data={getDisplayData}
					loading={isLoading}
					noResultFragment={<NoResultPage emptyStateMessageId="tourPlaine.suiviParBloc.crops.table.empty" />}
					totalElements={data.length}
					selectable
					displaySelectAll
					headers={headers}
					loaderStyle={{ width: '100%', paddingLeft: '0%', paddingRight: '0%' }}
					noPagination
					onFilterChange={key => {
						setSort(prev => {
							if (prev.key === key) {
								return ({
									...prev,
									order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
								})
							}
							return ({
								key,
								order: DATATABLE_SORT_ORDER.ASC
							})
						})
					}}
					onRowClick={row => setSelectedRows(prev => {
						if (prev.find(p => p.id === row.id)) {
							return prev.filter(p => p.id !== row.id)
						}

						return [
							...prev,
							row
						]
					})}
					selectRows={() => {
						selectedRows.length === data.length ? setSelectedRows([]) : setSelectedRows([...data])
					}}
					selectedRows={selectedRows}
				/>
			</Grid>
		</div>
	)
}

CropsContent.propTypes = {
	classes: PropTypes.object,
	openReminderForm: PropTypes.func,
	getActions: PropTypes.func,
	idFerme: PropTypes.string
}

const mapStateToProps = (state) => ({
	idFerme: getPreferences(state).FERME_ID
})

export default compose(
	injectReminderForm,
	injectActionsForm,
	connect(mapStateToProps),
	withStyles(contentStyles)
)
(CropsContent)