import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid/index'
import React from 'react'
import FertilisationIcon from '../../../../../components/icon/FertilisationIcon'
import FertilisationOutlinedIcon from '../../../../../components/icon/FertilisationOutlinedIcon'
import FinRecolteIcon from '../../../../../components/icon/FinRecolteIcon'
import ImplantationIcon from '../../../../../components/icon/ImplantationIcon'
import PrepaPlancheIcon from '../../../../../components/icon/PrepaPlancheIcon'
import RecolteIcon from '../../../../../components/icon/RecolteIcon'
import SemiDirectIcon from '../../../../../components/icon/SemiDirectIcon'
import SemiPlancheIcon from '../../../../../components/icon/SemiPlancheIcon'
import FinRecolteOutlinedIcon from '../../../../../components/icon/FinRecolteOutlinedIcon'
import ImplantationOutlinedIcon from '../../../../../components/icon/ImplantationOutlinedIcon'
import PrepaPlancheOutlinedIcon from '../../../../../components/icon/PrepaPlancheOutlinedIcon'
import RecolteOutlinedIcon from '../../../../../components/icon/RecolteOutlinedIcon'
import SemiDirectOutlinedIcon from '../../../../../components/icon/SemiDirectOutlinedIcon'
import SemiPlancheOutlinedIcon from '../../../../../components/icon/SemiPlancheOutlinedIcon'
import { colors, ETAPE_TYPE } from '../../../../../utils/constants'
import { ETAPE_COLOR } from '../row/AssolementRowItem'
import TypeEtape from '../../../../common/information/TypeEtape'

const HEIGHT_ICONS = 32
const WIDTH_ICONS = 38

const styles = () => getStyles({
	container: {
		margin: 20,
		marginBottom: 0,
		width: 440,
		height: 400
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 4,
		width: WIDTH_ICONS,
		height: HEIGHT_ICONS
	},
	itemRevised: {
		background: colors.iconRevised
	},
	empty: {
		background: 'white',
		border: '1px solid #804180'
	},
	row: {
		marginTop: 15
	},
	column: {},
	sectionTitle: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	icons: {
		marginRight: 20,
		marginLeft: 15
	},
	icon: {
		padding: 2
	},
	iconReviseLeft: {
		marginLeft: 65
	},
	iconReviseRight: {
		marginRight: 65
	},
	label: {
		fontSize: 14
	}
})

const iconStyle = {
	padding: 2
}

const etapes = [
	{
		icon: <SemiPlancheIcon style={iconStyle} />,
		iconRevise: <SemiPlancheOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.SEMI_EN_CONTENANT
	}, {
		icon: <PrepaPlancheIcon style={iconStyle} />,
		iconRevise: <PrepaPlancheOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.PREP_PLANCHE
	}, {
		icon: <ImplantationIcon style={iconStyle} />,
		iconRevise: <ImplantationOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.IMPLANTATION
	}, {
		icon: <SemiDirectIcon style={iconStyle} />,
		iconRevise: <SemiDirectOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.SEMI_DIRECT
	}, {
		icon: <RecolteIcon style={iconStyle} />,
		iconRevise: <RecolteOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.RECOLTE
	}, {
		icon: <FinRecolteIcon style={iconStyle} />,
		iconRevise: <FinRecolteOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.FIN_RECOLTE
	}, {
		icon: <FertilisationIcon style={iconStyle} />,
		iconRevise: <FertilisationOutlinedIcon style={iconStyle} color="black" />,
		type: ETAPE_TYPE.FERTILISATION
	}
]
const Legende = ({ isComparing = false, classes }) => {
	return (
		<Grid className={classes.container}>
			<Grid container direction="column" justify="center" alignItems="center" className={classes.column}>
				{isComparing && <Grid item container direction="row" justify="space-between" alignItems="center">
					<Grid className={classes.sectionTitle}><FormattedMessage id="legende.actuelle" /></Grid>
					<Grid className={classes.sectionTitle} style={{ marginRight: 20 }}><FormattedMessage id="legende.initiale" /></Grid>
				</Grid>}
				{etapes.map(etape => (
					<Grid item container direction="row" justify={isComparing ? 'space-between' : 'start'} alignItems="center" className={classes.row}>
						<Grid className={isComparing ? classes.iconReviseLeft : classes.icons}>
							<Grid
								className={classes.item}
								style={ETAPE_COLOR[etape.type]}
							>
								{etape.icon}
							</Grid>
						</Grid>
						<Grid className={classes.label}><TypeEtape typeEtape={etape.type} /></Grid>
						{isComparing && <Grid className={classes.iconReviseRight}>
							<Grid className={[classes.itemRevised, classes.item]}>{etape.iconRevise}</Grid>
						</Grid>}
					</Grid>
				))}
				<Grid item container direction="row" justify={isComparing ? 'space-between' : 'start'} alignItems="center" className={classes.row}>
					<Grid className={isComparing ? classes.iconReviseLeft : classes.icons}>
						<Grid
							className={[classes.item, classes.empty]}
						/>
					</Grid>
					<Grid className={classes.label}><FormattedMessage id="legende.semisConseilles" /></Grid>
					{isComparing && <Grid className={classes.iconReviseRight}>
						<Grid className={classes.item}/>
					</Grid>}
				</Grid>
			</Grid>
		</Grid>)
}

Legende.propType = {
	isRevise: PropTypes.bool
}

export default withStyles(styles)(Legende)
