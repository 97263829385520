import {Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {compose} from 'redux'
import {change, Field, formValueSelector, reduxForm} from 'redux-form'
import {PANEL_FORM, UPDATE_TASK_FIELD} from '../../tool/tourPlaine.constants'
import {formatPositiveInteger, normalizeDate, sortObjectByLabel} from '../../../../../utils/utils'
import {connect} from 'react-redux'
import DateFieldCustom from '../../../../../components/form/DateFieldCustom'
import {deleteTasks as deleteTasksApi, getAllUsers} from '../../../gestionTache/gestionTachesApi'
import {getAllPlanchesWithoutId} from '../../../gestionTache/services/api'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import {DeleteForever} from '@material-ui/icons'
import Button from '../../../../../components/Button'
import ContenuPopinSuppressionErrorTache from '../../../gestionTache/component/planification/ContenuPopinSuppressionErrorTache'
import {colors, STATUTS_TACHE, TYPE_DIALOG} from '../../../../../utils/constants'
import ContenuPopinSuppressionTache, {formName as deleteTaskFormName} from '../../../gestionTache/component/planification/ContenuPopinSuppressionTache'
import FormButton from '../../../../../components/form/FormButton'
import {DialogContext} from '../../../../../components/layout/dialog'
import {useSnackbar} from '../../../../../components/layout/snackbar/SnackbarContext'
import FormattedMessageWithBold from "../../../../../components/FormattedMessageWithBold";
import {getDureeCalculee} from "../../../gestionTache/form/CreerTache";
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat} from "../../../../../utils/date/model/date";
import Input from "../../../../../components/form/Input";
import AutocompleteMulti from "../../../../../components/form/AutocompleteMulti";
import IconButton from '../../../../../components/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import InfoIcon from '@material-ui/icons/Info'
import PickingIcon from "../../../../../components/icon/PickingIcon";
import {injectTypeTacheValueList} from "../../../../common/valueLists/valueListInjectors";
import {displaySensibilite} from "../../../gestionTache/utils/tacheUtils";

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25,
		rowGap: 25,
		maxHeight: 'calc(100vh - 220px)'
	},
	formBody: {
		width: '100%',
		marginTop: 10,
		overflowY: 'auto',
		gap: 15
	},
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	},
	detail: {
		paddingLeft: 20,
		gap: 10,
		'& p': {
			fontSize: '12px !important'
		},
		'& ul': {
			marginTop: 6,
			marginBottom: 6
		}
	},
	lockIcon: {
		color: `${colors.primary} !important`,
		border: '1px solid #804180',
		padding: 6,

		'&:hover': {
			color: `white !important`,
			backgroundColor: colors.primary
		}
	},
	hidden: {
		display: 'none',
		padding: 0,
		margin: 0
	}
})

const formattedMessageBase = 'tourPlaine.form.updateTask.fields'

const mandatoryFields = [UPDATE_TASK_FIELD.PLANNED_DATE, UPDATE_TASK_FIELD.PRIORITY, UPDATE_TASK_FIELD.RESSOURCES]

const validate = (values) => {
	const errors = {}
	mandatoryFields.forEach(field => {
		if (!values[field] || values[field].length === 0) {
			errors[field] = {id: `${formattedMessageBase}.errors.mandatory`}
		}
	})
	return errors
}

/**
 * UpdateTaskForm
 * @param handleSubmit
 * @param classes
 * @param inputLabel
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateTaskForm = ({
	classes,
	task,
	onSuccess,
	handleSubmit,
	dateValue,
	change,
	lockDateValue,
	lockRessourcesValue,
	typeTacheList
}) => {
	const {openDialog, closeDialog} = useContext(DialogContext)
	const [planches, setPlanches] = useState([])
	const {snackError, snackSuccess} = useSnackbar()
	const intl = useIntl()

	const deleteTaskSubmit = (id, onSuccess) => {
		deleteTasksApi([id], 'tache').then(() => {
			onSuccess(true)
			closeDialog()
			snackSuccess({id: 'gestionTaches.toolbar.actions.delete.success'})
		}).catch(() => {
			closeDialog()
			snackError({id: 'gestionTaches.toolbar.actions.delete.error'})
		})
	}
	const deleteTaskOnClick = () => {
		if ([task].some(task => task.statut === STATUTS_TACHE.TERMINEE)) {
			openDialog(
				<span className={classes.spanTitlePopin}>
					<WarningRoundedIcon className={classes.error}/>
						<FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.error.title"/>
				</span>,
				<ContenuPopinSuppressionErrorTache tachesEnErreur={[task].filter(tache => tache.statut === STATUTS_TACHE.TERMINEE)}/>,
				[],
				TYPE_DIALOG.ACTION,
				'actions.close'
			)
		} else {
			openDialog(
				{id: 'gestionTaches.toolbar.actions.delete.popin.validation.title'},
				<ContenuPopinSuppressionTache
					tachesRecurrentes={[task].filter(tache => tache.recurrence)}
					onSubmit={() => deleteTaskSubmit(task.id, onSuccess)}/>,
				[
					<FormButton
						type="primary"
						variant="text"
						withBorder={false}
						formName={deleteTaskFormName}
					>
						<FormattedMessage id="actions.delete"/>
					</FormButton>
				],
				TYPE_DIALOG.ACTION
			)
		}
	}

	/*
	Permet d'obtenir le sous-titre du détail de la tâche
	 */
	const getSubtitle = (task) => {
		if (task.culture != null) {
			return task.culture.nom
		} else if (task.assolement != null) {
			return task.assolement.culture.nom
		} else {
			return task.typeEntity
		}
	}

	useEffect(() => {
		getAllPlanchesWithoutId().then(setPlanches)
	}, [])

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid item container direction="row" justify="center" alignItems="center">
				<Button
					type="primary"
					variant="text"
					withBorder={false}
					keepCase
					onClick={deleteTaskOnClick}
				>
					<Grid item container direction="row" justify="center" alignItems="center" style={{gap: 10}}>
						<DeleteForever color="primary"/>
						<Typography color="primary">
							<FormattedMessage id="tourPlaine.form.updateTask.deleteAction"/>
						</Typography>
					</Grid>
				</Button>
			</Grid>
			<Grid item container direction="column" className={classes.formBody}>
				<Grid container item direction="column" className={classes.detail}>
					<Grid container item direction="row" wrap="nowrap" style={{gap: 10}}>
						<PickingIcon style={{minWidth: 25, minHeight: 25}}/>
						<Typography variant="h4">{typeTacheList.find(type => type.code === (task.type ?? task.tache)).label} - {getSubtitle(task)}</Typography>
					</Grid>
					<Grid>
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.surface`} values={{surface: task.lieu}}/>
						</Typography>
					</Grid>
					<Grid container item>
						<Grid container item direction="column" xs={6}>
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.ressource`} values={{nbPersonnes: task.nbPersonnes}}/>
							</Typography>
							{task.flagDivisible && <Typography><FormattedMessageWithBold id={`${formattedMessageBase}.detail.flagDivisible`}/></Typography>}
						</Grid>
						<Grid container item xs={6}>
							{planches.length > 0 && task.assolement && <Typography><FormattedMessageWithBold id={`${formattedMessageBase}.detail.duree`} values={{duree: getDureeCalculee(task, planches, task.assolement.planche.id)}}/></Typography>}
						</Grid>
					</Grid>
					{task.anticipable && <Grid>
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.anticipation`} values={{nbJours: task.joursAnticipable}}/>
						</Typography>
					</Grid>}
					<Grid>
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.dateInitiale`} values={{date: LocalDate.fromAPI(task.initialDate).format(DateFormat.SHORT_DATE)}}/>
						</Typography>
					</Grid>
					{task.categorieTechnique && <Grid>
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.categorie`} values={{categorie: `${task.categorieTechnique.code} - ${task.categorieTechnique.nom}`}}/>
						</Typography>
					</Grid>}
					{task.materiels && task.materiels.length > 0 && <Grid container item direction="column">
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.materiel`}/>
						</Typography>
						<ul>
							{task.materiels.map(materiel => <li><Typography>{materiel}</Typography></li>)}
						</ul>
					</Grid>}
					{task.sensibilites && task.sensibilites.length > 0 && <Grid container item direction="column">
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.sensibilite`}/>
						</Typography>
						<ul>
							{task.sensibilites.map(sensibilite => <li><Typography>{displaySensibilite(sensibilite, intl)}</Typography></li>)}
						</ul>
					</Grid>}
					{task.competences && task.competences.length > 0 && <Grid container item direction="column">
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.competences`}/>
						</Typography>
						<ul>
							{task.competences.map(competence => <li><Typography>{competence}</Typography></li>)}
						</ul>
					</Grid>}
					{task.description && <Grid>
						<Typography>
							<FormattedMessageWithBold id={`${formattedMessageBase}.detail.description`} values={{description: task.description}}/>
						</Typography>
					</Grid>}
				</Grid>
				<Grid container direction="column">
					<Grid container direction="row">
						<Grid item xs>
							<Field
								name={UPDATE_TASK_FIELD.PLANNED_DATE}
								type="text"
								component={DateFieldCustom}
								placeholder={intl.formatMessage({id: `${formattedMessageBase}.${UPDATE_TASK_FIELD.PLANNED_DATE}`})}
								format={normalizeDate}
								hideFootInfo
								onChange={() => change(UPDATE_TASK_FIELD.RESSOURCES, [])}
								textFieldStyle={{width: '100%'}}
								style={{paddingLeft: 10, paddingRight: 10}}
								sliderCalendar
							/>
						</Grid>
						{task.tacheGenerique && !task.idTacheGenerique && <Grid item xs style={{paddingLeft: 10, paddingTop: 10, maxWidth: 60}}>
							<IconButton onClick={() => change(UPDATE_TASK_FIELD.LOCK_DATE, !lockDateValue)} className={classes.lockIcon}>
								{lockDateValue ? <LockIcon/> : <LockOpenIcon/>}
							</IconButton>
						</Grid>}
						<Field
							name={UPDATE_TASK_FIELD.LOCK_DATE}
							component={Input}
							containerClassName={classes.hidden}
						/>
					</Grid>
					{lockDateValue && <Typography style={{paddingLeft: 10, fontSize: 10, fontStyle: 'italic', fontWeight: 700}}>
						<Grid container alignItems="center" style={{gap: 8}}>
							<InfoIcon color='primary'/>
							<FormattedMessage id={`${formattedMessageBase}.${UPDATE_TASK_FIELD.LOCK_DATE}`}/>
						</Grid>
					</Typography>}
				</Grid>
				<Grid container direction="column">
					<Grid container direction="row">
						<Grid item xs>
							<Field
								label={intl.formatMessage({id: `${formattedMessageBase}.${UPDATE_TASK_FIELD.RESSOURCES}`})}
								name={UPDATE_TASK_FIELD.RESSOURCES}
								component={AutocompleteMulti}
								getOptions={() => getAllUsers(dateValue).then(users => sortObjectByLabel(users.map(ressource => ({
									label: ressource.alias + ' ' + intl.formatMessage({ id: `enums.roles.${ressource?.user?.roles[0]?.code?.toUpperCase()}` }),
									code: ressource.user.id,
									value: ressource.user.id
								}))))} // TODO filtre par compétences utilisateur quand ce sera ajouté
								forceGetOptions
								formName={PANEL_FORM.UPDATE_TASK}
							/>
						</Grid>
						{task.tacheGenerique && !task.idTacheGenerique && <Grid item xs style={{paddingLeft: 10, paddingTop: 10, maxWidth: 60}}>
							<IconButton onClick={() => change(UPDATE_TASK_FIELD.LOCK_RESSOURCES, !lockRessourcesValue)} className={classes.lockIcon}>
								{lockRessourcesValue ? <LockIcon/> : <LockOpenIcon/>}
							</IconButton>
						</Grid>}
						<Field
							name={UPDATE_TASK_FIELD.LOCK_RESSOURCES}
							component={Input}
							containerClassName={classes.hidden}
						/>
					</Grid>
					{lockRessourcesValue && <Typography style={{paddingLeft: 10, fontSize: 10, fontStyle: 'italic', fontWeight: 700}}>
						<Grid container alignItems="center" wrap="nowrap" style={{gap: 8}}>
							<InfoIcon color='primary'/>
							<FormattedMessage id={`${formattedMessageBase}.${UPDATE_TASK_FIELD.LOCK_RESSOURCES}`}/>
						</Grid>
					</Typography>}
				</Grid>
				<Field
					name={UPDATE_TASK_FIELD.PRIORITY}
					component={Input}
					type="number"
					label={<FormattedMessage id={`${formattedMessageBase}.${UPDATE_TASK_FIELD.PRIORITY}`}/>}
					format={formatPositiveInteger}
					inputProps={{ min: 0 }}
				/>
				<Field
					name={UPDATE_TASK_FIELD.INSTRUCTIONS}
					component={Input}
					multiline
					label={<FormattedMessage id={`${formattedMessageBase}.${UPDATE_TASK_FIELD.INSTRUCTIONS}`}/>}
				/>
			</Grid>
		</form>
	)

}


UpdateTaskForm.propType = {
	task: PropTypes.object,
	classes: PropTypes.object,
	onSuccess: PropTypes.func
}

const selector = formValueSelector(PANEL_FORM.UPDATE_TASK)
const mapStateToProps = (state, {task}) => ({
	dateValue: LocalDate.fromJS(selector(state, UPDATE_TASK_FIELD.PLANNED_DATE)).format(DateFormat.SHORT_DATE_WITH_DASH),
	lockDateValue: selector(state, UPDATE_TASK_FIELD.LOCK_DATE),
	lockRessourcesValue: selector(state, UPDATE_TASK_FIELD.LOCK_RESSOURCES),
	initialValues: {
		[UPDATE_TASK_FIELD.PLANNED_DATE]: new Date(task.plannedDate),
		[UPDATE_TASK_FIELD.PRIORITY]: task.priorite.toString(), // toString car autrement la valeur n'est pas détectée par le validate (PRM293)
		[UPDATE_TASK_FIELD.INSTRUCTIONS]: task.instructions,
		[UPDATE_TASK_FIELD.RESSOURCES]: task.affectationLightBeanList.map(ressource => ({
			label: ressource.user.preferences.ALIAS,
			code: ressource.user.id,
			value: ressource.user.id
		})),
		[UPDATE_TASK_FIELD.LOCK_DATE]: task.lockDate,
		[UPDATE_TASK_FIELD.LOCK_RESSOURCES]: task.lockRessources
	}
})

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	injectTypeTacheValueList,
	reduxForm({
		form: PANEL_FORM.UPDATE_TASK,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(UpdateTaskForm)
