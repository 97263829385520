import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const CultureIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M268.891 6.812c-5.391-9.082-20.391-9.082-25.781 0-1.853 3.135-34.497 58.92-51.687 138.638 25.767 37.084 47.585 76.595 64.578 118.588 17-41.984 38.811-81.502 64.574-118.601C303.388 65.726 270.744 9.946 268.891 6.812zM239.778 309.709c-23.695-69.804-60.367-135.112-109.051-191.913l-43.33-50.552C77.035 55.1 57.174 65.638 61.586 81.116l38.145 133.55c5.339 18.688 9.36 37.674 12.697 56.772 15.004 22.837 33.124 43.579 54.569 60.723 35.364 28.282 47.705 36.478 74.004 65.645V317c0-2.516-.41-4.909-1.223-7.291zM424.604 67.244l-43.33 50.552c-48.684 56.801-85.36 122.111-109.054 191.915A22.955 22.955 0 00271 317v80.808c26.191-29.049 38.403-37.16 74.004-65.647 21.445-17.144 39.565-37.886 54.569-60.725 3.336-19.098 7.357-38.082 12.697-56.77l38.145-133.55c4.43-15.538-15.54-25.985-25.811-13.872z"
				fill={color}
			/>
			<path
				d="M221.437 419.916c-22.875-25.768-41.33-38.851-73.191-64.332-39.111-31.289-70.639-72.407-89.242-118.916l-30.088-75.234C22.955 146.332 0 150.414 0 167v120c0 124.072 101.928 225 226 225h15v-40.541a77.662 77.662 0 00-19.563-51.543zM483.084 161.434l-30.088 75.234c-18.604 46.509-50.131 87.627-89.242 118.916-31.361 25.081-49.77 38.123-72.48 63.537A79.679 79.679 0 00271 472.263V512h15c124.072 0 226-100.928 226-225V167c0-16.511-22.928-20.737-28.916-5.566z"
				fill={color}
			/>
		</svg>
	)
}

CultureIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

CultureIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default CultureIcon
