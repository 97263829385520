import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ETAT_TACHE_LIBELLE, STATUTS_TACHE, ETAT_TACHE_UPPERCASE, colors } from '../../../../../utils/constants'
import classNames from 'classnames'
import Check from '@material-ui/icons/Check'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import Alert from '@material-ui/icons/NotificationImportant'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 5
	},
	enRetardIcon: {
		width: 24,
		height: 24,
		color: colors.error
	},
	iconEnRetardContainer: {
		width: 18,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	iconContainer: {
		width: 18,
		height: 18,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: ({ statut }) => theme.palette.tache[statut]
	},
	icon: {
		fill: theme.palette.common.white,
		width: 15
	},
	texte: {
		width: 65
	},
	bigText: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	statutContainerFromSuivi: {
		alignItems: 'center',
		justifyContent: 'start',
		margin: 'auto',
		width: '8em',
		gap: '8px'
	}
}))


const TacheStatutIcon = ({ statut, isEnRetard, isLegende = false }) => {
	const classes = useStyles({ statut })

	const getLibelleFromStatut = (statut) => {
		switch (statut) {
			case ETAT_TACHE_UPPERCASE.A_PLANIFIER:
				return ETAT_TACHE_LIBELLE.A_PLANIFIER
			case ETAT_TACHE_UPPERCASE.A_REALISER:
				return ETAT_TACHE_LIBELLE.A_REALISER
			case ETAT_TACHE_UPPERCASE.EN_COURS:
				return ETAT_TACHE_LIBELLE.EN_COURS
			case ETAT_TACHE_UPPERCASE.EN_PAUSE:
				return ETAT_TACHE_LIBELLE.EN_PAUSE
			case ETAT_TACHE_UPPERCASE.EN_ATTENTE:
				return ETAT_TACHE_LIBELLE.EN_ATTENTE
			case ETAT_TACHE_UPPERCASE.TERMINEE:
				return ETAT_TACHE_LIBELLE.TERMINEE
			case ETAT_TACHE_UPPERCASE.SUPPRIMEE:
				return ETAT_TACHE_LIBELLE.SUPPRIMEE
			case isEnRetard && isLegende && ETAT_TACHE_UPPERCASE.EN_RETARD:
				return ETAT_TACHE_LIBELLE.EN_RETARD
			default:
				return ""
		}
	}

	const getIconFromStatut = (statut) => {
		if (isEnRetard && isLegende && ETAT_TACHE_UPPERCASE.EN_RETARD) {
			return <Alert className={classNames(classes.root, classes.enRetardIcon)}/>
		}
		switch (statut) {
			case STATUTS_TACHE.EN_COURS:
				return <PlayArrow className={classes.icon}/>
			case ETAT_TACHE_UPPERCASE.EN_PAUSE:
				return <Pause className={classes.icon}/>
			case STATUTS_TACHE.TERMINEE:
				return <Check className={classes.icon}/>
			case STATUTS_TACHE.A_REALISER:
			case STATUTS_TACHE.A_PLANIFIER:
				return isEnRetard && <Alert className={classNames(classes.root, classes.enRetardIcon)}/>
			default:
				break
		}
	}
	return (
		<Grid container direction="row" alignItems="center" className={classes.statutContainerFromSuivi}>
			<Grid className={classNames(classes.root,
				(isEnRetard && ((statut === STATUTS_TACHE.A_REALISER || statut === STATUTS_TACHE.A_PLANIFIER) || isLegende))
					? classes.iconEnRetardContainer
					: classes.iconContainer)}>
				{getIconFromStatut(statut)}
			</Grid>
			<Grid className={isLegende ? classes.bigText : classes.texte}>
				<FormattedMessage id="suiviAvancement.suiviRessources.statut"
								  values={{
									  statut: getLibelleFromStatut(statut)
								  }}
				/>
			</Grid>
		</Grid>
	)
}
TacheStatutIcon.propTypes = {
	statut: PropTypes.oneOf(Object.keys(STATUTS_TACHE)).isRequired,
	isEnRetard: PropTypes.bool.isRequired,
	isLegende: PropTypes.bool
}

export default TacheStatutIcon
