import React from 'react'
import PropTypes from 'prop-types'

const SemiDirectIcon = ({ height, width, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M497 197.875H362.885c-28.771 0-39.215 23.319-39.215 38.96 0 37.098-30.394 67.305-67.753 67.305-37.991 0-67.75-29.363-67.75-66.81-.011-15.845-10.464-39.455-39.215-39.455H15c-8.284 0-15 6.818-15 15.102v172.219c0 24.778 20.159 45.677 44.938 45.677h422.124c24.779 0 44.938-20.899 44.938-45.677V212.977c0-8.284-6.716-15.102-15-15.102z"
				fill="#6ac473"
			/>
			<path
				d="M262.284 82.554c-4.047-1.904-8.574-1.904-12.621 0C214.709 99.011 194 127.3 194 158.228v36.66c0 30.928 20.762 59.217 55.716 75.674a14.774 14.774 0 006.337 1.429c2.183 0 4.234-.477 6.258-1.429C297.264 254.105 318 225.816 318 194.888v-36.66c0-30.928-20.762-59.217-55.716-75.674z"
				fill="#a67955"
			/>
			<path
				d="M255.974 81.126a14.79 14.79 0 00-6.311 1.428C214.709 99.011 194 127.3 194 158.228v36.66c0 30.928 20.762 59.217 55.716 75.674a14.774 14.774 0 006.337 1.429l-.079-190.865z"
				fill="#dea06d"
			/>
			<path
				d="M0 359.874v25.323c0 24.778 20.159 45.677 44.938 45.677h422.124c24.779 0 44.938-20.899 44.938-45.677v-25.323H0z"
				fill="#a67955"
			/>
			<path
				d="M255.916 304.14c-37.991 0-67.75-29.364-67.75-66.81-.011-15.845-10.464-39.455-39.215-39.455H15c-8.284 0-15 6.818-15 15.102v172.219c0 24.778 20.159 45.677 44.938 45.677H256l-.084-126.733z"
				fill="#a9e570"
			/>
			<path
				d="M255.961 359.874H0v25.323c0 24.778 20.159 45.677 44.938 45.677H256l-.039-71z"
				fill="#dea06d"
			/>
		</svg>
	)
}

SemiDirectIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

SemiDirectIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default SemiDirectIcon
