import React from 'react'
import PropTypes from 'prop-types'

const PickingIcon = ({height, width, style}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
			...style
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#4f66d0"
			d="m41.991 487.256-22.129-16.09c0-6.996.594-13.98 1.775-20.876l13.259-77.407-.35-99.373 47.851 95.272-10.43 102.384c-.001 0-30.224 12.759-29.976 16.09z"
		/>
		<path
			fill="#80d261"
			d="M376.847 424.948a7.885 7.885 0 0 1-6.317-12.596l41.75-57.294a7.885 7.885 0 1 1 12.647 9.421l-41.75 57.294a7.874 7.874 0 0 1-6.33 3.175z"
		/>
		<g fill="#fef056">
			<path d="M81.984 512H39.152c-11.144 0-19.944-9.441-19.176-20.554l-.116-20.28h52.104l16.559 29.7c2.786 4.984-.82 11.134-6.539 11.134z"/>
			<path d="M81.984 512H39.152c-11.144 0-19.944-9.441-19.176-20.554l-.116-20.28h52.104l16.559 29.7c2.786 4.984-.82 11.134-6.539 11.134z"/>
			<path d="M81.984 512H39.152c-11.144 0-19.944-9.441-19.176-20.554l-.116-20.28h52.104l16.559 29.7c2.786 4.984-.82 11.134-6.539 11.134z"/>
		</g>
		<path
			fill="#99e6fc"
			d="M107.854 127.832v.084l-.105 44.314-38.378 14.498-34.826-14.498v-44.398c0-21.069 17.705-37.796 38.742-36.597 19.396 1.104 34.567 17.168 34.567 36.597z"
		/>
		<path
			fill="#62dbfb"
			d="M93.26 200.43a9.012 9.012 0 0 1-.311-.396l-36.563-49.76a7.887 7.887 0 0 1 12.709-9.339l36.397 49.536c3.101 3.588-6.402 16.915-12.232 9.959z"
		/>
		<path
			fill="#6c7fd8"
			d="m124.065 471.166-.21-105.47-16.254-135.948h.001l-27.19-57.519H34.545v57.519h-.001l-1.283 21.931a93.717 93.717 0 0 0 6.129 39.225l30.29 77.384a32.04 32.04 0 0 1 2.205 11.656l.075 91.222 23.056 11.331z"
		/>
		<path
			fill="#4f66d0"
			d="m140.623 500.866-16.559-29.7-.21-105.47L107.6 229.748h.001l.147-57.519H80.412l-.147 57.519h-.001l16.254 135.948.21 105.47 16.559 29.7c2.786 4.983-.82 11.134-6.539 11.134h27.337c5.718 0 9.325-6.15 6.538-11.134z"
		/>
		<path
			fill="#fef48b"
			d="M134.084 512H91.253c-11.144 0-19.944-9.441-19.176-20.554l-.116-20.28h24.767l43.895 29.7c2.787 4.984-.82 11.134-6.539 11.134z"
		/>
		<path
			fill="#fef056"
			d="m140.623 500.866-16.559-29.7H96.728l16.559 29.7c2.786 4.983-.82 11.134-6.539 11.134h27.337c5.718 0 9.325-6.15 6.538-11.134z"
		/>
		<path
			fill="#fbc56d"
			d="m341.841 388.742-3.196 18.882-9.967 7.769 7.318 7.885-13.037 77.042c-1.135 6.739-6.981 11.68-13.814 11.68h-29.616l-7.286-12.29-8.506 12.29h-27.44l-8.663-6.76-7.128 6.76H190.89c-6.844 0-12.679-4.941-13.825-11.68l-13.026-77.042 8.737-7.885-11.386-7.769-3.196-18.882c-1.072-6.319 3.795-12.069 10.198-12.069h42.264l9.094 9.767 6.697-9.767h47.142l8.211 6.213 7.58-6.213h42.264c6.402-.001 11.259 5.75 10.197 12.069z"
		/>
		<path
			fill="#e9b665"
			d="m341.841 388.742-3.196 18.882-9.967 7.769 7.318 7.885-13.037 77.042c-1.135 6.739-6.981 11.68-13.814 11.68H281.8c6.844 0 12.689-4.941 13.825-11.68l18.882-111.578c1.062-6.319-3.806-12.069-10.208-12.069h27.345c6.402-.001 11.259 5.75 10.197 12.069z"
		/>
		<path
			fill="#bbec6c"
			d="m346.016 196.522-20.104 14.975a47.342 47.342 0 0 1-18.691-14.96l-41.971-56.344c-6.132-8.232-4.43-19.877 3.802-26.009 8.232-6.132 19.877-4.43 26.009 3.802l41.971 56.344a47.35 47.35 0 0 1 8.984 22.192z"
		/>
		<path
			fill="#80d261"
			d="M376.86 266.787a7.871 7.871 0 0 1-6.33-3.175l-38.316-50.631a7.885 7.885 0 1 1 12.647-9.421l38.316 50.631a7.885 7.885 0 0 1-6.317 12.596z"
		/>
		<path
			fill="#a2e62e"
			d="M347.775 210.072c.675 5.192-4.596 9.119-9.378 6.988l-12.484-5.564a23.307 23.307 0 0 1-1.157-.536l-1.871-14.435a47.341 47.341 0 0 0-8.989-22.194l-41.969-56.341a18.168 18.168 0 0 0-3.333-3.438v-.012a18.511 18.511 0 0 1 11.544-4.035 18.58 18.58 0 0 1 14.929 7.485l41.969 56.341a47.325 47.325 0 0 1 8.978 22.194z"
		/>
		<path
			fill="#bbec6c"
			d="m346.016 314.287-20.104 14.975a47.342 47.342 0 0 1-18.691-14.96l-41.971-56.344c-6.132-8.232-4.43-19.877 3.802-26.009 8.232-6.132 19.877-4.43 26.009 3.802l41.971 56.344a47.341 47.341 0 0 1 8.984 22.192z"
		/>
		<path
			fill="#80d261"
			d="M376.86 384.552a7.874 7.874 0 0 1-6.33-3.175l-38.316-49.568a7.885 7.885 0 1 1 12.647-9.421l38.316 49.568a7.885 7.885 0 0 1-6.317 12.596z"
		/>
		<path
			fill="#a2e62e"
			d="M347.753 327.662c.685 5.27-4.666 9.255-9.519 7.09l-12.32-5.496a23.307 23.307 0 0 1-1.157-.536l-1.871-14.435a47.341 47.341 0 0 0-8.989-22.194l-41.969-56.341a18.168 18.168 0 0 0-3.333-3.438v-.012a18.511 18.511 0 0 1 11.544-4.035 18.58 18.58 0 0 1 14.929 7.485l41.969 56.341a47.325 47.325 0 0 1 8.978 22.194z"
		/>
		<path
			fill="#bbec6c"
			d="m383.708 156.656-2.512 19.362 17.833-7.95a36.086 36.086 0 0 0 14.244-11.4l21.62-27.037c4.673-6.274 3.376-15.148-2.898-19.821-6.273-4.673-15.148-3.376-19.821 2.898l-21.62 27.037a36.064 36.064 0 0 0-6.846 16.911zM407.691 354.683l-1.873 14.437c4.708 3.356 21.977.538 21.977.538a47.342 47.342 0 0 0 18.691-14.96l41.971-56.344c6.132-8.232 4.43-19.877-3.802-26.009-8.232-6.132-19.877-4.43-26.009 3.802l-41.971 56.344a47.341 47.341 0 0 0-8.984 22.192z"
		/>
		<path
			fill="#a2e62e"
			d="m488.46 298.358-41.969 56.341a47.362 47.362 0 0 1-18.693 14.96l-13.303 5.928c-4.425 1.972-9.302-1.66-8.68-6.465a47.256 47.256 0 0 0 17.536-14.424l41.969-56.341c6.024-8.074 4.499-19.45-3.343-25.653a18.511 18.511 0 0 1 11.586-4.048c3.858 0 7.759 1.199 11.092 3.69 8.231 6.132 9.934 17.78 3.805 26.012z"
		/>
		<path
			fill="#bbec6c"
			d="m209.804 217.064-6.23 11.1c-2.764 4.924-9.853 4.922-12.614-.003l-6.224-11.103a47.331 47.331 0 0 1-6.046-23.164l.019-70.258c.003-10.265 8.327-18.585 18.592-18.582 10.265.003 18.585 8.327 18.582 18.592l-.019 70.258a47.349 47.349 0 0 1-6.06 23.16z"
		/>
		<path
			fill="#bbec6c"
			d="m209.804 217.064-6.23 11.1c-2.764 4.924-9.853 4.922-12.614-.003l-6.224-11.103a47.331 47.331 0 0 1-6.046-23.164l.019-70.258c.003-10.265 8.327-18.585 18.592-18.582 10.265.003 18.585 8.327 18.582 18.592l-.019 70.258a47.349 47.349 0 0 1-6.06 23.16z"
		/>
		<path
			fill="#bbec6c"
			d="m209.804 217.064-6.23 11.1c-2.764 4.924-9.853 4.922-12.614-.003l-6.224-11.103a47.331 47.331 0 0 1-6.046-23.164l.019-70.258c.003-10.265 8.327-18.585 18.592-18.582 10.265.003 18.585 8.327 18.582 18.592l-.019 70.258a47.349 47.349 0 0 1-6.06 23.16z"
		/>
		<path
			fill="#99e6fc"
			d="M206.721 175.667c0 4.247-1.619 8.137-4.29 11.06-2.681 2.923-22.696 7.622-26.912 8.011l-50.485 3.964a22.623 22.623 0 0 1-19.376-8.011l-36.565-49.76c-7.286-9.914-5.299-23.823 4.458-31.319.21-.158.421-.315.631-.463a22.465 22.465 0 0 1 13.1-4.205c7.811 0 15.486 4.027 19.691 11.439l20.995 36.986a7.403 7.403 0 0 0 6.119 3.743l46.122 2.04c8.715.441 26.512 7.789 26.512 16.515z"
		/>
		<path
			fill="#62dbfb"
			d="m134.087 157.111-20.501-.179a12.353 12.353 0 0 1-10.377-5.656l-22.52-34.894a22.402 22.402 0 0 0-6.508-7.233 22.465 22.465 0 0 1 13.1-4.205c7.811 0 15.486 4.027 19.691 11.439l20.995 36.986a7.402 7.402 0 0 0 6.12 3.742zM215.881 176.414c0 4.247-1.619 8.137-4.29 11.06a16.439 16.439 0 0 1-10.629 5.267l-25.442 1.998c8.032-1.146 14.078-8.022 14.078-16.222v-6.308c0-6.518-3.816-11.018-9.389-13.058l20.123.894c8.715.441 15.549 7.643 15.549 16.369z"
		/>
		<path
			fill="#80d261"
			d="M376.847 307.184a7.885 7.885 0 0 1-6.317-12.596l41.75-55.316a7.885 7.885 0 1 1 12.647 9.421l-41.75 55.316a7.874 7.874 0 0 1-6.33 3.175z"
		/>
		<path
			fill="#80d261"
			d="M404.552 56.973a7.887 7.887 0 0 0-11.094-1.127l-8.719 7.11V38.605c0-4.355-3.53-7.885-7.885-7.885s-7.885 3.53-7.885 7.885v24.351l-8.719-7.11a7.886 7.886 0 0 0-9.967 12.221l18.686 15.238v420.81c0 4.355 3.53 7.885 7.885 7.885s7.885-3.53 7.885-7.885V83.305l18.686-15.238a7.887 7.887 0 0 0 1.127-11.094z"
		/>
		<path
			fill="#b98080"
			d="m322.758 417.47-14.098 5.808h-22.667L279.528 512h-15.791c0-.189.011-.378.021-.578l6.424-88.144h-40.34l6.434 88.144c.011.2.021.389.021.578h-15.791l-6.476-88.722h-49.991l-2.649-15.654a7.69 7.69 0 0 1 1.314-.116h50.18l-2.208-30.268c-.011-.2-.021-.379-.021-.568h15.791l2.25 30.836h42.642l2.25-30.836h15.791c0 .189-.011.379-.021.568l-2.208 30.268h24.181z"
		/>
		<path
			fill="#ae6c6c"
			d="m338.645 407.623-2.649 15.654h-27.335l2.67-15.77h25.999a7.9 7.9 0 0 1 1.315.116z"
		/>
		<path
			fill="#ffb09e"
			d="M104.205 17.289v28.139c0 16.159-13.1 29.269-29.269 29.269-16.159 0-29.259-13.11-29.259-29.269v-10.85z"
		/>
		<path
			fill="#ffa78f"
			d="M76.848 34.579v10.85c0 11.218-6.316 20.962-15.587 25.874a29.108 29.108 0 0 0 13.675 3.395c16.17 0 29.269-13.11 29.269-29.269v-28.14z"
		/>
		<path
			fill="#b98080"
			d="M125.978 17.289c0 9.549-35.098 17.289-44.647 17.289H45.677v-5.309C45.677 13.1 58.777 0 74.936 0h33.753c9.549 0 17.289 7.741 17.289 17.289z"
		/>
		<path
			fill="#ae6c6c"
			d="M108.689 0H81.332c9.549 0 17.289 7.741 17.289 17.289s-7.741 17.29-17.289 17.29h27.357c9.549 0 17.289-7.741 17.289-17.289S118.238 0 108.689 0z"
		/>
		<path
			fill="#bbec6c"
			d="m407.691 236.918-1.872 14.43 21.976.546a47.342 47.342 0 0 0 18.691-14.96l41.971-56.344c6.132-8.232 4.43-19.877-3.802-26.009-8.232-6.132-19.877-4.43-26.009 3.802l-41.971 56.344a47.342 47.342 0 0 0-8.984 22.191z"
		/>
		<path
			fill="#a2e62e"
			d="m488.46 180.588-41.969 56.352a47.434 47.434 0 0 1-18.693 14.95l-13.295 5.931c-4.428 1.975-9.31-1.659-8.688-6.467a47.326 47.326 0 0 0 17.536-14.414l41.969-56.352c6.024-8.074 4.499-19.439-3.343-25.653a18.582 18.582 0 0 1 11.586-4.037c3.869 0 7.759 1.199 11.092 3.68 8.231 6.14 9.934 17.778 3.805 26.01z"
		/>
	</svg>
)


PickingIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

PickingIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default PickingIcon