import { Box, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const styles = () => getStyles({
	container: {
		width: '55rem',
		textAlign: 'center',
		margin: '1.75rem auto 6.5rem'
	},
	head: {
		fontWeight: 'bold',
		letterSpacing: 1,
		marginBottom: "1.5rem"
	},
	content: {}
})

const Content = ({ classes }) => (
	<Box className={classes.container}>
		<Typography variant="h3" className={classes.head}>
			<p><FormattedMessage id="accueil.content.welcome"/></p>
			<p><FormattedMessage id="accueil.content.title"/></p>
		</Typography>
		<Typography variant="body1" className={classes.content}>
			<FormattedMessage id="accueil.content.content"/>
		</Typography>
	</Box>
)

export default withStyles(styles)(Content)