import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import FormButton from '../../../../../../components/form/FormButton'
import FormattedMessageWithBold from '../../../../../../components/FormattedMessageWithBold'
import WateringCanIcon from '../../../../../../components/icon/WateringCanIcon'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { TYPE_DIALOG } from '../../../../../../utils/constants'
import { DateFormat } from '../../../../../../utils/dateConstants'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import IrrigationAddForm from '../component/Irrigation/IrrigationAddForm'
import { IRRIGATION_ADD_FIELDS, IRRIGATION_ADD_FORM_NAME } from '../tool/suiviParBloc.constants'
import { addIrrigations, deleteIrrigation } from './suiviParBlocApi'
import { addIrrigationsToApi } from './suiviParBlocApiMapper'

/**
 * Styles
 */
const styles = () => getStyles({
	informationText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	}
})

/**
 * Inject the Weather Update Form
 */
export const injectIrrigationAddForm = (WrappedComponent) => {

	const OpenIrrigationAddFormInjector = ({ classes, getNotifications, ...props }) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)
		const { openDialog, closeDialog } = useContext(DialogContext)
		const { bioAggressors } = useTourPlaineContext()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const addFormAction = [
			<FormButton type="primary" formName={IRRIGATION_ADD_FORM_NAME}>
				<FormattedMessage id="tourPlaine.suiviParBloc.irrigation.panel.submit"/>
			</FormButton>
		]

		const openIrrigationAddForm = useCallback((block, weekDay, dataDay, onSave) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.suiviParBloc.irrigation.panel.title.add' },
				<IrrigationAddForm
					block={block}
					weekDay={weekDay}
					dataDay={dataDay}
					onSubmit={values => {
						const data = addIrrigationsToApi(block, weekDay, values[IRRIGATION_ADD_FIELDS.SLOTS])

						addIrrigations(data)
							.then(() => {
								onSave()
								getNotifications()
								refreshSolverInfosForCurrentCampagne()
								closePanel()
								snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.generic"/>)
							})
							.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.generic"/>))

					}}/>,
				addFormAction,
				<WateringCanIcon height={24} width={24}/>
			)
		}, [bioAggressors])

		const openDeleteDialog = (dayPeriod, onSave) => openDialog({ id: 'tourPlaine.suiviParBloc.irrigation.dialog.title' },
			<Grid style={{ margin: '1.5em' }}>
				<Grid>
					<Typography className={classes.informationText}>
						<FormattedMessage id="tourPlaine.suiviParBloc.irrigation.dialog.description"/>
					</Typography>
				</Grid>
				<br/>
				<Grid direction="row">
					<Typography className={classes.informationText}>
						<li>
							<FormattedMessageWithBold
								id="tourPlaine.suiviParBloc.irrigation.dialog.period"
								values={{
									dayLabel: moment(dayPeriod.date, DateFormat.YYYY_MM_DD).locale('fr').format(DateFormat.dddd_Do_MMMM),
									start: dayPeriod.start,
									end: dayPeriod.end
								}}
							/>

						</li>
					</Typography>
				</Grid>
			</Grid>,
			[
				<Button
					type="primary"
					variant="text"
					withBorder={false}
					onClick={() => {
						deleteIrrigation([dayPeriod.id])
							.then(() => {
								onSave()
								snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.deleteIrrigation"/>)
								getNotifications()
								refreshSolverInfosForCurrentCampagne()
							})
							.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.deleteIrrigation"/>))
							.finally(() => closeDialog())
					}}
				>
					<FormattedMessage id="actions.confirm"/>
				</Button>
			],
			TYPE_DIALOG.ACTION,
			'actions.close',
			<WateringCanIcon height={24} width={24}/>)

		return <WrappedComponent
			{...props}
			openIrrigationAddForm={openIrrigationAddForm}
			openDeleteDialog={openDeleteDialog}
		/>
	}

	OpenIrrigationAddFormInjector.propTypes = {
		classes: PropTypes.object
	}

	const actions = {
		getNotifications
	}

	return compose(
		connect(null, actions),
		withStyles(styles)
	)(OpenIrrigationAddFormInjector)
}
