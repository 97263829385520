import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import classnames from 'classnames'
import {getStyles, Snackbar} from 'isotope-client'
import {colors} from '../../utils/constants'
import UserMenu from './menu/UserMenu'
import PermasoftMenu from './menu/PermasoftMenu'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Link from '../Link'
import {isBrowser} from 'react-device-detect'
import NotificationZone from './menu/NotificationZone'
import {Grid} from '@material-ui/core'
import {getNotifications} from '../../modules/common/notification/notificationActions'
import {useSolverStatusContext} from '../../modules/fo/besoinsProduction/SolverStatusContextProvider'
import AlerteBar from './alerte/AlerteBar'
import {useModalState} from '../../utils/customHooks'

const menuWidth = 279
const appBarSmHeight = 56
const appBarHeight = 64

const style = theme => getStyles({
	appFrame: {
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%'
	},
	appBar: {
		flex: 1,
		backgroundColor: `${colors.appBarBackground} !important`,
		color: colors.appBarColor
	},
	contentFrame: {
		position: 'absolute',
		display: 'flex',
		overflowX: 'hidden',
		overflowY: 'auto',
		bottom: 0,
		left: 0,
		right: 0,
		[theme.breakpoints.down('sm')]: {
			top: appBarSmHeight
		},
		[theme.breakpoints.up('sm')]: {
			top: appBarHeight
		}
	},
	drawerPaper: {
		width: menuWidth,
		zIndex: 2
	},
	mainContent: {
		position: 'relative',
		flexGrow: 1,
		marginLeft: 0,
		backgroundColor: colors.etapeContrastBackground,
		width: '100%'
	},
	boContent: {
		backgroundColor: '#f7f5f8',
		marginLeft: 279,
		overflowX: 'auto'
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
		color: 'white !important',
		height: 48
	},
	logo: {
		paddingRight: 24
	},
	clickable: {
		cursor: 'pointer'
	}
})


export const Page = ({classes, children, location, menu, getNotifications}) => {
	const isBo = menu.includes('bo')
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()
	const [open, _, closeModal, toggleModal] = useModalState(isBo)

	React.useEffect(() => {
		getNotifications()
		refreshSolverInfosForCurrentCampagne()
		const interval = setInterval(() => {
			getNotifications()
			refreshSolverInfosForCurrentCampagne()
		}, 60000)
		return () => clearInterval(interval)
	}, [])

	return (
		<React.Fragment>
			<div className={classes.appFrame}>
				<AppBar elevation={1} className={classes.appBar}>
					<Toolbar>
						<Grid container justify="space-between" alignItems="center">
							{isBrowser ?
								<>
									<IconButton className={classes.menuButton} color="inherit" onClick={toggleModal}>
										<MenuIcon/>
									</IconButton>
									<Link to="/accueil">
										<img src="/img/permasoft.png" className={classnames(classes.logo, classes.clickable)} alt="Permasoft" height={55} width="auto"/>
									</Link>
								</> : <img src="/img/permasoft.png" className={classes.logo} alt="Permasoft" height={55} width="auto"/>
							}
							{isBrowser && <NotificationZone/>}
							<UserMenu isBrowser={isBrowser}/>
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
			<div className={classes.contentFrame}>
				{isBrowser &&
					<Drawer
						variant={isBo ? 'permanent' : 'temporary'}
						open={isBo ? true : open}
						onClose={closeModal}
						classes={{paper: classes.drawerPaper}}
						ModalProps={{keepMounted: true}}
					>
						<PermasoftMenu code={menu} location={location} closeModal={closeModal}/>
					</Drawer>
				}
				<main className={classnames(classes.mainContent, {[classes.boContent]: isBo})}>
					{isBrowser && <AlerteBar />}
					{children}
				</main>
				<Snackbar/>
			</div>
		</React.Fragment>
	)
}

Page.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.object,
	location: PropTypes.object,
	menu: PropTypes.string.isRequired
}

Page.defaultProps = {
	menu: 'fo'
}

const actions = {
	getNotifications
}

export default compose(
	connect(undefined, actions),
	withStyles(style, {name: 'page'})
)(Page)
