import { withStyles } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import FormButton from '../../../../components/form/FormButton'
import { DialogContext } from '../../../../components/layout/dialog'
import { ActionPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { CHOIX_DEPLACEMENT, colors, ETAPE_TYPE, STATUTS_TACHE, TYPE_DIALOG } from '../../../../utils/constants'
import ContenuPopinSuppressionErrorTache from '../../gestionTache/component/planification/ContenuPopinSuppressionErrorTache'
import { addInstructions, delayTache, deleteTasks as deleteTasksApi } from '../../gestionTache/gestionTachesApi'
import { openCultureDetail } from '../../planning/services/actions/detail/openCultureDetailInjector'
import { injectModifierEtapeRecolte } from '../../planning/services/actions/form/allongerReduireEtapeRecolte'
import { injectCommentairePlanche, injectCommentaireSuivi } from '../../planning/services/actions/form/commentInjectors'
import { injectDeleteCulture } from '../../planning/services/actions/form/deleteCultureInjector'
import { delayEtape } from '../../planning/services/planningApi'
import DeleteTaskDialog, { fieldIdList, formName as deleteTaskFormName } from '../component/dialog/DeleteTaskDialog'
import ConfirmHarvestForm from '../component/panel/ConfirmHarvestForm'
import InstructionForm from '../component/panel/InstructionForm'
import MoveForm from '../component/panel/MoveForm'
import SpecifyGenericTaskForm from '../component/panel/SpecifyGenericTaskForm'
import { confirmTachesRecolte } from '../component/suiviParBloc/service/suiviParBlocApi'
import { ACTIONS_KEY, ADD_INSTRUCTION_FIELD, CONFIRM_HARVEST_FIELD, MOVE_TASK_FIELD, PANEL_FORM } from '../tool/tourPlaine.constants'
import { moreActions } from '../tool/tourPlaine.utils'
import { getModelesTache, specifyGenericTask as specifyGenericTaskApi } from './tourPlaineApi'

/**
 * Styles
 */
const styles = () => getStyles({
	spanTitlePopin: {
		display: 'flex',
		alignItems: 'center'
	},
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	}
})

/**
 * Inject the actions Form
 */
export const injectActionsForm = (WrappedComponent) => {

	const ActionsFormInjector = ({
		classes,
		openPanelCommentaireSuivi,
		openCultureDetail,
		closeCultureDetail,
		openCultureDelete,
		openCommentairePlanche,
		modifierEtapeRecolte,
		...props
	}) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)
		const { openDialog, closeDialog } = useContext(DialogContext)

		/**
		 * Submit / Api call
		 */
		const moveStepSubmit = (values, actionKey, onSuccess) => {
			const idList = values.idList

			switch (actionKey) {
				case ACTIONS_KEY.MOVE_BOARD_STEP:
				case ACTIONS_KEY.MOVE_SEEDLING_STEP:
				case ACTIONS_KEY.MOVE_TO_HARVEST_STEP:
				case ACTIONS_KEY.MOVE_TO_IMPLANT_STEP:
					// RG_PEPINIERE_10
					delayEtape({
						assolementsId: idList,
						typeEtape: values[MOVE_TASK_FIELD.STEP],
						date: (values[MOVE_TASK_FIELD.DATE_CHOICE] === CHOIX_DEPLACEMENT.DEPLACER) ? values[MOVE_TASK_FIELD.DATE] : null,
						nbSemaines: (values[MOVE_TASK_FIELD.DATE_CHOICE] === CHOIX_DEPLACEMENT.DECALER) ? values[MOVE_TASK_FIELD.NB_WEEK] : null
					})
						.then(() => {
							closePanel()
							onSuccess(true)
							snackSuccess({ id: 'listAssolement.snackbar.ok.deplacerEtapes' })
						})
						.catch((e) => {
							snackError({ id: 'listAssolement.snackbar.error.deplacerEtapes' })
						})
					break
				case ACTIONS_KEY.MOVE_TASK:
					// RG_PEPINIERE_18
					delayTache(idList,
						(values[MOVE_TASK_FIELD.DATE_CHOICE] === CHOIX_DEPLACEMENT.DEPLACER) ? values[MOVE_TASK_FIELD.DATE] : null,
						(values[MOVE_TASK_FIELD.DATE_CHOICE] === CHOIX_DEPLACEMENT.DECALER) ? values[MOVE_TASK_FIELD.NB_WEEK] : null
					)
						.then(() => {
							closePanel()
							onSuccess(true)
							snackSuccess({ id: 'listAssolement.snackbar.ok.deplacerEtapes' })
						})
						.catch((e) => {
							snackError({ id: 'listAssolement.snackbar.error.deplacerEtapes' })
						})
					break
				default: // do nothing
					break
			}
		}

		const deleteTaskSubmit = (values, onSuccess) => {
			deleteTasksApi(values[fieldIdList], "tache").then(() => {
				onSuccess(true)
				closeDialog()
				snackSuccess({ id: 'gestionTaches.toolbar.actions.delete.success' })
			}).catch(() => {
				closeDialog()
				snackError({ id: 'gestionTaches.toolbar.actions.delete.error' })
			})
		}

		const confirmHarvestSubmit = (values, onSuccess) => {
			// on va récupérer les ids des tâches de récolte qu'on veut confirmer
			const harvestTaskToValidate = values[CONFIRM_HARVEST_FIELD.TASKS].map(selectedTasks => {
				// si checked est true alors on prend tous les ids dans tasklist sauf ceux des tâches déjà confirmées
				if (selectedTasks.checked) {
					return selectedTasks.tasksList.flat()
						.filter(task => task.confirmed !== true)
						.map(task => task.id)
				} else { // si checked est false alors on prend juste l'id de tasklist[0] (qui est la tâche sélectionnée initialement)
					return selectedTasks.tasksList[0].id
				}
			}).flat()

			confirmTachesRecolte(harvestTaskToValidate)
				.then(() => {
					onSuccess(true)
					closePanel()
					snackSuccess({ id: 'snackbar.update.tourPlaine.generic' })
				})
				.catch((e) => {
					snackError({ id: 'snackbar.error.tourPlaine.generic' })
				})
		}

		const specifyGenericTask = (idTache, values, onSuccess) => {
			specifyGenericTaskApi(idTache, values.modele)
				.then(() => {
					onSuccess(true)
					closePanel()
					snackSuccess({ id: 'snackbar.update.tourPlaine.generic' })
				})
				.catch(() => {
					snackError({ id: 'snackbar.error.tourPlaine.generic' })
				})
		}

		const formActions = (formName) => [
			<FormButton type="primary" formName={formName}>
				<FormattedMessage id="actions.validate" />
			</FormButton>
		]

		/**
		 * Utils
		 */
		const getMoveStep = (actionKey) => {
			switch (actionKey) {
				case ACTIONS_KEY.MOVE_BOARD_STEP:
					return ETAPE_TYPE.PREP_PLANCHE
				case ACTIONS_KEY.MOVE_TO_HARVEST_STEP:
					return ETAPE_TYPE.RECOLTE
				case ACTIONS_KEY.MOVE_TO_IMPLANT_STEP:
					return ETAPE_TYPE.IMPLANTATION
				case ACTIONS_KEY.MOVE_SEEDLING_STEP:
					return ETAPE_TYPE.SEMI_EN_CONTENANT
				case ACTIONS_KEY.MOVE_TASK:
					return // not used
				default: // do nothing
					break
			}
		}

		const onActionClick = (actionKey, items, tab, onSuccess) => {
			closePanel()
			switch (actionKey) {
				case ACTIONS_KEY.DETAIL_CROP:
					// RG_PEPINIERE_9
					openCultureDetail(items[0].assolement)
					break
				case ACTIONS_KEY.DETAIL_CROP_CURRENT_OR_NEXT:
					// RG_PEPINIERE_9
					openCultureDetail(items[0].assolementEnCoursOuSuivant)
					break
				case ACTIONS_KEY.ADD_COMMENT:
					// RG_PEPINIERE_11
					openPanelCommentaireSuivi(items.map(item => item.assolement), true, undefined, onSuccess)
					break
				case ACTIONS_KEY.ADD_COMMENT_CURRENT_OR_NEXT:
					// RG_PEPINIERE_11
					openPanelCommentaireSuivi(items.filter(item => item.assolementEnCoursOuSuivant).map(item => item.assolementEnCoursOuSuivant), true, undefined, onSuccess)
					break
				case ACTIONS_KEY.ADD_INSTRUCTION:
					// RG_PEPINIERE_20
					openPanel(
						{ id: 'tourPlaine.form.instruction.title' },
						<InstructionForm
							items={items}
							onSubmit={values => {
								addInstructions(values[ADD_INSTRUCTION_FIELD.IDS_LIST], values[ADD_INSTRUCTION_FIELD.INSTRUCTION])
									.then(() => {
										closePanel()
										onSuccess()
										snackSuccess({ id: 'snackbar.update.tourPlaine.generic' })
									})
									.catch((e) => {
										snackError({ id: 'snackbar.error.tourPlaine.generic' })
									})
							}}
						/>,
						formActions(PANEL_FORM.ADD_INSTRUCTION),
						undefined
					)
					break
				case ACTIONS_KEY.ADD_PROD_TRACKING:
					// RG_BLOC_16
					openPanelCommentaireSuivi(items.map(item => item.assolement), true, undefined, onSuccess)
					break
				case ACTIONS_KEY.ADJUST_DURATION:
					// RG_BLOC_17
					modifierEtapeRecolte(items.map(item => item.assolement))
					break
				case ACTIONS_KEY.CONFIRM_HARVEST:
					// RG_BLOC_30
					openPanel(
						{ id: 'tourPlaine.form.confirmHarvest.title' },
						<ConfirmHarvestForm
							items={items}
							onSubmit={values => confirmHarvestSubmit(values, onSuccess)}
						/>,
						formActions(PANEL_FORM.CONFIRM_HARVEST),
						undefined
					)
					break
				case ACTIONS_KEY.DELETE_CROP:
					// RG_PEPINIERE_12
					openCultureDelete(items.map(item => item.assolement), onSuccess)
					break
				case ACTIONS_KEY.DELETE_CROP_CURRENT_OR_NEXT:
					// RG_PEPINIERE_12
					openCultureDelete(items.filter(item => item.assolementEnCoursOuSuivant).map(item => item.assolementEnCoursOuSuivant), onSuccess)
					break
				case ACTIONS_KEY.DELETE_TASK:
					// RG_PEPINIERE_21
					if (items.some(task => task.statut === STATUTS_TACHE.TERMINEE)) {
						openDialog(
							<span className={classes.spanTitlePopin}>
								<WarningRoundedIcon className={classes.error} />
									<FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.error.title" />
							</span>,
							<ContenuPopinSuppressionErrorTache tachesEnErreur={items.filter(tache => tache.statut === STATUTS_TACHE.TERMINEE)} />,
							[],
							TYPE_DIALOG.ACTION,
							'actions.close'
						)
					} else {
						openDialog(
							{ id: 'gestionTaches.toolbar.actions.delete.popin.validation.title' },
							<DeleteTaskDialog
								taches={items}
								onSubmit={values => deleteTaskSubmit(values, onSuccess)} />,
							[
								<FormButton
									type="primary"
									variant="text"
									withBorder={false}
									formName={deleteTaskFormName}
								>
									<FormattedMessage id="actions.delete" />
								</FormButton>
							],
							TYPE_DIALOG.ACTION
						)
					}
					break
				case ACTIONS_KEY.MOVE_BOARD_STEP:
				case ACTIONS_KEY.MOVE_TO_HARVEST_STEP:
				case ACTIONS_KEY.MOVE_TO_IMPLANT_STEP:
				case ACTIONS_KEY.MOVE_SEEDLING_STEP:
				case ACTIONS_KEY.MOVE_TASK:
					// RG_PEPINIERE_10
					// RG_PEPINIERE_18
					// RG_BLOC_15
					const title = actionKey === ACTIONS_KEY.MOVE_TASK ? { id: 'tourPlaine.form.moveTask.title.task' } : { id: 'tourPlaine.form.moveTask.title.step' }
					openPanel(
						title,
						<MoveForm
							items={items}
							type={actionKey}
							step={getMoveStep(actionKey)}
							onSubmit={values => moveStepSubmit(values, actionKey, onSuccess)}
						/>,
						formActions(PANEL_FORM.MOVE_TASK),
						undefined
					)
					break
				case ACTIONS_KEY.SPECIFY_GENERIC_TASK:
					// RG_TACHE_GENERIQUE_1
					const tache = items[0]
					getModelesTache(tache.id).then(modeles => {
						openPanel(
							{ id: 'tourPlaine.form.specifyGenericTask.title' },
							<SpecifyGenericTaskForm
								tache={tache}
								modeles={modeles}
								onSubmit={values => specifyGenericTask(tache.id, values, onSuccess)}
							/>,
							formActions(PANEL_FORM.SPECIFY_GENERIC_TASK),
							undefined
						)
					})
					break
				default: // do nothing
					break
			}
		}

		const getActions = useCallback((actionKeys, items, tab, onSuccess) => moreActions(actionKeys, items).map(action => ({
			...action,
			onClick: () => onActionClick(action.key, action.items || items, tab, onSuccess)
		})), [onActionClick])

		return <WrappedComponent
			{...props}
			getActions={getActions}
		/>
	}

	ActionsFormInjector.propTypes = {
		classes: PropTypes.object,
		openCultureDetail: PropTypes.func,
		closeCultureDetail: PropTypes.func,
		openPanelCommentaireSuivi: PropTypes.func,
		openCommentairePlanche: PropTypes.func,
		openCultureDelete: PropTypes.func,
		modifierEtapeRecolte: PropTypes.func
	}

	return compose(
		openCultureDetail,
		injectCommentaireSuivi,
		injectCommentairePlanche,
		injectModifierEtapeRecolte,
		injectDeleteCulture,
		withStyles(styles)
	)(ActionsFormInjector)
}
