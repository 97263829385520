import {Grid, Typography, withStyles} from '@material-ui/core'
import React from 'react'
import {dataTableValueSelectors, getStyles} from 'isotope-client'
import {compose} from 'redux'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {formatDuration} from '../../../../../utils/utils'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import {ReactComponent as Gauge} from '../../../../../icons/gauge.svg'
import {PLANIFICATION_TABLE_NAME} from '../../utils/constants'
import {connect} from 'react-redux'
import classNames from 'classnames'
import CheckboxLabel from "../../../../../components/CheckboxLabel";
import {injectTypeContratValueList} from "../../../../common/valueLists/valueListInjectors";
import Button from "../../../../../components/Button";
import {checkRessources} from "../../gestionTachesApi";
import moment from "moment";
import PeopleIcon from '@material-ui/icons/People'
import {useSnackbar} from "../../../../../components/layout/snackbar/SnackbarContext";

const styles = (theme) => getStyles({
	container: {
		width: 'auto',
		marginBottom: 8,
		height: 48,
		fontFamily: 'Lato',
		minWidth: 1150,
		maxWidth: '100%'
	},
	tableScrollable: {
		width: 'calc(100% - 20px)'
	},
	icon: {
		fill: theme.palette.primary.main,
		alignSelf: 'center',
		width: 24,
		height: 24
	},
	ressources: {
		width: '19.7%',
		backgroundColor: theme.palette.primary.light,
		height: '100%'
	},
	content: {
		width: '9.6%',
		backgroundColor: theme.palette.primary.light,
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},
	numberHeaderCol1And2: {
		fontSize: 20,
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	numberHeader: {
		marginLeft: 10,
		fontSize: 16,
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	simpleText: {
		marginLeft: 10,
		fontSize: 13
	},
	gridCol1And2Content: {
		width: 'auto',
		alignSelf: 'center'
	},
	marginRessource: {
		width: 'auto',
		marginLeft: 20
	},
	filter: {
		paddingBottom: 15
	},
	title: {
		fontStyle: 'italic',
		fontSize: 14
	}
})

const HeaderRessources = ({ classes, reference, nbElement, typeContratValueList }) => {
	const {
		headerData,
		setSelectedContrats,
		selectedContrats,
		refreshHeaderData,
		semaineSelection,
		semaineCourante,
		isResultat
	} = useGestionTacheContext()
	const { snackError, snackSuccess } = useSnackbar()
	const [tableScrollable, setTableScrollable] = React.useState(false)
	const typesContrat = [...typeContratValueList]

	React.useEffect(() => {
		setTimeout(() => {
			setTableScrollable(reference && reference.current && reference.current.offsetHeight < reference.current.scrollHeight)
		}, 0)
	}, [nbElement])

	React.useEffect(() => {
		refreshHeaderData()
	}, [selectedContrats])

	if (!headerData) {
		return <></>
	}
	const handleCheckboxClick = (contrat) => {
		setSelectedContrats((prevSelectedContrats) => {
			const newSelectedContrats = new Set(prevSelectedContrats);
			if (newSelectedContrats.has(contrat)) {
				newSelectedContrats.delete(contrat);
			} else {
				newSelectedContrats.add(contrat);
			}
			return newSelectedContrats;
		});
	};

	return <Grid>
		<Typography variant={"caption"} className={classes.title} >
			<FormattedMessage id="gestionTaches.ecranRessources.header.title" />
		</Typography>
		<Grid container direction="row" justify="space-between" alignItems={"center"}>
			<Grid item container xs={9} alignItems="center" className={classes.filter}>
				<Typography>
					<FormattedMessage id="gestionTaches.ecranRessources.filter.title" />
				</Typography>
				{typesContrat.map((contrat) => (
					<CheckboxLabel
						onClick={() => handleCheckboxClick(contrat.label)}
						label={contrat.label}
					/>
				))}
			</Grid>
			<Grid item container xs={3} justify={"flex-end"}>
			{(semaineCourante && !isResultat) &&
				<Button
					type="primary"
					startIcon={<PeopleIcon />}
					onClick={() => {
						checkRessources(moment(semaineSelection).format('YYYY-MM-DD'))
							.then(res => {
								if (!!res) {
									snackSuccess({ id: 'gestionTaches.ecranRessources.snackbar.success.verifRessources' })
									refreshHeaderData()
								} else {
									snackSuccess({ id: 'gestionTaches.ecranRessources.snackbar.success.verifRessourcesNoChange' })
								}
							})
							.catch(() => {
								snackError({ id: 'gestionTaches.ecranRessources.snackbar.errors.verifRessources' })
							})
					}}
				>
					<FormattedMessage id="gestionTaches.toolbar.buttons.verifRessources" />
				</Button>
			}
			</Grid>
		</Grid>
		<Grid container alignItems="center" className={classNames(classes.container, { [classes.tableScrollable]: tableScrollable })} justify='space-between'>
			<Grid container item className={classes.ressources}>

				<Grid container item className={classes.marginRessource}>
					<SupervisorAccountIcon className={classes.icon} />
				</Grid>

				<Grid container item direction="column" className={classes.gridCol1And2Content}>
					<Grid item className={classes.simpleText}>
						<label className={classes.numberHeaderCol1And2}>{headerData.totalRessources}</label>
						<br />
						<FormattedMessage id="gestionTaches.ecranRessources.header.ressources" />
					</Grid>
				</Grid>

			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />

				<Grid container direction="column" className={classes.gridCol1And2Content}>
					<Grid item className={classes.simpleText}>
						<label className={classes.numberHeaderCol1And2}>{formatDuration(headerData.totalWeek)}</label>
						<br />
						<FormattedMessage id="gestionTaches.ecranRessources.header.duration" />
					</Grid>
				</Grid>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalLundi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalMardi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />

				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalMercredi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalJeudi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalVendredi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalSamedi)}</Typography>
			</Grid>

			<Grid container item direction="row" className={classes.content}>
				<Gauge className={classes.icon} />
				<Typography className={classes.numberHeader}>{formatDuration(headerData.totalDimanche)}</Typography>
			</Grid>
		</Grid>
	</Grid>
}

HeaderRessources.propType = {
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	nbElement: dataTableValueSelectors.getDataTableTotalElements(state)(PLANIFICATION_TABLE_NAME)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	injectTypeContratValueList
)(HeaderRessources)