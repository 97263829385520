import { Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const styles = () => getStyles({
	toolbar: {
		paddingLeft: '1.5rem',
		margin: '1.38rem 0'
	}
})

const ToolBar = ({ classes }) => (
	<Typography variant="h2" className={classes.toolbar}>
		<FormattedMessage id="accueil.title"/>
	</Typography>
)

export default withStyles(styles)(ToolBar)