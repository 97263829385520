import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MenuActions from '../../../../../components/MenuActions'
import { checkExportStateUntilFound } from '../../../../../utils/exportUtils'
import { CHARGES_TRAVAIL, EXPORT_ITEMS } from '../../utils/constant'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import ExportIcon from '../../../../../components/icon/ExportIcon'
import { exportTableauProduction } from '../../services/cultureApi'
import { ASSOLEMENT_ETAT, TYPE_DIALOG } from '../../../../../utils/constants'
import { exportChargesTravail, exportPlanning } from '../../services/planningApi'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'
import FormButton from '../../../../../components/form/FormButton'
import { DialogContext } from '../../../../../components/layout/dialog'
import { FormattedMessage } from 'react-intl'
import PopinExport, { EXPORT_CHARGES_TRAVAIL_FORM } from './PopinExport'

const styles = () => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12
	}
})


const Export = ({ isRevise, campagne, classes }) => {
	const { snackError, snackSuccess } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const [event, setEvent] = React.useState(null)

	const getItemToExport = EXPORT_ITEMS.filter(item => ((isRevise && item.name !== ASSOLEMENT_ETAT.BROUILLON) || (!isRevise && !item.isRevise)))

	const onSubmit = (values) => {
		exportChargesTravail(values.campagne, CHARGES_TRAVAIL)
			.then(checkExportStateUntilFound(snackSuccess))
			.then(closeDialog())
			.catch(() => snackError({ id: 'snackbar.error.exportProduction' }))
	}

	const onClick = (event, category, item) => {
		let promise
		if (item.name === CHARGES_TRAVAIL) {
			openDialog(
				<FormattedMessage id="planning.export.popin.title" initialValues={{ campagne: campagne.id }} />,
				<PopinExport onSubmit={onSubmit} initialValues={{ campagne: campagne.id }} />,
				[
					<FormButton type="primary" formName={EXPORT_CHARGES_TRAVAIL_FORM}>
						<FormattedMessage id="planning.export.popin.button" />
					</FormButton>
				],
				TYPE_DIALOG.ACTION
			)
		} else {
			if (item.name === ASSOLEMENT_ETAT.BROUILLON || item.name === ASSOLEMENT_ETAT.REVISE || item.name === ASSOLEMENT_ETAT.VALIDE) {
				promise = exportPlanning(campagne.idFerme, campagne.id, item.name)
			} else {
				promise = exportTableauProduction(campagne.idFerme, campagne.id, item.name)
			}
			promise.then(checkExportStateUntilFound(snackSuccess))
				.catch(() => snackError({ id: 'snackbar.error.exportProduction' }))
		}
	}

	let className = classes.icon
	return (
		<>
			<IconButton onClick={event => setEvent({ ...event })} className={className}><ExportIcon/></IconButton>
			<MenuActions
				items={{ export: getItemToExport }}
				onClick={onClick}
				formattedMessageId='planning'
				placement="bottom"
				setEvent={setEvent}
				event={event}
			/>
		</>
	)
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

Export.propType = {
	snackSuccess: PropTypes.func,
	classes: PropTypes.object,
	campagne: PropTypes.object.isRequired
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(Export)
