import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Input from '../../../../../../components/form/Input'
import { SUIVI_ITK_DETAIL, SUIVI_ETAPE_FIELDS } from '../../../utils/constant'

const styles = () => getStyles({
	formInput: {
		paddingTop: 15,
		'& > div': {
			padding: 0
		}
	}
})

const formattedMessageMenuBase = 'planning.etapesAssolement'

const SuiviEtapeForm = ({ comment, change, classes, handleSubmit }) => {

	const intl = useIntl()
	const changeForm = (field, value) => change(field, value)
	React.useEffect(() => {
		if (comment) {
			changeForm(SUIVI_ETAPE_FIELDS.COMMENTAIRE, comment.commentaire)
		}
	}, [comment])

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.container}>
				<Grid item xs={12} className={classes.formInput}>
					<Field
						name={SUIVI_ETAPE_FIELDS.COMMENTAIRE}
						component={Input}
						multiline
						label={intl.formatMessage({ id: `${formattedMessageMenuBase}.commentaire` })}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

SuiviEtapeForm.propTypes = {
	comment: PropTypes.object,
	change: PropTypes.func,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

export default compose(
	reduxForm({
		form: SUIVI_ITK_DETAIL,
		destroyOnUnmount: true
	}),
	withStyles(styles)
)(SuiviEtapeForm)
