import { fetchFactory, urlUtils } from 'isotope-client'
import { baseUri } from '../../../service/tourPlaineApi'

const suiviPepiniereBaseUri = `${baseUri}/suiviPepiniere`

/**
 * getInNurseryCrops
 * @param idFerme
 * @param dateDebut
 * @returns {Promise<*>}
 */
export const getInNurseryCrops = (idFerme, dateDebut) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviPepiniereBaseUri}/culturesEnPepiniere`, { idFerme, dateDebut }))

/**
 * getTasks
 * @param dateDebut
 * @param onlyRepiquage
 * @param onlyTachesEnAttenteArbitrage
 * @returns {Promise<*>}
 */
export const getTasks = (dateDebut, onlyRepiquage, onlyTachesEnAttenteArbitrage) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviPepiniereBaseUri}/taches`, { dateDebut, onlyRepiquage, onlyTachesEnAttenteArbitrage }))