import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { LocalDate } from '../../../../../utils/date/local-date'
import { DateFormat } from '../../../../../utils/date/model/date'
import { injectTypeTacheValueList } from '../../../../common/valueLists/valueListInjectors'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

const styles = () => getStyles({
	contenu: {
		margin: '1.5em',
		marginTop: 14
	},
	titre: {
		marginTop: 10,
		fontWeight: 'bold'
	},
	bloc: {
		marginTop: 22
	},
	radio: {
		width: '100%',
		marginTop: 10,
		'& > div': {
			'& > label': {
				'& > span': {
					fontSize: '14px'
				}
			}
		}
	},
	liste: {
		marginTop: -6,
		marginLeft: -8
	}
})

export const formName = 'DeleteTasks'
export const fieldIdList = 'idList'

/**
 * DeleteTask
 * @param taches
 * @param classes
 * @param typeTacheList
 * @constructor
 */
const DeleteTaskDialog = ({ taches, classes, typeTacheList }) => {

	return (
		<Grid className={classes.contenu}>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					<FormattedMessage id={'tourPlaine.dialog.deleteTask.body'} />
				</Grid>
				<Grid className={classes.liste}>
					<ul>
						{
							taches.map(tache => {
								const typeTache = (typeTacheList.find(type => type.code === tache.task) || {}).label
								return (
									<li key={tache.id}>
										<FormattedMessageWithBold
											id="tourPlaine.dialog.deleteTask.task"
											values={{
												type: typeTache,
												date: LocalDate.fromAPI(tache.plannedDate).format(DateFormat.SHORT_DATE_2_DIGITS),
												crop: tache.culture,
												board: tache.board.name,
												size: tache.board.surface
											}}
										/>
									</li>
								)
							})
						}
					</ul>
				</Grid>
			</Grid>
		</Grid>
	)
}

DeleteTaskDialog.propTypes = {
	taches: PropTypes.array,
	classes: PropTypes.object,
	typeTacheList: PropTypes.array,
	change: PropTypes.func,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, { taches, ...props }) => {
	return ({
		initialValues: {
			[fieldIdList]: taches.map(tache => tache.id)
		},
		taches,
		...props
	})
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	injectTypeTacheValueList,
	reduxForm({
		form: formName,
		destroyOnUnmount: true
	})
)(DeleteTaskDialog)
