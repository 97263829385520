import * as api from './notificationApi'

export const GET_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS'
export const DELETE_NOTIFICATIONS = 'NOTIFICATIONS/DELETE_NOTIFICATIONS'

export const getNotifications = () => dispatch => {
	return api.getNotifications()
		.then(data => {
			dispatch({
				type: GET_NOTIFICATIONS_SUCCESS,
				data
			})
			return data
		})
		.catch(e => {
			throw e
		})
}