import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import IconButton from '../../../../../components/IconButton'
import {getToolbarState} from '../../services/toolbar/toolbarSelector'

const styles = (theme) => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12
	},
	open: {
		borderRadius: 5000,
		'& $additionalText': {
			display: 'unset'
		}
	},
	used: {
		backgroundColor: theme.palette.primary.light2,
		color: theme.palette.primary.main
	},
	additionalText: {
		display: 'none',
		fontSize: '1rem'
	}
})

const ToolBarButton = ({ onClick, type, intl: { formatMessage }, buttonState, classes, children }) => {

	let className = `toolbar-${type} ${classes.icon}`
	if (buttonState) {
		if (buttonState.open) {
			className += ` ${classes.open}`
		}
		if (buttonState.used || buttonState.open) {
			className += ` ${classes.used}`
		}
	}
	return (
		<IconButton className={className} onClick={() => onClick(!buttonState.open)}>
			{children}
			<div className={classes.additionalText}>{formatMessage({ id: `planning.toolbar.button.${type}` })}</div>
		</IconButton>
	)
}

ToolBarButton.propType = {
	children: PropTypes.node,
	onClick: PropTypes.func,
	type: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => ({
	buttonState: getToolbarState(props.type)(state)
})

export default compose(
	injectIntl,
	connect(mapStateToProps),
	withStyles(styles)
)(ToolBarButton)