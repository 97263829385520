import React from 'react'
import { compose } from 'redux'
import Button from '../../../components/Button'
import { withStyles } from '@material-ui/core'
import { isCookieNotExist, updateCookies } from './cookieUtils'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid/index'
import { getStyles } from 'isotope-client'
import { injectDialogCookies } from './dialogCookiesInjector'

const COOKIE_NAME = 'google-analytics-banner'
const COOKIE_PATH = '/'

const style = () => getStyles({
	text: {
		marginLeft: 40,
		width: '60%'
	},
	textMobile: {
		width: '90%',
		textAlign: 'center'
	},
	bandeau: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		fontWeight: '300',
		fontStyle: 'normal',
		fontSize: '16px',
		color: '#fff',
		background: 'none #9c9e9f',
		zIndex: 1,
		height: 83
	},
	bandeauMobile: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		fontWeight: '300',
		fontStyle: 'normal',
		fontSize: '16px',
		color: '#fff',
		background: 'none #9c9e9f',
		zIndex: 1
	},
	button: {
		color: '#161617',
		borderColor: '#161617'
	},
	buttonMobile: {
		color: '#161617',
		borderColor: '#161617',
		marginBottom: 4,
		marginTop: 4
	}
})

const BandeauRGPD = ({ openDialogCookies, classes, isBrowser }) => {
	const [hideCookie, setHideCookie] = React.useState(!isCookieNotExist(COOKIE_NAME))
	const handleClick = () => {
		if (isCookieNotExist(COOKIE_NAME)) {
			updateCookies(COOKIE_NAME, true, {
				path: COOKIE_PATH,
				expires: new Date(9999, 12)
			})
			setHideCookie(true)
		}
	}

	if (hideCookie) {
		return <></>
	}

	return (
		<>
			<Grid container direction="row" justify="space-evenly" alignItems="center" className={isBrowser ? classes.bandeau : classes.bandeauMobile}>
				<Grid item className={isBrowser ? classes.text : classes.textMobile}>
					<p><FormattedMessage id="cookies.message" />
					</p>
				</Grid>
				<Grid item className={!isBrowser && classes.buttonMobile}>
					<Button
						type="primary"
						onClick={handleClick}
					>
						<FormattedMessage id="actions.accept" />
					</Button>
				</Grid>
				<Grid item>
					<Button
						type="secondary"
						className={isBrowser ? classes.button : classes.buttonMobile}
						onClick={() => openDialogCookies(handleClick)}
					>
						<FormattedMessage id="actions.more" />
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default compose(
	withStyles(style),
	injectDialogCookies
)(BandeauRGPD)