import React from 'react'
import PlanificationTable from './planification/PlanificationTable'
import HeaderPlanification from './planification/HeaderPlanification'

const PlanificationOnglet = ({ moveTasksDay }) => {
	const ref = React.createRef()

	return <>
		<HeaderPlanification reference={ref} />
		<PlanificationTable moveTasksDay={moveTasksDay} reference={ref} />
	</>
}

export default PlanificationOnglet