import React from 'react'
import {compose} from 'redux'
import {getStyles} from 'isotope-client'
import {Route} from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import {loadDefaultValueList} from '../common/valueLists/valueListInjectors'
import SnackbarProvider from '../../components/layout/snackbar/SnackbarContext'
import {ROOT_BO} from '../../utils/constants'
import AccueilBoPage from './accueil/AccueilBoPage'
import FermePage from './ferme/FermePage'
import UtilisateurPage from './utilisateur/UtilisateurPage'
import BlocSurfacePage from './blocSurface/BlocSurfacePage'
import CultureItkPage from './cultureItk/CultureItkPage'
import GroupeCulturePage from './groupeCulture/GroupeCulturePage'
import ModeleTachePage from './modeleTache/ModeleTachePage'
import CampagnePage from './campagne/CampagnePage'
import DialogProvider from '../../components/layout/contextProvider/DialogProvider'
import ReloadValueList from '../common/valueLists/ReloadValueList'
import FamilleEspecePage from "./familleEspece/FamilleEspecePage";
import ReglagesMaterielsPage from "./reglagesMateriels/ReglagesMaterielsPage";
import PlanificateurPage from "./planificateur/PlanificateurPage";
import {PAGE, usePageContext} from '../../components/layout/PageContext'
import CategoriesTechniquesPage from "./categoriesTechniques/CategoriesTechniquesPage";

const styles = () => getStyles({
	container: {
		width: 1255,
		backgroundColor: 'transparent',
		margin: 'auto',
		marginLeft: 75,
		marginTop: 35
	},
	valueList: {
		height: '100%'
	}
})

const BackOffice = ({classes}) => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.BO), [])

	return (<SnackbarProvider>
		<DialogProvider fromBo>
			<Paper elevation={0} className={classes.container}>
				<Route exact path={ROOT_BO} component={AccueilBoPage}/>
				<Paper className={classes.valueList}>
					<Route exact path={`${ROOT_BO}/value-list`} component={ReloadValueList}/>
					<Route exact path={`${ROOT_BO}/value-list/:id`} component={ReloadValueList}/>
				</Paper>
				<Route exact path={`${ROOT_BO}/ferme`} component={FermePage}/>
				<Route exact path={`${ROOT_BO}/utilisateur`} component={UtilisateurPage}/>
				<Route exact path={`${ROOT_BO}/bloc-surface`} component={BlocSurfacePage}/>
				<Route exact path={`${ROOT_BO}/culture-itk`} component={CultureItkPage}/>
				<Route exact path={`${ROOT_BO}/groupe-culture`} component={GroupeCulturePage}/>
				<Route exact path={`${ROOT_BO}/modele-tache`} component={ModeleTachePage}/>
				<Route exact path={`${ROOT_BO}/categories-techniques`} component={CategoriesTechniquesPage}/>
				<Route exact path={`${ROOT_BO}/campagne`} component={CampagnePage}/>
				<Route exact path={`${ROOT_BO}/famille-espece`} component={FamilleEspecePage}/>
				<Route exact path={`${ROOT_BO}/reglages-materiels`} component={ReglagesMaterielsPage}/>
				<Route exact path={`${ROOT_BO}/planificateur`} component={PlanificateurPage}/>
			</Paper>
		</DialogProvider>
	</SnackbarProvider>)
}

export default compose(
	loadDefaultValueList,
	withStyles(styles)
)(BackOffice)
