import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import {Grid} from '@material-ui/core'
import BackOfficeContainer from '../BackOfficeContainer'
import {getEspeceList, getFamilleList, updateEspece, updateFamille} from './services/familleEspeceApi'
import {getPreferences} from '../../common/user/services/userSelectors'
import {connect} from 'react-redux'
import BoListView from '../components/BoListView'
import Class from '@material-ui/icons/Class'
import Eco from '@material-ui/icons/Eco'
import NoResultPage from '../../../components/NoResultPage'
import {getEspeceForInitValues, getEspeceSelectionForInitValues, getFamilleForInitValues, getFamilleSelectionForInitValues} from './familleEspeceUtils'
import {SubmissionError} from 'redux-form'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import Loader from '../../../components/layout/Loader'
import {compose} from 'redux'
import {injectFermeList} from '../ferme/services/fermeListInjector'
import EspeceForm from "./components/EspeceForm";
import FamilleForm from "./components/FamilleForm";
import {injectEspeceCultureValueList, injectFamilleCultureValueList} from "../../common/valueLists/valueListInjectors";
import {getNotifications} from '../../common/notification/notificationActions'
import {useSolverStatusContext} from '../../fo/besoinsProduction/SolverStatusContextProvider'

const FAMILLE_ESPECE_FORM = 'FAMILLE_ESPECE_FORM'

const FamilleEspecePage = ({ idFerme, getNotifications, especeCultureList, familleCultureList }) => {
	const intl = useIntl()
	const INIT_FILTERS = { idFerme }
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [familles, setFamille] = React.useState([])
	const [famillesData, setFamillesData] = React.useState([])
	const [especesData, setEspecesData] = React.useState([])
	const [selection, setSelection] = React.useState(undefined)
	const [loading, setLoading] = React.useState(false)
	const [refresh, setRefresh] = React.useState(false)
	const { snackSuccess, snackError } = useSnackbar()
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	React.useEffect(() => {
		setInitialisation()
		setSelection(undefined)
	}, [filters, familleCultureList])

	React.useEffect(() => {
		setInitialisation()
	}, [refresh])

	const setInitialisation = () => {
		setLoading(true)
		getFamilleList(filters).then(setFamillesData)
		getEspeceList(filters).then(setEspecesData)
		if (familleCultureList.length > 0) {
			setFamille(filters?.query ?
				setFamilleTuile() : familleCultureList.map(famille => formatTuileFamille(famille))
			);
			setLoading(false)
		}
	}

	const setFamilleTuile = () => (
		familleCultureList.filter(famille => {
			const familleLabelMatch = famille.label && famille.label.toLowerCase().startsWith((filters?.query || "").toString().toLowerCase());
			const especeMatch = especeCultureList.some(espece => {
				return espece.code.substring(0, 3) === famille.code && espece.label && espece.label.toLowerCase().startsWith((filters?.query || "").toString().toLowerCase());
			});
			return familleLabelMatch || especeMatch
		}).map(famille => formatTuileFamille(famille))
	)

	const formatTuileFamille = (famille) => ({
		uniqueId: `famille-${famille.id}-${famille.code || famille.codeFamille}`,
		icon: Class,
		title: famille.label || famille.name,
		description: <FormattedMessage
			id="bo.familleEspece.espece"
			values={{ quantity:  especeCultureList.filter(espece =>
					espece.code.substring(0, 3) === famille.code).length }} />,
		data: famille,
		children: filters?.query ? especeCultureList.filter(espece =>
				(espece.code.substring(0, 3) === famille.code) &&
				(espece.label && espece.label.toLowerCase().startsWith((filters?.query || "").toString().toLowerCase())))
				.map(espece => formatTuileEspece(espece, famille))
			:
			especeCultureList.filter(espece => espece.code.substring(0, 3) === famille.code).map(espece => formatTuileEspece(espece, famille))
	})

	const formatTuileEspece = (espece, famille) => ({
		uniqueId: `espece-${espece.id}-${espece.code}`,
		icon: Eco,
		title: espece.label,
		description:`${famille.label} > ${espece.label}`,
		data: {
			...espece,
			isEspece: true,
			name: espece && espece.label,
		},
		children: []
	})

	const getTitleSelection = (selection) => {
		const dataSelection = selection.data
		return dataSelection.isEspece ? `${dataSelection.name}` : `${selection.title}`
	}

	const initialValues= React.useMemo(() => {
		if (selection && selection.data) {
			const espece = especesData.find(espece => selection.data.code === espece.codeEspece);
			const famille = famillesData.find(famille => selection.data.code === famille.codeFamille)
			return selection.data.isEspece ?
				(espece ? getEspeceForInitValues(espece) : getEspeceSelectionForInitValues(selection.data))
				:
				(famille ? getFamilleForInitValues(famille) : getFamilleSelectionForInitValues(selection.data))
		}
		return {}
	}, [selection])

	const handleUpdateEspece = (values) =>
		updateEspece(filters.idFerme, values)
			.then(() => {
				snackSuccess({ id: 'bo.familleEspece.snackbar.updateEspece' })
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				setRefresh(!refresh);
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	const handleUpdateFamille = (values) =>
		updateFamille(filters.idFerme, values)
			.then((savedFamille) => {
				const famille = setFamilleTuile().find(famille => famille.data.code === savedFamille.codeFamille);
				snackSuccess({ id: 'bo.familleEspece.snackbar.updateFamille' })
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				setRefresh(!refresh);
				setSelection(famille);
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.familleEspece.title" />
			</Typography>
		</Grid>
		<BackOfficeContainer inputLabel="bo.familleEspece.inputQuery" onSubmit={setFilters}>
			{loading ?
				<Loader fromBo />
				:
				 familles.length > 0 ?
					<BoListView
						titleLeft={intl.formatMessage({ id: 'bo.familleEspece.titleLeft' })}
						titleRight={selection ? getTitleSelection(selection) : ''}
						tuileList={familles}
						selection={selection}
						handleSelection={setSelection}
						formName={FAMILLE_ESPECE_FORM}
					>
						{selection && selection.data && <>
							{selection.data.isEspece ?
								<EspeceForm
									formName={FAMILLE_ESPECE_FORM}
									onSubmit={handleUpdateEspece}
									initialValues={initialValues}
								/>
								:
								<FamilleForm
									formName={FAMILLE_ESPECE_FORM}
									onSubmit={handleUpdateFamille}
									initialValues={initialValues}
								/>
							}
						</>}
					</BoListView>
					: <NoResultPage emptyStateMessageId="bo.familleEspece.empty" />
			}
		</BackOfficeContainer>
	</>)
}

FamilleEspecePage.propTypes = {
	idFerme: PropTypes.string,
	fermeList: PropTypes.array,
	especeCultureList: PropTypes.array,
	familleCultureList: PropTypes.array,
}

const mapStateToProps = (state) => ({
	idFerme: (getPreferences(state).FERME_ID || '0')
})

const actions = {
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	injectFamilleCultureValueList,
	injectEspeceCultureValueList,
	injectFermeList
)(FamilleEspecePage)

