import React from 'react'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../../GestionRessourceProvider';
import {Grid, withStyles} from '@material-ui/core'
import {AJOUT_RESSOURCE_FORM, colors, daysOfWeek} from '../../../../../utils/constants';
import TimerIcon from '@material-ui/icons/Timer'
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {getStyles} from 'isotope-client'
import Input from '../../../../../components/form/Input';
import Checkbox from '../../../../../components/form/Checkbox';
import {connect} from 'react-redux'
import {convertMinutesToTime} from '../../utils/ressourceUtils';
import {formatPositiveInteger} from '../../../../../utils/utils';
import {getPreferences} from "../../../../common/user/services/userSelectors";
import {getFermeData} from "../../../../bo/ferme/services/fermeApi";

const styles = () => getStyles({
	container: {
		fontFamily: 'Lato',
	},
	title: {
		fontSize: 18,
		fontWeight: 600
	},
	subtitle: {
		fontSize: 12,
		fontWeight: 300,
		fontStyle: 'italic'
	},
	icon: {
		height: 24,
		width: 24
	},
	field: {
		width: '33%'
	},
	row: {
		width: '80%'
	},
	fieldRow: {
		marginTop: 5
	},
	header: {
		marginBottom: 20
	},
	weekDiv: {
		width: '65%'
	},
	weekContainer: {
		marginTop: 10
	},
	checkLabel: {
		fontSize: 14,
		marginLeft: '-5%'
	},
	textField: {
		backgroundColor: colors.light,
		borderRadius: 20,
		'&.MuiOutlinedInput-root': {
			borderRadius: 20,
		},
	},
	inputs: {
		marginLeft: 20
	},
	addIcon: {
		marginTop: 20,
		marginBottom: 20,
		"& .MuiButton-outlinedPrimary": {
			border: 'none'
		}
	},
	hourValue: {
		fontSize: 13,
		color: colors.empty
	}
})

const required = value => (value ? undefined : { id: 'global.errors.mandatory' });

const HeaderText = ({Icon, message, classes}) => {
	return (
		<Grid container direction="row" alignItems="center" className={classes.header}>
			<Grid item style={{marginRight: 15}}>
				<Icon color="primary" className={classes.icon}/>
			</Grid>
			<Grid item className={classes.title}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.${message}`}/>
			</Grid>
		</Grid>
	);
};

const WeekDiv = ({day, fieldNameDebut, fieldNameTemps, classes, tempsTravail}) => {
	return (
		<Grid container alignItems="center" spacing={2} className={classes.weekDiv}>
			<Grid item xs={2}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.weekDay.${day}`}/>
			</Grid>
			<Grid item xs={4}>
				<Field
					name={fieldNameDebut}
					InputLabelProps={{
						shrink: true
					}}
					component={Input}
					type="time"
					label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.debut`}/>}
					validate={required}
					fromBo
				/>
			</Grid>
			<Grid item xs={4}>
				<Field
					name={fieldNameTemps}
					InputLabelProps={{
						shrink: true
					}}
					component={Input}
					type="number"
					label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.tempsTravail`}/>}
					validate={required}
					adornment={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.minutes`}/>}
					parse={value => value && value > 1440 ? 1440 : value}
					format={formatPositiveInteger}
					fromBo
				/>
			</Grid>
			<Grid item xs={2} className={classes.hourValue}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.tempsTravailHour`} values={{ hour: convertMinutesToTime(tempsTravail || 0)}}/>
			</Grid>
		</Grid>
	);
};

const makeMapStateToProps = (state, ownProps) => {
	const formSelector = formValueSelector(AJOUT_RESSOURCE_FORM);
	return {
		tempsTravail: formSelector(state, ownProps.fieldNameTemps),
	};
};

const ConnectedWeekDiv = connect(makeMapStateToProps)(WeekDiv);


const FIELDS = {
	IS_HOUR_DEPEND: {name: 'isHourDepend', optional: true},
}


const Contrats = ({
					  classes,
					  handleSubmit,
					  initialValues,
					  idFerme,
					  change
	}) => {
	const {ongletGestion} = useGestionRessourceContext()

	React.useEffect(() => {
		if (!initialValues) {
			getFermeData(idFerme).then(data => {
				if (data) {
					daysOfWeek.forEach(day => {
						change(`${day}Debut`, data[`${day}Debut`]);
					});
				}
			});
		}
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			<Grid item container justify={"center"} style={{marginLeft: initialValues ? 150 : 20, paddingRight: initialValues ? '5%' : 0}} className={classes.row}>
				<HeaderText Icon={TimerIcon} message={ongletGestion.code.toLowerCase()} classes={classes}/>
				<Grid item container justify={"flex-start"} className={classes.subtitle}>
					<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.contratSub`}/>
				</Grid>
				<Grid item container direction={"column"} className={classes.weekContainer}>
					{daysOfWeek.map(day => (
						<ConnectedWeekDiv
							key={day}
							classes={classes}
							day={day}
							fieldNameDebut={`${day}Debut`}
							fieldNameTemps={`${day}Temps`}
						/>
					))}
				</Grid>
				<Grid item container direction={"column"}>
					<Field
						name={FIELDS.IS_HOUR_DEPEND.name}
						component={Checkbox}
						containerClassName={classes.checkbox}
						label={
						<Grid item className={classes.checkLabel}>
								<FormattedMessage id="gestionRessource.ajoutRessource.form.titles.hourDepend"/>
						</Grid>
						}
					/>
				</Grid>
			</Grid>
		</form>
	)
}


Contrats.propType = {
	classes: PropTypes.object,
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	idFerme: PropTypes.string
}

const mapStateToProps = (state) => {
	return ({
		form: AJOUT_RESSOURCE_FORM,
		idFerme: getPreferences(state).FERME_ID
	})
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		enableReinitialize: true,
		destroyOnUnmount: false,
	}),
	withStyles(styles),
)(Contrats)
