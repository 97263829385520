import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ActionPanelContext, DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import { PANEL_TYPE } from '../../../utils/constant'
import { selectAssolement } from '../../assolement/assolementAction'

/**
 * Injecteur pour l'ouverture du panel de détail de surface
 */
export const openSurfaceDetail = (WrappedComponent) => {
	const OpenSurfaceDetailInjector = ({ selectAssolement, ...props }) => {
		const { openPanel: openPanelDetail } = React.useContext(DetailsPanelContext)
		const { closePanel: closePanelAction } = React.useContext(ActionPanelContext)

		const openSurfaceDetail = (assolement) => {
			closePanelAction()
			openPanelDetail()
			selectAssolement(assolement, PANEL_TYPE.SURFACE_DETAIL)
		}

		return <WrappedComponent {...props} openSurfaceDetail={React.useCallback(openSurfaceDetail, [])} />
	}

	const actions = {
		selectAssolement
	}

	OpenSurfaceDetailInjector.propTypes = {
		selectAssolement: PropTypes.func
	}

	return connect(undefined, actions)(OpenSurfaceDetailInjector)
}
