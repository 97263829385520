import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/Button'
import { DialogContext } from '../../../../components/layout/dialog'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import PopinPlancheIndisponible from '../PopinPlancheIndisponible'

export const injectPlancheIndisponible = (WrappedComponent) => {
	const PlancheIndisponibleInjector = (props) => {
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { snackSuccess, snackError } = useSnackbar()
		const onSubmit = (data, toSend) => {
			toSend(data)
				.then(() => {
					snackSuccess({id: 'bo.blocSurface.snackbar.success.plancheIndisponible'})
					closeDialog()
				})
				.catch((error) => {
					snackError({id: 'bo.blocSurface.snackbar.errors.plancheIndisponible'})
				})
		}

		const openPopinPlancheIndisponible = (assolementList, data, toSend) => {
			openDialog(
				<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.popin.title" />,
				<PopinPlancheIndisponible assolementList={assolementList} />,
				[
					<Button style={{display: assolementList?.some(assolement => assolement?.enCours) && 'none'}} onClick={() => onSubmit(data, toSend)} type="primary">
						<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.popin.buttons.save" />
					</Button>
				],
			)
		}
		return <WrappedComponent {...props} openPopinPlancheIndisponible={React.useCallback(openPopinPlancheIndisponible, [])} />
	}

	return (PlancheIndisponibleInjector)
}