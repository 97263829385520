import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../../../../../components/Button'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid/index'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { COMMENTAIRE_TYPE_ENTITY, TYPE_DIALOG } from '../../../../../../utils/constants'
import { deleteCommentPlanche, deleteCommentSuivi, deleteCommentEtape } from '../../commentaires/commentairesActions'
import moment from 'moment'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'

/**
 * Injecteur pour l'ouverture de la popin de suppression de commentaire
 */
export const injectDeleteComment = (WrappedComponent) => {
	const CommentDeleteInjector = (props) => {
		const { snackError, snackSuccess } = useSnackbar()
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { deleteCommentEtape, deleteCommentPlanche, deleteCommentSuivi } = props

		const showSnackSuccess = () => snackSuccess({ id: 'snackbar.update.suppression' })
		const showSnackError = () => snackError({ id: 'snackbar.error.suppression' })
		const openCommentDelete = (commentaire, typeEntity) => openDialog({ id: `commentForm.deleteComment.${typeEntity}.title` },
			<Grid style={{ margin: '1.5em' }}>
				<Grid><FormattedMessage id={`commentForm.deleteComment.${typeEntity}.label`} values={{ date: moment(commentaire.dateModification).format('DD/MM/YYYY') }} /></Grid><br />
				{typeEntity === COMMENTAIRE_TYPE_ENTITY.ETAPE && <Grid><FormattedMessage id="commentForm.deleteComment.label" /></Grid>}
			</Grid>, [
				<Button
					type="primary"
					onClick={() => {
						if (typeEntity === COMMENTAIRE_TYPE_ENTITY.ETAPE) {
							deleteCommentEtape(commentaire.id)
								.then(() => {
									closeDialog()
									showSnackSuccess()
								})
								.catch(() => {
									showSnackError()
								})
						}
						if (typeEntity === COMMENTAIRE_TYPE_ENTITY.PLANCHE) {
							deleteCommentPlanche(commentaire.id, commentaire.idEntity)
								.then(() => {
									closeDialog()
									showSnackSuccess()
								})
								.catch(showSnackError)
						}
						if (typeEntity === COMMENTAIRE_TYPE_ENTITY.CULTURE_PLANCHE) {
							deleteCommentSuivi(commentaire.id)
								.then(() => {
									closeDialog()
									showSnackSuccess()
								})
								.catch(showSnackError)
						}
					}}
				>
					<FormattedMessage id="actions.delete" />
				</Button>
			], TYPE_DIALOG.ACTION)

		return <WrappedComponent {...props} openCommentDelete={React.useCallback(openCommentDelete, [])} />
	}

	const actions = {
		deleteCommentEtape,
		deleteCommentPlanche,
		deleteCommentSuivi
	}

	CommentDeleteInjector.propTypes = {
		deleteCommentEtape: PropTypes.func,
		snackError: PropTypes.func,
		deleteCommentSuivi: PropTypes.func,
		deleteCommentPlanche: PropTypes.func,
		snackSuccess: PropTypes.func
	}

	return connect(undefined, actions)(CommentDeleteInjector)
}
