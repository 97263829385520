import PropTypes from 'prop-types'
import React from 'react'
import {ActionPanel, ActionPanelContext, FilterPanelContext} from '../rightPanels'
import FilterPanel from '../rightPanels/FilterPanel'
import {ACTION_RESET, ACTION_UPDATE} from './service/action'
import {initialState, reducer} from './service/reducer'

const ActionPanelProvider = ({ Context, level, children }) => {
	const [actionState, actionDispatch] = React.useReducer(reducer, initialState)

	const updateAction = React.useCallback((title, content, actionValider, titleIcon) => actionDispatch({
		type: ACTION_UPDATE,
		payload: {
			titleIcon,
			title,
			content,
			actions: actionValider
		}
	}), [])
	const resetAction = React.useCallback(() => actionDispatch({ type: ACTION_RESET }), [])
	const toggleClickAway = React.useCallback((isClickAwayEnabled) => actionDispatch({
		type: ACTION_UPDATE,
		payload: {
			isClickAwayEnabled
		}
	}), [])

	const [openAction, setOpenAction] = React.useState(false)

	const openActionPanel = (title, content, actionValider, titleIcon) => {
		updateAction(title, content, actionValider, titleIcon)
		setOpenAction(true)
	}

	const closeActionPanel = () => {
		resetAction()
		setOpenAction(false)
	}
	let Panel = ActionPanel
	if (Context === FilterPanelContext) {
		Panel = FilterPanel
	}
	return (
		<Context.Provider value={{ ...actionState, openPanel: openActionPanel, closePanel: closeActionPanel, toggleClickAway }}>
			<Panel Context={Context} open={openAction} level={level} />
			{children}
		</Context.Provider>
	)
}

ActionPanelProvider.propTypes = {
	level: PropTypes.number,
	Context: PropTypes.object
}

ActionPanelProvider.defaultProps = {
	Context: ActionPanelContext,
	level: 0
}

export default ActionPanelProvider
