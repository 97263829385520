import { DateTime } from 'luxon'
import { assertNotNullOrUndefined } from '../utils'
import { LocalDateTime } from './local-date-time'
import { DateUnit } from './model/date'
import { Temporal } from './temporal'

/**
 * Méthode permettant de manipuler uniquement la partie date d'un {@link Date} js. <br>
 * Cette classe est sérializable, donc elle peut directement être envoyée au back.
 */
export class LocalDate extends Temporal {

	constructor(temporal) {
		super(temporal.startOf('day'))
	}

	static now = () => new LocalDate(DateTime.now().setZone(Temporal.API_TIMEZONE))

	static epoch = () => new LocalDate(DateTime.fromSeconds(0, { zone: Temporal.API_TIMEZONE }))

	static fromAPI(temporal) {
		if (assertNotNullOrUndefined(temporal)) {
			return new LocalDate(DateTime.fromISO(temporal, { zone: Temporal.API_TIMEZONE }))
		}
		return temporal
	}

	static fromJS = (date) => this.fromJSWithTimezone(date, Temporal.API_TIMEZONE)

	static fromJSWithTimezone = (date, timezone) => new LocalDate(DateTime.fromJSDate(date, { zone: timezone }))

	static fromString = (date, format = 'yyyy/MM/dd') => new LocalDate(DateTime.fromFormat(date, format, { zone: Temporal.API_TIMEZONE }))

	format = (dateFormat, locale) => {
		return this.toDisplayDate(dateFormat, locale)
	}

	endOfDay = () => new LocalDateTime(this._date.endOf('day'))

	startOfDay = () => new LocalDateTime(this._date.startOf('day'))

	plus(amount, unit = DateUnit.day) {
		return new LocalDate(this._date.plus({ [unit]: amount }))
	}

	atTime(time) {
		return this.atTimeString(time.toJSON())
	}

	atTimeString(time) {
		return new LocalDateTime(this._date.set(Temporal._splitTime(time)))
	}

	minus(amount, unit = DateUnit.day) {
		return this.plus(-amount, unit)
	}

	startOf(unit = DateUnit.day) {
		return new LocalDate(this._date.startOf(unit))
	}

	endOf(unit = DateUnit.day) {
		return new LocalDate(this._date.endOf(unit))
	}

	get weekNumber() {
		return this._safe(this._date).weekNumber
	}

	get weekday() {
		return this._date.weekday - 1
	}

	get year() {
		return this._date.year
	}

	get month() {
		return this._date.month - 1
	}

	toString = () => this.format()

	toJSON() {
		return this._date.toISODate()
	}
}
