import React from 'react'
import PropTypes from 'prop-types'

const PlaceIcon = ({ height, width }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M256 361c-91.126 0-165 33.578-165 75 0 41.42 73.874 76 165 76s165-34.58 165-76c0-41.422-73.874-75-165-75z"
				fill="#91dc5a"
			/>
			<path
				d="M421 436c0-41.422-73.874-75-165-75v151c91.126 0 165-34.58 165-76z"
				fill="#64c37d"
			/>
			<path
				d="M256 0C174.892 0 91 61.714 91 165c0 94.351 147.217 273.08 153.472 280.609C247.328 449.022 251.547 451 256 451s8.672-1.978 11.528-5.391C273.783 438.08 421 259.351 421 165 421 74.019 346.981 0 256 0zm0 270c-57.891 0-105-47.109-105-105S198.109 60 256 60s105 47.109 105 105-47.109 105-105 105z"
				fill="#ff7f4b"
			/>
			<path
				d="M421 165C421 74.019 346.981 0 256 0v60c57.891 0 105 47.109 105 105s-47.109 105-105 105v181c4.453 0 8.672-1.978 11.528-5.391C273.783 438.08 421 259.351 421 165z"
				fill="#fd5219"
			/>
		</svg>
	)
}

PlaceIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

PlaceIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default PlaceIcon
