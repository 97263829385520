import { fetchFactory, urlUtils } from 'isotope-client'

export const getSuiviAvancement = (jour) => fetchFactory(urlUtils.buildUrlWithParams('/suiviTaches/avancementGlobalJournee', {
	jour: jour
}))

export const getSuiviAvancementRessource = (jour, idUserSelectionne) => fetchFactory(urlUtils.buildUrlWithParams('/suiviTaches/byUser', {
	jour: jour,
	idRessource: idUserSelectionne
}))

export const exportSuiviAvancement = (debut, fin, idFerme) => fetchFactory(urlUtils.buildUrlWithParams('/suiviTaches/export', {
	debut: debut,
	fin: fin,
	idFerme: idFerme
}))