import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import { TYPE_DIALOG } from '../../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import DeleteEtape from '../../../component/DeleteEtape'
import { ACTION_TYPE } from '../../../utils/constant'
import { hasCampagneChanged } from '../../../utils/utils'
import { deleteAssolementEtape } from '../../assolement/assolementAction'
import { getAssolements } from '../../assolement/assolementSelector'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'

/**
 * Injecteur pour l'ouverture de la popin de suppression de etape
 */
export const injectDeleteEtape = (WrappedComponent) => {
	const EtapeDeleteInjector = (props) => {
		const { snackError, snackSuccess, snackWarning } = useSnackbar()
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { closePanel } = React.useContext(DetailsPanelContext)
		const { deleteAssolementEtape, campagne, assolementsById } = props
		const openEtapeDelete = (assolementEtape, callback) => {
			const assolement = assolementsById[assolementEtape.idAssolement] || assolementEtape.assolement
			openDialog({ id: 'planning.etapesAssolement.deleteEtape.title' },
				<DeleteEtape assolementEtape={assolementEtape}/>, [
					<Button
						type="primary"
						onClick={() =>
							deleteAssolementEtape(assolementEtape.id, assolementEtape.idAssolement, campagne.id || assolement.campagne.id)
								.then(({ assolement: newAssolement }) => {
									closeDialog()
									if(callback){
										callback(newAssolement)
									}
									closePanel()
									if (!hasCampagneChanged(assolement.campagne, newAssolement, campagne, snackWarning, ACTION_TYPE.DELETE))
										snackSuccess({ id: 'snackbar.update.suppression' })
								})
								.catch(() => {
									snackError({ id: 'snackbar.error.suppressionEtape' })
								})
						}
					>
						<FormattedMessage id="actions.delete"/>
					</Button>
				], TYPE_DIALOG.ACTION)
		}

		return <WrappedComponent {...props} openEtapeDelete={React.useCallback(openEtapeDelete, [assolementsById])} />
	}

	const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state),
		assolementsById: getAssolements(state)
	})

	const actions = {
		deleteAssolementEtape
	}

	EtapeDeleteInjector.propTypes = {
		deleteAssolementEtape: PropTypes.func
	}

	return compose(
		connect(mapStateToProps, actions)
	)(EtapeDeleteInjector)
}
