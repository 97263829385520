import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const CultureGroupIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 0 24 24" width={width} height={height}>
			<path
				d="M2,2H4V20H22V22H2V2M7,10H17V13H7V10M11,15H21V18H11V15M6,4H22V8H20V6H8V8H6V4Z"
				fill={color}
			/>
		</svg>
	)
}

CultureGroupIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

CultureGroupIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default CultureGroupIcon
