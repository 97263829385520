import {Box, Grid, Typography, withStyles} from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ContextePedoclimatique from "../../tourPlaine/component/contextePedoclimatique/ContextePedoclimatique";


const styles = () => getStyles({
	root: {
		zIndex: 100,
		paddingTop: 20
	},
	container: {
		fontFamily: 'Lato',
		fontStyle: "italic",
		textAlign: 'left',
		fontSize: 14,
		padding: 0,
	},
	text: {
		paddingBottom: 15
	}
})

const ConditionPedoclimatiqueBloc = ({ classes }) => {
	return(
	<Box container className={classes.root}>
		<Grid container justify={"flex-start"} alignItems={"center"} direction={"row"} className={classes.container}>
			<Typography className={classes.text} variant="p">
				<FormattedMessage id="tdb.blocs.pedoclimatique.description"/>
			</Typography>
			<Grid container justify={"space-around"} alignItems={"center"} direction={"row"}>
				<ContextePedoclimatique isDashboard={true}/>
			</Grid>
		</Grid>
	</Box>
)}

export default withStyles(styles)(ConditionPedoclimatiqueBloc)