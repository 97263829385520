import { createSelector } from 'reselect'

const getLocalState = state => state.gestionTaches

const getAffectationsState = createSelector(
	getLocalState,
	state => state.affectations
)

export const getAffectationsData = createSelector(
	getAffectationsState,
	affectations => affectations.data
)

export const getAffectationsShouldRefresh = createSelector(
	getAffectationsState,
	affectations => affectations.shouldRefresh
)

export const getAffectationsSelectedRows = createSelector(
	getAffectationsState,
	affectations => affectations.selectedRows
)

const getOrdonnancementState = createSelector(
	getLocalState,
	state => state.ordonnancement
)

const getOrdonnancementsTachesParId = createSelector(
	getOrdonnancementState,
	state => state.parId
)

export const extractSelectedTaches = (selected) => createSelector(
	getOrdonnancementsTachesParId,
	taches => selected.map(id => taches[id])
)

export const getOrdonnancementsTachesIds = createSelector(
	getOrdonnancementState,
	state => state.ids
)

export const getOrdonnancementTaches = createSelector(
	getOrdonnancementsTachesParId,
	getOrdonnancementsTachesIds,
	(parId, ids) => ids.map(id => parId[id])
)

export const getOrdonnancementTacheAtIndex = (index) => createSelector(
	getOrdonnancementsTachesIds,
	ids => ids[index]
)

export const getFilterValues = createSelector(
	getLocalState,
	state => state.filter
)

export const getFilterState = createSelector(
	getLocalState,
	state => state && state.filter
)

export const affectationLoading = createSelector(
	getAffectationsState,
	affectations => affectations.loading
)

export const ordonnancementLoading = createSelector(
	getOrdonnancementState,
	ordonnancement => ordonnancement.loading
)
