import Grid from '@material-ui/core/Grid'
import Update from '@material-ui/icons/Update'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FormButton from '../../../../components/form/FormButton'
import { DialogContext } from '../../../../components/layout/dialog'
import { colors, TYPE_DIALOG } from '../../../../utils/constants'
import { getPreferences } from '../../../common/user/services/userSelectors'
import PopinPlanification from '../popin/PopinPlanification'

/**
 * Injecteur pour l'ouverture de la popin de la planification des taches
 */
export const injectPopinPlanification = (WrappedComponent) => {
	const PlanificationPopinInjector = (props) => {
		const { openDialog } = React.useContext(DialogContext)
		const onSubmit = (values) => {alert('Fonctionnalité non disponible pour le moment')} // TODO Faire le submit vers le solveur taches, attente de sa création

		const openPopinPlanification = (semaineCourante) => {
			if (semaineCourante) {
				openDialog(
					<Grid container justify={"space-between"}>
						<Update style={{ paddingRight: 20, color: colors.primary }}/>
						<FormattedMessage id="gestionTaches.toolbar.popin.titre"/>
					</Grid>,
					<PopinPlanification onSubmit={onSubmit} semaineCourante={semaineCourante}/>,
					[
						<FormButton variant="text" withBorder={false}>
							<FormattedMessage id="gestionTaches.toolbar.popin.validation"/>
						</FormButton>
					],
					TYPE_DIALOG.ACTION
				)
			}
		}
		return <WrappedComponent {...props} openPopinPlanification={React.useCallback(openPopinPlanification, [])} />
	}

	const mapStateToProps = state => ({
		userPreferences: getPreferences(state)
	})

	return compose(
		connect(mapStateToProps),
	)(PlanificationPopinInjector)
}

