import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CHOIX_DATES, colors, ETAPE_ETAT, TYPE_PLANCHE } from '../../../../utils/constants'
import { normalizeNumber } from '../../../../utils/utils'
import TypeEtape from '../../../common/information/TypeEtape'


/**
 * Fonction qui indique si la date saisie est hors de la campagne
 * @param campagne
 * @param choixDate
 * @param date
 * @param nbWeek
 * @returns {boolean}
 */
export const isInvalidDateCampagne = (campagne, choixDate, date, nbWeek) => {
	const dateDebutAffiche = moment(campagne.dateDebutVisible)
	const dateFinVisible = moment(campagne.dateFinVisible)

	if (choixDate === CHOIX_DATES.SEMIS) {
		return moment(date).isBefore(dateDebutAffiche) || moment(date).add(nbWeek, 'weeks').isAfter(dateFinVisible)
	} else {
		return moment(date).isAfter(dateFinVisible) || moment(date).subtract(nbWeek, 'weeks').isBefore(dateDebutAffiche)
	}
}

const cardStyle = (etat) => {
	return {
		height: 24,
		float: 'right',
		width: 84,
		backgroundColor: etat === ETAPE_ETAT.TERMINE ? colors.iconeDetail : colors.toDo,
		color: 'white',
		fontSize: 14,
		textAlign: 'center',
		paddingTop: 5
	}
}

export const getEtapeDetailTitle = (currentEtape) => (
	<div style={{ marginTop: 10, width: '100%' }}>
		<TypeEtape typeEtape={currentEtape.type}/>
		<div style={cardStyle(currentEtape.etat)}><FormattedMessage id={`enums.etatEtape.${currentEtape.etat}`}/>
		</div>
	</div>
)

/**
 *
 * @param date la date dont on cherche le nombre de jour depuis le debut/la fin de l'année
 * @param fromStart determine si on veux la periode [debutAnnée - date] ou [date - finAnnée]
 * @returns number (jour)
 */
const compareDate = (date, fromStart) => {
	let newDate = moment(date)
	newDate = (fromStart) ? newDate.startOf('year') : newDate.endOf('year')
	return ((fromStart) ? -1 : 1) * newDate.diff(date, 'days')
}

/**
 *
 * @param itk l'itk a modifier
 * @param campagne la campagne actuelle
 * @return {{semisDebut: string, semisFin: string}} itk modifié
 */
export const getValidStartAndEndDate = (itk, campagne) => {
	const { semisDebut, semisFin } = itk
	const startDate = moment(semisDebut)
	const endDate = moment(semisFin)

	let currentYear
	let campagneStart = moment(campagne.dateDebut)
	let campagneEnd = moment(campagne.dateFin)

	//cas d'une campagne sur la même année
	if (campagneStart.year() === campagneEnd.year()) {
		currentYear = campagneStart.year()
	} else {
		//cas d'une campagne sur 2 années
		// recupère le nombre de jour que la campagne passe dans chaque année pour determiner l'année principale
		const dayToEnd = compareDate(campagneStart, false)
		const dayFromStart = compareDate(campagneEnd, true)
		currentYear = (dayToEnd < dayFromStart) ? campagneEnd.year() : campagneStart.year()
	}

	const startYear = startDate.year()
	let shift = currentYear - startYear

	const newStart = moment(startDate).add(shift, 'year')
	const newEnd = moment(endDate).add(shift, 'year')

	//si la fin de la periode de semis est avant le debut de la campagne on est une année trop tôt
	if (newEnd.isBefore(campagneStart)) {
		shift += 1
	}
	// si le debut de la periode de semis est après la fin de la campagne on est une année trop tard
	else if (newStart.isAfter(campagneEnd)) {
		shift -= 1
	}

	return {
		...itk,
		semisDebut: moment(semisDebut).add(shift, 'year').format('YYYY-MM-DD'),
		semisFin: moment(semisFin).add(shift, 'year').format('YYYY-MM-DD')
	}
}

export const withLineSeparator = (assolement, index, assolements) => {
	if (index === 0 || index === assolements.length) {
		return false
	}
	const previousAsso = assolements[index - 1]
	return assolement.planche.id !== previousAsso.planche.id
}

export const findFertilizedAssolement = (plancheId, assolements) => Object.values(assolements).find(asso => asso.planche.id === plancheId && !asso.culture)

/**
 * verifie si une fertilization existe
 * @param plancheId l'id de la planche
 * @param assolements la liste des assolements
 * @param fertilizationExist  conditionne l'information qu'on attends
 * @return {*|boolean} si fertilizationExist faux verifie si une et une seule fertilisation existe. si vrai verifie si plusieurs fertilisations existent
 */
export const checkFertilization = (plancheId, assolements, fertilizationExist) => {
	const fertilizedAssolement = findFertilizedAssolement(plancheId, assolements)
	if (!fertilizedAssolement) {
		return !fertilizationExist
	}
	const numberOfFert = fertilizedAssolement.assolementEtapeList.length

	return fertilizationExist && numberOfFert < 3
}

export const hasCampagneChanged = (previousAssolementCampagne, newAssolement, currentCampagne, snackWarning, type) => {
	const newCampagne = newAssolement.campagne

	if (previousAssolementCampagne.id === newCampagne.id) {
		return false
	}

	let code = 'current'
	if (newCampagne.dateDebut < currentCampagne.dateDebut) {
		code = 'previous'
	}
	if (newCampagne.dateDebut > currentCampagne.dateDebut) {
		code = 'next'
	}

	snackWarning({
		id: `snackbar.warning.${type}.${code}Campagne`
	})
	return true
}

export const getEspacementDetail = assolement => {
	const isPer = assolement.planche.typePlanche === TYPE_PLANCHE.PERMANENTE
	return {
		id: `planning.itineraireTechnique.detail.${(!isPer) ? 'densite' : 'nbRangs'}`,
		values: {
			espacement: normalizeNumber(assolement.espacement),
			value: isPer ? assolement.nbRangs : assolement.densite
		}
	}
}
