import { fetchFactory, urlUtils } from 'isotope-client'

export const baseUri = '/tourPlaine'

/**
 * API for all tourPlaine
 */

/**
 * AddReminder
 * @param values
 * @returns {Promise<*>}
 */
export const addReminder = (values) => fetchFactory(`${baseUri}/note`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getNotes = () => fetchFactory(`${baseUri}/note`)

export const updateNote = (noteId, isSeen) => fetchFactory(`${baseUri}/note/${noteId}`, {
	method: 'PUT',
	body: JSON.stringify(isSeen)
})

export const validationTdp = (toSolver) => fetchFactory(`${baseUri}/validation`, {
	method: 'POST',
	body: JSON.stringify(toSolver)
})

export const getDonneesManquantesTDP = (dateDebut) => fetchFactory(urlUtils.buildUrlWithParams(`${baseUri}/check`, { dateDebut }))

export const getModelesTache = (idTache) => fetchFactory(urlUtils.buildUrlWithParams(`${baseUri}/modelesTache`, { idTache }))

export const specifyGenericTask = (idTache, idModele) => fetchFactory(urlUtils.buildUrlWithParams(`${baseUri}/specifyGenericTask`, {idTache, idModele}), {
	method: 'PUT'
})