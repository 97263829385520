import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import Autocomplete from '../../../../../../components/form/Autocomplete'
import { Field, formValueSelector } from 'redux-form'
import { compose } from 'redux'
import CultureIcon from '../../../../../../components/icon/CultureIcon'
import PropTypes from 'prop-types'
import Comment from '../../../../../../components/Comment'
import { fields, getAutocompleteOptions } from './../NouvelleCulture'
import InfoCulture from '../../../../../common/information/InfoCulture';

const MAX_LINES = 2

const styles = () => getStyles({
	titleField: {
		marginTop: 30,
		marginBottom: 19,
		marginLeft: 10,
		fontWeight: 'bold',
		fontSize: 18
	},
	icon: {
		marginRight: 10,
		marginTop: 5
	},
	blocText: {
		marginTop: 20,
		fontSize: 14,
		marginLeft: 10,
		marginBottom: 10
	},
	ligneText: {
		marginBottom: 20
	},
	important: {
		fontWeight: 'bold',
		marginBottom: 8
	},
	italique: {
		fontStyle: 'italic',
		marginBottom: 20
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const CultureForm = ({ resetItkForm, cultureValue, cultures, classes }) => {

	// Information sur les éléments sélectionnés par autocomplete
	const cultureSelected = cultures && cultureValue && cultures.find(culture => culture.id === cultureValue)

	return (
		<React.Fragment>
			<Grid className={classes.titleField}>
				<span className={classes.icon}><CultureIcon /></span>
				<FormattedMessage id={`${formattedMessageMenuBase}.culture.title`} />
			</Grid>
			<Grid>
				<Field
					name={fields.CULTURE}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageMenuBase}.culture.labelField`} />}
					options={getAutocompleteOptions(cultures)}
					onChange={() => resetItkForm(fields.CULTURE)}
				/>
			</Grid>
			{cultureSelected &&
			<Typography variant="body1" component="div" className={classes.section}>
				<Grid className={classes.blocText}>
					<Grid
						className={classes.important}><InfoCulture culture={cultureSelected} /></Grid>
					<Grid className={classes.italique}> {cultureSelected.cultivar} </Grid>
					{cultureSelected.commentaire && <Grid className={classes.ligneText}>
						<Comment comment={cultureSelected.commentaire} maxLines={MAX_LINES} />
					</Grid>}
				</Grid>
			</Typography>}
		</React.Fragment>
	)
}

CultureForm.propTypes = {
	resetItkForm: PropTypes.func,
	cultures: PropTypes.array
}

const FORM_NAME = 'NewCultureForm'
const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => ({
	cultureValue: selector(state, fields.CULTURE)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(CultureForm)
