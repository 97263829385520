import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, Field, FieldArray, formValueSelector } from 'redux-form'
import DateInput from '../../../../../../components/form/DateInput'
import Radio from '../../../../../../components/form/Radio'
import { TYPE_DATE } from '../../../../../../utils/constants'
import { fields, NEW_CULTURE_GROUP_FORM_NAME } from '../NouveauGroupeDeCulture'
import Typography from '@material-ui/core/Typography'
import { getJanuaryInCampain, normalizeDate } from '../../../../../../utils/utils'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import CultureFieldArray from './CultureFieldArray'

const styles = () => getStyles({
	radioText: {
		marginBottom: 10,
		marginLeft: 10
	},
	radio: {
		width: '80%'
	},
	cultures: {
		marginTop: 20,
		fontSize: 14,
		marginLeft: 10,
		marginBottom: 10
	}
})

const formattedMessageBase = 'planning.cultureGroup.addGroup'

const choices = [
	{
		value: TYPE_DATE.DEBUT,
		label: <FormattedMessage id={`${formattedMessageBase}.dates.debut`} />
	},
	{
		value: TYPE_DATE.FIN,
		label: <FormattedMessage id={`${formattedMessageBase}.dates.fin`} />
	}
]

const ItineraireGroupeForm = ({ groupe, choixDate, change, classes, campagne }) => {

	React.useEffect(() => {
		change(NEW_CULTURE_GROUP_FORM_NAME, fields.CHOIX_DATE, TYPE_DATE.DEBUT)
	}, [])

	const year = React.useMemo(() => getJanuaryInCampain(campagne).year(), [campagne])

	return (
		<React.Fragment>
			<Grid>
				<Grid>
					<Grid className={classes.radioText}>
						<Typography variant="body1">
							<FormattedMessage id={`${formattedMessageBase}.dates.text`} />
						</Typography>
					</Grid>
					<Field
						name={fields.CHOIX_DATE}
						component={Radio}
						label=""
						choices={choices}
						row
						style={{ justifyContent: 'space-between' }}
						containerClass={classes.radio}
					/>
				</Grid>
				<Grid>
					{choixDate === TYPE_DATE.DEBUT && <Field
						name={fields.DATE}
						component={DateInput}
						format={normalizeDate}
						type="date"
						style={{ width: '48%', marginRight: '50%' }}
					/>}
					{choixDate === TYPE_DATE.FIN && <Field
						name={fields.DATE}
						component={DateInput}
						format={normalizeDate}
						type="date"
						style={{ width: '48%', marginLeft: '52%' }}
					/>}
				</Grid>
				<Grid className={classes.cultures}>
					<FieldArray
						name={fields.INFOS_ASSOLEMENT}
						component={CultureFieldArray}
						groupesAssolement={groupe.groupesAssolement}
						timelineDebut={groupe.timelineDebut}
						typeSurface={groupe.typeSurface}
						year={year}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

ItineraireGroupeForm.propTypes = {
	groupe: PropTypes.object,
	choixDate: PropTypes.string,
	classes: PropTypes.object,
	campagne: PropTypes.object,
	change: PropTypes.func
}

const mapStateToProps = state => {
	const selector = formValueSelector(NEW_CULTURE_GROUP_FORM_NAME)
	return {
		campagne: getCurrentCampagne(state),
		choixDate: selector(state, fields.CHOIX_DATE)
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(ItineraireGroupeForm)
