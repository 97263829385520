import { getStyles } from 'isotope-client'
import { colors } from '../../../../../../utils/constants'

/**
 * Styles fot the page with a dataTable in SuiviPepiniere
 */
export const contentStyles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '12px',
		height: 'calc(100vh - 364px)'
	},
	header: {
		rowGap: '10px',
		padding: '10px 12px'
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	button: {
		'&.MuiButton-root': {
			padding: '5px 18px'
		}
	},
	filterText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: colors.text
	},
	ulContainer: {
		marginTop: 0,
		marginLeft: '-20px',
		marginBottom: 0
	},
	actionContainer: {
		columnGap: 24
	},
	dataTableContainer: {
		overflowY: 'auto',
		maxHeight: 'calc(100vh - 460px)'
	},
	dataTable: {
		'& .MuiTableSortLabel-root': {
			marginLeft: 0
		},
		'& .MuiTableCell-head': {
			textAlign: 'left'
		},
		'& .MuiTableCell-body': {
			height: 48
		},
		'& .MuiTableCell-root': {
			borderBottom: '1px solid #D6D6D6'
		}
	},
	dataTableHeader: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '600',
		color: '#000',
		textAlign: 'left'
	},
	dataTableHeaderNext: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '600',
		color: '#88888A',
		textAlign: 'left'
	},
	dataTableBoldText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '700',
		color: '#000',
		textAlign: 'left'
	},
	dataTableNormalText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000',
		textAlign: 'left'
	},
	dataTableFuturText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		color: '#88888A',
		textAlign: 'left',
		fontStyle: 'italic',
	},
	italicText: {
		fontStyle: 'italic',
	},
	cell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: 5,
		color: '#B70F0A'
	},
	datatableWarningText: {
		color: '#B70F0A'
	},
})

