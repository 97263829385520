import {Checkbox as MuiCheckbox, Grid, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import mapError from './mapError'
import {compose} from 'redux'

const styles = () => getStyles({
	checkbox: {
		padding: 0,
		paddingRight: '0.75rem'
	}
})

// Composant uniquement fonctionnel avec Redux Form, si pas dans un reduxForm voir CheckboxLabel.jsx

const Checkbox = ({label, classes, input, checked = null, useChecked = true, ...props}) => {
	const checkedProps = useChecked ? {checked: input && input.value} : {}

	return (
		<label>
			<Grid container alignItems="center">
				<Grid item xs={1}>
					{checked != null
						? <MuiCheckbox
							className={classes.checkbox}
							{...input}
							{...props}
							{...checkedProps}
							checked={checked}
						/>
						: <MuiCheckbox
							className={classes.checkbox}
							{...input}
							{...props}
							{...checkedProps}
						/>
					}
				</Grid>
				<Grid item xs>
					{label}
				</Grid>
			</Grid>
		</label>
	);
}

Checkbox.propType = {
	label: PropTypes.string
}

export default compose(
	mapError,
	withStyles(styles)
)(Checkbox)
