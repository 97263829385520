import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import {FormattedMessage} from 'react-intl'
import Grid from '@material-ui/core/Grid/index'
import React from 'react'
import {ETAT_TACHE_UPPERCASE, ETAT_TACHE_UPPERCASE_VALUES} from '../../../../utils/constants'
import TacheStatutIcon from './common/TacheStatutIcon'

const styles = () => getStyles({
	container: {
		margin: 20,
		marginBottom: 0,
		width: 550
	},
	row: {
		marginTop: 15,
		display: 'flex',
		gap: '15px'
	},
	column: {},
})

const LegendeTaches = ({classes}) => {
	return (
		<Grid className={classes.container}>
			<Grid container direction="column" justify="center" alignItems="center" className={classes.column}>
				{ETAT_TACHE_UPPERCASE_VALUES.map((etatTache, index) => (
					<Grid key={index} item direction="row" alignItems="center" className={classes.row}>
						<Grid>
							<TacheStatutIcon statut={etatTache}
											 isEnRetard={etatTache === ETAT_TACHE_UPPERCASE.EN_RETARD}
											 isLegende={true}/>
						</Grid>
						<Grid>
							<FormattedMessage id={`enums.descriptionEtatTache.${etatTache}`}/>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>)
}

export default withStyles(styles)(LegendeTaches)
