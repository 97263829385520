import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import {formatPositiveInteger} from '../../../../utils/utils'
import Checkbox from "../../../../components/form/Checkbox";
import classnames from "classnames";

const styles = theme => getStyles({
	bigField: {
		width: '100%'
	},
	field: {
		width: '50%',
	},
	row: {
		marginBottom: 15
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	checkbox: {
		paddingLeft: 15,
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Lato'
	},
	text: {
		paddingLeft: 10,
		paddingBottom: 10,
		fontSize: 13,
		fontFamily: 'Lato'
	}
})

const formattedMessageBase = 'bo.familleEspece.form'

const FIELDS = {
	CODE_ESPECE: { name: 'codeEspece' },
	NAME: { name: 'name' },
	ROTATION_MINIMUM: { name: 'rotationMinimum' },
	SCORE_GOURMANDISE: { name: 'scoreGourmandise' },
	FILET_INSECTES: { name: 'filetInsectes', optional: true },
	ASPERSION_INTERDITE: { name: 'aspersionInterdite', optional: true },
	TUTEUREE: { name: 'tuteuree', optional: true },
	EXCLUSION_ROTATION: { name: 'exclusionRotation', optional: true },
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})

	return errors
}

const EspeceForm = ({
	classes,
	handleSubmit
}) => {

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.SCORE_GOURMANDISE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.SCORE_GOURMANDISE.name}`} />}
						fromBo
						type="number"
						format={formatPositiveInteger}
						inputProps={{ min: 0 }}
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.ROTATION_MINIMUM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.ROTATION_MINIMUM.name}`} />}
						fromBo
						type="number"
						format={formatPositiveInteger}
						inputProps={{ min: 0 }}
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.ROTATION_MINIMUM.name}Adornment`} />}
					/>
				</Grid>
				<Grid container className={classes.checkbox}>
					<Grid item className={classnames(classes.field)}>
						<Field
							name={FIELDS.TUTEUREE.name}
							component={Checkbox}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.TUTEUREE.name}`} />}
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.FILET_INSECTES.name}
							component={Checkbox}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.FILET_INSECTES.name}`} />}
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.ASPERSION_INTERDITE.name}
							component={Checkbox}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.ASPERSION_INTERDITE.name}`} />}
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.EXCLUSION_ROTATION.name}
							component={Checkbox}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.espece.${FIELDS.EXCLUSION_ROTATION.name}`} />}
						/>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

EspeceForm.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	classes: PropTypes.object,
}

const mapStateToProps = (state, { formName }) => {
	return {
		form: formName,
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(EspeceForm)
