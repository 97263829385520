import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { DATATABLE_SORT_ORDER, ETAPE_TYPE } from '../../../../../../utils/constants'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Title from '../../layout/Title'
import { BLOCK_ACTION_TYPE } from '../tool/suiviParBloc.constants'
import Button from '../../../../../../components/Button'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import AddCommentIccon from '@material-ui/icons/AddComment'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import NoResultPage from '../../../../../../components/NoResultPage'
import DataTableWithLoader from '../../../../../../components/layout/DataTableWithLoader'
import { getInProgressCrops } from '../service/suiviParBlocApi'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import { localCompare, toI18N } from '../../../../../../utils/utils'
import { Chat } from '@material-ui/icons'
import { injectReminderForm } from '../../../service/reminderFormInjector'
import { ACTIONS_KEY, TOUR_PLAINE_TABS, CROP_DATATABLE_KEYS, EMPTY_PLACEHOLDER } from '../../../tool/tourPlaine.constants'
import { injectActionsForm } from '../../../service/actionsFormInjector'
import { contentStyles } from '../tool/suiviParBloc.styles'
import { inProgressCropsToApp } from '../../../tool/tourPlaine.mapper'
import { formatDateToWeek } from '../../../tool/tourPlaine.utils'
import classnames from 'classnames'
import { colors } from '../../../../../../utils/constants'

/**
 * CropsContent for SuiviParBloc
 * @returns {JSX.Element}
 */
const CropsContent = ({ classes, block, openReminderForm, getActions }) => {
	const { formatMessage } = useIntl()
	const { currentWeek } = useTourPlaineContext()
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [sort, setSort] = useState({ key: CROP_DATATABLE_KEYS.BOARD, order: DATATABLE_SORT_ORDER.ASC })
	const [selectedRows, setSelectedRows] = useState([])

	// Call Api
	const loadData = useCallback((shouldUnselect) => {
		if (shouldUnselect) {
			setSelectedRows([])
		}

		if (block.uniqueId) {
			setIsLoading(true)
			getInProgressCrops({
				blocId: block.uniqueId,
				dateDebut: currentWeek
			})
				.then(data => setData(inProgressCropsToApp(data)))
				.finally(() => setIsLoading(false))
		}
	}, [block.uniqueId])

	const headers = useMemo(() => [
		{
			key: CROP_DATATABLE_KEYS.BOARD,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.BOARD}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 80
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.BOARD ? sort.order : undefined,
			checked: true,
			render: row => (
				<Typography className={classes.dataTableBoldText}>
					{row.board.name}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.CROP,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.CROP}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.CROP ? sort.order : undefined,
			render: row => (
				<div className={classes.cell}>
					{(row.crop.comment && row.crop.comment.length) ? <Chat style={{ color: colors.warning }} /> : null}
					<Typography className={classes.dataTableNormalText}>
						{row.crop.name || EMPTY_PLACEHOLDER}
					</Typography>
				</div>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.STEP,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.STEP}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.STEP ? sort.order : undefined,
			render: row => {
				const stepType = Object.keys(ETAPE_TYPE).find(key => key === row.step)
				return (
					<Typography className={classnames(classes.dataTableNormalText, { [classes.italicText]: ETAPE_TYPE[stepType] === ETAPE_TYPE.CROISSANCE })}>
						{stepType && <FormattedMessage id={`enums.etape.${ETAPE_TYPE[stepType]}`} />}
					</Typography>
				)
			}
		},
		{
			key: CROP_DATATABLE_KEYS.START_HARVEST,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.START_HARVEST}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.START_HARVEST ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{toI18N(formatDateToWeek(row.startHarvestDate))}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.END_HARVEST,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.END_HARVEST}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.END_HARVEST ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{toI18N(formatDateToWeek(row.endHarvestDate))}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.NEXT,
			name: <Typography className={classes.dataTableHeaderNext}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.NEXT}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.NEXT ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableBoardNext}>
					{row.currentOrNextCrop?.name || EMPTY_PLACEHOLDER}
				</Typography>
			)
		},
		{
			key: CROP_DATATABLE_KEYS.PLANNED_PREP_DATE,
			name: <Typography className={classes.dataTableHeaderNext}><FormattedMessage id={`tourPlaine.suiviParBloc.crops.table.headers.${CROP_DATATABLE_KEYS.PLANNED_PREP_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 140
			},
			sortable: true,
			sorted: sort.key === CROP_DATATABLE_KEYS.PLANNED_PREP_DATE ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableBoardNext}>
					{toI18N(formatDateToWeek(row.plannedPrepDate))}
				</Typography>
			)
		}], [data, sort])

	const getDisplayData = useMemo(() => {
		const desc = sort.order === DATATABLE_SORT_ORDER.DESC
		if (sort.key === CROP_DATATABLE_KEYS.CROP) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.crop.name.localeCompare(element2.crop.name))
		} else if (sort.key === CROP_DATATABLE_KEYS.BOARD) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.board.name.localeCompare(element2.board.name))
		} else if (sort.key === CROP_DATATABLE_KEYS.NEXT) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * (element1.currentOrNextCrop?.name || EMPTY_PLACEHOLDER).localeCompare((element2.currentOrNextCrop?.name || EMPTY_PLACEHOLDER)))
		}

		return data.sort(localCompare(sort.key, desc))
	}, [sort, data])

	const cropActions = useMemo(() => getActions([
				ACTIONS_KEY.DETAIL_CROP,
				ACTIONS_KEY.MOVE_TO_HARVEST_STEP,
				ACTIONS_KEY.MOVE_TO_IMPLANT_STEP,
				ACTIONS_KEY.ADD_PROD_TRACKING,
				ACTIONS_KEY.ADJUST_DURATION,
				ACTIONS_KEY.DELETE_CROP
			],
			selectedRows,
			TOUR_PLAINE_TABS.SUIVI_BLOC,
			loadData),
		[selectedRows])
	const currentOrNextCropActions = useMemo(() => getActions([
				ACTIONS_KEY.DETAIL_CROP_CURRENT_OR_NEXT,
				ACTIONS_KEY.MOVE_BOARD_STEP,
				ACTIONS_KEY.DELETE_CROP_CURRENT_OR_NEXT
			],
			selectedRows,
			TOUR_PLAINE_TABS.SUIVI_BLOC,
			loadData),
		[selectedRows])

	/**
	 * UseEffect
	 */
	useEffect(() => {
		loadData()
	}, [loadData])

	return (
		<div className={classes.root}>
			<Grid item container className={classes.header}>
				<Title actionBlock={BLOCK_ACTION_TYPE.CROPS} />
				<Grid item container direction="row" justify="flex-start" alignItems="flex-end">
					<Grid item>
						<Typography className={classes.descriptionText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.crops.description" />
						</Typography>
						<ul className={classes.ulContainer}>
							<li>
								<Typography className={classes.descriptionText}>
									<FormattedMessage id="tourPlaine.suiviParBloc.crops.description1" />
								</Typography>
							</li>
							<li>
								<Typography className={classes.descriptionText}>
									<FormattedMessage id="tourPlaine.suiviParBloc.crops.description2" />
								</Typography>
							</li>
						</ul>
					</Grid>
					<Grid item container xs direction="row" justify="flex-end" alignItems="flex-end" className={classes.actionContainer}>
						<Button
							type="secondary"
							startIcon={<AddCommentIccon />}
							onClick={() => openReminderForm(`${formatMessage({ id: 'tourPlaine.tabBar.suiviBlock' })} / ${formatMessage({ id: 'tourPlaine.suiviParBloc.actionTab.crops' })}`)}
						>
							<FormattedMessage id="tourPlaine.actions.addReminder" />
						</Button>
						<ButtonWithMenu
							type="primary"
							startIcon={<MoreHorizIcon />}
							items={[
								{
									label: <FormattedMessage id="tourPlaine.actions.cultureCrop.suiviParBloc" />,
									isTitle: true
								},
								...cropActions,
								{
									isDivider: true
								},
								{
									label: <FormattedMessage id="tourPlaine.actions.nextCrop.suiviParBloc" />,
									isTitle: true
								},
								...currentOrNextCropActions
							]}
							disabled={!selectedRows.length}
						>
							<FormattedMessage id="tourPlaine.actions.others" />
						</ButtonWithMenu>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container className={classes.dataTableContainer}>
				<DataTableWithLoader
					nom="SuiviParBloc_CropsContent"
					tableProps={{
						stickyHeader: true,
						className: classes.dataTable
					}}
					data={getDisplayData}
					loading={isLoading}
					noResultFragment={<NoResultPage emptyStateMessageId="tourPlaine.suiviParBloc.crops.table.empty" />}
					totalElements={data.length}
					selectable
					displaySelectAll
					headers={headers}
					loaderStyle={{ width: '100%', paddingLeft: '0%', paddingRight: '0%' }}
					noPagination
					onFilterChange={key => {
						setSort(prev => {
							if (prev.key === key) {
								return ({
									...prev,
									order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
								})
							}
							return ({
								key,
								order: DATATABLE_SORT_ORDER.ASC
							})
						})
					}}
					onRowClick={row => setSelectedRows(prev => {
						if (prev.find(p => p.id === row.id)) {
							return prev.filter(p => p.id !== row.id)
						}

						return [
							...prev,
							row
						]
					})}
					selectRows={() => {
						selectedRows.length === data.length ? setSelectedRows([]) : setSelectedRows([...data])
					}}
					selectedRows={selectedRows}
				/>
			</Grid>
		</div>
	)
}

CropsContent.propTypes = {
	classes: PropTypes.object,
	block: PropTypes.object,
	openReminderForm: PropTypes.func,
	getActions: PropTypes.func
}

export default compose(
	injectReminderForm,
	injectActionsForm,
	withStyles(contentStyles)
)(CropsContent)