import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Check from '@material-ui/icons/Check'

const useStyles = makeStyles(theme => ({
	root: {
		width: 18,
		height: 18,
		margin: 'auto',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.main
	},
	icon: {
		fill: theme.palette.common.white,
		height: 18,
		width: 18
	}
}))
const AffectationIcon = () => {
	const classes = useStyles()
	return <div className={classes.root}>
		<Check className={classes.icon} />
	</div>
}

export default AffectationIcon