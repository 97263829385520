import { FILTER_PLANNING_FIELDS } from '../modules/fo/planning/component/form/FilterPlanning'

export const isAssolementVisible = (assolement, values) => {
	if(!values){
		return true
	}
	if(!assolement){
		return false
	}
	const {planche, culture} = assolement

	return checkList(values, planche, FILTER_PLANNING_FIELDS.SURFACE)
	&& checkList(values, culture, FILTER_PLANNING_FIELDS.CULTURE)
	&& checkList(values, culture, FILTER_PLANNING_FIELDS.ESPECE)
	&& checkList(values, culture, FILTER_PLANNING_FIELDS.FAMILLE)
	&& checkList(values, planche, FILTER_PLANNING_FIELDS.BLOC)
	&& checkList(values, planche, FILTER_PLANNING_FIELDS.ZONE)
}

const checkList = (values, obj, field) => {
	const value = values[field.name]
	if(!value || !value.length){
		return true
	}
	if(!obj){
		return false
	}
	return value.indexOf(obj[field.propName]) !== -1
}
