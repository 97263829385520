import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import FormButton from '../../../../../../components/form/FormButton'
import PestIcon from '../../../../../../components/icon/PestIcon'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import BioAggressorsAddForm from '../component/bioAggressors/BioAggressorsAddForm'
import BioAggressorsUpdateForm from '../component/bioAggressors/BioAggressorsUpdateForm'
import { BIO_AGGRESSOR_ADD_FORM_NAME, BIO_AGGRESSOR_UPDATE_FORM_NAME } from '../tool/suiviParBloc.constants'
import { addBioAggressors, updateBioAggressors } from './suiviParBlocApi'
import { addBioAggressorsToApi, updateBioAggressorsToApi } from './suiviParBlocApiMapper'

/**
 * Inject the Weather Update Form
 */
export const injectBioAggressorsAddForm = (WrappedComponent) => {

	const OpenBioAggressorsAddFormInjector = ({ getNotifications, ...props }) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)
		const { bioAggressors, loadSuiviBlockNotifs } = useTourPlaineContext()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const addFormAction = [
			<FormButton type="primary" formName={BIO_AGGRESSOR_ADD_FORM_NAME}>
				<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.submit" />
			</FormButton>
		]

		const openBioAggressorsAddForm = useCallback((block, boards, onSave) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.suiviParBloc.bio.panel.title.add' },
				<BioAggressorsAddForm
					boards={boards}
					bioAggressors={bioAggressors}
					onSubmit={values => {
						const data = addBioAggressorsToApi(values)
						addBioAggressors(data)
							.then(() => {
								loadSuiviBlockNotifs(block.uniqueId)
								snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								closePanel()
								getNotifications()
								refreshSolverInfosForCurrentCampagne()
								onSave()
							})
							.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />))
					}} />,
				addFormAction,
				<PestIcon height={24} width={24} />
			)
		}, [bioAggressors, loadSuiviBlockNotifs])

		const updateFormAction = [
			<FormButton type="primary" formName={BIO_AGGRESSOR_UPDATE_FORM_NAME}>
				<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.submit" />
			</FormButton>
		]

		const openBioAggressorsUpdateForm = useCallback((block, boards, onSave) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.suiviParBloc.bio.panel.title.update' },
				<BioAggressorsUpdateForm
					boards={boards}
					bioAggressors={bioAggressors}
					onSubmit={values => {
						const data = updateBioAggressorsToApi(values)
						updateBioAggressors(data)
							.then(() => {
								loadSuiviBlockNotifs(block.uniqueId)
								snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								closePanel()
								getNotifications()
								refreshSolverInfosForCurrentCampagne()
								onSave()
							})
							.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />))
					}}
				/>,
				updateFormAction,
				<PestIcon height={24} width={24} />
			)
		}, [bioAggressors, loadSuiviBlockNotifs])

		return <WrappedComponent
			{...props}
			openBioAggressorsAddForm={openBioAggressorsAddForm}
			openBioAggressorsUpdateForm={openBioAggressorsUpdateForm}
		/>
	}

	const actions = {
		getNotifications
	}

	return connect(null, actions)(OpenBioAggressorsAddFormInjector)
}
