import React from 'react'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../GestionRessourceProvider'
import {Grid, useMediaQuery, withStyles} from '@material-ui/core'
import {AJOUT_RESSOURCE_FORM, colors, ONGLET_GESTION_DES_RESSOURCES} from '../../../../utils/constants'
import {reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {getStyles} from 'isotope-client'
import Button from '../../../../components/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import FormButton from '../../../../components/form/FormButton'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {connect} from 'react-redux'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import Informations from './onglets/Informations';
import Contrats from './onglets/Contrats';
import Competences from './onglets/Competences';
import Absences from './onglets/Absences';

const styles = () => getStyles({
	container: {
		fontFamily: 'Lato',
	},
	icon: {
		height: 24,
		width: 24
	},
	inputs: {
		marginLeft: 20
	},
	textEdit: {
		color: colors.error,
		marginRight: 20
	},
	continueButtons: {
		marginTop: 40,
		marginLeft: '-20%'
	},
	saveButtons: {
		marginTop: 40,
		marginLeft: '-15%'
	}
})

const FormRessource = ({
						   classes,
						   handleSubmit,
						   invalid,
						   initialValues,
						   pristine,
						   reset,
					   }) => {
	const {setOngletGestion, ongletGestion, setRessource} = useGestionRessourceContext()
	const isTablette = useMediaQuery('(min-width: 0px) and (max-width: 1279px)');

	React.useEffect(() => {
		if (initialValues) {
			setRessource(initialValues)
		}
	}, [initialValues])

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.container} style={{marginTop: initialValues ? 50 : 120, marginLeft: initialValues ? (isTablette ? '15%' : '10%') : 130}} justify={initialValues ? "flex-start" : "center"} direction={initialValues ? "row" : "column"}>
				{ongletGestion === ONGLET_GESTION_DES_RESSOURCES.INFORMATIONS && <Informations initialValues={initialValues}/>}
				{ongletGestion === ONGLET_GESTION_DES_RESSOURCES.CONTRAT && <Contrats initialValues={initialValues}/>}
				{ongletGestion === ONGLET_GESTION_DES_RESSOURCES.COMPETENCES && <Competences initialValues={initialValues}/>}
				{ongletGestion === ONGLET_GESTION_DES_RESSOURCES.ABSENCES && <Absences initialValues={initialValues}/>}
				{initialValues && (ongletGestion !== ONGLET_GESTION_DES_RESSOURCES.ABSENCES)
					? <Grid container justify="flex-end" alignItems={"center"} className={classes.saveButtons} direction={"row"}>
						{!pristine && (
							<Grid item className={classes.textEdit}>
								<FormattedMessage id={`gestionRessource.detailRessource.modification`}/>
							</Grid>
						)}
						<Button
							startIcon={<CancelIcon style={{transform: 'scaleX(-1)'}}/>}
							variant="outlined"
							color="primary"
							disabled={pristine}
							onClick={reset}
						>
							<FormattedMessage id={`actions.cancel`}/>
						</Button>
						<Button
							startIcon={<SaveIcon/>}
							variant="contained"
							color="primary"
							disabled={invalid || pristine}
							onClick={handleSubmit}
							className={classes.inputs}
						>
							<FormattedMessage id={`actions.save`}/>
						</Button>
					</Grid>
					: ongletGestion !== ONGLET_GESTION_DES_RESSOURCES.ABSENCES && <Grid container justify="flex-end" className={classes.continueButtons}>
					{ongletGestion.order !== 0 && (<Button
						startIcon={<ArrowForwardIcon style={{transform: 'scaleX(-1)'}}/>}
						variant="outlined"
						color="primary"
						onClick={() => setOngletGestion(Object.values(ONGLET_GESTION_DES_RESSOURCES).find(item => item.order === ongletGestion.order - 1))}
					>
						<FormattedMessage id={`actions.back`}/>
					</Button>)
					}
					{ongletGestion.order !== 2 ? (<Button
								startIcon={<ArrowForwardIcon/>}
								variant="contained"
								color="primary"
								disabled={invalid}
								onClick={() => setOngletGestion(Object.values(ONGLET_GESTION_DES_RESSOURCES).find(item => item.order === ongletGestion.order + 1))}
								className={classes.inputs}
							>
								<FormattedMessage id={`actions.continue`}/>
							</Button>
						)
						:
						(
							<FormButton className={classes.inputs} startIcon={<PersonAddIcon/>} type="primary" formName={AJOUT_RESSOURCE_FORM}>
								<FormattedMessage id="actions.add"/>
							</FormButton>
						)
					}
				</Grid>
				}
			</Grid>
		</form>
	)
}


FormRessource.propType = {
	classes: PropTypes.object,
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	invalid: PropTypes.bool.isRequired,
	pristine: PropTypes.bool
}

const mapStateToProps = () => {
	return {
		form: AJOUT_RESSOURCE_FORM,
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		enableReinitialize: false
	}),
	withStyles(styles),
)(FormRessource)
