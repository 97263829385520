import React from 'react'
import Grid from '@material-ui/core/Grid/index'
import StatBloc from '../../../../common/stat/StatBloc'
import {ReactComponent as Gauge} from '../../../../../icons/gauge.svg'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import GroupIcon from '@material-ui/icons/Group'
import ListIcon from '@material-ui/icons/List'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import {colors} from '../../../../../utils/constants'
import {useTheme} from '@material-ui/core'

const Stats = ({datas}) => {
	const theme = useTheme()

	const getPrimaryColor = () => {
		return theme.palette.primary.main
	}

	const getPourcentageCouleur = (pourcentage) => {
		if (pourcentage < 80) {
			return colors.error
		} else {
			return getPrimaryColor()
		}
	}

	return (
		<>
			<Grid container item spacing={2}>
				<Grid container item justify="flex-end" xs={6}>
					<StatBloc
						Icon={Gauge}
						getPourcentageCouleur={getPrimaryColor}
						idMessage="gestionTaches.ecranSynthese.stats.capaciteTotale"
						value={datas.capaciteTotale}
						unite="h"
					/>
				</Grid>
				<Grid container item justify="flex-start" xs={6}>
					<StatBloc
						Icon={TimelapseIcon}
						getPourcentageCouleur={getPrimaryColor}
						idMessage="gestionTaches.ecranSynthese.stats.chargeTotale"
						value={datas.chargeTotale}
						unite="h"
					/>
				</Grid>
			</Grid>
			<Grid container item spacing={2}>
				<Grid container item justify="flex-end" xs={6}>
					<StatBloc
						Icon={GroupIcon}
						getPourcentageCouleur={getPrimaryColor}
						idMessage="gestionTaches.ecranSynthese.stats.ressourcesDispo"
						value={datas.ressourcesDispo}
					/>
				</Grid>
				<Grid container item justify="flex-start" xs={6}>
					<StatBloc
						Icon={HowToRegIcon}
						getPourcentageCouleur={getPourcentageCouleur}
						idMessage="gestionTaches.ecranSynthese.stats.ressourcesPlanifiees"
						value={datas.ressourcesPlanifiees}
						isPourcentage
					/>
				</Grid>
			</Grid>
			<Grid container item spacing={2}>
				<Grid container item justify="flex-end" xs={6}>
					<StatBloc
						Icon={ListIcon}
						getPourcentageCouleur={getPrimaryColor}
						idMessage="gestionTaches.ecranSynthese.stats.tachesPrevues"
						value={datas.tachesPrevues}
					/>
				</Grid>
				<Grid container item justify="flex-start" xs={6}>
					<StatBloc
						Icon={PlaylistAddCheckIcon}
						getPourcentageCouleur={getPourcentageCouleur}
						idMessage="gestionTaches.ecranSynthese.stats.tachesPlanifiees"
						value={datas.tachesPlanifieesValeur}
						secondaryValue={datas.tachesPlanifieesPourcentage}
					/>
				</Grid>
			</Grid>
		</>
	)

}

export default Stats