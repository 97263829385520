import { createSelector } from 'reselect'
import { DEFAULT_LANGUAGE_ID, USER_PREFERENCES } from '../../../../utils/constants'

export const getUser = state => state.user

export const getAuthorities = createSelector(
	getUser,
	user => user.authorities
)

export const getPreferences = createSelector(
	getUser,
	user => user.preferences || {}
)

export const getUserLanguage = createSelector(
	getPreferences,
	preferences => preferences[USER_PREFERENCES.LANGUE] || DEFAULT_LANGUAGE_ID
)
