import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isInvalid, SubmissionError } from 'redux-form'
import FormButton from '../../../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { CHOIX_TYPE_MODIFICATION } from '../../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import ModifierEtapeRecolte, { MODIFICATION_ETAPE_RECOLTE } from '../../../component/form/AllongerReduireEtapeRecolte'
import { loadAssolement } from '../../assolement/assolementInjector'
import { modifierEtapeRecolteApi } from '../../planningApi'
import { resetFilter } from '../../toolbar/toolbarAction'

export const injectModifierEtapeRecolte = (WrappedComponent) => {
	const ModifierEtapeRecolteInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { campagne, resetFilter, refreshAssolements, getNotifications } = props
		const { snackError, snackSuccess } = useSnackbar()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const modifierEtapeRecolte = (assolement) => {

			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]
			const assolementsId = selectedAssolements.map((assolement) => assolement.id)


			//TODO -- RG_PLALIST_103 : “Allonger / réduire les étapes Récolte”  :
			//TODO -- Grisé et non sélectionnable si au moins une des cultures sélectionnées a été marquée comme prête à être récoltée

			// TODO cf thomas msg succes snackbar
			//TODO snackbar FONT BLACK on error ?

			const onSubmit = (values) => {
				const data = {
					assolementsId,
					pointDepartModification: values.pointDepartModification,
					typeDeModification: values.typeDeModification,
					nbSemainesAllongement: (values.typeDeModification === CHOIX_TYPE_MODIFICATION.ALLONGER) ? values.nbSemainesAllongement : null,
					nbSemainesReduction: (values.typeDeModification === CHOIX_TYPE_MODIFICATION.REDUIRE) ? values.nbSemainesReduction : null
				}

				return modifierEtapeRecolteApi(data)
					.then(() => {
						closePanel()
						snackSuccess({ id: 'listAssolement.snackbar.ok.modifierEtapeRecolte' })
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
						resetFilter()
						refreshAssolements()
					})
					.catch((e) => {
						snackError({ id: 'listAssolement.snackbar.error.modifierEtapeRecolte' })
						throw new SubmissionError(e)
					})
			}

			openPanel(
				<FormattedMessage id="listAssolement.forms.headers.modifEtapeRecolte" />,
				<ModifierEtapeRecolte
					selectedAssolements={selectedAssolements}
					onSubmit={(values) => onSubmit(values)} />,
				[<FormButton
					type="primary"
					formName={MODIFICATION_ETAPE_RECOLTE}
					getDisabledFromState={state => isInvalid(MODIFICATION_ETAPE_RECOLTE)(state)}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>]
			)
		}
		return <WrappedComponent {...props} modifierEtapeRecolte={React.useCallback(modifierEtapeRecolte, [campagne])} />
	}

	ModifierEtapeRecolteInjector.propTypes = {
		campagne: PropTypes.object,
		resetFilter: PropTypes.func,
		refreshAssolements: PropTypes.func
	}

	const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state)
	})

	const actions = {
		resetFilter,
		getNotifications
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(ModifierEtapeRecolteInjector)
}
