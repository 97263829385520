import React from 'react'
import {dataTableActions} from 'isotope-client'
import NoResultPage from '../../../../../components/NoResultPage'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import moment from 'moment'
import {JOURS, NUMERO_JOURS_SEMAINE} from '../../utils/constants'
import {colors, NOM_LISTE_RESSOURCES} from '../../../../../utils/constants'
import {compose} from 'redux'
import CellEditable from '../common/CellEditable'
import {formatDuration} from '../../../../../utils/utils'
import classNames from 'classnames'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import {makeStyles} from '@material-ui/styles'
import SpringDataTableWithLoader from '../../../../../components/layout/SpringDataTableWithLoader'
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
	centeredCell: {
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	duration: {
		width: '10%',
		height: 40,
		textAlign: 'center',
		borderRight: 'solid 1px lightgrey',
		fontSize: 14,
		'&[class*="MuiTableCell-root"]': {
			padding: 10
		}
	},
	ressources: {
		width: '20%',
		height: 40,
		borderLeft: 'solid 1px lightgrey',
		borderRight: 'solid 1px lightgrey',
		fontSize: 14
	},
	header: {
		fontSize: 14,
		marginLeft: 0
	},
	headerInfoDebut:{
		fontSize: 10,
		color: colors.commentHeader,
		paddingLeft: 10
	},
	headerInfoTemps:{
		fontSize: 10,
		color: colors.commentHeader,
		paddingRight: 3
	},
	/** Pour la popin  */
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	},
	redText: {
		color: colors.error
	},
	table: {
		borderCollapse: 'collapse',
		'& [class*="MuiTableCell-root"]': {
			height: 40,
			borderLeft: `1px solid ${theme.palette.border}`,
			borderRight: `1px solid ${theme.palette.border}`
		},
		'& [class*="MuiTableRow-head"]': {
			backgroundColor: theme.palette.primary.light
		},
		'& [class*="MuiTableCell-head"]': {
			padding: '0 5px',
			lineHeight: '1.3',
			backgroundColor: theme.palette.primary.light
		},
		'& [class*="MuiTableCell-body"]:first-child': {
			padding: '0 5px',
			textAlign: 'center'
		}
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		minWidth: 1165,
		maxWidth: '100%',
		overflow: 'auto'
	},
	tempsDeTravail : {
		fontWeight: 700
	}
}))

const RessourcesTable = ({ reference }) => {
	const { semaineSelection, selectedContrats, isResultat } = useGestionTacheContext()
	const classes = useStyles()

	const dynamicFilters = { semaine: moment(semaineSelection).format('YYYY-MM-DD'), contrats: [...selectedContrats] };

	const header = (jour) => {
		return (
			<Grid container item direction={"column"}>
				<Grid item>
					<label className={classes.header}>
						<FormattedMessage id={`gestionTaches.ecranRessources.table.headers.${jour}`}/>
					</label>
				</Grid>
				<Grid container item justify={"space-between"} alignItems={"center"}>
					<Grid container item xs={5} justify={"center"}>
						<label className={classes.headerInfoDebut}>
							<FormattedMessage id="gestionTaches.ecranRessources.table.headers.debut"/>
						</label>
					</Grid>
					<Grid container item xs={7} justify={"center"}>
						<label className={classes.headerInfoTemps}>
							<FormattedMessage id="gestionTaches.ecranRessources.table.headers.tempsTravail"/>
						</label>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	const rowData = (row, jour, name) => {
		return (
			<Grid container item direction="row">
				<Grid item xs={6}>
					<CellEditable
						row={row}
						jour={jour}
						duration={formatDuration(row?.[`${name}Debut`], 'hh:mm:ss')}
						isDebut
						disabled={isResultat}
					/>
				</Grid>
				<Grid item xs={6}>
					<CellEditable
						row={row}
						jour={jour}
						duration={formatDuration(row[name], 'hh:mm:ss')}
						disabled={isResultat}
					/>
				</Grid>
			</Grid>
		)
	}

	const Table = React.forwardRef((props, ref) => {
		return <div className={classes.leftContainer} ref={ref}>
			<SpringDataTableWithLoader
				nom="PlanificationTacheRessources"
				showBorders={false}
				apiUrl="/taches/ressources"
				defaultPageSize={50}
				noResultFragment={<NoResultPage />}
				filters={dynamicFilters}
				tableProps={{
					stickyHeader: true,
					className: classes.table
				}}
				headers={[
					{
						key: 'code',
						name: <label className={classes.header}><FormattedMessage
							id="gestionTaches.ecranRessources.table.headers.ressource" /></label>,
						tableHeaderColumnProps: { className: classes.ressources },
						render: row => <><b>{row.alias}</b> - {row.nom} {row.prenom}
						<Typography style={{fontStyle: 'italic'}} >
							<FormattedMessage id={`enums.roles.` + row?.user?.roles[0]?.code?.toUpperCase()} />
						</Typography>
						</>
					},
					{
						key: 'total',
						name: <label className={classes.header}>
							<FormattedMessage id="gestionTaches.ecranRessources.table.headers.total" />
						</label>,
						tableHeaderColumnProps: { className: classes.duration },
						render: row => {
							let total = moment.duration(0)
							Object.values(JOURS).forEach(jour => total = total.add(moment.duration(row[jour])))

							return <span className={classNames({ [classes.redText]: total.asHours() > 42 })}>
							{total.format('h[h]mm', { trim: false })}
						</span>
						}
					},
					{
						key: 'lundi',
						name: header('lundi'),
						tableHeaderColumnProps: {className: classes.duration},
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.LUNDI, 'lundi')
					},
					{
						key: 'mardi',
						name: header('mardi'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.MARDI, 'mardi')
					},
					{
						key: 'mercredi',
						name: header('mercredi'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.MERCREDI, 'mercredi')
					},
					{
						key: 'jeudi',
						name: header('jeudi'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.JEUDI, 'jeudi')
					},
					{
						key: 'vendredi',
						name: header('vendredi'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.VENDREDI, 'vendredi')
					},
					{
						key: 'samedi',
						name: header('samedi'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.SAMEDI, 'samedi')
					},
					{
						key: 'dimanche',
						name: header('dimanche'),
						tableHeaderColumnProps: { className: classes.duration },
						render: row => rowData(row, NUMERO_JOURS_SEMAINE.DIMANCHE, 'dimanche')
					}
				]}
			/>
		</div>
	})

	return (
		<Table ref={reference} />
	)
}

const actions = {
	refresh: dataTableActions.refresh(NOM_LISTE_RESSOURCES.LISTE_RESSOURCES)
}

export default compose(
	connect(undefined, actions)
)(RessourcesTable)
