import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { colors, DEFAULT_LABEL } from '../../../../utils/constants'
import { getPreviousCultureByPlanche } from '../services/cultureApi'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import { injectEspeceCultureValueList, injectFamilleCultureValueList } from '../../../common/valueLists/valueListInjectors'

const styles = () => getStyles({
	title: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: '1em',
		cursor: 'pointer'
	},
	toggle: {
		color: colors.primary,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 6,
		marginTop: 12,
		marginLeft: '-8px'
	},
	iconRotate: {
		fontSize: '2rem !important',
		transform: 'rotate(90deg)',
		transition: 'ease-in-out 0.1s',
		cursor: 'pointer'
	},
	icon: {
		transform: 'rotate(0)'
	},
	detail: {
		marginTop: 6,
		marginLeft: '-12px'
	}
})

const PreviousCulture = ({ campagneDateDebut, plancheId, familleCultureList, especeCultureList, classes }) => {
	const [previousFamilies, setPreviousFamilies] = React.useState([])
	const [open, setOpen] = React.useState(true)


	React.useEffect(() => {
		getPreviousCultureByPlanche(campagneDateDebut, plancheId).then(res => {
			const families = {}
			if (res) {
				res.forEach(culture => {
					const family = culture.famille
					if (!(family in families)) {
						families[family] = []
					}
					const especeName = (especeCultureList.find(espece => espece.code === culture.espece) || {}).label || DEFAULT_LABEL
					families[family].push(especeName)
				})
			}
			setPreviousFamilies(Object.keys(families).map(key => {
				const familyName = (familleCultureList.find(famille => famille.code === key) || {}).label || DEFAULT_LABEL
				return ({
					name: familyName,
					species: families[key]
				});
			}))
		})
	}, [campagneDateDebut, plancheId])

	const handleClick = () => setOpen(previousState => !previousState)

	if (!previousFamilies.length) {
		return null
	}

	return (
		<div>
			<div onClick={handleClick} className={classes.toggle}>
				<ArrowRight className={`${classes.iconRotate} ${open ? '' : classes.icon}`} />
				<Typography variant="body1" className={classes.title}><FormattedMessage id={`planning.culture.previousCampaign${open ? '' : 'Collapsed'}`} /></Typography>
			</div>
			{open && <ul className={classes.detail}>
				{previousFamilies.map(family => (
					<li key={family.name}>{family.name} : {family.species.join(', ')}</li>
				))}
			</ul>}
		</div>)
}

PreviousCulture.propType = {
	campagneDateDebut: PropTypes.date,
	plancheId: PropTypes.string,
	familleCultureList: PropTypes.array,
	especeCultureList: PropTypes.array,
	classes: PropTypes.object
}

export default compose(
	injectFamilleCultureValueList,
	injectEspeceCultureValueList,
	withStyles(styles)
)(PreviousCulture)
