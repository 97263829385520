import React from 'react'
import PropTypes from 'prop-types'

const HeatIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 512 512"
	>
		<path
			fill="#d9f2ff"
			d="M139.001 60 108.465 0C79.46 0 55.939 23.46 55.939 52.39v256.77C22.853 327.45.365 362.51.004 402.84-.521 462.517 48.273 512 108.465 512l30.536-37.534z"
		/>
		<path
			fill="#b5d9ff"
			d="M216.926 403.82c0 59.75-48.556 108.18-108.461 108.18V0c14.498 0 27.632 5.86 37.136 15.35 9.505 9.48 15.39 22.57 15.39 37.04v256.77c33.356 18.44 55.935 53.92 55.935 94.66z"
		/>
		<path
			fill="#ffd92e"
			d="M352.087 169.107c-8.821-2.623-18.159-58.387-27.823-58.387-53.799 0-97.573 43.66-97.573 97.33 0 53.66 43.773 97.32 97.573 97.32 9.664 0 19.002-56.83 27.823-59.452zM330.082 38.701l-5.818-17.911c-8.301 0-15.039 6.72-15.039 15v36.13c0 8.29 6.737 15 15.039 15l5.818-18.429z"
		/>
		<path
			fill="#fcbe00"
			d="M496.961 193.046h-36.222c-8.306 0-15.039 6.716-15.039 15s6.733 15 15.039 15h36.222c8.306 0 15.039-6.716 15.039-15s-6.733-15-15.039-15z"
		/>
		<path
			fill="#ffd92e"
			d="m328.931 344.978-4.667-15.808c-8.301 0-15.039 6.71-15.039 15v36.13c0 8.28 6.737 15 15.039 15l4.667-12.324z"
		/>
		<g fill="#fcbe00">
			<path d="M406.377 317.802c-4.208-7.142-13.423-9.529-20.586-5.331-7.161 4.197-9.554 13.39-5.346 20.531l18.353 31.147c2.802 4.756 7.823 7.402 12.98 7.402 2.589 0 5.212-.667 7.606-2.07 7.161-4.197 9.554-13.39 5.346-20.532zM481.956 279.856l-31.521-17.8c-7.227-4.083-16.405-1.547-20.496 5.662-4.092 7.21-1.55 16.362 5.678 20.443l31.521 17.8a14.999 14.999 0 0 0 7.396 1.95c5.242 0 10.334-2.738 13.101-7.612 4.091-7.209 1.549-16.361-5.679-20.443zM419.383 46.608c-7.162-4.195-16.378-1.81-20.586 5.333L380.445 83.09c-4.208 7.142-1.815 16.335 5.346 20.532a15.007 15.007 0 0 0 7.606 2.069c5.156 0 10.179-2.647 12.98-7.402l18.353-31.148c4.207-7.142 1.814-16.335-5.347-20.533z" />
		</g>
		<path
			fill="#ffd92e"
			d="M255.131 105.691a15.01 15.01 0 0 0 7.606-2.069c7.161-4.197 9.554-13.391 5.346-20.532L249.73 51.941c-4.208-7.144-13.423-9.527-20.586-5.333-7.161 4.197-9.554 13.391-5.346 20.532l18.353 31.148c2.802 4.757 7.823 7.403 12.98 7.403z"
		/>
		<path
			fill="#fcbe00"
			d="M443.04 155.984c2.51 0 5.055-.628 7.396-1.949l31.521-17.799c7.228-4.082 9.77-13.234 5.678-20.443-4.092-7.21-13.268-9.743-20.496-5.664l-31.521 17.799c-7.228 4.082-9.77 13.234-5.678 20.443 2.766 4.875 7.858 7.613 13.1 7.613z"
		/>
		<path
			fill="#ff7559"
			d="M171.809 403.82c0 34.84-28.413 63.18-63.344 63.18-34.93 0-63.344-28.34-63.344-63.18 0-29.67 20.613-54.63 48.305-61.38V75c0-8.28 6.737-15 15.039-15 8.312 0 15.039 6.72 15.039 15v267.44c27.691 6.75 48.305 31.71 48.305 61.38z"
		/>
		<path
			fill="#e63a3a"
			d="M171.809 403.82c0 34.84-28.413 63.18-63.344 63.18V60c8.312 0 15.039 6.72 15.039 15v267.44c27.691 6.75 48.305 31.71 48.305 61.38z"
		/>
		<path
			fill="#fcbe00"
			d="M324.264 86.92V20.79c8.302 0 15.039 6.72 15.039 15v36.13c0 8.29-6.737 15-15.039 15zM339.303 344.17v36.13c0 8.28-6.737 15-15.039 15v-66.13c8.302 0 15.039 6.71 15.039 15zM421.837 208.05c0 53.66-43.773 97.32-97.573 97.32V110.72c53.799 0 97.573 43.66 97.573 97.33z"
		/>
	</svg>
)


HeatIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

HeatIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default HeatIcon