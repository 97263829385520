import {Divider, Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {change, Field, FieldArray, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {BIO_AGGRESSOR_UPDATE_FIELDS, BIO_AGGRESSOR_UPDATE_FORM_NAME} from '../../tool/suiviParBloc.constants'
import {normalizeDate} from '../../../../../../../utils/utils'
import BioAggressorsBoardsInput from './BioAggressorsBoardInput'
import {groupEligibleBoardsByBioAggressor} from '../../tool/suiviParBloc.utils'
import DateFieldCustom from '../../../../../../../components/form/DateFieldCustom'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		padding: '30px 40px',
		gap: 15,
		maxHeight: 'calc(100vh - 220px)'
	},
	fieldContainer: {
		gap: 10
	},
	rowContainer: {
		columnGap: 25
	},
	label: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	boardsText: {
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: '400',
		color: '#000'
	},
	divider: {
		borderBottom: '1px #D6D6D6',
		width: '100%'
	},
	formBody: {
		maxHeight: 'calc(100vh - 300px)',
		overflowY: 'auto',
		gap: 15
	}
})

/**
 * BioAggressorsUpdateForm
 * @param handleSubmit
 * @param classes
 * @param bioAggressors
 * @param boards
 * @constructor
 */
const BioAggressorsUpdateForm = ({ handleSubmit, classes, bioAggressors, boards }) => {
	const renderFieldArray = ({ fields }) => {
		return (
			fields.map((fieldName, index, fields) => {
					const selectedBA = bioAggressors.find(ba => ba.id === fields.get(index).bioAggressor)
					const concernedBoards = fields.get(index).boards.map(concernedBoard => boards.find(board => board.id === concernedBoard.idBoard).label).join(', ')

					return (
						<Grid key={index} container className={classes.fieldContainer}>
							<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
								<Typography className={classes.label}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.concernedBoards" />
								</Typography>
								<Typography className={classes.boardsText}>
									{concernedBoards}
								</Typography>
							</Grid>
							<Grid item container alignItems="center" justify="flex-start" className={classes.rowContainer}>
								<Typography className={classes.label}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.bioAggressors" />
								</Typography>
								<Typography className={classes.boardsText}>
									{selectedBA.label}
								</Typography>
							</Grid>
							<Grid item container alignItems="center" justify="space-between">
								<Typography className={classes.label}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.period" />
								</Typography>
								<Typography className={classes.label}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.start" />
								</Typography>
								<Field
									name={`${fieldName}${BIO_AGGRESSOR_UPDATE_FIELDS.START}`}
									type="text"
									component={DateFieldCustom}
									format={normalizeDate}
									style={{ width: '35%'}}
									hideFootInfo
								/>
								<Typography className={classes.label}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.end" />
								</Typography>
								<Field
									name={`${fieldName}${BIO_AGGRESSOR_UPDATE_FIELDS.END}`}
									type="text"
									component={DateFieldCustom}
									format={normalizeDate}
									hideFootInfo
									style={{ width: '35%'}}
								/>
							</Grid>
							<Field
								name={`${fieldName}${BIO_AGGRESSOR_UPDATE_FIELDS.BIO_AGGRESSORS}`}
								component={BioAggressorsBoardsInput}
								bioAggressors={bioAggressors}
								isCollapsable
							/>
							<Divider className={classes.divider} style={{ marginTop: 10 }} />
						</Grid>
					)
				}
			)
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Grid item container alignItems="center" justify="center" className={classes.rowContainer}>
					<Typography className={classes.label}>
						<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.selectedBoards" />
					</Typography>
					<Typography className={classes.boardsText}>
						{boards.map(board => board.label).join(', ')}
					</Typography>
				</Grid>
				<Divider className={classes.divider} />
				<Grid container className={classes.formBody}>
					<FieldArray
						name={BIO_AGGRESSOR_UPDATE_FIELDS.BIO_AGGRESSORS}
						component={renderFieldArray}
					/>
				</Grid>
			</Grid>
		</form>
	)

}

BioAggressorsUpdateForm.propType = {
	boards: PropTypes.array,
	classes: PropTypes.object,
	groupedBoardsByBioAggressor: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func
}

const mapStateToProps = (state, { boards, bioAggressors, ...props }) => {
	const groupedByBioAggressorsInitialValues = groupEligibleBoardsByBioAggressor(boards, bioAggressors)
	return ({
		boards,
		bioAggressors,
		initialValues: {
			[BIO_AGGRESSOR_UPDATE_FIELDS.BIO_AGGRESSORS]: groupedByBioAggressorsInitialValues
		},
		...props
	})
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: BIO_AGGRESSOR_UPDATE_FORM_NAME,
		destroyOnUnmount: true,
		enableReinitialize: true
	}),
	withStyles(styles)
)
(BioAggressorsUpdateForm)
