import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper/index'
import IconButton from '@material-ui/core/IconButton/index'
import Grid from '@material-ui/core/Grid/index'
import {getStyles} from 'isotope-client'
import {FormattedMessage} from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography/index'
import {compose} from 'redux'
import withStyles from '@material-ui/styles/withStyles/index'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../../Button'
import {toI18N} from '../../../utils/utils'
import {DialogContext} from './context'
import {colors, TYPE_DIALOG} from '../../../utils/constants'

const styles = () => getStyles({
	closeIcon: {
		position: 'absolute',
		top: 8,
		right: 12
	},
	titleContainer: {
		height: 65
	},
	title: {
		height: 50,
		borderBottom: '2px solid #55555a',
		marginLeft: 20,
		marginRight: 20,
		columnGap: 10
	},
	content: {
		fontFamily: 'Lato',
		overflowY: 'auto',
		fontSize: 14
	},
	actions: {
		width: '100%',
		boxSizing: 'border-box'
	},
	actionButton: {
		margin: '1.25rem 0.5rem',
		padding: '0 10px 0 10px'
	}
})

const Popin = ({classes, open, fromBo, textButton}) => (
	<DialogContext.Consumer>
		{({title, content, actions, type, closeDialog, labelClose, titleIcon, isWidthLg}) => <Dialog
			open={open}
			onClose={closeDialog}
			maxWidth={isWidthLg ? 'lg' : fromBo ? 'md' : 'sm'}
			fullWidth={fromBo}
		>
			<Paper elevation={4} square={false}>
				<Grid container direction="column">
					<Grid item container>
						<Grid item container direction="row" justify="center" alignContent="flex-end" className={classes.titleContainer}>
							<Grid item className={classes.closeIcon}>
								<IconButton onClick={closeDialog}>
									<CloseIcon/>
								</IconButton>
							</Grid>
							<Grid item container justify="flex-start" alignContent="center" className={classes.title}>
								{titleIcon}
								<Typography component="h3" variant="h3">{toI18N(title)}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container className={classes.content}>
						{content}
					</Grid>
					<Grid item container justify="center" alignContent="center" className={classes.actions}>
						{type !== TYPE_DIALOG.COOKIE && <Grid item>
							<Button
								type={textButton ? '' : 'cancel'}
								variant={textButton ? 'text' : ''}
								withBorder={!textButton}
								key="cancel"
								onClick={closeDialog}
								className={classes.actionButton}
								style={{color: textButton ? colors.text : ''}}
							>
								{
									labelClose ? (
										<FormattedMessage id={`${labelClose}`}/>
									) : (
										type === TYPE_DIALOG.ACTION ? <FormattedMessage id="actions.cancel"/> : <FormattedMessage id="actions.close"/>
									)
								}
							</Button>
						</Grid>}
						{actions.map((action, index) => <Grid item key={index}>
							{React.cloneElement(action, {className: classes.actionButton})}
						</Grid>)}
					</Grid>
				</Grid>
			</Paper>
		</Dialog>}
	</DialogContext.Consumer>
)

Popin.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	fromBo: PropTypes.bool
}

export default compose(
	withStyles(styles)
)(Popin)
