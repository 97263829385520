import React from 'react'
import {Grid} from '@material-ui/core'
import HeaderAjoutRessource from './ajoutRessource/HeaderAjoutRessource'
import FormRessource from './FormRessource'
import GestionRessourceProvider from '../GestionRessourceProvider'
import DialogProvider from '../../../../components/layout/contextProvider/DialogProvider'
import {createRessource} from '../gestionRessourceApi'
import { SubmissionError } from 'redux-form'
import {daysOfWeek} from '../../../../utils/constants'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {useSnackbar} from '../../../../components/layout/snackbar/SnackbarContext'
import {replace} from 'connected-react-router'
import {formatTime} from '../utils/ressourceUtils'

const AjoutRessourceOnglet = ({ goRessourceTable }) => {
	const { snackSuccess, snackError } = useSnackbar()
	const onSubmit = (values) => {
		const { competences, ...otherValues } = values;

		daysOfWeek.forEach(day => {
			delete otherValues[`${day}Debut`];
		});

		createRessource({
			competences : values.competences && Object.keys(values.competences)
				.filter(key => values.competences[key])
				.map(id => id),
			horaire: daysOfWeek.map(day => ({
				day,
				debut: values[`${day}Debut`] != null ? formatTime(values[`${day}Debut`]) : null,
				temps: values[`${day}Temps`]
			})),
			...otherValues
		})
			.then(() => {
				goRessourceTable()
				snackSuccess({ id: 'gestionRessource.ajoutRessource.snackbar.success' })
			})
			.catch((e) => {
				snackError({ id: 'gestionRessource.ajoutRessource.snackbar.error' })
				throw new SubmissionError(e)
			})

	}

	return (
		<DialogProvider textButton>
			<GestionRessourceProvider>
				<Grid style={{ padding: 50 }}>
					<HeaderAjoutRessource />
					<FormRessource onSubmit={onSubmit} />
				</Grid>
			</GestionRessourceProvider>
		</DialogProvider>
	)

}

const actions = {
	goRessourceTable: () => dispatch => dispatch(replace('/gestion-ressources')),
}

export default compose(
	connect(null, actions))
	(AjoutRessourceOnglet)