import React from 'react'
import PropTypes from 'prop-types'
import { TACHE_TYPE_ENTITE } from '../../../../../utils/constants'
import { FormattedMessage } from 'react-intl'

const FormattedTacheLieuMessage = ({ typeLieu, lieu }) => {
	let idMessage = null
	switch (typeLieu) {
		case TACHE_TYPE_ENTITE.ETAPE:
		case TACHE_TYPE_ENTITE.ASSOLEMENT:
		case TACHE_TYPE_ENTITE.SURFACE:
			idMessage = 'gestionTaches.ecranPlanification.table.lieu.surface'
			break
		case TACHE_TYPE_ENTITE.BLOC:
			idMessage = 'gestionTaches.ecranPlanification.table.lieu.bloc'
			break
		case TACHE_TYPE_ENTITE.FERME:
			idMessage = 'gestionTaches.ecranPlanification.table.lieu.ferme'
			break
		default:
			break
	}
	if (idMessage != null) {
		return <FormattedMessage id={idMessage} values={{ nom: lieu }} />
	}
	return <></>
}
FormattedTacheLieuMessage.propTypes = {
	typeLieu: PropTypes.oneOf(Object.values(TACHE_TYPE_ENTITE)).isRequired,
	lieu: PropTypes.string.isRequired
}

export default FormattedTacheLieuMessage
