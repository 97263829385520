import {Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, reduxForm} from 'redux-form'
import {PANEL_FORM} from '../../tool/tourPlaine.constants'
import {connect} from 'react-redux'
import {LocalDate} from '../../../../../utils/date/local-date'
import {DateFormat} from '../../../../../utils/date/model/date'
import PickingIcon from "../../../../../components/icon/PickingIcon";
import {injectTypeTacheValueList} from "../../../../common/valueLists/valueListInjectors";
import Radio from "../../../../../components/form/Radio";
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag'
import FormattedMessageWithBold from "../../../../../components/FormattedMessageWithBold";

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25,

		'& .MuiFormGroup-root': {
			gap: 15,

			'& .MuiButtonBase-root': {
				alignSelf: 'start'
			}
		}
	}
})

const formattedMessageBase = 'tourPlaine.form.specifyGenericTask'

const SpecifyGenericTaskForm = ({ handleSubmit, classes, tache, modeles, typeTacheList }) => {

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid container item direction="column">
				<Grid container item direction="row" wrap="nowrap" style={{gap: 10, marginBottom: 20}}>
					<PickingIcon style={{minWidth: 25, minHeight: 25}}/>
					<Typography variant="h4">{typeTacheList.find(type => type.code === tache.tache).label} - {tache.assolement.culture.nom}</Typography>
				</Grid>
				<Typography style={{fontSize: 12, marginBottom: 5}}>
					<FormattedMessageWithBold id={`${formattedMessageBase}.date`} values={{date: LocalDate.fromAPI(tache.datePrevue).format(DateFormat.SHORT_DATE)}}/>
				</Typography>
				<Typography style={{fontSize: 12}}>
					<FormattedMessageWithBold id={`${formattedMessageBase}.surface`} values={{surface: tache.lieu}}/>
				</Typography>
				<Grid container item direction="row" wrap="nowrap" style={{gap: 10, marginBottom: 10, marginTop: 20}}>
					<OutlinedFlagIcon style={{minWidth: 25, minHeight: 25}} color="primary"/>
					<Typography variant="h4">
						<FormattedMessage id={`${formattedMessageBase}.selection`}/>
					</Typography>
				</Grid>
				<Field
					name="modele"
					component={Radio}
					choices={modeles.map(m => ({
						value: m.id,
						label: <Grid container direction="column">
							<Typography><FormattedMessageWithBold id={`${formattedMessageBase}.categorie`} values={{categorie: m.categorieTechnique.nom}}/></Typography>
							<Typography><FormattedMessageWithBold id={`${formattedMessageBase}.tempsMoy`} values={{temps: m.tempsMoyM2}}/></Typography>
							<Typography><FormattedMessageWithBold id={`${formattedMessageBase}.personnes`} values={{nbPersonnes: m.nbPersonnes, flagDivisible: m.flagDivisible}}/></Typography>
							{m.materiels && m.materiels.length > 0 && <Typography><FormattedMessage id={`${formattedMessageBase}.materiels`} values={{materiels: m.materiels.map(m => m.nom).join(', ')}}/></Typography>}
						</Grid>
					}))}
					column
				/>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, {modeles}) => ({
	initialValues: {
		modele: modeles[0].id
	}
})

export default compose(
	connect(mapStateToProps),
	injectTypeTacheValueList,
	reduxForm({
		form: PANEL_FORM.SPECIFY_GENERIC_TASK
	}),
	withStyles(styles)
)(SpecifyGenericTaskForm)
