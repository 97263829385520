import React from 'react'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import BesoinsProductionComponent from "./BesoinsProductionComponent";
import DialogProvider from "../../../components/layout/contextProvider/DialogProvider";

const BesoinsProduction = (props) => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.BESOINS_PRODUCTION), [])

	return (
		<DialogProvider textButton>
			<BesoinsProductionComponent location={props.location}/>
		</DialogProvider>
	)
}

export default BesoinsProduction
