import { DeleteForever } from '@material-ui/icons'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import Comment from '../../../../../../components/Comment'
import CommentaireIcon from '../../../../../../components/icon/CommentaireIcon'
import CultureIcon from '../../../../../../components/icon/CultureIcon'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import { DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import AccessChecker from '../../../../../../components/security/AccessChecker'
import {
	ASSOLEMENT_ETAT,
	colors,
	COMMENTAIRE_TYPE_ENTITY,
	PROFILS,
	ETAPE_ETAT,
	ETAPE_TYPE
} from '../../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../../utils/utils'
import Detail from '../../../component/detail/Detail'
import EtapeAssolement from '../../../component/detail/EtapeAssolement'
import { PANEL_TYPE } from '../../../utils/constant'
import { getEtapeDetailTitle } from '../../../utils/utils'
import * as selectors from '../../assolement/assolementSelector'
import { injectDeleteComment } from '../form/deleteCommentInjector'
import { injectDeleteEtape } from '../form/deleteEtapeInjector'
import { injectSuiviEtape } from '../form/suiviEtapeInjector'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'
import InfoCulture from '../../../../../common/information/InfoCulture'
import TypeEtape from '../../../../../common/information/TypeEtape'

const buttonStyle = {
	marginLeft: '50%'
}
/**
 * Injecteur pour l'affichage du panel de détail de culture
 */
export const injectEtapeDetailPanel = (WrappedComponent) => {
	const EtapeDetailInjector = ({ currentAssolement, currentEtape, panelType, ...props }) => {
		const { updatePanel: updateDetailsPanel, closePanel: closePanelDetail } = React.useContext(DetailsPanelContext)
		const { openEtapeSuivi, openEtapeDelete, openCommentDelete } = props

		React.useEffect(() => {
			if (currentAssolement && currentEtape && panelType === PANEL_TYPE.ETAPE_DETAIL) {

				const { culture, planche } = currentAssolement
				const title = <span>
				<FormattedMessage id="planning.etapesAssolement.title" />
				<TypeEtape typeEtape={currentEtape.type} />
			</span>

				let buttons = []
				if(currentAssolement.etat === ASSOLEMENT_ETAT.REVISE) {
					if (currentEtape.etat === ETAPE_ETAT.A_REALISER && currentEtape.type !== ETAPE_TYPE.SEMI_DIRECT && currentEtape.type !== ETAPE_TYPE.SEMI_EN_CONTENANT) {
						buttons = [
							<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
								<Button
									type="secondary"
									onClick={() => openEtapeDelete(currentEtape)}
									startIcon={<DeleteForever style={{ width: '1.25em' }}/>}
									style={{ padding: '0 10px 0 10px' }}
								>
									<FormattedMessage id="planning.etapesAssolement.buttons.supprimer"/>
								</Button>
							</AccessChecker>]
					}

					buttons = buttons.concat([
						<Button
							type="secondary"
							style={currentEtape.etat === ETAPE_ETAT.TERMINE ? buttonStyle : {}}
							onClick={() => openEtapeSuivi(currentAssolement, currentEtape)}
							startIcon={<CommentaireIcon color={colors.primary}/>}
						>
							<FormattedMessage id="planning.etapesAssolement.buttons.suivi"/>
						</Button>
					])
				}

				let sections = [
					{
						icon: currentEtape.etat === ETAPE_ETAT.TERMINE ?
							<CheckCircleIcon style={{ color: colors.iconeDetail }} /> :
							<Brightness1Icon style={{ color: colors.toDo }} />,
						title: getEtapeDetailTitle(currentEtape),
						content: <EtapeAssolement assolement={currentAssolement} assolementEtape={currentEtape}
						                          openEtapeSuivi={(comment) => openEtapeSuivi(currentAssolement, currentEtape, false, comment)}
						                          openCommentDelete={(comment) => openCommentDelete(comment, COMMENTAIRE_TYPE_ENTITY.ETAPE)} />
					}
				]
				if (culture) {
					sections = sections.concat([
						{
							icon: <CultureIcon />,
							title: culture.nom,
							path: <InfoCulture culture={culture} />,
							content: <em>{culture.cultivar}</em>,
							comment: <Comment comment={culture.commentaire} />
						}
					])
				}

				sections = sections.concat([
					{
						icon: <PlaceIcon />,
						title: planche.nom,
						path: <ZoneSurface planche={planche} />,
						content: <TypeSurface planche={planche} />
					}
				])
				updateDetailsPanel(
					title,
					<Detail sections={sections} />,
					buttons
				)
			} else if (!currentAssolement && !currentEtape && !panelType) {
				closePanelDetail()
			}
		}, [currentAssolement, currentEtape, panelType])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		currentAssolement: selectors.getCurrentAssolement(state),
		currentEtape: selectors.getCurrentEtape(state),
		panelType: selectors.getDetailPanelType(state)
	})

	EtapeDetailInjector.propTypes = {
		panelType: PropTypes.string,
		currentAssolement: PropTypes.object,
		currentEtape: PropTypes.object
	}
	return compose(
		injectSuiviEtape,
		injectDeleteEtape,
		injectDeleteComment,
		connect(mapStateToProps)
	)(EtapeDetailInjector)
}
