import React from 'react'
import ActionPanelProvider from '../../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../../components/layout/contextProvider/DetailPanelProvider'
import {FilterPanelContext} from '../../../../components/layout/rightPanels/index'
import {PAGE, usePageContext} from '../../../../components/layout/PageContext'
import SuiviConnexionsComponents from './SuiviConnexionsComponents'
import AccessChecker from '../../../../components/security/AccessChecker'
import {PROFILS} from '../../../../utils/constants'
import {getAccessCheckerRole} from '../../../../utils/utils'

const SuiviConnexions = () => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.SUIVI_CONNEXIONS), [])

	return (
		<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
			<DetailPanelProvider>
				<ActionPanelProvider Context={FilterPanelContext} level={1}>
					<ActionPanelProvider>
						<SuiviConnexionsComponents/>
					</ActionPanelProvider>
				</ActionPanelProvider>
			</DetailPanelProvider>
		</AccessChecker>
	)
}

export default SuiviConnexions
