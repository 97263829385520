import React from 'react'
import {Grid, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {getStyles} from 'isotope-client'
import {change, Field, reduxForm, formValueSelector} from 'redux-form'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import Checkbox from "../../../../components/form/Checkbox";
import classnames from "classnames";
import {formatPositiveInteger} from "../../../../utils/utils";
import AutocompleteMulti from "../../../../components/form/AutocompleteMulti";
import {getCategories} from "../../categoriesTechniques/services/categoriesTechniquesApi";

const styles = theme => getStyles({
	field: {
		width: '50%'
	},
	fieldAll: {
		width: '100%'
	},
	row: {
		marginBottom: 15
	},
	checkbox: {
		paddingLeft: 15,
		paddingTop: 15,
		fontFamily: 'Lato'
	},
	title: {
		fontWeight: 700,
		paddingLeft: 12,
		fontSize: 16
	},
	categorieTitle: {
		fontWeight: 700,
		paddingLeft: 12,
		fontSize: 12
	},
	space: {
		paddingTop: 15
	}
})

const formattedMessageBase = 'bo.reglagesMateriels.form'

const FIELDS = {
	NOM: {name: 'nom'},
	STOCK: {name: 'stock'},
	QUANTITE: {name: 'quantiteLimite', optional: true},
	CATEGORIES_TECHNIQUES: {name: 'categoriesTechniques', optional: true}
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = {id: 'global.errors.mandatory'}
		}
	})

	return errors
}

const MaterielForm = ({
						  classes,
						  handleSubmit,
						  isLimit,
						  formName,
						  change
					  }) => {
	React.useEffect(() => {
		if (isLimit === false) {
			change(FIELDS.STOCK.name, 0)
		}
	}, [isLimit])

	const getCategoriesTechniques = () => {
		return getCategories().then(categorie => categorie.map(categorie => ({
			code: categorie.id,
			label: categorie.nom
		})))
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.NOM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.materiel.${FIELDS.NOM.name}`}/>}
						fromBo
					/>
				</Grid>
				<Grid container>
					<Grid item className={classnames(classes.field, classes.checkbox)}>
						<Field
							name={FIELDS.QUANTITE.name}
							component={Checkbox}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.materiel.${FIELDS.QUANTITE.name}`}/>}
						/>
					</Grid>
					{isLimit && (
						<Grid item className={classes.field}>
							<Field
								name={FIELDS.STOCK.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.materiel.${FIELDS.STOCK.name}`}/>}
								format={formatPositiveInteger}
								fromBo
								type="number"
							/>
						</Grid>
					)}
				</Grid>
				<Grid item className={classnames(classes.fieldAll, classes.space)}>
					<Field
						name={FIELDS.CATEGORIES_TECHNIQUES.name}
						component={AutocompleteMulti}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.materiel.categorie.${FIELDS.CATEGORIES_TECHNIQUES.name}`}/>}
						getOptions={() => getCategoriesTechniques()}
						forceGetOptions
						fromBo
						formName={formName}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

MaterielForm.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	isLimit: PropTypes.bool,
	classes: PropTypes.object
}

const mapStateToProps = (state, {formName}) => {
	const selector = formValueSelector(formName)
	return {
		form: formName,
		isLimit: selector(state, FIELDS.QUANTITE.name)
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(MaterielForm)
