import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PollIcon from '@material-ui/icons/Poll'
import {FormattedDate, FormattedMessage} from 'react-intl'
import NoResultPage from '../../../../../components/NoResultPage'
import {Rating} from "@material-ui/lab";
import {colors, DATATABLE_SORT_ORDER, ONGLETS_BESOINS_PRODUCTION} from '../../../../../utils/constants'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import BesoinsProductionFilters from '../filters/BesoinsProductionFilters'
import {getResultats, getStats} from '../../service/besoinsProductionApi'
import WarningRounded from '@material-ui/icons/WarningRounded'
import TableChart from '@material-ui/icons/TableChart'
import {getQuantiteHebdo, getQuantiteHebdoSolution} from '../recueil/quantite/QuantiteForm'
import FieldWithTooltip from '../../../../../components/FieldWithTooltip'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import classnames from 'classnames'
import StatBloc from '../../../../common/stat/StatBloc'
import DataTableWithLoader from "../../../../../components/layout/DataTableWithLoader";
import {compareNullableNumbers, localCompare} from "../../../../../utils/utils";

const styles = () => getStyles({
	mainContainer: {
		marginTop: 20,
		marginLeft: '2%',
		marginRight: '2%'
	},
	error: {
		color: colors.error
	},
	warning: {
		color: colors.warning
	},
	chartIcon: {
		transform: 'rotate(90deg)'
	},
	title: {
		color: colors.primary,
		fontWeight: 'bold',

		'& svg': {
			paddingLeft: 10,
			paddingRight: 20
		}
	},
	tooltip: {
		height: '100%',
		padding: 8,
		fontSize: 14,

		'& span': {
			fontSize: 12,
			paddingLeft: 5,
			paddingRight: 5
		}
	},
	table: {
		'& .MuiTableCell-body': {
			height: 40,
			fontSize: 14,

			'& span': {
				fontSize: 12,
				color: colors.placeholder
			}
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073',
			padding: '5px'
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		}
	},
	bouton: {
		height: 36,
		minWidth: 'auto',
		paddingLeft: 20,
		paddingRight: 20
	}
})

const DATATABLE_KEYS = {
	CULTURE: 'culture',
	PERIODE: 'dateDebutRecolteSolution',
	QUANTITE: 'quantiteKgSolution',
	ATTEINTE_OBJECTIF_QUANTITE: 'atteinteObjectifQuantite',
	IMPORTANCE: 'importance'
}

const getPourcentageCouleur = (value, total, reversed) => {
	let pourcentage = (total ? ((value / total) * 100) : value)
	if (reversed) {
		pourcentage = 100 - pourcentage
	}
	if (pourcentage >= 80 && pourcentage <= 120) {
		return colors.valid
	} else if (pourcentage >= 60 && pourcentage <= 140) {
		return colors.warning
	} else {
		return colors.error
	}
}

const showIconeErreur = (value, classes) => (
	<div>{value && <WarningRounded className={classes.error}/>}</div>
)

const getPourcentageCellule = value => (
	<p style={{color: getPourcentageCouleur(value)}}>
		{value} %
	</p>
)

const getContenuTooltipPeriode = (row, classes) => {
	return row.alertePeriode && <Grid container direction="column" justify="space-between" className={classes.tooltip}>
		<Grid item>
			<b><FormattedMessage id="besoinsProduction.resultat.table.headers.periodeSouhaitee"/></b>
		</Grid>
		<Grid container item justify="space-between">
			<Grid item xs={2}></Grid>
			<Grid item xs={5}>
				<span style={{color: colors.placeholder}}><FormattedMessage id="besoinsProduction.date.debut"/></span>
				<FormattedDate value={row.dateDebutRecolte}/>
			</Grid>
			<Grid item xs={5}>
				<span style={{color: colors.placeholder}}><FormattedMessage id="besoinsProduction.date.fin"/></span>
				<FormattedDate value={row.dateFinRecolte}/>
			</Grid>
		</Grid>
		<Grid container item style={{color: colors.error}} justify="space-between">
			<Grid item xs={2}>
				<EventBusyIcon className={classes.error}/>
			</Grid>
			{row.dateDebutRecolteSolution ? <>
				<Grid item xs={5}>
					<span><FormattedMessage id="besoinsProduction.date.debut"/></span>
					<FormattedDate value={row.dateDebutRecolteSolution}/>
				</Grid>
				<Grid item xs={5}>
					<span><FormattedMessage id="besoinsProduction.date.fin"/></span>
					<FormattedDate value={row.dateFinRecolteSolution}/>
				</Grid>
			</> : <Grid item xs={10} style={{paddingLeft: 5}}><FormattedMessage id="besoinsProduction.resultat.table.emptyPeriode"/></Grid>}
		</Grid>
	</Grid>
}

const getContenuTooltipQuantite = (row, classes) => {
	const differenceQuantite = row.quantiteKgSolution - row.quantiteKg
	const differenceQuantiteHebdo = (getQuantiteHebdoSolution(row) - getQuantiteHebdo(row)).toFixed(1)
	return row.alerteQuantite && <Grid container direction="column" justify="space-between" className={classes.tooltip}>
		<Grid item>
			<b><FormattedMessage id="besoinsProduction.resultat.table.headers.quantite"/></b>
		</Grid>
		<Grid container item justify="space-between">
			<Grid item xs={2}></Grid>
			<Grid item xs={5}>
				{row.quantiteKg} <span style={{color: colors.placeholder}}><FormattedMessage id="besoinsProduction.unites.kg"/></span>
			</Grid>
			<Grid item xs={5}>
				{getQuantiteHebdo(row)} <span style={{color: colors.placeholder}}><FormattedMessage id="besoinsProduction.unites.kgParSemaineFull"/></span>
			</Grid>
		</Grid>
		<Grid container item style={{color: differenceQuantite < 0 ? colors.error : colors.warning}} justify="space-between">
			<Grid item xs={2}>
				<ShowChartIcon className={classnames(differenceQuantite < 0 ? classes.error : classes.warning, classes.chartIcon)}/>
			</Grid>
			<Grid item xs={5}>
				{differenceQuantite > 0 ? '+' + differenceQuantite : differenceQuantite} <span><FormattedMessage id="besoinsProduction.unites.kg"/></span>
			</Grid>
			<Grid item xs={5}>
				{differenceQuantiteHebdo > 0 ? '+' + differenceQuantiteHebdo : differenceQuantiteHebdo} <span><FormattedMessage id="besoinsProduction.unites.kgParSemaineFull"/></span>
			</Grid>
		</Grid>
	</Grid>
}

const OngletResultatEcartCulture = ({classes, campagne}) => {
	const [filters, setFilters] = React.useState({idCampagne: campagne.id})
	const [cultures, setCultures] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [stats, setStats] = React.useState({})
	const [sort, setSort] = useState({})

	React.useEffect(() => {
		if (campagne.id) {
			setFilters({...filters, idCampagne: campagne.id})
			getStats(campagne.id).then(setStats)
		}
	}, [campagne])

	React.useEffect(() => {
		if (filters.idCampagne) {
			setIsLoading(true)
			getResultats(filters).then(setCultures).finally(() => setIsLoading(false))
		}
	}, [filters])

	const getDisplayData = React.useMemo(() => {
		if (sort.key) {
			if (sort.key === DATATABLE_KEYS.CULTURE || sort.key === DATATABLE_KEYS.PERIODE) {
				return cultures.sort(localCompare(sort.key, sort.order === DATATABLE_SORT_ORDER.DESC))
			}
			return cultures.sort(compareNullableNumbers(sort.key, sort.order === DATATABLE_SORT_ORDER.DESC))
		}
		return cultures
	}, [cultures, sort])


	return (
		<Grid container direction="column" spacing={4} className={classes.mainContainer}>
			<Grid container justify="center" spacing={3}>
				<Grid item xs={12} md={4} lg={3}>
					<StatBloc
						Icon={PollIcon}
						getPourcentageCouleur={getPourcentageCouleur}
						value={stats.atteinteObjectifQuantite}
						idMessage="besoinsProduction.resultat.stats.atteinteObjectifQuantite"
						idSecondaryMessage={"besoinsProduction.resultat.stats.pourcentageNormalise"}
						isPourcentage
					/>
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<StatBloc
						Icon={WarningRounded}
						getPourcentageCouleur={getPourcentageCouleur}
						value={stats.alertesQuantite}
						idMessage="besoinsProduction.resultat.stats.alertesQuantite"
						total={stats.total}
						reversePourcentage
					/>
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<StatBloc
						Icon={WarningRounded}
						getPourcentageCouleur={getPourcentageCouleur}
						value={stats.alertesPeriode}
						idMessage="besoinsProduction.resultat.stats.alertesPeriode"
						total={stats.total}
						reversePourcentage
					/>
				</Grid>
			</Grid>

			<Grid container item direction="column">
				<Grid container item justify="space-between">
					<Grid item container className={classes.title} alignItems="center">
						<TableChart/>
						<FormattedMessage id="besoinsProduction.resultat.ecartParCulture.ecart"/>
					</Grid>

				</Grid>
				<Grid item className={classes.table}>
					<BesoinsProductionFilters
						onSubmit={values => setFilters({idCampagne: filters.idCampagne, ...values})}
						onglet={ONGLETS_BESOINS_PRODUCTION.RESULTATS}
					>
						{filters.idCampagne && <DataTableWithLoader
							nom="ResultatBesoinsProductionTable"
							filters={filters}
							noPagination
							loading={isLoading}
							noResultFragment={<NoResultPage/>}
							data={getDisplayData}
							onFilterChange={key => {
								setSort(prev => {
									if (prev.key === key) {
										return ({
											...prev,
											order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
										})
									}
									return ({
										key,
										order: DATATABLE_SORT_ORDER.ASC
									})
								})
							}}
							headers={[
								{
									key: DATATABLE_KEYS.CULTURE,
									name: <FormattedMessage id="besoinsProduction.resultat.table.headers.culture"/>,
									sortable: true,
									sorted: sort.key === DATATABLE_KEYS.CULTURE ? sort.order : undefined,
									tableHeaderColumnProps: {
										width: '18%'
									},
									render: row => row.culture
								},
								{
									key: 'alertePeriode',
									tableHeaderColumnProps: {
										align: 'right',
										width: '2%'
									},
									render: row => <FieldWithTooltip
										contenuField={showIconeErreur(row.alertePeriode, classes)}
										contenuTooltip={getContenuTooltipPeriode(row, classes)}
									/>
								},
								{
									key: DATATABLE_KEYS.PERIODE,
									name: <FormattedMessage id="besoinsProduction.resultat.table.headers.periodePrevue"/>,
									sortable: true,
									sorted: sort.key === DATATABLE_KEYS.PERIODE ? sort.order : undefined,
									tableHeaderColumnProps: {
										align: 'center',
										width: '25%'
									},
									render: row => row.dateDebutRecolteSolution ? <>
										<span><FormattedMessage id="besoinsProduction.date.debut"/></span>
										<FormattedDate value={row.dateDebutRecolteSolution}/>
										<span><FormattedMessage id="besoinsProduction.date.fin"/></span>
										<FormattedDate value={row.dateFinRecolteSolution}/>
									</> : <FormattedMessage id="besoinsProduction.resultat.table.emptyPeriode"/>
								},
								{
									key: 'alerteQuantite',
									tableHeaderColumnProps: {
										align: 'right',
										width: '2%'
									},
									render: row => <FieldWithTooltip
										contenuField={showIconeErreur(row.alerteQuantite, classes)}
										contenuTooltip={getContenuTooltipQuantite(row, classes)}
									/>
								},
								{
									key: DATATABLE_KEYS.QUANTITE,
									name: <FormattedMessage id="besoinsProduction.resultat.table.headers.quantitePrevue"/>,
									sortable: true,
									sorted: sort.key === DATATABLE_KEYS.QUANTITE ? sort.order : undefined,
									tableHeaderColumnProps: {
										align: 'center',
										width: '20%'
									},
									render: row => <Grid container alignItems="center" justify="center">
										<Grid container item alignItems="center" justify="center" xs={4}>
											{row.quantiteKgSolution} <span>&nbsp;<FormattedMessage id="besoinsProduction.unites.kg"/></span>
										</Grid>
										<Grid container item alignItems="center" justify="center" xs={8}>
											{getQuantiteHebdoSolution(row)} <span>&nbsp;<FormattedMessage id="besoinsProduction.unites.kgParSemaineFull"/></span>
										</Grid>
									</Grid>
								},
								{
									key: DATATABLE_KEYS.ATTEINTE_OBJECTIF_QUANTITE,
									name: '%',
									sortable: true,
									sorted: sort.key === DATATABLE_KEYS.ATTEINTE_OBJECTIF_QUANTITE ? sort.order : undefined,
									tableHeaderColumnProps: {
										align: 'center',
										width: '3%'
									},
									render: row => row.atteinteObjectifQuantite !== null && getPourcentageCellule(row.atteinteObjectifQuantite)
								},
								{
									key: DATATABLE_KEYS.IMPORTANCE,
									name: <FormattedMessage id="besoinsProduction.resultat.table.headers.importance"/>,
									sortable: true,
									sorted: sort.key === DATATABLE_KEYS.IMPORTANCE ? sort.order : undefined,
									tableHeaderColumnProps: {
										align: 'center',
										width: '20%'
									},
									render: row => row.importance ? <Rating
										name={`importance-${row.id}`}
										value={row.importance}
										emptyIcon={<StarBorderIcon fontSize="inherit"/>}
										disabled
										icon={<StarIcon fontSize="inherit" style={{color: '#757575'}}/>}
									/> : <FormattedMessage id="besoinsProduction.resultat.table.emptyImportance"/>
								}
							]}
						/>}
					</BesoinsProductionFilters>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default withStyles(styles)(OngletResultatEcartCulture)