import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { formatListeCulturesConcerneesDetails } from '../../../../utils/utils'

const styles = () => getStyles({
	container: {
		margin: '1.5em',
		fontFamily: 'Lato'
	}
})

const DeleteCulture = ({ selectedAssolements, classes }) => {

	if (selectedAssolements.length === 1) {
		const assolementExist = selectedAssolements[0] && selectedAssolements[0].planche && selectedAssolements[0].planche.id && selectedAssolements[0].culture && selectedAssolements[0].culture.id

		return (
			assolementExist && <Grid className={classes.container}>
				<Grid><FormattedMessage id="planning.cultureForm.deleteCulture.text" values={{ culture: selectedAssolements[0].culture.nom, surface: selectedAssolements[0].planche.nom }} /></Grid><br />
				<Grid><FormattedMessage id="planning.cultureForm.deleteCulture.warning" /></Grid>
			</Grid>)
	} else {

		const items = formatListeCulturesConcerneesDetails(selectedAssolements)

		return (
			<Grid className={classes.container}>
				<Grid><FormattedMessage id="listAssolement.popup.content.supprimerContent" /></Grid><br />
				<ul>{items}</ul>
			</Grid>
		)
	}
}

DeleteCulture.propType = {
	selectedAssolements: PropTypes.array,
	classes: PropTypes.object
}

export default withStyles(styles)(DeleteCulture)
