import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

const CustomWaypoint = ({ onVisible, onEnter, onLeave, waypointKey, childOnly, disabled, children, ...otherProps }) => {
	const [mounted, setMounted] = React.useState(false)
	const [waypointVisible, setWaypointVisible] = React.useState(false)

	React.useEffect(() => {
		if (!mounted) {
			setMounted(true)
		}
		if (mounted && waypointVisible) {
			onVisible()
		}
	}, [])

	if (childOnly) {
		return children
	}

	const customOnEnter = (props) => {
		if (mounted && !disabled) {
			if (onEnter) {
				onEnter(props)
			}
			setWaypointVisible(true)
		}
	}

	const customOnLeave = (props) => {
		if (mounted && !disabled) {
			if (onLeave) {
				onLeave(props)
			}
			setWaypointVisible(false)
		}
	}

	return (
		<Waypoint
			key={waypointKey}
			onEnter={customOnEnter}
			onLeave={customOnLeave}
			{...otherProps}
		>
			{children}
		</Waypoint>
	)
}

CustomWaypoint.propType = {
	waypointKey: PropTypes.string,
	onEnter: PropTypes.func,
	onLeave: PropTypes.func,
	onVisible: PropTypes.func
}

export default CustomWaypoint
