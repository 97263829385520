/**
 * Déplace un objet d'un index à un autre dans une liste
 * @param list la liste
 * @param startIndex l'index de départ
 * @param endIndex l'index de retour
 * @returns {any[]} la liste mise à jour
 */
const reorderSingleDrag = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

/**
 * Déplace un ensemble d'objets d'un index à un autre dans une liste
 * @param list la liste
 * @param selected la liste des objets à déplacer
 * @param endIndex l'index de retour
 * @returns {any[]} la liste mise à jour
 */
const reorderMultiDrag = (list, selected, endIndex) => {

	const dragged = list[0]
	const indexOffset = selected.reduce((previous, current) => {
		if (current === dragged) {
			return previous
		}
		const index = list.indexOf(current)
		if (index > endIndex) {
			return previous
		}
		return previous + 1
	}, 0)
	const insertAtIndex = endIndex - indexOffset

	// Suppression des éléments déplacés de la liste
	const newList = list.filter(id => !selected.includes(id))

	// Puis réinsertion des éléments à l'emplacement du drop
	newList.splice(insertAtIndex, 0, ...selected)

	return newList

}

export const multiDragAwareReorder = (list, selected, startIndex, endIndex) => {
	if (selected.length > 1) {
		return reorderMultiDrag(list, selected, endIndex)
	}
	return reorderSingleDrag(list, startIndex, endIndex)
}