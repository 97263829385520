export const SET_FILTER_OPEN = 'SET_FILTER_OPEN'
export const SET_FILTER_CLOSE = 'SET_FILTER_CLOSE'
export const SET_FILTER_CLOSING = 'SET_FILTER_CLOSING'

export const setFilterOpen = value => dispatch => {
	if (value) {
		return dispatch({ type: SET_FILTER_OPEN })
	}
	dispatch({ type: SET_FILTER_CLOSING })
	setTimeout(() => dispatch({ type: SET_FILTER_CLOSE }), 200)
}

export const SET_FILTER_USED = 'SET_FILTER_USED'

export const setFilterUsed = value => dispatch => dispatch({
	type: SET_FILTER_USED,
	value: value
})

export const RESET_FILTER = 'RESET_FILTER'
export const resetFilter = value => dispatch => dispatch({
	type: RESET_FILTER
})

export const SAVE_CURRENT_FILTER = 'SAVE_CURRENT_FILTER'

export const saveCurrentFilter = values => dispatch => dispatch({
	type: SAVE_CURRENT_FILTER,
	values: values
})

export const SET_SORT_USED = 'SET_SORT_USED'

export const setSortUsed = value => dispatch => dispatch({
	type: SET_SORT_USED,
	value
})

export const SET_SORT_OPEN = 'SET_SORT_OPEN'

export const setSortOpen = value => dispatch => dispatch({
	type: SET_SORT_OPEN,
	value
})


export const SET_SORT_VALUE = 'SET_SORT_VALUE'

export const setSortValue = value => dispatch => dispatch({
	type: SET_SORT_VALUE,
	value
})

export const SET_PLANNING_SIZE_VALUE = 'SET_PLANNING_SIZE_VALUE'

export const setPlanningSize = value => dispatch => dispatch({
	type: SET_PLANNING_SIZE_VALUE,
	value
})
