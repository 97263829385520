import React from 'react'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../../GestionRessourceProvider';
import {Grid, withStyles} from '@material-ui/core'
import {ABSENCE_RESSOURCES_TABLE, AJOUT_RESSOURCE_FORM, colors} from '../../../../../utils/constants'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {getStyles} from 'isotope-client'
import Button from '../../../../../components/Button'
import {injectAbsence} from '../../injector/absenceInjector'
import {connect} from 'react-redux'
import AbsenceIcon from '../../../../../components/icon/AbsenceIcon'
import NoResultPage from '../../../../../components/NoResultPage'
import SpringDataTableWithLoader from '../../../../../components/layout/SpringDataTableWithLoader'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import {calculateDaysBetweenDates, formatDate} from '../../utils/ressourceUtils'

const styles = () => getStyles({
	container: {
		fontFamily: 'Lato',
	},
	title: {
		fontSize: 18,
		fontWeight: 600
	},
	subtitle: {
		fontSize: 12,
		fontWeight: 300,
		fontStyle: 'italic'
	},
	icon: {
		height: 24,
		width: 24
	},
	field: {
		width: '33%'
	},
	row: {
		width: '80%'
	},
	fieldRow: {
		marginTop: 5
	},
	header: {
		marginBottom: 20
	},
	textField: {
		backgroundColor: colors.light,
		borderRadius: 20,
		'&.MuiOutlinedInput-root': {
			borderRadius: 20,
		},
	},
	withoutBorder: {
		border: 'none'
	},
	table: {
		borderCollapse: 'collapse',
		'& [class*="MuiTableCell-root"]': {
			height: 40,
		},
		'& [class*="MuiTableCell-head"]': {
			padding: '0 5px',
			lineHeight: '1.3',
		},
		'& [class*="MuiTableCell-body"]': {
			padding: '0 5px',
			textAlign: 'center'
		},
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		minWidth: '100%',
		maxWidth: '100%',
		overflow: 'auto'
	},
	addIcon: {
		marginTop: 20,
		marginBottom: 20,
		"& .MuiButton-outlinedPrimary": {
			border: 'none'
		}
	},
	tabButton: {
		color: colors.primary,
	}
})

const HeaderText = ({Icon, message, classes}) => {
	return (
		<Grid container direction="row" alignItems="center" className={classes.header}>
			<Grid item style={{marginRight: 15}}>
				<Icon color="primary" className={classes.icon}/>
			</Grid>
			<Grid item className={classes.title}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.${message}`}/>
			</Grid>
		</Grid>
	);
};

const Absences = ({
						   classes,
						   handleSubmit,
						   openPopinAbsence,
						   openPopinAbsenceDelete,
						   initialValues,
					   }) => {
	const {ongletGestion, refreshTab} = useGestionRessourceContext()

	return (
		<form onSubmit={handleSubmit}>
			<Grid item container justify={"center"} style={{marginLeft: initialValues ? 150 : 20, paddingRight: initialValues ? '5%' : 0}} className={classes.row}>
				<HeaderText Icon={AbsenceIcon} message={ongletGestion.code.toLowerCase()} classes={classes}/>
				<Grid item container justify={"flex-start"} className={classes.subtitle}>
					<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.absenceSub`}/>
				</Grid>
				<Grid item container className={classes.addIcon}>
					<Button
						startIcon={<AddIcon/>}
						withBorder={false}
						color="primary"
						variant="outlined"
						onClick={() => openPopinAbsence(false, initialValues?.id)}
					>
						<FormattedMessage id={`gestionRessource.detailRessource.absences.buttonAdd`}/>
					</Button>
				</Grid>
				<Grid item container className={classes.leftContainer}>
					<SpringDataTableWithLoader
						key={refreshTab}
						nom={ABSENCE_RESSOURCES_TABLE}
						showBorders={false}
						apiUrl={`/ressource/absence/${initialValues?.id}`}
						defaultPageSize={50}
						noResultFragment={<NoResultPage emptyStateMessageId={'gestionRessource.detailRessource.absences.emptyTable'}/>}
						tableProps={{
							stickyHeader: true,
							className: classes.table
						}}
						headers={[
							{
								key: 'dateDebut',
								name: <FormattedMessage id="gestionRessource.detailRessource.absences.table.dateDebut"/>,
								render: row => <>{formatDate(row.dateDebutIndispo)}</>
							},
							{
								key: 'dateFin',
								name: <FormattedMessage id="gestionRessource.detailRessource.absences.table.dateFin"/>,
								render: row => <>{formatDate(row?.dateFinIndispo) || '-'}</>
							},
							{
								key: 'nombreJours',
								name: <FormattedMessage id="gestionRessource.detailRessource.absences.table.nombreJours"/>,
								render: row => <>{row?.dateFinIndispo ? calculateDaysBetweenDates(row?.dateDebutIndispo, row?.dateFinIndispo) : '-'}</>
							},
							{
								key: 'typeAbsence',
								name: <FormattedMessage id="gestionRessource.detailRessource.absences.table.typeAbsence"/>,
								render: row => <FormattedMessage id={`gestionRessource.detailRessource.absences.popin.typeAbsences.${row.typeAbsence.toLowerCase()}`} />
							},
							{
								key: 'commentaire',
								name: <FormattedMessage id="gestionRessource.detailRessource.absences.table.commentaire"/>,
								render: row => <>{row.commentaire || '-'}</>
							},
							{
								key: 'buttons',
								render: row => <>
									<Grid justify={"flex-end"} alignItems="center" className={classes.tabButton}>
										<EditOutlinedIcon style={{cursor: 'pointer'}} onClick={() => openPopinAbsence(true, null, row)}/>
										<DeleteForeverOutlinedIcon style={{cursor: 'pointer', marginLeft: 10}} onClick={() => openPopinAbsenceDelete(row)}/>
									</Grid>
								</>
							}
						]}
					/>
				</Grid>
			</Grid>
		</form>
	)
}


Absences.propType = {
	classes: PropTypes.object,
	formName: PropTypes.string,
	initialValues: PropTypes.object,
}

const mapStateToProps = () => {
	return {
		form: AJOUT_RESSOURCE_FORM,
	}
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	injectAbsence,
)(Absences)
