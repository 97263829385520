import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectZoneSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_VALUE_LIST_ITEM } from '../../../utils/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'

/** Composant qui permet l'affichage du titre d'une planche **/

const TitrePlanche = ({
	planche,
	zoneSurfaceList
}) => {
	return <FormattedMessage
		id="bo.blocSurface.titreTuileSurface"
		values={{
			nom: planche.nom,
			zone: (zoneSurfaceList.find(x => x.code === planche.zone) || DEFAULT_VALUE_LIST_ITEM).label.trim()
		}}
	/>
}

TitrePlanche.propTypes = {
	planche: PropTypes.object,
	zoneSurfaceList: PropTypes.array
}

export default compose(injectZoneSurfaceValueList)(TitrePlanche)