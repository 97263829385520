import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const PrepaPlancheOutlinedIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M332.181 239.714H271v-63.312h2.07c7.51 0 13.862-5.553 14.865-12.996l14.821-109.922a47.173 47.173 0 00-11.236-37.357C282.56 5.877 269.614 0 256 0s-26.56 5.879-35.52 16.128a47.178 47.178 0 00-11.236 37.356l14.822 109.923a15.001 15.001 0 0014.866 12.996H241v63.311h-61.181c-24.813 0-45 20.187-45 45V497c0 8.284 6.716 15 15 15s15-6.716 15-15V391.267H241V497c0 8.284 6.716 15 15 15s15-6.716 15-15V391.266h76.181v105.733c0 8.284 6.716 15 15 15s15-6.716 15-15V284.714c0-24.813-20.188-45-45-45zM238.974 49.475c-.672-4.984.781-9.815 4.091-13.602C246.376 32.086 250.969 30 256 30c5.03 0 9.624 2.086 12.935 5.873 3.311 3.787 4.763 8.618 4.091 13.603l-13.069 96.927h-7.913l-13.07-96.928zM347.18 361.266l-182.361.001v-76.553c0-8.271 6.729-15 15-15H332.18c8.271 0 15 6.729 15 15v76.552z"
				fill={color}
			/>

		</svg>
	)
}

PrepaPlancheOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

PrepaPlancheOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default PrepaPlancheOutlinedIcon
