import moment from 'moment'
import * as actions from './campagneAction'

export const PLANNING_RANGE = 6
const currentCampagne = (state = {}, action) => {
	switch (action.type) {
		case actions.SET_ACTIVE_CAMPAGNE:
			const campagne = action.campagne
			return {
				...campagne,
				dateDebutVisible: campagne && moment(campagne.dateDebut).subtract(PLANNING_RANGE, 'months').startOf('isoWeeks'),
				dateFinVisible: campagne && moment(campagne.dateFin).add(PLANNING_RANGE, 'months').endOf('isoWeeks')
			}
		default:
			return state
	}
}

export default currentCampagne