import {Grid, Typography, withStyles} from '@material-ui/core'
import React from 'react'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {useGestionRessourceContext} from '../../GestionRessourceProvider'
import {DISPONIBILITES} from '../../utils/constants'
import CheckboxLabel from '../../../../../components/CheckboxLabel'
import {injectTypeContratValueList} from '../../../../common/valueLists/valueListInjectors'
import Button from '../../../../../components/Button'
import AddIcon from '@material-ui/icons/Add'
import {colors} from '../../../../../utils/constants'
import {replace} from 'connected-react-router'
import {connect} from 'react-redux'
import SearchBar from '../../../../../components/SearchBar';

const styles = () => getStyles({
	container: {
		width: 'auto',
		marginBottom: 8,
		height: 48,
		fontFamily: 'Lato',
		minWidth: 1150,
		maxWidth: '100%'
	},
	filter: {
		paddingBottom: 15
	},
	title: {
		fontStyle: 'italic',
		fontSize: 14
	},
	iconSearch: {
		width: 32,
		height: 32,
		minHeight: 35
	},
	textField: {
		backgroundColor: colors.light,
		borderRadius: 20,
		'&.MuiOutlinedInput-root': {
			borderRadius: 20,
		},
	},
	withoutBorder: {
		border: 'none'
	},
	search: {
		textAlign: 'center',
		width: 45,
		height: 45,
		minHeight: 45
	}
})

const HeaderRessources = ({classes, typeContratValueList, goAjoutRessource}) => {
	const {
		setSelectedContrats,
		selectedContrats,
		setSelectedDisponibilite,
		selectedDisponibilite,
		setTextInput,
	} = useGestionRessourceContext()
	const [typesContrat, setTypeContrat] = React.useState([...typeContratValueList]);

	React.useEffect(() => {
		setTypeContrat([...typeContratValueList])
	}, [typeContratValueList])

	const handleCheckboxClickContrat = (contrat) => {
		setSelectedContrats((prevSelectedContrats) => {
			const newSelectedContrats = new Set(prevSelectedContrats);
			if (newSelectedContrats.has(contrat)) {
				newSelectedContrats.delete(contrat);
			} else {
				newSelectedContrats.add(contrat);
			}
			return newSelectedContrats;
		});
	};

	const handleCheckboxClickDisponibilite = (disponibilite) => {
		setSelectedDisponibilite((prevSelectedDisponibilite) => {
			const newSelectedDisponibilite = new Set(prevSelectedDisponibilite);
			if (newSelectedDisponibilite.has(disponibilite)) {
				newSelectedDisponibilite.delete(disponibilite);
			} else {
				newSelectedDisponibilite.add(disponibilite);
			}
			return newSelectedDisponibilite;
		});
	};

	return <Grid>
		<Typography variant={"caption"} className={classes.title}>
			<FormattedMessage id="gestionRessource.header.title"/>
		</Typography>
		<SearchBar setTextInput={setTextInput} placeHolder={'gestionRessource.filter.recherche'}/>
		<Grid container direction="row" justify="space-between" alignItems={"center"}>
			<Grid container xs={9} direction="column">
				<Grid item container xs={9} alignItems="center">
					<Typography>
						<FormattedMessage id="gestionRessource.filter.statut.title"/>
					</Typography>
					{Object.keys(DISPONIBILITES).map(key => (
						<CheckboxLabel
							key={key}
							onClick={() => handleCheckboxClickDisponibilite(key)}
							label={<FormattedMessage id={`gestionRessource.filter.statut.${key.toLowerCase()}`}/>}
							checked={selectedDisponibilite.has(key)}
						/>
					))}
				</Grid>
				<Grid item container xs={9} alignItems="center" className={classes.filter}>
					<Typography>
						<FormattedMessage id="gestionRessource.filter.contrat.title"/>
					</Typography>
					{typesContrat.map((contrat) => (
						<CheckboxLabel
							onClick={() => handleCheckboxClickContrat(contrat.label)}
							label={contrat.label}
							checked={selectedContrats?.has(contrat.label)}
						/>
					))}
				</Grid>
			</Grid>
			<Grid item container xs={3} justify={"flex-end"}>
				<Button
					type="primary"
					startIcon={<AddIcon/>}
					onClick={goAjoutRessource}
				>
					<FormattedMessage id="gestionRessource.toolbar.ajoutRessource"/>
				</Button>
			</Grid>
		</Grid>
	</Grid>
}

HeaderRessources.propType = {
	classes: PropTypes.object
}

const actions = {
	goAjoutRessource: () => dispatch => dispatch(replace('/ajout-ressource')),
}

export default compose(
	connect(null, actions),
	withStyles(styles),
	injectTypeContratValueList
)(HeaderRessources)