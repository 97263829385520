import { createSelector } from 'reselect'

const getLocalState = (state) => state.assolements

export const listIsLoading = createSelector(
	getLocalState,
	state => state.loading
)

export const isLoadingMore = createSelector(
	getLocalState,
	state => state.loadingMore
)

export const getAssolementsSortedList = createSelector(
	getLocalState,
	state => state.assolementSortedList
)

export const getAssolements = createSelector(
	getAssolementsSortedList,
	assolements => assolements.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})
)

export const getAssoRef = createSelector(
	getLocalState,
	state => state.assolementReferenceNotOnPlanche
)

export const isCompare = createSelector(
	getLocalState,
	state => state.comparaison
)

export const getAssolementsWithRef = createSelector(
	getAssolementsSortedList,
	getAssoRef,
	isCompare,
	(assolements, ref, isCompare) => assolements.reduce((acc, curr) => {
		const lastPLanche = acc.length && acc[acc.length -1].planche
		const output = []
		let index = 0
		while(ref.length > index && curr.planche.id !== lastPLanche.id && lastPLanche.id === ref[index].planche.id){
			output.push(ref[index++])
		}
		output.push(curr)
		return [
			...acc,
			...output
		]
	}, [])
		.filter(item => isCompare || (!isCompare && !item.deleted))
)

export const getAssolementById = (state, id) => createSelector(
	getAssolementsWithRef,
	assolements => assolements.find(asso => asso.id === id)
)(state)

const getCurrentEtapeId = createSelector(
	getLocalState,
	state => state.currentEtape
)

export const getCurrentAssolement = createSelector(
	getLocalState,
	state => state.currentAssolement
)

export const getCurrentEtape = createSelector(
	getCurrentAssolement,
	getCurrentEtapeId,
	(assolement, etapeId) => ((assolement || {}).assolementEtapeList || []).find(etape => etape.id === etapeId)
)

export const getDetailPanelType = createSelector(
	getLocalState,
	state => state.detailPanelType
)

export const getActiveRow = createSelector(
	getLocalState,
	state => state.activeRow
)

export const isActiveRow = (state, id) => createSelector(
	getActiveRow,
	activeRow => activeRow === id
)(state)

export const getCurrentPage = createSelector(
	getLocalState,
	state => state.currentPage
)
