import React from 'react'
import PropTypes from 'prop-types'

const PrepaPlancheIcon = ({ height, width, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props} >
			<path
				d="M271 169.733c0-8.284-6.716-15-15-15s-15 6.716-15 15v197.766c0 8.284 6.716 15 15 15s15-6.716 15-15V169.733z"
				fill="#859da6"
			/>
			<path
				d="M332.181 240H179.819C155.006 240 135 259.901 135 284.714V497c0 8.284 6.716 15 15 15s15-6.716 15-15V376h76v121c0 8.284 6.716 15 15 15s15-6.716 15-15V376h76v121h.091c0 8 6.671 15 14.955 15S377 505.284 377 497V284.714C377 259.901 356.993 240 332.181 240z"
				fill="#b8cfd6"
			/>
			<path
				d="M256 240h-76.181C155.006 240 135 259.901 135 284.714V497c0 8.284 6.716 15 15 15s15-6.716 15-15V376h76v121c0 8.284 6.716 15 15 15V240z"
				fill="#ebf6fa"
			/>
			<path
				d="M291.366 16.058a46.972 46.972 0 00-70.732.001 46.97 46.97 0 00-11.187 37.193l14.727 108.988c1.004 7.442 7.356 12.76 14.866 12.76h33.921c7.51 0 13.862-5.318 14.865-12.761l14.727-109.104c1.812-13.433-2.266-26.872-11.187-37.077z"
				fill="#cfa05f"
			/>
			<path
				d="M256 0a46.97 46.97 0 00-35.366 16.059 46.97 46.97 0 00-11.187 37.193l14.727 108.988c1.004 7.442 7.356 12.76 14.866 12.76h17.004L256 0z"
				fill="#facc8c"
			/>
		</svg>
	)
}

PrepaPlancheIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

PrepaPlancheIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default PrepaPlancheIcon
