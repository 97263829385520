import { withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider/index'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import withTheme from '@material-ui/core/styles/withTheme'
import * as icons from '@material-ui/icons'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { transformEntries } from 'isotope-client/components/menu/services/menuSelectors'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import warning from 'warning'
import SvgToIcon from '../../icon/SvgToIcon'
import { PermasoftConnectedMenu } from './PermasoftInternalMenu'

const styles = theme => getStyles({
	menuText: {
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.placeholder,
		cursor: 'default',
		paddingLeft: 20
	},
	menuDivider: {
		backgroundColor: 'rgb(85, 85, 90, .5)',
		marginBottom: 19,
		marginRight: 36
	},
	listItemLabel: {
		paddingLeft: 10
	}
})

const PermasoftMenuEntry = ({classes, entry, changeMenu, closeModal, location}) => {

	const entries = transformEntries(entry.children)
	let Component
	const componentProps = {}

	switch (entry.type) {
		case 'FUNCTION':
		case 'LINK':
		case 'TEXT':
		case 'DOWNLOAD':
			Component = entry.type === 'TEXT' ? ListItem : MenuItem

			let IconElement
			let SvgIconElement
			// Reconnaissance d'un code material-ui pour aller chercher l'icone
			if (entry.icon) {
				if (entry.icon.startsWith('[svg]')) {
					SvgIconElement = entry.icon.substring(5);
				} else {
					IconElement = icons[entry.icon]
					warning(IconElement, `Le code de l'icône de menu ${entry.code} ne correspond à aucune icône de MaterialUI`)
				}
			}

			// Placement de l'icone, du libellé et des entrées
			componentProps.children = (
				<Fragment>
					{IconElement && <IconElement color="inherit"/>}
					{SvgIconElement && <SvgToIcon path={SvgIconElement} color='inherit'/>}
					<ListItemText disableTypography inset={!!entry.icon} key="label" classes={{root: classes.listItemLabel}}>
						<FormattedMessage id={entry.code}/>
					</ListItemText>
				</Fragment>
			)
			if (entry.type === 'FUNCTION' || entry.type === 'LINK') {
				componentProps.button = true

				componentProps.onClick = () => {
					if (entry.url) {
						if (entry.url.startsWith('http')) {
							window.open(entry.url, '_blank')
						} else {
							changeMenu(entry.url)
							closeModal()
						}
					}
				}
			} else if (entry.type === 'DOWNLOAD') {
				componentProps.button = true
				componentProps.onClick = () => download(entry.url)
			}
			if (location.pathname === entry.url) {
				componentProps.selected = true
			}

			break
		case 'DIVIDER':
			Component = Divider
			break
		default:
	}


	if (Component) {
		return <Fragment>
			{(entry.type === 'TEXT' || entry.code === 'menu.bo.returnFo') && <Divider className={classes.menuDivider}/>}
			<Component {...componentProps} className={classnames({[classes.menuText]: entry.type === 'TEXT' || !entry.url })}/>
			<PermasoftConnectedMenu entries={entries} location={location} closeModal={closeModal}/>
		</Fragment>
	}
	return null
}

PermasoftMenuEntry.propTypes = {
	entry: PropTypes.object.isRequired,
	changeMenu: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired
}

export default compose(
	withStyles(styles),
	withTheme
)(PermasoftMenuEntry)
