import { withStyles } from '@material-ui/core'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Comment from '../../../../../components/Comment'
import { joinNode, localCompare } from '../../../../../utils/utils'
import { getAssolements } from '../../services/assolement/assolementSelector'
import { findFertilizedAssolement } from '../../utils/utils'

const dateFormat = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit'
}

const styles = () => getStyles({
	margin: {
		margin: '5px 0 0 0'
	},
	bold: {
		fontWeight: 'bold'
	}
})

const Fertilization = ({ plancheId, assolements, classes }) => {
	const assolement = findFertilizedAssolement(plancheId, assolements)
	if (!assolement) {
		return <FormattedMessage id='planning.planche.detail.fertilization.none' />
	}

	const assolementEtapeDates = assolement.assolementEtapeList
		.sort(localCompare('dateDebut'))
		.map(task => <FormattedDate value={task.dateDebut} {...dateFormat} />)
	return (
		<>
			<p className={classes.margin}>
				<FormattedMessage
					id='planning.planche.detail.fertilization.planned'
					values={{ quantity: assolementEtapeDates.length }} />
			</p>
			<p className={classnames(classes.margin, classes.bold)}>
				{joinNode(assolementEtapeDates, ' - ')}
			</p>
			<Comment comment={assolement.commentaires[0]} />
		</>
	)
}

Fertilization.propType = {
	plancheId: PropTypes.string,
	assolements: PropTypes.array,
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	assolements: getAssolements(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(Fertilization)
