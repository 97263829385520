import React, {useState} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from './Button'
import {getStyles} from 'isotope-client'
import {colors} from '../utils/constants'
import {Typography} from '@material-ui/core'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'

/**
 * Styles
 * @param theme
 */
const styles = () => getStyles({
	container: {
		display: 'flex',
		padding: '4px 6px',
		borderRadius: 4,
		border: '1px solid #D6D6D6',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#FFF'
	},
	actionBtn: {
		minWidth: 68,
		width: 68,
		height: 28,
		'&:hover': {
			color: '#000',
			backgroundColor: '#DDD'
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		},
	},
	text: {
		fontWeight: '500',
		lineHeight: '20px',
		fontSize: '12px'
	},
	selectedText: {
		color: '#FFF'
	},
	inactiveText: {
		color: colors.text
	}
})

/**
 * Switch
 * @param items
 * @param classes
 * @param defaultValue
 * @param setValue
 * @returns {JSX.Element}
 * @constructor
 */
const Switch = ({ items, classes, defaultValue = undefined, setValue, additionalStyle }) => {
	const [selectedValue, setSelectedValue] = useState(defaultValue)

	const handleClick = (item) => {
		if (selectedValue.id !== item.id) {
			setValue(item)
			setSelectedValue(item)
		}
	}


	return (
		<div className={classes.container}>
			{
				items.map(item => {
					const isSelected = selectedValue.id === item.id
					return (
						<Button
							key={`${item.id}${isSelected}`}
							className={classes.actionBtn}
							style={additionalStyle}
							onClick={() => handleClick(item)}
							withBorder={false}
							type={isSelected ? 'primary' : 'secondary'}
							keepCase
						>
							<Typography className={classnames(classes.text, isSelected ? classes.selectedText : classes.inactiveText)}>
								<FormattedMessage id={item.label} />
							</Typography>
						</Button>
					)
				})
			}
		</div>
	)
}

Switch.propTypes = {
	classes: PropTypes.object,
	items: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string, id: PropTypes.string
	})).isRequired,
	defaultValue: PropTypes.shape({
		label: PropTypes.string, id: PropTypes.string
	}),
	setValue: PropTypes.func.isRequired
}

export default withStyles(styles)(Switch)
