import { withStyles } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector } from 'redux-form'
import Button from '../../../../../../components/Button'
import FormButton from '../../../../../../components/form/FormButton'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { PAGE, usePageContext } from '../../../../../../components/layout/PageContext'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { colors, TYPE_DIALOG } from '../../../../../../utils/constants'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import DeplacerCulture from '../../../component/form/DeplacerCulture'
import PopinDeplacement from '../../../component/form/DeplacerPopin'
import { fields } from '../../../component/form/NouvelleCulture'
import { DEPLACER_CULTURE_FORM_NAME } from '../../../utils/constant'
import { editAssolement } from '../../assolement/assolementAction'
import { loadAssolement } from '../../assolement/assolementInjector'
import { editListAssolement, getPlanche } from '../../planningApi'
import { openCultureDetail } from '../detail/openCultureDetailInjector'

const styles = () => getStyles({
	//Ne connait pas "warning" pour attribut color="warning"
	warning: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.warning,
		paddingRight: '20px'

	},
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	},
	spanTitlePopin: {
		display: 'flex',
		alignItems: 'center'
	}
})

/**
 * Injecteur pour l'ouverture du panel d'édition de la planche de la culture
 */
export const injectEditCulturePlanche = (WrappedComponent) => {
	const EditCulturePlancheInjector = (props) => {
		const { snackError, snackSuccess } = useSnackbar()
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { page } = usePageContext()
		const { openCultureDetail, editAssolement, refreshAssolements, classes, getNotifications } = props
		const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()

		const annulerModifications = React.useCallback((selectedAssolements) => {

			selectedAssolements.forEach((assolement) => {
				const idAssolementEnCours = Array.isArray(assolement.id) ? assolement.id : [assolement.id]
				editListAssolement(idAssolementEnCours, assolement.planche.id, true, true)
					.then(() => {
						snackSuccess({ id: 'listAssolement.snackbar.ok.annulation' })
						refreshAssolements()
					})
			})
		}, [snackSuccess])

		const submitEditAssolement = (values, selectedAssolements, success, erreurDeTraitement, traitementImpossible) => {
			const selectedAssolementsID = selectedAssolements.map((assolement) => assolement.id)

			const isAssolementInAllPlanche = (planche) => {
				return selectedAssolements
					.filter(assolement => assolement.zone)
					.every((assolement) =>
						(assolement.zone.includes(planche.zone))
					)
			}

			getPlanche(values.localisation)
				.then(planche => {
					if (isAssolementInAllPlanche(planche)) {
						editAssolement(selectedAssolementsID, { ...values }, true, false)//ForceValidation FALSE → redirige popup si PB
							.then(({ assolement }) => {
								snackSuccess({ id: success }, [{ onClick: (() => annulerModifications(selectedAssolements)), label: { id: 'actions.cancel' } }])
								if (page === PAGE.PLANNING) {
									openCultureDetail(assolement[0])
								}
								getNotifications()
								refreshSolverInfosForCurrentCampagne()
								closePanel()
								refreshAssolements()
							})
							.catch((e) => {
								const errors = e.bodyError.globalErrors[0].arguments[0].errorsList
								const surfaceDestination = e.bodyError.globalErrors[0].arguments[0].plancheDestination
								const warnings = e.bodyError.globalErrors[0].arguments[0].warningsList

								if (errors.length > 0) {
									openDialog(
										<span className={classes.spanTitlePopin}>
											<WarningRoundedIcon className={classes.error}/>
											<FormattedMessage id="listAssolement.popinAlerte.errorTitle"/>
										</span>,
										<PopinDeplacement errors={errors} warnings={warnings} surfaceDestination={surfaceDestination}/>,
										[], TYPE_DIALOG.DETAILS
									)
								} else {
									openDialog(
										<span className={classes.spanTitlePopin}>
											<WarningRoundedIcon className={classes.warning}/>
											<FormattedMessage id="listAssolement.popinAlerte.warningsTitle"/>
										</span>,
										<PopinDeplacement errors={errors} warnings={warnings} surfaceDestination={surfaceDestination}/>,
										[
											<Button type="primary"
											        onClick={() => {
												        editAssolement(selectedAssolementsID, { ...values }, true, true)//ForceValidation true → V whatever
													        .then(() => {
														        snackSuccess({ id: success }, [{ onClick: (() => annulerModifications(selectedAssolements)), label: { id: 'actions.cancel' } }])
														        closeDialog()
														        refreshAssolements()
													        })
													        .catch(() => {
														        snackError({ id: erreurDeTraitement })
														        closeDialog()
													        })
											        }}
											>
												<FormattedMessage id="actions.confirm"/>
											</Button>
										], TYPE_DIALOG.ACTION
									)
								}
							})
					} else {
						snackError({ id: erreurDeTraitement })
					}
				})
		}

		const panelDeplacementCulture = (selectedAssolements, title, success, erreurDeTraitement, traitementImpossible) => {
			openPanel({ id: title },
				<DeplacerCulture onSubmit={(values) => submitEditAssolement(values, selectedAssolements, success, erreurDeTraitement, traitementImpossible)} selectedAssolements={selectedAssolements}/>, [
					<FormButton
						type="primary"
						formName={DEPLACER_CULTURE_FORM_NAME}
						getDisabledFromState={state => !formValueSelector(DEPLACER_CULTURE_FORM_NAME)(state, fields.LOCALISATION)}
					>
						<FormattedMessage id="actions.validate"/>
					</FormButton>
				])
		}

		const openEditCulturePlanche = (assolement) => {
			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]

			if (page === PAGE.PLANNING) {
				return panelDeplacementCulture(
					selectedAssolements,
					'planning.cultureForm.moveCulture',
					'snackbar.update.deplacerCulture',
					'snackbar.error.deplacerCulture',
					'snackbar.error.deplacerCulture2')
			}
			return panelDeplacementCulture(
				selectedAssolements,
				'listAssolement.forms.headers.deplacerCultures',
				'listAssolement.snackbar.ok.deplacerCultures',
				'listAssolement.snackbar.error.deplacerCultures')
		}
		return <WrappedComponent {...props} openEditCulturePlanche={React.useCallback(openEditCulturePlanche, [])}/>
	}

	const actions = {
		editAssolement,
		getPlanche,
		editListAssolement,
		getNotifications
	}

	EditCulturePlancheInjector.propTypes = {
		openCultureDetail: PropTypes.func,
		editAssolement: PropTypes.func,
		refreshAssolements: PropTypes.func,
		classes: PropTypes.object
	}

	return compose(
		loadAssolement,
		openCultureDetail,
		connect(undefined, actions),
		withStyles(styles)
	)(EditCulturePlancheInjector)
}
