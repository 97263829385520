import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { default as MuiRadio } from '@material-ui/core/Radio'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import withTheme from '@material-ui/core/styles/withTheme'

const styles = theme => getStyles({
	radio: {
		margin: '-8px 0'
	},
	label: {
		color: theme.palette.primary.main,
		fontSize: 13,
		fontWeight: 'bold',
		transform: 'scale(1)',
		paddingLeft: 10
	},
	radioGroup: {
		marginBottom: 15,
		paddingLeft: 10
	}
})

const Radio = ({ input, choices, classes, label, labelClasses, containerClass, disabled, ...otherProps }) => (
	<FormControl className={containerClass}>
		{label && <FormLabel component="legend" className={classes.label}>{label}</FormLabel>}
		<RadioGroup {...input} {...otherProps} className={classes.radioGroup}>
			{choices.map(choice => <FormControlLabel
				control={<MuiRadio className={classes.radio} disabled={disabled} />}
				key={choice.value}
				label={choice.label}
				value={choice.value}
				classes={{ label: labelClasses }}
			/>)}
		</RadioGroup>
	</FormControl>
)

Radio.propTypes = {
	label: PropTypes.string,
	choices: PropTypes.array,
	classes: PropTypes.object,
	disabled: PropTypes.bool
}

Radio.defaultProps = {
	disabled: false
}

export default compose(
	withStyles(styles),
	withTheme
)(Radio)
