import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, IconButton } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import AccessChecker from '../../../../components/security/AccessChecker'
import { colors } from '../../../../utils/constants'

const styles = () => getStyles({
	container: {
		position: 'relative',
		marginTop: 5
	},
	iconButton: {
		alignItems: 'baseline',
		color: colors.primary,
		position: 'absolute',
		bottom: 0,
		marginLeft: 16
	}
})

const EditableField = ({ content, type, dataToPass, access, action, classes, noEdit = false }) => (
	<p className={classes.container}>{content}
		<AccessChecker access={access}>
			{
				!noEdit && (
					<IconButton onClick={() => action(dataToPass)} className={classes.iconButton} size="small">
						<Edit />
					</IconButton>
				)
			}
		</AccessChecker>
	</p>
)

EditableField.propType = {
	content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	type: PropTypes.string,
	dataToPass: PropTypes.any,
	access: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	])
}

export default withStyles(styles)(EditableField)
