import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import PropTypes from 'prop-types'
import {KeyboardTimePicker} from '@material-ui/pickers'
// import CloseIcon from '@material-ui/icons/Close'
import {refreshDataViaTimePicker} from '../../gestionTachesApi'
import {now} from 'moment'
import {revertFormatTime, revertFormatTimev2} from '../../../../../utils/utils'
import {colors} from '../../../../../utils/constants'
import Grid from '@material-ui/core/Grid'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import classnames from "classnames";

const styles = () => getStyles({
	icon: {
		height: 14,
		width: 14,
		padding: 0,
		position: 'absolute',
		right: 8,
		top: 7
	},
	cellule: {
		fontSize: 25,
		color: colors.primary,
		backgroundColor: 'cyan'
	},
	container: {
		position: 'relative',
		padding: 0,
		fontSize: 16,
		fontFamily: 'Lato',
		textAlignLast: 'center',

		'& [class*="Mui-disabled"]': {
			color: 'black',

			'&::before': {
				borderBottomStyle: 'none'
			}
		}
	},
	boldValue: {
		'& [class*="MuiInputBase-input"]': {
			fontWeight: 800
		}
	}
})

const CellEditable = ({ classes, row, jour, duration, disabled, isDebut = false }) => {

	const { refreshHeaderData } = useGestionTacheContext()

	const today = new Date(now())
	const initDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), duration.split(':')[0], duration.split(':')[1], 0, 0)

	const [timeValue, setTimeValue] = React.useState(initDate)
	const [tempValue, setTempValue] = React.useState(initDate)
	// const [displayReset, setDisplayReset] = React.useState(false)

	const handleDateChange = (date) => {
		if (!Number.isNaN(Date.parse(date))) {
			//si date ok → temp prends time
			// time devient la nouvelle valeur
			setTempValue(timeValue)
			setTimeValue(date)
		}
	}

	const resetTo0 = () => {
		tempValue.setMinutes(0)
		tempValue.setHours(0)
		setTimeValue(tempValue)
	}

	const callToRefreshTimer = (nouvelleCharge) => {
		refreshDataViaTimePicker(row.id, jour, nouvelleCharge, isDebut)
			.then(refreshHeaderData)
	}

	const refreshTime = (event) => {
		var regex = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$')
		const valeurDuChamp = event.target.value

		//Champ non vide
		if (valeurDuChamp) {
			const heureDuChamp = parseInt(valeurDuChamp.split(':')[0])
			const minuteDuChamp = parseInt(valeurDuChamp.split(':')[1])

			//Date valide
			if (regex.test(valeurDuChamp)) {
				const heureTempValue = tempValue.getHours()
				const minuteTempValue = tempValue.getMinutes()

				//Heure différente
				if (heureDuChamp !== heureTempValue || minuteDuChamp !== minuteTempValue) {
					callToRefreshTimer(revertFormatTimev2(valeurDuChamp))
				}
			//Date invalide
			} else {
				if (heureDuChamp > 23 && minuteDuChamp > 59) {
					tempValue.setHours(23)
					tempValue.setMinutes(59)
					setTimeValue(tempValue)
					callToRefreshTimer(revertFormatTime(timeValue))

				} else if (minuteDuChamp > 59) {
					tempValue.setHours(heureDuChamp)
					tempValue.setMinutes(59)
					setTimeValue(tempValue)
					callToRefreshTimer(revertFormatTime(timeValue))

				} else if (heureDuChamp > 23) {
					tempValue.setHours(23)
					tempValue.setMinutes(minuteDuChamp)
					setTimeValue(tempValue)
					callToRefreshTimer(revertFormatTime(timeValue))

				} else {
					resetTo0()
					callToRefreshTimer(0)
				}
			}
		//Champ vide
		} else {
			resetTo0()
			callToRefreshTimer(0)
		}
		setTempValue(timeValue)
		// setDisplayReset(false)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && event.target.value) {
			refreshTime(event)
		}
	}

	return (
		<Grid container item direction="row" className={classnames(classes.container, !isDebut && classes.boldValue)}>
			<KeyboardTimePicker
				format="HH:mm"
				disabled={disabled}
				value={timeValue}
				invalidDateMessage={''}
				onChange={handleDateChange}
				onBlur={refreshTime}
				onKeyDown={handleKeyDown}
				KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
				onFocus={() => {
					setTempValue(timeValue)
					// setDisplayReset(true)
				}}
			/>
			{/*<CloseIcon className={classes.icon} style={displayReset ? { display: 'block' } : { display: 'none' }} onClick={() => {*/}
			{/*	resetTo0()*/}
			{/*	callToRefreshTimer(0)*/}
			{/*}} />*/}
		</Grid>
	)
}

CellEditable.propType = {
	classes: PropTypes.object,
	row: PropTypes.object,
	jour: PropTypes.element,
	duration: PropTypes.element,
	refreshHeaders : PropTypes.func
}

export default withStyles(styles)(CellEditable)
