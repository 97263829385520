import React from 'react'
import PropTypes from 'prop-types'

const PlantsIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 512 512"
	>
		<path
			fill="#0c5e7f"
			fillRule="evenodd"
			d="M7.994 66.474h496.012c4.394 0 7.994 3.6 7.994 7.993v304.671c0 4.393-3.6 7.994-7.994 7.994H7.994c-4.394 0-7.994-3.6-7.994-7.994V74.467c0-4.394 3.6-7.993 7.994-7.993z"
			clipRule="evenodd"
		/>
		<path
			fill="#174b67"
			fillRule="evenodd"
			d="M486.998 66.474h17.008c4.394 0 7.994 3.6 7.994 7.993v304.671c0 4.393-3.6 7.994-7.994 7.994H7.994c-4.394 0-7.994-3.6-7.994-7.994V362.13h461.996c13.776 0 25.002-11.254 25.002-25.002z"
			clipRule="evenodd"
		/>
		<path
			fill="#ffefd6"
			fillRule="evenodd"
			d="M45.213 21.26h165.574c17.178 0 31.663 9.979 39.827 23.869 3.203 5.442 7.653 5.471 10.771 0 8.051-13.975 22.678-23.869 39.828-23.869h165.602c4.394 0 7.994 3.601 7.994 7.995V341.92c0 4.422-3.6 8.022-7.994 8.022H45.213c-4.422 0-8.022-3.601-8.022-8.022V29.254c0-4.393 3.6-7.994 8.022-7.994z"
			clipRule="evenodd"
		/>
		<path
			fill="#ffe6c1"
			fillRule="evenodd"
			d="M449.807 21.26h17.008c4.394 0 7.994 3.601 7.994 7.995V341.92c0 4.422-3.6 8.022-7.994 8.022H45.213c-4.422 0-8.022-3.601-8.022-8.022v-16.98h397.62c8.249 0 14.996-6.747 14.996-14.996z"
			clipRule="evenodd"
		/>
		<path
			fill="#66b394"
			d="M139.098 133.117c0-4.139 3.373-7.512 7.512-7.512s7.484 3.373 7.484 7.512v59.415c0 4.139-3.345 7.484-7.484 7.484s-7.512-3.345-7.512-7.484z"
		/>
		<path
			fill="#b3e59f"
			fillRule="evenodd"
			d="M146.61 133.117c-10.885 7.965-27.128 8.702-41.018.68-11.112-6.435-18.113-16.923-19.729-27.752-1.191-7.937-.595-9.042 6.86-11.99 10.204-4.025 22.819-3.232 33.959 3.203 13.89 8.022 21.373 22.451 19.928 35.859z"
			clipRule="evenodd"
		/>
		<path
			fill="#95d6a4"
			fillRule="evenodd"
			d="M146.61 133.117c10.885 7.965 27.128 8.702 41.018.68 11.141-6.463 18.171-17.036 19.758-27.893 1.134-7.851.538-8.872-6.832-11.82-10.177-4.054-22.876-3.26-34.016 3.175-13.89 8.021-21.374 22.45-19.928 35.858z"
			clipRule="evenodd"
		/>
		<path
			fill="#0c5e7f"
			d="M72.88 241.997c-4.139 0-7.483-3.374-7.483-7.512A7.477 7.477 0 0 1 72.88 227h147.46a7.477 7.477 0 0 1 7.483 7.485c0 4.138-3.345 7.512-7.483 7.512zm0 62.958a7.476 7.476 0 0 1-7.483-7.483c0-4.167 3.345-7.512 7.483-7.512h120.701c4.139 0 7.483 3.345 7.483 7.512a7.476 7.476 0 0 1-7.483 7.483zm0-31.466c-4.139 0-7.483-3.373-7.483-7.512s3.345-7.512 7.483-7.512h147.46c4.138 0 7.483 3.373 7.483 7.512s-3.345 7.512-7.483 7.512zM296.564 168.891c-4.139 0-7.512-3.374-7.512-7.513 0-4.138 3.374-7.483 7.512-7.483h73.73a7.476 7.476 0 0 1 7.483 7.483c0 4.139-3.345 7.513-7.483 7.513z"
		/>
		<g fillRule="evenodd" clipRule="evenodd">
			<path
				fill="#ff6e6e"
				d="M433.763 66.474H297.075c-4.422 0-8.022 3.6-8.022 7.993v43.371c0 4.395 3.6 7.994 8.022 7.994h136.688c4.394 0 7.994-3.6 7.994-7.994V74.467c-.001-4.394-3.601-7.993-7.994-7.993z"
			/>
			<path fill="#ffd064" d="M390.959 213.423h50.797v206.222h-50.797z" />
			<path fill="#9dc6fb" d="M390.959 419.646h50.797v28.432h-50.797z" />
			<path
				fill="#ff6e6e"
				d="M441.756 448.077v17.264c0 13.975-11.424 25.399-25.398 25.399-13.976 0-25.399-11.424-25.399-25.399v-17.264z"
			/>
			<path
				fill="#9dc6fb"
				d="m419.93 169.797 21.827 43.626H390.96l21.827-43.626c1.898-3.798 5.215-3.883 7.143 0z"
			/>
			<path
				fill="#f5bc98"
				d="M261.386 45.128c-3.118 5.471-7.568 5.442-10.771 0a52.471 52.471 0 0 0-7.115-9.439v314.252h25.002V35.604a49.807 49.807 0 0 0-7.116 9.524z"
			/>
			<path
				fill="#ffc250"
				d="M441.756 323.21V213.423h-25.001v206.223h25.001V349.941z"
			/>
			<path
				fill="#80b4fb"
				d="M441.756 419.646h-25.001v20.693c0 2.692-.425 5.301-1.219 7.738h26.221v-28.431z"
			/>
			<path
				fill="#ff555e"
				d="M441.756 448.077h-26.221c-3.288 10.234-12.898 17.661-24.18 17.661h-.397c.226 13.805 11.565 25.002 25.399 25.002 13.975 0 25.398-11.424 25.398-25.399v-17.264z"
			/>
		</g>
	</svg>
)


PlantsIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

PlantsIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default PlantsIcon