import { Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { colors } from '../../../../utils/constants'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import { getFilterValues } from '../../planning/services/toolbar/toolbarSelector'
import { FILTER_CONNEXIONS_FORM } from './constants'
import Checkbox from '../../../../components/form/Checkbox'

const styles = () => getStyles({
	form: {
		width: 'calc(100% - 3rem)',
		padding: '1.5rem'
	},
	formFields: {
		width: '100%'
	},
	formInput: {
		'& > div': {
			padding: 0,
			marginBottom: '2.25rem'
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	link: {
		'&.MuiTypography-root': {
			color: colors.link,
			textDecoration: 'underline',
			cursor: 'pointer',
			textAlign: 'center',
			margin: '0.5rem 0 2.25rem'
		}
	},
	labelCheckbox: {
		fontSize: 14,
		marginTop: 4,
		margin: 0
	}
})

export const fields = {
	ETAT: 'etat',
	TODAY: 'today'
}

const FilterConnexionsForm = ({
	handleSubmit,
	change,
	classes,
	checkTodayValue
}) => {
	const onResetClick = (event) => {
		change(fields.ETAT, [])
		change(fields.TODAY, true)
		event.preventDefault()
	}

	const intl = useIntl()

	const ETATS_CONNEXION = [
		{ code: 'enLigne', label: intl.formatMessage({ id: 'suiviConnexions.state.online' }) },
		{ code: 'horsLigne', label: intl.formatMessage({ id: 'suiviConnexions.state.offline' }) }
	]

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Typography variant="body1" className={classes.link} onClick={onResetClick}>
				<FormattedMessage id="suiviConnexions.filter.reset" />
			</Typography>

			<Grid className={classes.formFields}>
				<Grid className={classes.formInput}>
					<Field
						name={fields.ETAT}
						component={AutocompleteMulti}
						label={<FormattedMessage id="suiviConnexions.filter.fields.state" />}
						staticOptions={ETATS_CONNEXION}
						formName={FILTER_CONNEXIONS_FORM}
					>
					</Field>
				</Grid>

				<Grid>
					<Field
						name={fields.TODAY}
						label={
							<p className={classes.labelCheckbox}>
								<FormattedMessage id="suiviConnexions.filter.fields.checkToday" />
							</p>
						}
						component={Checkbox}
						defaultChecked
						checked={checkTodayValue}
					>
					</Field>
				</Grid>
			</Grid>
		</form>
	)
}

FilterConnexionsForm.propType = {
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	change: PropTypes.func,
	checkTodayValue: PropTypes.bool
}

const mapStateToProps = state => ({
	initialValues: Object.keys(getFilterValues(state)).length > 0 ? getFilterValues(state) : { [fields.TODAY]: true },
	checkTodayValue: formValueSelector(FILTER_CONNEXIONS_FORM)(state, fields.TODAY)
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FILTER_CONNEXIONS_FORM,
		enableReinitialize: true
	}),
	withStyles(styles)
)(FilterConnexionsForm)
