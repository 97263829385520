import React, { useEffect, useState } from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { colors } from '../../../../../utils/constants'
import PlanSpatial from './component/SpatialPlan'
import CropsContent from './component/CropsContent'
import IconButton from '../../../../../components/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useTourPlaineContext } from '../../TourPlaineContext'
import { FormattedMessage } from 'react-intl'
import ActionTabs from '../../../../../components/layout/actionTab/ActionTabs'
import { BLOCK_ACTION_TYPE } from './tool/suiviParBloc.constants'
import TasksContent from './component/TasksContent'
import IrrigationContent from './component/IrrigationContent'
import BioAggressorsContent from './component/BioAggressorsContent'
import BoardsUnavailableContent from './component/BoardsUnavailableContent'
import { injectCultureDetailPanel } from '../../../planning/services/actions/detail/cultureDetailPanel'

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeBackground,
		maxHeight: '-webkit-fill-available'
	},
	plan: {
		marginTop: 20
	},
	content: {
		marginTop: 12
	},
	header: {
		gap: 24,
		marginBottom: 12
	},
	selectorBlockContainer: {
		gap: 24
	},
	selectorIconContainer: {
		height: 35,
		width: 35
	},
	selectorIcon: {
		height: 24,
		width: 24,
		color: '#0000008'
	},
	blockTitleText: {
		lineHeight: '17px',
		fontSize: 14,
		fontWeight: '600',
		textAlign: 'center'
	},
	blockDescriptionText: {
		lineHeight: '15px',
		fontSize: 12,
		fontWeight: '300',
		textAlign: 'center'
	}
})

/**
 * SuiviParBloc
 * @returns {JSX.Element}
 */
const SuiviParBloc = ({ classes }) => {

	const {
		data: { suiviBlock, suiviBlockActions },
		currentActionTab,
		setActionTab,
		loadSuiviBlockNotifs,
		initialBlockId,
		resetInitialBlockId
	} = useTourPlaineContext()

	const [selectedBlock, setSelectBlock] = useState()
	const [currentBlockIndex, setCurrentBlockIndex] = useState()

	const canGoNextBlock = currentBlockIndex + 1 < suiviBlock.listBlock.length
	const goNextBlock = () => {
		if (canGoNextBlock) {
			setSelectBlock(suiviBlock.listBlock[currentBlockIndex + 1])
		}
	}

	const canGoPreviousBlock = currentBlockIndex - 1 >= 0
	const goPreviousBlock = () => {
		if (canGoPreviousBlock) {
			setSelectBlock(suiviBlock.listBlock[currentBlockIndex - 1])
		}
	}

	const renderHeader = () => {
		return (
			<Grid item container direction="column" justify="center" alignItems="center" className={classes.header}>
				<Grid item container xs direction="row" justify="center" alignItems="center" className={classes.selectorBlockContainer}>
					<IconButton className={classes.selectorIconContainer} onClick={goPreviousBlock} disabled={!canGoPreviousBlock}>
						<ArrowBackIosIcon />
					</IconButton>
					<Grid item>
						<Typography className={classes.blockTitleText}>
							{selectedBlock.title}
						</Typography>
						<Typography className={classes.blockDescriptionText}>
							<FormattedMessage {...selectedBlock.description} />
						</Typography>
					</Grid>
					<IconButton className={classes.selectorIconContainer} onClick={goNextBlock} disabled={!canGoNextBlock}>
						<ArrowForwardIosIcon />
					</IconButton>
				</Grid>
				<ActionTabs actions={suiviBlockActions} selection={currentActionTab || BLOCK_ACTION_TYPE.CROPS} handleSelection={setActionTab} />
			</Grid>
		)
	}

	const renderBody = () => {
		switch (currentActionTab?.code) {
			case BLOCK_ACTION_TYPE.TASK.code:
				return <TasksContent block={selectedBlock} />
			case BLOCK_ACTION_TYPE.IRRIGATON.code:
				return <IrrigationContent block={selectedBlock} />
			case BLOCK_ACTION_TYPE.BIO_AGGRESSORS.code:
				return <BioAggressorsContent block={selectedBlock} />
			case BLOCK_ACTION_TYPE.BOARDS_UNAVAILABLE.code:
				return <BoardsUnavailableContent block={selectedBlock} />
			case BLOCK_ACTION_TYPE.CROPS.code:
			default:
				return <CropsContent block={selectedBlock} />
		}
	}

	/**
	 * UseEffect
	 */
	useEffect(() => {
		// redirect to the initial block
		if (initialBlockId && suiviBlock.listBlock.length) {
			setSelectBlock(suiviBlock.listBlock.find(block => block.uniqueId === initialBlockId))
			resetInitialBlockId()
		}
	}, [initialBlockId, suiviBlock.listBlock])

	useEffect(() => {
		const index = suiviBlock.listBlock.findIndex(block => block.uniqueId === selectedBlock?.uniqueId)
		setCurrentBlockIndex(index)
		if (!currentActionTab) {
			setActionTab(BLOCK_ACTION_TYPE.CROPS)
		}
		loadSuiviBlockNotifs(selectedBlock?.uniqueId)
	}, [selectedBlock])

	useEffect(() => {
		// Upon currentActionTab reset, reset the selectedBlock
		if (!currentActionTab) {
			setSelectBlock(undefined)
		}
	}, [currentActionTab])

	return (<div className={classes.root}>
		{
			selectedBlock ? (
				<div className={classes.content}>
					{renderHeader()}
					{renderBody()}
				</div>
			) : (
				<div className={classes.plan}>
					<PlanSpatial onSelect={setSelectBlock} />
				</div>
			)
		}
	</div>)
}

export default compose(
	injectCultureDetailPanel,
	withStyles(styles)
)(SuiviParBloc)