import MenuList from '@material-ui/core/MenuList'
import { push } from 'connected-react-router'
import { injectEntries, injectOpen } from 'isotope-client/components/menu/services/menuInjector'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PermasoftMenuEntry from './PermasoftMenuEntry'

export class PermasoftInternalMenu extends Component {

	render() {
		const { Component, entries, changeMenu, location, padding, closeModal } = this.props

		return (
			<Component
				value={location.pathname}
				onChange={changeMenu}
				style={{ outline: 'none' }}
			>
				{entries && entries.map((entry, index) => <PermasoftMenuEntry
					padding={padding}
					key={index}
					entry={entry}
					changeMenu={changeMenu}
					location={location}
					closeModal={closeModal}
				/>)}
			</Component>
		)
	}
}

PermasoftInternalMenu.propTypes = {
	location: PropTypes.object.isRequired,
	changeMenu: PropTypes.func.isRequired,
	entries: PropTypes.array,
	padding: PropTypes.number
}

PermasoftInternalMenu.defaultProps = {
	padding: 0,
	Component: MenuList
}

const actions = {
	changeMenu: (value) => dispatch => dispatch(push(value))
}

export const PermasoftConnectedMenu = connect(undefined, actions)(PermasoftInternalMenu)

export default compose(
	injectEntries,
	injectOpen,
	connect(undefined, actions)
)(PermasoftInternalMenu)
