/**
 * Formattage des values du reglage pour l'édition
 * @param reglage
 */
export const getReglageForInitValues = (reglage) => {
	return {
		id: reglage.id,
		nom: reglage.nom,
		codeReglage: reglage.codeReglage,
		description: reglage.description,
		urlInstruction: reglage.urlInstruction,
		categoriesTechniques: reglage.categoriesTechniques
	}
}

/**
 * Formattage des values du materiel pour l'édition
 * @param materiel
 */
export const getMaterielForInitValues = (materiel) => {
	return {
		id: materiel.id,
		nom: materiel.nom,
		stock: materiel.stock,
		quantiteLimite: materiel.quantiteLimite,
		categoriesTechniques: materiel.categoriesTechniques
	}
}

