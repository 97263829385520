import { Grid, withStyles } from '@material-ui/core'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import RepeatIcon from '@material-ui/icons/Repeat'
import { getStyles } from 'isotope-client'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/Button'
import FormButton from '../../../../components/form/FormButton'
import FormattedMessageWithBold from '../../../../components/FormattedMessageWithBold'
import { DialogContext } from '../../../../components/layout/dialog'
import { ActionPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { colors, PARAMETRES_TACHE, TYPE_DIALOG } from '../../../../utils/constants'
import { LocalDate } from '../../../../utils/date/local-date'
import { DateFormat } from '../../../../utils/date/model/date'
import CreerTache, { formName } from '../form/CreerTache'
import { useGestionTacheContext } from '../GestionTacheProvider'
import { getRecurrences } from '../gestionTachesApi'
import { createTache } from '../services/api'

const styles = () => getStyles({
	titrePopin: {
		display: 'flex',
		alignItems: 'center',
		gap: 15,
		letterSpacing: 0,
		width: 550,

		'& svg': {
			color: colors.primary
		}
	},
	contenuPopin: {
		padding: '10px 30px'
	}
})

/**
 * Injecteur pour l'ouverture du panel d'ajout d'une tâche
 */
export const injectCreateTask = (WrappedComponent) => {
	const CreateTaskInjector = ({ classes, ...props }) => {
		const { openPanel, closePanel, toggleClickAway } = useContext(ActionPanelContext)
		const { snackSuccess, snackError } = useSnackbar()
		const { semaineSelection, refreshHeaderData } = useGestionTacheContext()
		const { openDialog, closeDialog } = React.useContext(DialogContext)

		const formattedMessageBase = 'gestionTaches.ecranPlanification.ajout'

		const openPopinRecurrence = (values, onSuccess) => {
			if (values.parametres && values.parametres.includes(PARAMETRES_TACHE.RECURRENTE)) {
				toggleClickAway(false)
				const data = { ...values, jours: values.jours && values.jours.map(j => j.code) }
				getRecurrences(data).then(recurrences => {
					openDialog(
						<span className={classes.titrePopin}>
							<RepeatIcon/>
							<FormattedMessage id={`${formattedMessageBase}.popin.recurrence.title`}/>
						</span>,
						<span className={classes.contenuPopin}>
							<FormattedMessage
								id={`${formattedMessageBase}.popin.recurrence.texte`}
								values={{ culture: values.assolementLabel, surface: values.surfaceLabel }}
							/>
							<ul>
								{recurrences.map(r => <li>
									<FormattedMessageWithBold
										id={`${formattedMessageBase}.popin.recurrence.info`}
										values={{type: values.typeLabel, date: LocalDate.fromAPI(r).format(DateFormat.SHORT_DATE)}}
									/>
								</li>)}
							</ul>
						</span>,
						[
							<Button
								variant="text"
								withBorder={false}
								onClick={() => {
									handleCreateTask(data, onSuccess)
									toggleClickAway(true)
									closeDialog()
								}}
							>
								<FormattedMessage id="actions.confirm"/>
							</Button>
						], TYPE_DIALOG.ACTION
					)
				})
			} else {
				handleCreateTask(values, onSuccess)
			}
		}

		const handleCreateTask = React.useCallback((values, onSuccess) => {
			const date = values.parametres && values.parametres.includes(PARAMETRES_TACHE.RECURRENTE) ? LocalDate.fromString(semaineSelection) : LocalDate.fromJS(values.date)
			createTache(date.format(DateFormat.SHORT_DATE_WITH_DASH), { ...values, modele: values.modele }).then(() => {
				closePanel()
				snackSuccess({ id: `${formattedMessageBase}.success` })
				refreshHeaderData()
				if (onSuccess) {
					onSuccess()
				}
			}).catch(() => {
				snackError({ id: `${formattedMessageBase}.error` })
			})
		}, [closePanel, refreshHeaderData, semaineSelection, snackError, snackSuccess])

		const openCreateTask = React.useCallback((onSuccess) => {
			const buttons = [
				<FormButton
					type="primary"
					formName={formName}
				>
					<FormattedMessage id="actions.save"/>
				</FormButton>
			]

			openPanel(
				<Grid container alignItems="center" style={{ gap: 20 }}>
					<PlaylistAddIcon color="primary"/>
					<FormattedMessage id={`${formattedMessageBase}.title`}/>
				</Grid>,
				<CreerTache onSubmit={values => openPopinRecurrence(values, onSuccess)} semaineSelection={semaineSelection}/>,
				buttons
			)
		}, [closePanel, handleCreateTask, openPanel, semaineSelection])

		return <WrappedComponent
			{...props}
			openCreateTask={openCreateTask}
		/>
	}

	return withStyles(styles)(CreateTaskInjector)
}
