import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { PAGE, usePageContext } from '../../../../../components/layout/PageContext'
import { NOM_VUE_LISTE_ASSOLEMENT } from '../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { SORT } from '../../utils/constant'
import { initialFilter } from '../toolbar/toolbarReducer'
import { getFilterValues, getSortValue } from '../toolbar/toolbarSelector'
import { addAssolements, getAssolements } from './assolementAction'
import { getCurrentPage } from './assolementSelector'

export const loadAssolement = (WrappedComponent) => {
	const AssolementInjector = (props) => {
		const { currentCampagne, getAssolements, currentPage, addAssolements, currentFilter, currentSort, refreshListeAssolements, resetSelectedRows } = props
		const { page } = usePageContext()

		const formattedCurrentFilter = Object.entries(currentFilter).reduce((acc, [key, value]) => ({
			...acc,
			[key]: value.map(item => item.code)
		}), {})

		const getPrevious = (idCampagne = currentCampagne.id, filter = formattedCurrentFilter, sort = currentSort) => {
			const start = +currentPage.start
			if (start === 0) {
				return
			}
			return addAssolements(idCampagne, { ...filter, preview: currentCampagne.preview }, sort, start - 1, currentPage)
		}

		const getNext = (idCampagne = currentCampagne.id, filter = formattedCurrentFilter, sort = currentSort) => {
			if (!currentPage.empty && !currentPage.last) {
				addAssolements(idCampagne, { ...filter, preview: currentCampagne.preview }, sort, 1 + currentPage.end, currentPage)
			}
		}

		const refreshAssolements = (idCampagne = currentCampagne.id, filter = formattedCurrentFilter, sort = currentSort) => {
			switch (page) {
				case PAGE.LISTE:
					resetSelectedRows()
					return refreshListeAssolements()
				case PAGE.PLANNING:
					if (idCampagne) {
						return getAssolements(idCampagne, { ...filter, preview: currentCampagne.preview }, sort)
					}
					break
				default:
			}
		}


		const accessAssolement = (assolementId, idCampagne = currentCampagne.id, filter = formattedCurrentFilter, sort = currentSort) => {
			if (page === PAGE.PLANNING) {
				getAssolements(idCampagne, { ...filter, preview:currentCampagne.preview }, sort, assolementId)
			}
		}


		const memoizedGetPrevious = React.useCallback(getPrevious, [currentCampagne, currentFilter, currentSort, currentPage])
		const memoizedGetNext = React.useCallback(getNext, [currentCampagne, currentFilter, currentSort, currentPage])
		const memoizedRefreshAssolements = React.useCallback(refreshAssolements, [currentCampagne, currentFilter, currentSort, page])
		const memoizedAccessAssolement = React.useCallback(accessAssolement, [currentCampagne, currentFilter, currentSort])

		return <WrappedComponent
			{...props}
			refreshAssolements={memoizedRefreshAssolements}
			getPrevious={memoizedGetPrevious}
			getNext={memoizedGetNext}
			accessAssolement={memoizedAccessAssolement}
		/>
	}

	const mapStateToProps = state => ({
		currentFilter: getFilterValues(state),
		currentSort: getSortValue(state),
		currentCampagne: getCurrentCampagne(state),
		currentPage: getCurrentPage(state)
	})

	const actions = {
		getAssolements,
		addAssolements,
		refreshListeAssolements: () => dataTableActions.refresh(NOM_VUE_LISTE_ASSOLEMENT.LISTE_ASSOLEMENT),
		resetSelectedRows: () => dataTableActions.selectRows(NOM_VUE_LISTE_ASSOLEMENT.LISTE_ASSOLEMENT, [])
	}

	AssolementInjector.propTypes = {
		campagne: PropTypes.object,
		getAssolements: PropTypes.func.isRequired,
		currentFilter: PropTypes.object,
		currentSort: PropTypes.string
	}

	AssolementInjector.defaultProps = {
		currentFilter: initialFilter,
		currentSort: SORT.SURFACE_ET_SEMIS
	}

	return connect(mapStateToProps, actions)(AssolementInjector)
}

export const initAssolementList = (WrappedComponent) => {
	const AssolementInjector = (props) => {
		const { currentCampagne, refreshAssolements } = props
		React.useEffect(() => {
			if (currentCampagne && currentCampagne.id) {
				refreshAssolements()
			}
		}, [currentCampagne])

		return <WrappedComponent {...props} />
	}

	AssolementInjector.propTypes = {
		currentCampagne: PropTypes.object,
		refreshAssolements: PropTypes.func.isRequired
	}

	return loadAssolement(AssolementInjector)
}
