import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const styles = () => getStyles({
	container: {
		margin: '1.5em',
		fontFamily: 'Lato'
	}
})

const PopinSemaineNonDefinie = ({ classes }) => {

	return (
		<Grid className={classes.container}>
			<FormattedMessage id="gestionTaches.ecranRessources.popin.content.texte" />
		</Grid>
	)
}

PopinSemaineNonDefinie.propType = {
	classes: PropTypes.object
}


export default withStyles(styles)(PopinSemaineNonDefinie)