import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { colors } from '../../../utils/constants'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	},
	bloc: {
		marginBottom: 20
	},
	title: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: 16,
		marginBottom: 10
	},
	link: {
		color: colors.link,
		cursor: 'pointer'
	},
})

const DialogCookies = ({ classes }) => {
	return (
		<Grid className={classes.container}>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message1"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message2"/><a className={classes.link} href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a></Grid>
			<Grid className={classes.title}><FormattedMessage id="cookies.dialog.title1"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message3"/></Grid>
			<Grid className={classes.title}><FormattedMessage id="cookies.dialog.title2"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message4"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message5"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message6"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message7"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message8"/></Grid>
			<Grid className={classes.bloc}><FormattedMessage id="cookies.dialog.message9"/><a className={classes.link} href="https://www.cnil.fr/">www.cnil.fr</a></Grid>
		</Grid>)
}

export default withStyles(styles)(DialogCookies)
