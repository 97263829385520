/**
 * Class permettant de facilement définir des méthodes de comparaison.
 * Il suffit de définir `_compareTo` pour que toutes les méthodes soient disponibles.
 */
export class Comparable {
	_compareTo = (to) => {
	}

	gte = (to) => {
		return this._compareTo(to) !== -1
	}

	gt = (to) => {
		return this._compareTo(to) === 1
	}

	lte = (to) => {
		return this._compareTo(to) !== 1
	}

	lt = (to) => {
		return this._compareTo(to) === -1
	}

	eq = (to) => {
		return this._compareTo(to) === 0
	}

	static max(...comparable) {
		return comparable.reduce((max, e) => e.gt(max) ? e : max, comparable[0])
	}

	static min(...comparable) {
		return comparable.reduce((max, e) => e.lt(max) ? e : max, comparable[0])
	}
}
