import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const SemiPlancheOutlinedIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M497 242.989h-22v-76.492c0-8.284-6.716-15-15-15s-15 6.716-15 15v76.492h-21V127.497c0-8.284-6.716-15-15-15s-15 6.716-15 15v115.492h-21v-76.492c0-8.284-6.716-15-15-15s-15 6.716-15 15v76.492h-21V127.497c0-8.284-6.716-15-15-15s-15 6.716-15 15v115.492h-21v-76.492c0-8.284-6.716-15-15-15s-15 6.716-15 15v76.492h-21V127.497c0-8.284-6.716-15-15-15s-15 6.716-15 15v115.492h-21v-76.492c0-8.284-6.716-15-15-15s-15 6.716-15 15v76.492h-21V127.497c0-8.284-6.716-15-15-15s-15 6.716-15 15v115.492H67v-76.492c0-8.284-6.716-15-15-15s-15 6.716-15 15v76.492H15c-8.284 0-15 6.716-15 15v96.514c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-96.514c0-8.284-6.716-15-15-15zm-15 111.514c0 8.271-6.729 15-15 15H45c-8.271 0-15-6.729-15-15v-81.514h452v81.514z"
				fill={color}
			/>
		</svg>
	)
}

SemiPlancheOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

SemiPlancheOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default SemiPlancheOutlinedIcon
