import React from 'react'
import { MenuItem, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { CREER_GROUPE_DE_CULTURE_FORM_NAME } from '../../utils/constant'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { localCompare } from '../../../../../utils/utils'
import Input from '../../../../../components/form/Input'
import Select from '../../../../../components/form/Select'
import { injectZoneSurfaceValueList } from '../../../../common/valueLists/valueListInjectors'
import { getAssolements } from '../../../planning/services/assolement/assolementSelector'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import CheckboxGroup from '../../../../../components/form/CheckboxGroup'
import moment from 'moment'
import _ from 'lodash'
import { DEFAULT_LABEL } from '../../../../../utils/constants'

export const fields = {
	ASSOLEMENT: { name: 'assolement' },
	DATE_DEBUT: { name: 'dateDebut' },
	DATE_FIN: { name: 'dateFin' },
	NOM: { name: 'nom', size: 50 },
	DESCRIPTION: { name: 'description', size: 150 },
	ZONES: { name: 'zones' }
}

const styles = () => getStyles({
	form: {
		width: '100%',
		margin: 15
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	labelCheckbox: {
		fontSize: 14,
		marginBottom: 4,
		margin: 0
	},
	text: {
		marginTop: 4,
		fontSize: 14,
		marginLeft: 8,
		marginBottom: 22
	},
	fields: {
		marginTop: 22
	},
	dateDebutField: {
		float: 'left',
		width: '45%'
	},
	dateFinField: {
		float: 'right',
		width: '45%'
	},
	checkbox: {
		marginLeft: 8,
		fontSize: 14
	}
})

const formattedMessageBase = 'planning.cultureGroup.createGroup'

const requiredFields = [
	fields.NOM,
	fields.DESCRIPTION,
	fields.ZONES
]
const sizeCheckFields = [
	fields.NOM,
	fields.DESCRIPTION
]

const validate = values => {
	const errors = {}
	requiredFields.forEach(field => {
		if (!values[field.name] || values[field.name].length === 0) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	})
	sizeCheckFields.forEach(field => {
		if (values[field.name] && values[field.name].length > field.size) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.tooLong`, values: { sizeMax: field.size } }
		}
	})
	const cultures = Object.keys(values).filter(value => value.includes('assolement') && values[value])
	if (!cultures || cultures.length === 0) {
		errors[fields.DATE_DEBUT.name] = { id: `${formattedMessageBase}.errors.chooseOne` }
	}
	return errors
}

const CreerGroupeDeCulture = ({ handleSubmit, classes, zoneSurfaceList, currentAssolement, assolements, change, assolementValues, zoneValues }) => {
	React.useEffect(() => {
		change(fields.ZONES.name, new Array(currentAssolement.planche.zone))
	}, [])

	React.useEffect(() => {
		if (assolementValues && assolementValues.length > 0) {
			const datesDebut = getAssolementOfAPlanche().filter(assolement => assolementValues.includes(assolement.id)).map(culture => getDateDebutAssolement(culture)).sort()
			change(fields.DATE_DEBUT.name, moment(datesDebut[0]).format('DD/MM/YY'))
			const datesFin = getAssolementOfAPlanche().filter(assolement => assolementValues.includes(assolement.id)).map(culture => getDateFinAssolement(culture)).sort()
			change(fields.DATE_FIN.name, moment(datesFin[datesFin.length - 1]).format('DD/MM/YY'))
			const newZones = zoneValues
			zoneValues.forEach(zone => {
				if (!isZonePossible(zone)) {
					newZones.splice(zoneValues.indexOf(zone), 1)
				}
			})
			change(fields.ZONES.name, newZones)
		} else {
			change(fields.DATE_DEBUT.name, '')
			change(fields.DATE_FIN.name, '')
		}
	}, [assolementValues])

	const valueListToMenuItems = (valueList) => (
		valueList && valueList.map(element => {
			return <MenuItem key={element.code} value={element.code} classes={{ selected: classes.menuItem }}>
				{element.label}
			</MenuItem>
		})
	)

	const getAssolementOfAPlanche = () => {
		return Object.values(assolements).filter(assolement => assolement.culture && assolement.planche.nom === currentAssolement.planche.nom)
	}

	const getDateDebutAssolement = (assolement) => {
		const etapes = assolement.assolementEtapeList.length > 0 ? assolement.assolementEtapeList.filter(etape => !etape.deleted).sort(localCompare('dateDebut')) : []
		return etapes.length > 0 ? etapes[0].dateDebut : null
	}

	const getDateFinAssolement = (assolement) => {
		const etapes = assolement.assolementEtapeList.length > 0 ? assolement.assolementEtapeList.filter(etape => !etape.deleted).sort(localCompare('dateFin', true)) : []
		return etapes.length > 0 ? etapes[0].dateFin : null
	}

	const isZonePossible = (zone) => {
		if (assolementValues) {
			const selectedAssolements = getAssolementOfAPlanche().filter(assolement => assolementValues.includes(assolement.id))
			const arrayZone = selectedAssolements.map((assol) => assol.zone.split(';'))
			const localisationsDisponibles = _.intersection(...arrayZone)
			return localisationsDisponibles.includes(zone)
		}
		return currentAssolement.planche.zone === zone
	}

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.text}>
				<FormattedMessage id={`${formattedMessageBase}.text`} />
			</Grid>
			<Grid className={classes.checkbox}>
				<Field
					name={fields.ASSOLEMENT.name}
					component={CheckboxGroup}
					items={assolements && getAssolementOfAPlanche().map((assolement) => {
						const debutAssolement = getDateDebutAssolement(assolement)
						const finAssolement = getDateFinAssolement(assolement)
						const selected = assolementValues && assolementValues.includes(assolement.id)

						return {
							value: assolement.id,
							label: <FormattedMessageWithBold
								id={`${formattedMessageBase}.fields.${fields.ASSOLEMENT.name}${selected ? 'WithBold' : ''}`}
								values={{
									nom: assolement.culture.nom,
									debut: debutAssolement ? moment(debutAssolement).format('DD/MM') : DEFAULT_LABEL,
									fin: finAssolement ? moment(finAssolement).format('DD/MM') : DEFAULT_LABEL
								}}
							/>
						}
					})}
				/>
			</Grid>
			<Grid className={classes.fields}>
				<Field
					name={fields.DATE_DEBUT.name}
					component={Input}
					disabled
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.DATE_DEBUT.name}`} />}
					containerClassName={classes.dateDebutField}
				/>
				<Field
					name={fields.DATE_FIN.name}
					component={Input}
					disabled
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.DATE_FIN.name}`} />}
					containerClassName={classes.dateFinField}
				/>
			</Grid>
			<Grid>
				<Field
					name={fields.NOM.name}
					component={Input}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.NOM.name}`} />}
					inputProps={{
						maxLength: fields.NOM.size,
					}}
				/>
			</Grid>
			<Grid>
				<Field
					name={fields.DESCRIPTION.name}
					component={Input}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.DESCRIPTION.name}`} />}
					inputProps={{
						maxLength: fields.DESCRIPTION.size,
					}}
				/>
			</Grid>
			<Grid>
				<Field
					name={fields.ZONES.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.ZONES.name}`} />}
					multiple
				>
					{valueListToMenuItems(zoneSurfaceList.filter(zone => isZonePossible(zone.code)))}
				</Field>
			</Grid>
		</form>
	)
}

CreerGroupeDeCulture.propTypes = {
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	zoneSurfaceList: PropTypes.array,
	currentAssolement: PropTypes.object,
	assolements: PropTypes.object,
	change: PropTypes.func,
	assolementValues: PropTypes.array,
	zoneValues: PropTypes.array
}

const mapStateToPros = state => ({
	campagne: getCurrentCampagne(state),
	assolements: getAssolements(state),
	assolementValues: formValueSelector(CREER_GROUPE_DE_CULTURE_FORM_NAME)(state, fields.ASSOLEMENT.name),
	zoneValues: formValueSelector(CREER_GROUPE_DE_CULTURE_FORM_NAME)(state, fields.ZONES.name)
})

export default compose(
	connect(mapStateToPros),
	withStyles(styles),
	injectZoneSurfaceValueList,
	reduxForm({
		form: CREER_GROUPE_DE_CULTURE_FORM_NAME,
		destroyOnUnmount: true,
		validate
	})
)(CreerGroupeDeCulture)
