import React from 'react'
import { Grid, MenuItem, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import { injectVarieteCultureValueList } from '../../../common/valueLists/valueListInjectors'
import Select from '../../../../components/form/Select'
import { VALUE_LIST_SHORTCUTS } from '../../../../utils/constants'
import { getFilteredLists } from '../../../common/valueLists/valueListsSelectors'
import {getEspeceListByFamille, getFamilleList} from "../../familleEspece/services/familleEspeceApi";
import {getPreferences} from "../../../common/user/services/userSelectors";

const styles = theme => getStyles({
	field: {
		width: '50%'
	},
	fieldAll: {
		width: '100%'
	},
	row: {
		marginBottom: 15
	},
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary,
		paddingLeft: 10,
		paddingRight: 50
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	}
})

const formattedMessageBase = 'bo.cultureItk.form'

const FIELDS = {
	NOM: { name: 'nom', size: 100 },
	FAMILLE: { name: 'famille' },
	ESPECE: { name: 'espece' },
	ID_FAMILLE: { name: 'idFamille' },
	ID_ESPECE: { name: 'idEspece' },
	VARIETE: { name: 'variete', optional: true },
	CULTIVAR: { name: 'cultivar', optional: true, size: 150 },
	PROD_MINO: { name: 'prodMino' },
	COMMENTAIRE: { name: 'commentaire', optional: true, size: 250 }
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})
	Object.values(FIELDS).filter(field => field.size).forEach(field => {
		if (values[field.name] && values[field.name].length > field.size) {
			errors[field.name] = { id: 'global.errors.sizeExceeded' }
		}
	})

	return errors
}

const CultureForm = ({
	varieteCultureList,
	idFerme,
	classes,
	handleSubmit,
	familleSelected,
	change
}) => {
	const [especes, setEspeces] = React.useState([])
	const [familles, setFamilles] = React.useState([])

	React.useEffect(() => {
		getFamilleList({idFerme: idFerme})
			.then(setFamilles)
	}, [])

	React.useEffect(() => {
		if (familleSelected) {
			getEspeceListByFamille({idFerme: idFerme, codeFamille: familleSelected})
				.then(setEspeces)
		}
	}, [familleSelected])

	const resetValuelistField = (level) => {
		if (level === 1) {
			change(FIELDS.ESPECE.name, null)
		}
		change(FIELDS.VARIETE.name, null)
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NOM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.NOM.name}`} />}
						fromBo
						inputProps={{
							maxLength: FIELDS.NOM.size
						}}
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.FAMILLE.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.FAMILLE.name}`} />}
						fromBo
						onChange={(event) => {
							change(FIELDS.ID_FAMILLE.name, familles.find(famille => famille.codeFamille === event.target.value).id)
							resetValuelistField(1)
						}}
					>
						{familles.map(famille => <MenuItem key={`famille-${famille.codeFamille}`} value={famille.codeFamille}>
							{famille.name}
						</MenuItem>)}
					</Field>
				</Grid>
			</Grid>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.ESPECE.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.ESPECE.name}`} />}
						fromBo
						onChange={(event) => {
							change(FIELDS.ID_ESPECE.name, especes.find(espece => espece.codeEspece === event.target.value).id)
							resetValuelistField(2)
						}}					>
						{especes?.map(espece => <MenuItem key={`espece-${espece.codeEspece}`} value={espece.codeEspece}>
							{espece.name}
						</MenuItem>)}
					</Field>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.VARIETE.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.VARIETE.name}`} />}
						fromBo
					>
						{varieteCultureList.map(variete => <MenuItem key={`variete-${variete.code}`} value={variete.code}>
							{variete.label}
						</MenuItem>)}
					</Field>
				</Grid>
			</Grid>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.CULTIVAR.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.CULTIVAR.name}`} />}
						fromBo
						inputProps={{
							maxLength: FIELDS.CULTIVAR.size
						}}
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.PROD_MINO.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.PROD_MINO.name}`} />}
						fromBo
						type="number"
						adornment="%"
					/>
				</Grid>
			</Grid>
			<Grid container className={classes.row}>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.COMMENTAIRE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.culture.${FIELDS.COMMENTAIRE.name}`} />}
						fromBo
						inputProps={{
							maxLength: FIELDS.COMMENTAIRE.size
						}}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

CultureForm.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	idFerme: PropTypes.string,
	varieteCultureList: PropTypes.array,
	familleSelected: PropTypes.string,

	classes: PropTypes.object
}

const mapStateToProps = (state, { formName }) => {
	const formSelector = formValueSelector(formName)
	const especeSelected = formSelector(state, FIELDS.ESPECE.name)

	return {
		form: formName,
		idFerme: (getPreferences(state).FERME_ID || '0'),
		varieteCultureList: getFilteredLists(state, especeSelected, VALUE_LIST_SHORTCUTS.VARIETE_CULTURE),
		familleSelected: formSelector(state, FIELDS.FAMILLE.name)
	}
}

const actions = {
	change
}

export default compose(
	injectVarieteCultureValueList,
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(CultureForm)
