import { Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React, {useState} from 'react'
import { compose } from 'redux'
import { colors } from '../../../../../../utils/constants'
import Button from "../../../../../../components/Button";
import {updateStockMateriel} from "../service/stockMaterielApi";
import {useSnackbar} from "../../../../../../components/layout/snackbar/SnackbarContext";
import PropTypes from "prop-types";

/**
 * Styles
 */
const styles = () => getStyles({
	content: {
		borderRadius: 10,
		border: `1px solid ${colors.lightPrimary}`,
		fontFamily: 'Lato'
	},
	buttons: {
		margin: 10,
		minWidth: 35,
		height: 35,
		'&.MuiButton-root': {
			padding: 0
		}
	},
	buttonsDiv: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		'&.MuiTypography-body1': {
			padding: 10,
			textAlign: 'center'
		}
	}
})

/**
 * StockCard
 * @param classes
 * @param stockMateriel
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
const StockCard = ({
	classes,
	stockMateriel,
	index,
	isEditable
}) => {
	const [stockValue, setStockValue] = useState(stockMateriel.stock)
	const { snackSuccess, snackError } = useSnackbar()

	const updateStockValue = React.useCallback((nextStockValue) => {
		return updateStockMateriel({ id: stockMateriel.id, stock: nextStockValue })
			.then(() => {
				setStockValue(nextStockValue)
				snackSuccess({ id: 'tourPlaine.stockMateriel.toaster.sucess' })
			})
			.catch(() => snackError({ id: 'tourPlaine.stockMateriel.toaster.echec' }))
	}, [stockValue])

	const incrementStock = React.useCallback(() => {
		return updateStockValue(stockValue + 1)
	}, [updateStockValue])

	const decrementStock = React.useCallback(() => {
		if (stockValue > 0) {
			return updateStockValue(stockValue - 1)
		}
		return Promise.resolve()
	}, [updateStockValue])

	return (
		<Grid container justify="center" alignItems={"center"} className={classes.content} key={index} style={{height: isEditable ? '15%' : '10%'}}>
			<Grid item container justify="center" alignItems="flex-end">
				<Typography className={classes.title} style={{padding: isEditable ? 10 : 0}}>
					{stockMateriel.nom}
				</Typography>
			</Grid>
			<Grid item container className={classes.buttonsDiv}>
				{isEditable && <Button className={classes.buttons} type="secondary" keepCase onClick={decrementStock}>-</Button>}
				{stockValue}
				{isEditable && <Button className={classes.buttons} type="secondary" keepCase onClick={incrementStock}>+</Button>}
			</Grid>
		</Grid>
	)
}

StockCard.propTypes = {
	isEditable: PropTypes.bool
}

export default compose(
	withStyles(styles))(StockCard)