import React from 'react'
import PropTypes from 'prop-types'

const FinRecolteIcon = ({ height, width, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M412.519 131.338a75.204 75.204 0 00-19.928 2.45c-17.719-15.315-39.952-23.648-63.721-23.648-23.773 0-46.052 8.339-63.773 23.667a78.329 78.329 0 00-19.456-2.473c-42.295 0-76.694 34.235-76.707 76.317l-.455 121.973c-.015 3.988 1.564 7.908 4.384 10.734 2.821 2.825 6.655 4.504 10.651 4.504H473.66c8.301 0 14.341-6.897 14.341-15.181V208.647c-.001-42.212-33.672-76.892-75.482-77.309z"
				fill="#a67955"
			/>
			<path
				d="M328.247 110.141c-23.476.121-45.559 8.45-63.15 23.665a78.323 78.323 0 00-19.456-2.473c-42.295 0-76.694 34.235-76.707 76.317l-.456 121.973c-.015 3.988 1.564 7.908 4.384 10.734 2.821 2.825 6.655 4.504 10.651 4.504h144.622l.112-234.72z"
				fill="#dea06d"
			/>
			<path
				d="M501.682 274.345c-6.633-6.621-15.45-10.484-24.837-10.484h-.094c-9.394 0-18.23 4.206-24.885 10.848l-46.671 47.152h-37.947c-40.075 0-79.092-14-110.689-38h93.964c19.218 0 35.216-15.534 35.663-34.416.226-9.534-3.319-18.359-9.982-25.168-6.683-6.829-15.644-10.417-25.231-10.417H107.559c-1.535 0-3.051.085-4.434.751l-79.769 38.297c-19.551 9.42-28.491 32.467-20.354 52.552l21.714 53.57c8.302 20.494 31.169 30.778 52.057 23.432l61.655-21.682 156.511 40.74c.84.219 1.705.341 2.573.341h118.6c7.753 0 15.252-3.242 20.572-8.866l65.112-68.822c6.582-6.626 10.204-15.521 10.204-24.857 0-9.391-3.665-18.332-10.318-24.971z"
				fill="#d19988"
			/>
			<path
				d="M328.246 317.444c-25.954-5.891-50.491-17.483-71.687-33.583h71.688v-70H107.559c-1.535 0-3.051.085-4.434.751l-79.769 38.297C3.805 262.328-5.136 285.375 3.002 305.46l21.715 53.57c8.301 20.494 31.169 30.778 52.057 23.432l61.654-21.682 156.511 40.74c.84.219 1.705.341 2.573.341h30.727l.007-84.417z"
				fill="#ffbd9e"
			/>
		</svg>
	)
}

FinRecolteIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

FinRecolteIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default FinRecolteIcon
