import {Box, Grid, Typography, withStyles} from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React, {useEffect, useState} from 'react'
import { FormattedMessage } from 'react-intl'
import {getStockMateriel} from "../../tourPlaine/component/stockMateriel/service/stockMaterielApi";
import StockCard from "../../tourPlaine/component/stockMateriel/component/StockCard";


const styles = () => getStyles({
	root: {
		zIndex: 100,
	},
	container: {
		fontSize: 14,
		padding: 0
	},
	text: {
		paddingBottom: 15,
		paddingTop: 15,
		fontFamily: 'Lato',
		fontStyle: "italic",
		textAlign: 'left',
	},
	blocDiv: {
		overflowY: 'auto',
		alignContent: 'flex-start',
		fontSize: 15
	}
})

const MaterielsBloc = ({ classes, isSmallScreen, isMediumScreen }) => {
	const [stockMaterielList, setStockMaterielList] = useState([])

	useEffect(() => {
		getStockMateriel()
			.then(stockMateriel => setStockMaterielList(stockMateriel))
	}, [])

	return(
	<Box container className={classes.root}>
		<Grid container justify={"flex-start"} alignItems={"center"} direction={"row"} className={classes.container}>
			<Typography className={classes.text} variant="p">
				<FormattedMessage id="tdb.blocs.materiels.description"/>
			</Typography>
			<Grid container spacing={1} justify={"flex-start"} alignItems={"flex-start"} className={classes.blocDiv} style={{height: isSmallScreen ? 200 : isMediumScreen ? 320 : 240}}>
				{stockMaterielList.map((stockMateriel, index) => (
					<Grid item xs={4} key={index}>
						<StockCard
							stockMateriel={stockMateriel}
							index={index}
							isEditable={false}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	</Box>
)}

export default withStyles(styles)(MaterielsBloc)