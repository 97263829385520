import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectZoneSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_VALUE_LIST_ITEM } from '../../../utils/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'

/** Composant qui permet l'affichage des informations d'un bloc **/

const InfoBloc = ({
	bloc,
	zoneSurfaceList
}) => {
	return <FormattedMessage
		id="bo.blocSurface.descriptionBloc"
		values={{
			zone: (zoneSurfaceList.find(x => x.code === bloc.zone) || DEFAULT_VALUE_LIST_ITEM).label.trim()
		}}
	/>
}

InfoBloc.propTypes = {
	bloc: PropTypes.object,
	zoneSurfaceList: PropTypes.array
}

export default compose(injectZoneSurfaceValueList)(InfoBloc)
