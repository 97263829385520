import { fetchFactory, urlUtils } from 'isotope-client'

/**
 * Récupération de la liste des materiels
 */
export const getMateriels = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/reglage-materiel', filters))

/**
 * Mise à jour d'un reglage
 */
export const updateReglage = (idMateriel, values) => fetchFactory('/bo/reglage-materiel/reglage', {
	method: 'PUT',
	body: JSON.stringify({
		idMateriel,
		...values
	})
})

/**
 * Création d'un reglage
 */
export const createReglage = (values) => fetchFactory('/bo/reglage-materiel/reglage', {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Mise à jour d'un materiel
 */
export const updateMateriel = (idFerme, values) => fetchFactory('/bo/reglage-materiel/materiel', {
	method: 'PUT',
	body: JSON.stringify({
		idFerme,
		...values
	})
})

/**
 * Création d'un materiel
 */
export const createMateriel = (idFerme, values) => fetchFactory('/bo/reglage-materiel/materiel', {
	method: 'POST',
	body: JSON.stringify({
		idFerme,
		...values
	})
})