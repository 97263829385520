import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'

const styles = theme => getStyles({
	field: {
		width: '50%'
	},
	fieldAll: {
		width: '100%'
	},
	row: {
		marginBottom: 15
	},
})

const formattedMessageBase = 'bo.reglagesMateriels.form'

const FIELDS = {
	NOM: { name: 'nom' },
	CODE: { name: 'codeReglage' },
	DESCRIPTION: { name: 'description', optional: true },
	INSTRUCTION: { name: 'urlInstruction', optional: true },
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})
	return errors
}

const ReglageForm = ({
	classes,
	handleSubmit,
}) => {

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NOM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.reglage.${FIELDS.NOM.name}`} />}
						fromBo
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.CODE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.reglage.${FIELDS.CODE.name}`} />}
						fromBo
					/>
				</Grid>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.DESCRIPTION.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.reglage.${FIELDS.DESCRIPTION.name}`} />}
						fromBo
					/>
				</Grid>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.INSTRUCTION.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.reglage.${FIELDS.INSTRUCTION.name}`} />}
						fromBo
					/>
				</Grid>
			</Grid>
		</form>
	)
}

ReglageForm.propTypes = {
	noDeleteOnUnmout: PropTypes.bool,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state, { formName, noDeleteOnUnmout }) => {
	return {
		form: formName,
		destroyOnUnmount: !noDeleteOnUnmout
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(ReglageForm)
