import { combineReducers } from 'redux'
import { SELECT_TACHE } from './tacheAction'

const detailPanelType = (state = null, action) => {
	switch (action.type) {
		case SELECT_TACHE:
			return action.panelType
		default:
			return state
	}
}

const currentTache = (state = {}, action) => {
	switch (action.type) {
		case SELECT_TACHE:
			return action.currentTache
		default:
			return state
	}
}

const taches = combineReducers({
	detailPanelType,
	currentTache
})

export default taches