import { fetchFactory, urlUtils } from 'isotope-client'
import { buildUrlWithParams } from 'isotope-client/utils/urlUtils'

export const getPlanificationTotaux = (date, filtres) => fetchFactory('/taches/planification/totaux', {
	method: 'POST',
	body: JSON.stringify({
		date,
		...filtres
	})
})

export const getAffectationTotaux = (date, filtres) => fetchFactory('/taches/affectations/totaux', {
	method: 'POST',
	body: JSON.stringify({
		date,
		...filtres
	})
})

export const getOrdonnancementTotaux = (date, filtres) => fetchFactory('/taches/ordonnancement/totaux', {
	method: 'POST',
	body: JSON.stringify({
		date,
		...filtres
	})
})

export const getAffectations = (date, page, size, filtres) => fetchFactory(
	urlUtils.buildUrlWithParams('/taches/affectations', {
		page,
		size
	}),
	{
		method: 'POST',
		body: JSON.stringify({
			date,
			...filtres
		})
	}
)

export const getOrdonnancement = (date, filtres) => fetchFactory('/taches/ordonnancement', {
	method: 'POST',
	body: JSON.stringify({
		date,
		...filtres
	})
})


export const updateOrder = (selected, dropId, jour) => fetchFactory('/taches/ordonnancement/reorder', {
	method: 'post',
	body: JSON.stringify({
		selected,
		dropId,
		jour
	})
})

export const getModelesTacheTypes = (famille) => fetchFactory(`/taches/modelesTache/types/${famille}`)

export const getModelesTaches = (famille, type, assolement, surface) => fetchFactory(`/taches/modeleTache/${famille}/${type}/${surface}/${assolement !== '0' ? assolement : 0}`)

export const getAllModeleTache = (idFerme, isGenerique = null, itkId = null, etape = null, typeEntity = null, moTacheId = null) => fetchFactory(buildUrlWithParams(`/bo/modeleTache/filter`, { idFerme, isGenerique, itkId, etape, typeEntity, moTacheId }))

export const getAllPlanchesWithoutId = () => fetchFactory('/planche')

export const createTache = (semaine, values) => fetchFactory(`/taches/createTaches/${semaine}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const updateTache = (id, values) => fetchFactory(`/taches/updateTache/${id}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const validerPlanification = (semaine) => fetchFactory('/taches/valider-semaine', {
	method: 'post',
	body: JSON.stringify({ semaine })
})

export const getSyntheseDatas = () => fetchFactory('/taches/synthese')

export const getSyntheseDetailDatas = (tachesPlanifiees) => fetchFactory(urlUtils.buildUrlWithParams('/taches/synthese/detail', {tachesPlanifiees}))