import {Grid, Box, Typography, withStyles, Fab} from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import {compose} from "redux";
import {colors} from "../../../../utils/constants";
import PropTypes from "prop-types";
import TouchApp from '@material-ui/icons/TouchApp'
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add'
import { push } from 'connected-react-router'
import {connect} from "react-redux";
import {TOUR_PLAINE_TABS} from "../../tourPlaine/tool/tourPlaine.constants";
import {injectReminderForm} from "../../tourPlaine/service/reminderFormInjector";
import { useIntl, FormattedMessage } from 'react-intl'


const styles = () => getStyles({
	root: {
		zIndex: 100,
		position: 'relative',
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: 10
	},
	container: {
		padding: 15
	},
	head: {
		fontWeight: 'bold',
		letterSpacing: 1,
		color: colors.primary,
		paddingLeft: 20
	},
	detail: {
		marginLeft: 'auto',
		color: colors.primary,
		fontFamily: 'Lato',
		paddingTop: 0,
		"&:hover": {
			backgroundColor: colors.emptyColor
		},
	},
	addCommentaire: {
		marginLeft: 'auto',
		width: 30,
		height: 30,
		minHeight: 30,
	},
	textDetail: {
		paddingLeft: 10,
		fontSize: 14,
		textTransform: 'none',
		paddingTop: 5,
		weight: 100
	}
})

const HeaderBloc = ({ classes, children, icon : Icon, title, urlDetail, height, isCommentaire, goToTourPlaine, goToMateriel, openReminderForm, setRefreshNote, refreshNote }) => {
	const { formatMessage } = useIntl()
	return(
	<Box container style={{height: height}} className={classes.root}>
		<Grid container justify={"flex-start"} direction={"row"} flex className={classes.container}>
			<Grid container style={{maxHeight: 35}}>
				<Icon/>
				<Typography variant="h3" className={classes.head}>
					<FormattedMessage id={`tdb.blocs.${title}`}/>
				</Typography>
				{urlDetail &&
					<Button onClick={() => urlDetail === 'materiel' ? goToMateriel() : goToTourPlaine()} className={classes.detail}>
						<TouchApp/>
						<Typography className={classes.textDetail} variant="p">
							<FormattedMessage id={`tdb.detail`}/>
						</Typography>
					</Button>
				}
				{isCommentaire &&
					<Fab
						onClick={() => openReminderForm(`${formatMessage({ id: 'tdb.blocs.commentaires.label' })}`, setRefreshNote, refreshNote)}
						color="primary" className={classes.addCommentaire}>
						<AddIcon />
					</Fab>
			}
			</Grid>
			{children}
		</Grid>
	</Box>
)}

HeaderBloc.propTypes = {
	icon: PropTypes.elementType,
	children: PropTypes.node,
	title: PropTypes.string,
	urlDetail: PropTypes.string,
	isCommentaire: PropTypes.bool,
	openReminderForm: PropTypes.func,
	height: PropTypes.number
}

const actions = {
	goToTourPlaine: () => dispatch => dispatch(push('/tour-plaine')),
	goToMateriel: () => dispatch => dispatch(push('/tour-plaine', { tab: TOUR_PLAINE_TABS.SUIVI_MATERIELS.code }))
}

export default compose(
	injectReminderForm,
	connect(null, actions),
	withStyles(styles))(HeaderBloc)