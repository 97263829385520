import React from 'react'
import { getAllTacheByIdUser, isConnectionOk } from '../mobileApi'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { useMobileContext } from '../MobileProvider'
import moment from 'moment'
import { ETAT_TACHE_UPPERCASE } from '../../../../utils/constants'

const Socket = () => {
	const { tache, setStart, setTache, start, setTachesNonTerminees, setTachesTerminees, setInitialScreenSize } = useMobileContext()
	const { snackError } = useSnackbar()
	const idTache = tache ? tache.id : null

	const checkConnection = (premiereTache) => isConnectionOk(start ? moment().diff(start, 'minutes') : null, premiereTache || idTache)
		.then((res) => {
			//Relance le timer si la tache etait en cours, sinon pas besoin
			if (res && res.etatTacheUser === ETAT_TACHE_UPPERCASE.EN_COURS) {
				setStart(moment())
				setTache(res)
			} else {
				setStart(null)
			}
		})
		.catch(() => snackError({ id: 'mobile.bandeau' }))

	React.useEffect(() => {
		getAllTacheByIdUser()
			.then((res) => {
				setTachesNonTerminees(res.tachesNonTerminees)
				setTachesTerminees(res.tachesTerminees)
				setTache(res.premiereTacheARealiser)
				setInitialScreenSize(window.innerHeight)
				checkConnection(res.premiereTacheARealiser && res.premiereTacheARealiser.id)
			})
			.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
	}, [snackError])

	React.useEffect(() => {
		const interval = setInterval(() => {
			checkConnection()
		}, 300000);
		return () => clearInterval(interval)
	}, [snackError, setStart, start, idTache, tache, setTache])

	return <></>
}

export default Socket
