import { ETAPE_TYPE, TYPE_ITK_BO } from '../../../utils/constants'

/**
 * Formattage des values de l'itk pour l'édition
 * @param itk
 */
export const getItkForInitValues = (itk) => {
	const isSemisD = !!itk.details.find(etape => etape.typeEtape === ETAPE_TYPE.SEMI_DIRECT)

	return {
		id: itk.id,
		nomItk: itk.designation,
		numItk: itk.numItk,
		debutSemis: itk.debutSemis,
		nbSemainesSemis: itk.dureeSemis,
		zone: itk.zone && itk.zone.split(';'),
		surface: itk.typeSurface,
		nbRangs: itk.nbRang,
		densite: itk.densite,
		espacement: itk.espacement,
		nbGraines: itk.nbGraines,
		saison: itk.saison,
		voileThermique: itk.voileThermique,
		prodTheo: itk.rendementTheo,
		typeItk: isSemisD ? TYPE_ITK_BO.SEMID : TYPE_ITK_BO.SEMIC,
		debutSemisC: getTimelineForEtape(itk.details, ETAPE_TYPE.SEMI_EN_CONTENANT),
		debutSemisD: getTimelineForEtape(itk.details, ETAPE_TYPE.SEMI_DIRECT),
		debutPreparation: getTimelineForEtape(itk.details, ETAPE_TYPE.PREP_PLANCHE),
		debutImplantation: getTimelineForEtape(itk.details, ETAPE_TYPE.IMPLANTATION),
		debutRecolte: getTimelineForEtape(itk.details, ETAPE_TYPE.RECOLTE),
		dureeRecolte: getDureeForRecolte(itk.details),
		finRecolte: getTimelineForEtape(itk.details, ETAPE_TYPE.FIN_RECOLTE),
		campagnes: itk.campagnes
	}
}

export const getAssolementForInitValues = (assolement, typeSurface) => {
	const isSemisD = !!assolement.groupesEtape.find(etape => etape.typeEtape === ETAPE_TYPE.SEMI_DIRECT)

	return {
		id: assolement.id,
		idCulture: assolement.culture.id,
		idItk: assolement.itk.id,
		debutSemis: assolement.timelineDebutSemis,
		nbSemainesSemis: assolement.dureeSemis,
		surface: typeSurface,
		nbRangs: assolement.nbRangs,
		densite: assolement.densite,
		espacement: assolement.espacement,
		nbGraines: assolement.nbGraines,
		saison: assolement.saison,
		voileThermique: assolement.voileThermique,
		timelineItk: assolement.timeline,
		typeItk: isSemisD ? TYPE_ITK_BO.SEMID : TYPE_ITK_BO.SEMIC,
		debutSemisC: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.SEMI_EN_CONTENANT),
		debutSemisD: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.SEMI_DIRECT),
		debutPreparation: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.PREP_PLANCHE),
		debutImplantation: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.IMPLANTATION),
		debutRecolte: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.RECOLTE),
		dureeRecolte: getDureeForRecolte(assolement.groupesEtape),
		finRecolte: getTimelineForGroupeEtape(assolement.groupesEtape, ETAPE_TYPE.FIN_RECOLTE),
		prodTheo: assolement.rendementTheo
	}
}

/**
 * Formattage des values de la culture pour l'édition
 * @param culture
 */
export const getCultureForInitValues = (culture) => {
	return {
		id: culture.id,
		nom: culture.nom,
		famille: culture.famille,
		espece: culture.espece,
		idFamille: culture.idFamille,
		idEspece: culture.idEspece,
		variete: culture.variete,
		cultivar: culture.cultivar,
		prodMino: culture.prodMino,
		commentaire: culture.commentaire
	}
}

/**
 * Récupération de la timeline d'une étape d'un itk
 * @param itkDetails
 * @param etape
 */
export const getTimelineForEtape = (itkDetails, etape) => {
	const etapeFound = itkDetails.find(itkD => itkD.typeEtape === etape)

	if (!!etapeFound) {
		return `${etapeFound.timeline}`
	}
	return '0'
}

/**
 * Récupération de la timeline d'une étape d'un groupe
 * @param itkDetails
 * @param etape
 */
export const getTimelineForGroupeEtape = (itkDetails, etape) => {
	const etapeFound = itkDetails.find(itkD => itkD.typeEtape === etape)

	if (!!etapeFound) {
		return `${etapeFound.timelineDebutEtape}`
	}
	return '0'
}

/**
 * Récupération de la durée pour l'étape récolte
 * @param itkDetails
 */
export const getDureeForRecolte = (itkDetails) => {
	const etapeFound = itkDetails.find(itkD => itkD.typeEtape === ETAPE_TYPE.RECOLTE)

	if (!!etapeFound) {
		return `${etapeFound.duree}`
	}
	return '0'
}
