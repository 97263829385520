import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, ListItemIcon, Typography } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { joinNode } from '../../../../../utils/utils'

const styles = () => getStyles({
	section: {
		marginBottom: 35,
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	title: {
		marginBottom: 15,
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		verticalAlign: 'middle',
		minWidth: 35
	},
	path: {
		fontWeight: 'bold'
	}
})

const SectionDetail = ({ icon, title, path, content, comment, classes }) => (
	<Typography variant="body1" component="div" className={classes.section}>
		{title && (
			<Typography variant="h4" className={classes.title}>
				{
					icon && (
						<ListItemIcon className={classes.icon}>
							{icon}
						</ListItemIcon>
					)
				}
				{title}
			</Typography>
		)}
		{path &&
			<span className={classes.path}>
			{joinNode(path, ' > ')}
		</span>
		}
		<div>{content}</div>
		{comment && <p>{comment}</p>}
	</Typography>
)

SectionDetail.propType = {
	icon: PropTypes.node,
	title: PropTypes.string,
	path: PropTypes.objectOf(PropTypes.string),
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	comment: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default withStyles(styles)(SectionDetail)
