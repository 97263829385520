import DashboardIcon from '@material-ui/icons/Dashboard'
import moment from 'moment'
import { DateFormat } from '../../../../../../utils/dateConstants'
import { NUMERO_JOURS_SEMAINE } from '../../../../gestionTache/utils/constants'
import { ZONE_TYPE } from '../../../tool/tourPlaine.constants'
import { BIO_AGGRESSOR_TYPE, BLOCK_ACTION_TYPE, BOARD_UNAVAILABLE_FIELDS } from '../tool/suiviParBloc.constants'

const ZONE_TYPE_API = {
	SER: 'serre',
	EXT: 'exterieur',
	BUT: 'butte',
	FRT: 'fruit'
}

/**
 * Map Plan Api To App
 * ListBloc should map the SimpleCell data object
 */
export const farmPlanToApp = (values) => {
	const getZoneTypeValue = (zone) => Object.keys(ZONE_TYPE).find(key => ZONE_TYPE[key] === zone)

	return ({
		imageUri: values.urlPlan,
		listBlock: values.blocs.map((bloc) => {
			const { id, idFerme, zone, nom, code } = bloc
			return ({
				uniqueId: id, // SimpleCell data object
				icon: DashboardIcon, // SimpleCell data object
				title: nom, // SimpleCell data object
				description: { id: `enums.zoneType.${ZONE_TYPE_API[zone]}` }, // SimpleCell data object
				idFarm: idFerme,
				zone: getZoneTypeValue(ZONE_TYPE_API[zone]),
				code
			})
		})
	})
}

/**
 * Map Plan Api To App
 */
export const notifsToApp = (values) => ({
	[BLOCK_ACTION_TYPE.BIO_AGGRESSORS.code]: parseInt(values.nbPlanchesBioagresseurs),
	[BLOCK_ACTION_TYPE.BOARDS_UNAVAILABLE.code]: parseInt(values.nbPlanchesIndisponibles)
})


/**
 * Map Bio Aggressors Api To App
 * BioAggressor
 */
export const bioAggressorsToApp = (values) => values.map((val) => {
		const bioAggType = Object.keys(BIO_AGGRESSOR_TYPE).find(key => BIO_AGGRESSOR_TYPE[key].code === val.type)
		return ({
			id: val.id,
			label: val.nom,
			type: BIO_AGGRESSOR_TYPE[bioAggType].code
		})
	}
)

/**
 * Map Bio Aggressor Detail for a Board to the App
 * @param values
 * @returns {*}
 */
const bioAggressorDetailToApp = (values) => values.map((val) => ({
	id: val.id,
	idBioAggressor: val.idBioagresseur,
	label: val.nom,
	start: val.dateDebut,
	end: val.dateFin
}))

/**
 * Map Boards in Bio Aggressor Api To App
 * BioAggressor
 */
export const boardsToApp = (values) => values.map((val) => {
		return ({
			id: val.id,
			label: val.nom,
			bioAggressors: bioAggressorDetailToApp(val.bioagresseurs)
		})
	}
)

/**
 * Map Bio Aggressor Add Form To Api
 * BioAggressor
 */
export const addBioAggressorsToApi = (values) => ({
	planches: values.boards,
	dateDebut: values.start,
	dateFin: values.end,
	bioagresseurs: values.bioAggressor
})

/**
 * Map Bio Aggressor Update Form To Api
 * BioAggressor
 */
export const updateBioAggressorsToApi = (values) => values.bioAggressor.map(value => ({
		dateDebut: value.start,
		dateFin: value.end,
		bioagresseur: value.bioAggressor,
		presences: value.boards.map(b => b.idBioAggressor),
		id: value.id
	})
)

/**
 * Map irrigations Add Form To Api
 * @param values
 * @returns [{}]
 */
export const irrigationsToApp = (values, weekOptions) => {
	const result = weekOptions.map((weekOption, index) => {
		// from the API
		const valuesOfTheWeek = values[index]
		// start of the week
		const startOftheWeek = moment(weekOption.id, DateFormat.YYYY_MM_DD).startOf('isoWeeks')

		// for each day of the week, get the api value
		const weekDayValues = [...Object.values(NUMERO_JOURS_SEMAINE)].reduce((acc, value) => {
				const date = startOftheWeek.clone().add(value, 'day')
				return {
					...acc,
					[date.format(DateFormat.YYYY_MM_DD)]: valuesOfTheWeek.filter(val => moment(val.date, DateFormat.YYYY_MM_DD).isSame(date)).map(val => ({
							id: val.id,
							date: val.date,
							start: moment(val.heureDebut, 'HH:mm:ss').format('HH[h]mm'),
							end: moment(val.heureFin, 'HH:mm:ss').format('HH[h]mm')
						})
					)
				}
			}, {}
		)
		return ({
			id: weekOption.id,
			...weekDayValues
		})
	})

	return result
}

/**
 * Map irrigations Add Form To Api
 * @param values
 * @returns {{}}
 */
export const addIrrigationsToApi = (block, weekDay, values) => ({
	blocId: block.uniqueId,
	date: weekDay.id,
	heures: values.map(value => ({ heureDebut: `${value.start}:00`, heureFin: `${value.end}:00` }))
})

/**
 * Map Board Unavailable Api To App
 * BioAggressor
 */
export const unavailableBoardsToApp = (values) => values.map((val) => {
		return ({
			id: val.id,
			label: val.nom,
			start: val.dateDebutIndisponibilite,
			end: val.dateFinIndisponibilite,
			comment: val.commentaireIndisponibilite
		})
	}
)

/**
 * Map Board Unavailable Add Form To Api
 */
export const addBoardsUnavailablesToApi = (values) => ({
	planches: values[BOARD_UNAVAILABLE_FIELDS.BOARDS],
	dateDebut: values[BOARD_UNAVAILABLE_FIELDS.START],
	dateFin: values[BOARD_UNAVAILABLE_FIELDS.END],
	commentaire: values[BOARD_UNAVAILABLE_FIELDS.COMMENT]
})

/**
 * Map Board Unavailable Update Form To Api
 */
export const updateBoardsUnavailablesToApi = (values) => values.map(value => ({
		dateDebut: value[BOARD_UNAVAILABLE_FIELDS.START],
		dateFin: value[BOARD_UNAVAILABLE_FIELDS.END],
		commentaire: value[BOARD_UNAVAILABLE_FIELDS.COMMENT],
		planches: value.boards.map(b => b.id)
	})
)