import React from 'react'
import {dataTableActions} from 'isotope-client'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {makeStyles} from '@material-ui/styles'
import {NUMERO_JOURS_SEMAINE, PLANIFICATION_TABLE_NAME} from '../../utils/constants'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import {getFilterValues} from '../../../planning/services/toolbar/toolbarSelector'
import SpringDataTableWithLoader from "../../../../../components/layout/SpringDataTableWithLoader";
import NoResultPage from "../../../../../components/NoResultPage";
import moment from "moment";
import InfoTache from "../../../../common/information/InfoTache";
import FormattedTacheLieuMessage from "../common/FormattedTacheLieuMessage";
import TacheStatutIcon from "../common/TacheStatutIcon";
import {formatDuration} from "../../../../../utils/utils";
import AffectationIcon from "../common/AffectationIcon";
import {formatFilters} from "../../utils/tacheUtils";
import {getInfosTacheForDetailsPanel} from "../../gestionTachesApi";
import {openTacheDetail} from "../../injectors/openTacheDetailsInjector";
import {compose} from "redux";
import {injectTachePanel} from "../../form/tacheDetailPanel";

const DAYS_OFFSETS = {
	LUNDI: 0,
	MARDI: 1,
	MERCREDI: 2,
	JEUDI: 3,
	VENDREDI: 4,
	SAMEDI: 5,
	DIMANCHE: 6
}

const useStyles = makeStyles(theme => ({
	table: {
		borderCollapse: 'collapse',
		tableLayout: 'fixed',
		'& [class*="MuiTableCell-root"]': {
			height: 40,
			borderLeft: `1px solid ${theme.palette.border}`,
			borderRight: `1px solid ${theme.palette.border}`
		},
		'& [class*="MuiTableRow-head"]': {
			backgroundColor: theme.palette.primary.light
		},
		'& [class*="MuiTableCell-head"]': {
			padding: '0 5px',
			lineHeight: '1.3',
			'& span': {
				cursor: 'pointer'
			}
		},
		'& [class*="MuiTableCell-body"]:first-child': {
			padding: '0 5px',
			textAlign: 'center'
		},
		maxWidth: '100%'
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		maxWidth: '100%',
		overflow: 'auto'
	}
}))

const PlanificationTable = ({filters, resetSelectedRows, moveTasksDay, reference, openTacheDetail}) => {
	const {semaineSelection} = useGestionTacheContext()
	const classes = useStyles()

	const getDayOffset = React.useCallback((date) => moment(date).diff(moment(semaineSelection), 'days'), [semaineSelection])
	const filterValues = React.useMemo(() => formatFilters(filters), [filters])

	const Table = React.forwardRef((props, ref) => {
		return <div className={classes.leftContainer} ref={ref}>
			<SpringDataTableWithLoader
				nom={PLANIFICATION_TABLE_NAME}
				showBorders={false}
				mode="post"
				apiUrl="/taches/planification"
				selectable
				displaySelectAll
				preFetch={resetSelectedRows}
				defaultPageSize={50}
				noResultFragment={<NoResultPage/>}
				filters={{...filterValues, date: moment(semaineSelection).format('YYYY-MM-DD')}}
				tableProps={{
					stickyHeader: true,
					className: classes.table
				}}
				headers={[
					{
						key: 'code',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.tache"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '17%'
						},
						render: row => <span onClick={(event) => {
							getInfosTacheForDetailsPanel(row.id).then((res) => openTacheDetail(res))
							event.preventDefault()
							event.stopPropagation()
						}}><InfoTache tache={row} inTable/></span>
					},
					{
						key: 'priorite',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.priorite"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						}
					},
					{
						key: 'lieu',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.lieu"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '7%',
							align: 'center'
						},
						render: row => <FormattedTacheLieuMessage typeLieu={row.typeLieu} lieu={row.lieu}/>
					},
					{
						key: 'statut',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.statut"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '12%',
							align: 'center'
						},
						render: row => <TacheStatutIcon statut={row.statut} isEnRetard={row.enRetard}/>
					},
					{
						key: 'chargeTravail',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.chargeTravail"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => formatDuration(row.chargeTravail)
					},
					{
						key: 'chargeUnitaire',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.chargeParPersonne"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => formatDuration(row.chargeUnitaire)
					},
					{
						key: 'nbPersonnes',
						name: <FormattedMessage id="gestionTaches.ecranPlanification.table.headers.nbPersonnes"/>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						}
					},
					{
						key: 'lundi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.LUNDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.lundi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => (getDayOffset(row.date) === DAYS_OFFSETS.LUNDI || moment(row.date).isBefore(moment(semaineSelection).day('Monday'))) && <AffectationIcon/>
					},
					{
						key: 'mardi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.MARDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.mardi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.MARDI && <AffectationIcon/>
					},
					{
						key: 'mercredi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.MERCREDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.mercredi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.MERCREDI && <AffectationIcon/>
					},
					{
						key: 'jeudi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.JEUDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.jeudi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.JEUDI && <AffectationIcon/>
					},
					{
						key: 'vendredi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.VENDREDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.vendredi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.VENDREDI && <AffectationIcon/>
					},
					{
						key: 'samedi',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.SAMEDI)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.samedi"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.SAMEDI && <AffectationIcon/>
					},
					{
						key: 'dimanche',
						name: <span onClick={() => moveTasksDay(NUMERO_JOURS_SEMAINE.DIMANCHE)}><FormattedMessage id="gestionTaches.ecranPlanification.table.headers.dimanche"/></span>,
						sort: false,
						tableHeaderColumnProps: {
							width: '6%',
							align: 'center'
						},
						render: row => getDayOffset(row.date) === DAYS_OFFSETS.DIMANCHE && <AffectationIcon/>
					}
				]}
			/>
		</div>
	})

	return (
		<Table ref={reference}/>
	)
}

const mapStateToProps = state => ({
	filters: getFilterValues(state)
})

const actions = {
	resetSelectedRows: () => dataTableActions.selectRows(PLANIFICATION_TABLE_NAME, [])
}

export default compose(
	connect(mapStateToProps, actions),
	openTacheDetail,
	injectTachePanel
)(PlanificationTable)
