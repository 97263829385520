import React from 'react'
import PropTypes from 'prop-types'

const ImplantationIcon = ({ height, width, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props} >
			<path
				d="M341.047 36.784c-6.407-5.253-15.861-4.316-21.113 2.088C301.164 61.763 284 82.921 271 106.491v-91.37c0-8.283-6.716-14.997-15-14.997s-15 6.715-15 14.997v91.37c-13-23.57-30.158-44.729-48.929-67.618-5.253-6.405-14.702-7.341-21.108-2.088-6.406 5.251-7.341 14.701-2.089 21.106 41.13 50.156 68.716 88.476 71.146 151.014l-30.91 60.678c-8.326 16.345-7.746 35.581 1.55 51.459 9.103 15.546 25.194 25.259 43.023 25.982a58.297 58.297 0 004.637 0c17.853-.731 33.943-10.451 43.041-26.001 9.289-15.875 9.864-35.108 1.538-51.447l-30.908-60.653c2.425-62.548 30.012-100.871 71.146-151.032 5.252-6.405 4.317-15.856-2.09-21.107z"
				fill="#cfa05f"
			/>
			<path
				d="M341.047 36.784c-6.407-5.253-15.861-4.316-21.113 2.088C301.164 61.763 284 82.921 271 106.491v-91.37c0-8.283-6.716-14.997-15-14.997s-15 6.715-15 14.997v91.37c-13-23.57-30.158-44.729-48.929-67.618-5.253-6.405-14.702-7.341-21.108-2.088-6.406 5.251-7.341 14.948-2.089 21.353 41.08 50.095 68.646 88.033 71.134 151.033h31.995c2.488-63 30.053-100.938 71.133-151.033 5.253-6.406 4.318-16.103-2.089-21.354z"
				fill="#6ac473"
			/>
			<path
				d="M256 .124c-8.284 0-15 6.715-15 14.997v91.37c-13-23.57-30.158-44.729-48.929-67.618-5.253-6.405-14.702-7.341-21.108-2.088-6.406 5.251-7.341 14.701-2.089 21.106 41.13 50.156 68.716 88.476 71.146 151.014l-30.91 60.678c-8.326 16.345-7.746 35.581 1.55 51.459 9.103 15.546 25.194 25.259 43.023 25.982a55.68 55.68 0 002.317.047c.771-.001 0-346.947 0-346.947z"
				fill="#facc8c"
			/>
			<path
				d="M256 .124c-8.284 0-15 6.715-15 14.997v91.37c-13-23.57-30.158-44.729-48.929-67.618-5.253-6.405-14.702-7.341-21.108-2.088-6.406 5.251-7.341 14.948-2.089 21.353 41.08 50.095 68.646 88.033 71.134 151.033h16.335L256 .124z"
				fill="#a9e570"
			/>
			<path
				d="M497 278.878H362.885c-28.771 0-39.215 23.319-39.215 38.96 0 37.098-30.394 67.305-67.753 67.305-37.991 0-67.75-29.363-67.75-66.81-.011-15.845-10.464-39.455-39.215-39.455H15c-8.284 0-15 6.818-15 15.102v172.219c0 24.778 20.159 45.677 44.938 45.677h422.124c24.779 0 44.938-20.899 44.938-45.677v-172.22c0-8.284-6.716-15.101-15-15.101z"
				fill="#6ac473"
			/>
			<path
				d="M0 440.876v25.323c0 24.778 20.159 45.677 44.938 45.677h422.124c24.779 0 44.938-20.899 44.938-45.677v-25.323H0z"
				fill="#a67955"
			/>
			<path
				d="M255.916 385.142c-37.991 0-67.75-29.364-67.75-66.81-.011-15.845-10.464-39.455-39.215-39.455H15c-8.284 0-15 6.818-15 15.102v172.219c0 24.778 20.159 45.677 44.938 45.677H256l-.084-126.733z"
				fill="#a9e570"
			/>
			<path
				d="M256.053 440.876H0v25.323c0 24.778 20.159 45.677 44.938 45.677h211.115v-71z"
				fill="#dea06d"
			/>
		</svg>
	)
}

ImplantationIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

ImplantationIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default ImplantationIcon
