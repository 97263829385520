import React from 'react'
import PropTypes from 'prop-types'

const ItineraireTechniqueIcon = ({ height, width }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<g fill="#7ccc92">
				<path d="M512 256c0 141.134-114.867 256-256 256-68.674 0-133.677-27.603-181.315-75.464v19.812a16.673 16.673 0 01-16.696 16.696c-9.238 0-16.696-7.457-16.696-16.696v-63.221c-2.226-11.02 6.679-20.257 16.696-20.257h66.783a16.673 16.673 0 0116.696 16.696 16.673 16.673 0 01-16.696 16.696H92.049C133.899 452.008 193.113 478.61 256 478.61c122.769 0 222.609-99.84 222.609-222.609 0-21.148-3.005-42.073-8.793-62.108-2.56-8.904 2.449-18.143 11.353-20.703 8.793-2.56 18.143 2.56 20.704 11.353C508.548 207.694 512 231.736 512 256zM473.043 55.652v66.783c0 8.904-6.568 16.696-18.475 16.696h-65.003c-9.239 0-16.696-7.457-16.696-16.696s7.456-16.696 16.696-16.696h30.386C378.101 59.993 318.887 33.391 256 33.391c-122.769 0-222.609 99.84-222.609 222.609 0 21.148 3.005 42.073 8.793 62.108 2.56 8.904-2.449 18.143-11.353 20.703a16.956 16.956 0 01-4.675.668c-7.235 0-13.913-4.786-16.029-12.021C3.452 304.306 0 280.264 0 256 0 114.866 114.867 0 256 0c69.899 0 135.791 28.494 183.652 77.913V55.652a16.673 16.673 0 0116.696-16.696c9.239.001 16.695 7.458 16.695 16.696z" />
			</g>
			<g fill="#34b772">
				<path d="M512 256c0 141.134-114.867 256-256 256v-33.391c122.769 0 222.609-99.84 222.609-222.609 0-21.148-3.005-42.073-8.793-62.108-2.56-8.904 2.449-18.143 11.353-20.703 8.793-2.56 18.143 2.56 20.704 11.353C508.548 207.694 512 231.736 512 256zM473.043 55.652v66.783c0 8.904-6.568 16.696-18.475 16.696h-65.003c-9.239 0-16.696-7.457-16.696-16.696s7.456-16.696 16.696-16.696h30.386C378.101 59.993 318.887 33.391 256 33.391V0c69.899 0 135.791 28.494 183.652 77.913V55.652a16.673 16.673 0 0116.696-16.696c9.239.001 16.695 7.458 16.695 16.696zM133.565 161.391v66.783c0 64.445 52.424 116.87 116.87 116.87h66.783a16.673 16.673 0 0016.696-16.696v-66.783c0-50.755-32.611-94.163-77.913-110.191-12.133-4.341-25.266-6.678-38.957-6.678h-66.783a16.672 16.672 0 00-16.696 16.695z" />
			</g>
			<path
				d="M133.565 161.391v66.783c0 64.445 52.424 116.87 116.87 116.87H256v-193.67c-12.133-4.341-25.266-6.678-38.957-6.678H150.26a16.672 16.672 0 00-16.695 16.695z"
				fill="#7ccc92"
			/>
			<path
				d="M205.244 239.972l139.13 139.13c6.568 6.567 17.03 6.567 23.598 0 6.567-6.456 6.567-17.141 0-23.597l-139.13-139.13c-6.568-6.567-17.03-6.567-23.598 0-6.566 6.456-6.566 17.142 0 23.597z"
				fill="#c7d100"
			/>
			<path
				d="M205.244 239.972L256 290.727v-47.193l-27.157-27.158c-6.568-6.567-17.03-6.567-23.598 0-6.567 6.455-6.567 17.141-.001 23.596z"
				fill="#dddd47"
			/>
		</svg>
	)
}

ItineraireTechniqueIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

ItineraireTechniqueIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default ItineraireTechniqueIcon
