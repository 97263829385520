import { Grid, Typography, withStyles } from '@material-ui/core'
import { formatDuration } from '../../../../utils/utils'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import LensIcon from '@material-ui/icons/Lens'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { getStyles } from 'isotope-client'
import { colors } from '../../../../utils/constants'
import moment from 'moment'

const styles = () => getStyles({
	content: {
		width: '100%',
	},
	boxTop: {
		backgroundColor: colors.backgroundSuiviTacheAvancement,
		padding: '15px',
		marginRight: 10,
		height: '82px',
		maxWidth: '275px',
		marginBottom: '13px',
	},
	boxBottom: {
		backgroundColor: colors.backgroundSuiviTacheAvancement,
		padding: '15px',
		height: '97px',
		maxWidth: '275px',
		marginRight: 10,
	},
	iconContainerPrimary: {
		width: '24px',
		color: colors.primary
	},
	iconContainerARealiser: {
		width: '24px',
		color: colors.tacheARealiser
	},
	iconContainerEnCoursOuTerminees: {
		width: '24px',
		color: colors.tacheEnCoursOuTerminee
	},
	text: {
		width: '150px',
		marginLeft: '16px',
		color: colors.primary
	},
	dataBoldPrimary: {
		fontSize: '24px',
		fontWeight: 'bold'
	},
	dataBoldSmallPrimary: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: colors.primary
	},
	bottomContainer: {
		width: '50px',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		alignContent: 'flex-end',
		color: colors.primary
	},
	iconARealiser: {
		backgroundColor: colors.tacheARealiser
	},
	smallText: {
		fontSize: '12px',
		color: colors.primary
	},
	normalText: {
		fontSize: '14px'
	}
})

const SuiviAvancementBlocs = ({ classes, dataSuivi }) => {
	return (
		<React.Fragment>
			<Grid container direction="row" className={classes.content}>
				<Grid container item justify={"center"}>
					<Grid container item xs={3} direction="row" className={classes.boxTop}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerPrimary}>
								<FormatListBulletedIcon color="primary" />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tachePlanifiee" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{dataSuivi.tachesPlanifiees}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxTop}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerARealiser}>
								<LensIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheARealiser" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{dataSuivi.tachesARealiser}
								</Typography>

							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{Math.trunc((moment.duration(dataSuivi.chargeTravailARealiser).asMinutes()) / Math.max(moment.duration(dataSuivi.chargeTravailTotal).asMinutes(), 1) * 100)} %
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxTop}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerEnCoursOuTerminees}>
								<PlayCircleFilledIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheEnCours" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{dataSuivi.tachesEnCours}
								</Typography>

							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{Math.trunc((moment.duration(dataSuivi.chargeTravailEnCours).asMinutes() / Math.max(moment.duration(dataSuivi.chargeTravailTotal).asMinutes(), 1)) * 100)} %
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxTop}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerEnCoursOuTerminees}>
								<CheckCircleIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheTerminee" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{dataSuivi.tachesTerminees}
								</Typography>
							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{Math.trunc((moment.duration(dataSuivi.chargeTravailTerminees).asMinutes() / Math.max(moment.duration(dataSuivi.chargeTravailTotal).asMinutes(), 1)) * 100)} %
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container item justify={"center"}>
					<Grid container item xs={3} className={classes.boxBottom}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerPrimary}>
								<TimelapseIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.chargeTravailTotalePlanifiee" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{formatDuration(dataSuivi.chargeTravailTotal)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxBottom}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerARealiser}>
								<LensIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheARealiser" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									-
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tempsPasse" />
								</Typography>
							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{formatDuration(dataSuivi.chargeTravailARealiser)}
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.chargePlanifiee" />
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxBottom}>
						<Grid item container>
							<Grid container justify="center"
							      className={classes.iconContainerEnCoursOuTerminees}>
								<PlayCircleFilledIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheEnCours" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{formatDuration(dataSuivi.tempsPasseEnCours)}
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tempsPasse" />
								</Typography>
							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{formatDuration(dataSuivi.chargeTravailEnCours)}
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.chargePlanifiee" />
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container item xs={3} className={classes.boxBottom}>
						<Grid item container>
							<Grid container justify="center" className={classes.iconContainerEnCoursOuTerminees}>
								<CheckCircleIcon />
							</Grid>
							<Grid container item direction="column" className={classes.text}>
								<Typography className={classes.normalText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tacheTerminee" />
								</Typography>
								<Typography color="primary" className={classes.dataBoldPrimary}>
									{formatDuration(dataSuivi.tempsPasseTerminees)}
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.tempsPasse" />
								</Typography>
							</Grid>
							<Grid container item alignItems="bottom" className={classes.bottomContainer}>
								<Typography className={classes.dataBoldSmallPrimary}>
									{formatDuration(dataSuivi.chargeTravailTerminees)}
								</Typography>
								<Typography className={classes.smallText}>
									<FormattedMessage id="suiviAvancement.suiviGlobal.chargePlanifiee" />
								</Typography>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default withStyles(styles)(SuiviAvancementBlocs)
