import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from './Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import classnames from 'classnames'
import Typography from "@material-ui/core/Typography/index";

const styles = () => getStyles({
	labelCheckbox: {
		margin: 0,
		marginTop: 2
	},
	row: {
		flexDirection: 'row',
		float: 'left'
	},
	labelCheckboxRow: {
		marginLeft: 20,
		fontSize: 14
	},
	tache: {
		justifyContent: 'center',
		width: '100%',
		height: 45
	}
})

const CheckboxGroup = (
	{
		input,
		items,
		classes,
		row,
		fromTache
	}
) => {

	return (<FormGroup controlId={input.name} className={classnames({[classes.row]: row, [classes.tache]: fromTache})}>
		{items.map((item, index) => (
			<Checkbox
				key={index}
				label={
					<Typography className={row ? classes.labelCheckboxRow : classes.labelCheckbox}>
						{item.label}
					</Typography>
				}
				input={{
					name: `${input.name}[${index}]`,
					value: item.value,
					onChange: event => {
						const newValue = [...input.value];
						if (event.target.checked) {
							newValue.push(item.value);
						} else {
							newValue.splice(newValue.indexOf(item.value), 1);
						}

						return input.onChange(newValue);
					}
				}}
				checked={(input.value || []).includes(item.value)}
				meta={{}}
				useChecked={false}
			/>
		))
		}
	</FormGroup>)
}

CheckboxGroup.propTypes = {
	input: PropTypes.object,
	items: PropTypes.array,
	classes: PropTypes.object,
	row: PropTypes.bool
}

export default withStyles(styles)(CheckboxGroup)
