import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectTypeSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_VALUE_LIST_ITEM } from '../../../utils/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'

/** Composant qui permet l'affichage des informations d'une planche **/

const InfoPlanche = ({
	planche,
	typeSurfaceList
}) => {
	return <FormattedMessage
		id="bo.blocSurface.descriptionSurface"
		values={{
			aire: planche.surface,
			typeSurface: (typeSurfaceList.find(x => x.code === planche.typePlanche) || DEFAULT_VALUE_LIST_ITEM).label.trim()
		}}
	/>
}

InfoPlanche.propTypes = {
	planche: PropTypes.object,
	zoneSurfaceList: PropTypes.array
}

export default compose(injectTypeSurfaceValueList)(InfoPlanche)
