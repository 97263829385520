import { Typography } from '@material-ui/core'
import { Chat, Check, ClearAll, Code, CompareArrows, DeleteForever, PinDrop, Spa } from '@material-ui/icons'
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ETAPE_TYPE } from '../../../../utils/constants'
import { DateFormat } from '../../../../utils/dateConstants'
import { ACTIONS_KEY, EMPTY_PLACEHOLDER } from './tourPlaine.constants'

/**
 * Utils
 */
 const filterItemsForSteps = (itemsToFilter, steps) => {
	const stepKeys = Object.keys(ETAPE_TYPE).filter(key => steps.includes(ETAPE_TYPE[key]))
	return itemsToFilter.filter(item => stepKeys.includes(item.step))
}

/**
 * List of all actions filtered by the array of actionKeys
 */
export const moreActions = (actionKeys, items) => {
	const allActions = [
		{
			key: ACTIONS_KEY.DETAIL_CROP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.details" values={{ quantity: items.length }} /></Typography>,
			icon: <Spa color="primary" />,
			disabled: items.length > 1 || (items.length === 1 && !items[0].assolement)
		},
		{
			key: ACTIONS_KEY.DETAIL_CROP_CURRENT_OR_NEXT,
			label: <Typography><FormattedMessage id="tourPlaine.actions.details" values={{ quantity: items.length }} /></Typography>,
			icon: <Spa color="primary" />,
			disabled: items.length > 1 || (items.length === 1 && !items[0].assolementEnCoursOuSuivant)
		},
		{
			key: ACTIONS_KEY.ADD_COMMENT,
			label: <Typography><FormattedMessage id="tourPlaine.actions.addComment" /></Typography>,
			icon: <Chat color="primary" />
		},
		{
			key: ACTIONS_KEY.ADD_COMMENT_CURRENT_OR_NEXT,
			label: <Typography><FormattedMessage id="tourPlaine.actions.addComment" /></Typography>,
			icon: <Chat color="primary" />,
			disabled: items.filter(item => item.assolementEnCoursOuSuivant).length === 0
		},
		{
			key: ACTIONS_KEY.ADD_INSTRUCTION,
			label: <Typography><FormattedMessage id="tourPlaine.actions.addInstruction" /></Typography>,
			icon: <Chat color="primary" />
		},
		{
			key: ACTIONS_KEY.ADD_PROD_TRACKING,
			label: <Typography><FormattedMessage id="tourPlaine.actions.addToProd" /></Typography>,
			icon: <Chat color="primary" />
		},
		{
			key: ACTIONS_KEY.ADJUST_DURATION,
			label: <Typography> <FormattedMessage id="tourPlaine.actions.adjustDuration" /></Typography>,
			icon: <Code color="primary" />
		},
		{
			key: ACTIONS_KEY.CONFIRM_HARVEST,
			label: <Typography><FormattedMessage id="tourPlaine.actions.confirmHarvest" /></Typography>,
			icon: <Check color="primary" />,
			disabled: !items.filter(item => item.taskHarvest).length,
			items: items.filter(item => item.taskHarvest)
		},
		{
			key: ACTIONS_KEY.DELETE_CROP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.delete" values={{ quantity: items.length }} /></Typography>,
			icon: <DeleteForever color="primary" />
		},
		{
			key: ACTIONS_KEY.DELETE_CROP_CURRENT_OR_NEXT,
			label: <Typography><FormattedMessage id="tourPlaine.actions.delete" values={{ quantity: items.length }} /></Typography>,
			icon: <DeleteForever color="primary" />,
			disabled: items.filter(item => item.assolementEnCoursOuSuivant).length === 0
		},
		{
			key: ACTIONS_KEY.DELETE_TASK,
			label: <Typography><FormattedMessage id="tourPlaine.actions.deleteTask" values={{ quantity: items.length }} /></Typography>,
			icon: <DeleteForever color="primary" />
		},
		{
			key: ACTIONS_KEY.MOVE_BOARD_STEP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.moveToBoard" /></Typography>,
			icon: <PinDrop color="primary" />,
			disabled: !(filterItemsForSteps(items, [ETAPE_TYPE.PREP_PLANCHE, ETAPE_TYPE.CROISSANCE])).length,
			items: filterItemsForSteps(items, [ETAPE_TYPE.PREP_PLANCHE, ETAPE_TYPE.CROISSANCE])
		},
		{
			key: ACTIONS_KEY.MOVE_TO_HARVEST_STEP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.moveToHarvestStep" /></Typography>,
			icon: <PinDrop color="primary" />,
			disabled: !(filterItemsForSteps(items, [ETAPE_TYPE.RECOLTE])).length,
			items: filterItemsForSteps(items, [ETAPE_TYPE.RECOLTE])
		},
		{
			key: ACTIONS_KEY.MOVE_TO_IMPLANT_STEP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.moveToNextImplantStep" /></Typography>,
			icon: <PinDrop color="primary" />,
			disabled: !filterItemsForSteps(items, [ETAPE_TYPE.IMPLANTATION]).length,
			items: filterItemsForSteps(items, [ETAPE_TYPE.IMPLANTATION])
		},
		{
			key: ACTIONS_KEY.MOVE_SEEDLING_STEP,
			label: <Typography><FormattedMessage id="tourPlaine.actions.moveToSeedling" /></Typography>,
			icon: <ClearAll color="primary" />,
			disabled: !(filterItemsForSteps(items, [ETAPE_TYPE.SEMI_EN_CONTENANT])).length,
			items: filterItemsForSteps(items, [ETAPE_TYPE.SEMI_EN_CONTENANT])
		},
		{
			key: ACTIONS_KEY.MOVE_TASK,
			label: <Typography><FormattedMessage id="tourPlaine.actions.moveTask" values={{ quantity: items.length }} /></Typography>,
			icon: <CompareArrows color="primary" />
		},
		{
			key: ACTIONS_KEY.SPECIFY_GENERIC_TASK,
			label: <Typography><FormattedMessage id="tourPlaine.actions.specifyGenericTask" /></Typography>,
			icon: <OutlinedFlagIcon color="primary" />,
			disabled: items.length > 1 || !items.every(i => i.taskGeneric)
		}
	]

	return actionKeys.map(key => allActions.find(action => action.key === key))
}

export const formatDateToWeek = (date) => {
	if (!date) {
		return EMPTY_PLACEHOLDER
	}

	const currentYear = moment().year()
	const targetDateYear = moment(date, DateFormat.YYYY_MM_DD).year()
	if (currentYear === targetDateYear) {
		return ({
			id: 'tourPlaine.dataTable.cell.week',
			values: { weekNumber: moment(date, DateFormat.YYYY_MM_DD).week() }
		})
	} else {
		return ({
			id: 'tourPlaine.dataTable.cell.weekYear',
			values: {
				weekNumber: moment(date, DateFormat.YYYY_MM_DD).week(),
				year: targetDateYear
			}
		})
	}
}
