import { ASSOLEMENT_ETAT } from '../../../../../utils/constants'
import * as etapeApi from '../etapeApi'
import { isAssolementVisible } from '../../../../../utils/filterCheck'
import * as api from '../planningApi'

export const GET_ASSOLEMENTS_REQUEST = 'GET_ASSOLEMENTS_REQUEST'
export const GET_ASSOLEMENTS_SUCCESS = 'GET_ASSOLEMENTS_SUCCESS'
export const GET_ASSOLEMENTS_ERROR = 'GET_ASSOLEMENTS_ERROR'

export const getAssolements = (campagneId, filter, sort, assolementId) => dispatch => {
	dispatch({ type: GET_ASSOLEMENTS_REQUEST })

	return (assolementId ? api.getAssolementsWithPosition(campagneId, filter, sort, assolementId) : api.getAssolements(campagneId, filter, sort, 0))
		.then((assolementsWithFertilizedPlanche) => dispatch({
			type: GET_ASSOLEMENTS_SUCCESS,
			assolements: assolementsWithFertilizedPlanche.assolementList.content,
			assolementReference: assolementsWithFertilizedPlanche.assolementReference,
			page: assolementsWithFertilizedPlanche.assolementList,
			position: assolementsWithFertilizedPlanche.position
		}))
		.catch((error) => {
			return dispatch({ type: GET_ASSOLEMENTS_ERROR, error })
		})
}

export const CLEAR_ASSOLEMENT_LIST = 'CLEAR_ASSOLEMENT_LIST'
export const clearAssolementList = () => dispatch => {
	return dispatch({ type: CLEAR_ASSOLEMENT_LIST })
}

export const ADD_ASSOLEMENTS_REQUEST = 'ADD_ASSOLEMENTS_REQUEST'
export const ADD_ASSOLEMENTS_SUCCESS = 'ADD_ASSOLEMENTS_SUCCESS'
export const ADD_ASSOLEMENTS_ERROR = 'ADD_ASSOLEMENTS_ERROR'

export const addAssolements = (campagneId, filter, sort, page, currentPage) => dispatch => {
	dispatch({ type: ADD_ASSOLEMENTS_REQUEST })

	return api.getAssolements(campagneId, filter, sort, page)
		.then((assolementsWithFertilizedPlanche) => dispatch({
			type: ADD_ASSOLEMENTS_SUCCESS,
			assolements: assolementsWithFertilizedPlanche.assolementList.content,
			assolementReference: assolementsWithFertilizedPlanche.assolementReference,
			page: assolementsWithFertilizedPlanche.assolementList,
			currentPage
		}))
		.catch((error) => dispatch({ type: ADD_ASSOLEMENTS_ERROR, error }))
}

export const CREER_ASSOLEMENTS_REQUEST = 'CREER_ASSOLEMENTS_REQUEST'
export const CREER_ASSOLEMENTS_SUCCESS = 'CREER_ASSOLEMENTS_SUCCESS'
export const CREER_ASSOLEMENTS_ERROR = 'CREER_ASSOLEMENTS_ERROR'
export const creerAssolement = (idCampagne, values, filterValues , fromGroup) => dispatch => {
	const afterCreateAssolement = assolement => dispatch({
		type: CREER_ASSOLEMENTS_SUCCESS,
		isVisible: isAssolementVisible(assolement, filterValues),
		assolement
	})
	dispatch({ type: CREER_ASSOLEMENTS_REQUEST })
	if (fromGroup) {
		return api.creerAssolementFromGroup(idCampagne, values)
			.then(afterCreateAssolement)
			.catch(e => {
				dispatch({ type: CREER_ASSOLEMENTS_ERROR, e })
				throw e
			})
	}
	return api.creerAssolement(idCampagne, values)
		.then(afterCreateAssolement)
		.catch(e => {
			dispatch({ type: CREER_ASSOLEMENTS_ERROR, e })
			throw e
		})
}

export const MODIFIER_ASSOLEMENT_REQUEST = 'MODIFIER_ASSOLEMENT_REQUEST'
export const MODIFIER_ASSOLEMENT_SUCCESS = 'MODIFIER_ASSOLEMENT_SUCCESS'
export const MODIFIER_ASSOLEMENT_PLANCHE_SUCCESS = 'MODIFIER_ASSOLEMENT_PLANCHE_SUCCESS'
export const MODIFIER_ASSOLEMENT_ERROR = 'MODIFIER_ASSOLEMENT_ERROR'
export const editAssolement = (listAssolementsID, values, moveCulture = false, forceValidation = true) => dispatch => {
	dispatch({ type: MODIFIER_ASSOLEMENT_REQUEST })
	return api.editListAssolement(listAssolementsID, values.localisation, forceValidation, values)
		.then(assolement => {
			if (moveCulture) {
				dispatch({ type: MODIFIER_ASSOLEMENT_PLANCHE_SUCCESS, assolement })
			}
			return dispatch({ type: MODIFIER_ASSOLEMENT_SUCCESS, assolement: assolement[0] })
		})
		.catch(e => {
			dispatch({ type: MODIFIER_ASSOLEMENT_ERROR, e })
			throw e
		})
}

export const MODIFIER_PERIODE_ASSOLEMENT_REQUEST = 'MODIFIER_PERIODE_ASSOLEMENT_REQUEST'
export const MODIFIER_PERIODE_ASSOLEMENT_SUCCESS = 'MODIFIER_PERIODE_ASSOLEMENT_SUCCESS'
export const MODIFIER_PERIODE_ASSOLEMENT_ERROR = 'MODIFIER_PERIODE_ASSOLEMENT_ERROR'
export const editAssolementPeriod = (idAssolement, nbWeeks, idCampagne) => dispatch => {
	dispatch({ type: MODIFIER_PERIODE_ASSOLEMENT_REQUEST, idAssolement, nbWeeks })
	return api.editAssolementPeriode(idAssolement, nbWeeks, idCampagne)
		.then(assolement => {
			return dispatch({ type: MODIFIER_PERIODE_ASSOLEMENT_SUCCESS, idAssolement, assolement })
		})
		.catch(e => {
			dispatch({ type: MODIFIER_PERIODE_ASSOLEMENT_ERROR, e })
			throw e
		})
}

export const MODIFIER_PERIODE_ETAPE_LIST_REQUEST = 'MODIFIER_PERIODE_ETAPE_LIST_REQUEST'
export const MODIFIER_PERIODE_ETAPE_LIST_SUCCESS = 'MODIFIER_PERIODE_ETAPE_LIST_SUCCESS'
export const MODIFIER_PERIODE_ETAPE_LIST_ERROR = 'MODIFIER_PERIODE_ETAPE_LIST_ERROR'
export const editEtapeListPeriod = (values, idCampagne, idAssolement) => dispatch => {
	dispatch({ type: MODIFIER_PERIODE_ETAPE_LIST_REQUEST })
	return api.editEtapeListPeriode(values, idCampagne)
		.then(assolement => dispatch({
			type: MODIFIER_PERIODE_ETAPE_LIST_SUCCESS,
			idAssolement,
			assolement
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_PERIODE_ETAPE_LIST_ERROR, e })
			throw e
		})
}


export const DELETE_ASSOLEMENT_REQUEST = 'DELETE_ASSOLEMENT_REQUEST'
export const DELETE_ASSOLEMENT_SUCCESS = 'DELETE_ASSOLEMENT_SUCCESS'
export const DELETE_ASSOLEMENT_ERROR = 'DELETE_ASSOLEMENT_ERROR'
export const deleteAssolement = (listAssolementsID) => dispatch => {

	dispatch({ type: DELETE_ASSOLEMENT_REQUEST })
	return api.deleteListAssolement(listAssolementsID)
		.then(() => {
			return dispatch({ type: DELETE_ASSOLEMENT_SUCCESS, listAssolementsID })
		})
		.catch(e => {
			dispatch({ type: DELETE_ASSOLEMENT_ERROR, e })
			throw e
		})
}

export const SUIVI_ETAPE_REQUEST = 'SUIVI_ETAPE_REQUEST'
export const SUIVI_ETAPE_SUCCESS = 'SUIVI_ETAPE_SUCCESS'
export const SUIVI_ETAPE_ERROR = 'SUIVI_ETAPE_ERROR'
export const editEtapeSuivi = (idAssolementEtape, values) => dispatch => {
	dispatch({ type: SUIVI_ETAPE_REQUEST })
	return api.editEtapeSuivi(idAssolementEtape, values)
		.then((assolementEtape) => {
			return dispatch({ type: SUIVI_ETAPE_SUCCESS, assolementEtape })
		})
		.catch(e => {
			dispatch({ type: SUIVI_ETAPE_ERROR, e })
			throw e
		})
}

export const DELETE_ASSOLEMENT_ETAPE_REQUEST = 'DELETE_ASSOLEMENT_ETAPE_REQUEST'
export const DELETE_ASSOLEMENT_ETAPE_SUCCESS = 'DELETE_ASSOLEMENT_ETAPE_SUCCESS'
export const DELETE_ASSOLEMENT_ETAPE_ERROR = 'DELETE_ASSOLEMENT_ETAPE_ERROR'
export const deleteAssolementEtape = (idAssolementEtape, idAssolement, idCampagne) => dispatch => {
	dispatch({ type: DELETE_ASSOLEMENT_ETAPE_REQUEST })
	return api.deleteAssolementEtape(idAssolementEtape, idCampagne)
		.then((assolement) => dispatch({ type: DELETE_ASSOLEMENT_ETAPE_SUCCESS, idAssolementEtape, idAssolement, assolement }))
		.catch(e => {
			dispatch({ type: DELETE_ASSOLEMENT_ETAPE_ERROR, e })
			throw e
		})
}

export const SELECT_ASSOLEMENT = 'SELECT_ASSOLEMENT'
export const selectAssolement = (assolement, panelType, idEtape = null) => dispatch => dispatch({
	type: SELECT_ASSOLEMENT,
	assolement,
	panelType,
	idEtape
})

export const GET_ETAPES_PLANCHE = 'GET_ETAPES_PLANCHE'
export const GET_ETAPES_PLANCHE_ERROR = 'GET_ETAPES_PLANCHE_ERROR'
export const getEtapesPlanche = (filterForm, idPlanche) => dispatch => {
	return etapeApi.getEtapeList({ ...filterForm, etatAssolement: ASSOLEMENT_ETAT.BROUILLON })
		.then((etapeList) => {
			let etapesFiltered = []
			let assolementsFiltered = []
			if (etapeList.assolementEtapes.length > 0 && etapeList.assolements.length > 0) {
				etapesFiltered = etapeList.assolementEtapes.filter(etape => etapeList.assolements.find(assolement => assolement.id === etape.idAssolement).planche.id === idPlanche)
				assolementsFiltered = etapeList.assolements.filter(assolement => assolement.planche.id === idPlanche)
			}
			return dispatch({ type: GET_ETAPES_PLANCHE, etapesFiltered, assolementsFiltered })
		})
		.catch((error) => dispatch({ type: GET_ETAPES_PLANCHE_ERROR, error }))
}


export const SET_COMPARE = 'SET_COMPARE'
export const setCompare = (value) => dispatch => {
	dispatch({ type: SET_COMPARE, value })
}

export const UPDATE_TASK = 'UPDATE_TASK'
export const updateTask = (newTask) => dispatch => dispatch({
	type: UPDATE_TASK,
	newTask
})

export const SET_ACTIVE_ROW = 'SET_ACTIVE_ROW'
export const setActiveRow = (id) => dispatch => {
	dispatch({ type: SET_ACTIVE_ROW, id })
}
