import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'
import LoaderAdvanced from 'react-loader-advanced'

const styles = () => getStyles({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '4rem',
		width: '100%',
		zIndex: 100,
		background: 'linear-gradient(180deg, ' +
			'rgba(133,133,133,0.4) 0%, ' +
			'rgba(133,133,133,0.3) 35%, ' +
			'rgba(133,133,133,0) 100%)'
	},
	loader: {
		'& .Loader__message': {
			transform: 'translate(0, 0.5rem)'
		}
	},
	containerBottom: {
		background: 'linear-gradient(0, ' +
			'rgba(133,133,133,0.4) 0%, ' +
			'rgba(133,133,133,0.3) 35%, ' +
			'rgba(133,133,133,0) 100%)',
		top: 'unset',
		bottom: 0
	},
	containerAffectation: {
		height: '100%'
	},
	loaderAffectation: {
		paddingTop: '40%'
	}
})
const FloattingLoader = ({ isBottom, isLoading, isEcranAffectation, classes }) => isLoading &&
	<div className={`${classes.container} ${isBottom && classes.containerBottom} ${isEcranAffectation && classes.containerAffectation}`}>
		<LoaderAdvanced
			show={isLoading}
			message={<CircularProgress size={isEcranAffectation ? 100 : 30}/>}
			className={isEcranAffectation ? classes.loaderAffectation : classes.loader}
			backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0)' }}
		/>
	</div>


FloattingLoader.propType = {}

export default withStyles(styles)(FloattingLoader)