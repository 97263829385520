import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import FermeForm from './FermeForm'
import BackOfficeContainer from '../BackOfficeContainer'
import Button from '../../../components/Button'
import Grid from '@material-ui/core/Grid'
import {createFerme, exportMatriceProximite, updateFerme} from './services/fermeApi'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import PropTypes from 'prop-types'
import {DialogContext} from '../../../components/layout/dialog/context'
import {PlanView, TYPE_DIALOG} from '../../../utils/constants'
import FormButton from '../../../components/form/FormButton'
import BoListView from '../components/BoListView'
import {injectFermeList} from './services/fermeListInjector'
import StoreIcon from '@material-ui/icons/Store'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {updateFermeList} from './services/fermeActions'
import StyledTab from "../../../components/layout/tab/StyledTab";
import StyledTabs from "../../../components/layout/tab/StyledTabs";
import {useSolverStatusContext} from "../../fo/besoinsProduction/SolverStatusContextProvider";
import {getNotifications} from "../../common/notification/notificationActions";
import {checkExportStateUntilFound} from "../../../utils/exportUtils";

const FORM_NAME_CREATION = 'CreationFermeForm'
const FORM_NAME_UPDATE = 'UpdateFermeForm'

const FermePage = ({ fermeList, updateFermeList, getNotifications }) => {
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const [selection, setSelection] = React.useState({})
	const [menuSelection, setMenuSelection] = React.useState(PlanView.FORM.order)
	const { formatMessage } = useIntl()
	const { refreshSolverInfosForCurrentCampagne } = useSolverStatusContext()

	const updateListAndSelection = (fermeList, ferme) => {
		updateFermeList(fermeList).then(() => {
			const tuiles = formatTuiles([ferme])
			setSelection(tuiles[0])
		})
	}

	const onExportMatriceProximiteClick = (idFerme) => {
		return exportMatriceProximite(idFerme)
			.then(checkExportStateUntilFound(snackSuccess))
			.catch(() => snackError({ id: 'snackbar.error.exportEcartProduction' }))
	}

	const handleUpdate = (fermeId, values) => {
		return updateFerme({ id: fermeId, ...values }).then((ferme) => {
			snackSuccess({ id: 'bo.ferme.snackbar.update.success' })
			const index = fermeList.findIndex(ferme => ferme.id === fermeId)
			fermeList[index] = ferme
			getNotifications()
			refreshSolverInfosForCurrentCampagne()
			updateListAndSelection(fermeList, ferme)
		}).catch((error) => {
			if (error.status === 406) {
				snackError({id: 'bo.ferme.snackbar.update.matriceError'})
			} else {
				snackError({id: 'bo.ferme.snackbar.update.error'})
			}
		})
	}

	const handleCreation = (values) => {
		return createFerme(values).then((ferme) => {
			closeDialog()
			snackSuccess({ id: 'bo.ferme.snackbar.creation.success' })
			fermeList.push(ferme)
			updateListAndSelection(fermeList, ferme)
		}).catch(() => snackError({ id: 'bo.ferme.snackbar.creation.error' }))
	}

	const handleChange = (selection) => {
		setSelection(selection)
		setMenuSelection(PlanView.FORM.order)
	}

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.ferme.popin.title" />,
			<FermeForm
				isCreation
				formName={FORM_NAME_CREATION}
				onSubmit={handleCreation}
				fermeList={fermeList}
			/>,
			[
				<FormButton
					type="primary"
					formName={FORM_NAME_CREATION}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const formatInitialValues = (datas) => ({
		...datas,
		siret: datas.numSiret,
		telephone: datas.telephoneRepresentant,
		email: datas.emailRepresentant,
		representant: datas.nomRepresentant,
		minoration: datas.prodMino,
		savedFile: datas.urlImageFerme,
		lundiDebut: datas.lundiDebut,
		mardiDebut: datas.mardiDebut,
		mercrediDebut: datas.mercrediDebut,
		jeudiDebut: datas.jeudiDebut,
		vendrediDebut: datas.vendrediDebut,
		samediDebut: datas.samediDebut,
		dimancheDebut: datas.dimancheDebut,
	})

	const formatTuiles = (fermes) => fermes.map(ferme => ({
		uniqueId: `ferme-${ferme.id}`,
		icon: StoreIcon,
		title: ferme.raisonSociale,
		subtitle: ferme.numSiret && `(${ferme.numSiret})`,
		description: `${ferme.adresse},${ferme.codePostal ? ` ${ferme.codePostal}` : ''} ${ferme.ville}`,
		data: ferme
	}))

	return (
		<>
			<Grid container justify="space-between">
				<Typography variant="h2">
					<FormattedMessage id="menu.bo.ferme" />
				</Typography>
				<Button type="secondary" keepCase onClick={openPopinCreation}>
					<FormattedMessage id="bo.ferme.buttonNew" />
				</Button>
			</Grid>
			<BackOfficeContainer withFilter={false}>
				<BoListView
					titleLeft={<FormattedMessage id="bo.ferme.title" />}
					titleRight={selection && selection.data ? `${selection.data.raisonSociale} :` : ''}
					tuileList={formatTuiles(fermeList)}
					selection={selection}
					handleSelection={(selection) => handleChange(selection)}
					withFilter={false}
					formName={FORM_NAME_UPDATE}
				>
					{
						selection && selection.data && (
							<Grid>
								<Grid container justify="center" >
									<StyledTabs value={menuSelection} onChange={(_, index) => setMenuSelection(index)}>
										{Object.values(PlanView)
											.map(onglet => <StyledTab key={onglet.code} label={formatMessage({ id: `bo.ferme.${onglet.code}` })} />)}
									</StyledTabs>
								</Grid>
								<FermeForm
									formName={FORM_NAME_UPDATE}
									initialValues={formatInitialValues(selection.data)}
									onSubmit={values => handleUpdate(selection.data.id, values)}
									handleExport={() => onExportMatriceProximiteClick(selection.data.id)}
									fermeList={fermeList}
									menuSelection={menuSelection}
								/>
							</Grid>
						)}
				</BoListView>
			</BackOfficeContainer>
		</>
	)
}

const actions = {
	updateFermeList,
	getNotifications
}

FermeForm.propTypes = {
	fermeList: PropTypes.array,
	updateFermeList: PropTypes.func
}

export default compose(
		connect(undefined, actions),
		injectFermeList
)(FermePage)

