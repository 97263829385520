import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import TourPlaineContext from './TourPlaineContext'
import moment from 'moment'
import {MAX_WEEKS, TOUR_PLAINE_LAUNCH_SOLVER_VALUE, TOUR_PLAINE_TABS, TOUR_PLAINE_VALIDATION_FORM, ZONE_TYPE} from './tool/tourPlaine.constants'
import {FormattedMessage, useIntl} from 'react-intl'
import {DateFormat} from '../../../utils/dateConstants'
import {getBioAggressors, getFarmPlan, getNotifs} from './component/suiviParBloc/service/suiviParBlocApi'
import {getContextePedoclimatique} from './component/contextePedoclimatique/service/contextePedoclimatiqueApi'
import {contextePedoclimatiqueToApp} from './component/contextePedoclimatique/service/contextePedoclimatiqueApiMapper'
import {bioAggressorsToApp, farmPlanToApp, notifsToApp} from './component/suiviParBloc/service/suiviParBlocApiMapper'
import {BLOCK_ACTION_TYPE as SVB_BLOCK_ACTION_TYPE, BLOCK_DEFAULT_VALUE} from './component/suiviParBloc/tool/suiviParBloc.constants'
import {DAY_PERIOD, INSIDE_TILES, OUTSIDE_TILES} from './component/contextePedoclimatique/tool/contextePedoclimatique.constants'
import PropTypes from 'prop-types'
import {TYPE_DIALOG} from '../../../utils/constants'
import {DialogContext} from '../../../components/layout/dialog'
import DoneOutline from '@material-ui/icons/DoneOutline'
import FormButton from '../../../components/form/FormButton'
import SaveTourDialogForm from './component/dialog/SaveTourDialogForm'
import SaveTourErrorDialog from './component/dialog/SaveTourErrorDialog'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import {getCurrentCampagne} from '../../common/campagne/campagneSelector'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {launchSolver} from '../../common/solver/solverApi'
import {getDonneesManquantesTDP, validationTdp} from './service/tourPlaineApi'

/**
 * TourPlaineProvider
 * @param children
 * @returns {JSX.Element}
 */
const TourPlaineProvider = ({ children, initialTab, initialActionTab, blockId, currentCampagne }) => {

	const { formatMessage } = useIntl()
	const { openDialog, closeDialog } = useContext(DialogContext)
	const { snackError, snackSuccess } = useSnackbar()
	/**
	 * UseState
	 */
	const [tab, setTab] = useState(initialTab || TOUR_PLAINE_TABS.CONTEXTE_PEDOCLIMATIQUE.code)
	const [actionTab, setActionTab] = useState(initialActionTab)
	const [week, setWeek] = useState(moment().startOf('isoWeeks').format(DateFormat.YYYY_MM_DD))
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState({ contexte: {}, suiviBlock: BLOCK_DEFAULT_VALUE, suiviBlockActions: Object.keys(SVB_BLOCK_ACTION_TYPE).map(key => SVB_BLOCK_ACTION_TYPE[key]) })
	const [bioAggressors, setBioAggressors] = useState([])
	const [initialBlockId, setInitialBlockId] = useState(blockId)

	/**
	 * UseMemo
	 */
	const weekOptions = useMemo(() => {
		const today = moment()
		return [...Array(MAX_WEEKS)].map((_, i) => ({
				id: moment(today).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.YYYY_MM_DD),
				label: formatMessage({ id: 'tourPlaine.toolbar.selector.week' },
					{
						weekNumber: moment(today).add(i, 'weeks').week(),
						start: moment(today).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.DD_MM_SLASH),
						end: moment(today).add(i, 'weeks').endOf('isoWeeks').format(DateFormat.DD_MM_SLASH)
					})
			})
		)
	}, [])

	const shouldDuplicateWeatherContextePedoclimatique = useCallback((dayKey, type, dayPeriod) => {
		const startOfWeek = moment(dayKey, DateFormat.YYYY_MM_DD).startOf('isoWeeks').format(DateFormat.YYYY_MM_DD)
		let result = false
		if (dayPeriod) {
			result = !data.contexte[startOfWeek][dayKey][dayPeriod][ZONE_TYPE.INSIDE] || !data.contexte[startOfWeek][dayKey][dayPeriod][ZONE_TYPE.INSIDE][type]
		} else {
			// check for all period
			result = Object.keys(DAY_PERIOD).every(pkey => {
				const period = DAY_PERIOD[pkey]
				return !data.contexte[startOfWeek][dayKey][period][ZONE_TYPE.INSIDE] || !data.contexte[startOfWeek][dayKey][period][ZONE_TYPE.INSIDE][type]
			})

		}

		return result
	}, [data])

	const getTPErrors = useCallback(async () => {

		const error = []
		if (data.contexte && Object.keys(data.contexte).length && Object.keys(data.contexte).includes(week)) {
			// Week key for the current and the next week n+1
			const weekKeys = [week, moment(week).add(1, 'weeks').startOf('isoWeeks').format(DateFormat.YYYY_MM_DD)]
			const contexteHasEmptyValue = weekKeys.some(weekKey => {
				// For each week
				const week = data.contexte[weekKey]
				return Object.keys(week).some(dayKey => {
					// For each day
					const day = week[dayKey]
					return Object.keys(DAY_PERIOD).some(periodKey => {
						// For each period check inside & outside
						const period = DAY_PERIOD[periodKey]

						const insideHasEmpty = Object.keys(INSIDE_TILES).some(insideKey => {
							return day[period][ZONE_TYPE.INSIDE][INSIDE_TILES[insideKey]] === undefined
						})

						if (insideHasEmpty) {
							return true
						}

						return Object.keys(OUTSIDE_TILES).some(outsideKey => {
							return day[period][ZONE_TYPE.OUTSIDE][OUTSIDE_TILES[outsideKey]] === undefined
						})
					})
				})
			})

			if (contexteHasEmptyValue) {
				error.push({
					label: `${formatMessage({ id: `tourPlaine.tabBar.${TOUR_PLAINE_TABS.CONTEXTE_PEDOCLIMATIQUE.code}` })} ${formatMessage({ id: 'tourPlaine.dialog.cantSaveTP.mandatoryWeek' }, { n: moment(week).week(), n1: moment(week).add(1, 'weeks').week() })} `
				})
			}
		}

		const result = await getDonneesManquantesTDP(week)
		if (result.recoltes.length > 0) {
			error.push({
				label:  formatMessage({ id: `tourPlaine.tabBar.${TOUR_PLAINE_TABS.SUIVI_BLOC.code}` }),
				actionTab: formatMessage({ id: `tourPlaine.suiviParBloc.actionTab.${SVB_BLOCK_ACTION_TYPE.TASK.code}` }),
				blocks: result.recoltes.join(" / ")
			})
		}
		return error
	}, [data, week])

	/**
	 * UseCallback
	 */

	/**
	 * Api CALL
	 */
	const loadContextePedoclimatique = useCallback((newWeek) => {
		setIsLoading(true)
		getContextePedoclimatique(newWeek)
			.then(response => {
				const contextePedoclimatiqueData = contextePedoclimatiqueToApp(response)
				setData(prev => ({
					...prev,
					contexte: contextePedoclimatiqueData
				}))
				setWeek(newWeek)
			})
			.finally(() => setIsLoading(false))
	}, [])

	const loadSuiviBlock = useCallback(() => {
		return getFarmPlan()
			.then(response => {
				const suiviBlockData = farmPlanToApp(response)
				setData(prev => ({
					...prev,
					suiviBlock: suiviBlockData
				}))
			})
	}, [])

	const loadSuiviBlockNotifs = useCallback((blockId) => {
		if (!blockId) {
			return
		}

		return getNotifs({
			blocId: blockId,
			dateDebut: week
		})
			.then(response => {
				const notifsData = notifsToApp(response)
				setData(prev => ({
					...prev,
					suiviBlockActions: Object.keys(SVB_BLOCK_ACTION_TYPE).map(key => {
						const code = SVB_BLOCK_ACTION_TYPE[key].code
						return ({
							...SVB_BLOCK_ACTION_TYPE[key],
							notif: notifsData[code] ? notifsData[code] : 0
						})
					})
				}))
			})
	}, [week])

	const handleSaveTour = useCallback((values) => {
		validationTdp(!!values[TOUR_PLAINE_LAUNCH_SOLVER_VALUE])
			.then(() => snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.saveTP" />))
			.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.saveTP" values={{ campagne: currentCampagne.id }} />))
		closeDialog()
	}, [launchSolver, week, currentCampagne, closeDialog])

	/**
	 * Actions
	 */
	const handleTabChange = useCallback((newTab) => {
		// reset actionTab
		setActionTab(undefined)
		setTab(newTab)
	}, [])

	const handleActionTabChange = useCallback((newActionTab) => setActionTab(newActionTab), [])

	const resetInitialBlockId = useCallback(() => setInitialBlockId(undefined), [])

	const handleWeekChange = useCallback((newWeek) => {
		loadContextePedoclimatique(newWeek)
	}, [loadContextePedoclimatique])

	const saveTour = useCallback(async () => {
		const tpErrors = await getTPErrors()
		if (!tpErrors.length) {
			openDialog({ id: 'tourPlaine.dialog.title' },
				<SaveTourDialogForm onSubmit={handleSaveTour} />,
				[
					<FormButton
						type="primary"
						variant="text"
						withBorder={false}
						formName={TOUR_PLAINE_VALIDATION_FORM}
					>
						<FormattedMessage id="actions.confirm" />
					</FormButton>
				],
				TYPE_DIALOG.COOKIE,
				'actions.cancel',
				<DoneOutline height={24} width={24} color="primary" />)
		} else {
			openDialog({ id: 'tourPlaine.dialog.title' },
				<SaveTourErrorDialog tpErrors={tpErrors} />,
				[],
				TYPE_DIALOG.ACTION,
				'actions.close',
				<DoneOutline height={24} width={24} color="primary" />)
		}
	}, [getTPErrors, handleSaveTour])

	// Contexte Pédoclimatique
	/**
	 * Save Data
	 */
	const saveWeatherContextePedoclimatique = useCallback((dayKey, dayPeriod, zone, type, value, fullday) => {
		const startOfWeek = moment(dayKey, DateFormat.YYYY_MM_DD).startOf('isoWeeks').format(DateFormat.YYYY_MM_DD)
		setData(prev => {
				const prevContexte = prev.contexte
				return ({
					...prev,
					contexte: {
						...prevContexte,
						[startOfWeek]: {
							...prevContexte[startOfWeek], // rest of the day
							[dayKey]: { // the day to update
								...prevContexte[startOfWeek][dayKey],
								[dayPeriod]: { // the day period to update
									...prevContexte[startOfWeek][dayKey][dayPeriod],
									[zone]: { // the zone to update
										...prevContexte[startOfWeek][dayKey][dayPeriod][zone],
										[type]: value // the type to update
									}
								},
								...(fullday ? {
									[DAY_PERIOD.PM]: { // the day period to update
										...prevContexte[startOfWeek][dayKey][DAY_PERIOD.PM],
										[zone]: { // the zone to update
											...prevContexte[startOfWeek][dayKey][DAY_PERIOD.PM][zone],
											[type]: value // the type to update
										}
									}
								} : {})
							}
						}
					}
				})
			}
		)
	}, [data])

	useEffect(() => {
		loadContextePedoclimatique(moment().startOf('isoWeeks').format(DateFormat.YYYY_MM_DD))
		getBioAggressors().then(result => setBioAggressors(bioAggressorsToApp(result)))
	}, [])

	const contextValue = useMemo(() => ({
		isLoading,
		currentTab: tab,
		setTab: handleTabChange,
		currentActionTab: actionTab,
		setActionTab: handleActionTabChange,
		currentWeek: week,
		setWeek: handleWeekChange,
		weekOptions,
		saveTour,
		data,
		saveWeatherContextePedoclimatique,
		shouldDuplicateWeatherContextePedoclimatique,
		loadSuiviBlock,
		loadSuiviBlockNotifs,
		initialBlockId,
		resetInitialBlockId,
		bioAggressors
	}), [
		isLoading,
		tab,
		handleTabChange,
		actionTab,
		handleActionTabChange,
		week,
		handleWeekChange,
		weekOptions,
		saveTour,
		data,
		saveWeatherContextePedoclimatique,
		shouldDuplicateWeatherContextePedoclimatique,
		loadSuiviBlock,
		loadSuiviBlockNotifs,
		initialBlockId,
		resetInitialBlockId,
		bioAggressors
	])


	return (
		<TourPlaineContext.Provider value={contextValue}>
			{children}
		</TourPlaineContext.Provider>
	)
}


TourPlaineProvider.propType = {
	initialTab: PropTypes.string,
	initialActionTab: PropTypes.string,
	children: PropTypes.object,
	currentCampagne: PropTypes.object
}

const mapStateToProps = state => ({
	currentCampagne: getCurrentCampagne(state) || {}
})

export default compose(
	connect(mapStateToProps)
)(TourPlaineProvider)

