import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as selectors from './configurationSelector'
import * as configurationActions from './configurationAction'

export const loadConfiguration = (WrappedComponent) => {
	const ConfigurationInjector = ({ getConfiguration, ...props }) => {
		React.useEffect(() => {
			if (!props.configuration) {
				getConfiguration()
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		configuration: selectors.getConfiguration(state)
	})

	const actions = {
		getConfiguration: configurationActions.getConfiguration
	}

	ConfigurationInjector.propTypes = {
		getConfiguration: PropTypes.func,
		configuration: PropTypes.object
	}

	return connect(mapStateToProps, actions)(ConfigurationInjector)
}
