import React, { useContext, useEffect, useState, useCallback } from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles, Box } from '@material-ui/core'
import { colors } from '../../../../../../utils/constants'
import PropTypes from 'prop-types'
import Title from '../../layout/Title'
import { BIO_AGGRESSOR_FORM_NAME, BOARD_UNDER_PRESSURE_VALUE, BLOCK_ACTION_TYPE } from '../tool/suiviParBloc.constants'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import { FormattedMessage } from 'react-intl'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Edit, BugReport } from '@material-ui/icons'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import { getBoardsBioAggressors } from '../service/suiviParBlocApi'
import { boardsToApp } from '../service/suiviParBlocApiMapper'
import classnames from 'classnames'
import BugReportIcon from '@material-ui/icons/BugReport'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, Field } from 'redux-form'
import Checkbox from '../../../../../../components/form/Checkbox'
import { injectBioAggressorsAddForm } from '../service/bioAggressorsInjector'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import Loader from '../../../../../../components/layout/Loader'
import { DateFormat } from '../../../../../../utils/dateConstants'
import moment from 'moment'
import { groupEligibleBoardsByBioAggressor } from '../tool/suiviParBloc.utils'


/**
 * Styles
 */
const styles = theme => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '12px',
		height: '-webkit-fill-available'
	},
	header: {
		rowGap: 10,
		padding: '10px 12px'
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	action: {
		minWidth: 115,
		width: 115
	},
	filterText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: colors.text
	},
	loader: {
		height: 'calc(100vh - 564px)'
	},
	boardContainer: {
		marginTop: 10,
		gap: 10,
		height: '-webkit-fill-available'
	},
	board: {
		cursor: 'pointer',
		minHeight: 100,
		maxHeight: 132,
		padding: '10px 5px',
		borderRadius: 5,
		backgroundColor: theme.palette.primary.light,
		borderWidth: 4,
		borderStyle: 'solid',
		borderColor: 'transparent',
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	selectedBoard: {
		borderColor: colors.lightPrimary
	},
	inactiveBoard: {
		borderColor: 'transparent'
	},
	buttonContent: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flex: 1
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	title: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '600',
		color: '#000'
	},
	bodyDateText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#88888A'
	},
	bodytext: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	bodyContainer: {
		overflowY: 'auto',
		marginTop: 12,
		padding: 5,
		display: 'flex',
		flexDirection: 'column',
		gap: 5
	},
	dateContainer: {
		gap: 5
	}
})

/**
 * BioAggressorsContent for SuiviParBloc
 * @returns {JSX.Element}
 */
const BioAggressorsContent = ({ classes, block, boardUnderPressure, openBioAggressorsAddForm, openBioAggressorsUpdateForm }) => {
	const { closePanel } = useContext(ActionPanelContext)
	const { currentWeek, bioAggressors } = useTourPlaineContext()
	const [isLoading, setIsLoading] = useState(true)
	const [selectedBoards, setSelectedBoards] = useState([])
	const [boards, setBoards] = useState([])

	const loadData = useCallback(() => {
		setIsLoading(true)
		// reset selection
		setSelectedBoards([])
		getBoardsBioAggressors(block.uniqueId, currentWeek, boardUnderPressure)
			.then(result => {
				setBoards(boardsToApp(result))
			})
			.finally(() => setIsLoading(false))
	}, [block.uniqueId, currentWeek, boardUnderPressure])

	const canUpdateBioAggressor = groupEligibleBoardsByBioAggressor(selectedBoards, bioAggressors).length === 0

	const groupedBioAggressors = (boardBioAggressors) => {
		if (boardBioAggressors.length > 0) {

			const groupedArrayByBioAggressors = bioAggressors.reduce((acc, bioAggressor) => {
				const filteredboardBioAggressors = boardBioAggressors.filter(ba => ba.idBioAggressor === bioAggressor.id)

				if (filteredboardBioAggressors.length) {
					return ([
						...acc,
						{
							label: bioAggressor.label,
							bioAggressors: filteredboardBioAggressors
						}
					])
				}
				return acc
			}, [])

			return groupedArrayByBioAggressors.map((ba, index) => (
				<Grid container direction="row" className={classes.dateContainer} key={`${index}`}>
					<Grid item xs>
						<Typography className={classes.bodytext}>
							{ba.label}
						</Typography>
					</Grid>
					{
						ba.bioAggressors.map((ba, index) => (
							<Grid item container direction="row" justify="flex-end" className={classes.dateContainer}  key={`${ba.label}_${index}`}>
								<Typography className={classes.bodyDateText}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.start" />
								</Typography>
								<Typography className={classes.bodytext}>
									{moment(ba.start, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY)}
								</Typography>
								<Typography className={classes.bodyDateText}>
									<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.date.end" />
								</Typography>
								<Typography className={classes.bodytext}>
									{moment(ba.end, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY)}
								</Typography>
							</Grid>
						))
					}
				</Grid>
			))
		}
	}

	/**
	 * useEffect
	 */
	useEffect(() => {
		loadData()
	}, [loadData])

	return (
		<div className={classes.root}>
			<Grid container className={classes.header}>
				<Title actionBlock={BLOCK_ACTION_TYPE.BIO_AGGRESSORS} />
				<Grid item container direction="row" justify="space-between" alignItems="center">
					<Grid item xs>
						<Typography className={classes.descriptionText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.bio.description" />
						</Typography>
					</Grid>
					<Grid item>
						<ButtonWithMenu
							type="primary"
							startIcon={<MoreHorizIcon />}
							items={[
								{
									label: <Typography><FormattedMessage id="tourPlaine.actions.addBioAggressor" /></Typography>,
									icon: <BugReport color="primary" />,
									onClick: () => openBioAggressorsAddForm(block, selectedBoards, loadData)
								},
								{
									label: <Typography><FormattedMessage id="tourPlaine.actions.updateBioAggressor" /></Typography>,
									icon: <Edit color="primary" />,
									onClick: () => openBioAggressorsUpdateForm(block, selectedBoards, loadData),
									disabled: canUpdateBioAggressor
								}
							]}
							disabled={!selectedBoards.length}
							className={classes.action}
						>
							<FormattedMessage id="tourPlaine.actions.button" />
						</ButtonWithMenu>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center">
					<Grid item style={{ marginRight: 24 }}>
						<Typography className={classes.filterText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.bio.filter" />
						</Typography>
					</Grid>
					<Field
						name={BOARD_UNDER_PRESSURE_VALUE}
						component={Checkbox}
						label={<Typography className={classes.filterText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.bio.boardUnderPressure" />
						</Typography>
						}
					/>
				</Grid>
			</Grid>
			<Box display="grid" gridTemplateColumns="repeat(5, 1fr)" className={classes.boardContainer}>
				{
					isLoading ?
						(
							<Box gridColumn="span 5">
								<Loader show={isLoading} minHeight={false} style={classes.loader} />
							</Box>
						) : (
							boards.map((board, index) => {
									const isSelected = selectedBoards.find(sboard => sboard.id === board.id)
									return (
										<Box
											gridColumn="span 1"
											className={classnames(classes.board, isSelected ? classes.selectedBoard : classes.inactiveBoard)}
											key={`${index}_${board.id}`}
											onClick={() => setSelectedBoards(prev => {
												closePanel()
												if (prev.find(sboard => sboard.id === board.id)) {
													return prev.filter(sboard => sboard.id !== board.id)
												}

												return [...prev, board]
											})}
										>
											<div className={classes.titleContainer}>
												<Typography className={classes.title}>
													{board.label}
												</Typography>
												{
													board.bioAggressors.length > 0 ?
														<BugReportIcon style={{ color: '#B70F0A' }} />
														:
														<CheckCircleOutlineIcon style={{ color: '#1EBB6B' }} />
												}
											</div>
											<div className={classes.bodyContainer}>
												{groupedBioAggressors(board.bioAggressors)}
											</div>
										</Box>
									)
								}
							)
						)
				}
			</Box>
		</div>
	)
}

BioAggressorsContent.propTypes = {
	classes: PropTypes.object,
	block: PropTypes.object,
	boardUnderPressure: PropTypes.bool,
	openBioAggressorsAddForm: PropTypes.func,
	openBioAggressorsUpdateForm: PropTypes.func
}

const mapStateToProps = (state) => {
	const selector = formValueSelector(BIO_AGGRESSOR_FORM_NAME)
	return ({
		boardUnderPressure: selector(state, BOARD_UNDER_PRESSURE_VALUE)
	})
}

export default compose(
	injectBioAggressorsAddForm,
	connect(mapStateToProps, undefined),
	reduxForm({
		form: BIO_AGGRESSOR_FORM_NAME,
		enableReinitialize: true
	}),
	withStyles(styles)
)(BioAggressorsContent)