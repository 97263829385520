import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { injectTypeTacheValueList } from '../../../../common/valueLists/valueListInjectors'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { getLibelleLieu } from './ContenuPopinDeplacementTache'

const styles = () => getStyles({
	contenu: {
		margin: '1.5em',
		marginTop: 14
	},
	titre: {
		marginTop: 10,
		fontWeight: 'bold'
	},
	bloc: {
		marginTop: 22
	},
	liste: {
		marginTop: -6,
		marginLeft: -8
	}
})

const ContenuPopinSuppressionTache = ({ tachesEnErreur, classes, typeTacheList }) => {
	return (
		<Grid className={classes.contenu}>
			<Grid>
				<FormattedMessage id={'gestionTaches.toolbar.actions.delete.popin.error.text'} />
			</Grid>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					<FormattedMessage id={'gestionTaches.toolbar.actions.delete.popin.error.message'} />
				</Grid>
				<Grid className={classes.liste}>
					<ul>
						{
							tachesEnErreur.map(tache => {
								const typeTache = (typeTacheList.find(type => type.code === tache.type) || {}).label

								return (
									<li key={tache.id}>
										<FormattedMessageWithBold
											id={'gestionTaches.toolbar.actions.delete.popin.error.label'}
											values={{
												type: typeTache,
												culture: tache.culture && `- ${tache.culture}`,
												lieu: getLibelleLieu(tache.typeLieu, tache.lieu), }}
										/>
									</li>
								)
							})
						}
					</ul>
				</Grid>
			</Grid>
		</Grid>
	)
}

ContenuPopinSuppressionTache.propTypes = {
	tachesEnErreur: PropTypes.array,
	classes: PropTypes.object,
	typeTacheList: PropTypes.array
}

export default compose(
	withStyles(styles),
	injectTypeTacheValueList
)(ContenuPopinSuppressionTache)
