import {Grid, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {Field, reduxForm} from 'redux-form'
import {ADD_INSTRUCTION_FIELD, EMPTY_PLACEHOLDER, PANEL_FORM} from '../../tool/tourPlaine.constants'
import Input from '../../../../../components/form/Input'
import Detail from '../../../planning/component/detail/Detail'
import PickingIcon from '../../../../../components/icon/PickingIcon'
import {injectTypeTacheValueList} from '../../../../common/valueLists/valueListInjectors'
import {connect} from 'react-redux'
import ChatBubbleIcon from '../../../../../components/icon/ChatBubbleIcon'
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat} from "../../../../../utils/date/model/date";
import Typography from "@material-ui/core/Typography/index";

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25,
		rowGap: 25
	},
	radioText: {
		marginBottom: 10,
		marginLeft: 10
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 170
			}
		}
	},
	details: {
		width: 'initial !important'
	}
})


const validate = (values) => {

	const errors = {}
	const requiredFields = [
		ADD_INSTRUCTION_FIELD.INSTRUCTION
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: 'global.errors.mandatory' }
		}
	})

	return errors
}

/**
 * InstructionForm
 * @param handleSubmit
 * @param classes
 * @param inputLabel
 * @returns {JSX.Element}
 * @constructor
 */
const InstructionForm = ({ handleSubmit, classes, items, typeTacheList, isFromPlanification }) => {
	const firstSection = () => {
		return ({
			icon: <PickingIcon width={25} height={25} />,
			title: <FormattedMessage id="tourPlaine.form.instruction.sectionOne.title" />,
			content: <ul>
				{
					items.map((item) => {
						const task = typeTacheList.find(typeTache => typeTache.code === (item.task ?? item.type))
						return (
							<li style={{ paddingBottom: '5px' }} key={item.id}>
								{isFromPlanification ? <FormattedMessage id={'tourPlaine.form.instruction.sectionOne.bodyPlanification'}
																		 values={{
																			 task: task.label,
																			 date: LocalDate.fromAPI(item.date).toDisplayDate(DateFormat.SHORT_DATE),
																			 crop: item.culture,
																			 board: item.lieu,
																			 surface: item.surface
																		 }}
								/> : <FormattedMessage id={'tourPlaine.form.instruction.sectionOne.body'}
													   values={{
														   task: task.label,
														   date: item.plannedDate ? LocalDate.fromAPI(item.plannedDate).toDisplayDate(DateFormat.SHORT_DATE) : EMPTY_PLACEHOLDER,
														   crop: item.crop.name,
														   board: item.board.name
													   }}
								/>}
							</li>
						)
					})
				}
			</ul>
		})
	}

	const secondSection = () => ({
		icon: <ChatBubbleIcon width={25} height={25} />,
		title: <FormattedMessage id="tourPlaine.form.instruction.sectionTwo.title" />,
		content: <Grid>
			{isFromPlanification && <Typography style={{fontWeight: 300, paddingBottom: 20}}>
				<i><FormattedMessage id="tourPlaine.form.instruction.sectionTwo.info"/></i>
			</Typography>}
			<Field
				name={ADD_INSTRUCTION_FIELD.INSTRUCTION}
				component={Input}
				multiline
				containerClassName={classes.container}
			/>
		</Grid>
	})

	const sections = [
		firstSection(),
		secondSection()
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details} />
		</form>
	)
}

InstructionForm.propType = {
	items: PropTypes.object,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	typeTacheList: PropTypes.object
}

const mapStateToProps = (state, { items, ...props }) => {
	return ({
		initialValues: {
			[ADD_INSTRUCTION_FIELD.IDS_LIST]: items.map((item) => item.id)
		},
		items,
		...props
	})
}

export default compose(
	connect(mapStateToProps),
	injectTypeTacheValueList,
	reduxForm({
		form: PANEL_FORM.ADD_INSTRUCTION,
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)
(InstructionForm)
