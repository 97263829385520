import React from 'react'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import Comment from '../../../../../components/Comment'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import Detail from '../detail/Detail'
import PlancheForm from './component/PlancheForm'
import { getAllPlanches } from '../../services/planningApi'
import { DEPLACER_CULTURE_FORM_NAME } from '../../utils/constant'
import { fields } from './NouvelleCulture'
import InfoCulture from '../../../../common/information/InfoCulture'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { listeCulturesConcernees } from '../../../../../utils/utils'

const styles = () => getStyles({
	form: {
		width: '94%'
	},
	localisation: {
		alignItems: 'center',
		margin: 15
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const validate = values => {
	const errors = {}
	const requiredFields = [
		fields.LOCALISATION
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})
	return errors
}

const DeplacerCulture = ({ selectedAssolements, handleSubmit, campagne, classes }) => {

	// Listing pour saisie du formulaire
	const [planches, setPlanches] = React.useState([])

	React.useEffect(() => {
		getAllPlanches(campagne.idFerme).then(setPlanches)
	}, [])

	let sections
	if (selectedAssolements.length === 1) {
		const { culture } = selectedAssolements[0]
		sections = [
			{
				icon: <CultureIcon />,
				title: culture.nom,
				path: <InfoCulture culture={culture} />,
				content: <em>{culture.cultivar}</em>,
				comment: <Comment comment={culture.commentaire} />
			}
		]
	} else {
		sections = [
			{
				icon: <CultureIcon />,
				title: <FormattedMessage id="listAssolement.forms.h2.deplacerCultures" />,
				content: <ul>{listeCulturesConcernees(selectedAssolements)}</ul>
			}
		]
	}

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Detail sections={sections} />
			<Grid className={classes.localisation}>
				<PlancheForm editForm={true} planches={planches} formName={DEPLACER_CULTURE_FORM_NAME} />
			</Grid>
		</form>
	)
}

DeplacerCulture.propTypes = {
	plancheValue: PropTypes.string,
	handleSubmit: PropTypes.func,
	selectedAssolements: PropTypes.array
}

const mapStateToPros = state => ({
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToPros),
	withStyles(styles),
	reduxForm({
		form: DEPLACER_CULTURE_FORM_NAME,
		destroyOnUnmount: true,
		validate
	})
)(DeplacerCulture)
