import withStyles from '@material-ui/core/styles/withStyles'
import classnames from 'classnames'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {Rnd} from 'react-rnd'
import {colors, ETAPE_TYPE} from '../../../../../utils/constants'
import {getEtapeIconByType} from '../../../../../utils/utils'
import {MIN_ROW_SIZE} from '../../utils/constant'
import ContextMenuActions from './ContextMenuActions'

const ICON_PADDING = 2
const FR_PADDING = 6
const FERTI_PADDING = 4

const styles = () => getStyles({
	item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 4,
		cursor: 'initial !important'
	},
	moving: {
		cursor: 'move !important'
	},
	iconValide: {
		padding: 2
	},
	itemIsMoving: {
		color: 'white',
		padding: 2,
	}
})

const RESIZING_CONFIG = {
	top: false,
	right: false,
	bottom: false,
	left: false,
	topRight: false,
	bottomRight: false,
	bottomLeft: false,
	topLeft: false
}

const ITEM_HEIGHT = 32
const PERIODE_SEMIS_HEIGHT = ITEM_HEIGHT + 4

export const ETAPE_COLOR = {
	[ETAPE_TYPE.NM_PERIODE_SEMIS]: { background: 'transparent', border: '2px solid #804180', pointerEvents: "none" },
	[ETAPE_TYPE.SEMI_DIRECT]: { background: 'linear-gradient(0.25turn, #465516, #8fb50d)' },
	[ETAPE_TYPE.SEMI_EN_CONTENANT]: { background: '#804180' },
	[ETAPE_TYPE.PREP_PLANCHE]: { background: 'linear-gradient(0.25turn, #53320e, #aa671d)' },
	[ETAPE_TYPE.IMPLANTATION]: { background: 'linear-gradient(0.25turn, #1a4732, #199b68)' },
	[ETAPE_TYPE.NM_DUREE_ASSOLEMENT]: { background: '#f5f1ee', zIndex: '-10' },
	[ETAPE_TYPE.NM_DUREE_ASSOLEMENT_SELECTED]: { background: '#d8c7de', zIndex: '-10' },
	[ETAPE_TYPE.RECOLTE]: { background: 'linear-gradient(0.25turn, #3a639b, #7097ca)' },
	[ETAPE_TYPE.FIN_RECOLTE]: { background: 'linear-gradient(0.25turn, #0062a9, #0f98fa)' },
	[ETAPE_TYPE.FERTILISATION]: { background: 'linear-gradient(0.25turn, #ffd616, #ffefb0)' }
}


/**
 * Item représentant une étape de l'assolement
 * On décale l'item d'un pixel à gauche et droite pour laisser une margin
 */
const AssolementRowItem = ({
	campagne,
	gridDimension,
	itemWidth,
	x,
	etape,
	assolement,
	rowIsMoving,
	setEnableMovingRow,
	taskIsMoving,
	taskIsResizing,
	setMovingTask,
	setResizingTask,
	setXTask,
	setXTaskWidth,
	isAssolementValide,
	isCompare = true,
	classes
}) => {
	const [event, setEvent] = React.useState(null)
	const defaultHeight = etape.type === ETAPE_TYPE.NM_PERIODE_SEMIS ? PERIODE_SEMIS_HEIGHT : ITEM_HEIGHT
	const getEtapeIcon = (isAssolementValide) => {
		let padding = ICON_PADDING
		if (ETAPE_TYPE.FIN_RECOLTE === etape.type) {
			padding = FR_PADDING
		} else if (ETAPE_TYPE.FERTILISATION === etape.type || ETAPE_TYPE.SEMI_EN_CONTENANT === etape.type) {
			padding = FERTI_PADDING
		}
		const width = gridDimension.width - 2
		const minIconWidth = MIN_ROW_SIZE - 2 * ICON_PADDING - 2
		const maxWidth = Math.max(width - 2 * padding, minIconWidth)
		const paddingX = Math.min(padding, (width - maxWidth) / 2)
		return getEtapeIconByType(
			etape.type,
			isAssolementValide,
			{ style:{maxWidth, padding: `${padding}px ${paddingX}px`} }
		)
	}

	// DragConfig
	let dragConfig = {
		dragAxis: 'none',
		bounds: 'parent',
		size: { width: itemWidth - 2,  height:defaultHeight  },
		position: { x: x + 1, y: (gridDimension.height - defaultHeight) / 2 }
	}
	if (taskIsMoving) {
		dragConfig = {
			...dragConfig,
			bounds: `.moveItem-assolement-${assolement.id}`,
			dragAxis: 'x',
			onDrag: (e, d) => setXTask(d.x),
			onDragStop: () => setMovingTask(undefined)
		}
	}

	// ResizeConfig
	let resizeConfig = {
		enableResizing: RESIZING_CONFIG
	}
	if (taskIsResizing) {
		resizeConfig = {
			...resizeConfig,
			default: {
				width: itemWidth - 2,
				height: defaultHeight,
				x: x,
				y: (gridDimension.height - defaultHeight) / 2
			},
			dragAxis: 'none',
			bounds: `.moveItem-assolement-${assolement.id}`,
			enableResizing: {
				...resizeConfig.enableResizing,
				right: true,
				left: true
			},
			onResize: (e, dir, curr, { width }) => {
				// Normalisation de la width
				const formattedWidth = width > 0 ? Math.trunc(width / gridDimension.width) * gridDimension.width : -Math.ceil(-width / gridDimension.width) * gridDimension.width
				// Set de la width pour déplacer les autres étapes
				setXTaskWidth({ direction: dir, width: formattedWidth })
			},
			onResizeStop: () => setResizingTask(undefined)
		}
	}
	const isDureeAssolement = etape.type === ETAPE_TYPE.NM_DUREE_ASSOLEMENT || etape.type === ETAPE_TYPE.NM_DUREE_ASSOLEMENT_SELECTED

	const textToBig = gridDimension.width < MIN_ROW_SIZE + 2
	const fontSize = textToBig ? '0.65rem' : 'unset'
	const padding = textToBig ? '6px 2px' : 2
	return (<>
		<Rnd
			className={classnames(classes.item, { [classes.moving]: (rowIsMoving || taskIsMoving) })}
			style={isAssolementValide ? {background: colors.iconRevised } : ETAPE_COLOR[etape.type]}
			dragGrid={[gridDimension.width, gridDimension.height]}
			resizeGrid={[gridDimension.width, gridDimension.height]}
			minWidth={gridDimension.width - 2}
			maxHeight={defaultHeight}
			onContextMenu={(event) => {
				if ((assolement || isDureeAssolement) && !isCompare && !campagne.finished) {
					setEvent({...event})
				}
				event.preventDefault()
			}}
			{...dragConfig}
			{...resizeConfig}
		>
			{(taskIsMoving || taskIsResizing) ? <p className={classes.itemIsMoving} style={{fontSize, padding}}>{etape.type}</p> : getEtapeIcon(isAssolementValide)}
		</Rnd>
		{(assolement || isDureeAssolement) && !isCompare && !campagne.finished && <ContextMenuActions
			campagne={campagne}
			setEvent={setEvent}
			event={event}
			assolement={assolement}
			etape={etape}
			setEnableMovingRow={setEnableMovingRow}
			setEnableMovingTask={() => setMovingTask(etape.id)}
			setEnableResizingTask={() => setResizingTask(etape.id)}
		/>}
	</>)
}

AssolementRowItem.propTypes = {
	campagne: PropTypes.object,
	gridDimension: PropTypes.object,
	itemWidth: PropTypes.number,
	x: PropTypes.number,
	initXTask: PropTypes.number,
	etape: PropTypes.object,
	assolement: PropTypes.object,
	isAssolementValide: PropTypes.bool, // Assolement validé ?
	isCompare: PropTypes.bool, // Comparaison de planning ?
	rowIsMoving: PropTypes.bool, // La row complète est-elle en déplacement ?
	taskIsMoving: PropTypes.bool, // La etape de la row est-elle en déplacement ?
	taskIsResizing: PropTypes.bool, // La etape de la row est-elle en cours de resize ?
	setEnableMovingRow: PropTypes.func, // Fonction d'activation du déplacement de la row
	setMovingTask: PropTypes.func, // Fonction d'activation du déplacement de l'étape
	setResizingTask: PropTypes.func, // Fonction d'activation du resize de l'étape
	setXTask: PropTypes.func, // Fonction d'enregistrement des coordonnées de l'étape
	setXTaskWidth: PropTypes.func // Fonction d'enregistrement de la width de l'étape
}

export default withStyles(styles)(AssolementRowItem)
