import React, { useEffect, useState } from 'react'
import { getStyles } from 'isotope-client'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../../../../utils/constants'
import TractorIcon from '../../../../../components/icon/TractorIcon'
import { getStockMateriel } from './service/stockMaterielApi'
import StockCard from './component/StockCard'
import NoResultPage from "../../../../../components/NoResultPage";

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		marginTop: '20px',
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '10px',
		height: 'calc(100vh - 250px)',
	},
	header: {
		rowGap: '10px',
		padding: '10px 12px'
	},
	titleContainer: {
		padding: '0px 12px',
		columnGap: '12px'
	},
	titleText: {
		lineHeight: '25px',
		fontSize: 18,
		fontWeight: '600',
		color: colors.primary
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	blocDiv: {
		overflowY: 'auto',
		alignContent: 'flex-start',
		height: 'calc(100vh - 325px)'
	},
})

/**
 * StockMateriel
 * @returns {JSX.Element}
 */

const StockMateriel = ({ classes }) => {
	const [stockMaterielList, setStockMaterielList] = useState([])

	useEffect(() => {
		getStockMateriel()
			.then(stockMateriel => setStockMaterielList(stockMateriel))
	}, [])

	return (<div className={classes.root}>
		<Grid container className={classes.header}>
			<Grid item container direction="row" alignItems="center" className={classes.titleContainer}>
				<Grid item>
					<TractorIcon height={24} width={24} />
				</Grid>
				<Grid item>
					<Typography className={classes.titleText}>
						<FormattedMessage id="tourPlaine.stockMateriel.title" />
					</Typography>
				</Grid>
			</Grid>
			<Typography className={classes.descriptionText}>
				<FormattedMessage id="tourPlaine.stockMateriel.description" />
			</Typography>
		</Grid>
		<Grid container spacing={2} justify={"flex-start"} alignItems={"flex-start"} className={classes.blocDiv}>
			{ stockMaterielList.length > 0 ? stockMaterielList.map((stockMateriel, index) => (
				<Grid item xs={2} key={index}>
					<StockCard
						stockMateriel={stockMateriel}
						index={index}
						isEditable={true}
					/>
				</Grid>
			)) :
				<Grid container xs={12} justify={"center"}>
					<NoResultPage emptyStateMessageId="tourPlaine.stockMateriel.empty" />
				</Grid>
			}
		</Grid>
	</div>)
}

export default withStyles(styles)(StockMateriel)