import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import {colors, ROLE_WEIGHT} from '../../../utils/constants'
import {Grid, Typography} from '@material-ui/core'
import {push} from 'connected-react-router'
import {compose} from 'redux'
import {connect} from 'react-redux'
import * as userSelectors from "../../../modules/common/user/services/userSelectors";
import {hasRole} from "../../security/RoleChecker";
import {LocalDateTime} from "../../../utils/date/local-date-time";
import {DateFormat} from "../../../utils/date/model/date";
import {readNotification} from "../../../modules/common/notification/notificationApi";
import {getNotifications} from "../../../modules/common/notification/notificationActions";

const styles = () => getStyles({
	link: {
		color: colors.link,
		textDecoration: 'underline',
		cursor: 'pointer',
		textAlign: 'center',
		padding: 0
	},
	infos: {
		padding: 0,
		fontStyle: 'italic',
		textAlign: 'right',
		display: 'flex',
		flexDirection: 'column'
	},
	metadata: {
		paddingTop: 8,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		width: '100%'
	}
})

const Notification = ({classes, data, handleClose, goToPage, getNotifications, isSuperviseur}) => {
	const onLinkClick = React.useCallback(() => {
		goToPage(data.urlLien)
		readNotification(data.id).then(getNotifications)
		handleClose()
	}, [goToPage, handleClose, data])

	const date = React.useMemo(() => {
		return LocalDateTime.fromAPI(data.dateMotification).format(DateFormat.DATETIME_SHORT_WITH_SECONDS)
	}, [data.dateMotification])
	return (
		<Grid container>
			<Typography variant="body1">
				{data.texte}
			</Typography>
			<Typography variant="body1" className={classes.metadata}>
				<span className={classes.link} onClick={onLinkClick}>
					{data.texteLien}
				</span>
				<span className={classes.infos}>
					{ isSuperviseur && <span>{data.nomFerme}</span> }
					<span>{date}</span>
				</span>
			</Typography>
		</Grid>
	)
}



const mapStateToProps = (state) => {
	const userAuthorities = userSelectors.getAuthorities(state)
	return {
		isSuperviseur: hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities)
	}
}

const actions = {
	goToPage: (url) => dispatch => dispatch(push(url)),
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(Notification)