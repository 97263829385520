import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {VALUE_LIST_SHORTCUTS} from '../../../utils/constants'
import {loadListFoByShortcut} from './valueListActions'
import {getItemsByListCode} from 'isotope-client/modules/valueList/services/valueListSelectors'
import * as userSelectors from '../user/services/userSelectors'
import {sortObjectByLabel} from '../../../utils/utils'

const injectList = (shortcut, listName) => (WrappedComponent) => {
	const ListInjector = ({ userLanguage, loadListFoByShortcut, ...props }) => {
		React.useEffect(() => {
			if (!props[listName] || props[listName].length === 0) {
				loadListFoByShortcut(shortcut, userLanguage)
			}
		}, [userLanguage])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		[listName]: sortObjectByLabel(getItemsByListCode(state)[shortcut] || []),
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	ListInjector.propTypes = {
		list: PropTypes.array,
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(ListInjector)
}

export const injectTypeSurfaceValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_SURFACE, 'typeSurfaceList')
export const injectZoneSurfaceValueList = injectList(VALUE_LIST_SHORTCUTS.ZONE_SURFACE, 'zoneSurfaceList')
export const injectFamilleCultureValueList = injectList(VALUE_LIST_SHORTCUTS.FAMILLE_CULTURE, 'familleCultureList')
export const injectEspeceCultureValueList = injectList(VALUE_LIST_SHORTCUTS.ESPECE_CULTURE, 'especeCultureList')
export const injectVarieteCultureValueList = injectList(VALUE_LIST_SHORTCUTS.VARIETE_CULTURE, 'varieteCultureList')
export const injectEtapeTypeValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_ETAPE, 'etapeTypeList')
export const injectCommentTypeValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_COMMENTAIRE_SUIVI, 'commentTypeList')
export const injectVariationTypeValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_VARIATION_IMPACT_PROD, 'variationTypeList')
export const injectTypeTacheValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_TACHE, 'typeTacheList')
export const injectTypeContratValueList = injectList(VALUE_LIST_SHORTCUTS.TYPE_CONTRAT, 'typeContratValueList')
export const injectFamilleTacheValueList = injectList(VALUE_LIST_SHORTCUTS.FAMILLE_TACHE, 'familleTacheList')

// Load des values list par défaut utilisées dans l'ensemble de l'application
export const loadDefaultValueList = (WrappedComponent) => {
	const DefaultValueListInjector = ({ loadListFoByShortcut, userLanguage, ...props }) => {
		React.useEffect(() => {
			Object.values(VALUE_LIST_SHORTCUTS).forEach(shortcut => loadListFoByShortcut(shortcut, userLanguage))
		}, [userLanguage])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		userLanguage: userSelectors.getUserLanguage(state)
	})

	const actions = {
		loadListFoByShortcut
	}

	DefaultValueListInjector.propTypes = {
		userLanguage: PropTypes.string,
		loadListFoByShortcut: PropTypes.func
	}

	return connect(mapStateToProps, actions)(DefaultValueListInjector)
}
