import PropTypes from 'prop-types'
import React from 'react'
import DataTableRessource from './tableRessource/DataTableRessource'
import DataTableRessourceListener from './tableRessource/DataTableRessourceListener'

export const SUIVI_RESSOURCE_TABLE = 'SuiviRessourceTable'

const SuiviRessourceTable = ({ idRessource, refreshTable }) => {
	return (<>
		<DataTableRessourceListener idRessource={idRessource} refreshTable={refreshTable}/>
		<DataTableRessource idRessource={idRessource} />
	</>)
}

SuiviRessourceTable.propTypes = {
	idRessource: PropTypes.string
}

export default SuiviRessourceTable
