import { Grid, withStyles } from '@material-ui/core'
import { EventAvailable } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '../../../../components/Button'
import DriveIcon from '../../../../components/icon/DriveIcon'
import Link from '../../../../components/Link'
import { colors } from '../../../../utils/constants'
import { openGSheet } from '../../../../utils/exportUtils'
import { getLastExport } from '../../../common/export/exportApi'

const styles = () => getStyles({
	container: {
		width: '50rem',
		margin: 'auto',
		'& > div:nth-child(odd):last-child': {
			margin: 'auto',
			'& > a': {
				marginBottom: 0
			}
		}
	},
	link: {
		color: colors.primary,
		textTransform: 'unset',
		marginBottom: '5.5rem',
		display: 'block'
	},
	button: {
		minWidth: '18.75rem'
	}
})

const LinkButtonNoStyle = ({ startIcon, onClick, to, id, values, classes }) => (
	<Link to={to} onClick={onClick} className={classes.link}>
		<Button startIcon={startIcon} type="secondary" className={classes.button}>
			<FormattedMessage id={`accueil.button.${id}`} values={values}/>
		</Button>
	</Link>

)

const LinkButton = withStyles(styles)(LinkButtonNoStyle)

const Buttons = ({ intl, classes }) => {
	const [exportSheet, setExportSheet] = React.useState()
	React.useEffect(() => {
		getLastExport().then(setExportSheet).catch(() => {})
	}, [])

	const onClick = () => exportSheet && openGSheet(exportSheet.sheetId)

	const exportValues = {
		date: exportSheet && intl.formatDate(exportSheet.dateCreationEnd)
	}
	return (
		<Grid container
		      justify="space-evenly"
		      wrap="wrap"
		      spacing={0}
		      className={classes.container}>
			<Grid item>
				<LinkButton startIcon={<EventAvailable/>} to="/planning" id="planning"/>
			</Grid>
			{exportSheet && <Grid item>
				<LinkButton onClick={onClick} startIcon={<DriveIcon/>} id="lastExport" values={exportValues}/>
			</Grid>}
		</Grid>
	)
}

export default injectIntl(withStyles(styles)(Buttons))
