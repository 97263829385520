import React from 'react'
import PropTypes from 'prop-types'

const GardenerIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512.001 512.001",
		}}
		viewBox="0 0 512.001 512.001"
	>
		<path
			d="M397.758 324H114.275c-19.315 0-37.451 7.096-51.065 20.554C49.532 358.075 42 375.825 42 395.109V496.03c0 8.268 6.716 15.97 15 15.97h397c8.284 0 15-7.702 15-15.97V395.109C469 354.88 437.707 324 397.758 324z"
			style={{
				fill: "#d19988",
			}}
		/>
		<path
			d="M256.063 324H114.275c-19.315 0-37.451 7.096-51.065 20.554C49.532 358.074 42 375.824 42 395.109V496.03c0 8.268 6.716 15.97 15 15.97h199.063V324z"
			style={{
				fill: "#ffbd9e",
			}}
		/>
		<path
			d="M395.969 404.471c-21.267-15.832-35.515-39.457-41.201-68.444-1.379-7.026-7.548-12.027-14.721-12.027H171.953c-7.173 0-13.342 5.001-14.721 12.027-5.687 28.987-19.935 52.645-41.201 68.477-3.795 2.826-6.031 7.24-6.031 11.967v80.058c0 8.268 6.716 15.471 15 15.471h262c8.284 0 15-7.203 15-15.471v-80.058c0-4.726-2.236-9.175-6.031-12z"
			style={{
				fill: "#b8cfd6",
			}}
		/>
		<path
			d="M256 324h-84.047c-7.173 0-13.342 5.001-14.721 12.027-5.687 28.987-19.935 52.645-41.201 68.477-3.795 2.825-6.031 7.24-6.031 11.967v80.059c0 8.268 6.716 15.471 15 15.471h131V324z"
			style={{
				fill: "#ebf6fa",
			}}
		/>
		<path
			d="M342.417 144H169.583c-8.284 0-14.583 6.649-14.583 14.917v85.447c0 31.732 19.362 59.247 47 70.755v58.151c0 24.765 19.825 45.73 44.639 45.73h17.92C289.372 419 310 398.035 310 373.27v-58.201c27.646-11.539 47-39.021 47-70.705v-85.447c0-8.268-6.299-14.917-14.583-14.917z"
			style={{
				fill: "#d19988",
			}}
		/>
		<path
			d="M256 144h-86.417c-8.284 0-14.583 6.649-14.583 14.917v85.447c0 31.732 19.362 59.247 47 70.755v58.151c0 24.765 19.825 45.73 44.639 45.73H256c.147 0 0-275 0-275z"
			style={{
				fill: "#ffbd9e",
			}}
		/>
		<path
			d="m467.909 156.905-123.909-53V65.549C344 28.854 314.066 0 277.273 0h-42.378C198.009 0 168 28.854 168 65.549v38.356l-123.909 53c-6.546 2.8-10.217 9.989-8.784 16.952C36.738 180.82 42.878 186 50 186h412c7.122 0 13.262-5.18 14.693-12.142 1.433-6.963-2.238-14.153-8.784-16.953z"
			style={{
				fill: "#57a15e",
			}}
		/>
		<path
			d="M256 0h-21.104C198.009 0 168 28.854 168 65.549v38.356l-123.909 53c-6.546 2.8-10.217 9.989-8.784 16.952C36.738 180.82 42.878 186 50 186h206V0z"
			style={{
				fill: "#6ac473",
			}}
		/>
		<path
			d="m467.909 156.905-123.909-53V103H168v.905l-123.909 53c-6.546 2.8-10.217 9.989-8.784 16.952C36.738 180.82 42.878 186 50 186h412c7.122 0 13.262-5.18 14.693-12.142 1.433-6.963-2.238-14.153-8.784-16.953z"
			style={{
				fill: "#ffc77d",
			}}
		/>
		<path
			d="M256 103h-88v.905l-123.909 53c-6.546 2.8-10.217 9.989-8.784 16.952C36.738 180.82 42.878 186 50 186h206v-83z"
			style={{
				fill: "#ffe57d",
			}}
		/>
	</svg>
)


GardenerIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

GardenerIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default GardenerIcon