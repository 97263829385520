import React, {useCallback, useEffect, useState} from 'react'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {Box, Fab, Grid, Typography, withStyles} from '@material-ui/core'
import {FormattedMessage, useIntl} from 'react-intl'
import {colors} from '../../../../../../utils/constants'
import PropTypes from 'prop-types'
import Title from '../../layout/Title'
import {BLOCK_ACTION_TYPE} from '../tool/suiviParBloc.constants'
import moment from 'moment'
import {DateFormat} from '../../../../../../utils/dateConstants'
import {useTourPlaineContext} from '../../../TourPlaineContext'
import IconButton from '../../../../../../components/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Button from '../../../../../../components/Button'
import Loader from '../../../../../../components/layout/Loader'
import {duplicateLastWeekIrrigations, getIrrigations} from '../service/suiviParBlocApi'
import {irrigationsToApp} from '../service/suiviParBlocApiMapper'
import {NUMERO_JOURS_SEMAINE} from '../../../../gestionTache/utils/constants'
import AddIcon from '@material-ui/icons/Add'
import {injectIrrigationAddForm} from '../service/irrigationInjector'
import {useSolverStatusContext} from '../../../../besoinsProduction/SolverStatusContextProvider'
import {getNotifications} from '../../../../../common/notification/notificationActions'
import {connect} from 'react-redux'

export const MAX_WEEKS = 3

/**
 * Styles
 */
const styles = (theme) => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '12px',
		minHeight: '-webkit-fill-available'
	},
	header: {
		rowGap: 10,
		padding: '10px 12px'
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	duplicateBtn: {
		padding: '5px 10px'
	},
	selectorContainer: {
		marginTop: 5
	},
	selectorWeekContainer: {
		gap: 20
	},
	selectorIconContainer: {
		height: 35,
		width: 35
	},
	selectorText: {
		fontStyle: 'normal',
		lineHeight: '18px',
		fontSize: 14,
		fontWeight: '600',
		color: '#000000',
		marginBottom: 4,
		textAlign: 'center'
	},
	loaderContainer: {
		height: 'calc(100vh - 564px)'
	},
	daysContainer: {
		height: '-webkit-fill-available',
		marginTop: 10,
		gap: 10
	},
	dayContainer: {
		height: '-webkit-fill-available',
		padding: '0px 15px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	dayTitleContainer: {
		display: 'flex',
		minHeight: 36,
		justifyContent: 'center',
		alignItems: 'center'
	},
	dayTitle: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '600',
		color: '#000'
	},
	dayPeriodContainer: {
		gap: 10,
		paddingBottom: '15px'
	},
	dayPeriod: {
		gap: 3,
		height: 60,
		borderRadius: 5,
		backgroundColor: theme.palette.primary.light
	},
	dayPeriodLabel: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	icon: {
		width: 40,
		height: 40
	},
	deleteContainer: {
		height: 25,
		width: 25,
		backgroundColor: '#FFF'
	}
})

/**
 * IrrigationContent for SuiviParBloc
 * @returns {JSX.Element}
 */
const IrrigationContent = ({ classes, block, openIrrigationAddForm, openDeleteDialog, getNotifications }) => {

	const { formatMessage } = useIntl()
	const { currentWeek: toolBarWeek } = useTourPlaineContext()
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	/**
	 * UseState
	 */
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [currentWeekId, setCurrentWeekId] = useState(toolBarWeek)

	const startOftheToolBarWeek = moment(toolBarWeek, DateFormat.YYYY_MM_DD).startOf('isoWeeks')

	/**
	 * Weeks for the selector N to N+3
	 * @type {{id: string, label: *}[]}
	 */
	const weekOptions = [...Array(MAX_WEEKS)].map((_, i) => ({
			id: moment(startOftheToolBarWeek).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.YYYY_MM_DD),
			label: formatMessage({ id: 'tourPlaine.contextePedoclimatique.selector.week' },
				{
					weekNumber: i,
					start: moment(startOftheToolBarWeek).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.DD_MM_SLASH),
					end: moment(startOftheToolBarWeek).add(i, 'weeks').endOf('isoWeeks').format(DateFormat.DD_MM_SLASH),
					b: (chunks) => <Typography className={classes.selectorText}>{chunks}</Typography>
				})
		})
	)

	const canGoNextWeek = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) >= 0 ? weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) + 1 < weekOptions.length : false

	// Go to the next week if possible
	const goNextWeek = () => {
		const index = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId)
		if (index + 1 < weekOptions.length) {
			setCurrentWeekId(weekOptions[index + 1].id)
		}
	}

	const canGoPreviousWeek = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) > 0 ? weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) - 1 >= 0 : false

	// Go to the previous week if possible
	const goPreviousWeek = () => {
		const index = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId)
		if (index - 1 >= 0) {
			setCurrentWeekId(weekOptions[index - 1].id)
		}
	}

	const startOftheWeek = moment(currentWeekId, DateFormat.YYYY_MM_DD).startOf('isoWeeks')
	const weekSelectorTitle = weekOptions.find(weekOption => weekOption.id === currentWeekId)

	/**
	 * WeekDays
	 * @type {{id: string, label: string}[]}
	 */
	const weekDays = [...Object.values(NUMERO_JOURS_SEMAINE)].map((value) => ({
			id: startOftheWeek.clone().add(value, 'days').format(DateFormat.YYYY_MM_DD),
			label: startOftheWeek.clone().add(value, 'days').locale('fr').format(DateFormat.dddd)
		})
	)

	const weeklyData = data.find(d => d.id === currentWeekId)
	/**
	 * Api Call
	 */
	const loadData = useCallback(() => {
		if (block.uniqueId) {
			setIsLoading(true)
			getIrrigations({
				blocId: block.uniqueId,
				dateDebut: toolBarWeek
			})
				.then(data => setData(irrigationsToApp(data, weekOptions)))
				.finally(() => setIsLoading(false))
		}
	}, [block, toolBarWeek])

	/**
	 * UseEffect
	 */
	useEffect(() => {
		loadData()
	}, [loadData])

	return (<div className={classes.root}>
		<Grid container className={classes.header}>
			<Title actionBlock={BLOCK_ACTION_TYPE.IRRIGATON} />
			<Grid item container direction="row" justify="space-between" alignItems="center">
				<Grid item>
					<Typography className={classes.descriptionText}>
						<FormattedMessage id="tourPlaine.suiviParBloc.irrigation.description" />
					</Typography>
				</Grid>
			</Grid>
			<Grid item container direction="row" justify="center" alignItems="center" className={classes.selectorContainer}>
				<Grid item container xs>
					{
						currentWeekId !== toolBarWeek && (
							<Button
								className={classes.duplicateBtn}
								withBorder={false}
								type="secondary"
								startIcon={<FileCopyIcon />}
								onClick={() => {
									setIsLoading(true)
									duplicateLastWeekIrrigations({
										blocId: block.uniqueId,
										dateDebutSemaineSelectionne: currentWeekId,
										dateDebutSemaineBase: toolBarWeek
									})
										.then((response) => {
											setData(irrigationsToApp(response, weekOptions))
											getNotifications()
											refreshSolverInfosForCurrentCampagne()
										})
										.finally(() => setIsLoading(false))
								}}
							>
								<FormattedMessage id={'tourPlaine.suiviParBloc.irrigation.action.duplicate'} />
							</Button>
						)
					}
				</Grid>
				<Grid item container xs direction="row" justify="center" alignItems="center" className={classes.selectorWeekContainer}>
					<IconButton className={classes.selectorIconContainer} onClick={goPreviousWeek} disabled={!canGoPreviousWeek}>
						<ArrowBackIosIcon />
					</IconButton>
					<Typography className={classes.descriptionText}>
						{weekSelectorTitle?.label}
					</Typography>
					<IconButton className={classes.selectorIconContainer} onClick={goNextWeek} disabled={!canGoNextWeek}>
						<ArrowForwardIosIcon />
					</IconButton>
				</Grid>
				<Grid item container xs />
			</Grid>
		</Grid>
		<Box display="grid" gridTemplateColumns="repeat(7, 1fr)" className={classes.daysContainer}>
			{
				isLoading ?
					(
						<Box gridColumn="span 7">
							<Loader show={isLoading} minHeight={false} style={classes.loaderContainer} />
						</Box>
					) : (
						weekDays.map(weekDay => {
							const dataDay = weeklyData ? weeklyData[weekDay.id] : []
							return (
								<Box gridColumn="span 1" className={classes.dayContainer} key={weekDay.id}>
									<div className={classes.dayTitleContainer}>
										<Typography className={classes.dayTitle}>
											{<FormattedMessage id={`enums.jours.${weekDay.label.toUpperCase()}`} />}
										</Typography>
									</div>
									<Grid container justify="center" className={classes.dayPeriodContainer}>
										{
											dataDay.map(dd => (
												<Grid item container direction="row" justify="center" alignItems="center" className={classes.dayPeriod}>
													<Typography className={classes.dayPeriodLabel}>
														{dd.start} - {dd.end}
													</Typography>
													<IconButton className={classes.deleteContainer} onClick={() => openDeleteDialog(dd, loadData)}>
														<DeleteIcon style={{ width: 15 }} color="primary" />
													</IconButton>
												</Grid>
											))
										}
										<Fab color="primary" onClick={() => openIrrigationAddForm(block, weekDay, dataDay, loadData)} className={classes.icon}>
											<AddIcon />
										</Fab>
									</Grid>
								</Box>
							)
						})
					)
			}
		</Box>
	</div>)
}

IrrigationContent.propTypes = {
	classes: PropTypes.object,
	block: PropTypes.object,
	openIrrigationAddForm: PropTypes.func,
	openDeleteDialog: PropTypes.func
}

const actions = {
	getNotifications
}

export default compose(
	connect(null, actions),
	injectIrrigationAddForm,
	withStyles(styles)
)(IrrigationContent)