import React from 'react'
import { FormattedMessage } from 'react-intl'
import PopinExport, { EXPORT_SUIVI_AVANCEMENT_FORM } from '../component/PopinExport/PopinExport'
import { TYPE_DIALOG } from '../../../../utils/constants'
import { DialogContext } from '../../../../components/layout/dialog'
import FormButton from '../../../../components/form/FormButton'
import { exportSuiviAvancement } from '../suiviAvancementApi'
import { checkExportStateUntilFound } from '../../../../utils/exportUtils'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getPreferences } from '../../../common/user/services/userSelectors'

/**
 * Injecteur pour l'ouverture de la popin d'export du suivi d'avancement
 */
export const injectExportSuiviAvancement = (WrappedComponent) => {
	const ExportSuiviAvancementInjector = (props) => {
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { snackError, snackSuccess } = useSnackbar()
		const {userPreferences} = props
		const onSubmit = (values) => {
			let promise = exportSuiviAvancement(values.debut, values.fin, userPreferences.FERME_ID)
			promise.then(checkExportStateUntilFound(snackSuccess))
				.then(closeDialog())
				.catch(() => snackError({ id: 'snackbar.error.exportProduction' }))
		}

		const openPopinExportSuiviAvancement = () => {

			openDialog(
				<FormattedMessage id="suiviAvancement.toolbar.export.titlePopin" />,
				<PopinExport onSubmit={onSubmit} />,
				[
					<FormButton type="primary" formName={EXPORT_SUIVI_AVANCEMENT_FORM}>
						<FormattedMessage id="suiviAvancement.toolbar.export.exporter" />
					</FormButton>
				],
				TYPE_DIALOG.ACTION
			)
		}
		return <WrappedComponent {...props} openPopinExportSuiviAvancement={React.useCallback(openPopinExportSuiviAvancement, [])} />
	}

	const mapStateToProps = state => ({
		userPreferences: getPreferences(state)
	})

	return compose(
		connect(mapStateToProps)
	)(ExportSuiviAvancementInjector)
}

