import React from 'react'
import PropTypes from 'prop-types'

const SemiPlancheIcon = ({ height, width, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M52 151c-8.284 0-15 6.716-15 15v104c0 8.284 6.716 15 15 15s15-6.716 15-15V166c0-8.284-6.716-15-15-15z"
				fill="#a9e570"
			/>
			<g fill="#6ac473">
				<path d="M103 112c-8.284 0-15 6.716-15 15v143c0 8.284 6.716 15 15 15s15-6.716 15-15V127c0-8.284-6.716-15-15-15zM205 112c-8.284 0-15 6.716-15 15v143c0 8.284 6.716 15 15 15s15-6.716 15-15V127c0-8.284-6.716-15-15-15z" />
			</g>
			<g fill="#57a15e">
				<path d="M307 112c-8.284 0-15 6.716-15 15v143c0 8.284 6.716 15 15 15s15-6.716 15-15V127c0-8.284-6.716-15-15-15zM409 112c-8.284 0-15 6.716-15 15v143c0 8.284 6.716 15 15 15s15-6.716 15-15V127c0-8.284-6.716-15-15-15z" />
			</g>
			<g fill="#a9e570">
				<path d="M154 151c-8.284 0-15 6.716-15 15v104c0 8.284 6.716 15 15 15s15-6.716 15-15V166c0-8.284-6.716-15-15-15zM256 151c-8.284 0-15 6.716-15 15v104c0 8.284 6.716 15 15 15s15-6.716 15-15V166c0-8.284-6.716-15-15-15z" />
			</g>
			<g fill="#6ac473">
				<path d="M358 151c-8.284 0-15 6.716-15 15v104c0 8.284 6.716 15 15 15s15-6.716 15-15V166c0-8.284-6.716-15-15-15zM460 151c-8.284 0-15 6.716-15 15v104c0 8.284 6.716 15 15 15s15-6.716 15-15V166c0-8.284-6.716-15-15-15z" />
			</g>
			<path
				d="M497 243H15c-8.284 0-15 6.208-15 14.492v96.514C0 378.819 20.187 400 45 400h422c24.813 0 45-21.181 45-45.994v-96.514c0-8.284-6.716-14.492-15-14.492z"
				fill="#cfa05f"
			/>
			<path
				d="M256.138 243H15c-8.284 0-15 6.208-15 14.492v96.514C0 378.819 20.187 400 45 400h211.138V243z"
				fill="#facc8c"
			/>
		</svg>
	)
}

SemiPlancheIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

SemiPlancheIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default SemiPlancheIcon
