import React from 'react'
import {ONGLET_GESTION_TACHE} from '../../../utils/constants'
import moment from 'moment'
import {refreshHeaderRessources} from './gestionTachesApi'
import {getAffectationTotaux, getOrdonnancementTotaux, getPlanificationTotaux} from './services/api'
import {LocalDate} from "../../../utils/date/local-date";
import {DateFormat, DateUnit} from "../../../utils/date/model/date";

const gestionTacheContextType = {
	onglet: ONGLET_GESTION_TACHE.SYNTHESE,
	setOnglet: (onglet) => {
	},

	semaineSelection: LocalDate.now().startOf(DateUnit.week).format(DateFormat.REVERSE_SHORT_DATE),
	setSemaineSelection: (semaineSelection) => {
	},

	semaineCourante: null,
	setSemaineCourante: (semaine) => {
	},

	selectedContrats: new Set(),
	setSelectedContrats: (contrat) => {
	},

	jourSelection: LocalDate.now().format(DateFormat.REVERSE_SHORT_DATE),
	setJourSelection: (jourSelection) => {
	},

	headerData: {},
	refreshHeaderData: () => {
	},

	isResultat: false,
	setIsResultat: (isResultat) => {
	}
}

export const GestionTacheContext = React.createContext(gestionTacheContextType)

export const useGestionTacheContext = () => React.useContext(GestionTacheContext)

const GestionTacheProvider = ({ children }) => {

	const [onglet, setOnglet] = React.useState(ONGLET_GESTION_TACHE.SYNTHESE)
	const [semaineSelection, setSemaineSelection] = React.useState(LocalDate.now().startOf(DateUnit.week).format(DateFormat.REVERSE_SHORT_DATE))
	const [jourSelection, setJourSelection] = React.useState(LocalDate.now().format(DateFormat.REVERSE_SHORT_DATE))
	const [semaineCourante, setSemaineCourante] = React.useState(null)
	const [headerData, setHeaderData] = React.useState({})
	const [selectedContrats, setSelectedContrats] = React.useState(new Set());
	const [isResultat, setIsResultat] = React.useState(false);

	/** Synchronise les headers avec les bonnes valeurs pour jourSelection (jourChoisi)
	 * cf. filterInjector.js pour le call avec param.
	 * */
	const fetchHeaderData = React.useCallback((onglet, date, jourChoisi = null) => {
		let promise = Promise.resolve({})
		switch (onglet) {
			case ONGLET_GESTION_TACHE.RESSOURCES:
				const dynamicFilters = { semaine: moment(date).format('YYYY-MM-DD'), contrats: [...selectedContrats] };
				promise = refreshHeaderRessources(dynamicFilters)
				break
			case ONGLET_GESTION_TACHE.PLANIFICATION:
				promise = getPlanificationTotaux(moment(date).format('YYYY-MM-DD'))
				break
			case ONGLET_GESTION_TACHE.AFFECTATION:
				jourChoisi
					? promise = getAffectationTotaux(moment(jourChoisi).format('YYYY-MM-DD'))
					: promise = getAffectationTotaux(moment(date).format('YYYY-MM-DD'))
				break
			case ONGLET_GESTION_TACHE.ORDONNANCEMENT:
				jourChoisi
					? promise = getOrdonnancementTotaux(moment(jourChoisi).format('YYYY-MM-DD'))
					: promise = getOrdonnancementTotaux(moment(date).format('YYYY-MM-DD'))
				break
			default:
		}
		return promise.then(setHeaderData)
	}, [selectedContrats])

	/** Prends en compte le switch d'onglet dans n'importe quel ordre */
	const handleOngletChange = React.useCallback((newOnglet, jour) => {
		setOnglet(newOnglet)
		setSelectedContrats(new Set())
		if (newOnglet === ONGLET_GESTION_TACHE.RESSOURCES || newOnglet === ONGLET_GESTION_TACHE.PLANIFICATION) {
			return fetchHeaderData(newOnglet, semaineSelection)
		}
		return fetchHeaderData(newOnglet, jour)
	}, [fetchHeaderData, semaineSelection])

	const handleSelectSemaine = React.useCallback(semaine => {
		setSemaineSelection(semaine)
		return fetchHeaderData(onglet, semaine)
	}, [])

	const handleSelectJour = React.useCallback(jour => {
		setJourSelection(jour)
		return fetchHeaderData(onglet, jour)
	}, [fetchHeaderData, onglet])

	return (
		<GestionTacheContext.Provider value={{
			onglet,
			setOnglet: handleOngletChange,
			semaineSelection,
			setSemaineSelection: handleSelectSemaine,
			semaineCourante,
			setSemaineCourante,
			setSelectedContrats,
			selectedContrats,
			jourSelection,
			setJourSelection: handleSelectJour,
			headerData,
			refreshHeaderData: (jourChoisi) => fetchHeaderData(onglet, semaineSelection, jourChoisi),
			isResultat,
			setIsResultat
		}}>
			{children}
		</GestionTacheContext.Provider>
	)
}


export default GestionTacheProvider
