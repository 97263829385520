import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const DetailEtapeIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M304.038 103.717V1.012H85.818c-11.733 0-21.245 9.512-21.245 21.245v469.51c0 11.733 9.512 21.245 21.245 21.245h339.917c11.733 0 21.245-9.512 21.245-21.245V143.423H343.744c-21.893 0-39.706-17.812-39.706-39.706zm-85.793 298.837l-45.198 45.198c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.393l-18.764-18.763c-5.858-5.858-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l8.157 8.157 34.592-34.592c5.857-5.858 15.355-5.858 21.213 0 5.858 5.857 5.858 15.354-.001 21.212zm0-100l-45.198 45.198c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.393l-18.764-18.763c-5.858-5.858-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l8.157 8.157 34.592-34.592c5.857-5.858 15.355-5.858 21.213 0 5.858 5.857 5.858 15.354-.001 21.212zm0-107.637l-45.198 45.198c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.394l-18.764-18.764c-5.858-5.858-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l8.157 8.157 34.592-34.591c5.857-5.858 15.355-5.858 21.213 0s5.858 15.355-.001 21.213zm149.631 234.63h-96c-8.284 0-15-6.716-15-15s6.716-15 15-15h96c8.284 0 15 6.716 15 15s-6.716 15-15 15zm-111-115c0-8.284 6.716-15 15-15h95.571c8.284 0 15 6.716 15 15s-6.716 15-15 15h-95.571c-8.284 0-15-6.716-15-15zm125.572-100c0 8.284-6.716 15-15 15h-95.571c-8.284 0-15-6.716-15-15s6.716-15 15-15h95.571c8.284 0 15 6.716 15 15z"
				fill={color}
			/>
			<path
				d="M334.038 103.717c0 5.352 4.354 9.706 9.706 9.706h104.694L334.038 0z"
				fill={color}
			/>
		</svg>
	)
}

DetailEtapeIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

DetailEtapeIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default DetailEtapeIcon
