import { Grid, Box, Typography, withStyles, useMediaQuery  } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React, {useState} from 'react'
import { FormattedMessage } from 'react-intl'
import * as userSelectors from "../../../common/user/services/userSelectors";
import {compose} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {colors} from "../../../../utils/constants";
import HeaderBloc from "./HeaderBloc";
import GrowingIcon from "../../../../components/icon/GrowingIcon";
import WindMillIcon from "../../../../components/icon/WindMillIcon";
import ChatBubbleIcon from "../../../../components/icon/ChatBubbleIcon";
import PlantsIcon from "../../../../components/icon/PlantsIcon";
import TractorIcon from "../../../../components/icon/TractorIcon";
import PlanningBloc from "./PlanningBloc";
import MaterielsBloc from "./MaterielsBloc";
import BesoinsBloc from "./BesoinsBloc";
import CommentaireBloc from "./CommentaireBloc";
import ConditionPedoclimatiqueBloc from "./ConditionPedoclimatiqueBloc";

const styles = () => getStyles({
	container: {
		textAlign: 'center',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto'
	},
	head: {
		fontWeight: 'bold',
		letterSpacing: 1,
		marginBottom: "3.5rem",
		marginTop: "2.5rem",
		color: colors.primary,
	},
	blocContainer: {
		margin: "0 auto",
		width: "90%",
		paddingBottom: 20
	},
})

const TableauDeBordContent = ({ classes, user }) => {
	const isMediumScreen = useMediaQuery('(min-width: 1280px) and (max-width: 1350px)');
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [refreshNote, setRefreshNote] = useState(false)

	return (
	<Box container className={classes.container}>
		<Typography variant="h3" className={classes.head}>
			<FormattedMessage id="tdb.title" values={{ name: user.firstname }}/>
		</Typography>
		<Grid container justify={'center'} spacing={3} className={classes.blocContainer}>
			<Grid item xs={12} md={5} lg={3} style={{order: 1}}>
				<HeaderBloc icon={GrowingIcon} title={'planning.title'} height={250}>
					<PlanningBloc/>
				</HeaderBloc>
			</Grid>
			<Grid item xs={12} md={7} lg={5} style={{order: 2}}>
				<HeaderBloc icon={PlantsIcon} title={'production.title'} height={250}>
					<BesoinsBloc/>
				</HeaderBloc>
			</Grid>
			<Grid item xs={12} md={6} lg={4} style={{order: isSmallScreen ? 4 : 3}} >
				<HeaderBloc refreshNote={refreshNote} setRefreshNote={setRefreshNote} icon={ChatBubbleIcon} title={'commentaires.title'} isCommentaire={true} height={350}>
					<CommentaireBloc setRefreshNote={setRefreshNote} refreshNote={refreshNote}/>
				</HeaderBloc>
			</Grid>
			<Grid item xs={12} md={12} lg={8} style={{order: isSmallScreen ? 3 : 4, marginTop: isSmallScreen ? 0 : -100}}>
				<HeaderBloc icon={WindMillIcon} title={'pedoclimatique.title'} urlDetail={"tourDePlaine"} height={isMediumScreen ? 625 : 475}>
					<ConditionPedoclimatiqueBloc/>
				</HeaderBloc>
			</Grid>
			<Grid item xs={12} md={6} lg={4} style={{order: 5}}>
				<HeaderBloc icon={TractorIcon} title={'materiels.title'} urlDetail={'materiel'} height={isSmallScreen ? 350 : isMediumScreen ? 525 : 375}>
					<MaterielsBloc isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}/>
				</HeaderBloc>
			</Grid>
		</Grid>
	</Box>
)
}

TableauDeBordContent.propTypes = {
	user: PropTypes.object
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(TableauDeBordContent)