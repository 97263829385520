import React from 'react'
import PropTypes from 'prop-types'

const ChatBubbleIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 48 48"
	>
		<g data-name="Chat Bubble">
			<path
				fill="#d3dbef"
				d="M42 4H6a4 4 0 0 0-4 4v22a4 4 0 0 0 4 4h3v8.955a.995.995 0 0 0 1.707.737L20 34h22a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4z"
			/>
			<path
				fill="#bfcae2"
				d="M42 4h-2a4 4 0 0 1 4 4v22a4 4 0 0 1-4 4h2a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4z"
			/>
			<g fill="#8491c1">
				<circle cx={24} cy={19} r={3} />
				<circle cx={13} cy={19} r={3} />
				<circle cx={35} cy={19} r={3} />
			</g>
		</g>
	</svg>
)


ChatBubbleIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

ChatBubbleIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default ChatBubbleIcon