import {Grid, Typography} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {NOM_ONGLET_GESTION_TACHES} from '../../../../../utils/constants'
import {useGestionRessourceContext} from '../../GestionRessourceProvider'
import StyledTabs from '../../../../../components/layout/tab/StyledTabs'
import StyledTab from '../../../../../components/layout/tab/StyledTab'

const styles = () => getStyles({
	content: {
		height: 120
	},
	tabBar: {
		borderBottom: '1px solid lightgray'
	}
})

const getOngletTaches = () => {
	return [
		NOM_ONGLET_GESTION_TACHES.RESSOURCES,
	]
}

const ToolBar = ({ classes }) => {
	const {onglet} = useGestionRessourceContext()
	const steps = getOngletTaches()

	return (
		<Grid container direction="column" justify="space-between" className={classes.content}>
			<Grid container item direction="row">
				<Grid container item direction="column" xs={3} justify="space-between" alignItems="flex-start" style={{padding: 20}}>
					<Typography variant="h2" className={classes.head}>
						<FormattedMessage id="gestionRessource.toolbar.gestionRessource"/>
					</Typography>
				</Grid>
			</Grid>
			<Grid item container justify="center" alignContent="flex-end" direction="row" className={classes.tabBar}>
				<StyledTabs value={onglet}>
					{steps.map((label, index) => (
						<StyledTab key={index} label={label} withoutBorder/>
					))}
				</StyledTabs>
			</Grid>
		</Grid>
	)
}

ToolBar.propTypes = {
	classes: PropTypes.object,
}

export default compose(
	withStyles(styles)
)(ToolBar)
