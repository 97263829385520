import withStyles from '@material-ui/core/styles/withStyles'
import {colors} from '../../../utils/constants'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'


export const StyledConnector = withStyles({
	alternativeLabel: {
		top: 19
	},
	active: {
		'& $line': {
			borderColor: colors.primary
		}
	},
	completed: {
		'& $line': {
			borderColor: colors.primary
		}
	},
	line: {
		borderColor: '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1
	}
})(StepConnector)

export const StyledLabel = withStyles({
	alternativeLabel: (props) => ({
		justifyContent: 'center',
		width: props.large ? 250 : 110,

		'& .MuiTypography-displayBlock': {
			cursor: props.onClick ? 'pointer' : 'default'
		}
	})
})(StepLabel)

const stepIconStyles = makeStyles(theme => ({
	root: (props) => ({
		backgroundColor: '#ccc',
		color: '#fff',
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		width: 40,
		height: 40,
		zIndex: 1,
		cursor: props.clickable ? 'pointer' : 'default'
	}),
	active: (props) => ({
		backgroundColor: theme.palette.primary.main,
		cursor: props.clickable ? 'pointer' : 'default'
	}),
	completed: (props) => ({
		backgroundColor: colors.primary,
		cursor: props.clickable ? 'pointer' : 'default'
	})
}))

export const StepIcon = (props) => {
	const classes = stepIconStyles(props)
	const {icons, active, completed, icon} = props

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed
			})}
		>
			{icons[String(icon)]}
		</div>
	)
}