import {Fab, Grid, Typography, withStyles} from '@material-ui/core'
import React from 'react'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {FormattedMessage, useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {useGestionRessourceContext} from '../../GestionRessourceProvider'
import {replace} from 'connected-react-router'
import {ONGLET_GESTION_DES_RESSOURCES} from '../../../../../utils/constants'
import {connect} from 'react-redux'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import StyledTabs from '../../../../../components/layout/tab/StyledTabs';
import StyledTab from '../../../../../components/layout/tab/StyledTab';

const styles = () => getStyles({
	container: {
		width: 'auto',
		marginBottom: 8,
		height: 48,
		fontFamily: 'Lato',
		minWidth: 1150,
		maxWidth: '100%'
	},
	stepper: {
		textAlign: 'left',
		'& .MuiTab-wrapper': {
			alignItems: 'flex-start'
		}
	},
	title: {
		fontSize: 24
	},
	returnButton: {
		height: 35,
		width: 35,
		minHeight: 35,
		marginRight: 20
	},
	returnIcon: {
		height: 30,
		width: 30,
		transform: 'scaleX(-1)',
	}
})


const getSteps = () => {
	return [
		ONGLET_GESTION_DES_RESSOURCES.INFORMATIONS,
		ONGLET_GESTION_DES_RESSOURCES.CONTRAT,
		ONGLET_GESTION_DES_RESSOURCES.COMPETENCES,
		ONGLET_GESTION_DES_RESSOURCES.ABSENCES,
	]
}

const HeaderDetailRessource = ({ classes, goBack }) => {
	const {setOngletGestion, ongletGestion, ressource} = useGestionRessourceContext()
	const steps = getSteps()
	const intl = useIntl()

	return (
		<Grid container className={classes.container}>
			<Grid container item>
				<Grid item>
					<Fab color="primary" onClick={goBack} className={classes.returnButton}>
						<ArrowRightAltIcon className={classes.returnIcon}/>
					</Fab>
				</Grid>
				<Grid item>
					<Typography variant="h1" className={classes.title}>
						<FormattedMessage id={`gestionRessource.detailRessource.title`} values={{nom: ressource?.nom, prenom: ressource?.prenom }}/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item>
				<Grid item xs={12} lg={2} md={3} style={{borderRight: '0.5px solid lightgray', marginTop: 30}}>
					<StyledTabs className={classes.stepper} value={ongletGestion.order} orientation="vertical">
						{steps.map(onglet => (
							<StyledTab
								key={onglet.code}
								withoutBorder
								onClick={() => setOngletGestion(onglet)}
								label={intl.formatMessage({id: `gestionRessource.detailRessource.onglets.${onglet.code.toLowerCase()}`})}
								orientation="vertical"
							/>
						))}
					</StyledTabs>
				</Grid>
			</Grid>
		</Grid>
	)
}

HeaderDetailRessource.propType = {
	classes: PropTypes.object
}

const actions = {
	goBack: () => dispatch => dispatch(replace('/gestion-ressources')),
}

export default compose(
	connect(null, actions),
	withStyles(styles),
)(HeaderDetailRessource)