import React from 'react'
import PropTypes from 'prop-types'

const DroughtIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<circle cx={256} cy={241} r={105} fill="#fabe2c" />
		<path
			fill="#ff9100"
			d="M361 241c0-57.99-47.01-105-105-105v210c57.99 0 105-47.01 105-105z"
		/>
		<path
			fill="#fabe2c"
			d="M139.384 145.595 75.692 81.903c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l63.691 63.691c5.859 5.859 5.859 15.352 0 21.211-5.858 5.86-15.351 5.86-21.21.001z"
		/>
		<path
			fill="#ff9100"
			d="M351.405 145.595c-5.859-5.859-5.859-15.352 0-21.211l63.691-63.691c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-63.691 63.691c-5.859 5.859-15.351 5.859-21.211 0zM380.731 189.335c-3.164-7.661.469-16.436 8.115-19.6l27.715-11.47c7.632-3.149 16.436.469 19.6 8.115 3.164 7.661-.469 16.436-8.115 19.6l-27.715 11.47c-7.612 3.152-16.419-.426-19.6-8.115zM307.665 116.269c-7.646-3.164-11.279-11.938-8.115-19.6l11.47-27.715c3.179-7.646 11.909-11.279 19.6-8.115 7.646 3.164 11.279 11.938 8.115 19.6l-11.47 27.715c-3.128 7.56-11.827 11.308-19.6 8.115z"
		/>
		<g fill="#fabe2c">
			<path d="m184.735 108.153-11.47-27.715c-3.164-7.661.469-16.436 8.115-19.6 7.632-3.164 16.436.469 19.6 8.115l11.47 27.715c3.164 7.661-.469 16.436-8.115 19.6-7.612 3.152-16.419-.426-19.6-8.115zM111.669 197.45l-27.715-11.47c-7.646-3.164-11.279-11.938-8.115-19.6 3.179-7.646 11.968-11.265 19.6-8.115l27.715 11.47c7.646 3.164 11.279 11.938 8.115 19.6-3.187 7.703-12.003 11.261-19.6 8.115zM106 256H46c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15z" />
		</g>
		<path
			fill="#ff9100"
			d="M466 256h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
		/>
		<path
			fill="#d97f4a"
			d="M512 301c0-8.291-6.709-15-15-15H385.272l-3.648 15-29.897-15H156.806l-29.244 15-2.369-15H15c-8.291 0-15 6.709-15 15v75l15 15-15 15v75c0 8.291 6.709 15 15 15h126.731l29.893-15 3.652 15h191.453l2.708-15 30.839 15H497c8.291 0 15-6.709 15-15V376l-15-14.063L512 346z"
		/>
		<path
			fill="#b35f49"
			d="m369.438 481 30.839 15H497c8.291 0 15-6.709 15-15V376l-15-14.063L512 346v-45c0-8.291-6.709-15-15-15H385.272l-3.648 15-29.897-15H256v210h110.729z"
		/>
		<path
			fill="#b35f49"
			d="m415.272 346-30-60h-33.544l36.02 72.041L339.789 406H198.417l-73.451-24.483L156.806 286h-31.613l-30.004 90H0v30h103.583l71.265 23.76L141.731 496h33.545l29.996-60h131.456l30.001 60h33.547l-36.024-72.041L412.211 376H512v-30z"
		/>
		<path
			fill="#8c4a37"
			d="M366.729 496h33.547l-36.024-72.041L412.211 376H512v-30h-96.728l-30-60h-33.544l36.02 72.041L339.789 406H256v30h80.728z"
		/>
		<path
			fill="#fabe2c"
			d="M256 16c-8.291 0-15 6.709-15 15v60c0 8.291 6.709 15 15 15s15-6.709 15-15V31c0-8.291-6.709-15-15-15z"
		/>
		<path
			fill="#ff9100"
			d="M271 91V31c0-8.291-6.709-15-15-15v90c8.291 0 15-6.709 15-15z"
		/>
	</svg>
)


DroughtIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

DroughtIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default DroughtIcon