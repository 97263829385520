import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isInvalid } from 'redux-form'
import FormButton from '../../../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { ASSOLEMENT_ETAT, ETAPE_ETAT, ETAPE_TYPE, TYPE_COMMENTAIRE_SUIVI } from '../../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import TypeEtape from '../../../../../common/information/TypeEtape'
import FertilizationForm, { formFields } from '../../../component/form/fertilization/FertilizationForm'
import { FERTILIZATION_FORM } from '../../../utils/constant'
import { findFertilizedAssolement } from '../../../utils/utils'
import { getEtapesPlanche } from '../../assolement/assolementAction'
import { loadAssolement } from '../../assolement/assolementInjector'
import { getAssolements } from '../../assolement/assolementSelector'
import { sendFertilization } from '../../etapeApi'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'

/**
 * Injecteur pour l'<ouverture du panel d'ajout/édition de fertilization
 */
export const injectFertilizationForm = WrappedComponent => {
	const FertilizationFormInjector = (props) => {
		const { snackError, snackSuccess, snackWarning } = useSnackbar()
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { campagne, assolements, accessAssolement, getEtapesPlanche } = props

		const openFertilizationForm = (assolement, isUpdate, callbackOnSubmit) => {
			const title = <FormattedMessage id={`planning.cultureForm.fertilization.${isUpdate ? 'update' : 'new'}`}/>
			let fertilizedAssolement

			if (isUpdate) {
				fertilizedAssolement = findFertilizedAssolement(assolement.planche.id, assolements)
			}

			const checkDatesFertilisation = (assolement) => {
				assolement.assolementEtapeList.forEach(etape => {
					const filterForm = {
						types: [ETAPE_TYPE.PREP_PLANCHE, ETAPE_TYPE.SEMI_DIRECT, ETAPE_TYPE.IMPLANTATION],
						dateDebut: etape.dateDebut,
						dateFin: etape.dateFin
					}
					getEtapesPlanche(filterForm, assolement.planche.id)
						.then((result) => {
							if (result.etapesFiltered.length > 0) {
								result.etapesFiltered.forEach((etape) => {
									snackWarning({
										id: 'snackbar.warning.fertilisation',
										values: {
											etape: <TypeEtape typeEtape={etape.type}/>,
											culture: result.assolementsFiltered.find(assolement => assolement.id === etape.idAssolement).culture.nom
										}
									})
								})
							} else {
								snackSuccess({ id: 'snackbar.update.fertilisation' })
							}
							accessAssolement(assolement.id)
						})
				})
			}

			const onSubmit = (values) => {
				let fertilizationAddForm = {
					isRevise: assolement.etat === ASSOLEMENT_ETAT.REVISE,
					planches: values[formFields.planches],
					allPlanches: values[formFields.allPlanches],
					idCampagne: campagne.id,
					idAssolement: fertilizedAssolement && fertilizedAssolement.id
				}
				let fertilizationEditFormList = []

				values[formFields.fertilization].sort().forEach((field, index) => {
					let task = {
						etat: ETAPE_ETAT.A_REALISER,
						id: -1
					}
					const date = field[formFields.date]
					const comment = field[formFields.comment]
					task.dateDebut = moment(date).startOf('isoWeek').format('YYYY-MM-DD')
					task.dateFin = moment(date).endOf('isoWeek').format('YYYY-MM-DD')

					if (comment) {
						task.comment = {
							commentaire: comment,
							typeCommentaire: TYPE_COMMENTAIRE_SUIVI.AUTRE_COMMENTAIRE
						}
					}
					fertilizationEditFormList[index] = task
				})

				fertilizationAddForm.etapes = fertilizationEditFormList
				return sendFertilization(fertilizationAddForm, false).then((assolement) => {
						checkDatesFertilisation(assolement)
						closePanel()
						if (callbackOnSubmit) {
							callbackOnSubmit()
						}
					})
					.catch(() => {
						snackError({ id: 'snackbar.error.fertilisation' })
					})
			}

			const buttons = [
				<FormButton
					type="primary"
					formName={FERTILIZATION_FORM}
					getDisabledFromState={state => isInvalid(FERTILIZATION_FORM)(state)}
				>
					<FormattedMessage id="actions.validate"/>
				</FormButton>
			]

			return openPanel(
				title,
				<FertilizationForm assolement={fertilizedAssolement || assolement} isUpdate={isUpdate} onSubmit={onSubmit}/>,
				buttons
			)
		}

		return <WrappedComponent  {...props} openFertilizationForm={React.useCallback(openFertilizationForm, [assolements])}/>
	}

	const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state),
		assolements: getAssolements(state)
	})

	const actions = {
		getEtapesPlanche
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(FertilizationFormInjector)

}
