import React from 'react'
import { MenuItem, withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import Select from '../../../../components/form/Select'
import { injectTypeSurfaceValueList, injectZoneSurfaceValueList } from '../../../common/valueLists/valueListInjectors'
import Button from '../../../../components/Button'
import { DeleteForever } from '@material-ui/icons'
import { connect } from 'react-redux'

const styles = () => getStyles({
	field: {
		width: '50%'
	},
	row: {
		marginBottom: 25
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	blocButton: {
		display: 'flex',
		justifyContent: 'center'
	},
	button: {
		padding: '0 20px 0 20px'
	}
})

const formattedMessageBase = 'bo.groupeCulture.form'

const fields = {
	NOM: { name: 'nom', size: 50 },
	ZONE: { name: 'zone' },
	DESCRIPTION: { name: 'description', size: 250 },
	SURFACE: { name: 'surface' }
}

const validate = (values) => {
	const errors = {}
	Object.values(fields).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	})
	Object.values(fields).filter(field => field.size).forEach(field => {
		if (values[field.name] && values[field.name].length > field.size) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.tooLong`, values: { sizeMax: field.size } }
		}
	})
	return errors
}

const GroupeCultureForm = ({
	zoneSurfaceList,
	typeSurfaceList,
	classes,
	handleSubmit,
	idGroupe,
	deleteGroupe,
	isCreation
}) => {
	return (<form onSubmit={handleSubmit}>
		<Grid item className={classes.row}>
			<Field
				name={fields.NOM.name}
				component={Input}
				label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.NOM.name}`} />}
				fromBo
				inputProps={{
					maxLength: fields.NOM.size
				}}
			/>
		</Grid>
		<Grid item className={classes.row}>
			<Field
				name={fields.DESCRIPTION.name}
				component={Input}
				label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.DESCRIPTION.name}`} />}
				fromBo
				inputProps={{
					maxLength: fields.DESCRIPTION.size
				}}
			/>
		</Grid>
		<Grid container className={classes.row}>
			<Grid item className={classes.field}>
				<Field
					name={fields.ZONE.name}
					component={Select}
					multiple
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.ZONE.name}`} />}
					fromBo
				>
					{
						zoneSurfaceList && zoneSurfaceList.map(zone => (
							<MenuItem key={zone.code} value={zone.code} classes={{ selected: classes.menuItem }}>
								{zone.label}
							</MenuItem>
						))
					}
				</Field>
			</Grid>
			<Grid item className={classes.field}>
				<Field
					name={fields.SURFACE.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.SURFACE.name}`} />}
					fromBo
					disabled={!isCreation}
				>
					{
						typeSurfaceList && typeSurfaceList.map(surface => (
							<MenuItem key={surface.code} value={surface.code} classes={{ selected: classes.menuItem }}>
								{surface.label}
							</MenuItem>
						))
					}
				</Field>
			</Grid>
		</Grid>
		{
			!isCreation &&
			<Grid item className={classes.blocButton}>
				<Button
					type="secondary"
					onClick={() => deleteGroupe(idGroupe)}
					startIcon={<DeleteForever color="currentColor" />}
					className={classes.button}
				>
					<FormattedMessage id="bo.groupeCulture.deleteButton.groupe" />
				</Button>
			</Grid>
		}
	</form>)
}

GroupeCultureForm.propTypes = {
	zoneSurfaceList: PropTypes.array,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	idGroupe: PropTypes.string,
	deleteGroupe: PropTypes.func,
	isCreation: PropTypes.bool
}

const mapStateToProps = (state, { formName }) => ({
	form: formName
})

export default compose(
	connect(mapStateToProps),
	injectTypeSurfaceValueList,
	injectZoneSurfaceValueList,
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(GroupeCultureForm)
