import React from 'react'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../../GestionRessourceProvider';
import {Grid, withStyles} from '@material-ui/core'
import {AJOUT_RESSOURCE_FORM, colors} from '../../../../../utils/constants';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {getStyles} from 'isotope-client'
import Checkbox from '../../../../../components/form/Checkbox';
import {getAllCompetences} from '../../../planning/services/planningApi';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {connect} from 'react-redux'
import {TYPES_COMPETENCES} from '../../utils/constants';
import SearchBar from '../../../../../components/SearchBar';

const styles = () => getStyles({
	container: {
		fontFamily: 'Lato',
	},
	title: {
		fontSize: 18,
		fontWeight: 600
	},
	subtitle: {
		fontSize: 12,
		fontWeight: 300,
		fontStyle: 'italic'
	},
	icon: {
		height: 24,
		width: 24
	},
	field: {
		width: '33%'
	},
	row: {
		width: '80%'
	},
	fieldRow: {
		marginTop: 5
	},
	header: {
		marginBottom: 20
	},
	textField: {
		backgroundColor: colors.light,
		borderRadius: 20,
		'&.MuiOutlinedInput-root': {
			borderRadius: 20,
		},
	},
	competenceTitle: {
		fontSize: 12,
		fontStyle: 'italic',
		fontWeight: 300,
		marginBottom: 10
	},
	competencePannel: {
		maxHeight: 500,
		overflowY: 'auto',
		paddingRight: 10,
		display: 'flex',
		flexDirection: 'column',
	},
	filterTitle: {
		paddingRight: 40
	},
	filterContainer: {
		paddingTop: 5,
		paddingBottom: 15
	},
	competenceRow: {
		marginLeft: 20,
		marginBottom: 20,
		"& .MuiCheckbox-colorSecondary.Mui-checked": {
			color: colors.valid
		}
	},
	checkBoxFilter: {
		marginLeft: 15,
		"& .MuiTypography-root": {
			marginLeft: -5,
		},
	},
	competenceContainer: {
		display: 'flex',
		width: '100%',
	},
	competenceSection: {
		flex: 1,
		marginRight: 20,
	},
})

const HeaderText = ({Icon, message, classes}) => {
	return (
		<Grid container direction="row" alignItems="center" className={classes.header}>
			<Grid item style={{marginRight: 15}}>
				<Icon color="primary" className={classes.icon}/>
			</Grid>
			<Grid item className={classes.title}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.${message}`}/>
			</Grid>
		</Grid>
	);
};

const renderCompetence = ({ competences, classes }) => (
	<div className={classes.competenceContainer}>
		<div className={classes.competenceSection}>
			<div className={classes.competenceTitle}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.competences.participant`} />
			</div>
			<div className={classes.competencePannel}>
				{competences
					.filter((competence) => competence.typeCompetence === TYPES_COMPETENCES.PARTICIPANT)
					.map((competence) => (
						<FormControlLabel
							key={competence.id}
							control={<Field name={`competences[${competence.id}]`} component={Checkbox} />}
							className={classes.competenceRow}
							label={competence.nom}
						/>
					))}
			</div>
		</div>
		<div className={classes.competenceSection}>
			<div className={classes.competenceTitle}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.competences.machiniste`} />
			</div>
			<div className={classes.competencePannel}>
				{competences
					.filter((competence) => competence.typeCompetence === TYPES_COMPETENCES.MACHINISTE)
					.map((competence) => (
						<FormControlLabel
							key={competence.id}
							control={<Field name={`competences[${competence.id}]`} component={Checkbox} />}
							className={classes.competenceRow}
							label={competence.nom}
						/>
					))}
			</div>
		</div>
	</div>
);

const Competences = ({
						   classes,
						   handleSubmit,
						   competencesList,
						   initialValues,
					   }) => {
	const {ongletGestion} = useGestionRessourceContext()
	const [textValue, setTextValue] = React.useState();
	const [alreadyChecked, setAlreadyChecked] = React.useState(true);
	const [notChecked, setNotChecked] = React.useState(true);
	const [competences, setCompetences] = React.useState([]);

	// Permet de recuperer les compétences
	const getCompetences = () => {
		getAllCompetences({nameQuery: textValue})
			.then((competences) => {
				if (competencesList) {
					let filteredCompetences = competences;
					if (alreadyChecked && !notChecked) {
						filteredCompetences = competences.filter(competence =>
							Object.keys(competencesList)
								.filter(key => competencesList[key])
								.some(id => id === competence.id)
						);
					}
					if (notChecked && !alreadyChecked) {
						filteredCompetences = competences.filter(competence =>
							!Object.keys(competencesList)
								.filter(key => competencesList[key])
								.some(id => id === competence.id)
						);
					}
					setCompetences(filteredCompetences);
				} else {
					setCompetences(competences);
				}
			});
	};

	React.useEffect(() => {
		getCompetences()
	}, [alreadyChecked, notChecked, textValue])

	return (
		<form onSubmit={handleSubmit}>
			<Grid item container justify={"center"} style={{marginLeft: initialValues ? 150 : 20, paddingRight: initialValues ? '5%' : 0}} className={classes.row}>
				<HeaderText Icon={EmojiEventsIcon} message={ongletGestion.code.toLowerCase()} classes={classes}/>
				<SearchBar setTextInput={setTextValue} placeHolder={'gestionRessource.ajoutRessource.form.competences.recherche'} />
				<Grid container direction="row" alignItems="center" className={classes.filterContainer}>
					<Grid item className={classes.filterTitle}>
						<FormattedMessage id="gestionRessource.ajoutRessource.form.competences.filtreTitle"/>
					</Grid>
					<Grid item className={classes.checkBoxFilter}>
						<FormControlLabel
							control={
								<Field
									name="alreadyChecked"
									component={Checkbox}
									checked={alreadyChecked}
									onChange={() => setAlreadyChecked(!alreadyChecked)}
								/>
							}
							label={<FormattedMessage id="gestionRessource.ajoutRessource.form.competences.filtreAcquise"/>}
						/>
					</Grid>
					<Grid item className={classes.checkBoxFilter}>
						<FormControlLabel
							control={
								<Field
									name="notChecked"
									component={Checkbox}
									checked={notChecked}
									onChange={() => setNotChecked(!notChecked)}
								/>
							}
							label={<FormattedMessage id="gestionRessource.ajoutRessource.form.competences.filtreNonAcquise"/>}
						/>
					</Grid>
				</Grid>
				<FieldArray
					name="competences"
					component={renderCompetence}
					competences={competences}
					classes={classes}
				/>
			</Grid>
		</form>
	)
}


Competences.propType = {
	classes: PropTypes.object,
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	competencesList: PropTypes.object,
}

const mapStateToProps = (state) => {
	const formSelector = formValueSelector(AJOUT_RESSOURCE_FORM);
	return {
		form: AJOUT_RESSOURCE_FORM,
		competencesList: formSelector(state, 'competences'),
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		enableReinitialize: true,
		destroyOnUnmount: false,
	}),
	withStyles(styles),
)(Competences)
