export const TOUR_PLAINE_TABS = {
	CONTEXTE_PEDOCLIMATIQUE: { code: 'contextePedoclimatique', order: 0 },
	SUIVI_PEPINIERE: { code: 'suiviPepiniere', order: 1 },
	SUIVI_BLOC: { code: 'suiviBlock', order: 2 },
	SUIVI_MATERIELS: { code: 'suiviMaterials', order: 3 }
}

export const MAX_WEEKS = 4

export const TOUR_PLAINE_FORM = 'TOUR_PLAINE_FORM'
export const TOUR_PLAINE_VALIDATION_FORM = 'TOUR_PLAINE_VALIDATION_FORM'
export const TOUR_PLAINE_LAUNCH_SOLVER_VALUE = 'TOUR_PLAINE_LAUNCH_SOLVER_VALUE'
export const WEEK_SELECT_FIELD = 'weekSelectField'

export const ZONE_TYPE = {
	INSIDE: 'serre',
	OUTSIDE: 'exterieur',
	BUTTE: 'butte',
	FRUIT: 'fruit'
}

// Common dataTable Header
export const TASK_DATATABLE_KEYS = {
	FAMILLE: 'family', // Famille
	TASK: 'task', // Tâche
	CROP: 'crop', // Culture
	SEEDLING_DATE: 'seedlingDate', // Dates de semis
	TARGET_BOARD: 'targetBoard', // Planche destination
	PLANNED_DATE: 'plannedDate', // Tâche prévue le
	ARBITRAGE: 'arbitrage', // Arbitrage
	INITIAL_DATE: 'initialDate', // Date initiale
	BOARD: 'board', // Surface
	MATERIAL: 'material', // Matériel(s)
}

export const CROP_DATATABLE_KEYS = {
	BOARD: 'board',
	CROP: 'crop', // Culture en cours
	CURRENT_HARVEST: 'currentHarvest', // Culture en cours (sur la planche)
	END_HARVEST: 'endHarvestDate', // Fin de récolte prévue
	PLANNED_BOARD: 'board', // Planche prévue
	PLANNED_PREP_DATE: 'plannedPrepDate', // Préparation prévue
	PLANNED_IMPLANT_DATE: 'plannedImplantDate', // Implantation prévue
	NEXT: 'nextCrop',
	SEEDLING_DATE: 'seedlingDate', // Dates de semis
	START_HARVEST: 'startHarvestDate',
	STEP: 'step', // Etape
}


// Right Panels action (except BIO_AGGRESSORS)
export const ACTIONS_KEY = {
	DETAIL_CROP: 'detail',
	DETAIL_CROP_CURRENT_OR_NEXT: 'detailCurrentOrNext',
	ADD_COMMENT: 'addComment',
	ADD_COMMENT_CURRENT_OR_NEXT: 'addCommentNext',
	ADD_INSTRUCTION: 'addInstruction',
	ADD_PROD_TRACKING: 'addToProd',
	ADJUST_DURATION: 'adjustDuration',
	CONFIRM_HARVEST: 'confirmHarvest',
	DELETE_CROP: 'deleteCrop',
	DELETE_CROP_CURRENT_OR_NEXT: 'deleteCropCurrentOrNext',
	DELETE_TASK: 'deleteTask',
	MOVE_BOARD_STEP: 'moveToBoard',
	MOVE_TO_HARVEST_STEP: 'moveToHarvestStep',
	MOVE_TO_IMPLANT_STEP: 'moveToNextImplantStep',
	MOVE_SEEDLING_STEP: 'moveToSeedling',
	MOVE_TASK: 'moveTask',
	SPECIFY_GENERIC_TASK: 'specifyGenericTask'
}
// FORMS
// Form in right panels
export const PANEL_FORM = {
	REMINDER: 'reminderForm',
	MOVE_TASK: 'moveForm',
	ADD_INSTRUCTION: 'addInstruction',
	CONFIRM_HARVEST: 'confirmHarvest',
	UPDATE_TASK: 'updateTask',
	SPECIFY_GENERIC_TASK: 'specifyGenericTask'
}

// FIELDS
export const REMINDER_FORM_FIELD = {
	RECIPENT: 'recipient',
	REMINDER: 'reminder'
}
export const RECIPENT_VALUE_FIELD = {
	CHIEF: 'chief',
	MANAGER: 'manager',
}
export const MOVE_TASK_FIELD = {
	DATE: 'date',
	NB_WEEK: 'nbWeek',
	DATE_CHOICE: 'dateChoice',
	STEP: 'step',
	IDS_LIST: 'idList'
}
export const ADD_INSTRUCTION_FIELD = {
	INSTRUCTION: 'instruction',
	IDS_: 'idList'
}

export const CONFIRM_HARVEST_FIELD = {
	CHECKED: 'checked',
	TASKS: 'idsTasks'
}
export const UPDATE_TASK_FIELD = {
	PLANNED_DATE: 'date',
	LOCK_DATE: 'lockDate',
	PRIORITY: 'priorite',
	INSTRUCTIONS: 'instructions',
	RESSOURCES: 'ressources',
	LOCK_RESSOURCES: 'lockRessources',
}

export const EMPTY_PLACEHOLDER = '-'