import { fetchFactory, urlUtils } from 'isotope-client'

/**
 * Récupération de la liste des familles et especes liés
 */
export const getEspeceList = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/famille-espece/espece', filters))
export const getEspeceListByFamille = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/famille-espece/espece-famille', filters))

export const getFamilleList = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/famille-espece/famille', filters))


/**
 * Mise à jour d'une espece
 */
export const updateEspece = (idFerme, values) => fetchFactory('/bo/famille-espece/espece', {
	method: 'PUT',
	body: JSON.stringify({
		idFerme,
		...values
	})
})

/**
 * Création d'une espece
 */
export const createEspece = (idFerme, values) => fetchFactory('/bo/famille-espece/espece', {
	method: 'POST',
	body: JSON.stringify({
		idFerme,
		...values
	})
})

/**
 * Mise à jour d'une famille
 */
export const updateFamille = (idFerme, values) => fetchFactory('/bo/famille-espece/famille', {
	method: 'PUT',
	body: JSON.stringify({
		idFerme,
		...values
	})
})

/**
 * Création d'une famille
 */
export const createFamille = (idFerme, values) => fetchFactory('/bo/famille-espece/famille', {
	method: 'POST',
	body: JSON.stringify({
		idFerme,
		...values
	})
})