import React from 'react'
import PropTypes from 'prop-types'
import LoginForm from './component/LoginForm'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import LoaderAdvanced from 'react-loader-advanced'
import CircularProgress from '@material-ui/core/CircularProgress'
import BandeauRGPD from '../cookies/BandeauRGPD'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {isBrowser} from 'react-device-detect'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {loadConfiguration} from '../../common/configuration/configurationInjector'
import {compose} from 'redux'

const styles = () => getStyles({
	container: {
		width: 400,
		margin: 'auto'
	},
	paperBrowser: {
		background: 'rgba(255,255,255)',
		textAlign: 'center',
		width: 400,
		borderRadius: 10,
		position: 'absolute',
		top: '50vh',
		transform: 'translateY(-50%)'
	},
	paperMobile: {
		background: 'rgba(255,255,255)',
		textAlign: 'center',
		borderRadius: 10,
		position: 'absolute',
		top: '50vh',
		left: '50vw',
		transform: 'translateY(-50%) translateX(-50%)',
		margin: 'auto',
		padding: '5px 25px'
	},
	content: {
		padding: 15
	},
	img: {
		marginBottom: 10
	}
})

const toggleLoginClass = (force) => {
	if (isBrowser) {
		document.body.classList.toggle('login-content', force)
	} else {
		document.body.classList.toggle('login-content-mobile', force)
	}
	document.body.parentNode.classList.toggle('login-content', force)
}

const LoginPage = ({location, classes, configuration}) => {
	React.useEffect(() => {
		toggleLoginClass(true)
		return () => toggleLoginClass(false)
	}, [])

	const [loading, setLoading] = React.useState(false)

	return (
		<>
			<DialogProvider>
				<div className={classes.container}>
					<Paper className={isBrowser ? classes.paperBrowser : classes.paperMobile}>
						<LoaderAdvanced
							show={loading}
							message={<CircularProgress/>}
							backgroundStyle={{backgroundColor: 'rgba(255,255,255,0.5)'}}
						>
							<div className={classes.content}>
								<div className={classes.img}>
									<img src="/img/permasoft.png" alt="logo" height={100} width="auto"/>
								</div>
								{configuration &&
									<GoogleOAuthProvider clientId={configuration.GOOGLE_CLIENT_ID}>
										<LoginForm
											nextPathname={location && location.state ? location.state.nextPathname : undefined}
											setLoading={setLoading}
										/>
									</GoogleOAuthProvider>
								}
							</div>
						</LoaderAdvanced>
					</Paper>
				</div>
				<BandeauRGPD isBrowser={isBrowser}/>
			</DialogProvider>
		</>
	)
}

LoginPage.propTypes = {
	location: PropTypes.object,
	classes: PropTypes.object
}

export default compose(
	loadConfiguration,
	withStyles(styles)
)(LoginPage)
