import React from 'react'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../../GestionRessourceProvider';
import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {AJOUT_RESSOURCE_FORM, colors, TYPE_RESSOURCE} from '../../../../../utils/constants';
import GardenerIcon from '../../../../../components/icon/GardenerIcon';
import {change, Field, formValueSelector, reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {FormSwitch, getStyles} from 'isotope-client'
import Input from '../../../../../components/form/Input';
import {injectTypeContratValueList} from '../../../../common/valueLists/valueListInjectors';
import Select from '../../../../../components/form/Select';
import DateInput from '../../../../../components/form/DateInput';
import {normalizeDate} from '../../../../../utils/utils';
import {connect} from 'react-redux'
import {getAllUsersByFermeId, isRessourceHaveTache} from '../../gestionRessourceApi';
import {DialogContext} from '../../../../../components/layout/dialog';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import PopinTache from '../../popin/PopinTache';
import {getPreferences} from '../../../../common/user/services/userSelectors';

const styles = () => getStyles({
	container: {
		fontFamily: 'Lato',
	},
	title: {
		fontSize: 18,
		fontWeight: 600
	},
	subtitle: {
		fontSize: 12,
		fontWeight: 300,
		fontStyle: 'italic'
	},
	icon: {
		height: 24,
		width: 24
	},
	field: {
		width: '33%'
	},
	row: {
		width: '80%'
	},
	fieldRow: {
		marginTop: 5
	},
	header: {
		marginBottom: 20
	},
	inputs: {
		marginLeft: 20
	},
	addIcon: {
		marginTop: 20,
		marginBottom: 20,
		"& .MuiButton-outlinedPrimary": {
			border: 'none'
		}
	},
	fieldRowActive: {
		marginLeft: 15
	},
	fieldRowAccount: {
		marginLeft: 5,
		marginTop: -15
	},
	errorUsers: {
		color: colors.error,
		fontWeight: 800,
		paddingBottom: 20
	}
})

const HeaderText = ({Icon, message, classes}) => {
	return (
		<Grid container direction="row" alignItems="center" className={classes.header}>
			<Grid item style={{marginRight: 15}}>
				<Icon color="primary" className={classes.icon}/>
			</Grid>
			<Grid item className={classes.title}>
				<FormattedMessage id={`gestionRessource.ajoutRessource.form.titles.${message}`}/>
			</Grid>
		</Grid>
	);
};

const FIELDS = {
	NOM: {name: 'nom'},
	PRENOM: {name: 'prenom'},
	ACTIVE: {name: 'isActive', optional: true},
	TYPE_RESSOURCE: {name: 'typeRessource'},
	TYPE_CONTRAT: {name: 'typeContrat'},
	DEBUT_CONTRAT: {name: 'debutContrat'},
	FIN_CONTRAT: {name: 'finContrat', optional: true},
	IS_HOUR_DEPEND: {name: 'isHourDepend', optional: true},
	USER: {name: 'user'}
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = {id: 'global.errors.mandatory'}
		}
		if (values.typeContrat !== "CDI" && !values[FIELDS.FIN_CONTRAT.name]) {
			errors[FIELDS.FIN_CONTRAT.name] = {id: 'global.errors.mandatory'}
		}
	})

	return errors
}


const Informations = ({
						  classes,
						  typeContratValueList,
						  handleSubmit,
						  initialValues,
						  change,
						  idFerme,
						  typeContrat
					  }) => {
	const {ongletGestion} = useGestionRessourceContext()
	const {openDialog, closeDialog} = React.useContext(DialogContext)
	const [users, setUsers] = React.useState(undefined);
	const typesContrat = [...typeContratValueList]
	const isEmpty = !!users && users.length < 1 && !initialValues

	// Permet de verifier si la ressource à des taches actives ou non
	const isTacheHaveRessource = () => {
		isRessourceHaveTache(initialValues.id).then(taches => {
			if (taches.length > 0) {
				change(FIELDS.ACTIVE.name, 0)
				openDialog(
					<Grid item container>
						<ErrorOutlineIcon style={{color: colors.primary, marginRight: 10}}/>
						<FormattedMessage id={"gestionRessource.detailRessource.inactive.title"}/>
					</Grid>,
					<PopinTache taches={taches}/>,
					[],
				);
			}
		})
	}

	React.useEffect(() => {
		if (!initialValues || initialValues?.id) {
			getAllUsersByFermeId(idFerme, initialValues?.id).then(users => {
				setUsers(users.content)
			})
		}
	}, [initialValues])

	return (
		<form onSubmit={handleSubmit}>
			<Grid item container justify={"center"} style={{marginLeft: initialValues ? 150 : 20, paddingRight: initialValues ? '5%' : 0}} className={classes.row}>
				<HeaderText Icon={GardenerIcon} message={ongletGestion.code.toLowerCase()} classes={classes}/>
				{ !!users && !initialValues && users?.length < 1 && (
					<Grid item container className={classes.errorUsers}>
						<FormattedMessage id={`gestionRessource.ajoutRessource.form.emptyUsers`}/>
					</Grid>
				)}
				<Grid item container justify={"center"} className={classes.fieldRow}>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.PRENOM.name}
							component={Input}
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.PRENOM.name}`}/>}
							disabled={isEmpty}
							fromBo
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.NOM.name}
							component={Input}
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.NOM.name}`}/>}
							disabled={isEmpty}
							fromBo
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.TYPE_RESSOURCE.name}
							component={Select}
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.TYPE_RESSOURCE.name}`}/>}
							disabled={isEmpty}
							fromBo
						>
							{Object.keys(TYPE_RESSOURCE).map(key => (
								<MenuItem key={TYPE_RESSOURCE[key].id} value={TYPE_RESSOURCE[key].id}>
									{<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.ressources.${key.toLowerCase()}`}/>}
								</MenuItem>
							))}
						</Field>
					</Grid>
				</Grid>
				<Grid item container justify={"center"} className={classes.fieldRow} style={{ color: colors.emptyColor }}>
					<Grid item className={classes.field} style={{ color: colors.error }}>
						<Field
							name={FIELDS.TYPE_CONTRAT.name}
							component={Select}
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.TYPE_CONTRAT.name}`}/>}
							disabled={isEmpty}
							fromBo
						>
							{
								typesContrat.map(contrat =>
									<MenuItem key={contrat.label} value={contrat.label}>
										{contrat.label}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_CONTRAT.name}
							component={DateInput}
							format={normalizeDate}
							type="date"
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.DEBUT_CONTRAT.name}`}/>}
							disabled={isEmpty}
							fromBo
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.FIN_CONTRAT.name}
							component={DateInput}
							format={normalizeDate}
							type="date"
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.FIN_CONTRAT.name}` + (typeContrat !== 'CDI' ? 'WithDot' : '')}/>}
							disabled={isEmpty}
							fromBo
						/>
					</Grid>
				</Grid>
				<Grid item container justify={"flex-start"} className={classes.fieldRowAccount}>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.USER.name}
							component={Select}
							label={<FormattedMessage id={`gestionRessource.ajoutRessource.form.fields.${FIELDS.USER.name}`}/>}
							disabled={isEmpty}
							fromBo
						>
							{
								!!users && users.map(user =>
									<MenuItem key={user.user.id} value={user.user.id}>
										{user.user.email}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
				</Grid>
				<Grid item container justify={"flex-start"} className={classes.fieldRowActive}>
					{initialValues && (
						<Grid item className={classes.field}>
							<Field
								label={<FormattedMessage id={`gestionRessource.detailRessource.fields.${FIELDS.ACTIVE.name}`}/>}
								containerClassName={classes.inputs}
								name={FIELDS.ACTIVE.name}
								component={FormSwitch}
								onChange={isTacheHaveRessource}
								disabled={isEmpty}
								fromBo
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</form>
	)
}


Informations.propType = {
	classes: PropTypes.object,
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	idFerme: PropTypes.string,
	change: PropTypes.func,
	typeContrat: PropTypes.object
}

const mapStateToProps = (state) => {
	const formSelector = formValueSelector(AJOUT_RESSOURCE_FORM);
	return {
		form: AJOUT_RESSOURCE_FORM,
		idFerme: getPreferences(state).FERME_ID,
		typeContrat: formSelector(state, 'typeContrat'),
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true,
		destroyOnUnmount: false,
	}),
	withStyles(styles),
	injectTypeContratValueList
)(Informations)
