import React from 'react'

/**
 * @callback openPanelType
 * @param string le titre
 * @param React.Component
 * @param Array<React.Component>
 * @return void
 */
/**
 * @callback closePanelType
 * @return void
 */
/**
 * Définition des types des contextes relatifs aux panneaux latéraux
 * @type {{openPanel: openPanelType, title: string, actions: Array<React.Component>, content: React.Component, closePanel: closePanelType}}
 */
const contextType = {
	/**
	 * Titre du panneau
	 * @type string
	 */
	title: '',
	/**
	 * Contenu du panneau
	 * @type React.Component
	 */
	content: <React.Fragment />,
	/**
	 * Liste des actions du panneau
	 * @type Array<React.Component>
	 */
	actions: [],
	/**
	 * Méthode permettant de mettre à jour le panneau
	 * @type openPanelType
	 */
	updatePanel: (title, content, actions, persistOnCollapse) => {},
	/**
	 * Méthode permettant d'ouvrir le panneau
	 * @type openPanelType
	 */
	openPanel: () => {},
	/**
	 * Méthode permettant de fermer le panneau
	 * @type closePanelType
	 */
	closePanel: () => {},
	/**
	 * Méthode permettant de désactiver le clickAway du Panel (par exemple quand on ouvre une popin par-dessus)
	 */
	disableClickAway: (disableClickAway) => {}
}

/**
 * Contexte relatif au panneau affichant des détails
 * @type {React.Context<{openPanel: openPanelType, title: string, actions: Array<React.Component>, content: React.Component, closePanel: closePanelType}>}
 */
export const DetailsPanelContext = React.createContext(contextType)
/**
 * Contexte relatif au panneau proposant une action
 * @type {React.Context<{openPanel: openPanelType, title: string, actions: Array<React.Component>, content: React.Component, closePanel: closePanelType}>}
 */
export const ActionPanelContext = React.createContext(contextType)
/**
 * Contexte relatif au panneau de filtre
 * @type {React.Context<{openPanel: openPanelType, title: string, actions: Array<React.Component>, content: React.Component, closePanel: closePanelType}>}
 */
export const FilterPanelContext = React.createContext(contextType)