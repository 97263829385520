import { IconButton, Typography, withStyles } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getUser } from '../modules/common/user/services/userSelectors'
import { colors, TYPE_COMMENTAIRE_SUIVI } from '../utils/constants'
import FixedLineText from './FixedLineText'
import { isCompare } from '../modules/fo/planning/services/assolement/assolementSelector'
import TypeComment from '../modules/common/information/TypeComment'

const styles = () => getStyles({
	comment: {
		marginBottom: 15
	},
	headContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: colors.commentHeader,
		fontWeight: 'bold',
		'&:hover $commentActions, & .Mui-focused $commentActions': {
			display: 'unset'
		},
		height: '18px'
	},
	iconButton: {
		padding: 3,
		'& svg': {
			height: '0.875em',
			width: 'auto'
		}
	},
	commentActions: {
		display: 'none'
	},
	userSection: {
		display: 'flex',
		alignItems: 'center',
		'& p': {
			textTransform: 'capitalize'
		}
	},
	variation: {
		marginBottom: '0.5em'
	}
})

const CommentSuivi = ({
	comment,
	loggedUser,
	classes,
	editComment,
	deleteComment,
	maxLines,
	setTooLong,
	open,
	noEdit,
	isCompare
}) => {
	if (!comment) {
		return <></>
	}
	const user = comment?.userCreation
	const canEditOrDelete = user?.login === loggedUser?.username && !noEdit
	const variationSign = (comment?.variationRendement < 0) ? '-' : '+'
	const rendement = `${variationSign} ${Math.abs(comment.variationRendement)}`
	return (
		<div className={classes.comment}>
			<Typography component="div" variant="body1" className={classes.headContainer}>
				<div><FormattedDate value={comment?.dateCreation} />
					{[TYPE_COMMENTAIRE_SUIVI.SUIVI_TACHE, TYPE_COMMENTAIRE_SUIVI.FIN_TACHE, TYPE_COMMENTAIRE_SUIVI.DEMARRAGE_IMPOSSIBLE].includes(comment?.typeCommentaire) &&
					<React.Fragment><span> : </span><FormattedMessage id={`planning.suivi.detail.${comment?.typeCommentaire}`} /></React.Fragment>
					}
				</div>

				<div className={classes.userSection}>
					<p>{user?.firstname} {user?.lastname}</p>
					{canEditOrDelete && !isCompare && <div className={classes.commentActions}>
						<IconButton className={classes.iconButton} onClick={() => deleteComment(comment)}><Delete /></IconButton>
						<IconButton className={classes.iconButton} onClick={() => editComment(comment)}><Edit /></IconButton>
					</div>}
				</div>
			</Typography>

			{comment?.typeCommentaire === TYPE_COMMENTAIRE_SUIVI.IMPACT_PRODUCTION &&
			<div className={classes.variation}>
				<TypeComment typeComment={comment?.typeCommentaire} /> : <b>{rendement}%</b>
			</div>
			}
			<FixedLineText maxLines={maxLines} setTooLong={setTooLong} text={comment?.commentaire} open={open} />
		</div>
	)
}

CommentSuivi.propType = {
	comments: PropTypes.shape({
		typeCommentaire: PropTypes.string,
		dateCreation: PropTypes.instanceOf(Date).isRequired,
		commentaire: PropTypes.string.isRequired,
		userCreation: PropTypes.object.isRequired,
		variationRendement: PropTypes.string
	}),
	loggedUser: PropTypes.object.isRequired,
	noEdit: PropTypes.bool,
	editComment: PropTypes.func,
	setTooLong: PropTypes.func,
	maxLines: PropTypes.integer,
	open: PropTypes.bool,
	isCompare: PropTypes.bool
}

const mapStateToProps = state => ({
	loggedUser: getUser(state),
	isCompare: isCompare(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(CommentSuivi)
