import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {compose} from 'redux'
import {Grid, Typography, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import Title from '../../layout/Title'
import {BLOCK_ACTION_TYPE, SVB_TASKS_CONTENT_FORM_FIELDS, SVB_TASKS_CONTENT_FORM_NAME} from '../tool/suiviParBloc.constants'
import {injectCreateTask} from '../../../../gestionTache/injectors/createTaskInjector'
import {injectReminderForm} from '../../../service/reminderFormInjector'
import {injectActionsForm} from '../../../service/actionsFormInjector'
import {contentStyles} from '../tool/suiviParBloc.styles'
import Checkbox from '../../../../../../components/form/Checkbox'
import Button from '../../../../../../components/Button'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import DataTableWithLoader from '../../../../../../components/layout/DataTableWithLoader'
import NoResultPage from '../../../../../../components/NoResultPage'
import {DATATABLE_SORT_ORDER} from '../../../../../../utils/constants'
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {useTourPlaineContext} from '../../../TourPlaineContext'
import {FormattedMessage, useIntl} from 'react-intl'
import AddCommentIccon from '@material-ui/icons/AddComment'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {ACTIONS_KEY, TASK_DATATABLE_KEYS, TOUR_PLAINE_TABS} from '../../../tool/tourPlaine.constants'
import moment from 'moment'
import {DateFormat} from '../../../../../../utils/dateConstants'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import {getTasks} from '../service/suiviParBlocApi'
import {injectFamilleTacheValueList, injectTypeTacheValueList} from '../../../../../common/valueLists/valueListInjectors'
import {capitalize, localCompare} from '../../../../../../utils/utils'
import IconButton from '../../../../../../components/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag'
import {taskToApp} from '../../../tool/tourPlaine.mapper'
import {openTacheDetail} from '../../../../gestionTache/injectors/openTacheDetailsInjector'
import {injectTaskUpdateForm} from '../../../service/taskUpdateFormInjector'

/**
 * TasksContent for SuiviParBloc
 * @returns {JSX.Element}
 */
const TasksContent = ({
	classes,
	block,
	openReminderForm,
	getActions,
	openCreateTask,
	taskHarvest,
	taskWaiting,
	typeTacheList,
	familleTacheList,
	openTaskUpdateForm
}) => {
	const { formatMessage } = useIntl()
	const { currentWeek } = useTourPlaineContext()
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [sort, setSort] = useState({ key: TASK_DATATABLE_KEYS.CATEGORY, order: DATATABLE_SORT_ORDER.ASC })
	const [selectedRows, setSelectedRows] = useState([])

	// Call Api
	const loadData = useCallback((shouldUnselect) => {
		if (shouldUnselect) {
			setSelectedRows([])
		}

		setIsLoading(true)
		getTasks(block.uniqueId, currentWeek, taskHarvest, taskWaiting)
			.then(response => setData(taskToApp(response)))
			.finally(() => setIsLoading(false))
	}, [block, currentWeek, taskHarvest, taskWaiting])

	const headers = useMemo(() => [
		{
			key: TASK_DATATABLE_KEYS.FAMILLE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.FAMILLE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 100
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.FAMILLE ? sort.order : undefined,
			checked: true,
			render: row => {
				const family = familleTacheList.find(famille => famille.code === row.family)
				return (<Typography className={classes.dataTableBoldText}>
					{family.label}
				</Typography>)
			}
		},
		{
			key: TASK_DATATABLE_KEYS.TASK,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.TASK}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 145
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.TASK ? sort.order : undefined,
			render: row => {
				const task = typeTacheList.find(typeTache => typeTache.code === row.task)
				return (<Typography className={classes.dataTableBoldText}>
					{task.label}
				</Typography>)
			}
		},
		{
			key: TASK_DATATABLE_KEYS.CROP,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.CROP}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 145
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.CROP ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{row.crop.name}
				</Typography>
			)
		},
		{
			key: TASK_DATATABLE_KEYS.BOARD,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.BOARD}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 80
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.BOARD ? sort.order : undefined,
			render: row => (
				<Typography className={classes.dataTableNormalText}>
					{row.board.name}
				</Typography>
			)
		},
		{
			key: TASK_DATATABLE_KEYS.PLANNED_DATE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.PLANNED_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 100
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.PLANNED_DATE ? sort.order : undefined,
			render: row => (
				<div className={classes.cell}>
					<Typography className={classes.dataTableNormalText}>
						{capitalize(moment(row.plannedDate, DateFormat.YYYY_MM_DD).locale('fr').format(DateFormat.dddd_DD_MM_SLASH))}
					</Typography>
				</div>
			)
		},
		{
			key: TASK_DATATABLE_KEYS.MATERIAL,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.MATERIAL}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 145
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.MATERIAL ? sort.order : undefined,
			render: row => {
				const materials = row.material.join(', ')
				return (
					<div className={classes.dataTableCell}>
						<Typography className={classes.dataTableNormalText}>
							{materials || '-'}
						</Typography>
					</div>
				)
			}
		},
		{
			key: TASK_DATATABLE_KEYS.ARBITRAGE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.ARBITRAGE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 145
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.ARBITRAGE ? sort.order : undefined,
			render: row => {
				return (
					<div>
						{row.taskHarvest && (
							<div className={classes.cell}>
								<CheckCircleOutlineIcon />
								<Typography className={classes.dataTableNormalText}>
									<FormattedMessage
										id="tourPlaine.suiviParBloc.tasks.table.cells.taskHarvest"
									/>
								</Typography>
							</div>
						)}
						{row.taskGeneric && (
							<div className={classes.cell}>
								<OutlinedFlagIcon />
								<Typography className={classes.dataTableNormalText}>
									<FormattedMessage
										id="tourPlaine.suiviParBloc.tasks.table.cells.taskGeneric"
									/>
								</Typography>
							</div>
						)}
						{row.taskWaiting && (
							<div className={classes.cell}>
								<WarningRoundedIcon />
								<Typography className={classes.dataTableNormalText}>
									<FormattedMessage
										id="tourPlaine.suiviParBloc.tasks.table.cells.taskWaiting"
									/>
								</Typography>
							</div>
						)}
						{(!row.taskWaiting && !row.taskHarvest && !row.taskGeneric) && (
							<div className={classes.cell}>
								<Typography className={classes.dataTableNormalText}>
									-
								</Typography>
							</div>
						)}
					</div>
				)
			}
		},
		{
			key: TASK_DATATABLE_KEYS.INITIAL_DATE,
			name: <Typography className={classes.dataTableHeader}><FormattedMessage id={`tourPlaine.suiviParBloc.tasks.table.headers.${TASK_DATATABLE_KEYS.INITIAL_DATE}`} /></Typography>,
			tableHeaderColumnProps: {
				width: 100
			},
			sortable: true,
			sorted: sort.key === TASK_DATATABLE_KEYS.INITIAL_DATE ? sort.order : undefined,
			render: row => {
				const plannedDate = moment(row.plannedDate, DateFormat.YYYY_MM_DD)
				const initialDate = moment(row.initialDate, DateFormat.YYYY_MM_DD)
				const isFutur = initialDate.isSameOrAfter(plannedDate)
				const nbWeeksLate = plannedDate.diff(initialDate, 'week')
				return (
					<Typography className={isFutur ? classes.dataTableFuturText : classes.dataTableNormalText}>
						{capitalize(moment(row.initialDate, DateFormat.YYYY_MM_DD).locale('fr').format(DateFormat.dddd_DD_MM_SLASH))}
						{!isFutur && <><br /><FormattedMessage id="tourPlaine.suiviParBloc.tasks.table.cells.lateWeek" values={{ nbWeek: nbWeeksLate }} /></>}
					</Typography>
				)
			}
		},
		{
			key: 'action',
			tableHeaderColumnProps: {
				align: 'center',
				width: 24
			},
			render: row => (
				<IconButton onClick={(event) => {
					openTaskUpdateForm(row, loadData)
					return event.stopPropagation()
				}}>
					<EditIcon color="primary" />
				</IconButton>
			)
		}], [data, sort, loadData])

	const getDisplayData = useMemo(() => {
		const desc = sort.order === DATATABLE_SORT_ORDER.DESC
		if (sort.key === TASK_DATATABLE_KEYS.CROP) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.crop.name.localeCompare(element2.crop.name))
		} else if (sort.key === TASK_DATATABLE_KEYS.BOARD) {
			return data.sort((element1, element2) => (desc ? -1 : 1) * element1.board.name.localeCompare(element2.board.name))
		} else if (sort.key === TASK_DATATABLE_KEYS.MATERIAL) {
			return data.sort((element1, element2) => {
				const e1 = element1.material.length ? element1.material.join(', ') : ' '
				const e2 = element2.material.length ? element2.material.join(', ') : ' '
				return (desc ? -1 : 1) * e1.localeCompare(e2)
			})
		}

		return data.sort(localCompare(sort.key, sort.order === DATATABLE_SORT_ORDER.DESC))
	}, [sort, data])

	const actions = useMemo(() => getActions([
				ACTIONS_KEY.MOVE_TASK,
				ACTIONS_KEY.CONFIRM_HARVEST,
				ACTIONS_KEY.SPECIFY_GENERIC_TASK,
				ACTIONS_KEY.ADD_INSTRUCTION,
				ACTIONS_KEY.DELETE_TASK
			],
			selectedRows,
			TOUR_PLAINE_TABS.SUIVI_BLOC,
			loadData),
		[selectedRows])

	/**
	 * UseEffect
	 */
	useEffect(() => {
		loadData()
	}, [loadData])

	return (<div className={classes.root}>
		<Grid container className={classes.header}>
			<Title actionBlock={BLOCK_ACTION_TYPE.TASK} />
			<Grid item container direction="row" justify="flex-start" alignItems="center">
				<Grid item container xs direction="row" alignItems="center">
					<Grid item style={{ marginRight: 24 }}>
						<Typography className={classes.filterText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.tasks.filter" />
						</Typography>
					</Grid>
					<Field
						name={SVB_TASKS_CONTENT_FORM_FIELDS.TASK_HARVEST}
						component={Checkbox}
						label={<Typography className={classes.filterText} style={{ marginLeft: 15 }}>
							<FormattedMessage id="tourPlaine.suiviParBloc.tasks.harvest" />
						</Typography>
						}
					/>
					<Field
						name={SVB_TASKS_CONTENT_FORM_FIELDS.TASK_WAITING}
						component={Checkbox}
						label={<Typography className={classes.filterText} style={{ marginLeft: 10 }}>
							<FormattedMessage id="tourPlaine.suiviParBloc.tasks.waiting" />
						</Typography>
						}
					/>
				</Grid>
				<Grid item container xs direction="row" justify="flex-end" alignItems="flex-end" className={classes.actionContainer}>
					<Button
						type="secondary"
						startIcon={<AddCommentIccon />}
						onClick={() => openReminderForm(`${formatMessage({ id: 'tourPlaine.tabBar.suiviBlock' })} / ${formatMessage({ id: 'tourPlaine.suiviParBloc.actionTab.tasks' })}`)}
						className={classes.button}
						style={{ minWidth: 145 }}
					>
						<FormattedMessage id="tourPlaine.actions.addReminder" />
					</Button>
					<Button
						type="secondary"
						startIcon={<PlaylistAddIcon />}
						onClick={() => openCreateTask(loadData)}
						className={classes.button}
						style={{ minWidth: 150 }}
					>
						<FormattedMessage id="tourPlaine.actions.addTask" />
					</Button>
					<ButtonWithMenu
						type="primary"
						startIcon={<MoreHorizIcon />}
						className={classes.button}
						style={{ minWidth: 155, width: 155 }}
						items={actions}
						disabled={!selectedRows.length}
					>
						<FormattedMessage id="tourPlaine.actions.others" />
					</ButtonWithMenu>
				</Grid>
			</Grid>
		</Grid>
		<Grid item container className={classes.dataTableContainer}>
			<DataTableWithLoader
				nom="suiviParBloc_CropsContent"
				tableProps={{
					stickyHeader: true,
					className: classes.dataTable
				}}
				data={getDisplayData}
				loading={isLoading}
				noResultFragment={<NoResultPage emptyStateMessageId="tourPlaine.suiviParBloc.tasks.table.empty" />}
				totalElements={data.length}
				selectable
				displaySelectAll
				headers={headers}
				loaderStyle={{ width: '100%', paddingLeft: '0%', paddingRight: '0%' }}
				noPagination
				onFilterChange={key => {
					setSort(prev => {
						if (prev.key === key) {
							return ({
								...prev,
								order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
							})
						}
						return ({
							key,
							order: DATATABLE_SORT_ORDER.ASC
						})
					})
				}}
				onRowClick={row => setSelectedRows(prev => {
					if (prev.find(p => p.id === row.id)) {
						return prev.filter(p => p.id !== row.id)
					}

					return [
						...prev,
						row
					]
				})}
				selectRows={() => {
					selectedRows.length === data.length ? setSelectedRows([]) : setSelectedRows([...data])
				}}
				selectedRows={selectedRows}
			/>
		</Grid>
	</div>)
}

TasksContent.propTypes = {
	classes: PropTypes.object,
	block: PropTypes.object,
	openCreateTask: PropTypes.func,
	openReminderForm: PropTypes.func,
	getActions: PropTypes.func,
	taskHarvest: PropTypes.bool,
	taskWaiting: PropTypes.bool,
	typeTacheList: PropTypes.array,
	categorieTacheList: PropTypes.array,
	openTaskUpdateForm: PropTypes.func
}
const mapStateToProps = (state) => {
	const selector = formValueSelector(SVB_TASKS_CONTENT_FORM_NAME)
	return ({
		taskHarvest: selector(state, SVB_TASKS_CONTENT_FORM_FIELDS.TASK_HARVEST),
		taskWaiting: selector(state, SVB_TASKS_CONTENT_FORM_FIELDS.TASK_WAITING)
	})
}

export default compose(
	injectTaskUpdateForm,
	openTacheDetail,
	injectCreateTask,
	injectReminderForm,
	injectActionsForm,
	injectTypeTacheValueList,
	injectFamilleTacheValueList,
	connect(mapStateToProps),
	reduxForm({
		form: SVB_TASKS_CONTENT_FORM_NAME,
		enableReinitialize: true
	}),
	withStyles(contentStyles)
)(TasksContent)

