import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { colors } from '../../utils/constants'
import Input from './Input'
import mapError from './mapError'


const styles = () => getStyles({
	container: {
		minHeight: 72
	},
	iconToday: {
		transform: 'translate(0, -50%)',
		color: '#212121'
	},
	infoSemaine: {
		fontSize: '0.75em',
		color: colors.icon,
		marginLeft: '1em'
	}
})

const DateInput = ({ input: { value, ...inputProps }, classes, containerClassName, showFooter, ...props }) => {
	let footerInfo = <></>
	if (value) {
		footerInfo = (
			<span className={classes.infoSemaine} style={(props.style || {})}>
			<FormattedMessage
				id="planning.cultureForm.dates.semaine"
				values={{
					start: moment(value).startOf('isoWeek').format('DD/MM'),
					end: moment(value).endOf('isoWeek').format('DD/MM')
				}}
			/>
		</span>
		)
	}

	return (<Input
			{...props}
			input={{
				...inputProps,
				value
			}}
			footerInfo={showFooter ? footerInfo : <></>}
			InputLabelProps={{
				shrink: true
			}}
			containerClassName={containerClassName || classes.container}
		/>
	)
}

export default compose(
	mapError,
	withStyles(styles)
)(DateInput)
