import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {injectEtapeTypeValueList, injectTypeTacheValueList} from '../../../../common/valueLists/valueListInjectors'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import {DEFAULT_LABEL, ETAT_TACHE_UPPERCASE, TACHE_TYPE_ENTITE} from '../../../../../utils/constants'
import moment from 'moment'

const styles = () => getStyles({
	contenu: {
		margin: '1.5em',
		marginTop: 14,
		fontFamily: 'Lato'
	},
	titre: {
		marginTop: 10,
		fontWeight: 'bold'
	},
	bloc: {
		marginTop: 22
	},
	liste: {
		marginTop: -6,
		marginLeft: -8
	}
})

const isTaskInInterval = (task, weekBefore, semaineSelection) => {
	if (!task.etape) {
		return true
	} else if (weekBefore) {
		return !(moment(task.etape.dateDebut).isoWeek() === moment(semaineSelection).isoWeek())
	} else {
		return !(moment(task.etape.dateFin).isoWeek() === moment(semaineSelection).isoWeek())
	}
}

const getLibelleErreur = (tache) => {
	switch (tache.statut) {
		case ETAT_TACHE_UPPERCASE.EN_COURS:
			return 'en cours de réalisation'
		case ETAT_TACHE_UPPERCASE.TERMINEE:
			return 'terminée'
		default:
			return DEFAULT_LABEL
	}
}

export const getLibelleLieu = (typeLieu, lieu) => {
	switch (typeLieu) {
		case TACHE_TYPE_ENTITE.ETAPE:
		case TACHE_TYPE_ENTITE.ASSOLEMENT:
		case TACHE_TYPE_ENTITE.SURFACE:
			return `Surface ${lieu}`
		case TACHE_TYPE_ENTITE.BLOC:
			return `Bloc ${lieu}`
		case TACHE_TYPE_ENTITE.FERME:
			return `Toute la ferme`
		default:
	}
}

const ContenuPopinDeplacementTache = ({ tachesEnErreur, classes, typeTacheList, weekBefore, semaineSelection, etapeTypeList }) => {
	return (
		<Grid className={classes.contenu}>
			<Grid>
				<FormattedMessage id={'gestionTaches.toolbar.actions.move.popin.text'} />
			</Grid>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					<FormattedMessage id={'gestionTaches.toolbar.actions.move.popin.message'} />
				</Grid>
				<Grid className={classes.liste}>
					<ul>
						{
							tachesEnErreur.map(tache => {
								const typeTache = (typeTacheList.find(type => type.code === tache.type) || {}).label
								const nomEtape = tache.etape && (etapeTypeList.find(type => type.code === tache.etape.type) || {}).label

								return (
									<li key={tache.id}>
										{
											semaineSelection && !isTaskInInterval(tache, weekBefore, semaineSelection) ? (
												<FormattedMessageWithBold
													id={'gestionTaches.toolbar.actions.move.popin.labelInterval'}
													values={{
														type: typeTache,
														culture: tache.culture && `- ${tache.culture}`,
														lieu: getLibelleLieu(tache.typeLieu, tache.lieu),
														etape: nomEtape,
														debut: moment(tache.etape.dateDebut).format('DD/MM/YYYY'),
														fin: moment(tache.etape.dateFin).format('DD/MM/YYYY')
													}}
												/>
											) : (
												<FormattedMessageWithBold
													id={'gestionTaches.toolbar.actions.move.popin.labelEtat'}
													values={{
														type: typeTache,
														culture: tache.culture && `- ${tache.culture}`,
														lieu: getLibelleLieu(tache.typeLieu, tache.lieu),
														etat: getLibelleErreur(tache)
													}}
												/>
											)
										}
									</li>
								)
							})
						}
					</ul>
				</Grid>
			</Grid>
		</Grid>
	)
}

ContenuPopinDeplacementTache.propTypes = {
	tachesEnErreur: PropTypes.array,
	classes: PropTypes.object,
	typeTacheList: PropTypes.array,
	etapeTypeList: PropTypes.array,
	weekBefore: PropTypes.bool,
	semaineSelection: PropTypes.string
}

export default compose(
	withStyles(styles),
	injectTypeTacheValueList,
	injectEtapeTypeValueList
)(ContenuPopinDeplacementTache)
