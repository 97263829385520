import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { isInvalid, SubmissionError } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import FormButton from '../../../../components/form/FormButton'
import { ActionPanelContext, DetailsPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import SuiviDeTacheForm from '../form/SuiviDeTacheForm'
import { suiviDeTacheSave } from '../gestionTachesApi'
import { ETAT_TACHE_UPPERCASE, SUIVI_DE_TACHE_FORM } from '../../../../utils/constants'
import { useGestionTacheContext } from '../GestionTacheProvider'
import { refreshAffectationsTable } from '../services/actions'
import { dataTableActions } from 'isotope-client'

export const injectSuiviDeTache = (WrappedComponent) => {
	const SuiviDeTacheInjector = ({ refreshAffectationsTable, refreshRessource, ...props }) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { snackError, snackSuccess } = useSnackbar()
		const { refreshHeaderData } = useGestionTacheContext()
		const { closePanel: closeDetailsPanel } = React.useContext(DetailsPanelContext)

		const suiviDeTache = (currentTache, jour, isFromPageSuivi = false, idUserSelectionne = null, refreshTableSuiviAvancement) => {

			const onSubmit = (values) => {
				const tempsUsers = {}
				values.tempsPasseUser.forEach(user => tempsUsers[user.code] = user.tempsPasse)

				const data = {
					...values,
					users: (values.usersAutocomplete || []).map((user) => user.code),
					tache: currentTache.id,
					commentaire: values.commentaire || null,
					choixEtat: values.choixEtat || ETAT_TACHE_UPPERCASE.A_PLANIFIER,
					tempsUsers: tempsUsers
				}

				return suiviDeTacheSave(data)
					.then(() => {
						if(isFromPageSuivi){
							if(idUserSelectionne){
								refreshTableSuiviAvancement(idUserSelectionne)
							}
							closePanel()
							closeDetailsPanel()
							snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.suivi' })
						}else{
							refreshAffectationsTable()
							refreshRessource()
							refreshHeaderData(jour)
							closePanel()
							closeDetailsPanel()
							snackSuccess({ id: 'gestionTaches.ecranAffectations.snackbar.success.suivi' })
						}
					})
					.catch((e) => {
						snackError({ id: 'gestionTaches.ecranAffectations.snackbar.errors.suivi' })
						throw new SubmissionError(e)
					})
			}

			openPanel(
				<FormattedMessage id="gestionTaches.ecranAffectations.panels.titre.suiviTache" />,
				<SuiviDeTacheForm
					currentTache={currentTache}
					onSubmit={(values) => onSubmit(values)}
					isFromPageSuivi={isFromPageSuivi}
				/>,
				[<FormButton
					type="primary"
					formName={SUIVI_DE_TACHE_FORM}
					getDisabledFromState={state => isInvalid(SUIVI_DE_TACHE_FORM)(state)}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>]
			)
		}
		return <WrappedComponent {...props} suiviDeTache={React.useCallback(suiviDeTache, [])} />
	}

	SuiviDeTacheInjector.propTypes = {
		currentTache: PropTypes.object
	}

	const actions = {
		refreshAffectationsTable: refreshAffectationsTable,
		refreshRessource: () => dataTableActions.refresh('SuiviRessourceTable'),
	}

	return connect(undefined, actions)(SuiviDeTacheInjector)
}
