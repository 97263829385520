import React from 'react'
import Loader from 'react-loader-advanced'
import { SpringDataTable } from 'isotope-client'
import CircularProgress from '@material-ui/core/CircularProgress'

/**
 * Loader pour la springdatatable
 */
const SpringDataTableWithLoader = (props) => {
    const [loading, setLoading] = React.useState(false)
    return <Loader
        show={loading}
        message={<CircularProgress />}
        backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
        hideContentOnLoad
    >
        <SpringDataTable
            {...props}
            preFetch={() => setLoading(true)}
            postFetch={() => {
                // Ajout d'un timer pour laisser le temps aux sélecteurs de réaliser leurs traitements
                const timer = setTimeout(() => {
                    setLoading(false)
                }, 1000)
                return () => clearTimeout(timer)
            }}
        />
    </Loader>

}

export default SpringDataTableWithLoader
