import {Paper, withStyles} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classnames from 'classnames'
import {getStyles} from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import {FormattedMessage, injectIntl} from 'react-intl'
import LoaderAdvanced from 'react-loader-advanced'
import {connect} from 'react-redux'
import {ScrollSync, ScrollSyncPane} from 'react-scroll-sync'
import {compose} from 'redux'
import CustomWaypoint from '../../../../components/CustomWaypoint'
import FloattingLoader from '../../../../components/FloattingLoader'
import NoResultPage from '../../../../components/NoResultPage'
import {ASSOLEMENT_ETAT, ETAPE_TYPE} from '../../../../utils/constants'
import {displayAssolement, sliceText} from '../../../../utils/utils'
import {openCultureDetail} from '../services/actions/detail/openCultureDetailInjector'
import {openSurfaceDetail} from '../services/actions/detail/openSurfaceDetailInjector'
import {setActiveRow} from '../services/assolement/assolementAction'
import {loadAssolement} from '../services/assolement/assolementInjector'
import {getAssolementsWithRef, getAssoRef, isCompare, isLoadingMore, listIsLoading} from '../services/assolement/assolementSelector'
import {getPlanningSize, getSortState} from '../services/toolbar/toolbarSelector'
import {PLANNING_VISIBLE_WIDTH, SORT} from '../utils/constant'
import {withLineSeparator} from '../utils/utils'
import Delimiter from './Delimiter'
import PlanningLeftPanel from './leftPanel/PlanningLeftPanel'
import AssolementRow from './row/AssolementRow'
import {LocalDate} from "../../../../utils/date/local-date";
import {DateFormat} from "../../../../utils/dateConstants";
import {PLANNING_RANGE} from "../../../common/campagne/campagneReducer";

// Hauteur d'une ligne/colonne (dynamique plus tard si plusieurs vues planning)
const ROW_HEIGHT = 40

const COL_WIDTH = 38
const MIN_TEXT_SIZE = 34

const WAYPOINT_INDEX_OFFSET = 0

const styles = () => getStyles({
	root: {
		position: 'relative',
		width: '100%'
	},
	container: {
		maxHeight: 'calc(100vh - 130px)',
		width: 'calc(100% - 60px)'
	},
	colWidthSource: {
		width: 100
	},
	sourceHead1: {
		padding: 0,
		width: 100,
		textAlign: 'center',
		borderBottomWidth: 0
	},
	sourceHead2: {
		padding: 0,
		width: 'auto',
		borderBottomWidth: 0,
		textAlign: 'center'
	},
	contentHead1: {
		padding: 0,
		width: 100,
		borderBottom: 0,
		textAlign: 'center'
	},
	contentBody1: {
		padding: 0,
		width: 100,
		textAlign: 'center'
	},
	scrollBody1: {
		overflow: 'scroll',
		margin: '0',
		height: 'calc(100vh - 235px)',
		marginRight: 0,
		paddingRight: 0
	},
	rowMonth: {
		height: 45,
		verticalAlign: 'bottom'
	},
	rowWeek: {
		height: 40,
		verticalAlign: 'bottom'
	},
	rowNbWeek: {
		height: 40,
		verticalAlign: 'bottom'
	},
	relativeTable: {
		position: 'relative'
	}
})

const weekPerMonth = 52 / 12

const scrollPositionCentered = (campagne, colWidth) => {
	const now = LocalDate.now()
	if (campagne.dateDebut && campagne.dateFin) {
		const dateDebutVisible = LocalDate.fromAPI(campagne.dateDebutVisible.format(DateFormat.YYYY_MM_DD))
		const dateDebut = LocalDate.fromAPI(campagne.dateDebut)
		const dateFin = LocalDate.fromAPI(campagne.dateFin)
		let weeks

		if (now.gte(dateDebut) && now.lte(dateFin)) {
			weeks = LocalDate.now()._diff(dateDebutVisible, 'weeks')
		} else {
			weeks = PLANNING_RANGE * weekPerMonth
		}

		const weekPosition = weeks * colWidth
		const halfWidth = PLANNING_VISIBLE_WIDTH / 2
		return weekPosition - halfWidth
	}
	return 0
}

const Calendar = ({
	campagne,
	isCompare,
	assolements,
	openCultureDetail,
	openSurfaceDetail,
	closeCultureDetail,
	sort,
	isLoading,
	setActiveRow,
	getPrevious,
	getNext,
	isLoadingMore,
	planningSize,
	refreshAssolements,
	classes,
	intl
}) => {

	const [colWidth, setColWidth] = React.useState(COL_WIDTH)
	const lineSeparator = sort.currentSort !== SORT.SEMIS

	const isSmall = colWidth < MIN_TEXT_SIZE

	const rowRef = React.useRef()

	const indianaScrollRef = React.useRef()
	const allRowsContainer = React.useRef()

	React.useEffect(() => {
		if (planningSize) {
			const weekNumber = planningSize * weekPerMonth
			const width = Math.round(PLANNING_VISIBLE_WIDTH / weekNumber)
			setColWidth(width)
			refreshAssolements()
		}
	}, [planningSize, refreshAssolements])

	React.useEffect(() => {
		if (!isLoading) {
			allRowsContainer.current.scrollLeft = scrollPositionCentered(campagne, colWidth)
			syncScroll(true)()
		}
	}, [isLoading])

	const syncScroll = isCalendar => () => {
		if (!indianaScrollRef.current || !indianaScrollRef.current.container || !indianaScrollRef.current.container.current) {
			return
		}
		const asso = indianaScrollRef.current.container.current
		const dates = allRowsContainer.current

		const scrollLeft = isCalendar ? dates.scrollLeft : asso.scrollLeft
		dates.scrollLeft = scrollLeft
		asso.scrollLeft = scrollLeft
	}
	/**
	 * Récupère l'intervalle de mois pour la période donnée
	 */
	const getMonthArray = () => {
		if (!campagne.dateFinVisible || !campagne.dateDebutVisible) {
			return []
		}
		const nbMonths = Math.ceil(campagne.dateFinVisible.startOf('month').diff(moment(campagne.dateDebutVisible).endOf('month'), 'months', true))
		const monthsArray = []
		for (let i = 0; i < nbMonths; i++) {
			const actualDate = moment(campagne.dateDebutVisible).add(i, 'months')
			const actualMonth = { value: actualDate.month(), nbWeeks: 4}

			// On ajoute l'année
			if (actualMonth.value === 0) {
				actualMonth.year = actualDate.year()
			}
			let start = moment(actualDate).startOf('month')

			if (start.isoWeekday() !== 1) {
				start = moment(start).add(1, 'weeks').startOf('isoWeek')
			}
			const end = actualDate.endOf('month').startOf('isoWeek')

			actualMonth.nbWeeks = end.diff(start, 'weeks') + 1

			if (i === 0) {
				actualMonth.nbWeeks = Math.ceil(moment(campagne.dateDebutVisible).endOf('month').diff(moment(campagne.dateDebutVisible), 'weeks', true))
			} else if (i === nbMonths - 1) {
				actualMonth.nbWeeks = campagne.dateFinVisible.diff(start, 'weeks') + 1
			}
			monthsArray.push(actualMonth)
		}
		//on n'affiche pas le premier mois s'il n'est pas complet pour éviter le chevauchement
		if (monthsArray.length && monthsArray[0].nbWeeks < 4) {
			monthsArray.shift()
		}
		return monthsArray
	}

	const getMonthText = month => {
		const text = intl.formatMessage({ id: `enums.month.${month.value + 1}` })
		if (isSmall) {
			return sliceText(text)
		}
		return text
	}

	/**
	 * Récupère l'intervalle de semaine pour la période donnée
	 */
	const weekArray = React.useMemo(() => {
		const nbWeeks = Math.ceil(
			moment(campagne.dateFinVisible)
				.diff(moment(campagne.dateDebutVisible).day('Monday'), 'weeks', true)
		)
		const weeksArray = []
		for (let i = 0; i < nbWeeks; i++) {
			const actualDate = moment(campagne.dateDebutVisible).add(i, 'weeks')
			const currentWeek = actualDate.week()
			const currentYear = actualDate.year()
			weeksArray.push({ value: currentWeek, year: currentYear, date: actualDate, weekNumber: currentWeek })
		}
		return weeksArray
	}, [campagne])

	const getWeekDay = (week) => {
		const date = week.date.startOf('isoWeek')
		if (!isSmall) {
			return `${date.date()}-${date.add(6, 'days').date()}`
		}
		return date.date()
	}

	const getWeekNumber = (week) => {
		return `${intl.formatMessage({ id: 'planning.calendar.shortLabelWeek' })}${week.weekNumber}`
	}

	const isCurrentWeek = (week) => moment().isoWeek() === week.value && moment().year() === week.year

	const openPanelDetails = (id) => {
		const selectedAssolement = assolements.find(asso => asso.id === id)
		if (!isCompare && selectedAssolement && !!selectedAssolement.culture) {
			openCultureDetail(selectedAssolement)
		}
		if (isCompare && selectedAssolement) {
			if (selectedAssolement.deleted) {
				closeCultureDetail()
			} else if (selectedAssolement.assolementEtapeList && selectedAssolement.assolementEtapeList.length > 0) {
				selectedAssolement.assolementEtapeList.some(etape => etape.type === ETAPE_TYPE.FERTILISATION) ? openSurfaceDetail(selectedAssolement) : openCultureDetail(selectedAssolement)
			}
		}
	}

	const [isAlreadyOnTop, setIsAlreadyOnTop] = React.useState(false)
	const [isScrollingBottom, setIsScrollingBottom] = React.useState(false)

	const onMouseWheel = (event) => {
		if (isAlreadyOnTop && event.deltaY < 0) {
			getPrevious()
		}
	}


	const onEnter = isUp => ({ previousPosition }) => {
		if (isUp) {
			if (previousPosition) {
				getPrevious()
			} else {
				setIsAlreadyOnTop(true)
			}
		}
		if (!isUp) {
			getNext()
		}
		setIsScrollingBottom(!isUp)
	}

	const onLeave = index => () => {
		if (index === WAYPOINT_INDEX_OFFSET)
			setIsAlreadyOnTop(false)
	}

	const visibleAssolement = assolements.filter(assolement => displayAssolement(isCompare, assolement, campagne.id)).length

	const waypointEndIndex = visibleAssolement - 1 - WAYPOINT_INDEX_OFFSET

	return (
		<Paper className={classes.root} onWheel={onMouseWheel}>
			<LoaderAdvanced
				show={isLoading}
				message={<CircularProgress />}
				backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
			>
				<LoaderAdvanced
					show={isLoadingMore}
					message=""
					backgroundStyle={{ backgroundColor: 'unset' }}
				>
					<ScrollSync horizontal={false}>
						<div className="calendar-timeline">
							<TableContainer className={classes.container}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead style={{ borderBottom: '2px solid rgba(85,85,90,.5)' }}>
										<TableRow>
											<TableCell className="source-area widget-header">
												<TableContainer>
													<Table>
														<TableBody>
															<TableRow style={{ height: '45px', verticalAlign: 'bottom' }}>
																<TableCell className={classes.sourceHead1}>
																	<div className="source-col with-border"><FormattedMessage
																		id="planning.leftPanel.header.surface" /></div>
																</TableCell>
																<TableCell className={classes.sourceHead2}>
																	<div className="source-col"><FormattedMessage
																		id="planning.leftPanel.header.culture" /></div>
																</TableCell>
															</TableRow>
															<TableRow style={{ height: 55 }}>
																<TableCell className={classes.sourceHead1} />
																<TableCell className={classes.sourceHead2} />
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</TableCell>
											<TableCell className="time-area widget-header">
												<TableContainer>
													<Table>
														<TableBody>
															<TableRow>
																<TableCell className={classes.contentHead1}>
																	<div className="scroller-clip h-100">
																		<div ref={allRowsContainer} className="scroller"
																		     style={{ overflow: 'hidden', margin: '0px 0px -17px 0px' }}>
																			<div className="scroller-canvas">
																				<div className="scroller-content">
																					<Delimiter colWidth={colWidth} header />
																					<table>
																						<colgroup>
																							{weekArray.map((week, index) => <col key={`weeks-header-${index}`}
																							                                           width={colWidth} />)}
																						</colgroup>
																						<tbody>
																						<tr className={classes.rowMonth}>
																							{getMonthArray().map((month, index) => <th key={`months-row-${index}`}
																							                                           colSpan={month.nbWeeks}>
																								<div className="date1">{getMonthText(month)} {month.year || ''}</div>
																							</th>)}
																						</tr>
																						<tr className={classes.rowWeek}>
																							{weekArray.map((week, index) => <th key={`weeks-row-${index}`} className="widget-header">
																								<div className={`cell-content ${isCurrentWeek(week) ? 'active' : ''}`}>
																									<span className={`cell-text date2 ${isCurrentWeek(week) ? 'active' : ''}`}>{getWeekNumber(week)}</span>
																									<span className={`cell-text date2 ${isCurrentWeek(week) ? 'active' : ''}`}>{getWeekDay(week)}</span>
																								</div>
																							</th>)}
																						</tr>
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<PlanningLeftPanel
												campagne={campagne}
												isCompare={isCompare}
												openCultureDetail={openCultureDetail}
												lineSeparator={lineSeparator}
												setActiveRow={(id) => {
													setActiveRow(id)
													openPanelDetails(id)
												}}
												isScrollingBottom={isScrollingBottom}
												isLoading={isLoadingMore}
											/>
											<TableCell className="time-area widget-content">
												{!!assolements.length && <TableContainer className={classes.relativeTable}>
													<FloattingLoader isLoading={isLoadingMore} isBottom={isScrollingBottom} />

													<Table>
														<TableBody>
															<TableRow>
																<TableCell className={classes.contentBody1}>
																	<div className="scroller-clip">
																		<ScrollSyncPane>
																			<ScrollContainer
																				ref={indianaScrollRef}
																				vertical={false}
																				hideScrollbars
																				ignoreElements=".moveItemActive"
																				onScroll={syncScroll(false)}
																				className={classnames(classes.scrollBody1, 'scroller')}
																			>
																				<div className="scroller-canvas">
																					<div className="scroller-content">
																						<div className="scroller-rows">
																							<Delimiter colWidth={colWidth} />
																							<table ref={rowRef}>
																								<colgroup>
																									{weekArray.map((week, index) => <col
																										key={`weeks-timeline-header-${index}`} width={colWidth} />)}
																								</colgroup>
																								<tbody>
																								{assolements.map((assolement, index) => (<React.Fragment
																									key={`assolement-calendar-planche-${assolement.planche.id}-${assolement.id}`}>
																									{lineSeparator && withLineSeparator(assolement, index, assolements) &&
																									<tr key={`assolement-calendar-planche-${assolement.planche.id}-empty`}
																									    className="timeline-row">
																										{weekArray.map((week, indexWeek) => (
																											<th
																												key={`assolement-weeks-empty-${assolement.planche.id}-${indexWeek}`}
																												className={`widget-content ${isCurrentWeek(week) ? 'widget-bg-active' : ''}`}
																												style={{height: '30px'}}>
																												<div className="cell-content">
																													<span className="cell-text" />
																												</div>
																											</th>))}
																									</tr>}
																									<CustomWaypoint
																										disabled={isLoadingMore}
																										childOnly={!(index === waypointEndIndex || index === WAYPOINT_INDEX_OFFSET)}
																										onEnter={onEnter(index === WAYPOINT_INDEX_OFFSET)}
																										onLeave={onLeave(index)}>
																										{displayAssolement(isCompare, assolement, campagne.id) &&
																										<tr className="timeline-row"
																										    key={`assolement-calendar-assolement-${assolement.id}`}>
																											{weekArray.map((week, colIndex) => (
																												<th key={`assolement-weeks-${assolement.id}-${colIndex}`}
																												    className={`${isCompare ? 'widget-content-compare' : 'widget-content'} ${isCurrentWeek(week) ? 'widget-bg-active' : ''}`}>
																													<div className="cell-content">
																														<span className="cell-text" />
																													</div>
																													{colIndex === 0 && <div className="timeline-drag"
																													                        style={{ width: rowRef.current && rowRef.current.clientWidth }}>
																														<AssolementRow
																															isCompare={isCompare}
																															campagne={campagne}
																															assolementId={assolement.id}
																															gridDimension={{ height: ROW_HEIGHT, width: colWidth }}
																														/>
																													</div>}
																													{colIndex === 0 && isCompare &&
																													<div className="timeline-drag">
																														<AssolementRow
																															isCompare={isCompare}
																															campagne={campagne}
																															assolementId={assolement.id}
																															gridDimension={{ height: ROW_HEIGHT, width: colWidth }}
																															etat={ASSOLEMENT_ETAT.VALIDE}
																														/>
																													</div>}
																												</th>
																											))}
																										</tr>}
																									</CustomWaypoint>
																								</React.Fragment>))}
																								</tbody>
																							</table>
																						</div>
																					</div>
																				</div>
																			</ScrollContainer>
																		</ScrollSyncPane>
																	</div>
																</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>}
												{campagne.id && (!assolements.length || assolements.length === 0) && !isLoading && !isLoadingMore && <NoResultPage />}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</ScrollSync>
				</LoaderAdvanced>
			</LoaderAdvanced>
		</Paper>
	)
}

Calendar.propTypes = {
	campagne: PropTypes.object,
	isCompare: PropTypes.bool,
	assolements: PropTypes.array,
	sort: PropTypes.object,
	isLoading: PropTypes.bool,
	setActiveRow: PropTypes.func,
	classes: PropTypes.object,
	planningSize: PropTypes.number
}

const mapStateToProps = (state) => ({
	assolements: getAssolementsWithRef(state),
	assolementsRef: getAssoRef(state),
	isCompare: isCompare(state),
	sort: getSortState(state),
	isLoading: listIsLoading(state),
	isLoadingMore: isLoadingMore(state),
	planningSize: getPlanningSize(state)
})

const actions = {
	setActiveRow
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	openCultureDetail,
	openSurfaceDetail,
	injectIntl,
	loadAssolement
)(Calendar)
