import React from 'react'
import {compose} from "redux";
import mapError from './mapError'
import {TextField, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import {colors} from '../../utils/constants'
import {DatePicker} from '@material-ui/pickers'
import moment from 'moment'
import {FormattedMessage, useIntl} from 'react-intl'
import classnames from 'classnames'


const styles = () => getStyles({
	container: {
		minHeight: 72
	},
	iconToday: {
		transform: 'translate(0, -50%)',
		color: '#212121'
	},
	infoSemaine: {
		fontSize: '0.75em',
		color: colors.icon,
		marginLeft: '1em'
	},
	sliderCalendar: {
		'& .MuiPickersModal-dialogRoot': {
			position: 'absolute',
			right: 55
		}
	}
})

const DateFieldCustom = ({ input: { onBlur, value, ...inputProps }, meta, classes, containerClassName,  onInputChange, periodeDate, hideFootInfo = false , textFieldStyle, sliderCalendar, ...rest }) => {
	const intl = useIntl()

	const onChange = date => {
		inputProps.onChange(date)
		if (onInputChange) {
			onInputChange(date)
		}
	}

	let footerInfo = <></>
	if (value && !hideFootInfo) {
		footerInfo = (
			<span className={classes.infoSemaine}>
			<FormattedMessage
				id="planning.cultureForm.dates.semaine"
				values={{
					start: moment(value).startOf('isoWeek').format('DD/MM'),
					end: moment(value).endOf('isoWeek').format('DD/MM')
				}}
			/>
		</span>
		)
	}

	const renderInput = (props) => {
		return <div className={classnames(classes.container, containerClassName)} style={(rest.style || {})}>
			<TextField
			type="text"
			onClick={props.onClick}
			value={props.value}
			onChange={props.onChange}
			variant="filled"
			helperText={(!meta || meta.touched) && meta.error}
			meta={meta}
			error={(!meta || meta.touched) && !!meta.error}
            label={props.placeholder ?? "jj/mm/aaaa"}
			style={textFieldStyle}
			/>
			{footerInfo}
		</div>
	}

	const styleDay = {
		color: "rgba(0, 0, 0, 0.87)",
		width: "33px",
		height: "33px",
		margin: "0 2px",
		padding: 0,
		fontSize: "11px",
		fontWeight: 500
	}

	const styleDayPeriode = {
		color: "rgba(0, 0, 0, 0.87)",
		width: "33px",
		height: "33px",
		margin: "0 2px",
		padding: 0,
		fontSize: "11px",
		fontWeight: 500,
		backgroundColor: "#e8f5e9"
	}

	const styleDaySelect = {
		color: "#FFFF",
		width: "33px",
		height: "33px",
		margin: "0 2px",
		padding: 0,
		fontSize: "11px",
		fontWeight: 500,
		backgroundColor: "#804180"
	}

	const styleDayOut = {
		color: "rgba(0, 0, 0, 0.87)",
		width: "33px",
		height: "33px",
		margin: "0 2px",
		padding: 0,
		fontSize: "11px",
		fontWeight: 500,
		opacity: 0,
		pointerEvents: "none"
	}

	const weekNumber = {
		position:"absolute",
		left: "-18px",
		top: "9px",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "13px",
		fontWeight: 500,
		opacity: 0.5,
		pointerEvents: "none"
	}

	function getDayElement(day, selectedDate, isInCurrentMonth) {
		// fixme : ici le premier jour de la période n'est pas affiché en vert alors qu'il devrait
		//  (la comparaison day >= new Date(periodeDate[0]) return false) à cause de l'utc
		const isInPeriode = periodeDate ? day > new Date(periodeDate[0]) && day < new Date(periodeDate[1]) : false
		const isSelected = +day === +selectedDate
		const momentDay = moment(day.getTime())
		let dateTile
		if (isInCurrentMonth) { //conditionally return appropriate Element of date tile.
			let classe ="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day "
			if(isSelected){
				classe = classe + "MuiPickersDay-daySelected "
			}
			if(isInPeriode){
				dateTile = (
					<div style={{position:"relative"}}>
						{day.getDay() === 1 && <div style={weekNumber}>{intl.formatMessage({ id: 'planning.calendar.shortLabelWeek' })}{momentDay.format('ww')}</div>}
						<button className={classe} tabIndex="0" type="button" style={isSelected ? styleDaySelect : styleDayPeriode}>
							<span className="MuiIconButton-label">
								<p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">{day.getDate()}</p>
							</span>
							<span className="MuiTouchRipple-root"></span>
						</button>
					</div>)
			} else {
				dateTile = (
					<div style={{position:"relative"}}>
						{day.getDay() === 1 && <div style={weekNumber}>{intl.formatMessage({ id: 'planning.calendar.shortLabelWeek' })}{momentDay.format('ww')}</div>}
						<button className={classe} tabIndex="0" type="button" style={isSelected ? styleDaySelect : styleDay}>
						<span className="MuiIconButton-label">
							<p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">{day.getDate()}</p>
						</span>
							<span className="MuiTouchRipple-root"></span>
						</button>
					</div>
				)
			}

		} else {
			dateTile = (
				<div style={{position:"relative"}}>
					{day.getDay() === 1 && <div style={weekNumber}>{intl.formatMessage({ id: 'planning.calendar.shortLabelWeek' })}{momentDay.format('ww')}</div>}
					<button className="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-hidden"
							tabIndex="-1" type="button" style={styleDayOut}><span className="MuiIconButton-label"><p
						className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">1</p></span><span
						className="MuiTouchRipple-root"></span></button>
				</div>
			)
		}
		return dateTile
	}

	return <DatePicker
		autoOk
		{...inputProps}
		{...rest}
		format={"dd/MM/yyyy"}
		value={value ? value : null}
		onBlur={() => onBlur(value ? value : null)}
		onChange={onChange}
		TextFieldComponent={renderInput}
		renderDay={(day, selectedDate, isInCurrentMonth) => getDayElement(day, selectedDate, isInCurrentMonth)}
		cancelLabel="annuler"
		DialogProps={{
			className: sliderCalendar && classes.sliderCalendar
		}}
	/>
}

export default compose(
	mapError,
	withStyles(styles)
)(DateFieldCustom)
