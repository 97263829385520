import {Grid, Typography} from '@material-ui/core'
import React from 'react'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {FormattedMessage} from 'react-intl'
import {colors} from "../../../utils/constants";

const styles = (theme) => getStyles({
	container: {
		backgroundColor: theme.palette.primary.light,
		height: 100,
		width: 275
	},
	bigIcon: {
		fill: theme.palette.primary.main,
		width: 25,
		height: 25
	},
	secondaryMessage: {
		color: colors.primary,
		fontSize: 12,
		paddingLeft: '18%',
		marginTop: -3
	}
})

const StatBloc = ({Icon, getPourcentageCouleur, value, classes, idMessage, isPourcentage, total, reversePourcentage, unite, secondaryValue, idSecondaryMessage}) => {
	const color = getPourcentageCouleur(value, isPourcentage ? undefined : total, reversePourcentage)
	return (
		<Grid container item alignContent="center" justify="center" direction="column" className={classes.container}>
			<Grid container item alignContent="flex-start" justify="flex-start" style={{paddingLeft: 15}}>
				<Icon className={classes.bigIcon}/>
				<Typography style={{alignContent: 'center', paddingLeft: 10}}><FormattedMessage id={idMessage}/></Typography>
			</Grid>
			<Grid container item justify="space-between" alignItems="center">
				<Typography style={{color, fontSize: 35, fontWeight: 'bold', paddingLeft: 48, marginTop: -12}}>
					{`${value ?? 0}${isPourcentage ? ' %' : (unite ?? '')}`}
				</Typography>
				{secondaryValue && <Typography style={{color, fontSize: 15, paddingRight: 30}}>
					{`${secondaryValue} %`}
				</Typography>}
			</Grid>
			{idSecondaryMessage && <Typography className={classes.secondaryMessage}>
				<FormattedMessage className={classes.secondaryMessage} id={idSecondaryMessage}/>
			</Typography>}
		</Grid>
	)
}

export default withStyles(styles)(StatBloc)