import React from 'react'
import { MenuItem, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import { change, Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { hasRole } from '../../../../components/security/RoleChecker'
import { ROLE_WEIGHT } from '../../../../utils/constants'
import * as userSelectors from '../../../common/user/services/userSelectors'
import { injectFermeList } from '../../ferme/services/fermeListInjector'
import Select from '../../../../components/form/Select'
import { Typography } from '@material-ui/core'
import Input from '../../../../components/form/Input'
import { formValueSelector } from 'redux-form'
import moment from 'moment'
import { getCampagnesList } from './campagneApi'

const styles = () => getStyles({
	field: {
		width: '50%'
	},
	row: {
		marginBottom: 15
	},
	form: {
		padding: '20px 20px 0px 20px'
	},
	text: {
		paddingLeft: 10,
		paddingRight: 10,
		marginBottom: 15,
		fontSize: 14
	}
})

export const FORM_NAME_CREATION = 'CampagneCreationForm'
const formattedMessageBase = 'bo.campagne.popin.creation'

const fields = {
	FERME: 'ferme',
	DEBUT: 'debut',
	FIN: 'fin'
}

const validate = values => {
	const errors = {}
	Object.values(fields).forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageBase}.error` }
		}
	})
	return errors
}

const findLastSunday = (date) => {
	while (date.isoWeekday() !== 7) {
		date.subtract(1, 'days')
	}
	return date
}

const getDateDebut = (campagnes) => {
	// La date de début est le lendemain de la date de fin de la campagne précédente s'il y en a une,
	// sinon le lendemain du dernier dimanche de l'année précédente
	if (campagnes && campagnes.length > 0) {
		return moment(campagnes[0].dateFin).add(1, 'days')
	} else {
		let fin = moment(`31/12/${moment().year()-1}`, 'DD/MM/YYYY')
		fin = findLastSunday(fin)
		return fin.add(1, 'days')
	}
}

const getDateFin = (campagnes) => {
	// La date de fin est le dernier dimanche de l'année
	const debut = getDateDebut(campagnes)
	const year = debut.month() === 0 ? debut.year() : debut.year()+1
	let fin = moment(`31/12/${year}`, 'DD/MM/YYYY')
	fin = findLastSunday(fin)
	return fin.format('DD/MM/YYYY')
}

const CampagneForm = ({
	idFerme,
	classes,
	fermeList,
	handleSubmit,
	userAuthorities,
	fermeValue,
	campagnesList,
	change
}) => {
	const [campagnes, setCampagnes] = React.useState(campagnesList.map(campagne => campagne.data))
	const [lastIdFerme, setLastIdFerme] = React.useState(idFerme)

	React.useEffect(() => {
		change(fields.DEBUT, getDateDebut(campagnes).format('DD/MM/YYYY'))
		change(fields.FIN, getDateFin(campagnes))
	}, [])

	React.useEffect(() => {
		if (fermeValue && hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities) && lastIdFerme !== fermeValue) {
			getCampagnesList({ idFerme: fermeValue }).then(campagnes => {
				setCampagnes(campagnes)
				change(fields.DEBUT, getDateDebut(campagnes).format('DD/MM/YYYY'))
				change(fields.FIN, getDateFin(campagnes))
			})
			setLastIdFerme(fermeValue)
		}
	}, [fermeValue])

	return (
		<>
			<form onSubmit={handleSubmit} className={classes.form}>
				<Grid container>
					<Typography variant="body1" className={classes.text}>
						<FormattedMessage id={`${formattedMessageBase}.text`} />
					</Typography>
				</Grid>
				<Grid container className={classes.row}>
					<Grid item className={classes.field}>
						<Field
							name={fields.FERME}
							component={Select}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.FERME}`} />}
							fromBo
							disabled={!hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities)}
						>
							{
								fermeList.map(ferme =>
									<MenuItem key={ferme.id} value={ferme.id}>
										{`${ferme.raisonSociale} ${ferme.codePostal ? `(${ferme.codePostal})` : ''}`}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item className={classes.field}>
						<Field
							name={fields.DEBUT}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.DEBUT}`} />}
							fromBo
							disabled
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={fields.FIN}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.FIN}`} />}
							fromBo
							disabled
						/>
					</Grid>
				</Grid>
			</form>
		</>
	)
}

CampagneForm.propTypes = {
	idFerme: PropTypes.string,
	classes: PropTypes.object,
	fermeList: PropTypes.array,
	handleSubmit: PropTypes.func,
	userAuthorities: PropTypes.array,
	fermeValue: PropTypes.string,
	campagnesList: PropTypes.array,
	change: PropTypes.func
}

const mapStateToProps = (state, { idFerme }) => {
	const userAuthorities = userSelectors.getAuthorities(state)
	return {
		userAuthorities,
		initialValues: {
			ferme: idFerme
		},
		fermeValue: formValueSelector(FORM_NAME_CREATION)(state, fields.FERME)
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	injectFermeList,
	reduxForm({
		form: FORM_NAME_CREATION,
		validate
	}),
	withStyles(styles)
)(CampagneForm)
