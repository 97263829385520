import React from 'react'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import GestionRessourceProvider from './GestionRessourceProvider'
import GestionRessourceComponents from './GestionRessourceComponents'

const GestionRessource = () => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.GESTION_RESSOURCE), [])

	return (
		<DialogProvider>
			<DetailPanelProvider>
				<ActionPanelProvider>
					<GestionRessourceProvider>
						<GestionRessourceComponents/>
					</GestionRessourceProvider>
				</ActionPanelProvider>
			</DetailPanelProvider>
		</DialogProvider>
	)
}

export default GestionRessource
