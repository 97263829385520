import React from 'react'
import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {getStyles} from 'isotope-client'
import {change, Field, formValueSelector, reduxForm} from 'redux-form'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import classnames from 'classnames'
import {injectTypeSurfaceValueList, injectZoneSurfaceValueList} from '../../../common/valueLists/valueListInjectors'
import Select from '../../../../components/form/Select'
import {colors, Season, TYPE_ITK_BO, TYPE_PLANCHE} from '../../../../utils/constants'
import {formatInteger} from '../../../../utils/utils'
import Checkbox from "../../../../components/form/Checkbox";
import ErrorIcon from '@material-ui/icons/Error';
import Typography from "@material-ui/core/Typography";

const styles = theme => getStyles({
	field: {
		width: '50%'
	},
	row: {
		marginBottom: 15
	},
	checkbox: {
		paddingLeft: 15,
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Lato'
	},
	copy: {
		marginTop: 15,
		marginBottom: 15,
		'& .MuiGrid-grid-xs-1': {
			marginRight: 15
		},
		'& label': {
			paddingBottom: 15
		}
	},
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary,
		paddingLeft: 10,
		paddingRight: 50
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	alerteBar: {
		paddingBottom: 30,
		fontSize: 14
	},
	alerteIcon: {
		paddingRight: 10,
		color: colors.warning
	}
})

const formattedMessageBase = 'bo.cultureItk.form'

const FIELDS = {
	NOM_ITK: { name: 'nomItk', optional: true, size: 100 },
	NUM_ITK: { name: 'numItk', optional: true },
	DEBUT_SEMIS: { name: 'debutSemis' },
	NB_SEMAINES_SEMIS: { name: 'nbSemainesSemis', min: 1 },
	TIMELINE_ITK: { name: 'timelineItk', optional: true },
	ZONE: { name: 'zone', optional: true },
	SURFACE: { name: 'surface' },
	NB_RANGS: { name: 'nbRangs', optional: true, positive: true, min: 1 },
	DENSITE: { name: 'densite', optional: true, min: 0 },
	ESPACEMENT: { name: 'espacement', optional: true, min: 0 },
	NB_GRAINES: { name: 'nbGraines', min: 0 },
	PROD_THEO: { name: 'prodTheo', optional: true, min: 0 },
	TYPE_ITK: { name: 'typeItk' },
	DEBUT_SEMISC: { name: 'debutSemisC', optional: true, min: 0 },
	DEBUT_SEMISD: { name: 'debutSemisD', optional: true, min: 1 },
	DEBUT_PREPARATION: { name: 'debutPreparation', min: 0 },
	DEBUT_IMPLANTATION: { name: 'debutImplantation', optional: true, min: 1 },
	DEBUT_RECOLTE: { name: 'debutRecolte', min: 1 },
	DUREE_RECOLTE: { name: 'dureeRecolte', min: 0 },
	FIN_RECOLTE: { name: 'finRecolte', min: 1 },
	SAISON: { name: 'saison' },
	VOILE_THERMIQUE: { name: 'voileThermique', optional: true },
	CAMPAGNES: { name: 'campagnes', optional: true }
}

const STEPS_SEMIC = [FIELDS.DEBUT_PREPARATION.name, FIELDS.DEBUT_IMPLANTATION.name, FIELDS.DEBUT_RECOLTE.name]
const STEPS_SEMID = [FIELDS.DEBUT_SEMISD.name, FIELDS.DEBUT_RECOLTE.name]

const validate = (values, { fromGroupeCulture }) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})
	Object.values(FIELDS).filter(field => field.size).forEach(field => {
		if (values[field.name] && values[field.name].length > field.size) {
			errors[field.name] = { id: 'global.errors.sizeExceeded' }
		}
	})
	Object.values(FIELDS).filter(field => 'min' in field).forEach(field => {
		if (values[field.name] && values[field.name] < field.min) {
			errors[field.name] = { id: 'global.errors.min', values: { min: field.min } }
		}
	})

	// Validation selon provenance
	if (fromGroupeCulture) {
		if (!values[FIELDS.TIMELINE_ITK.name]) {
			errors[FIELDS.TIMELINE_ITK.name] = { id: 'global.errors.mandatory' }
		}
	} else {
		if (!values[FIELDS.NOM_ITK.name]) {
			errors[FIELDS.NOM_ITK.name] = { id: 'global.errors.mandatory' }
		}
		if (!values[FIELDS.ZONE.name]) {
			errors[FIELDS.ZONE.name] = { id: 'global.errors.mandatory' }
		}
		if (!values[FIELDS.PROD_THEO.name]) {
			errors[FIELDS.PROD_THEO.name] = { id: 'global.errors.mandatory' }
		}
	}

	// Validation selon surface
	if (!!values[FIELDS.SURFACE.name]) {
		if (values[FIELDS.SURFACE.name] === TYPE_PLANCHE.PERMANENTE) {
			if (!values[FIELDS.ESPACEMENT.name]) {
				errors[FIELDS.ESPACEMENT.name] = { id: 'global.errors.mandatory' }
			}
			if (!values[FIELDS.NB_RANGS.name]) {
				errors[FIELDS.NB_RANGS.name] = { id: 'global.errors.mandatory' }
			}
		} else {
			if (!values[FIELDS.DENSITE.name]) {
				errors[FIELDS.DENSITE.name] = { id: 'global.errors.mandatory' }
			}
		}
	}

	// Validation des timelines des steps
	if (!!values[FIELDS.TYPE_ITK.name]) {
		const steps = values[FIELDS.TYPE_ITK.name] === TYPE_ITK_BO.SEMID ? STEPS_SEMID : STEPS_SEMIC

		steps.forEach((step, index) => {
			if (!values[step]) {
				errors[step] = { id: 'global.errors.mandatory' }
			} else if (Number(values[step]) <= Number(values[steps[index - 1]] || 0)) {
				errors[step] = { id: `${formattedMessageBase}.errorDebutStep` }
			}
		})
	}

	return errors
}

const ItkForm = ({
	isCreation,
	fromGroupeCulture,
	typeSurfaceList,
	zoneSurfaceList,
	typeSurfaceSelected,
	typeItkSelected,
	debutRecolte,
	dureeRecolte,
	itkDesactive,
	classes,
	handleSubmit,
	change
}) => {
	const resetConditionnalField = () => {
		change(FIELDS.NB_RANGS.name, null)
		change(FIELDS.ESPACEMENT.name, null)
		change(FIELDS.DENSITE.name, null)
	}

	return (
		<form onSubmit={handleSubmit}>
			{!fromGroupeCulture && <Grid container className={classes.row}>
				{ itkDesactive && Object.entries(itkDesactive)?.some(([key, value]) => value) && <Grid container item alignItems={"center"} className={classes.alerteBar}>
					<ErrorIcon className={classes.alerteIcon}/>
					<Typography>
						<FormattedMessage id={`bo.cultureItk.alerteBar`}  values={{
							campagnes: Object.entries(itkDesactive)?.filter(([key, value]) => value).map(([key]) => key.split('_')[0]).join(', ')
						}}/>
					</Typography>
				</Grid>}
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NOM_ITK.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NOM_ITK.name}`} />}
						fromBo
						inputProps={{
							maxLength: FIELDS.NOM_ITK.size
						}}
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NUM_ITK.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NUM_ITK.name}`} />}
						fromBo
						type="number"
					/>
				</Grid>
			</Grid>}
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.DEBUT_SEMIS.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_SEMIS.name}`} />}
						fromBo
						type="number"
						format={formatInteger}
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NB_SEMAINES_SEMIS.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NB_SEMAINES_SEMIS.name}`} />}
						fromBo
						type="number"
						format={formatInteger}
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NB_SEMAINES_SEMIS.name}Adornment`} />}
					/>
				</Grid>
			</Grid>
			{fromGroupeCulture ?
				<Grid container className={classes.row}>
					<Grid item xs={6} className={classes.field}>
						<Field
							name={FIELDS.TIMELINE_ITK.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.TIMELINE_ITK.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
						/>
					</Grid>
				</Grid>
				:
				<Grid container className={classes.row}>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.ZONE.name}
							component={Select}
							multiple
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.ZONE.name}`} />}
							fromBo
						>
							{
								zoneSurfaceList.map(zone => (
									<MenuItem key={`zone-${zone.code}`} value={zone.code} classes={{ selected: classes.menuItem }}>
										{zone.label}
									</MenuItem>
								))
							}
						</Field>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.SURFACE.name}
							component={Select}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.SURFACE.name}`} />}
							fromBo
							onChange={resetConditionnalField}
						>
							{typeSurfaceList.map(typeSurface => <MenuItem key={`typeSurface-${typeSurface.code}`} value={typeSurface.code}>
								{typeSurface.label}
							</MenuItem>)}
						</Field>
					</Grid>
				</Grid>
			}
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.SAISON.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.saisonName`} />}
						fromBo
					>
						{Object.entries(Season).map(([value]) =>
							<MenuItem key={`season-${value}`} value={value}>
								{<FormattedMessage id={`${formattedMessageBase}.fields.itk.saison.${value.toLowerCase()}`} />}
							</MenuItem>
						)}
					</Field>
				</Grid>
				<Grid item className={classnames(classes.field, classes.checkbox, classes.copy)}>
					<Field
						name={FIELDS.VOILE_THERMIQUE.name}
						component={Checkbox}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.VOILE_THERMIQUE.name}`} />}
					/>
				</Grid>
			</Grid>
			<Grid container className={classes.row}>
				<Grid item xs={6} className={classes.field}>
					{typeSurfaceSelected === TYPE_PLANCHE.PERMANENTE ? <Field
						name={FIELDS.NB_RANGS.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NB_RANGS.name}`} />}
						fromBo
						type="number"
						format={formatInteger}
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NB_RANGS.name}Adornment`} />}
					/> : <Field
						name={FIELDS.DENSITE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DENSITE.name}`} />}
						fromBo
						type="number"
						adornment="m²"
					/>}
				</Grid>
				{typeSurfaceSelected === TYPE_PLANCHE.PERMANENTE && <Grid item className={classes.field}>
					<Field
						name={FIELDS.ESPACEMENT.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.ESPACEMENT.name}`} />}
						fromBo
						type="number"
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.ESPACEMENT.name}Adornment`} />}
					/>
				</Grid>}
			</Grid>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NB_GRAINES.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.NB_GRAINES.name}`} />}
						fromBo
						type="number"
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.PROD_THEO.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.PROD_THEO.name}`} />}
						fromBo
						type="number"
						adornment={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.PROD_THEO.name}Adornment`} />}
					/>
				</Grid>
			</Grid>
			<Grid container direction="column" alignItems="flex-start" className={classnames(classes.title, classes.row)}>
				<FormattedMessage id={`${formattedMessageBase}.titleSteps`} />
			</Grid>
			{!fromGroupeCulture && <Grid container className={classes.row}>
				<Grid xs={6} item className={classes.field}>
					<Field
						name={FIELDS.TYPE_ITK.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.TYPE_ITK.name}`} />}
						fromBo
						disabled={!isCreation}
						onChange={(ev, value) => {
							change(FIELDS.DEBUT_PREPARATION.name, value === TYPE_ITK_BO.SEMID ? '0' : '')
							change(FIELDS.DEBUT_SEMISC.name, value === TYPE_ITK_BO.SEMIC ? '0' : '')
						}}
					>
						{Object.values(TYPE_ITK_BO).map(typeItk => <MenuItem key={`typeItk-${typeItk}`} value={typeItk}>
							<FormattedMessage id={`enums.typeItkBo.${typeItk}`} />
						</MenuItem>)}
					</Field>
				</Grid>
			</Grid>}
			{!!typeItkSelected && <>
				{typeItkSelected === TYPE_ITK_BO.SEMIC && <Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_SEMISC.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_SEMISC.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
							input={{
								value: 0
							}}
							disabled
						/>
					</Grid>
				</Grid>}
				<Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_PREPARATION.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_PREPARATION.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
							disabled={typeItkSelected === TYPE_ITK_BO.SEMID}
						/>
					</Grid>
				</Grid>
				{typeItkSelected === TYPE_ITK_BO.SEMIC && <Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_IMPLANTATION.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_IMPLANTATION.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
						/>
					</Grid>
				</Grid>}
				{typeItkSelected === TYPE_ITK_BO.SEMID && <Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_SEMISD.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_SEMISD.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
						/>
					</Grid>
				</Grid>}
				<Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DEBUT_RECOLTE.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DEBUT_RECOLTE.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
							onChange={(ev, val) => {
								if (dureeRecolte && val) {
									change(FIELDS.FIN_RECOLTE.name, (Number(dureeRecolte) + Number(val)))
								} else {
									change(FIELDS.FIN_RECOLTE.name, 0)
								}
							}}
						/>
					</Grid>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.DUREE_RECOLTE.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.DUREE_RECOLTE.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
							onChange={(ev, val) => {
								if (debutRecolte && val) {
									change(FIELDS.FIN_RECOLTE.name, (Number(debutRecolte) + Number(val)))
								} else {
									change(FIELDS.FIN_RECOLTE.name, 0)
								}
							}}
						/>
					</Grid>
				</Grid>
				<Grid container className={classes.row}>
					<Grid xs={6} item className={classes.field}>
						<Field
							name={FIELDS.FIN_RECOLTE.name}
							component={Input}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.itk.${FIELDS.FIN_RECOLTE.name}`} />}
							fromBo
							type="number"
							format={formatInteger}
							disabled
						/>
					</Grid>
				</Grid>
			</>}
		</form>
	)
}

ItkForm.propTypes = {
	typeSurfaceList: PropTypes.array,
	zoneSurfaceList: PropTypes.array,
	isCreation: PropTypes.bool,
	noDeleteOnUnmout: PropTypes.bool,
	formName: PropTypes.string,
	fromGroupeCulture: PropTypes.bool,
	typeSurfaceSelected: PropTypes.string,
	typeItkSelected: PropTypes.string,
	debutRecolte: PropTypes.string,
	dureeRecolte: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	itkDesactive: PropTypes.object,
	change: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state, { formName, noDeleteOnUnmout }) => {
	const formSelector = formValueSelector(formName)

	return {
		form: formName,
		typeSurfaceSelected: formSelector(state, FIELDS.SURFACE.name),
		typeItkSelected: formSelector(state, FIELDS.TYPE_ITK.name),
		debutRecolte: formSelector(state, FIELDS.DEBUT_RECOLTE.name),
		dureeRecolte: formSelector(state, FIELDS.DUREE_RECOLTE.name),
		campagnes: formSelector(state, FIELDS.CAMPAGNES.name),
		destroyOnUnmount: !noDeleteOnUnmout
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	injectTypeSurfaceValueList,
	injectZoneSurfaceValueList,
	withStyles(styles)
)(ItkForm)
