import React from 'react'
import {DialogContext, Popin} from '../dialog'
import {ACTION_RESET, ACTION_UPDATE} from './service/action'
import {initialState, reducer} from './service/reducer'


const DialogProvider = ({ children, fromBo, textButton }) => {
	const [popinState, popinDispatch] = React.useReducer(reducer, initialState)

	const updatePopin = React.useCallback((title, content, actionValider, type, labelClose, titleIcon, isWidthLg) => popinDispatch({
		type: ACTION_UPDATE, payload: {
			title, content, actions: actionValider, type, labelClose, titleIcon, isWidthLg
		}
	}), [])
	const resetPopin = React.useCallback(() => popinDispatch({ type: ACTION_RESET }), [])

	const [openPopin, setOpenPopin] = React.useState(false)

	const openDialog = (title, content, actionValider, type, labelClose, titleIcon, isWidthLg) => {
		updatePopin(title, content, actionValider, type, labelClose, titleIcon, isWidthLg)
		setOpenPopin(true)
	}

	const closeDialog = () => {
		resetPopin()
		setOpenPopin(false)
	}
	return (
		<DialogContext.Provider value={{ ...popinState, openDialog, closeDialog }}>
			{children}
			<Popin open={openPopin} fromBo={fromBo} textButton={textButton}/>
		</DialogContext.Provider>
	)
}

export default DialogProvider