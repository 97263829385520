import PropTypes from 'prop-types'
import React from 'react'
import { dataTableActions, dataTableValueSelectors, getStyles } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NoResultPage from '../../../../components/NoResultPage'
import { withStyles } from '@material-ui/core'
import FormattedMessageWithBold from '../../../../components/FormattedMessageWithBold'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { colors } from '../../../../utils/constants'
import classnames from 'classnames'
import { convertMinToHours } from '../../../../utils/utils'
import SpringDataTableWithLoader from '../../../../components/layout/SpringDataTableWithLoader'
import Typography from '@material-ui/core/Typography'

const styles = () => getStyles({
	table: {
		marginLeft: 97,
		width: '85%',
		'& table': {
			width: '100%',
			borderSpacing: 0,
			backgroundColor: colors.backgroundSuiviTacheAvancement,
			'& th:first-child': {
				borderLeft: '1px solid darkgrey'
			},
			'& th': {
				padding: 10
			},
			'& th:last-child': {
				borderRight: '1px solid darkgrey'
			}
		}
	},
	divTable: {
		'& .MuiTableCell-root:first-child': {
			display: 'none'
		},
		'& .MuiTableRow-head': {
			backgroundColor: colors.backgroundSuiviTacheAvancement
		},
		'& .MuiTableCell-stickyHeader': {
			backgroundColor: '#F5F2F8'
		},
		'& .MuiTableRow-root': {
			height: 30
		},
		'& .MuiTableCell-root': {
			borderLeft: '1px solid darkgrey'
		},
		'& .MuiTableCell-root:last-child': {
			borderRight: '1px solid darkgrey'
		}
	},
	column20: {
		width: '20%'
	},
	column10: {
		width: '10%'
	},
	column8: {
		width: '8%'
	},
	column7: {
		width: '7%'
	},
	column4: {
		width: '4%'
	},
	column3: {
		width: '3%'
	},
	centeredCell: {
		textAlign: 'center'
	},
	red: {
		color: colors.error
	},
	orange: {
		color: colors.warning
	},
	green: {
		color: colors.valid
	},
	lightGreen: {
		color: colors.tacheEnCoursOuTerminee
	}
})

export const SUIVI_RESSOURCES_TABLE = 'SuiviRessourcesTable'

const SuiviRessourcesTable = ({
	classes,
	selectedRows,
	resetSelectedRows,
	setIdUserSelectionne
}) => {
	const today = React.useMemo(() => moment().format('YYYY-MM-DD'), [])

	React.useEffect(() => {
		if (selectedRows && selectedRows.length) {
			setIdUserSelectionne(selectedRows[0].user.id)
			resetSelectedRows()
		}
	}, [selectedRows])

	return (
		<>
			<div className={classes.table}>
				<table>
					<th className={classes.column20}><FormattedMessage id="suiviAvancement.suiviRessources.header.ressources" /></th>
					<th className={classes.column20} colspan="2"><FormattedMessage id="suiviAvancement.suiviRessources.header.tachePlanifiees" /></th>
					<th className={classes.column20} colspan="2"><FormattedMessage id="suiviAvancement.suiviRessources.header.tacheARealiser" /></th>
					<th className={classes.column20} colspan="3"><FormattedMessage id="suiviAvancement.suiviRessources.header.tachesEnCours" /></th>
					<th className={classes.column20} colspan="4"><FormattedMessage id="suiviAvancement.suiviRessources.header.tachesTerminees" /></th>
				</table>
			</div>
			<div className={classes.divTable}>
				<SpringDataTableWithLoader
					nom={SUIVI_RESSOURCES_TABLE}
					apiUrl="/suiviTaches/ressources"
					defaultPageSize={50}
					filters={{ jour: today }}
					tableProps={{
						stickyHeader: true,
						className: classes.table
					}}
					selectable
					noResultFragment={<><br /><br /><br /><NoResultPage/></>}
					headers={[
						{
							key: 'ressources',
							name: "",
							tableHeaderColumnProps: {
								className: classes.column20
							},
							render: row => <FormattedMessageWithBold
									id="suiviAvancement.suiviRessources.ressource"
									values={{
										alias: row.user.preferences.ALIAS,
										prenom: row.user.firstname,
										nom: row.user.lastname,
										role: <Typography style={{fontStyle: 'italic'}} >
												<FormattedMessage id={`enums.roles.` + row?.user?.roles[0]?.code?.toUpperCase()} />
											</Typography>
									}}
							/>
						}, {
							key: 'tachesPlanifiees.nb',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.nbTotal"/>,
							tableHeaderColumnProps: {
								className: classnames(classes.column10, classes.centeredCell)
							},
							render: row => row.tachesPlanifiees.length
						}, {
							key: 'tachesPlanifiees.charge',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.chargeTotale" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column10, classes.centeredCell)
							},
							render: row => convertMinToHours(row.tachesPlanifiees.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0))
						}, {
							key: 'tachesARealiser.nb',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.nb" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column10, classes.centeredCell)
							},
							render: row => row.tachesARealiser.length
						}, {
							key: 'tachesARealiser.charge',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.chargeTotale" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column10, classes.centeredCell)
							},
							render: row => convertMinToHours(row.tachesARealiser.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0))
						}, {
							key: 'tachesEnCours.nb',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.nb" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column4, classes.centeredCell)
							},
							render: row => row.tachesEnCours.length
						}, {
							key: 'tachesEnCours.charge',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.chargeTotale" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column8, classes.centeredCell)
							},
							render: row => convertMinToHours(row.tachesEnCours.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0))
						}, {
							key: 'tachesEnCours.temps',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.temps" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column8, classes.centeredCell)
							},
							render: row => convertMinToHours(row.tachesEnCours.map(tache => parseFloat(tache.cumulTemps)).reduce((t1, t2) => t1 + t2, 0))
						}, {
							key: 'tachesTerminees.nb',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.nb" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column3, classes.centeredCell)
							},
							render: row => row.tachesTerminees.length
						}, {
							key: 'tachesTerminees.pourcentage',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.pourcentage" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column3, classes.centeredCell)
							},
							render: row => {
								const tempsAPasse = row.tachesTerminees.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0)
								const tempsPlanifieAll = row.tachesPlanifiees.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0)
								return <FormattedMessage
									id="suiviAvancement.suiviRessources.pourcentage"
									values={{ value: Math.trunc(tempsAPasse / Math.max(tempsPlanifieAll, 1) * 100) }}
								/>
							}
						}, {
							key: 'tachesTerminees.charge',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.chargePlanifiee" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column7, classes.centeredCell)
							},
							render: row => convertMinToHours(row.tachesTerminees.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0))
						}, {
							key: 'tachesTerminees.temps',
							name: <FormattedMessage id="suiviAvancement.suiviRessources.header.temps" />,
							tableHeaderColumnProps: {
								className: classnames(classes.column7, classes.centeredCell)
							},
							render: row => {
								const cumulTempsPasse = row.tachesTerminees.map(tache => parseFloat(tache.cumulTemps)).reduce((t1, t2) => t1 + t2, 0)
								const cumulTempsPlanifie = row.tachesTerminees.map(tache => parseFloat(tache.tempsPersonne)).reduce((t1, t2) => t1 + t2, 0)
								return <span
									className={classnames({
										[classes.red]: cumulTempsPasse - cumulTempsPlanifie > 30,
										[classes.orange]: cumulTempsPasse - cumulTempsPlanifie < 30 && cumulTempsPasse - cumulTempsPlanifie > 0,
										[classes.green]: cumulTempsPlanifie - cumulTempsPasse > 30,
										[classes.lightGreen]: cumulTempsPlanifie - cumulTempsPasse < 30 && cumulTempsPlanifie - cumulTempsPasse > 0
									})}
								>
								{convertMinToHours(cumulTempsPasse)}
							</span>
							}
						}
					]}
				/>
			</div>
		</>
	)
}

const mapStateToProps = (state) => ({
	selectedRows: dataTableValueSelectors.getDataTableSelectedRows(state)(SUIVI_RESSOURCES_TABLE)
})

const actions = {
	resetSelectedRows: () => dataTableActions.selectRows(SUIVI_RESSOURCES_TABLE, [])
}

SuiviRessourcesTable.propTypes = {
	classes: PropTypes.object,
	selectedRows: PropTypes.array,
	resetSelectedRows: PropTypes.func,
	setIdUserSelectionne: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(SuiviRessourcesTable)
