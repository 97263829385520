import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { colors } from '../../../../../utils/constants'
import PropTypes from 'prop-types'
import { toI18N } from '../../../../../utils/utils'

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '12px'
	},
	header: {
		rowGap: '10px',
		padding: '10px 13px'
	},
	titleContainer: {
		padding: '0px 12px',
		columnGap: '12px'
	},
	titleText: {
		lineHeight: '25px',
		fontSize: 18,
		fontWeight: '600',
		color: colors.primary
	}
})

/**
 * Title
 * @returns {JSX.Element}
 */
const Title = ({ classes, actionBlock }) => {
	const Element = actionBlock.icon
	return (
		<Grid item container direction="row" alignItems="center" className={classes.titleContainer}>
			<Grid item>
				<Element height={24} width={24} />
			</Grid>
			<Grid item>
				<Typography className={classes.titleText}>
					{toI18N(actionBlock.label)}
				</Typography>
			</Grid>
		</Grid>
	)
}

Title.propTypes = {
	classes: PropTypes.object,
	actionBlock: PropTypes.object
}

export default compose(
	withStyles(styles)
)(Title)