import PropTypes from 'prop-types'
import React from 'react'
import { dataTableActions, dataTableValueSelectors } from 'isotope-client'
import { compose } from 'redux'
import moment from 'moment'
import { injectSuiviDeTache } from '../../../gestionTache/injectors/suiviDeTacheInjector'
import { connect } from 'react-redux'
import { SUIVI_RESSOURCE_TABLE } from '../SuiviRessourceTable'

const DataTableRessourceListener = ({
	suiviDeTache,
	selectedRows,
	resetSelectedRows,
	idRessource,
	refreshTable
}) => {
	const today = React.useMemo(() => moment().format('YYYY-MM-DD'), [])

	React.useEffect(() => {
		if (selectedRows && selectedRows.length) {
			suiviDeTache(selectedRows[0], today, true, idRessource, refreshTable)
			resetSelectedRows()
		}
	}, [selectedRows])

	return (
		<></>
	)
}

const mapStateToProps = (state) => ({
	selectedRows: dataTableValueSelectors.getDataTableSelectedRows(state)(SUIVI_RESSOURCE_TABLE)
})

const actions = {
	resetSelectedRows: () => dataTableActions.selectRows(SUIVI_RESSOURCE_TABLE, [])
}

DataTableRessourceListener.propTypes = {
	selectedRows: PropTypes.array,
	resetSelectedRows: PropTypes.func,
	suiviDeTache: PropTypes.func
}

export default compose(
	injectSuiviDeTache,
	connect(mapStateToProps, actions)
)(DataTableRessourceListener)
