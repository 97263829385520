import React from 'react'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import MobileContextProvider from './MobileProvider'
import MobileComponents from './MobileComponents'

const Mobile = () => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.MOBILE), [])

	return (
		<MobileContextProvider>
			<MobileComponents/>
		</MobileContextProvider>
	)
}

export default Mobile
