import React from 'react'
import PropTypes from 'prop-types'

const GrowingIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 512 512"
	>
		<path
			fill="#00aaf2"
			d="M501 267c0 73.686-32.53 139.774-84.011 184.687-2.268 1.979-5.999-4.936-8.339-3.04-37.265 30.177-83.561 49.642-134.203 53.706-3.858.31-6.316 9.382-10.222 9.511-2.73.09-5.472.135-8.224.135C120.69 512 11 402.31 11 267S120.69 22 256 22s245 109.69 245 245z"
		/>
		<path
			fill="#00c1fb"
			d="M453.721 267c0 110.457-89.543 200-200 200s-200-89.543-200-200 89.543-200 200-200 200 89.543 200 200z"
		/>
		<path
			fill="#c9f0ff"
			d="M97.991 371H52.885c-5.523 0-10-4.477-10-10V71c0-5.523 4.477-10 10-10h45.106c5.523 0 10 4.477 10 10v290c0 5.523-4.478 10-10 10z"
		/>
		<path
			fill="#fff5f5"
			d="M406.541 371H92.383c-5.523 0-10-4.477-10-10V71c0-5.523 4.477-10 10-10h314.158c5.523 0 10 4.477 10 10v290c0 5.523-4.477 10-10 10z"
		/>
		<path
			fill="#ff4343"
			d="M84.288 91H40c-5.523 0-10-4.477-10-10V21c0-5.523 4.477-10 10-10h44.288c-5.523 0-10 4.477-10 10v60c0 5.523 4.477 10 10 10z"
		/>
		<path
			fill="#ff5757"
			d="M419.426 91H83.305c-5.523 0-10-4.477-10-10V21c0-5.523 4.477-10 10-10h336.121c5.523 0 10 4.477 10 10v60c0 5.523-4.478 10-10 10z"
		/>
		<path
			fill="#00429c"
			d="M366.435 7.5v40c0 4.143-3.358 7.5-7.5 7.5s-7.5-3.357-7.5-7.5v-40c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5zM130.748 0a7.5 7.5 0 0 0-7.5 7.5v40c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-40a7.5 7.5 0 0 0-7.5-7.5zm-30.257 0a7.5 7.5 0 0 0-7.5 7.5v40c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-40a7.5 7.5 0 0 0-7.5-7.5zm228.187 0a7.5 7.5 0 0 0-7.5 7.5v40c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-40a7.5 7.5 0 0 0-7.5-7.5z"
		/>
		<path
			fill="#4bcd5c"
			d="M221.732 166v20c0 5.523-4.477 10-10 10h-44.038c-5.523 0-10-4.477-10-10v-20c0-5.523 4.477-10 10-10h44.038c5.523 0 10 4.477 10 10z"
		/>
		<path
			fill="#91de93"
			d="M371.732 196h-44.039c-5.523 0-10-4.477-10-10v-20c0-5.523 4.477-10 10-10h44.039c5.523 0 10 4.477 10 10v20c0 5.523-4.477 10-10 10zm-70-10v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 60v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 0v-20c0-5.523-4.477-10-10-10H87.694c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.522 0 9.999-4.477 9.999-10z"
		/>
		<path
			fill="#64dcfc"
			d="M141.732 126c0 5.523-4.477 10-10 10H87.694c-5.523 0-10-4.477-10-10s4.477-10 10-10h44.038c5.523 0 10 4.477 10 10z"
		/>
		<path
			fill="#c9f0ff"
			d="M371.732 256h-44.039c-5.523 0-10-4.477-10-10v-20c0-5.523 4.477-10 10-10h44.039c5.523 0 10 4.477 10 10v20c0 5.523-4.477 10-10 10zm-70-10v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm80 60v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 0v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 0v-20c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.523 0 10-4.477 10-10z"
		/>
		<path
			fill="#9ae7fd"
			d="M371.732 136h-44.039c-5.523 0-10-4.477-10-10s4.477-10 10-10h44.039c5.523 0 10 4.477 10 10s-4.477 10-10 10zm-70-10c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10s4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 0c0-5.523-4.477-10-10-10h-44.039c-5.523 0-10 4.477-10 10s4.477 10 10 10h44.039c5.523 0 10-4.477 10-10zm-80 60v-20c0-5.523-4.477-10-10-10H87.694c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.522 0 9.999-4.477 9.999-10zm0 120v-20c0-5.523-4.477-10-10-10H87.694c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h44.039c5.522 0 9.999-4.477 9.999-10z"
		/>
		<path
			fill="#00915d"
			d="M414.561 237.501a7.5 7.5 0 0 1-7.5 7.5c-35.988 0-65.266 29.278-65.266 65.267 0 4.143-3.358 7.5-7.5 7.5s-7.5-3.357-7.5-7.5c0-44.259 36.007-80.267 80.266-80.267a7.5 7.5 0 0 1 7.5 7.5z"
		/>
		<path
			fill="#007b4e"
			d="M334.67 317.719a7.5 7.5 0 0 1-8.304-6.598c-3.676-32.131-18.811-61.083-30.094-66.97a7.5 7.5 0 0 1 6.939-13.299c19.352 10.097 34.547 47.874 38.058 78.563a7.5 7.5 0 0 1-6.599 8.304z"
		/>
		<path
			fill="#ffb509"
			d="M381.732 346.201c0 13.696-12.67 24.799-28.3 24.799h-98.905c-15.629 0-28.3-11.103-28.3-24.799 0-7.585 3.886-14.375 10.008-18.923 1.784-1.326 2.752-3.447 2.748-5.67v-.038c0-13.696 12.67-24.799 28.299-24.799 2.102 0 4.15.201 6.121.582 2.609.504 5.308-.418 6.901-2.545 5.057-6.751 13.772-11.208 23.675-11.208s18.619 4.458 23.675 11.208c1.593 2.127 4.291 3.049 6.901 2.545a32.242 32.242 0 0 1 6.121-.582c15.629 0 28.3 11.103 28.3 24.799v.038c-.004 2.222.964 4.344 2.748 5.67 6.122 4.548 10.008 11.338 10.008 18.923z"
		/>
		<path
			fill="#ffc143"
			d="M435.504 346.201c0 13.696-12.67 24.799-28.3 24.799H308.3c-15.629 0-28.3-11.103-28.3-24.799 0-7.585 3.886-14.375 10.008-18.923 1.784-1.326 2.752-3.447 2.748-5.67v-.038c0-13.696 12.67-24.799 28.299-24.799 2.102 0 4.15.201 6.121.582 2.609.504 5.308-.418 6.901-2.545 5.057-6.751 13.772-11.208 23.675-11.208s18.618 4.458 23.675 11.208c1.593 2.127 4.292 3.049 6.901 2.545a32.242 32.242 0 0 1 6.121-.582c15.629 0 28.299 11.103 28.299 24.799v.038c-.004 2.222.964 4.344 2.748 5.67 6.122 4.548 10.008 11.338 10.008 18.923z"
		/>
		<path
			fill="#c5573a"
			d="M416.988 451.687c-33.532 29.254-75.103 49.524-120.964 57.059a4.858 4.858 0 0 1-5.269-2.93 10.102 10.102 0 0 1-.546-1.778L256.317 341.28c-1.293-6.21 3.447-12.039 9.79-12.039H430.19c6.343 0 11.083 5.829 9.79 12.039z"
		/>
		<path
			fill="#a94b30"
			d="M296.452 508.676a246.022 246.022 0 0 1-32.227 3.188 10 10 0 0 1-8.146-7.826L222.185 341.28c-1.293-6.21 3.447-12.039 9.79-12.039h38.029c-6.343 0-11.083 5.829-9.79 12.039l33.893 162.758a9.963 9.963 0 0 0 2.345 4.638z"
		/>
		<path
			fill="#c5573a"
			d="M270 353.103h-51.556c-5.523 0-10-4.477-10-10V320c0-5.523 4.477-10 10-10H270c5.523 0 10 4.477 10 10v23.103c0 5.523-4.477 10-10 10z"
		/>
		<path
			fill="#d8694b"
			d="M443.721 353.103H260c-5.523 0-10-4.477-10-10V320c0-5.523 4.477-10 10-10h183.721c5.523 0 10 4.477 10 10v23.103c0 5.523-4.478 10-10 10z"
		/>
		<path
			fill="#00b327"
			d="M316.24 254.749c-7.81 7.811-25.223 3.062-38.891-10.606s-18.417-31.081-10.607-38.891 25.223-3.062 38.891 10.607 18.417 31.079 10.607 38.89z"
		/>
		<path
			fill="#00c53b"
			d="M417.498 255.199c-22.502 9.272-45.039 6.365-50.337-6.493s8.648-30.799 31.15-40.071 45.039-6.365 50.337 6.494-8.648 30.798-31.15 40.07z"
		/>
	</svg>
)


GrowingIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

GrowingIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default GrowingIcon