import React from 'react'
import PropTypes from 'prop-types'

const CultureOutlinedIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M499.837 150.271a14.999 14.999 0 00-16.764 9.159l-30.095 75.238c-13.685 34.21-34.396 65.835-60.734 93.021a552.667 552.667 0 0120.02-115.015l38.158-133.552a14.999 14.999 0 00-25.811-13.882l-43.334 50.556a574.706 574.706 0 00-53.439 73.347c-5.532-45.495-17.378-85.234-27.763-113.426-15.303-41.54-30.547-67.315-31.188-68.392a14.999 14.999 0 00-25.775-.001c-.641 1.077-15.885 26.852-31.188 68.392-10.385 28.192-22.231 67.931-27.763 113.426a574.706 574.706 0 00-53.439-73.347L87.389 65.238A14.999 14.999 0 0061.578 79.12l38.158 133.552a552.667 552.667 0 0120.02 115.015c-26.338-27.185-47.049-58.811-60.734-93.021l-30.095-75.238a15.002 15.002 0 00-16.764-9.159A15.004 15.004 0 000 165v120c0 60.143 23.569 116.988 66.366 160.064C109.25 488.229 165.942 512 226 512h60c60.058 0 116.75-23.771 159.634-66.936C488.431 401.988 512 345.143 512 285V165a15 15 0 00-12.163-14.729zM30 285v-42.11c22.146 55.355 56.324 99.578 99.507 134.125 39.837 31.87 69.6 52.329 101.169 104.986C111.544 482 30 388.899 30 285zm120.917 70.724a582.66 582.66 0 00-22.335-151.292l-19.3-67.548c72.47 85.194 117.139 190.711 128.707 302.094-28.563-38.611-51.624-54.895-87.072-83.254zm83.903-61.848a591.445 591.445 0 00-23.8-55.835c.438-81.889 28.073-153.754 44.996-190.131 16.927 36.319 44.528 108.054 44.964 190.13a591.575 591.575 0 00-23.8 55.836A593.558 593.558 0 00256 365.005a593.375 593.375 0 00-21.18-71.129zm167.899-156.993l-19.3 67.549a582.66 582.66 0 00-22.335 151.292c-35.512 28.41-58.417 44.519-87.072 83.254 11.567-111.383 56.236-216.9 128.707-302.095zM482 285c0 103.581-81.222 197-200.676 197 33.49-55.863 65.326-75.633 104.062-107.338.082-.066.164-.131.244-.198 42.361-34.729 74.958-78.062 96.37-131.574V285z"
				fill={color}
			/>
		</svg>
	)
}

CultureOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

CultureOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#FFF'
}

export default CultureOutlinedIcon
