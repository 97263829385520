import * as fermeApi from './fermeApi'

export const GET_FERME_LIST_SUCCESS = 'GET_FERME_LIST_SUCCESS'
export const GET_FERME_LIST_ERROR = 'GET_FERME_LIST_ERROR'

export const getFermeList = () => dispatch => {
	return fermeApi.getFermeList()
		.then((liste) => {
			dispatch({
				type: GET_FERME_LIST_SUCCESS,
				payload: liste
			})
			return liste
		})
		.catch(() => dispatch({
			type: GET_FERME_LIST_ERROR
		}))
}

export const updateFermeList = (liste) => dispatch => new Promise((resolve) => {
	dispatch({
		type: GET_FERME_LIST_SUCCESS,
		payload: liste
	})
	return resolve(liste)
})

