import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import Autocomplete from '../../../../../../components/form/Autocomplete'
import { Field, formValueSelector } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import PreviousCulture from '../../PreviousCulture'
import { fields, getAutocompleteOptions } from '../NouvelleCulture'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'

const styles = () => getStyles({
	title: {
		marginBottom: 15,
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		verticalAlign: 'middle',
		minWidth: 35
	},
	blocText: {
		marginTop: 20,
		fontSize: 14,
		marginLeft: 10,
		marginBottom: 10
	},
	ligneText: {
		marginBottom: 20
	},
	important: {
		fontWeight: 'bold',
		marginBottom: 8
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const PlancheForm = ({ plancheValue, editForm = false, planches, resetItkForm, classes }) => {

	// Information sur les éléments sélectionnés par autocomplete
	const plancheSelected = planches && plancheValue && planches.find(planche => planche.id === plancheValue)

	return (
		<React.Fragment>
			<Grid>
				<Typography variant="h4" className={classes.title}>
					<ListItemIcon className={classes.icon}>
						<PlaceIcon />
					</ListItemIcon>
					<FormattedMessage id={editForm ? `${formattedMessageMenuBase}.localisation.editTitle` : `${formattedMessageMenuBase}.localisation.title`} />
				</Typography>
			</Grid>
			<Grid>
				<Field
					name={fields.LOCALISATION}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageMenuBase}.localisation.labelField`} />}
					options={getAutocompleteOptions(planches)}
					onChange={resetItkForm && !editForm && resetItkForm}
				/>
			</Grid>
			{plancheSelected && <Grid className={classes.blocText}>
				<Grid className={classes.important}><ZoneSurface planche={plancheSelected} /></Grid>
				<Grid className={classes.ligneText}><TypeSurface planche={plancheSelected} /></Grid>
				<Grid>
					<PreviousCulture campagneDateDebut={moment().format('YYYY-MM-DD')} plancheId={plancheSelected.id} />
				</Grid>
			</Grid>}
		</React.Fragment>
	)
}

PlancheForm.propTypes = {
	resetItkForm: PropTypes.func,
	planches: PropTypes.array
}

const mapStateToProps = (state, props) => {
	const selector = formValueSelector(props.formName)
	return {
	plancheValue: selector(state, fields.LOCALISATION)
}}

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(PlancheForm)
