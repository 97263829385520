import React from 'react'

export const PAGE = {
	PLANNING: 'planning',
	LISTE: 'liste',
	GESTION_TACHES: 'gestionTache',
	GESTION_RESSOURCE: 'gestionRessource',
	TDB: 'tdb',
	SUIVI_CONNEXIONS: 'suiviConnexions',
	SUIVI_AVANCEMENT: 'suiviAvancement',
	MOBILE: 'mobile',
	BESOINS_PRODUCTION: 'besoinsProduction',
	TOUR_PLAINE: 'tourPlaine',
	BO: 'bo'
}

const pageContextType = {
	page: null,
	setPage: () => {}
}

export const PageContext = React.createContext(pageContextType)

export const usePageContext = () => React.useContext(PageContext)

const PageContextProvider = ({ children }) => {

	const [page, setPage] = React.useState(null)

	return (
		<PageContext.Provider value={{page, setPage}}>
			{children}
		</PageContext.Provider>
	)
}

export default (PageContextProvider)