import React from 'react'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { getSuiviAvancementRessource } from '../suiviAvancementApi'
import SuiviAvancementBlocs from './SuiviAvancementBlocs'
import SuiviRessourceTable  from './SuiviRessourceTable'
import Button from '../../../../components/Button'

const PageAvancementOneUser = ({ dataSuiviUser, setDataSuiviUser, refreshTable, classes, setIdUserSelectionne, idUserSelectionne, jourActuel }) => {

	React.useEffect(() => {
		getSuiviAvancementRessource(jourActuel.format('YYYY-MM-DD'), idUserSelectionne)
			.then(res => setDataSuiviUser(res))
	}, [idUserSelectionne])

	return (
		<div>
			<div className={classes.containerTitleWithButton}>
				<Button startIcon={<ArrowBackIcon />} type="secondary"
				        onClick={() => {
					        setIdUserSelectionne(null)
				        }} className={classes.backButton}>
					<FormattedMessage id="suiviAvancement.suiviRessources.retour" />
				</Button>
				<Typography className={classes.titleWithButton}>
					<FormattedMessage
						id="suiviAvancement.suiviRessources.title"
						values={{ alias: dataSuiviUser?.user?.preferences?.ALIAS }}
					/>
					<FormattedMessage id={`enums.roles.${dataSuiviUser?.user?.roles[0]?.code?.toUpperCase()}`}/>
				</Typography>
			</div>
			<SuiviAvancementBlocs dataSuivi={dataSuiviUser} />
			<SuiviRessourceTable idRessource={idUserSelectionne} refreshTable={refreshTable} />
		</div>
	)
}

export default PageAvancementOneUser