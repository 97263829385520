import { Grid, MenuItem, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../components/Button'
import MapIcon from '@material-ui/icons/Map'
import DoneOutline from '@material-ui/icons/DoneOutline'
import { useTourPlaineContext } from '../TourPlaineContext'
import Select from '../../../../components/form/Select'
import { Field, reduxForm } from 'redux-form'
import { TOUR_PLAINE_TABS, WEEK_SELECT_FIELD, TOUR_PLAINE_FORM } from '../tool/tourPlaine.constants'
import { useIntl } from 'react-intl'

import StyledTabs from '../../../../components/layout/tab/StyledTabs'
import StyledTab from '../../../../components/layout/tab/StyledTab'

/**
 * Styles
 */
const styles = () => getStyles({
	head: {
		fontSize: 24
	},
	header: {
		alignItems: 'center',
		height: 40,
		columnGap: 64
	},
	action: {
		columnGap: 15
	},
	tabBar: {
		marginTop: 23,
		borderBottom: '0.5px solid #00000033'
	},
	selectContainer: {
		marginBottom: 0,
		'& .MuiSelect-root': {
			backgroundColor: 'transparent'
		},
		'& .MuiInputBase-root': {
			backgroundColor: 'transparent'
		}
	},
	select: {
		paddingTop: 10
	}
})

/**
 * ToolBar for Tour de Plain
 * @param classes
 * @param currentWeek
 * @returns {JSX.Element}
 */
const ToolBar = ({
	classes,
	currentWeek,
	currentTab
}) => {
	const { formatMessage } = useIntl()
	const { setWeek, weekOptions, saveTour, setTab, setActionTab } = useTourPlaineContext()
	const onChange = (_, value) => setWeek(value)

	const currentTabIndex = Object.values(TOUR_PLAINE_TABS).find(tab => tab.code === currentTab).order || 0

	return (
		<Fragment>
			<Grid container>
				<Grid item container alignContent="center" direction="row" className={classes.header}>
					<Grid item xs>
						<Typography variant="h1" className={classes.head}>
							<FormattedMessage id="tourPlaine.toolbar.title" />
						</Typography>
					</Grid>
					<Grid item>
						<Field
							component={Select}
							onChange={onChange}
							name={WEEK_SELECT_FIELD}
							selectClassName={classes.select}
							containerClassName={classes.selectContainer}
						>
							{weekOptions.map(weekOption => (
								<MenuItem value={weekOption.id} key={weekOption.id}>
									{weekOption.label}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid item>
						<Grid container alignContent="center" direction="row" className={classes.action}>
							<Button
								type="secondary"
								startIcon={<MapIcon />}
								onClick={() => {
									setActionTab(undefined)
									setTab(TOUR_PLAINE_TABS.SUIVI_BLOC.code)
								}}
							>
								<FormattedMessage id="tourPlaine.toolbar.action.plan" />
							</Button>
							<Button
								type="primary"
								startIcon={<DoneOutline />}
								onClick={saveTour}
							>
								<FormattedMessage id="tourPlaine.toolbar.action.done" />
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container justify="center" alignContent="center" direction="row" className={classes.tabBar}>
					<StyledTabs value={currentTabIndex} onChange={(_, index) => setTab(Object.values(TOUR_PLAINE_TABS).find(tab => tab.order === index).code)}>
						{
							Object.values(TOUR_PLAINE_TABS).map(tab => <StyledTab key={tab.code} label={formatMessage({ id: `tourPlaine.tabBar.${tab.code}` })} />)
						}
					</StyledTabs>
				</Grid>
			</Grid>
		</Fragment>
	)
}

const mapStateToProps = (state, { currentWeek }) => ({
	initialValues: {
		[WEEK_SELECT_FIELD]: currentWeek
	}
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: TOUR_PLAINE_FORM,
		enableReinitialize: true
	}),
	withStyles(styles)
)(ToolBar)