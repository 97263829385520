import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, withStyles } from '@material-ui/core'
import { colors } from '../../../../../utils/constants'
import CropsContent from './component/CropsContent'
import { useTourPlaineContext } from '../../TourPlaineContext'
import ActionTabs from '../../../../../components/layout/actionTab/ActionTabs'
import { SP_BLOCK_ACTION_TYPE } from './tool/suiviPepiniere.constants'
import TasksContent from './component/TasksContent'
import { injectCultureDetailPanel } from '../../../planning/services/actions/detail/cultureDetailPanel'
import { initAssolementList } from '../../../planning/services/assolement/assolementInjector'
import { injectSurfacePanel } from '../../../planning/services/actions/detail/surfaceDetailPanel'
import { injectEtapeDetailPanel } from '../../../planning/services/actions/detail/etapeDetailPanel'
import { injectCreateCultureGroupPanel } from '../../../planning/services/actions/form/createCultureGroupPanel'

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeBackground,
		marginTop: 12
	},
	header: {
		gap: 24,
		marginBottom: 12
	}
})

/**
 * SuiviPepiniere
 * @returns {JSX.Element}
 */
const SuiviPepiniere = ({ classes }) => {

	const { currentActionTab, setActionTab } = useTourPlaineContext()

	const actionTabs = Object.keys(SP_BLOCK_ACTION_TYPE).map(key => SP_BLOCK_ACTION_TYPE[key])

	const renderHeader = () => {
		return (
			<Grid item container direction="column" justify="center" alignItems="center" className={classes.header}>
				<ActionTabs actions={actionTabs} selection={currentActionTab || SP_BLOCK_ACTION_TYPE.CROPS} handleSelection={setActionTab} />
			</Grid>
		)
	}

	const renderBody = () => {
		switch (currentActionTab?.code) {
			case SP_BLOCK_ACTION_TYPE.TASK.code:
				return <TasksContent />
			case SP_BLOCK_ACTION_TYPE.CROPS.code:
			default:
				return <CropsContent />
		}
	}

	return (
		<div className={classes.root}>
			{renderHeader()}
			{renderBody()}
		</div>)
}

export default compose(
	initAssolementList,
	injectCultureDetailPanel,
	injectSurfacePanel,
	injectEtapeDetailPanel,
	injectCreateCultureGroupPanel,
	withStyles(styles)
)(SuiviPepiniere)