import React, {useRef} from 'react'
import {MenuItem, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import Grid from '@material-ui/core/Grid'
import {getStyles} from 'isotope-client'
import {change, Field, formValueSelector, reduxForm, untouch} from 'redux-form'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import Checkbox from '../../../components/form/Checkbox'
import classnames from 'classnames'
import Typography from '@material-ui/core/Typography'
import {checkMailFormat, parsePositiveFloat} from '../../../utils/utils'
import {colors, PlanView} from "../../../utils/constants";
import FileInput from "../../../components/FileInput";
import Button from "../../../components/Button";
import CloudUpload from '@material-ui/icons/CloudUpload'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Close from '@material-ui/icons/Close'


const styles = () => getStyles({
	field: {
		width: '50%'
	},
	litleField: {
		width: '33%'
	},
	veryLitleField: {
		width: '25%'
	},
	bigField: {
		width: '100%'
	},
	checkbox: {
		paddingLeft: 30,
		display: 'flex',
		alignItems: 'center'
	},
	row: {
		marginTop: 5,
		marginBottom: 15,
		fontFamily: 'Lato'
	},
	fileLoad: {
		display:'flex',
		fontStyle: 'italic',
		alignItems: 'center',
	},
	text: {
		paddingLeft: 10,
		paddingBottom: 10,
		fontSize: 14,
		fontFamily: 'Lato'
	},
	textTitle: {
		paddingLeft: 10,
		paddingTop: 15,
		paddingBottom: 10,
		fontSize: 17,
		fontFamily: 'Lato'
	},
	form: {
		padding: '20px 20px 0px 20px'
	},
	copy: {
		marginTop: 15,
		marginBottom: 15,
		'& .MuiGrid-grid-xs-1': {
			marginRight: 15
		},
		'& label': {
			paddingBottom: 15
		}
	},
	title: {
		paddingLeft: 10,
		marginBottom: 20,
		fontFamily: 'Lato'

	},
	titleText: {
		fontWeight: 'normal',
		fontFamily: 'Lato'
	},
	file: {
		display: 'inline-grid'
	},
	sizeText: {
		paddingRight: 15,
		fontFamily: 'Lato'
	},
	disableField: {
		backgroundColor: colors.input,
	},
	inputDate: {
		"& .MuiFilledInput-input": {
			marginTop: 5
		}
	},
	matriceButtons: {
		margin: 5
	}
})

const formattedMessageBase = 'bo.ferme.form'

const fieldsUpdate = {
	RAISON_SOCIALE: { name: 'raisonSociale', size: 50 },
	SIRET: { name: 'siret', optional: true },
	ADRESSE: { name: 'adresse', size: 150 },
	COMPLEMENT_ADRESSE: { name: 'complementAdresse', optional: true, size: 150 },
	CODE_POSTAL: { name: 'codePostal' },
	VILLE: { name: 'ville', size: 50 },
	TELEPHONE: { name: 'telephone' },
	EMAIL: { name: 'email', size: 150 },
	REPRESENTANT: { name: 'representant', size: 150 },
	MINORATION: { name: 'minoration', positive: true },
	SURFACE_LONGUEUR: { name: 'longueurPlanchePermanente', positive: true },
	SURFACE_LARGEUR: { name: 'largeurPlanchePermanente', positive: true },
	SURFACE_DIMENSION: { name: 'surfacePlanchePermanente', positive: true },
	FILE: { name: 'file', optional: true },
	SAVED_FILE: { name: 'savedFile', optional: true },
	MATRICE_FILE: { name: 'matriceFile', optional: true },
	LUNDI_DEBUT: { name: 'lundiDebut' },
	MARDI_DEBUT: { name: 'mardiDebut' },
	MERCREDI_DEBUT: { name: 'mercrediDebut' },
	JEUDI_DEBUT: { name: 'jeudiDebut' },
	VENDREDI_DEBUT: { name: 'vendrediDebut' },
	SAMEDI_DEBUT: { name: 'samediDebut' },
	DIMANCHE_DEBUT: { name: 'dimancheDebut' },
	ID_IMAGE: { name: 'idImageFerme', optional: true }
}

const fieldsCreation = {
	...fieldsUpdate,
	DONT_COPY_DATA: { name: 'dontCopyData' },
	FERME_TO_COPY: { name: 'fermeToCopy' }
}

const validate = (values, { isCreation }) => {
	const errors = {}
	Object.values(fieldsUpdate).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	})
	Object.values(fieldsUpdate).filter(field => field.size).forEach(field => {
		if (values[field.name] && values[field.name].length > field.size) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.tooLong`, values: { sizeMax: field.size } }
		}
	})
	Object.values(fieldsUpdate).filter(field => field.positive).forEach(field => {
		if (Number.isNaN(Number.parseFloat(values[field.name])) || Number.parseFloat(values[field.name]) < 0) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.wrongFormat` }
		}
	})
	if (values[fieldsUpdate.EMAIL.name] && !checkMailFormat(values[fieldsUpdate.EMAIL.name])) {
		errors[fieldsUpdate.EMAIL.name] = { id: `${formattedMessageBase}.errors.wrongFormat` }
	}
	Object.values(fieldsUpdate).filter(field => !field.optional).forEach(field => {
		if (isCreation && !values[field.name]) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	})
	if (isCreation && !values[fieldsCreation.DONT_COPY_DATA.name]) {
		if (!values[fieldsCreation.FERME_TO_COPY.name]) {
			errors[fieldsCreation.FERME_TO_COPY.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	}
	return errors
}

const FermeForm = ({
	classes,
	fermeList,
	handleSubmit,
	isCreation,
	dontCopyDataValue,
	savedFile,
	change,
	formName,
	longueurValue,
	largeurValue,
	menuSelection,
	untouch,
	handleExport,
	pristine
}) => {
	const fileInputRef = useRef(null);
	const [matriceFile, setMatriceFile] = React.useState()

	const handleDeleteFile = () => {
		setMatriceFile(null)
		change(fieldsUpdate.MATRICE_FILE.name, null)
	};

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const DayInput = ({field}) => {
		return (
			<Grid item className={classnames(classes.veryLitleField, classes.inputDate)}>
				<Field
					name={field}
					component={Input}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${field}`} />}
					fromBo
					type="time"
				/>
			</Grid>
		)
	}

	return (
		<>
			<form onSubmit={handleSubmit} className={classnames({[classes.form]: isCreation})}>
				{
					isCreation &&
					<>
						<Grid container>
							<Grid item>
								<Typography variant="body1" className={classes.text}>
									<FormattedMessage id="bo.ferme.popin.text"/>
								</Typography>
							</Grid>
							<Grid item className={classnames(classes.field, classes.copy)}>
								<Field
									name={fieldsCreation.FERME_TO_COPY.name}
									component={Select}
									label={<FormattedMessage
										id={`${formattedMessageBase}.fields.${fieldsCreation.FERME_TO_COPY.name}`}/>}
									fromBo
									disabled={dontCopyDataValue}
								>
									{
										fermeList.map(ferme =>
											<MenuItem key={ferme.id} value={ferme.id}>
												{ferme.raisonSociale} ({ferme.codePostal})
											</MenuItem>
										)
									}
								</Field>
							</Grid>
							<Grid item className={classnames(classes.field, classes.checkbox, classes.copy)}>
								<Field
									name={fieldsCreation.DONT_COPY_DATA.name}
									component={Checkbox}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsCreation.DONT_COPY_DATA.name}`} />}
									/>
								</Grid>
							</Grid>
							<Grid container className={classes.title}>
								<Typography variant="h3" className={classes.titleText}>
									<FormattedMessage id="bo.ferme.popin.subtitle" />
								</Typography>
							</Grid>
						</>
				}
				{ (menuSelection === PlanView.FORM.order || isCreation) ? (
					<>
					<Grid container className={classes.row}>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.RAISON_SOCIALE.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.RAISON_SOCIALE.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.RAISON_SOCIALE.size
								}}
							/>
						</Grid>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.SIRET.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.SIRET.name}`} />}
								fromBo
							/>
						</Grid>
					</Grid>
					<Grid container className={classes.row}>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.ADRESSE.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.ADRESSE.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.ADRESSE.size
								}}
							/>
						</Grid>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.COMPLEMENT_ADRESSE.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.COMPLEMENT_ADRESSE.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.COMPLEMENT_ADRESSE.size
								}}
							/>
						</Grid>
					</Grid>
					<Grid container className={classes.row}>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.CODE_POSTAL.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.CODE_POSTAL.name}`} />}
								fromBo
							/>
						</Grid>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.VILLE.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.VILLE.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.VILLE.size
								}}
							/>
						</Grid>
					</Grid>
					<Grid container className={classes.row}>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.TELEPHONE.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.TELEPHONE.name}`} />}
								fromBo
							/>
						</Grid>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.EMAIL.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.EMAIL.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.EMAIL.size
								}}
							/>
						</Grid>
					</Grid>
					<Grid container className={classes.row}>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.REPRESENTANT.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.REPRESENTANT.name}`} />}
								fromBo
								inputProps={{
									maxLength: fieldsUpdate.REPRESENTANT.size
								}}
							/>
						</Grid>
						<Grid item className={classes.field}>
							<Field
								name={fieldsUpdate.MINORATION.name}
								component={Input}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fieldsUpdate.MINORATION.name}`} />}
								fromBo
								adornment="%"
								type="number"
							/>
						</Grid>
					</Grid>
						<Typography variant="h4" className={classnames(classes.bigField, classes.textTitle)}>
							<FormattedMessage id="bo.ferme.form.fields.hourTitle" />
						</Typography>
						<Typography variant="p" className={classnames(classes.bigField, classes.text)}>
							<FormattedMessage id="bo.ferme.form.fields.hourSubtitle" />
						</Typography>
						<Grid container className={classes.row}>
							<DayInput field={fieldsUpdate.LUNDI_DEBUT.name}/>
							<DayInput field={fieldsUpdate.MARDI_DEBUT.name}/>
							<DayInput field={fieldsUpdate.MERCREDI_DEBUT.name}/>
							<DayInput field={fieldsUpdate.JEUDI_DEBUT.name}/>
						</Grid>
						<Grid container className={classes.row}>
							<DayInput field={fieldsUpdate.VENDREDI_DEBUT.name}/>
							<DayInput field={fieldsUpdate.SAMEDI_DEBUT.name}/>
							<DayInput field={fieldsUpdate.DIMANCHE_DEBUT.name}/>
						</Grid>
					<Grid container className={classes.row}>
						<Typography variant="h4" className={classnames(classes.bigField, classes.textTitle)}>
							<FormattedMessage id="bo.ferme.form.fields.surfaceTitle" />
						</Typography>
						<Typography variant="p" className={classnames(classes.bigField, classes.text)}>
							<FormattedMessage id="bo.ferme.form.fields.surfaceSubtitle" />
						</Typography>
						<Grid item className={classes.litleField}>
							<Field
								label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceLongueur" />}
								name={fieldsUpdate.SURFACE_LONGUEUR.name}
								onChange={(value) => change(fieldsUpdate.SURFACE_DIMENSION.name, (value.currentTarget.value * largeurValue).toFixed(2))}
								component={Input}
								adornment="m"
								type="number"
								fromBo
								normalize={(value) => parsePositiveFloat(value).toString()}
							/>
						</Grid>
						<Grid item className={classes.litleField}>
							<Field
								label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceLargeur" />}
								name={fieldsUpdate.SURFACE_LARGEUR.name}
								onChange={(value) => change(fieldsUpdate.SURFACE_DIMENSION.name, (longueurValue * value.currentTarget.value).toFixed(2))}
								component={Input}
								adornment="m"
								type="number"
								fromBo
								normalize={(value) => parsePositiveFloat(value).toString()}
							/>
						</Grid>
						<Grid item className={classnames(classes.litleField, classes.disableField)}>
							<Field
								label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceDimension" />}
								name={fieldsUpdate.SURFACE_DIMENSION.name}
								disabled
								component={Input}
								adornment="m²"
								type="number"
								fromBo
							/>
						</Grid>
					</Grid>
					</>
					)
				: isCreation ??
					(
						<>
							<Typography variant="h2" className={classes.row}>
								<FormattedMessage id="bo.ferme.plan.title" />
							</Typography>
							<Typography variant="paragraph" className={classes.row}>
								<FormattedMessage id="bo.ferme.plan.description" />
							</Typography>
							<Grid container className={classes.file}>
								<Grid container className={classes.row}>
									<Typography className={classes.sizeText} variant="h5">
										<FormattedMessage id="bo.ferme.plan.size" />
									</Typography>
									<Typography variant="h5">
										<FormattedMessage id="bo.ferme.plan.formats" />
									</Typography>
								</Grid>
								<Grid>
									<Field
										name={fieldsUpdate.FILE.name}
										component={FileInput}
										fromBo
										fileInputRef={fileInputRef}
										fieldsUpdate={fieldsUpdate}
										fieldsCreation={fieldsCreation}
										savedFile={savedFile}
										menuSelection={menuSelection}
										dontCopyDataValue={dontCopyDataValue}
										formName={formName}
										isFormInitial={pristine}
										untouchFermeToCopy={(form) => untouch(form, fieldsCreation.FERME_TO_COPY.name)}
										changeFile={(files) => change(fieldsUpdate.FILE.name, files)}
										changeFermeToCopy={(files) => change(fieldsCreation.FERME_TO_COPY.name, files)}
										changeImage={(files) => change(fieldsCreation.ID_IMAGE.name, files)}
									/>
								</Grid>
								<Grid container className={classes.row}>
									<Typography variant="h2">
										<FormattedMessage id="bo.ferme.matrice.title" />
									</Typography>
									<Typography variant="paragraph" className={classes.row}>
										<FormattedMessage id="bo.ferme.matrice.description" />
									</Typography>
								</Grid>
								<Grid container justify="center" className={classes.row}>
									<Button type="secondary" keepCase startIcon={<CloudUpload />} onClick={handleExport} className={classes.matriceButtons}>
										<FormattedMessage id="bo.ferme.matrice.export" />
									</Button>
									<Button type="primary" keepCase startIcon={<CloudDownload/>} onClick={handleButtonClick} className={classes.matriceButtons}>
										<FormattedMessage id="bo.ferme.matrice.import"/>
										<input
											type="file"
											accept=".xlsx"
											id="fileInput"
											onChange={(event) => {
												change(fieldsUpdate.MATRICE_FILE.name, event.target.files)
												setMatriceFile(event.target.files)
											}}
											style={{ display: 'none' }}
											ref={fileInputRef}
										/>
									</Button>
								</Grid>
								{ matriceFile?.[0] &&
									<Grid className={classnames(classes.fileLoad, classes.row)}>
										<Typography variant="body3">
											{matriceFile?.[0]?.name}
										</Typography>
										<Close onClick={handleDeleteFile}/>
									</Grid>
								}
							</Grid>
						</>
					)}
			</form>
		</>
	)
}

FermeForm.propTypes = {
	classes: PropTypes.object,
	fermeList: PropTypes.array,
	handleSubmit: PropTypes.func,
	isCreation: PropTypes.bool,
	dontCopyDataValue: PropTypes.bool,
	savedFile: PropTypes.string,
	form: PropTypes.string,
	change: PropTypes.func,
	formName: PropTypes.string,
	untouch: PropTypes.func,
	longueurValue: PropTypes.string,
	largeurValue: PropTypes.string,
	state: PropTypes.func,
	pristine: PropTypes.bool,
	handleExport: PropTypes.func
}

const mapStateToProps = (state, { formName }) => ({
	dontCopyDataValue: formValueSelector(formName)(state, fieldsCreation.DONT_COPY_DATA.name),
	savedFile: formValueSelector(formName)(state, fieldsCreation.SAVED_FILE.name),
	longueurValue: formValueSelector(formName)(state, fieldsCreation.SURFACE_LONGUEUR.name),
	largeurValue: formValueSelector(formName)(state, fieldsCreation.SURFACE_LARGEUR.name),
	form: formName
})

const actions = {
	change,
	untouch
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(FermeForm)
