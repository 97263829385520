import React from 'react'
import {FormattedMessage} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import {Grid} from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, {BO_FILTER_FORM} from '../BackOfficeContainer'
import DashboardIcon from '@material-ui/icons/Dashboard'
import RoomIcon from '@material-ui/icons/Room'
import BoListView from '../components/BoListView'
import {getPreferences} from '../../common/user/services/userSelectors'
import {connect} from 'react-redux'
import {change, isInvalid, reset} from 'redux-form'
import {createBlocSurface, getAllBlocsAndSurfaceByIdFerme, getAllBlocsByIdFerme, updateBlocSurface} from './services/BlocSurfaceApi'
import {BLOC_OU_SURFACE_CAPS, BLOC_OU_SURFACE_MAJ, colors, TYPE_DIALOG} from '../../../utils/constants'
import NoResultPage from '../../../components/NoResultPage'
import BlocForm from './BlocSurfaceForm'
import FormButton from '../../../components/form/FormButton'
import {DialogContext} from '../../../components/layout/dialog'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import {ERROR_TYPE_VALIDATION} from './constantsBoBlocSurface'
import Loader from '../../../components/layout/Loader'
import TitrePlanche from '../../common/information/TitrePlanche'
import InfoPlanche from '../../common/information/InfoPlanche'
import InfoBloc from '../../common/information/InfoBloc'
import {useSolverStatusContext} from '../../fo/besoinsProduction/SolverStatusContextProvider'
import {getNotifications} from "../../common/notification/notificationActions";
import {checkCulture} from "../../fo/tourPlaine/component/suiviParBloc/service/suiviParBlocApi";
import {injectPlancheIndisponible} from "./injector/plancheIndisponibleInjector";
import {compose} from "redux";
import PropTypes from "prop-types";
import LocationOffIcon from '@material-ui/icons/LocationOff';

const FORM_CREATION_BLOC_SURFACE = 'CreationBlocSurfaceForm'
const FORM_MODIFICATION_BLOC_SURFACE = 'ModificationBlocSurfaceForm'

const BlocSurfacePage = ({ userPreferences, reset, change, getNotifications, openPopinPlancheIndisponible }) => {
	const initFilters = { idFerme: userPreferences.FERME_ID }
	const [filters, setFilters] = React.useState(initFilters)
	const [selection, setSelection] = React.useState({})
	const [tuiles, setTuiles] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const TUILE_SURFACES_SANS_BLOC_LIES = 'surfacesSansBloc'
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const { snackSuccess, snackError } = useSnackbar()
	const [blocs, setBlocs] = React.useState([])
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	const formatInitialValues = (datas) => {
		return ({
			...datas,
			checkbox: (selection && selection.data) && ((selection.data.bloc === null && !selection.data.isBloc) || selection.data.isBloc)
		})
	}

	const arrayToOptionList = (array) => (
		array && array.map(element => {
			return { label: element.toString(), code: element }
		})
	)

	const getOptionsBloc = () => {
		return getAllBlocsByIdFerme(filters.idFerme)
			.then(option => arrayToOptionList(option))
	}

	const updateBlocOrSurface = (data) => {
		return updateBlocSurface(data)
			.then((res) => {
				if (selection.data.isBloc) {
					setSelection(formatOneBloc(res))
					snackSuccess({id: 'bo.blocSurface.snackbar.success.modificationBloc'})
				} else {
					setSelection(formatOnePlanche(res))
					snackSuccess({id: 'bo.blocSurface.snackbar.success.modificationSurface'})
				}
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				getAllBlocsAndSurfaces()
				getOptionsBloc()
					.then(setBlocs)
			})
			.catch((e) => {
				if (e && e.bodyError && e.bodyError.fieldErrors[0].code === ERROR_TYPE_VALIDATION.ASSOLEMENT_LIE) {
					data.isBloc
						? snackError({id: 'bo.blocSurface.snackbar.errors.modificationBlocAssolement'})
						: snackError({id: 'bo.blocSurface.snackbar.errors.modificationSurfaceAssolement'})
				}
				if (e && e.bodyError && e.bodyError.fieldErrors[0].code === ERROR_TYPE_VALIDATION.DUPLICATION_NOM) {
					data.isBloc
						? snackError({id: 'bo.blocSurface.snackbar.errors.modificationBlocDuplicationNom'})
						: snackError({id: 'bo.blocSurface.snackbar.errors.modificationSurfaceDuplicationNom'})
				}
			})
	}

	const createBlocOrSurface = (data) => {
		return createBlocSurface(data)
			.then(() => {
				setSelection(undefined)
				data.choix === BLOC_OU_SURFACE_CAPS.BLOC
					? snackSuccess({ id: 'bo.blocSurface.snackbar.success.creationBloc' })
					: snackSuccess({ id: 'bo.blocSurface.snackbar.success.creationSurface' })
				closeDialog()
				getNotifications()
				refreshSolverInfosForCurrentCampagne()
				reset(BO_FILTER_FORM)

				// Gestion du filter ferme après reset
				change(BO_FILTER_FORM, 'idFerme', filters.idFerme)
				setFilters({
					...initFilters,
					idFerme: filters.idFerme
				})

				getOptionsBloc()
					.then(setBlocs)
			})
			.catch((e) => {
				if (e && e.bodyError && e.bodyError.fieldErrors[0].code === ERROR_TYPE_VALIDATION.DUPLICATION_NOM) {
					data.choix === BLOC_OU_SURFACE_CAPS.BLOC
						? snackError({ id: 'bo.blocSurface.snackbar.errors.creationBlocDuplicationNom' })
						: snackError({ id: 'bo.blocSurface.snackbar.errors.creationSurfaceDuplicationNom' })
				} else if (e && e.bodyError && e.bodyError.fieldErrors[0].code === ERROR_TYPE_VALIDATION.MATRICE_SANS_BLOC) {
					snackError({ id: 'bo.blocSurface.snackbar.errors.creationSurfaceBlocMatriceVide' })
				}
			})
	}

	React.useEffect(() => {
		getOptionsBloc()
			.then(setBlocs)
	}, [filters.idFerme])

	const handleUpdate = (values) => {
		const data = {
			...values,
			idFerme: filters.idFerme
		}
		if (!data?.isBloc && data.indisponible) {
			return checkCulture([{
				planches: [data.id],
				dateDebut: data.periodeIndisponibilite.dateDebut,
				dateFin: data.periodeIndisponibilite.dateFin,
			}])
				.then(() => updateBlocOrSurface(data))
				.catch((assolementList) => {
					if (assolementList.response.status === 400) {
						openPopinPlancheIndisponible(assolementList.bodyError.flat(), data, updateBlocOrSurface)
					} else {
						snackError({ id: 'bo.blocSurface.snackbar.errors.plancheIndisponible' })
					}
				})
		} else {
			return updateBlocOrSurface(data)
		}
	}

	const handleCreation = (values) => {
		const data = {
			...values,
			idFerme: filters.idFerme
		}
		return createBlocOrSurface(data)
	}

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.blocSurface.popin.title" />,
			<BlocForm
				isCreation
				formName={FORM_CREATION_BLOC_SURFACE}
				onSubmit={handleCreation}
				idFerme={filters.idFerme}
				blocs={blocs}
				tuiles={tuiles}
			/>,
			[
				<FormButton
					type="primary"
					formName={FORM_CREATION_BLOC_SURFACE}
					getDisabledFromState={state => isInvalid(FORM_CREATION_BLOC_SURFACE)(state)}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const formatOnePlanche = (planche) => {
		return ({
			uniqueId: `planche-${planche.id}`,
			icon: planche.inactive ? LocationOffIcon : RoomIcon,
			iconColor: planche.inactive ? colors.placeholder : colors.primary,
			title: <TitrePlanche planche={planche} />,
			description: <InfoPlanche planche={planche} />,
			data: {
				id: planche.id,
				nom: planche.nom,
				typeSurface: planche.typePlanche,
				bloc: planche.bloc,
				zone: planche.zone,
				dimension: planche.surface,
				longueur: planche.longueur,
				largeur: planche.largeur,
				inactive: planche.inactive,
				indisponible: planche.indisponible,
				periodeIndisponibilite:
					{
						dateDebut: planche.dateDebutIndisponibilite,
						dateFin: planche.dateFinIndisponibilite
					}
			},
			children: []
		})
	}

	const formatOneBloc = (bloc) => {
		return ({
			uniqueId: `bloc-${bloc.id}`,
			icon: DashboardIcon,
			title: <FormattedMessage
				id="bo.blocSurface.titreTuileBloc"
				values={{
					nom: bloc.nom
				}}
			/>,
			description: <InfoBloc bloc={bloc} />,
			data: {
				id: bloc.id,
				nom: bloc.nom,
				zone: bloc.zone,
				isBloc: bloc.isBloc,
				typeIrrigation: bloc.typeIrrigation,
			},
			children: formatPlanches(bloc.planches)
		})
	}

	const formatPlanches = (planches, isPlanchesWithoutBloc = false) => {
		if (isPlanchesWithoutBloc) {
			if (planches.length > 0) {
				return ([{
					uniqueId: TUILE_SURFACES_SANS_BLOC_LIES,
					icon: DashboardIcon,
					title: <FormattedMessage id="bo.blocSurface.titreTuileSurfacesSansBlocLie" />,
					description: '',
					data: {},
					children: formatPlanches(planches)
				}])
			}
			return []
		}
		return planches.map(planche => formatOnePlanche(planche))
	}

	const formatBlocs = (res, fromUpdateOrCreation = false) => {
		if (fromUpdateOrCreation) {
			return formatOneBloc(res)
		}

		return ([
			...res.planchesWithBloc.map(bloc => formatOneBloc(bloc)),
			...formatPlanches(res.planchesWithoutBloc, true)
		])
	}

	const getAllBlocsAndSurfaces = () => {
		setLoading(true)
		getAllBlocsAndSurfaceByIdFerme(filters.idFerme, filters.query)
			.then((res) => {
				setTuiles(formatBlocs(res))
			})
			.finally(() => setLoading(false))
	}

	React.useEffect(() => {
		getAllBlocsAndSurfaces()
	}, [filters])

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.blocSurface.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={openPopinCreation}><FormattedMessage id="bo.blocSurface.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer
			inputLabel="bo.blocSurface.inputQuery"
			onSubmit={values => {
				setFilters(values)
				setSelection(undefined)
			}}
		>
			{loading ?
				<Loader fromBo />
				: tuiles.length > 0
					? <BoListView
						titleLeft={<FormattedMessage id="bo.blocSurface.titreGauche" />}
						titleRight={(selection && selection.data) &&
						<FormattedMessage id="bo.blocSurface.titreDroit"
						                  values={{
							                  entite: selection.uniqueId === TUILE_SURFACES_SANS_BLOC_LIES
								                  ? ''
								                  : selection.data.isBloc
									                  ? BLOC_OU_SURFACE_MAJ.BLOC
									                  : BLOC_OU_SURFACE_MAJ.SURFACE,
							                  nom: selection.data.nom
						                  }}
						/>}
						tuileList={tuiles}
						selection={selection}
						handleSelection={setSelection}
						withFilter
						formName={FORM_MODIFICATION_BLOC_SURFACE}
					>
						{selection &&
						<BlocForm
							formName={FORM_MODIFICATION_BLOC_SURFACE}
							initialValues={formatInitialValues(selection.data)}
							onSubmit={handleUpdate}
							idFerme={filters.idFerme}
							selection={selection}
							isCreation={false}
							blocs={blocs}
							tuiles={tuiles}
						/>
						}
					</BoListView>
					: <NoResultPage />
			}
		</BackOfficeContainer>
	</>)
}

const mapStateToProps = state => ({
	userPreferences: getPreferences(state),
})

BlocSurfacePage.propTypes = {
	openPopinPlancheIndisponible: PropTypes.func
}

const actions = {
	reset,
	change,
	getNotifications
}

export default compose(
	injectPlancheIndisponible,
	connect(mapStateToProps, actions))
(BlocSurfacePage)
