import React, {useCallback} from 'react'
import Badge from '@material-ui/core/Badge'
import NotificationIcon from '@material-ui/icons/Notifications'
import IconButton from '../../IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import {colors, SOLVER_STATUS} from '../../../utils/constants'
import Popover from '@material-ui/core/Popover'
import {Box, Typography} from '@material-ui/core'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {getNotificationsState} from '../../../modules/common/notification/notificationSelectors'
import CircularProgress from '@material-ui/core/CircularProgress'
import Notification from './Notification'
import Button from '../../Button'
import {stopDemande} from '../../../modules/common/solver/solverApi'
import {getCurrentCampagne} from '../../../modules/common/campagne/campagneSelector'
import {useSolverStatusContext} from '../../../modules/fo/besoinsProduction/SolverStatusContextProvider'
import {getNotifications} from "../../../modules/common/notification/notificationActions";
import {readAllNotifications} from "../../../modules/common/notification/notificationApi";
import DoneAllIcon from '@material-ui/icons/DoneAll';

const styles = () => getStyles({
	badge: {
		marginRight: 25,

		'& .MuiBadge-badge': {
			height: 14,
			width: 14,
			top: 4,
			right: 4,
			borderRadius: 10
		}
	},
	iconButton: {
		backgroundColor: 'white',
		color: colors.primary,
		padding: 6,
		boxShadow: '0px 5px 5px gray',

		'&:hover': {
			backgroundColor: 'white'
		}
	},
	box: {
		backgroundColor: colors.etapeBackground,
		width: 400,
		'& .MuiGrid-root': {
			'&:not(:last-child)': {
				borderBottom: '1px solid black',
				paddingBottom: 15
			},

			'&:not(:first-child)': {
				paddingTop: 15
			}
		}
	},
	loaderText: {
		fontFamily: 'Lato',
		fontSize: 12,
		color: colors.input
	},
	cancelButton: {
		color: 'white',
		minWidth: 100
	},
	loaderContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		gap: 20
	},
	notificationsBox: {
		padding: 16
	},
	markReadContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		borderBottom: "1px solid var(--Medium-grey, #D6D6D6)",
		padding: 8
	}
})

const NotificationZone = ({classes, notifications, getNotifications, campagne}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const {solverInfos, refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

	const markAllNotificationsReadAndRefresh = useCallback(() => {
		readAllNotifications().then(getNotifications)
	}, [readAllNotifications, getNotifications])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	};

	const handleClose = () => {
		setAnchorEl(null)
	};

	const open = !!anchorEl
	const id = open ? 'popover' : undefined;

	const hasNotification = notifications && notifications.length > 0

	const cancelSolver = () => {
		stopDemande(campagne.id).then(refreshSolverInfosForCurrentCampagne)
	}

	return (
		<>
			<div className={classes.loaderContainer}>
				{
					solverInfos?.status === SOLVER_STATUS.APPROVING &&
					<>
						<CircularProgress size={30}/>
						<span className={classes.loaderText}><FormattedMessage id="appBar.solveur.approving"/></span>
					</>
				}
				{
					(solverInfos?.status === SOLVER_STATUS.PREPARING || solverInfos?.status === SOLVER_STATUS.RUNNING) &&
					<>
						<CircularProgress size={30}/>
						<span className={classes.loaderText}><FormattedMessage id="appBar.solveur.cultures"/></span>
						<Button withBorder={false} variant="text" className={classes.cancelButton} onClick={cancelSolver}>
							<FormattedMessage id={`appBar.solveur.cancelCultures`}/>
						</Button>
					</>
				}
			</div>
			<Badge invisible={!hasNotification} color="error" variant="dot" className={classes.badge}>
				<IconButton aria-describedby={id} className={classes.iconButton} onClick={handleClick}>
					<NotificationIcon/>
				</IconButton>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: -10,
						horizontal: 'right',
					}}
				>
					<Box className={classes.box}>
						{hasNotification &&
							<>
								<div className={classes.markReadContainer}>
									<Button
										onClick={markAllNotificationsReadAndRefresh}
										variant="text"
										withBorder={false}
										height={24}
										startIcon={<DoneAllIcon/>}
									>
										<FormattedMessage id="notification.clear"/>
									</Button>
								</div>
								<div className={classes.notificationsBox}>
									{notifications.map(n => <Notification data={n} handleClose={handleClose}/>)}
								</div>
							</>
						}
						{!hasNotification &&
							<div className={classes.notificationsBox}>
								<Typography variant="body2"><FormattedMessage id="notification.empty"/></Typography>
							</div>
						}
					</Box>
				</Popover>
			</Badge>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		notifications: getNotificationsState(state),
		campagne: getCurrentCampagne(state)
	}
}

const actions = {
	getNotifications
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(NotificationZone)