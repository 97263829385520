import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const FilterIcon = ({ height, width, color }) => {
	return (
		<svg height={height} viewBox="-45 1 512 512" width={width}>
			<path d="M359.336 0H61.664C27.937 0 .5 27.496.5 61.297v19.96c0 27.747 10.777 53.837 30.344 73.458l104.629 104.93c3.464 3.472 5.37 8.093 5.37 13.011v142.899c0 8.906 3.384 17.007 9.79 23.441l61.015 61.195c7.696 7.715 17.918 11.809 28.348 11.809a40.138 40.138 0 0015.363-3.066c15.067-6.239 24.801-20.82 24.801-37.153v-199.12c0-4.919 1.906-9.544 5.371-13.016l104.625-104.93c19.567-19.621 30.344-45.711 30.344-73.457V61.297C420.5 27.497 393.063 0 359.336 0zM30.5 61.297c0-17.258 13.98-31.3 31.164-31.3h297.672c17.184 0 31.164 14.042 31.164 31.3v19.96c0 7.509-1.11 14.848-3.246 21.825H33.746A74.452 74.452 0 0130.5 81.258zm213.383 419.918a10.053 10.053 0 01-10.992-2.203l-61.004-61.184c-.973-.976-1.043-1.508-1.043-2.273v-50.157l79.316 79.543v26.844c0 4.149-2.465 7.852-6.277 9.43zm20.402-242.75c-9.11 9.137-14.125 21.281-14.125 34.195v129.797l-79.316-79.547v-50.25c0-12.918-5.016-25.058-14.125-34.195l-104.63-104.93c-.148-.152-.3-.3-.448-.453h317.718c-.148.152-.296.305-.445.453zm0 0"
			      fill={color}
			/>
		</svg>
	)
}

FilterIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

FilterIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default FilterIcon
