import { fetchFactory } from 'isotope-client'

export const getPreviousCultureByPlanche = (currentCampagneDateDebut, planchehId) =>
	fetchFactory(`/culture/previous?currentCampagneDateDebutStr=${currentCampagneDateDebut}&plancheId=${planchehId}`)

export const exportTableauProduction = (idFerme, idCampagne, typeExport) =>
	fetchFactory(`/culture/export/${idFerme}/${idCampagne}/${typeExport}`, {
		method: 'POST'
	})

export const createCultureGroup = (idCampagne, values) => fetchFactory(`/groupeCulture/${idCampagne}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getAllGroupes = (idFerme, zones, surface) => fetchFactory(`/groupeCulture/${idFerme}/${zones}/${surface}`)