import Grid from '@material-ui/core/Grid'
import React from 'react'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import { reduxForm } from 'redux-form'
import {ABSENCE_FORM} from '../../../../utils/constants'
import { FormattedMessage } from 'react-intl'
import {calculateDaysBetweenDates} from '../utils/ressourceUtils'
import { capitalize } from '../../../../utils/utils'
import FormattedMessageWithBold from '../../../../components/FormattedMessageWithBold'

const styles = () => getStyles({
	form: {
		padding: 15,
		width: '100%',
		fontFamily: 'Lato',
		fontSize: 16,
		fontWeight: 400
	},
	info: {
		paddingLeft: '20%'
	},
	value: {
		paddingTop: 10
	}
})

const PopinAbsenceDelete = ({ classes, handleSubmit, initialValues }) => {
	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.form} container>
				<Grid item container>
					<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.delete.info`} />
				</Grid>
				<Grid item container direction={"column"} justify={"center"} className={classes.info}>
					<Grid className={classes.value}>
						<FormattedMessageWithBold id="gestionRessource.detailRessource.absences.popin.delete.periode" values={{ dateDebut: initialValues?.dateDebutIndispo?.replace(/-/g, '/'), dateFin: initialValues?.dateFinIndispo?.replace(/-/g, '/') || '-' }} />
					</Grid>
					<Grid className={classes.value}>
						<FormattedMessageWithBold id="gestionRessource.detailRessource.absences.popin.delete.jours" values={{ jours: initialValues?.dateFinIndispo ? calculateDaysBetweenDates(initialValues?.dateDebutIndispo, initialValues?.dateFinIndispo) : '-' }} />
					</Grid>
					<Grid className={classes.value}>
						<FormattedMessageWithBold id="gestionRessource.detailRessource.absences.popin.delete.typeAbsence" values={{ typeAbsence: capitalize(initialValues?.typeAbsence?.toLowerCase()) }} />
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
}

PopinAbsenceDelete.propTypes = {
	classes: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: ABSENCE_FORM,
	})
)(PopinAbsenceDelete)