import { fetchFactory, urlUtils } from 'isotope-client'

export const getPlanificateurParametres = (idFerme) => fetchFactory(urlUtils.buildUrlWithParams(`/bo/planificateur/${idFerme}`))

export const getAuthorizeEmails = () => fetchFactory(`/bo/planificateur/authorize`)


/**
 * Mise à jour des reglages planificateur
 */
export const updateReglagesPlanificateur = (idFerme, values) => fetchFactory(`/bo/planificateur/${idFerme}`, {
	method: 'POST',
	body: JSON.stringify({
		...values
	})
})
