import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { injectTypeTacheValueList } from '../../../../common/valueLists/valueListInjectors'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { getLibelleLieu } from './ContenuPopinDeplacementTache'
import { TYPE_RECURRENCE } from '../../../../../utils/constants'
import { getJoursRecurrenceTache } from '../../../../../utils/utils'
import moment from 'moment'
import Radio from '../../../../../components/form/Radio'
import { change, Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

const styles = () => getStyles({
	contenu: {
		margin: '1.5em',
		marginTop: 14
	},
	titre: {
		marginTop: 10,
		fontWeight: 'bold'
	},
	bloc: {
		marginTop: 22
	},
	radio: {
		width: '100%',
		marginTop: 10,
		'& > div': {
			'& > label': {
				'& > span': {
					fontSize: '14px'
				}
			}
		}
	},
	liste: {
		marginTop: -6,
		marginLeft: -8
	}
})

export const formName = 'SelectionTypeSuppression'
export const field = 'typeSuppression'
export const fieldIdList = 'idList'

const ContenuPopinSuppressionTache = ({ tachesRecurrentes, classes, typeTacheList, change, handleSubmit }) => {
	React.useEffect(() => {
		change(field, 'tache')
	}, [])

	return (
		<Grid className={classes.contenu}>
			<Grid>
				<FormattedMessage id={'gestionTaches.toolbar.actions.delete.popin.validation.text'} />
			</Grid>
			{
				tachesRecurrentes && tachesRecurrentes.length > 0 &&
					<>
						<Grid className={classes.bloc}>
							<Grid className={classes.titre}>
								<FormattedMessage id={'gestionTaches.toolbar.actions.delete.popin.validation.message'} />
							</Grid>
							<Grid className={classes.liste}>
								<ul>
									{
										tachesRecurrentes.map(tache => {
											const typeTache = (typeTacheList.find(type => type.code === tache.type) || {}).label
											const jours = tache.recurrence.periodeType === TYPE_RECURRENCE.SEMAINE && getJoursRecurrenceTache(tache.recurrence).join(',')

											return (
												<li key={tache.id}>
													<FormattedMessageWithBold
														id={`gestionTaches.toolbar.actions.delete.popin.validation.label.${tache.recurrence.periodeType.toLowerCase()}`}
														values={{
															type: typeTache,
															culture: tache.culture && `- ${tache.culture}`,
															lieu: getLibelleLieu(tache.typeLieu, tache.lieu),
															jours: jours,
															nombre: tache.recurrence.periodeNb,
															debut: moment(tache.recurrence.recurrenceDebut).format('DD/MM/YYYY'),
															fin: moment(tache.recurrence.recurrenceFin).format('DD/MM/YYYY')
														}}
													/>
												</li>
											)
										})
									}
								</ul>
							</Grid>
						</Grid>
						<form onSubmit={handleSubmit}>
							<Grid className={classes.bloc}>
								<Grid className={classes.titre}>
									<FormattedMessage id={'gestionTaches.toolbar.actions.delete.popin.validation.form.title'} />
								</Grid>
								<Grid className={classes.liste}>
									<Field
										name={field}
										component={Radio}
										choices={[{
											value: "tache",
											label: <FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.validation.form.choixTache" />
										}, {
											value: "recurrence",
											label: <FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.validation.form.choixRecurrence" />
										}]}
										column
										containerClass={classes.radio}
									/>
								</Grid>
							</Grid>
						</form>
					</>
			}
		</Grid>
	)
}

ContenuPopinSuppressionTache.propTypes = {
	tachesRecurrentes: PropTypes.array,
	classes: PropTypes.object,
	typeTacheList: PropTypes.array,
	change: PropTypes.func,
	handleSubmit: PropTypes.func
}

const action = {
	change
}

const mapStateToProps = (state, { tachesRecurrentes, ...props }) => {
	return ({
		initialValues: {
			[fieldIdList]: tachesRecurrentes.map(tache => tache.id)
		},
		tachesRecurrentes,
		...props
	})
}

export default compose(
	connect(mapStateToProps, action),
	withStyles(styles),
	injectTypeTacheValueList,
	reduxForm({
		form: formName,
		destroyOnUnmount: true
	})
)(ContenuPopinSuppressionTache)
