import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isSubmitting, submit } from 'redux-form'
import { compose } from 'redux'
import Button from '../Button'

const FormButton = ({ submit, submitting, disabled, formName, getDisabledFromState, ...other }) => {
	return (
		<Button
			onClick={() => submit(formName)}
			loading={submitting}
			disabled={disabled}
			{...other}
		/>
	)
}

FormButton.propTypes = {
	formName: PropTypes.string.isRequired,
	submitting: PropTypes.bool,
	submit: PropTypes.func,
	getDisabledFromState: PropTypes.func
}

const mapStateToProps = (state, { formName, getDisabledFromState,disabled }) => ({
	submitting: isSubmitting(formName)(state),
	disabled: (typeof getDisabledFromState === 'function' && getDisabledFromState(state)) || disabled
})

const actions = {
	submit
}

export default compose(
	connect(mapStateToProps, actions)
)(FormButton)
