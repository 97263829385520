import React from 'react'
import PropTypes from 'prop-types'

const FertilisationOutlinedIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 60 60" width={width} height={height} {...props}>
			<path
				d="M30 60c16.569 0 30-13.431 30-30S46.569 0 30 0 0 13.431 0 30s13.431 30 30 30zm0-58c15.464 0 28 12.536 28 28S45.464 58 30 58 2 45.464 2 30 14.536 2 30 2z"
				fill={color}
			/>
			<path
				d="M11.44 19.57C21.34 25.82 27 35.65 27 46.55v3.35a3.06 3.06 0 003 3.1 3 3 0 003-3v-3.45c0-6.38 2.47-12.73 7.34-18.86a3 3 0 00-.88-4.48 3.06 3.06 0 00-3.87.83c-.34.42-.65.86-1 1.29a6.35 6.35 0 01.9-6.75c1.6-2 7-3.07 9.7-3.45.57 1.71 1.71 6.21-.7 9.17a1.002 1.002 0 001.55 1.27c3.77-4.63.87-11.63.74-11.93a1 1 0 00-1-.6c-.93.1-9.22 1.09-11.81 4.28a8.14 8.14 0 00-.61 9.81 41.29 41.29 0 00-3.12 5.45c0 .14-3.48-10.39-15.54-18a3.08 3.08 0 00-3.93.49 3 3 0 00.67 4.5zm.85-3.21a1.09 1.09 0 011.36-.12 37.32 37.32 0 0114.76 17.11 2 2 0 003.64.09 42 42 0 015.13-8.16 1 1 0 011.31-.28 1 1 0 01.31 1.5C33.64 32.94 31 39.7 31 46.55V50a1 1 0 01-1.17 1 1.08 1.08 0 01-.83-1.1v-3.35c0-11.6-6-22-16.52-28.67a1 1 0 01-.19-1.52z"
				fill={color}
			/>
		</svg>
	)
}

FertilisationOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

FertilisationOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#24ae5f'
}

export default FertilisationOutlinedIcon
