import {Checkbox as MuiCheckbox, Grid, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {compose} from 'redux'

const styles = () => getStyles({
	label: {
		fontFamily: "Lato",
		fontSize: 12
	}
})

// Composant fonctionnel sans Redux Form, si dans un reduxForm voir Checkbox.jsx

const CheckboxLabel = ({label, classes, input, checked = null, useChecked = true, ...props}) => {
	const checkedProps = useChecked ? {checked: input && input.value} : {}

	return (
		<label>
			<Grid container alignItems="center">
				<Grid item>
					{checked
						? <MuiCheckbox
							{...input}
							{...props}
							{...checkedProps}
							color="primary"
							checked={checked}
						/>
						: <MuiCheckbox
							{...input}
							{...props}
							{...checkedProps}
							color="primary"
						/>
					}
				</Grid>
				<Grid item className={classes.label}>
					<b>{label}</b>
				</Grid>
			</Grid>
		</label>
	);
}

CheckboxLabel.propType = {
	label: PropTypes.string
}

export default compose(
	withStyles(styles)
)(CheckboxLabel)
