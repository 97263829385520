import React from 'react'
import PropTypes from 'prop-types'

const WindMillIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#9e9797"
			d="M114.188 512a7.61 7.61 0 0 1-4.413-13.818l109.586-77.816-87.319-56.137c-3.536-2.274-4.56-6.982-2.286-10.518s6.985-4.558 10.518-2.286l96.791 62.226a7.611 7.611 0 0 1 .291 12.609l-118.767 84.335a7.595 7.595 0 0 1-4.401 1.405z"
		/>
		<path
			fill="#9e9797"
			d="M245.587 512a7.577 7.577 0 0 1-4.399-1.406L122.42 426.259a7.61 7.61 0 0 1 .291-12.609l96.79-62.226a7.608 7.608 0 0 1 10.518 2.286 7.61 7.61 0 0 1-2.286 10.518l-87.318 56.137L250 498.181a7.61 7.61 0 0 1 1.8 10.612 7.604 7.604 0 0 1-6.213 3.207z"
		/>
		<path
			fill="#8c8585"
			d="M245.588 512a7.612 7.612 0 0 1-7.517-6.484l-38.985-259.151a7.61 7.61 0 1 1 15.054-2.258l38.985 259.151a7.61 7.61 0 0 1-7.537 8.742zM114.193 512a7.61 7.61 0 0 1-7.537-8.741l38.688-259.15a7.608 7.608 0 0 1 8.656-6.398 7.61 7.61 0 0 1 6.398 8.656l-38.688 259.15a7.614 7.614 0 0 1-7.517 6.483z"
		/>
		<path
			fill="#6c7fd8"
			d="M67.586 241.996 18.059 279.21c-6.043 4.541-6.933 13.268-1.931 18.935l16.379 18.556c8.151 9.234 22.446 9.549 30.996.683l20.5-21.26a28.407 28.407 0 0 0 7.867-17.449z"
		/>
		<path
			fill="#8c8585"
			d="m95.164 221.274 71.158-53.469a7.613 7.613 0 0 1 10.658 1.512 7.614 7.614 0 0 1-1.512 10.658l-88.283 66.457c-1.371 1.031 7.979-25.158 7.979-25.158z"
		/>
		<path
			fill="#4f66d0"
			d="m67.586 241.996-2.105 36.679a28.405 28.405 0 0 1-7.867 17.449l-20.499 21.26a21.172 21.172 0 0 1-1.981 1.79c8.335 6.708 20.673 6.191 28.369-1.79l20.5-21.26a28.407 28.407 0 0 0 7.867-17.449l3.294-57.401z"
		/>
		<path
			fill="#c8effe"
			d="m158.516 258.432 16.355.003c14.133 0 26.318-8.162 24.128-16.508l-3.049-68.752a16.608 16.608 0 0 0-32.128 0l-18.041 68.749c-2.189 8.347 4.107 16.508 12.735 16.508zM45.346 145.156l13.208 40.649c2.666 8.206 12.374 11.672 19.635 7.01l59.809-38.402a16.609 16.609 0 0 0-9.928-30.557l-70.959 4.087c-8.615.497-14.432 9.007-11.765 17.213zM118.106 2.52 83.528 27.642c-6.981 5.072-7.277 15.375-.6 20.84l55.005 45.015a16.609 16.609 0 0 0 25.993-18.884L138.112 8.389C134.978.35 125.087-2.552 118.106 2.52zM276.246 27.642 241.668 2.519c-6.981-5.072-16.872-2.17-20.006 5.87l-25.814 66.223a16.608 16.608 0 0 0 25.993 18.884l55.005-45.015c6.677-5.464 6.381-15.767-.6-20.839zM301.22 185.805l13.208-40.649c2.666-8.206-3.15-16.716-11.765-17.213l-70.959-4.087a16.61 16.61 0 0 0-9.928 30.557l59.809 38.402c7.261 4.663 16.969 1.197 19.635-7.01z"
		/>
		<path
			fill="#99e6fc"
			d="M201.258 258.435h-26.386c8.626 0 14.918-8.16 12.736-16.512l-18.044-68.747a16.454 16.454 0 0 0-2.872-5.876 16.375 16.375 0 0 1 3.045-3.055 16.552 16.552 0 0 1 10.149-3.461 16.601 16.601 0 0 1 16.065 12.392l18.044 68.747c2.192 8.353-4.11 16.512-12.737 16.512zM138.002 154.411l-59.816 38.403c-6.85 4.405-15.893 1.563-19.11-5.683l52.529-33.734a16.624 16.624 0 0 0 6.83-19.11 16.58 16.58 0 0 0-7.814-9.428l17.445-1.005c.315-.02.629-.03.944-.03 7.165 0 13.579 4.608 15.812 11.478a16.604 16.604 0 0 1-6.82 19.109zM165.058 80.641a16.608 16.608 0 0 1-27.127 12.858l-2.953-2.415a16.59 16.59 0 0 0 3.694-10.443c0-2.02-.376-4.059-1.137-6.028l-25.808-66.22a9.994 9.994 0 0 0-.416-.944l6.8-4.932c6.972-5.064 16.867-2.172 20.003 5.876l25.808 66.22a16.496 16.496 0 0 1 1.136 6.028zM276.845 48.48 221.84 93.499a16.583 16.583 0 0 1-10.514 3.755c-3.42 0-6.84-1.055-9.763-3.177a16.668 16.668 0 0 1-3.156-2.994l52.052-42.604c6.678-5.46 6.384-15.771-.599-20.835L222.073 7.459c3.501-7.328 12.889-9.804 19.597-4.943l34.576 25.128c6.983 5.065 7.277 15.376.599 20.836zM314.426 145.156l-13.203 40.645c-2.669 8.21-12.371 11.681-19.638 7.013l-8.535-5.48a13.161 13.161 0 0 0 1.786-3.562l13.203-40.645c2.273-7.003-1.624-14.228-8.17-16.492l22.794 1.309c8.617.497 14.432 9.002 11.763 17.212z"
		/>
		<path
			fill="#c8effe"
			d="M392.929 232.667h-9.188c-4.204 0-7.611-3.407-7.611-7.611s3.407-7.611 7.611-7.611h9.188c7.124 0 13.822-2.774 18.859-7.813l7.355-7.355c7.914-7.913 18.434-12.271 29.625-12.271h42.735c4.204 0 7.611 3.407 7.611 7.611s-3.407 7.611-7.611 7.611h-42.735c-7.125 0-13.823 2.774-18.86 7.813l-7.355 7.355c-7.913 7.913-18.433 12.271-29.624 12.271z"
		/>
		<path
			fill="#99e6fc"
			d="M348.222 232.667H315.08c-4.204 0-7.611-3.407-7.611-7.611s3.407-7.611 7.611-7.611h33.142a7.61 7.61 0 1 1 0 15.222zM392.929 92.235H315.08c-4.204 0-7.611-3.407-7.611-7.611s3.407-7.611 7.611-7.611h77.849c7.124 0 13.822-2.774 18.859-7.813l7.355-7.355c7.914-7.913 18.434-12.271 29.625-12.271h42.735c4.204 0 7.611 3.407 7.611 7.611s-3.407 7.611-7.611 7.611h-42.735c-7.125 0-13.823 2.774-18.86 7.813l-7.355 7.355c-7.913 7.913-18.433 12.271-29.624 12.271z"
		/>
		<path
			fill="#c8effe"
			d="M491.504 152.156H350.881c-4.204 0-7.611-3.407-7.611-7.611s3.407-7.611 7.611-7.611h140.623a7.61 7.61 0 0 1 7.611 7.611 7.61 7.61 0 0 1-7.611 7.611zM370.558 32.715H315.08c-4.204 0-7.611-3.407-7.611-7.611s3.407-7.611 7.611-7.611h55.478c4.204 0 7.611 3.407 7.611 7.611s-3.407 7.611-7.611 7.611z"
		/>
		<circle cx={179.887} cy={123.831} r={7.611} fill="#c8effe" />
	</svg>
)


WindMillIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

WindMillIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default WindMillIcon