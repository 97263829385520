import Grid from '@material-ui/core/Grid'
import React from 'react'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {FormattedMessage} from 'react-intl'
import FormattedMessageWithBold from '../../../../components/FormattedMessageWithBold'
import {injectTypeTacheValueList} from '../../../common/valueLists/valueListInjectors';

const styles = () => getStyles({
	form: {
		padding: 25,
		width: '100%',
		fontFamily: 'Lato',
		fontSize: 13,
		fontWeight: 400
	},
})

const PopinTache = ({classes, taches, typeTacheList}) => {
	return (
		<Grid className={classes.form} container>
			<Grid item container>
				<FormattedMessage id={`gestionRessource.detailRessource.inactive.infoTop`} values={{quantity: taches.size}}/>
			</Grid>
			<Grid item container direction={"column"} justify={"center"}>
				<ul>
					{taches.map(tache => (
						<li key={tache.id}>
							<FormattedMessageWithBold id={`gestionRessource.detailRessource.inactive.tache`} values={{ type: typeTacheList.find(type => type.code === tache.type)?.label, date: tache.date, culture: tache.cultureName, planche: tache.plancheName }}/>
						</li>
					))}
				</ul>
			</Grid>
			<Grid item container>
				<FormattedMessage id={`gestionRessource.detailRessource.inactive.infoBottom`} values={{quantity: taches.size}}/>
			</Grid>
		</Grid>
	);
}

PopinTache.propTypes = {
	classes: PropTypes.object.isRequired,
	taches: PropTypes.object,
}

export default compose(
	withStyles(styles),
	injectTypeTacheValueList
)(PopinTache)