import {withStyles} from '@material-ui/core'
import {AddCircleOutline} from '@material-ui/icons'
import {getStyles} from 'isotope-client'
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Field} from 'redux-form'
import Input from '../../../../../../components/form/Input'
import Link from '../../../../../../components/Link'
import {colors} from '../../../../../../utils/constants'
import {normalizeDate} from '../../../../../../utils/utils'
import {formFields} from './FertilizationForm'
import PropTypes from 'prop-types'
import DateFieldCustom from "../../../../../../components/form/DateFieldCustom";

const styles = () => getStyles({
	formInput: {
		marginTop: '1.5rem',
		'& > div > div': {
			padding: 0,
			marginBottom: 0
		},
		'& label': {
			fontSize: '0.75em'
		}
	},
	date: {
		marginBottom: '0.4rem'
	},
	comment: {
		paddingBottom: '1.625rem'
	},
	addButton: {
		color: colors.primary,
		fontSize: '0.75rem',
		float: 'right',
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		marginRight: '0.5rem'
	}
})

const SIZE = '1.125rem'
const FertilizationField = ({ fields, isUpdate, classes }) => {
	const intl = useIntl()

	React.useEffect(() => {
		if (fields && !fields.length) {
			fields.push({})
		}
	}, [])

	return (
		<>
			{fields.map((field, index) => (
				<div key={index} className={classes.formInput}>
					<div className={classes.date}>
						<Field
							key={`${index}.${formFields.date}`}
							name={`${field}.${formFields.date}`}
							component={DateFieldCustom}
							type="text"
							placeholder={intl.formatMessage({ id: `planning.cultureForm.fertilization.label.${isUpdate ? 'add' : 'date'}`}, { value: index + 1 })}
							format={normalizeDate}
							hideFootInfo
							textFieldStyle={{width: '100%'}}
							sliderCalendar
						/>
					</div>
					<div className={classes.comment}>
						<Field
							key={`${index}.${formFields.comment}`}
							name={`${field}.${formFields.comment}`}
							type="text"
							component={Input}
							label={<FormattedMessage id="planning.cultureForm.fertilization.label.comment"/>}
							multiline
						/>
					</div>
				</div>
			))}
			{fields.length < 3 && !isUpdate && <Link className={classes.addButton} onClick={() => fields.push({})}>
				<AddCircleOutline height={SIZE} width={SIZE} className={classes.icon}/>
				<FormattedMessage id="planning.cultureForm.fertilization.addFertilization"/>
			</Link>}
		</>
	)

}

FertilizationField.propType = {
	isUpdate: PropTypes.bool
}

export default withStyles(styles)(FertilizationField)