import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {FormSwitch, getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import {BLOC_OU_SURFACE} from './constantsBoBlocSurface'
import {localCompare} from '../../../utils/utils'
import {colors, TYPE_ASPERSION, TYPE_PLANCHE} from '../../../utils/constants'
import {injectTypeSurfaceValueList, injectZoneSurfaceValueList} from '../../common/valueLists/valueListInjectors'
import Checkbox from '../../../components/form/Checkbox'
import classnames from 'classnames'
import PeriodSelector from "../../fo/listAssolement/component/ToolBar/PeriodSelector";
import moment from "moment";
import {getFermeData} from "../ferme/services/fermeApi";

const styles = () => getStyles({
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	field: {
		width: '50%'
	},
	containerClass: {
		width: '200%'
	},
	inputs: {
		fontSize: 16,
		width: 282
	},
	checkbox: {
		width: 282
	},
	checkLabel: {
		fontSize: 14
	},
	litleField: {
		width: '33%'
	},
	form: {
		padding: '20px 20px 0px 20px',
		fontFamily: 'Lato'
	},
	gridCheckBox: {
		textAlign: 'end',
		paddingLeft: 5,
		paddingTop: 14
	},
	introduction: {
		marginLeft: 10,
		fontSize: 14
	},
	selecteur: {
		width: 282,
		marginTop: 20
	},
	subTitle: {
		marginLeft: 10,
		marginTop: 50,
		marginBottom: 20,
		fontSize: 18
	},
	selecteurEntite: {
		width: 600
	},
	rowSwitch: {
		marginTop: 10,
		paddingLeft: 15,
		backgroundColor: "#00000",
		'& .MuiIconButton-root': {
			color: colors.whiteSwitch,
			bottom: 0
		}
	},
	periodSelector: {
		'& .MuiIconButton-root': {
			color: colors.text
		}
	}

})

const BLOC_FIELDS = {
	CHOIX_ENTITY: {name: 'choix'},
	NOM: {name: 'nom', size: 50},
	ZONE: {name: 'zone', size: 50},
	CHECKBOX: {name: 'checkbox'},
	TYPE_IRRIGATION: {name: 'typeIrrigation'},
	BLOC: {name: 'bloc', propName: 'bloc'},
	TYPESURFACE: {name: 'typeSurface', propName: 'typeSurface'},
	SURFACE_INACTIVE: {name: 'inactive'},
	SURFACE_INDISPONIBLE: {name: 'indisponible'},
	DIMENSIONS: {name: 'dimension', propName: 'dimension'},
	LONGUEUR: {name: 'longueur', propName: 'longueur'},
	LARGEUR: {name: 'largeur', propName: 'largeur'},
	PERIODE_INDISPONIBILITE: {name: 'periodeIndisponibilite'}
}

const validate = (values, {checkboxNonLieeBloc, choixEntity}) => {
	const errors = {}
	const requiredFields = [
		BLOC_FIELDS.NOM.name,
		BLOC_FIELDS.TYPESURFACE.name,
		BLOC_FIELDS.DIMENSIONS.name,
		BLOC_FIELDS.LONGUEUR.name,
		BLOC_FIELDS.LARGEUR.name,
		BLOC_FIELDS.CHOIX_ENTITY.name,
		BLOC_FIELDS.TYPE_IRRIGATION.name,
		BLOC_FIELDS.PERIODE_INDISPONIBILITE.name
	]

	if (checkboxNonLieeBloc || choixEntity === BLOC_OU_SURFACE.BLOC.value) {
		requiredFields.push(BLOC_FIELDS.ZONE.name)
	} else {
		requiredFields.push(BLOC_FIELDS.BLOC.name)
	}

	requiredFields.forEach(field => {
		if ((!values[field]) || (values[field] && values[field].length === 0)) {
			errors[field] = {id: 'global.errors.mandatory'}
		}
		if (field === BLOC_FIELDS.NOM.name && values[field] && values[field].trim() === '') {
			errors[field] = {id: 'global.errors.mandatory'}
		}
		if ((field === BLOC_FIELDS.DIMENSIONS.name || field === BLOC_FIELDS.LONGUEUR.name || field === BLOC_FIELDS.LARGEUR.name) && values[field] < 0) {
			errors[field] = {id: 'global.errors.mandatory'}
		}
		if ((field === BLOC_FIELDS.PERIODE_INDISPONIBILITE.name) && (values[field]?.dateDebut === null || values[field]?.dateFin === null)) {
			errors[field] = {id: 'global.errors.mandatory'}
		}
	})
	return errors
}

const BlocSurfaceForm = ({
							 classes,
							 handleSubmit,
							 selection,
							 isCreation,
							 zoneSurfaceList,
							 choixEntity,
							 ancienBloc,
							 typeDeSurface,
							 checkboxNonLieeBloc,
							 typeSurfaceList,
							 idFerme,
							 surfaceIndisponible,
							 surfaceInactive,
							 periodIndisponibilite,
							 change,
							 blocs,
							 tuiles,
							 form
						 }) => {
	const zonesList = [...zoneSurfaceList.sort(localCompare('label'))]
	const typesSurfacesList = [...typeSurfaceList.sort(localCompare('label'))]
	const [ferme, setFerme] = React.useState()
	const [exBloc, setExbloc] = React.useState()
	const [period, setPeriod] = React.useState({})

	React.useEffect(() => {
		if (idFerme) {
			getFermeData(idFerme)
				.then((ferme) => setFerme(ferme))
		}
		if (typeDeSurface && ferme) {
			if (typeDeSurface === TYPE_PLANCHE.PERMANENTE) {
				change(BLOC_FIELDS.DIMENSIONS.name, ferme.surfacePlanchePermanente)
				change(BLOC_FIELDS.LONGUEUR.name, ferme.longueurPlanchePermanente)
				change(BLOC_FIELDS.LARGEUR.name, ferme.largeurPlanchePermanente)
			} else {
				change(BLOC_FIELDS.LONGUEUR.name, null)
				change(BLOC_FIELDS.LARGEUR.name, null)
			}
		}
		setPeriod({
			dateDebut: periodIndisponibilite?.dateDebut ?? new Date(),
			dateFin: periodIndisponibilite?.dateFin ?? new Date(),
		})
	}, [periodIndisponibilite, idFerme, typeDeSurface, selection])

	const updatePeriod = React.useCallback((values) => {
		setPeriod({
			dateDebut: moment(values.dateDebut).format(moment.HTML5_FMT.DATE),
			dateFin: moment(values.dateFin).format(moment.HTML5_FMT.DATE)
		})
		change(BLOC_FIELDS.PERIODE_INDISPONIBILITE.name, {
			dateDebut: moment(values.dateDebut).format(moment.HTML5_FMT.DATE),
			dateFin: moment(values.dateFin).format(moment.HTML5_FMT.DATE)
		})
		change(BLOC_FIELDS.SURFACE_INACTIVE.name, false)
	}, [setPeriod])


	//Reset du formulaire si l'on switch dans le champ CHOIX_ENTITY
	const onResetClick = () => {
		change(BLOC_FIELDS.NOM.name, null)
		change(BLOC_FIELDS.ZONE.name, null)
		change(BLOC_FIELDS.CHECKBOX.name, false)
		change(BLOC_FIELDS.BLOC.name, null)
		change(BLOC_FIELDS.TYPESURFACE.name, null)
		change(BLOC_FIELDS.DIMENSIONS.name, null)
		change(BLOC_FIELDS.LONGUEUR.name, null)
		change(BLOC_FIELDS.LARGEUR.name, null)
	}



	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			{/*Si formulaire de création*/}
			{/*On ajoute le selecteur de BLOC ou SURFACE*/}
			{isCreation &&
				<Grid>
					<span className={classes.introduction}><FormattedMessage id="bo.blocSurface.popin.infos"/></span>
					<Grid className={classes.selecteurEntite}>
						<Field
							name={BLOC_FIELDS.CHOIX_ENTITY.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.popin.labels.typeAjout"/>}
							containerClassName={classes.selecteur}
							onChange={onResetClick}
							fromBo
						>
							{
								Object.values(BLOC_OU_SURFACE).map(zone => (
									<MenuItem key={zone.value} value={zone.value}
											  classes={{selected: classes.menuItem}}>
										{zone.label}
									</MenuItem>
								))
							}
						</Field>
					</Grid>

					{choixEntity === BLOC_OU_SURFACE.BLOC.value
						? <Grid className={classes.subTitle}><FormattedMessage
							id="bo.blocSurface.popin.labels.infosNouveauBloc"/></Grid>
						: choixEntity === BLOC_OU_SURFACE.SURFACE.value
						&& <Grid className={classes.subTitle}><FormattedMessage
							id="bo.blocSurface.popin.labels.infosNouvelleSurface"/></Grid>
					}

				</Grid>
			}

			{/* BLOC modification ou ajout */}
			{((isCreation && choixEntity === BLOC_OU_SURFACE.BLOC.value) || (selection && selection.uniqueId && selection.uniqueId.includes('bloc'))) &&
				<Grid>
					<Grid container justify='center'>
						<Field
							name={BLOC_FIELDS.NOM.name}
							component={Input}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.blocNom"/>}
							fromBo
							containerClassName={classes.inputs}
							inputProps={{
								maxLength: BLOC_FIELDS.NOM.size
							}}
						/>
						<Field
							name={BLOC_FIELDS.ZONE.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.blocZone"/>}
							fromBo
							containerClassName={classes.inputs}
							inputProps={{
								maxLength: BLOC_FIELDS.ZONE.size
							}}
						>
							{
								zonesList.map(zone =>
									<MenuItem key={zone.code} value={zone.code}
											  classes={{selected: classes.menuItem}}>
										{zone.label}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={BLOC_FIELDS.TYPE_IRRIGATION.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.blocAspersion"/>}
							fromBo
						>
							{Object.entries(TYPE_ASPERSION).map(([value]) =>
								<MenuItem key={`aspersion-${value}`} value={value}>
									<FormattedMessage id={`bo.blocSurface.forms.labels.aspersion.${value}`}/>
								</MenuItem>
							)}
						</Field>
					</Grid>
				</Grid>
			}

			{/*SURFACE modification ou ajout*/}
			{((isCreation && choixEntity === BLOC_OU_SURFACE.SURFACE.value) || (selection && selection.uniqueId && selection.uniqueId.includes('planche'))) &&
				<Grid>
					<Grid container>
						<Grid>
							<Field
								name={BLOC_FIELDS.NOM.name}
								component={Input}
								label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceNom"/>}
								fromBo
								containerClassName={classes.inputs}
								inputProps={{
									maxLength: BLOC_FIELDS.NOM.size
								}}
							/>
						</Grid>
						<Grid className={classes.gridCheckBox}>

							<Field
								name={BLOC_FIELDS.CHECKBOX.name}
								component={Checkbox}
								containerClassName={classes.checkbox}
								onChange={(event, value) => {
									if (ancienBloc != null) {
										setExbloc(ancienBloc)
									}
									if (!value) {
										change(BLOC_FIELDS.BLOC.name, exBloc)
									} else {
										change(BLOC_FIELDS.BLOC.name, null)
									}
								}}
								label={<span className={classes.checkLabel}><FormattedMessage
									id="bo.blocSurface.forms.labels.surfaceCheckBox"/></span>}
								inputProps={{
									maxLength: BLOC_FIELDS.ZONE.size
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Field
							name={BLOC_FIELDS.BLOC.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceBloc"/>}
							fromBo
							containerClassName={classes.inputs}
							disabled={checkboxNonLieeBloc}
							onChange={(event, value) => {
								change(BLOC_FIELDS.ZONE.name, ...tuiles.filter(tuile => tuile.data.nom === value).map(tuile => tuile.data.zone))
							}}
						>
							{
								blocs.map(zone =>
									<MenuItem key={zone.code} value={zone.code}
											  classes={{selected: classes.menuItem}}>
										{zone.label}
									</MenuItem>
								)
							}
						</Field>
						<Field
							name={BLOC_FIELDS.ZONE.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceZone"/>}
							fromBo
							containerClassName={classes.inputs}
							disabled={!checkboxNonLieeBloc}
							onChange={() => {
								if (checkboxNonLieeBloc) {
									change(BLOC_FIELDS.BLOC.name, null)
								}
							}}
						>
							{
								zonesList.map(profil =>
									<MenuItem key={profil.code} value={profil.code}
											  classes={{selected: classes.menuItem}}>
										{profil.label}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
					<Grid container>
						<Field
							name={BLOC_FIELDS.TYPESURFACE.name}
							component={Select}
							label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceType"/>}
							fromBo
							containerClassName={classes.inputs}
						>
							{
								typesSurfacesList.map(type =>
									<MenuItem key={type.code} value={type.code}
											  classes={{selected: classes.menuItem}}>
										{type.label}
									</MenuItem>
								)
							}
						</Field>
						{typeDeSurface !== TYPE_PLANCHE.PERMANENTE &&
							<Field
								label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceDimension"/>}
								containerClassName={classes.inputs}
								name={BLOC_FIELDS.DIMENSIONS.name}
								disabled={typeDeSurface === TYPE_PLANCHE.PERMANENTE}
								component={Input}
								adornment="m²"
								type="number"
								fromBo
							/>
						}
					</Grid>

					{typeDeSurface === TYPE_PLANCHE.PERMANENTE &&
						<Grid>
							<Grid container className={classes.row}>
								<Grid item className={classes.litleField}>
									<Field
										label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceLongueur"/>}
										name={BLOC_FIELDS.LONGUEUR.name}
										component={Input}
										adornment="m"
										disabled
										type="number"
										fromBo
									/>
								</Grid>
								<Grid item className={classes.litleField}>
									<Field
										label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceLargeur"/>}
										name={BLOC_FIELDS.LARGEUR.name}
										component={Input}
										disabled
										adornment="m"
										type="number"
										fromBo
									/>
								</Grid>
								<Grid item className={classes.litleField}>
									<Field
										label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceDimension"/>}
										name={BLOC_FIELDS.DIMENSIONS.name}
										disabled
										component={Input}
										adornment="m²"
										type="number"
										fromBo
									/>
								</Grid>
							</Grid>
							<Grid item className={classnames(classes.rowSwitch, classes.field)}>
								<Field
									label={<FormattedMessage id="bo.blocSurface.forms.labels.surfaceInactive"/>}
									containerClassName={classnames(classes.inputs)}
									name={BLOC_FIELDS.SURFACE_INACTIVE.name}
									component={FormSwitch}
									onChange={() => change(BLOC_FIELDS.SURFACE_INDISPONIBLE.name, false)}
									fromBo
								/>
								{!surfaceInactive && (
									<Grid container className={classes.containerClass}>
										<Field
											label={<FormattedMessage
												id="bo.blocSurface.forms.labels.surfaceIndisponible"/>}
											containerClassName={classnames(classes.inputs)}
											name={BLOC_FIELDS.SURFACE_INDISPONIBLE.name}
											component={FormSwitch}
											fromBo
										/>

										{surfaceIndisponible && (
											<div className={classes.periodSelector}>
												<Field
													name={BLOC_FIELDS.PERIODE_INDISPONIBILITE.name}
													component={PeriodSelector}
													onSubmit={updatePeriod}
													initialValues={period}
													period={period}
													formName={`${form}-periode`}
													fromBo
													isInput={true}
												/>
											</div>)}
									</Grid>
								)}
							</Grid>
						</Grid>
					}
				</Grid>
			}
		</form>
	)
}

BlocSurfaceForm.propType = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	selection: PropTypes.object,
	surfaceInactive: PropTypes.bool,
	surfaceIndisponible: PropTypes.bool,
	isCreation: PropTypes.bool,
	zoneSurfaceList: PropTypes.array,
	choixEntity: PropTypes.string,
	typeDeSurface: PropTypes.string,
	checkboxNonLieeBloc: PropTypes.bool,
	typeSurfaceList: PropTypes.array,
	periodIndisponibilite: PropTypes.array,
	change: PropTypes.func,
	form: PropTypes.string,
}

const mapStateToProps = (state, {formName}) => {
	const selector = formValueSelector(formName)
	return ({
		choixEntity: selector(state, BLOC_FIELDS.CHOIX_ENTITY.name),
		typeDeSurface: selector(state, BLOC_FIELDS.TYPESURFACE.name),
		checkboxNonLieeBloc: selector(state, BLOC_FIELDS.CHECKBOX.name),
		surfaceInactive: selector(state, BLOC_FIELDS.SURFACE_INACTIVE.name),
		surfaceIndisponible: selector(state, BLOC_FIELDS.SURFACE_INDISPONIBLE.name),
		periodIndisponibilite: selector(state, BLOC_FIELDS.PERIODE_INDISPONIBILITE.name),
		ancienBloc: selector(state, BLOC_FIELDS.BLOC.name),
		form: formName,
	})
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		enableReinitialize: true,
		validate
	}),
	withStyles(styles),
	injectZoneSurfaceValueList,
	injectTypeSurfaceValueList
)(BlocSurfaceForm)
