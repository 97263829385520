import * as actions from './fermeActions'

export const ferme = (state = [] , action = {}) => {
	switch (action.type) {
		case actions.GET_FERME_LIST_SUCCESS:
			return action.payload
		case actions.GET_FERME_LIST_ERROR:
			return []
		default:
			return state
	}
}
