import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper/index'
import IconButton from '@material-ui/core/IconButton/index'
import Grid from '@material-ui/core/Grid/index'
import {getStyles} from 'isotope-client'
import classnames from 'classnames'
import Slide from '@material-ui/core/Slide/index'
import Typography from '@material-ui/core/Typography/index'
import {compose} from 'redux'
import withStyles from '@material-ui/styles/withStyles/index'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {onClickAway, toI18N} from '../../../utils/utils'
import {DetailsPanelContext} from './context'
import {TIMEOUT_PANEL} from '../../../utils/constants'
import {PAGE, usePageContext} from '../PageContext'
import {ClickAwayListener, useMediaQuery} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const styles = () => getStyles({
	slide: {
		position: 'fixed',
		right: 0,
		width: 460,
		top: 64,
		height: 'calc(100vh - 60px)',
		zIndex: 1000,
		background: 'linear-gradient(#f7f5f8, #e0d6e6)'
	},
	collapsed: {
		transform: 'translateX(404px) !important'
	},
	collapseIconContainer: {
		position: 'absolute',
		top: 24,
		left: 0,
		width: 51,
		paddingLeft: 11,
		borderRadius: '0 20px 20px 0',
		transition: `background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left ${TIMEOUT_PANEL}ms cubic-bezier(0, 0, 0.2, 1) 0ms, padding-left ${TIMEOUT_PANEL}ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-radius ${TIMEOUT_PANEL}ms cubic-bezier(0, 0, 0.2, 1) 0ms`
	},
	collapseIconContainerFocus: {
		backgroundColor: '#d8c7de'
	},
	collapseIconContainerCollapsed: {
		left: 11,
		paddingLeft: 0,
		borderRadius: '20px 0 0 20px'
	},
	collapseIconRoot: {
		width: 40,
		height: 40,
		padding: 8,
		'&:hover': {
			backgroundColor: '#d8c7de'
		}
	},
	collapseIcon: {
		transition: `transform ${TIMEOUT_PANEL}ms cubic-bezier(0, 0, 0.2, 1) 0ms`
	},
	collapseIconCollapsed: {
		transform: 'rotate(180deg)'
	},
	titleContainer: {
		height: 65
	},
	title: {
		width: 350,
		height: 50,
		borderBottom: '2px solid #55555a',
		textAlign: 'center'
	},
	content: {
		overflowY: 'auto'
	},
	actions: {
		height: 100,
		position: 'absolute',
		bottom: 0,
		width: '100%',
		boxSizing: 'border-box'
	},
	actionButton: {
		margin: '0 9px 0 9px',
		padding: '0 10px 0 10px'
	},
	actionsTablette: {
		position: 'absolute',
		bottom: -50,
		width: '100%',
		boxSizing: 'border-box'
	},
	closeIcon: {
		position: 'absolute',
		top: 12,
		right: 12
	}
})

const DetailsPanel = ({ classes, open, collapsed, setCollapsed }) => {

	const [focus, setFocus] = useState(false)
	const isTablette = useMediaQuery('(min-width: 0px) and (max-width: 1279px)');
	const { page } = usePageContext()

	const parentContainer = (children, closePanel) => {
		if (page === PAGE.TOUR_PLAINE) {
			return (
				<ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway(() => closePanel(false), open)}>
					{children}
				</ClickAwayListener>
			)
		}
		return children
	}

	return (
		<DetailsPanelContext.Consumer>
			{({ title, content, actions, persistOnCollapse, closePanel }) => {
			return (<Slide
				in={open}
				mountOnEnter
				direction="left"
				timeout={TIMEOUT_PANEL}
				className={classnames(classes.slide, { [classes.collapsed]: collapsed })}
			>
				<Paper elevation={4}>
					{
						parentContainer(
							<Grid container direction="column" style={{ position: isTablette && 'relative' }}>
								<Grid item container>
									<Grid item container direction="row" justify="center" alignContent="flex-end" className={classes.titleContainer}>
										<Grid
											item
											className={classnames(classes.collapseIconContainer, {
												[classes.collapseIconContainerCollapsed]: collapsed,
												[classes.collapseIconContainerFocus]: focus
											})}
										>
											<IconButton
												onClick={() => setCollapsed(collapsed => !collapsed)}
												classes={{ root: classes.collapseIconRoot }}
												disableRipple
												onFocusVisible={() => setFocus(true)}
												onBlur={() => setFocus(false)}
												onMouseDown={() => setFocus(true)}
												onMouseUp={() => setFocus(false)}
												onMouseLeave={() => setFocus(false)}
											>
												<ArrowForwardIosIcon color="primary" className={classnames(classes.collapseIcon, { [classes.collapseIconCollapsed]: collapsed })} />
											</IconButton>
										</Grid>
										<Grid item container justify="center" alignContent="center" className={classes.title}>
											<Typography component="h3" variant="h3">{toI18N(title)}</Typography>
										</Grid>
										<Grid item className={classes.closeIcon}>
											<IconButton onClick={() => closePanel(false)}>
												<CloseIcon />
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
								{(!collapsed || (collapsed && persistOnCollapse)) && <>
									<Grid item container  className={classes.content} style={{height: isTablette ? 'calc(100vh - 300px)' : ((actions && actions.length > 0) ? 'calc(100vh - 220px)' : '100vh')}}>
										{content}
									</Grid>
									{actions && actions.length > 0 && <Grid item container justify="center" alignItems="center" className={isTablette ? classes.actionsTablette : classes.actions}>
										{actions.map((action, index) => <Grid item key={index}>
											{React.cloneElement(action, {className: classes.actionButton})}
										</Grid>)}
									</Grid>}
								</>}
							</Grid>,
							closePanel
						)
					}
				</Paper>
			</Slide>)}}
		</DetailsPanelContext.Consumer>
	)
}

DetailsPanel.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	collapsed: PropTypes.bool,
	setCollapsed: PropTypes.func
}

export default compose(
	withStyles(styles)
)(DetailsPanel)
