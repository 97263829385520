import { createSelector } from 'reselect'

const getLocalState = (state) => state.planningToolbar

export const getToolbarState = type => createSelector(
	getLocalState,
	state => state[type]
)

export const getFilterToolbarState = createSelector(
	getLocalState,
	state => state && state.filter
)

export const getFilterValues = createSelector(
	getFilterToolbarState,
	state => state.currentFilter
)

export const getSortState = createSelector(
	getLocalState,
	state => state && state.sort
)

export const getSortValue = createSelector(
	getSortState,
	state => state.currentSort
)

export const getPlanningSize = createSelector(
	getLocalState,
	state => state.planningSize
)
