import { fetchFactory, urlUtils } from 'isotope-client'

/**
 * Récupération de la liste des cultures et itks liés
 */
export const getCultureItkList = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/culture-itk', filters))

/**
 * Mise à jour d'un itk
 */
export const updateItk = (idCulture, values) => fetchFactory('/bo/culture-itk/itk', {
	method: 'PUT',
	body: JSON.stringify({
		idCulture,
		...values
	})
})

/**
 * Création d'un itk
 */
export const createItk = (values) => fetchFactory('/bo/culture-itk/itk', {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * Mise à jour d'une culture
 */
export const updateCulture = (idFerme, values) => fetchFactory('/bo/culture-itk/culture', {
	method: 'PUT',
	body: JSON.stringify({
		idFerme,
		...values
	})
})

/**
 * Création d'une culture
 */
export const createCulture = (idFerme, values) => fetchFactory('/bo/culture-itk/culture', {
	method: 'POST',
	body: JSON.stringify({
		idFerme,
		...values
	})
})