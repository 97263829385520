import React from 'react'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import { Grid, IconButton, withStyles, useTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const styles = theme => getStyles({
	container: {
		border: '1px solid #c8dcdc',
		borderRadius: 5,
		padding: 5,
		marginBottom: 15,
		cursor: 'pointer'
	},
	selected: {
		backgroundColor: '#f1ecf2'
	},
	icon: {
		color: theme.palette.primary.main,
		padding: 5,
		fontSize: '30px !important',
	},
	info: {
		paddingLeft: 10
	},
	bold: {
		fontWeight: 'bold'
	}
})

/**
 * Composant tuile
 * Lit un objet de type :
 * uniqueId: 'bloc-1',
	icon: DashboardIcon,
	title: 'Bloc 1',
	subTitle: 'Bloc 1 -subtitle',
	description: 'Bloc description',
	data: {},
	children: []
 */
const Tuile = ({ tuile, selection, handleSelection, classes }) => {
	const [collapse, setCollapse] = React.useState(false)
	const Element = tuile.icon
	const hasChildren = tuile.children && tuile.children.length > 0
	const isSelected = selection && selection.uniqueId === tuile.uniqueId
	const theme = useTheme()

	return (<>
		<Grid
			item
			container
			className={classnames(classes.container, { [classes.selected]: isSelected })}
			onClick={() => {
				if (hasChildren) {
					setCollapse(!collapse)
				}
				handleSelection(tuile)
			}}
			alignItems="center"
		>
			<Grid item xs={1}>
				<Element className={classes.icon} style={{ color: tuile?.iconColor ? tuile?.iconColor : theme.palette.primary.main }} color={theme.palette.primary.main} width={30} height={30} />
			</Grid>
			<Grid item xs={10} container direction="column" className={classes.info}>
				<Typography variant="body1"><span className={classes.bold} >{tuile.title}</span>{tuile.subtitle && <span>{` ${tuile.subtitle}`}</span>}</Typography>
				<Typography variant="body1">{tuile.description}</Typography>
			</Grid>
			{hasChildren && <Grid item xs={1}>
				<IconButton className={classes.icon} onClick={(e) => {
					e.stopPropagation()
					setCollapse(!collapse)
				}}>
					{collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
				</IconButton>
			</Grid>}
		</Grid>
		{collapse && tuile.children && tuile.children.map(child => <ConnectedTuile tuile={child} handleSelection={handleSelection} selection={selection} />)}
	</>)
}

Tuile.propTypes = {
	tuile: PropTypes.object,
	selection: PropTypes.string,
	handleSelection: PropTypes.object,
	classes: PropTypes.object
}

const ConnectedTuile = withStyles(styles)(Tuile)

export default ConnectedTuile
