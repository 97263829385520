import React from 'react'
import {Bar} from 'react-chartjs-2'
import {LocalDate} from '../../../../../utils/date/local-date'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {fillGapsX} from '../../utils/chartUtils'

const TYPES = {
	MOINS_DEUX: 'moinsDeux',
	MOINS_UN: 'moinsUn',
	ZERO: 'zero',
	PLUS_UN: 'plusUn',
	PLUS_DEUX: 'plusDeux',
	PLUS_TROIS: 'plusTrois',
	PLUS_QUATRE_OU_PLUS: 'plusQuatreOuPlus'
}

const getDatasetColor = (type) => {
	switch (type) {
		case TYPES.MOINS_DEUX:
			return '#8ECAE6'
		case TYPES.MOINS_UN:
			return '#219EBC'
		case TYPES.ZERO:
			return '#023047'
		case TYPES.PLUS_UN:
			return '#FFB703'
		case TYPES.PLUS_DEUX:
			return '#FB8500'
		case TYPES.PLUS_TROIS:
			return '#D62828'
		case TYPES.PLUS_QUATRE_OU_PLUS:
			return '#780000'
	}
}

const TachesReportOuAnticipation = ({datas}) => {
	const intl = useIntl()

	const data = {
		labels: datas.map(d => intl.formatMessage({id: 'gestionTaches.ecranSynthese.week'}, {weekNumber: LocalDate.fromAPI(d.dateDebut).weekNumber})),
		datasets: Object.values(TYPES).map(t => ({
			label: intl.formatMessage({id: `gestionTaches.ecranSynthese.tachesReportOuAnticipation.type.${t}`}),
			data: datas.map(d => d[t]),
			backgroundColor: getDatasetColor(t),
			borderRadius: 5
		}))
	}

	const options = {
		scales: {
			x: {
				stacked: true,
				title: {
					display: true,
					text: intl.formatMessage({id: 'gestionTaches.ecranSynthese.tachesReportOuAnticipation.graph.charge'}),
					font: {
						family: 'Lato'
					}
				},
				grid: {
					drawBorder: false
				}
			},
			y: {
				stacked: true,
				grid: {
					display: false
				},
				ticks: {
					font: {
						family: 'Lato'
					}
				}
			}
		},
		indexAxis: 'y',
		plugins: {
			legend: {
				position: 'right',
				labels: {
					usePointStyle: true,
					font: {
						family: 'Lato'
					}
				}
			},
			tooltip: {
				usePointStyle: true,
				callbacks: {
					label: (context) => {
						return context.dataset.label + ': ' + context.formattedValue + 'h (' + datas[context.dataIndex][`${Object.values(TYPES)[context.datasetIndex]}NbTaches`] + ' tâches)'
					}
				}
			}
		}
	}

	return (
		<Grid container item direction="column" alignItems="center" style={{gap: 30, paddingTop: 40, paddingRight: 60}}>
			<Grid container item direction="column" alignItems="flex-start" style={{gap: 10}}>
				<Typography variant="h2">
					<FormattedMessage id="gestionTaches.ecranSynthese.tachesReportOuAnticipation.title"/>
				</Typography>
				<Typography style={{fontSize: 12, color: 'gray'}}>
					<FormattedMessage id="gestionTaches.ecranSynthese.tachesReportOuAnticipation.subtitle"/>
				</Typography>
			</Grid>
			<Bar options={options} data={data} plugins={[fillGapsX]}/>
			<Table>
				<TableHead>
					<TableRow style={{backgroundColor: 'unset'}}>
						<TableCell>
							<i><FormattedMessage id='gestionTaches.ecranSynthese.tachesReportOuAnticipation.table.semaine'/></i>
						</TableCell>
						<TableCell>
							<Grid container justify="center" alignItems="center">
								<span style={{width: 42, height: 7, background: `linear-gradient(to right, ${getDatasetColor(TYPES.MOINS_DEUX)} 0%, ${getDatasetColor(TYPES.MOINS_DEUX)} 50%, ${getDatasetColor(TYPES.MOINS_UN)} 50%, ${getDatasetColor(TYPES.MOINS_UN)} 100%)`, marginRight: 10}}></span>
								<FormattedMessage id='gestionTaches.ecranSynthese.tachesReportOuAnticipation.table.tachesAnticipee'/>
							</Grid>
						</TableCell>
						<TableCell>
							<Grid container justify="center" alignItems="center">
								<span style={{width: 42, height: 7, backgroundColor: getDatasetColor(TYPES.ZERO), marginRight: 10}}></span>
								<FormattedMessage id='gestionTaches.ecranSynthese.tachesReportOuAnticipation.table.tachesDansDelais'/>
							</Grid>
						</TableCell>
						<TableCell>
							<Grid container justify="center" alignItems="center">
								<span style={{width: 42, height: 7, background: `linear-gradient(to right, ${getDatasetColor(TYPES.PLUS_UN)} 0%, ${getDatasetColor(TYPES.PLUS_UN)} 25%, ${getDatasetColor(TYPES.PLUS_DEUX)} 25%, ${getDatasetColor(TYPES.PLUS_DEUX)} 50%, ${getDatasetColor(TYPES.PLUS_TROIS)} 50%, ${getDatasetColor(TYPES.PLUS_TROIS)} 75%, ${getDatasetColor(TYPES.PLUS_QUATRE_OU_PLUS)} 75%, ${getDatasetColor(TYPES.PLUS_QUATRE_OU_PLUS)} 100%)`, marginRight: 10}}></span>
								<FormattedMessage id='gestionTaches.ecranSynthese.tachesReportOuAnticipation.table.tachesReportees'/>
							</Grid>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{datas.map(d => (
						<TableRow style={{height: 30}}>
							<TableCell align="center">
								<FormattedMessage id='gestionTaches.ecranSynthese.week' values={{weekNumber: LocalDate.fromAPI(d.dateDebut).weekNumber}}/>
							</TableCell>
							<TableCell align="center"><b>{d.moinsDeux + d.moinsUn}h</b></TableCell>
							<TableCell align="center"><b>{d.zero}h</b></TableCell>
							<TableCell align="center"><b>{d.plusUn + d.plusDeux + d.plusTrois + d.plusQuatreOuPlus}h</b></TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Grid>
	)
}

export default TachesReportOuAnticipation