import { Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import Button from '../../../../../../../components/Button'
import classnames from 'classnames'
import { colors } from '../../../../../../../utils/constants'
import { BIO_AGGRESSOR_TYPE } from '../../tool/suiviParBloc.constants'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

/**
 * Styles
 */
const styles = () => getStyles({
	container: {
		gap: 15
	},
	pestContainer: {
		gap: 15
	},
	bioAggressor: {
		minWidth: 100,
		width: 100,
		height: 100,
		gap: 6,
		padding: '0px 6px',
		borderRadius: 15,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: 'transparent',
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		}
	},
	selectedBioAggressor: {
		color: '#fff',
		textColor: '#fff',
		borderColor: 'transparent',
		backgroundColor: colors.primary
	},
	inactiveBioAggressor: {
		color: colors.primary,
		textColor: '#000',
		borderColor: colors.lightPrimary,
		backgroundColor: '#fff'
	},
	bioAggressorLabelContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	bioAggressorLabel: {
		fontSize: 15,
		lineHeight: '18px',
		fontWeight: '400'
	},
	selectedBioAggressorLabel: {
		color: '#fff'
	},
	inactiveBioAggressorLabel: {
		color: '#000'
	},
	errorText: {
		color: colors.error,
		fontSize: 11
	},
	link: {
		'&.MuiTypography-root': {
			color: colors.link,
			textDecoration: 'underline',
			cursor: 'pointer',
			verticalAlign: 'center'
		}
	}
})

/**
 * BioAggressorsBoardsInput
 * @param onChange
 * @param error
 * @param bioAggressors
 * @returns {JSX.Element}
 * @constructor
 */
const BioAggressorsBoardsInput = ({
	input: { onChange, value },
	meta: { error, submitFailed },
	bioAggressors,
	classes
}) => {
	const [isCollapsed, setIsCollapsed] = useState(true)

	const renderBioAggressors = (type) => (
		<Grid container justify="center" columns={3} className={classes.pestContainer}>
			{
				bioAggressors.filter(bA => bA.type === type.code)
					.map((bA) => {
						const isSelected = value === bA.id
						const Icon = type.icon
						return (
							<Button
								key={`${bA.id}`}
								type="primary"
								keepCase
								withBorder={false}
								className={classnames(classes.bioAggressor, isSelected ? classes.selectedBioAggressor : classes.inactiveBioAggressor)}
								onClick={() => onChange(bA.id)}
							>
								<div className={classes.bioAggressorLabelContent}>
									<Icon style={{ height: 40, width: 40 }} />
										{bA.label}
								</div>
							</Button>
						)
					})
			}
		</Grid>
	)

	return (
		<Grid container className={classes.container}>
			<Grid container direction="row" alignContent="center">
				{isCollapsed ? <ArrowDropDown color="primary" /> : <ArrowDropUp color="primary" />}
				<Typography variant="body1" className={classes.link} onClick={() => setIsCollapsed(prev => !prev)}>
					<FormattedMessage id="tourPlaine.suiviParBloc.bio.panel.listBio" />
				</Typography>
			</Grid>
			{isCollapsed && (
				<Grid container className={classes.pestContainer}>
					{renderBioAggressors(BIO_AGGRESSOR_TYPE.SOIL_BORNE)}
					{renderBioAggressors(BIO_AGGRESSOR_TYPE.MAMMAL)}
				</Grid>
			)}
			{(error && submitFailed) && <Typography className={classes.errorText}><FormattedMessage id={error.id} /></Typography>}
		</Grid>
	)
}

BioAggressorsBoardsInput.propType = {
	input: PropTypes.object,
	meta: PropTypes.object,
	bioAggressors: PropTypes.array,
	classes: PropTypes.object
}
export default compose(
	withStyles(styles)
)
(BioAggressorsBoardsInput)
