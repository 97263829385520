import React from 'react'
import {Bar} from 'react-chartjs-2'
import {LocalDate} from '../../../../../utils/date/local-date'
import {FormattedMessage, useIntl} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {fillGapsY} from '../../utils/chartUtils'

const FAMILLES = {
	DES: 'DES',
	RECOLTE: 'RECOLTE',
	SEMIS: 'SEMIS',
	IMPLANTATION: 'IMPLANTATION',
	AUTRES: 'AUTRES',
	PREP_PLANCHE: 'PREP_PLANCHE',
	SOINS_PROTECTION: 'SOINS_PROTECTION'
}

const getDatasetColor = (type) => {
	switch (type) {
		case FAMILLES.SOINS_PROTECTION:
			return '#1A8FE3'
		case FAMILLES.PREP_PLANCHE:
			return '#87CBAC'
		case FAMILLES.DES:
			return '#044389'
		case FAMILLES.AUTRES:
			return '#1C7C54'
		case FAMILLES.RECOLTE:
			return '#E6C229'
		case FAMILLES.SEMIS:
			return '#F17105'
		case FAMILLES.IMPLANTATION:
			return '#17C3B2'
	}
}

const RepartitionTotale = ({repartitionTotale}) => {
	return (
		<Grid container item direction="column" xs justify="center">
			{repartitionTotale.map(r => <Grid container item>
				<Grid container item xs={8} alignItems="center" justify="flex-start">
					<span style={{width: 10, height: 10, borderRadius: '100%', backgroundColor: getDatasetColor(r.type), marginRight: 10}}></span>
					<Typography>
						<FormattedMessage id={`gestionTaches.ecranSynthese.tachesParFamille.famille.${r.type}`}/>
					</Typography>
				</Grid>
				<Grid container item xs={4} justify="center">
					<Typography><b>{r.repartition}h</b></Typography>
				</Grid>
			</Grid>)}
		</Grid>
	)
}

const TachesParFamille = ({datas}) => {
	const intl = useIntl()

	const data = {
		labels: datas.map(d => intl.formatMessage({id: 'gestionTaches.ecranSynthese.week'}, {weekNumber: LocalDate.fromAPI(d.dateDebut).weekNumber})),
		datasets: Object.keys(FAMILLES).map(t => ({
			label: intl.formatMessage({id: `gestionTaches.ecranSynthese.tachesParFamille.famille.${t}`}),
			data: datas.map(d => d.repartition[t]),
			backgroundColor: getDatasetColor(t),
			borderRadius: 5
		}))
	}

	const repartitionTotale = Object.keys(FAMILLES).map(type => ({
		type,
		repartition: datas.map(d => d.repartition[type]).reduce((a, b) => a + b),
	}))

	const options = {
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false
				},
				ticks: {
					font: {
						family: 'Lato'
					}
				}
			},
			y: {
				stacked: true,
				title: {
					display: true,
					text: intl.formatMessage({id: 'gestionTaches.ecranSynthese.tachesParFamille.graph.nbHeures'}),
					font: {
						family: 'Lato'
					}
				},
				grid: {
					drawBorder: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				usePointStyle: true,
				callbacks: {
					label: (context) => {
						return context.dataset.label + ': ' + context.formattedValue + 'h (' + datas[context.dataIndex].repartitionTache[Object.values(FAMILLES)[context.datasetIndex]] + ' tâches)'
					}
				}
			}
		}
	}

	return (
		<Grid container item direction="column" alignItems="center" style={{gap: 30, paddingTop: 40, paddingRight: 60}}>
			<Grid container item direction="column" alignItems="flex-start" style={{gap: 10}}>
				<Typography variant="h2">
					<FormattedMessage id="gestionTaches.ecranSynthese.tachesParFamille.title"/>
				</Typography>
				<Typography style={{fontSize: 12, color: 'gray'}}>
					<FormattedMessage id="gestionTaches.ecranSynthese.tachesParFamille.subtitle"/>
				</Typography>
			</Grid>
			<Bar options={options} data={data} plugins={[fillGapsY]}/>
			<Grid container item direction="column" alignItems="center" style={{gap: 20}}>
				<Typography variant="h5">
					<FormattedMessage id="gestionTaches.ecranSynthese.tachesParFamille.detail"/>
				</Typography>
				<Grid container item direction="row" style={{paddingLeft: '10%', paddingRight: '10%'}}>
					<RepartitionTotale repartitionTotale={repartitionTotale.slice(0, Math.ceil(repartitionTotale.length / 2))}/>
					<RepartitionTotale repartitionTotale={repartitionTotale.slice(Math.ceil(repartitionTotale.length / 2), repartitionTotale.length)}/>
				</Grid>
			</Grid>
		</Grid>
	)

}

export default TachesParFamille