import GardenerIcon from '../../../../../../components/icon/GardenerIcon'
import PotIcon from '../../../../../../components/icon/PotIcon'

export const SP_TASKS_CONTENT_FORM_NAME = 'SP_TASKS_CONTENT_FORM_NAME'
export const SP_TASKS_CONTENT_FORM_FIELDS = {
	TASK_TRANSPLANTING: 'SP_TASK_TRANSPLANTING',
	TASK_WAITING: 'SP_TASK_WAITING'
}

export const SP_BLOCK_ACTION_TYPE = {
	CROPS: { code: 'crops', icon: PotIcon, label: { id: 'tourPlaine.suiviPepiniere.actionTab.crops' }, notif: 0 },
	TASK: { code: 'tasks', icon: GardenerIcon, label: { id: 'tourPlaine.suiviPepiniere.actionTab.tasks' }, notif: 0 }
}

export const SP_PLANIFICATEUR_DATATABLE_KEYS = {
	CATEGORY: 'category',
	CODE: 'code',
	DESCRIPTION: 'desription',
	KEY: 'key',
	LEVEL: 'level',
	VALUE: 'value',
	ACTIVE: 'active'
}

export const SP_PLANIFICATEUR_DATATABLE_KEYS_PARAMS = {
	PARAM: 'param',
	VALUE: 'value',
}