import { compose, setDisplayName, withProps, wrapDisplayName } from 'recompose'
import { toI18N } from '../../utils/utils'

/**
 * Passe les attributs `error` et `warning` dans leur version internationalisée.
 *
 * @param Component un field redux-form
 */
const mapError = Component => compose(
	setDisplayName(wrapDisplayName(Component, 'toI18N')),
	withProps(({
		           meta: {
			           error,
			           warning,
			           ...others
		           }
	           }) => ({
		meta: {
			error: error ? toI18N(error) : error,
			warning: warning ? toI18N(warning) : warning,
			...others
		}
	}))
)(Component)

export default mapError
