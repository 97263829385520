import React from 'react'
import {ONGLETS_BESOINS_PRODUCTION} from '../../../utils/constants'
import Header from './component/header/Header'
import {useBesoinsProductionContext} from './BesoinsProductionProvider'
import OngletRecueil from './component/recueil/OngletRecueil'
import OngletResultat from './component/resultat/OngletResultat'
import {getAllCampagnes} from "../planning/services/planningApi";

const BesoinsProductionComponent = ({location}) => {
	const {onglet, setOnglet} = useBesoinsProductionContext()
	const [campagnesActives, setCampagnesActives] = React.useState([])
	const [campagnesCloturees, setCampagnesCloturees] = React.useState([])
	const [isBesoinNotSaved, setIsBesoinNotSaved] = React.useState(false)

	React.useEffect(() => {
		getAllCampagnes().then(response => {
			const campagnesActives = response.filter(campagne => !campagne.finished)
			setCampagnesCloturees(response.filter(campagne => !campagnesActives.includes(campagne)))
			setCampagnesActives(campagnesActives)
			const search = new URLSearchParams(location.search)
			if (search && search.get('onglet')) {
				if (search.get('onglet') === 'resultats') {
					setOnglet(ONGLETS_BESOINS_PRODUCTION.RESULTATS)
				}
				if (search.get('onglet') === 'recueil') {
					setOnglet(ONGLETS_BESOINS_PRODUCTION.RECUEIL)
				}
			}
		})
	}, [])

	return (
		<div>
			<Header campagnes={campagnesActives} isBesoinNotSaved={isBesoinNotSaved}/>
			<div>
				{onglet === ONGLETS_BESOINS_PRODUCTION.RECUEIL && (campagnesActives.length > 0 || campagnesCloturees.length > 0) && <OngletRecueil
					campagnesActives={campagnesActives}
					campagnesCloturees={campagnesCloturees}
					setIsBesoinNotSaved={setIsBesoinNotSaved}
					isBesoinNotSaved={isBesoinNotSaved}
				/>}
				{onglet === ONGLETS_BESOINS_PRODUCTION.RESULTATS && campagnesActives.length > 0 && <OngletResultat campagnes={campagnesActives}/>}
			</div>
		</div>
	)
}

export default BesoinsProductionComponent
