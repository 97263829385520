import React, { useEffect, useState } from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../../../../utils/constants'
import { MAX_WEEKS } from '../../tool/tourPlaine.constants'
import Button from '../../../../../components/Button'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import CallMergeIcon from '@material-ui/icons/CallMerge'
import { useTourPlaineContext } from '../../TourPlaineContext'
import { DateFormat } from '../../../../../utils/dateConstants'
import moment from 'moment'
import { NUMERO_JOURS_SEMAINE } from '../../../gestionTache/utils/constants'
import { useIntl } from 'react-intl'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '../../../../../components/IconButton'
import Switch from '../../../../../components/Switch'
import classnames from 'classnames'
import WindMillIcon from '../../../../../components/icon/WindMillIcon'
import { FORM_NAME_CREATION } from '../../../../bo/campagne/services/CampagneForm'
import { reduxForm } from 'redux-form'
import { INSIDE_TILES, OUTSIDE_TILES, PERIOD_SWITCH } from './tool/contextePedoclimatique.constants'
import { ZONE_TYPE } from '../../tool/tourPlaine.constants'
import WeatherTile from './component/WeatherTile'
import PropTypes from "prop-types";

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		marginTop: '20px',
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '10px'
	},
	rootDashboard: {
		background: colors.etapeContrastBackground,
		marginTop: 0,
		borderRadius: '12px',
		padding: 0
	},
	header: {
		rowGap: '10px',
		padding: '10px 12px'
	},
	titleContainer: {
		padding: '0px 12px',
		columnGap: '12px'
	},
	titleText: {
		lineHeight: '25px',
		fontSize: 18,
		fontWeight: '600',
		color: colors.primary
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	body: {
		overflowY: 'hidden',
		rowGap: 10,
		marginTop: 10,
		maxHeight: '-webkit-fill-available'
	},
	selectorWeekContainer: {
		gap: 20
	},
	selectorIconContainer: {
		height: 35,
		width: 35
	},
	selectorText: {
		fontStyle: 'normal',
		lineHeight: '18px',
		fontSize: 14,
		fontWeight: '600',
		color: '#000000',
		marginBottom: 4,
		textAlign: 'center'
	},
	selectorSwitch: {
		position: 'absolute',
		right: 34
	},
	daysContainer: {
		columnGap: 10
	},
	dayBtn: {
		minWidth: 150,
		maxWidth: 150,
		width: 150,
		borderRadius: 10,
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		},
		fontStyle: 'normal',
		fontWeight: '600',
		textAlign: 'center',
		verticalAlign: 'bottom'
	},
	dashboardDayButton: {
		minWidth: 95,
		maxWidth: 95,
		width: 95,
		borderRadius: 10,
		'&:hover': {
			color: 'white',
			backgroundColor: colors.primary
		},
		'&:active': {
			color: 'white',
			backgroundColor: colors.primary
		},
		fontStyle: 'normal',
		fontWeight: '600',
		textAlign: 'center',
		verticalAlign: 'bottom'
	},
	daySelectedBtn: {
		backgroundColor: colors.primary,
		color: '#ffffff',
		lineHeight: '18px',
		fontSize: 14,
		height: 57,
		padding: '35px 10px'
	},
	dayInactiveBtn: {
		backgroundColor: 'transparent',
		color: colors.primary,
		lineHeight: '12px',
		fontSize: 10,
		padding: '35px 10px'
	},
	weatherContainer: {
		rowGap: 20,
		marginBottom: 10,
		maxHeight: '-webkit-fill-available'
	},
	weatherWeekContainer: {
		padding: '0 112px'
	},
	weatherDaysContainer: {
		padding: '0 80px'
	},
	weatherWeekContainerDashboard: {
		padding: '0'
	},
	weatherDaysContainerDashboard: {
		padding:  '0'
	},
	weatherZoneRowContainer: {
		columnGap: 25
	},
	weatherZoneContainer: {
		columnGap: 2
	},
	weatherZoneRowContainerDashboard: {
		columnGap: 10
	},
	weatherZoneContainerDashboard: {
		columnGap: 3
	},
	dayLabelContainer: {
		maxWidth: 115
	},
	dayLabel: {
		fontWeight: 400,
		lineHeight: '17px',
		fontSize: 14,
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	dayIconBtn: {
		marginLeft: 10,
		height: 25,
		width: 25,
		border: `1px solid ${colors.primary}`
	},
	zoneTitleContainer: {
		marginBottom: 5
	}
})

/**
 * ContextePedoclimatique
 * @returns {JSX.Element}
 */
const ContextePedoclimatique = ({ classes, isDashboard }) => {
	const { formatMessage } = useIntl()
	const { currentWeek: toolBarWeek, data: { contexte } } = useTourPlaineContext()

	/**
	 * UseState
	 */
	const [currentWeekId, setCurrentWeekId] = useState(toolBarWeek)
	const [showDays, setShowDays] = useState(true)
	const [selectedDay, setSelectedDay] = useState(moment().format(DateFormat.YYYY_MM_DD))
	const [isDaySplitted, setIsDaySplitted] = useState({})

	const startOftheToolBarWeek = moment(toolBarWeek, DateFormat.YYYY_MM_DD).startOf('isoWeeks')

	/**
	 * Data for the week
	 */
	const weatherWeeklyData = contexte ? contexte[currentWeekId] : undefined

	/**
	 * Weeks for the selector N to N+3
	 * @type {{id: string, label: *}[]}
	 */
	const weekOptions = [...Array(MAX_WEEKS)].map((_, i) => ({
			id: moment(startOftheToolBarWeek).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.YYYY_MM_DD),
			label: formatMessage({ id: 'tourPlaine.contextePedoclimatique.selector.week' },
				{
					weekNumber: moment(startOftheToolBarWeek).add(i, 'weeks').week(),
					start: moment(startOftheToolBarWeek).add(i, 'weeks').startOf('isoWeeks').format(DateFormat.DD_MM_SLASH),
					end: moment(startOftheToolBarWeek).add(i, 'weeks').endOf('isoWeeks').format(DateFormat.DD_MM_SLASH),
					b: (chunks) => <Typography className={classes.selectorText}>{chunks}</Typography>
				})
		})
	)

	const weekSelectorTitle = weekOptions.find(weekOption => weekOption.id === currentWeekId)

	const startOftheWeek = moment(currentWeekId, DateFormat.YYYY_MM_DD).startOf('isoWeeks')

	/**
	 * WeekDays
	 * @type {{id: string, label: string}[]}
	 */
	const weekDays = [...Object.values(NUMERO_JOURS_SEMAINE)].map((value) => ({
			id: startOftheWeek.clone().add(value, 'days').format(DateFormat.YYYY_MM_DD),
			label: startOftheWeek.clone().add(value, 'days').locale('fr').format(DateFormat.dddd_Do_MMMM)
		})
	)

	const canGoNextWeek = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) >= 0 ? weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) + 1 < weekOptions.length : false

	// Go to the next week if possible
	const goNextWeek = () => {
		const index = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId)
		if (index + 1 < weekOptions.length) {
			setSelectedDay(prev => moment(prev, DateFormat.YYYY_MM_DD).add(1, 'week').format(DateFormat.YYYY_MM_DD))
			setCurrentWeekId(weekOptions[index + 1].id)
		}
	}

	const canGoPreviousWeek = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) > 0 ? weekOptions.findIndex(weekOption => weekOption.id === currentWeekId) - 1 >= 0 : false

	// Go to the previous week if possible
	const goPreviousWeek = () => {
		const index = weekOptions.findIndex(weekOption => weekOption.id === currentWeekId)
		if (index - 1 >= 0) {
			setSelectedDay(prev => moment(prev, DateFormat.YYYY_MM_DD).subtract(1, 'week').format(DateFormat.YYYY_MM_DD))
			setCurrentWeekId(weekOptions[index - 1].id)
		}
	}

	// Switch select action
	const switchAction = (item) => setShowDays(item.id === PERIOD_SWITCH[0].id)

	// Render a weather row
	const renderWeatherRow = () => {
		if (!weatherWeeklyData) {
			return null
		}

		// filter for one day or the whole week
		const dayKeys = showDays ? Object.keys(weatherWeeklyData).filter(key => key === selectedDay) : Object.keys(weatherWeeklyData)

		if (!dayKeys.length) {
			return null
		}

		return dayKeys.map((dayKey, index) => {
				const dayData = weatherWeeklyData[dayKey]
				const isSplitted = isDaySplitted[dayKey]

				const Icon = isSplitted ? CallMergeIcon : CallSplitIcon
				return (
					<Grid
						key={dayKey}
						item
						container
						direction="row"
						alignItems="center"
						justify="center"
						className={isDashboard ? classes.weatherZoneRowContainerDashboard : classes.weatherZoneRowContainer}
					>
						{!showDays &&
							(
								<Grid
									item
									container
									direction="row"
									alignItems="center"
									justify="center"
									className={classes.dayLabelContainer}
								>
									<Typography className={classes.dayLabel}>
										{moment(dayKey, DateFormat.YYYY_MM_DD).locale('fr').format(DateFormat.dddd)}
									</Typography>
									<IconButton className={classes.dayIconBtn} onClick={() => setIsDaySplitted(prev => ({
										...prev,
										[dayKey]: !prev[dayKey]
									}))}>
										<Icon style={{ width: '14px', height: '14px', color: colors.primary }} />
									</IconButton>
								</Grid>
							)
						}
						<Grid item xs container direction="column" alignItems="flex-end" style={{ rowGap: showDays ? 5 : 20 }}>
							<Grid item container direction="row" justify={isDashboard ? "center" : "flex-end"} className={isDashboard ? classes.weatherZoneContainerDashboard : classes.weatherZoneContainer}>
								{
									Object.values(INSIDE_TILES).map(tileType => (
											<WeatherTile
												key={`${tileType}_${ZONE_TYPE.INSIDE}_${isSplitted}`}
												dayKey={dayKey}
												type={tileType}
												zone={ZONE_TYPE.INSIDE}
												data={dayData}
												isDaySplitted={isSplitted}
												weekMode={!showDays}
												isDashboard={isDashboard}
											/>
										)
									)
								}
							</Grid>
						</Grid>
						<Grid item xs container direction="column" alignItems="flex-start" style={{ rowGap: showDays ? 5 : 20 }}>
							<Grid item container xs direction="row" justify={isDashboard ? "center" : "flex-start"} className={isDashboard ? classes.weatherZoneContainerDashboard : classes.weatherZoneContainer}>
								{
									Object.values(OUTSIDE_TILES).map(tileType => (
											<WeatherTile
												key={`${tileType}_${ZONE_TYPE.OUTSIDE}_${isSplitted}`}
												dayKey={dayKey}
												type={tileType}
												zone={ZONE_TYPE.OUTSIDE}
												data={dayData}
												isDaySplitted={isSplitted}
												weekMode={!showDays}
												isDashboard={isDashboard}
											/>
										)
									)
								}
							</Grid>
						</Grid>
					</Grid>
				)
			}
		)
	}

	/**
	 * UseEffect
	 */
	useEffect(() => {
		// onChange
		setCurrentWeekId(toolBarWeek)
		// reset splitted day
		setIsDaySplitted({})

		// If today is before the first day of the week, select the first day of the week
		const startOfTheWeek = moment(toolBarWeek, DateFormat.YYYY_MM_DD).startOf('isoWeeks')
		if (moment().isBefore(startOfTheWeek)) {
			setSelectedDay(toolBarWeek)
		}
	}, [toolBarWeek])

	return (<div className={isDashboard ? classes.rootDashboard : classes.root}>
		{!isDashboard && (
		<Grid container className={classes.header}>
			<Grid item container direction="row" alignItems="center" className={classes.titleContainer}>
				<Grid item>
					<WindMillIcon height={24} width={24} />
				</Grid>
				<Grid item>
					<Typography className={classes.titleText}>
						<FormattedMessage id="tourPlaine.contextePedoclimatique.title" />
					</Typography>
				</Grid>
			</Grid>
			<Typography className={classes.descriptionText}>
				<FormattedMessage id="tourPlaine.contextePedoclimatique.description" />
			</Typography>
		</Grid>
		)}
		<Grid container className={classes.body}>
			{!isDashboard && (
				<Grid item container direction="row" justify="center" alignItems="center">
				<Grid item container xs />
				<Grid item container xs direction="row" justify="center" alignItems="center" className={classes.selectorWeekContainer}>
					<IconButton className={classes.selectorIconContainer} onClick={goPreviousWeek} disabled={!canGoPreviousWeek}>
						<ArrowBackIosIcon />
					</IconButton>
					<Typography className={classes.descriptionText}>
						{weekSelectorTitle?.label}
					</Typography>
					<IconButton className={classes.selectorIconContainer} onClick={goNextWeek} disabled={!canGoNextWeek}>
						<ArrowForwardIosIcon />
					</IconButton>
				</Grid>
				<Grid item container xs justify="flex-end" alignItems="flex-end">
					<Switch items={PERIOD_SWITCH} setValue={switchAction} defaultValue={PERIOD_SWITCH[0]} />
				</Grid>
			</Grid>
			)}
			{
				showDays && (
					<>
						<Grid item container direction="row" alignItems="center" justify="center" className={classes.daysContainer}>
							{
								weekDays.map((weekDay) => {
									const isSelected = weekDay.id === selectedDay
									return (
										<div className={isDashboard ? classes.dashboardDayButton : classes.dayBtn} key={`${weekDay.id}${isSelected}`}>
											<Button
												className={classnames(isDashboard ? classes.dashboardDayButton : classes.dayBtn, isSelected ? classes.daySelectedBtn : classes.dayInactiveBtn)}
												onClick={() => setSelectedDay(weekDay.id)}
												withBorder={false}
												variant="text"
												type="secondary"
											>
												{weekDay.label}
											</Button>
										</div>
									)
								})
							}
						</Grid>
						<Grid item container justify="flex-end">
							<Button
								type="secondary"
								startIcon={isDaySplitted[selectedDay] ? <CallMergeIcon /> : <CallSplitIcon />}
								onClick={() => setIsDaySplitted(prev => ({
									...prev,
									[selectedDay]: !prev[selectedDay]
								}))}
							>
								<FormattedMessage id={isDaySplitted[selectedDay] ? 'tourPlaine.contextePedoclimatique.action.fullDay' : 'tourPlaine.contextePedoclimatique.action.halfDay'} />
							</Button>
						</Grid>
					</>
				)
			}
			<Grid item container justify="flex-start" className={classnames(classes.weatherContainer, showDays ? ( isDashboard ? classes.weatherWeekContainerDashboard : classes.weatherWeekContainer) : ( isDashboard ? classes.weatherDaysContainerDashboard : classes.weatherDaysContainer))}>
				<Grid
					key="zone"
					item
					container
					direction="row"
					alignItems="center"
					justify="center"
					className={isDashboard ? classes.weatherZoneRowContainerDashboard : classes.weatherZoneRowContainer}
				>
					{!showDays &&
						(
							<Grid
								item
								container
								direction="row"
								alignItems="center"
								justify="center"
								className={classes.dayLabelContainer}
								style={{ marginTop: 70 }} //  text height 50 + row Gap 20
							/>
						)
					}
					<Grid item xs container direction="column" alignItems="flex-end" style={{ rowGap: showDays ? 5 : 20 }}>
						<Grid item container alignItems="center" justify="center" style={{ height: showDays ? 20 : 50, width: showDays ? (isDashboard ? '100%' : 400) : 350 }}>
							<Typography className={classes.dayLabel}>
								<FormattedMessage id="enums.zoneType.serre" />
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs container direction="column" alignItems="flex-start" style={{ rowGap: showDays ? 5 : 20 }}>
						<Grid item container alignItems="center" justify="center" style={{ height: showDays ? 20 : 50, width: showDays ? (isDashboard ? '100%' : 400) : 350 }}>
							<Typography className={classes.dayLabel}>
								<FormattedMessage id="enums.zoneType.exterieur" />
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container justify="flex-start" className={classnames(classes.weatherContainer, showDays ? (isDashboard ? classes.weatherWeekContainerDashboard : classes.weatherWeekContainer) : (isDashboard ? classes.weatherDaysContainerDashboard :classes.weatherDaysContainer))}>
				{renderWeatherRow()}
			</Grid>
		</Grid>
	</div>)
}

ContextePedoclimatique.propTypes = {
	isDashboard: PropTypes.bool
}

export default compose(
	reduxForm({
		form: FORM_NAME_CREATION,
		destroyOnUnmount: true,
		enableReinitialize: true
	}),
	withStyles(styles)
)(ContextePedoclimatique)