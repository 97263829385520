import React from 'react'
import PropTypes from 'prop-types'

const CultureIcon = ({ height, width }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M268.898 6.601C266.203 2.1 261.098 0 256 0s-10.203 2.1-12.898 6.601C240.7 11.102 181 115.099 181 242.899c0 1.5.3 2.701.601 4.2l56.1 200.099c1.798 6.601 7.8 10.801 14.399 11.1h.3c1.198 0 2.399-.298 3.6-.599 4.797-1.201 9.001-4.801 10.499-9.901l63.9-200.4c.3-1.199.601-3.9.601-5.4 0-126.899-59.7-230.896-62.102-235.397z"
				fill="#32d736"
			/>
			<g fill="#10bb67">
				<path d="M450.399 81.2l-38.101 133.499c-14.099 48.9-21.299 99.6-21.299 150.3 0 3.6-1.501 7.2-3.9 10.201l-120 131.699c0 .3-.3.601-.3.601h-.3v.3h-.297v.3h-20.405v-.3h-.297v-.3h-.3s-.3-.3-.3-.601L124.9 375.2c-2.399-3.001-3.9-6.601-3.9-10.201 0-50.7-7.2-101.4-21.299-150.3L61.601 81.2c-1.802-6.901 1.201-14.101 7.2-17.401 6.299-3.3 14.099-1.8 18.6 3.3l43.202 50.7C192.4 189.5 235.001 274.699 256 365.6c20.999-90.901 63.6-176.1 125.398-247.8L424.6 67.1c4.501-5.099 12.301-6.599 18.6-3.3 5.998 3.299 9.001 10.499 7.199 17.4z" />
				<path d="M331 242c0 1.5-.3 4.2-.601 5.4l-63.9 200.4c-1.498 5.099-5.702 8.699-10.499 9.901V0c5.098 0 10.203 2.1 12.898 6.601C271.3 11.102 331 115.099 331 242z" />
			</g>
			<path
				d="M450.399 81.2l-38.101 133.499c-14.099 48.9-21.299 99.6-21.299 150.3 0 3.6-1.501 7.2-3.9 10.201l-120 131.699c0 .3-.3.601-.3.601h-.3v.3h-.297v.3H256V365.6c20.999-90.901 63.6-176.1 125.398-247.8L424.6 67.1c4.501-5.099 12.301-6.599 18.6-3.3 5.998 3.299 9.001 10.499 7.199 17.4z"
				fill="#0ca656"
			/>
			<path
				d="M512 167v120c0 124.2-101.8 225-226 225h-60C101.8 512 0 411.2 0 287V167c0-7.2 5.098-13.5 12.297-14.7 6.903-1.201 14.103 2.399 16.501 9l30.3 75.3c18.604 46.5 49.9 87.599 89.198 119.099l34.805 27.599C212.201 406.7 236.499 434.9 256 466.7c19.501-31.8 43.799-60 72.898-83.401l34.805-27.599c39.298-31.5 69.595-72.599 88.198-119.099l31.3-75.3c2.399-6.601 9.298-10.501 16.501-9C506.902 153.5 512 159.8 512 167z"
				fill="#32d736"
			/>
			<path
				d="M512 167v120c0 124.2-101.8 225-226 225h-30v-45.3c19.501-31.8 43.799-60 72.898-83.401l34.805-27.599c39.298-31.5 69.595-72.599 88.198-119.099l31.3-75.3c2.399-6.601 9.298-10.501 16.501-9C506.902 153.5 512 159.8 512 167z"
				fill="#10bb67"
			/>
		</svg>
	)
}

CultureIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string
}

CultureIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default CultureIcon
