export const DISPONIBILITES = {
	DISPONIBLE: 'Disponible',
	INDISPONIBLE: 'Indisponible',
	INACTIF: 'Inactif',
}

export const TYPES_COMPETENCES = {
	PARTICIPANT: 'PARTICIPANT',
	MACHINISTE: 'MACHINISTE',
}

