import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import SimpleCell from './SimpleCell'

const styles = theme => getStyles({
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary
	},
	content: {
		paddingRight: 20,
		height: '100%',
		overflow: 'auto'
	}
})

/**
 * Simple List View inspiré du composant BoListView sur une seule colonne
 */
const SimpleListView = ({
	title,
	selection,
	handleSelection,
	datas,
	classes,
	maxHeight =  '100%'
}) => (
	<Grid container direction="column" style={{ maxHeight: maxHeight }}>
		{
			title
			&& (
				<Grid item container justify="flex-start" className={classes.title}>
					{title}
				</Grid>
			)
		}
		<Grid item container direction="column" wrap="nowrap" className={classes.content}>
			{datas.map(data => <SimpleCell key={`cell-${data.uniqueId}`} data={data} handleSelection={handleSelection} selection={selection} />)}
		</Grid>
	</Grid>
)


SimpleListView.propTypes = {
	title: PropTypes.string,
	selection: PropTypes.object,
	handleSelection: PropTypes.func,
	datas: PropTypes.array,
	classes: PropTypes.object,
	maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


export default compose(
	withStyles(styles)
)(SimpleListView)
