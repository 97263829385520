import React from 'react'
import MobileTachesView from './component/MobileTachesView'
import MobileActionView from './component/MobileActionView'
import { useMobileContext } from './MobileProvider'
import { ACTION_TYPE } from './utils/constantsSuiviTache'
import Grid from '@material-ui/core/Grid'

const MobileComponents = () => {
	const { actionType, tache } = useMobileContext()
	return (
		<Grid>
			{actionType === ACTION_TYPE.NONE
				? <MobileTachesView />
				: <MobileActionView actionType={actionType} tache={tache} />
			}
		</Grid>
	)
}

export default MobileComponents
