import {Fab, Grid, withStyles} from '@material-ui/core'
import React from 'react'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {useGestionRessourceContext} from '../../GestionRessourceProvider'
import {replace} from 'connected-react-router'
import {ONGLET_GESTION_DES_RESSOURCES} from '../../../../../utils/constants'
import {StepIcon, StyledConnector, StyledLabel} from '../../../../common/stepper/Stepper'
import {connect} from 'react-redux'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import InfoIcon from '@material-ui/icons/Info'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

const styles = () => getStyles({
	container: {
		width: 'auto',
		marginBottom: 8,
		height: 48,
		fontFamily: 'Lato',
		minWidth: 1150,
		maxWidth: '100%'
	},
	stepper: {
		padding: 0,
		marginTop: 17
	},
	title: {
		fontSize: 24
	},
	returnButton: {
		height: 35,
		width: 35,
		minHeight: 35,
		marginRight: 20
	},
	returnIcon: {
		height: 30,
		width: 30,
		transform: 'scaleX(-1)',
	}
})


const getSteps = () => {
	return [
		ONGLET_GESTION_DES_RESSOURCES.INFORMATIONS,
		ONGLET_GESTION_DES_RESSOURCES.CONTRAT,
		ONGLET_GESTION_DES_RESSOURCES.COMPETENCES
	]
}

export const HeaderStepIcon = (props) => {
	const icons = {
		1: <InfoIcon/>,
		2: <ImportContactsIcon/>,
		3: <EmojiEventsIcon/>
	}

	return (
		<StepIcon
			icons={icons}
			{...props}
		/>
	)
}

const HeaderAjoutRessource = ({classes, goBack}) => {
	const {ongletGestion} = useGestionRessourceContext()
	const steps = getSteps()

	return (
		<Grid container className={classes.container}>
			<Grid container item direction={"row"}>
				<Grid item>
					<Fab color="primary" onClick={goBack} className={classes.returnButton}>
						<ArrowRightAltIcon className={classes.returnIcon}/>
					</Fab>
				</Grid>
				<Grid item className={classes.title}>
					<FormattedMessage id={`gestionRessource.ajoutRessource.title`}/>
				</Grid>
			</Grid>
			<Grid container justify={"center"}>
				<Grid item container xs={12} md={4} lg={4} direction="row" justify="center" alignItems="center">
					<Stepper className={classes.stepper} alternativeLabel activeStep={ongletGestion.order}
							 connector={<StyledConnector/>}>
						{steps.map(onglet => (
							<Step key={onglet.code}>
								<StyledLabel StepIconComponent={HeaderStepIcon} large>
									<FormattedMessage id={`gestionRessource.ajoutRessource.onglets.${onglet.code.toLowerCase()}`}/>
								</StyledLabel>
							</Step>
						))}
					</Stepper>
				</Grid>
			</Grid>
		</Grid>)
}

HeaderAjoutRessource.propType = {
	classes: PropTypes.object
}

const actions = {
	goBack: () => dispatch => dispatch(replace('/gestion-ressources')),
}

export default compose(
	connect(null, actions),
	withStyles(styles),
)(HeaderAjoutRessource)