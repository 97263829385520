import Grid from '@material-ui/core/Grid'
import React from 'react'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import { Field, reduxForm } from 'redux-form'
import {ABSENCE_FORM, TYPE_ABSENCE} from '../../../../utils/constants'
import DateInput from '../../../../components/form/DateInput'
import {normalizeDate} from '../../../../utils/utils'
import { FormattedMessage } from 'react-intl'
import Input from '../../../../components/form/Input'
import Select from '../../../../components/form/Select'
import {MenuItem} from '@material-ui/core'

const styles = () => getStyles({
	form: {
		padding: '10px 5px 0px 5px',
		width: '100%',
		fontFamily: 'Lato'
	},
	field: {
		width: '50%'
	},
	fieldAll: {
		width: '100%'
	},
	fieldRow: {
		marginTop: 5
	},
})

const FIELDS = {
	COMMENTAIRE: { name: 'commentaire', optional: true },
	TYPE_ABSENCE: { name: 'typeAbsence' },
	DATE_DEBUT: { name: 'dateDebutIndispo' },
	DATE_FIN: { name: 'dateFinIndispo', optional: true },
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})

	return errors
}

const PopinAbsence = ({ classes, handleSubmit }) => {
	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.form} container>
				<Grid item container justify={"center"} className={classes.fieldRow}>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.DATE_DEBUT.name}
							component={DateInput}
							format={normalizeDate}
							type="date"
							label={<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.${FIELDS.DATE_DEBUT.name}`} />}
							fromBo
						/>
					</Grid>
					<Grid item className={classes.field}>
						<Field
							name={FIELDS.DATE_FIN.name}
							component={DateInput}
							format={normalizeDate}
							type="date"
							label={<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.${FIELDS.DATE_FIN.name}`} />}
							fromBo
						/>
					</Grid>
				</Grid>
				<Grid item container justify={"center"} className={classes.fieldRow}>
					<Grid item className={classes.fieldAll}>
						<Field
							name={FIELDS.TYPE_ABSENCE.name}
							component={Select}
							label={<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.${FIELDS.TYPE_ABSENCE.name}`} />}
							fromBo
						>
							{Object.keys(TYPE_ABSENCE).map(key => (
								<MenuItem key={key} value={key}>
									{<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.typeAbsences.${key.toLowerCase()}`} />}
								</MenuItem>
							))}
						</Field>
					</Grid>
				</Grid>
				<Grid item container justify={"center"} className={classes.fieldRow}>
					<Grid item className={classes.fieldAll}>
						<Field
							name={FIELDS.COMMENTAIRE.name}
							component={Input}
							label={<FormattedMessage id={`gestionRessource.detailRessource.absences.popin.${FIELDS.COMMENTAIRE.name}`} />}
							fromBo
						/>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
}

PopinAbsence.propTypes = {
	classes: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: ABSENCE_FORM,
		validate
	})
)(PopinAbsence)