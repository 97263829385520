import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import FormButton from '../../../../components/form/FormButton'
import ChatBubbleIcon from '../../../../components/icon/ChatBubbleIcon'
import { ActionPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import ReminderForm from '../component/panel/ReminderForm'
import { PANEL_FORM } from '../tool/tourPlaine.constants'
import { addReminder } from './tourPlaineApi'
import { addReminderMapToApi } from './tourPlaineApiMapper'

/**
 * Inject the Reminder Form
 */
export const injectReminderForm = (WrappedComponent) => {

	const ReminderFormInjector = ({  ...props }) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)

		const formActions = [
			<FormButton type="primary" formName={PANEL_FORM.REMINDER}>
				<FormattedMessage id="tourPlaine.form.submit" />
			</FormButton>
		]

		const openReminderForm = useCallback((inputLabel, setRefreshNote, refreshNote) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.form.reminder.title' },
				<ReminderForm
					inputLabel={inputLabel}
					onSubmit={values => {
						addReminder(addReminderMapToApi(values, inputLabel))
							.then(() => {
								snackSuccess({ id: 'snackbar.update.tourPlaine.reminder' })
								if (refreshNote !== undefined) {
									setRefreshNote(!refreshNote)
								}
							})
							.catch((e) => snackError({ id: 'snackbar.error.tourPlaine.reminder' }))
							.finally(() => closePanel())
					}}
				/>,
				formActions,
				<ChatBubbleIcon color="primary" />
			)
		}, [])

		return <WrappedComponent
			{...props}
			openReminderForm={openReminderForm}
		/>
	}
	return ReminderFormInjector
}
