import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import {getStyles} from 'isotope-client'
import mapError from './mapError'
import classnames from 'classnames'
import {default as MuiAutocomplete} from '@material-ui/lab/Autocomplete'
import {FormattedMessage} from 'react-intl'

const styles = () => getStyles({
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	bo: {
		backgroundColor: '#f7f6f6 !important'
	}
})

const Autocomplete = (Component) => ({
	input: { onChange, ...inputProps },
	meta: { error, ...otherMeta },
	renderOption,
	filterOptions,
	options,
	defaultValue,
	containerClassName,
	fromBo,
	classes,
	...otherProps
}
) => {
	const initialValue = options.find(option => option.code === otherMeta.initial)

	return <div className={classnames(classes.container, containerClassName)}>
		<Component
			options={options}
			getOptionLabel={option => option.label}
			renderOption={renderOption}
			{...(onChange ? { onChange: (event, value) => onChange(value ? value.code : null) } : {})}
			getOptionSelected={(option, value) => option.code === value.code}
			defaultValue={!!initialValue ? initialValue : defaultValue}
			noOptionsText={<FormattedMessage id="field.noOptions" />}
			filterOptions={filterOptions}
			classes={{
				inputRoot: fromBo && classes.bo
			}}
			renderInput={params => {
				return (
					<TextField
						{...otherProps}
						{...inputProps}
						{...params}
						onBlur={() => {
						}}
						variant="filled"
						helperText={(!otherMeta || otherMeta.touched) && error}
						meta={otherMeta}
						error={(!otherMeta || otherMeta.touched) && !!error}
					/>
				)
			}}
		/>
	</div>
}

Autocomplete.defaultProps = {
	containerClassName: '',
	options: []
}

Autocomplete.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	options: PropTypes.array,
	classes: PropTypes.object,
	renderOption: PropTypes.func,
	filterOptions: PropTypes.func
}

export default compose(
	mapError,
	withStyles(styles)
)(Autocomplete(MuiAutocomplete))

