import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { Field, change } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { fields } from '../NouveauGroupeDeCulture'
import { ArrowRight } from '@material-ui/icons'
import { colors, TYPE_PLANCHE } from '../../../../../../utils/constants'
import Grid from '@material-ui/core/Grid'
import Input from '../../../../../../components/form/Input'
import { formatInteger, getDateOfWeek } from '../../../../../../utils/utils'
import FormattedMessageWithBold from '../../../../../../components/FormattedMessageWithBold'
import { NEW_CULTURE_GROUP_FORM_NAME } from '../NouveauGroupeDeCulture'
import { injectIntl } from 'react-intl'

const styles = () => getStyles({
	title: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: '1em',
		cursor: 'pointer'
	},
	toggle: {
		color: colors.primary,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 6,
		marginTop: 12,
		marginLeft: '-8px',
	},
	iconRotate: {
		fontSize: '2rem !important',
		transform: 'rotate(90deg)',
		transition: 'ease-in-out 0.1s',
		cursor: 'pointer'
	},
	icon: {
		transform: 'rotate(0)'
	},
	fieldEspacement: {
		float: 'left',
		width: '46%'
	},
	blocText: {
		marginTop: 8,
		fontSize: 14,
		marginLeft: 10,
		marginBottom: 10
	},
	ligneText: {
		marginBottom: 10
	},
	hidden: {
		display: 'none'
	}
})

const formattedMessageBase = 'planning.cultureGroup.addGroup.cultures'

const CultureBloc = ({ groupeAssolement, classes, change, intl, typeSurface, year, field }) => {
	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		change(NEW_CULTURE_GROUP_FORM_NAME, `${field}.${fields.ID}`, groupeAssolement.id)
		change(NEW_CULTURE_GROUP_FORM_NAME, `${field}.${fields.ESPACEMENT}`, groupeAssolement.espacement)
		change(NEW_CULTURE_GROUP_FORM_NAME, `${field}.${fields.NB_RANGS}`, typeSurface && typeSurface === TYPE_PLANCHE.PERMANENTE ? groupeAssolement.nbRangs : "")
		change(NEW_CULTURE_GROUP_FORM_NAME, `${field}.${fields.DENSITE}`, typeSurface && typeSurface !== TYPE_PLANCHE.PERMANENTE ? groupeAssolement.densite : '')
	}, [])

	const handleClick = () => setOpen(previousState => !previousState)

	const dateDebut = React.useMemo(() => getDateOfWeek(groupeAssolement.timelineDebutSemis + groupeAssolement.timeline, year), [groupeAssolement.timelineDebutSemis, groupeAssolement.timeline, year])
	const lastTache = React.useMemo(() => {return groupeAssolement.groupesEtape.slice().sort((e1, e2) => e2.timelineDebutEtape - e1.timelineDebutEtape)[0];}, [groupeAssolement.groupesEtape]);
	const dateFin = React.useMemo(() => getDateOfWeek(groupeAssolement.timelineDebutSemis + lastTache.timelineDebutEtape + lastTache.duree, year, true), [groupeAssolement.timelineDebutSemis, lastTache.timelineDebutEtape, lastTache.duree, year])

	return (
		<Grid>
			<Grid onClick={handleClick} className={classes.toggle}>
				<ArrowRight className={`${classes.iconRotate} ${open ? '' : classes.icon}`} />
				<Typography variant="body1" className={classes.title}>
					{groupeAssolement.culture.nom}
				</Typography>
			</Grid>
			{open && <Grid>
				<Grid>
					<Grid className={classes.blocText}>
						<Grid className={classes.ligneText}>
							<FormattedMessageWithBold
								id={`${formattedMessageBase}.itineraire`}
								values={{
									nom: groupeAssolement.culture.nom,
									debut: intl.formatDate(dateDebut),
									fin: intl.formatDate(dateFin)
								}}
							/>
						</Grid>
						<Grid className={classes.ligneText}>
							<FormattedMessageWithBold
								id={`${formattedMessageBase}.periode`}
								values={{
									debut: intl.formatDate(getDateOfWeek(groupeAssolement.timelineDebutSemis, year)),
									fin: intl.formatDate(getDateOfWeek(groupeAssolement.timelineDebutSemis + groupeAssolement.dureeSemis, year, true))
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid>
					<Grid>
						<Field
							name={`${field}.${fields.ID}`}
							component={Input}
							containerClassName={classes.hidden}
						/>
						<Field
							name={`${field}.${fields.ESPACEMENT}`}
							component={Input}
							adornment={<FormattedMessage id={`${formattedMessageBase}.espacement.unite`} />}
							label={<FormattedMessage id={`${formattedMessageBase}.espacement.label`} />}
							type="number"
							containerClassName={classes.fieldEspacement}
						/>
						{
							typeSurface && typeSurface === TYPE_PLANCHE.PERMANENTE &&
								<Field
									name={`${field}.${fields.NB_RANGS}`}
									component={Input}
									type="number"
									label={<FormattedMessage id={`${formattedMessageBase}.nbRangs`} />}
									format={formatInteger}
									style={{ width: '48%' }}
								/>
						}
						{
							typeSurface && typeSurface !== TYPE_PLANCHE.PERMANENTE &&
								<Field
									name={`${field}.${fields.DENSITE}`}
									component={Input}
									type="number"
									adornment={<FormattedMessage id={`${formattedMessageBase}.densite.unite`} />}
									label={<FormattedMessage id={`${formattedMessageBase}.densite.label`} />}
									style={{ width: '48%' }}
								/>
						}
					</Grid>
				</Grid>
			</Grid>}
		</Grid>
	)
}

CultureBloc.propTypes = {
	groupeAssolement: PropTypes.object,
	typeSurface: PropTypes.string,
	year: PropTypes.number
}

const actions = {
	change
}

export default compose(
	connect(null, actions),
	injectIntl,
	withStyles(styles)
)(CultureBloc)
