import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from './Button'
import { toI18N } from '../utils/utils'
import { AccessChecker } from './security/AccessChecker'
import { getStyles } from 'isotope-client'
import { PROFILS } from '../utils/constants'
import { Typography } from '@material-ui/core'

const styles = (theme) => getStyles({
	icon: {
		color: theme.palette.text.main,
		minWidth: 35
	},
	title: {
		color: theme.palette.primary.main,
		fontWeight: 700,
		fontSize: 12

	},
	titleItem: {
		opacity: '1 !important'
	}
})

const ButtonWithMenu = ({ items, classes, children, ...otherProps }) => {

	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const withIcons = items.some(item => !!item.icon)

	return (
		<>
			<Button {...otherProps} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				{children}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{items.map((item, index) => {
						if (item.isTitle) {
							return (
								<MenuItem key={index} className={classes.titleItem} disabled>
									<ListItemText>
										<Typography className={classes.title}>
											{toI18N(item.label)}
										</Typography>
									</ListItemText>
								</MenuItem>
							)
						}

						if (item.isDivider) {
							return <Divider key={index} />
						}

						return (
							<AccessChecker key={index} access={item.access}>
								<MenuItem
									onClick={(event) => {
										if (typeof item.onClick === 'function') {
											item.onClick(event)
										}
										handleClose()
									}}
									disabled={item.disabled}
								>
									{withIcons && <ListItemIcon className={classes.icon}>
										{item.icon}
									</ListItemIcon>}
									{toI18N(item.label)}
								</MenuItem>
							</AccessChecker>
						)
					}
				)}
			</Menu>
		</>
	)
}

ButtonWithMenu.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.element
	]).isRequired,
	classes: PropTypes.object,
	items: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.node,
		icon: PropTypes.element,
		access: PropTypes.oneOf(Object.values(PROFILS)),
		onClick: PropTypes.function,
		disabled: PropTypes.bool,
		isTitle: PropTypes.bool,
		isDivider: PropTypes.bool
	})).isRequired
}

export default withStyles(styles)(ButtonWithMenu)
