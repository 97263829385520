import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isInvalid, SubmissionError } from 'redux-form'
import FormButton from '../../../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { CHOIX_DEPLACEMENT } from '../../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import DeplacerEtape, { DEPLACEMENT_ETAPE_FORM } from '../../../component/form/DeplacerEtape'
import { loadAssolement } from '../../assolement/assolementInjector'
import { delayEtape } from '../../planningApi'
import { resetFilter } from '../../toolbar/toolbarAction'

export const injectDeplacerEtape = (WrappedComponent) => {
	const DeplacerEtapeInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { campagne, resetFilter, refreshAssolements, getNotifications } = props
		const { snackError, snackSuccess } = useSnackbar()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const deplacerEtape = (assolement, etapeType) => {

			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]
			const assolementsId = selectedAssolements.map((assolement) => assolement.id)

			const onSubmit = (values) => {
				const data = {
					assolementsId,
					typeEtape: etapeType,
					date: (values.choixDate === CHOIX_DEPLACEMENT.DEPLACER) ? values.date : null,
					nbSemaines: (values.choixDate === CHOIX_DEPLACEMENT.DECALER) ? values.nbSemaines : null
				}

				return delayEtape(data)
					.then(() => {
						closePanel()
						snackSuccess({ id: 'listAssolement.snackbar.ok.deplacerEtapes' })
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
						resetFilter()
						refreshAssolements()
					})
					.catch((e) => {
						snackError({ id: 'listAssolement.snackbar.error.deplacerEtapes' })
						throw new SubmissionError(e)
					})
			}

			openPanel(
				<FormattedMessage id="listAssolement.forms.headers.deplacerEtapeImplantation" />,
				<DeplacerEtape
					selectedAssolements={selectedAssolements}
					etapeType={etapeType}
					onSubmit={(values) => onSubmit(values)} />,
				[<FormButton
					type="primary"
					formName={DEPLACEMENT_ETAPE_FORM}
					getDisabledFromState={state => isInvalid(DEPLACEMENT_ETAPE_FORM)(state)}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>]
			)
		}

		return <WrappedComponent {...props} deplacerEtape={React.useCallback(deplacerEtape, [campagne])} />
	}

	DeplacerEtapeInjector.propTypes = {
		campagne: PropTypes.object,
		resetFilter: PropTypes.func,
		refreshAssolements: PropTypes.func
	}

	const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state)
	})

	const actions = {
		resetFilter,
		getNotifications
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(DeplacerEtapeInjector)
}
