import LoginPage from '../modules/fo/login/LoginPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './App'

export default () => (
	<Switch>
		<Route path='/login' component={LoginPage} />
		<Route path='/logout' component={LoginPage} />
		<Route path='/' component={App} />
		<Route path='*' component={App} />
	</Switch>
)
