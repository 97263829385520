import { fetchFactory, urlUtils } from 'isotope-client'

export const getNotifications = () => fetchFactory('/notification')

export const readAllNotifications = () => {
	const url = urlUtils.buildUrlWithParams('/notification/markRead')
	return fetchFactory(url, { method: 'POST' })
}

export const readNotification = (id) => {
	const url = urlUtils.buildUrlWithParams('/notification/markRead/' + id)
	return fetchFactory(url, { method: 'POST' })
}
