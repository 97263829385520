import React from 'react'
import { getStyles } from 'isotope-client'
import { withStyles } from '@material-ui/core'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import classnames from "classnames";
import { FormattedMessage } from 'react-intl'
import {colors, MAX_FILE_SIZE} from "../utils/constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Close from '@material-ui/icons/Close'

const styles = () => getStyles({
	button: {
		padding: '0 20px 0 20px',
	},
	fileInput: {
		color: 'transparent',
		width: '1px',
		height: '1px',
		margin: '-1px',
		border: '0',
	},
	searchIcon: {
		color: colors.etapeContrastBackground,
		backgroundColor: colors.link,
		borderRadius: 30,
		padding: 7,
		margin: 5,
	},
	fileDiv: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		border: `1px solid ${colors.borderPlan}`,
		borderRadius: '8px',
		padding: 10,
		marginBottom: 10,
		backgroundColor: colors.etapeContrastBackground,
		cursor: 'pointer',
		fontStyle: 'italic',
		fontFamily: 'Lato',
		'&.dragOver': {
			border: `2px dashed ${colors.link}`,
		}
	},
	file: {
		display: 'inline-grid',
	},
	fileLoad: {
		display:'flex',
		fontStyle: 'italic',
		alignItems: 'center',
	},
	error: {
		color: colors.error,
		fontFamily: 'Lato'
	},
	row: {
		marginTop: 5,
		marginBottom: 15,
	},
	grab: {
		cursor: 'pointer',
	},
	loadMessage: {
		paddingRight: 10,
	}
})

const FileInput = ({input, isFormInitial, label, file, fileInputRef, classes, fieldsUpdate, fieldsCreation, changeFile, changeFermeToCopy, changeImage, savedFile, untouchFermeToCopy, menuSelection, dontCopyDataValue, formName, ...custom}) => {
	const [fileUpload, setFileUpload] = React.useState()
	const [planPreview, setPlanPreview] = React.useState("")
	const [errorMessage, setErrorMessage] = React.useState("")
	const [isFileDrag, setIsFileDrag] = React.useState()

	React.useEffect(() => {
		document.addEventListener('dragover', (event) => event.preventDefault());
		document.addEventListener('drop', (event) => event.preventDefault());
		return () => {
			document.removeEventListener('dragover', (event) => event.preventDefault());
			document.removeEventListener('drop', (event) => event.preventDefault());
		};
	}, []);

	React.useEffect(() => {
		if (savedFile) {
			setFileUpload(savedFile)
			setPlanPreview(savedFile)
		}
		if(dontCopyDataValue) {
			changeFermeToCopy(null)
		} else if (savedFile === null) {
			setPlanPreview(null)
			setFileUpload(null)
		} else {
			untouchFermeToCopy(formName)
		}
	}, [dontCopyDataValue, savedFile, menuSelection])

	React.useEffect(() => {
		if (isFormInitial) {
			setFileUpload(savedFile)
			setPlanPreview(savedFile)
		}
	}, [isFormInitial])

	const handleDeleteFile = () => {
		setFileUpload(null)
		changeImage(null)
	};

	const eventBasics = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleChangeFile = (event) => {
		setErrorMessage(null)
		const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
		if (allowedTypes.includes(event?.target?.files[0]?.type) && event?.target?.files[0] && (event?.target?.files[0]?.size < MAX_FILE_SIZE)) {
			setFileUpload(event.target.files[0]);
			setPlanPreview(URL.createObjectURL(event.target.files[0]))
			changeFile(event.target.files)
		} else if (event?.target?.files[0]?.size > MAX_FILE_SIZE) {
			setErrorMessage("sizeError")
			handleDeleteFile()
		} else if (!allowedTypes.includes(event?.target?.files[0]?.type)) {
			setErrorMessage("formatError")
		}
	};

	const handleDrag = (event, isDrag) => {
		const fileDiv = document.getElementsByClassName(classes.fileDiv)[0];
		if (isDrag) {
			eventBasics(event);
			fileDiv?.classList?.add('dragOver');
			setIsFileDrag(true);
		} else if (!isDrag) {
			event.stopPropagation();
			fileDiv?.classList?.remove('dragOver');
			setIsFileDrag(false);
		}
	};

	const handleError = (message) => {
		setPlanPreview(null)
		setFileUpload(null)
		changeFile(null)
		changeImage(null)
		setIsFileDrag(false);
		document.getElementsByClassName(classes.fileDiv)[0]?.classList?.remove('dragOver');
		handleDeleteFile()
		setErrorMessage(message)
	};

	const dropFile = (event) => {
		if (event?.dataTransfer?.files[0]) {
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (allowedTypes.includes(event.dataTransfer.files[0].type) && event.dataTransfer.files[0].size <= MAX_FILE_SIZE) {
				setErrorMessage(null)
				eventBasics(event);
				document.getElementsByClassName(classes.fileDiv)[0]?.classList?.remove('dragOver');
				setPlanPreview(URL.createObjectURL(event.dataTransfer.files[0]));
				setFileUpload(event.dataTransfer.files[0])
				changeFile(event.dataTransfer.files)
				setIsFileDrag(false);
			} else {
				if (event?.dataTransfer?.files[0]?.size > MAX_FILE_SIZE) {
					handleError("sizeError")

				} else if (!allowedTypes.includes(event.dataTransfer.files[0].type)) {
					handleError("formatError")
				}
			}
		}
	};
	return (
		<div>
			<div onClick={() => fileInputRef.current.click()} className={classnames(classes.fileDiv)}
				 onDragOver={(e) => {
					 handleDrag(e, true)
					 e.stopPropagation();
				 }}
				 onDragLeave={(e) => {
					 handleDrag(e, false)
					 e.stopPropagation();
				 }}
				 onDrop={(e) => {
					 dropFile(e)
					 e.stopPropagation();
				 }}
			>
				<ImageSearchIcon className={classnames(classes.searchIcon)}/>
				<input
					type="file"
					accept=".jpg, .jpeg, .png"
					className={classnames(classes.input, classes.row, classes.fileInput)}
					id="fileInput"
					onChange={(event) => {
						handleChangeFile(event)
					}}
					ref={fileInputRef}
					{...custom}
				/><FormattedMessage id={`bo.ferme.${isFileDrag ? 'dropText' : 'uploadText'}`}/>
			</div>
			<div>
				{ (planPreview && fileUpload) && (
					<Grid className={classnames(classes.file, classes.row)}>
						<Grid className={classnames(classes.fileLoad, classes.row)}>
							<Typography className={classnames(classes.loadMessage)} variant="body3">
								{(fileUpload?.name || <FormattedMessage id="bo.ferme.plan.load" />)}
							</Typography>
							<Close onClick={handleDeleteFile}/>
						</Grid>
						<Typography variant="h5" className={classes.row}>
							<FormattedMessage id="bo.ferme.plan.apercu" />
						</Typography>
						<img alt='preview' onError={() => handleError("imgError")} height={500} width={500} src={planPreview} />
					</Grid>
				)}
				{ errorMessage && (
					<Typography className={classes.error} variant="paragraph" onClick={handleDeleteFile}>
						<FormattedMessage id={`bo.ferme.plan.error.${errorMessage.toString()}`} />
					</Typography>
				)}
			</div>
		</div>
	)
}

export default withStyles(styles)(FileInput)