import { withStyles } from '@material-ui/core'
import { ControlPointDuplicate, DeleteForever, LowPriority, Room } from '@material-ui/icons'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import Comment from '../../../../../../components/Comment'
import CommentSuiviList from '../../../../../../components/CommentSuiviList'
import CommentaireIcon from '../../../../../../components/icon/CommentaireIcon'
import CultureIcon from '../../../../../../components/icon/CultureIcon'
import ItineraireTechniqueIcon from '../../../../../../components/icon/ItineraireTechniqueIcon'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import { PAGE, usePageContext } from '../../../../../../components/layout/PageContext'
import { ActionPanelContext, DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import AccessChecker from '../../../../../../components/security/AccessChecker'
import { ASSOLEMENT_ETAT, colors, COMMENTAIRE_TYPE_ENTITY, ETAPE_ETAT, ETAPE_TYPE, PROFILS } from '../../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../../utils/utils'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import InfoCulture from '../../../../../common/information/InfoCulture'
import TypeSurface from '../../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../../common/information/ZoneSurface'
import Detail from '../../../component/detail/Detail'
import ItineraireTechnique from '../../../component/detail/ItineraireTechnique'
import PreviousCulture from '../../../component/PreviousCulture'
import { DO_NOT_OPEN_PANEL, EN_TERRE, PANEL_TYPE } from '../../../utils/constant'
import * as selectors from '../../assolement/assolementSelector'
import { injectCommentaireSuivi } from '../form/commentInjectors'
import { injectDeleteComment } from '../form/deleteCommentInjector'
import { injectDeleteCulture } from '../form/deleteCultureInjector'
import { injectCultureDuplicator } from '../form/duplicateCultureInjector'
import { injectEditCulturePlanche } from '../form/editCulturePlancheInjector'
import { openSurfaceDetail } from './openSurfaceDetailInjector'

const styles = () => getStyles({
	button: {
		padding: '0 10px 0 10px',
		margin: '0 9px 0 9px'
	}
})

/**
 * Injecteur pour l'affichage du panel de détail de culture
 */
export const injectCultureDetailPanel = (WrappedComponent) => {
	const CultureDetailInjector = ({ currentAssolement, panelType, currentCampagne, ...props }) => {
		const { updatePanel: updateDetailsPanel, closePanel: closePanelDetail } = React.useContext(DetailsPanelContext)
		const { openPanel: openPanelAction } = React.useContext(ActionPanelContext)
		const { page } = usePageContext()
		const { openSurfaceDetail, openCultureDuplicator, openEditCulturePlanche, openPanelCommentaireSuivi, openCultureDelete, openCommentDelete, isCompare, classes } = props

		React.useEffect(() => {
			if (currentAssolement && panelType === PANEL_TYPE.CULTURE_DETAIL) {
				// Si c'est un assolement de fertilisation on n'ouvre pas le panel
				if (!currentAssolement || !currentAssolement.assolementEtapeList || !!currentAssolement.assolementEtapeList.find(etape => etape.type === ETAPE_TYPE.FERTILISATION)) {
					closePanelDetail()
					return
				}

				const { campagne, culture, planche } = currentAssolement
				const title = `${culture.nom} - ${planche.nom}`
				const assolementRevise = ASSOLEMENT_ETAT.REVISE === currentAssolement.etat

				const cultureMove = {
					label: <FormattedMessage id="planning.leftPanel.menu.culture.move" />,
					icon: <LowPriority />,
					onClick: () => openEditCulturePlanche(currentAssolement)
				}

				const duplicateCulture = {
					label: <FormattedMessage id="planning.leftPanel.menu.culture.duplicate" />,
					icon: <ControlPointDuplicate />,
					onClick: () => openCultureDuplicator(currentAssolement)
				}

				let buttons = assolementRevise ? [
					<Button type="secondary" onClick={() => openPanelCommentaireSuivi(currentAssolement, DO_NOT_OPEN_PANEL)}
					        startIcon={<CommentaireIcon color="currentColor" />}>
						<FormattedMessage id="planning.leftPanel.menu.culture.suiviShort" />
					</Button>,
					<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
						<ButtonWithMenu
							type="secondary"
							items={[
								{
									label: <FormattedMessage id="planning.leftPanel.menu.surface.short.detail" />,
									icon: <Room />,
									onClick: () => openSurfaceDetail(currentAssolement)
								},
								cultureMove,
								{
									label: <FormattedMessage id="planning.leftPanel.menu.culture.delete" />,
									icon: <DeleteForever />,
									onClick: () => openCultureDelete(currentAssolement),
									isDisable: true
								},
								duplicateCulture
							]}
							startIcon={<MoreHorizIcon />}>
							<FormattedMessage id="actions.other" />
						</ButtonWithMenu>
					</AccessChecker>
				] : (!currentCampagne.preview ? [
					<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
						<Button
							type="secondary"
							onClick={() => openCultureDelete(currentAssolement)}
							startIcon={<DeleteForever color="currentColor" />}
							className={classes.button}
						>
							<FormattedMessage id="planning.leftPanel.menu.culture.delete" />
						</Button>
					</AccessChecker>,
					<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
						<ButtonWithMenu
							type="secondary"
							items={[
								cultureMove,
								duplicateCulture
							]}
							className={classes.button}
							startIcon={<MoreHorizIcon />}>
							<FormattedMessage id="actions.other" />
						</ButtonWithMenu>
					</AccessChecker>
				] : [])

				const estEnTerre = currentAssolement.assolementEtapeList.filter(etape => EN_TERRE.indexOf(etape.type) !== -1 && etape.etat === ETAPE_ETAT.TERMINE).length > 0
				if (estEnTerre && assolementRevise) {
					buttons = [
						<Button type="secondary"
						        onClick={() => openSurfaceDetail(currentAssolement)}
						        startIcon={<Room />}>
							<FormattedMessage id="planning.leftPanel.menu.surface.short.detail" />
						</Button>,
						<Button type="secondary"
						        onClick={() => openPanelCommentaireSuivi(currentAssolement, DO_NOT_OPEN_PANEL)}
						        startIcon={<CommentaireIcon color="currentColor" />}>
							<FormattedMessage id="planning.leftPanel.menu.culture.suiviShort" />
						</Button>
					]
				}

				const sections = [
					{
						icon: <CultureIcon />,
						title: culture.nom,
						path: <InfoCulture culture={culture} />,
						content: <em>{culture.cultivar}</em>,
						comment: <Comment comment={culture.commentaire} />
					},
					{
						icon: <PlaceIcon />,
						title: planche.nom,
						path: <ZoneSurface planche={planche} />,
						content: <>
							<TypeSurface planche={planche} />
							{page === PAGE.TOUR_PLAINE && <PreviousCulture campagneDateDebut={campagne.dateDebut} plancheId={planche.id} />}
						</>
					},
					{
						icon: <ItineraireTechniqueIcon />,
						title: <FormattedMessage id="planning.itineraireTechnique.title" />,
						content: <ItineraireTechnique
							isCompare={isCompare}
							assolement={currentAssolement}
							estEnTerre={estEnTerre}
							openPanelAction={openPanelAction}
							noEdit={page === PAGE.TOUR_PLAINE}
						/>
					}
				]

				const comments = currentAssolement.commentaires
				sections.unshift({
					icon: <CommentaireIcon color={colors.iconeDetail} />,
					title: <FormattedMessage id="planning.suivi.detail.name" />,
					content: (comments && comments.length) ?
						<CommentSuiviList
							comments={comments}
							editComment={(comment) => openPanelCommentaireSuivi(currentAssolement, DO_NOT_OPEN_PANEL, comment)}
							deleteComment={(comment) => openCommentDelete(comment, COMMENTAIRE_TYPE_ENTITY.CULTURE_PLANCHE)}
							noEdit={page === PAGE.TOUR_PLAINE}
						/>
						:
						<FormattedMessage id="planning.suivi.detail.empty" />
				})

				if (isCompare || campagne.finished || page === PAGE.TOUR_PLAINE) {
					buttons = []
				}

				if (page === PAGE.TOUR_PLAINE) {
					updateDetailsPanel(
						title,
						<Detail sections={sections} />,
						buttons,
						false
					)
				} else {
					updateDetailsPanel(
						title,
						<Detail sections={sections} />,
						buttons
					)
				}
			}
		}, [currentAssolement, panelType])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		currentAssolement: selectors.getCurrentAssolement(state),
		panelType: selectors.getDetailPanelType(state),
		isCompare: selectors.isCompare(state),
		currentCampagne: getCurrentCampagne(state)
	})

	CultureDetailInjector.propTypes = {
		panelType: PropTypes.string,
		currentAssolement: PropTypes.object,
		isCompare: PropTypes.bool
	}

	return compose(
		openSurfaceDetail,
		injectEditCulturePlanche,
		injectCommentaireSuivi,
		injectDeleteCulture,
		injectDeleteComment,
		connect(mapStateToProps),
		injectCultureDuplicator,
		withStyles(styles)
	)(CultureDetailInjector)
}
