import React from 'react'
import PropTypes from 'prop-types'

const ColdIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		fill="none"
		viewBox="0 0 42 79"
	>
		<g clipPath="url(#clip0_1505_24693)">
			<path d="M26.9392 48.6584V9.59112C26.9392 4.29379 22.6454 0 17.3487 0C12.0513 0 7.75754 4.29379 7.75754 9.59112V48.6584C3.46616 51.6774 0.662292 56.6679 0.662292 62.3136C0.662292 71.5293 8.13303 79 17.3487 79C26.5643 79 34.035 71.5293 34.035 62.3136C34.035 56.6679 31.2306 51.6774 26.9392 48.6584Z" fill="#D8D8D8" />
			<path d="M17.3486 73.5351C10.9941 73.5351 5.82458 68.365 5.82458 62.0111C5.82458 57.1857 8.82313 52.8895 13.3224 51.2133V9.59113C13.3224 7.3707 15.1282 5.56494 17.3486 5.56494C19.5685 5.56494 21.3748 7.3707 21.3748 9.59113V51.2133C25.8735 52.8889 28.8727 57.1851 28.8727 62.0105C28.8727 68.365 23.7031 73.5351 17.3486 73.5351Z" fill="#F3E8D7" />
			<path d="M13.3218 46.1113V51.2133C8.82312 52.8895 5.82397 57.1857 5.82397 62.0111C5.82397 68.365 10.9941 73.5351 17.3486 73.5351C23.7025 73.5351 28.8727 68.365 28.8727 62.0111C28.8727 57.1857 25.8735 52.8895 21.3748 51.2133V46.1113H13.3218Z" fill="#69E6ED" />
			<path d="M21.3749 51.2133V27.5H13.3225V51.2133C13.3225 51.2133 18.8886 53.796 18.8886 62.0111C18.8886 66.5779 16.218 70.533 12.3563 72.3966C13.8673 73.1259 15.561 73.5351 17.3487 73.5351C23.7026 73.5351 28.8727 68.365 28.8727 62.0111C28.8727 57.1851 25.8736 52.8895 21.3749 51.2133Z" fill="#33D8DD" />
			<path d="M40.2238 27.8072H33.3075C32.6488 27.8072 32.1154 27.2731 32.1154 26.615C32.1154 25.9568 32.6488 25.4228 33.3075 25.4228H40.2238C40.882 25.4228 41.4154 25.9568 41.4154 26.615C41.416 27.2731 40.882 27.8072 40.2238 27.8072Z" fill="#0D6E9A" />
			<path d="M36.4477 18.1449H33.3075C32.6488 18.1449 32.1154 17.6109 32.1154 16.9527C32.1154 16.2946 32.6488 15.7606 33.3075 15.7606H36.4477C37.1059 15.7606 37.6399 16.2946 37.6399 16.9527C37.6399 17.6109 37.1059 18.1449 36.4477 18.1449Z" fill="#0D6E9A" />
			<path d="M37.5603 8.4827H33.3075C32.6488 8.4827 32.1154 7.94868 32.1154 7.29051C32.1154 6.63234 32.6488 6.09833 33.3075 6.09833H37.5603C38.2191 6.09833 38.7525 6.63234 38.7525 7.29051C38.7525 7.94868 38.2191 8.4827 37.5603 8.4827Z" fill="#0D6E9A" />
			<path d="M37.5603 47.1317H33.3075C32.6488 47.1317 32.1154 46.5977 32.1154 45.9395C32.1154 45.2807 32.6488 44.7473 33.3075 44.7473H37.5603C38.2191 44.7473 38.7525 45.2807 38.7525 45.9395C38.7525 46.5977 38.2191 47.1317 37.5603 47.1317Z" fill="#0D6E9A" />
			<path d="M36.4477 37.4694H33.3075C32.6488 37.4694 32.1154 36.9354 32.1154 36.2772C32.1154 35.619 32.6488 35.085 33.3075 35.085H36.4477C37.1059 35.085 37.6399 35.619 37.6399 36.2772C37.6399 36.9354 37.1059 37.4694 36.4477 37.4694Z" fill="#0D6E9A" />
		</g>
		<defs>
			<clipPath id="clip0_1505_24693">
				<rect width="42" height="79" fill="white" />
			</clipPath>
		</defs>
	</svg>
)


ColdIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

ColdIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default ColdIcon