import withStyles from '@material-ui/core/styles/withStyles'
import Tab from '@material-ui/core/Tab'
import React from 'react'
import { colors } from '../../../utils/constants'

/**
 * StyledTab
 */
const StyledTab = withStyles((theme) => ({
	root: (props) => ({
		minWidth: 220,
		borderBottom: props.withoutBorder ? '' : `2px solid ${colors.secondary}`,
		marginLeft: 10,
		marginRight: 10,
		paddingBottom: 0,
		'&.Mui-selected': {
			color: theme.palette.primary.main,
			fontWeight: 'bold'
		}
	})
}))((props) => <Tab disableRipple {...props} />)

export default StyledTab