import React from 'react'
import PropTypes from 'prop-types'

const HumidityIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#76e2f8"
			d="M267.513 81.254c-2.842-3.413-7.178-5.12-11.514-5.12s-8.672 1.707-11.514 5.12c-12.642 15.132-123.486 149.912-123.486 219.609 0 74.443 60.557 135 135 135s135-60.557 135-135c0-69.697-110.844-204.477-123.486-219.609z"
		/>
		<path
			fill="#3aaaff"
			d="M390.999 300.864c0-69.697-110.845-204.478-123.486-219.609-2.842-3.413-7.178-5.12-11.514-5.12v359.729c74.444 0 135-60.557 135-135z"
		/>
		<g fill="#f0f7ff">
			<path
				d="M8.29 194.282c2.578 1.289 64.896 31.582 127.709 31.582 52.086 0 102.62-20.632 120-28.482 37.991-17.159 77.734-31.518 120-31.518 55.605 0 113.734 28.14 114.306 28.418 7.354 3.691 16.421.703 20.112-6.724 3.691-7.412.703-16.406-6.709-20.112-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482-37.991 17.159-77.734 31.518-120 31.518-55.605 0-113.734-28.14-114.306-28.418-7.339-3.662-16.406-.688-20.112 6.724-3.691 7.412-.703 16.406 6.709 20.112zM503.708 257.446c-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482-37.991 17.159-77.734 31.518-120 31.518-55.605 0-113.734-28.14-114.306-28.418-7.339-3.677-16.406-.688-20.112 6.724-3.691 7.412-.703 16.406 6.709 20.112 2.578 1.289 64.896 31.582 127.709 31.582 52.086 0 102.62-20.632 120-28.482 37.991-17.159 77.734-31.518 120-31.518 55.605 0 113.734 28.14 114.306 28.418 7.354 3.677 16.421.688 20.112-6.724 3.692-7.412.703-16.406-6.709-20.112zM503.708 347.446c-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482-37.991 17.159-77.734 31.518-120 31.518-55.605 0-113.734-28.14-114.306-28.418-7.339-3.677-16.406-.703-20.112 6.724-3.691 7.412-.703 16.406 6.709 20.112 2.578 1.289 64.896 31.582 127.709 31.582 52.086 0 102.62-20.632 120-28.482 37.991-17.159 77.734-31.518 120-31.518 55.605 0 113.734 28.14 114.306 28.418 7.354 3.662 16.421.688 20.112-6.724 3.692-7.412.703-16.406-6.709-20.112z" />
		</g>
		<g fill="#d9e7ec">
			<path d="M375.999 165.864c55.605 0 113.734 28.14 114.306 28.418 7.354 3.691 16.421.703 20.112-6.724 3.691-7.412.703-16.406-6.709-20.112-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482v33.036c37.991-17.159 77.734-31.518 120-31.518zM375.999 255.864c55.605 0 113.734 28.14 114.306 28.418 7.354 3.677 16.421.688 20.112-6.724s.703-16.406-6.709-20.112c-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482v33.036c37.991-17.159 77.734-31.518 120-31.518zM375.999 345.864c55.605 0 113.734 28.14 114.306 28.418 7.354 3.662 16.421.688 20.112-6.724s.703-16.406-6.709-20.112c-2.578-1.289-64.896-31.582-127.709-31.582-52.086 0-102.62 20.632-120 28.482v33.036c37.991-17.159 77.734-31.518 120-31.518z" />
		</g>
	</svg>
)


HumidityIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

HumidityIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default HumidityIcon