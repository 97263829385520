import React from 'react'
import {getStyles} from 'isotope-client'
import {withStyles} from '@material-ui/core'
import {colors} from '../../../utils/constants'
import RessourcesOnglet from './component/RessourcesOnglet'
import ToolBar from './component/Toolbar/ToolBar'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import {FilterPanelContext} from '../../../components/layout/rightPanels'

const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		height: '-webkit-fill-available'
	},
})

const GestionRessourceComponents = ({classes}) => {

	return <div className={classes.root}>
			<ActionPanelProvider Context={FilterPanelContext} level={1}>
				<ToolBar/>
				<RessourcesOnglet/>
			</ActionPanelProvider>
	</div>
}

export default withStyles(styles)(GestionRessourceComponents)
