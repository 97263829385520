import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import EditItineraire from '../../../component/form/EditItineraire'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { EDIT_ITK_FORM_NAME } from '../../../utils/constant'
import { editAssolement } from '../../assolement/assolementAction'
import FormButton from '../../../../../../components/form/FormButton'


/**
 * Injecteur pour l'ouverture du panel d'édition de l'itk
 */
export const injectEditItineraire = (WrappedComponent) => {
	const EditItineraireInjector = (props) => {
		const { snackSuccess } = useSnackbar()
		const { closePanel, openPanel } = React.useContext(ActionPanelContext)
		const { editAssolement } = props

		const submitEditAssolement = (values, assolement, callbackOnSubmit) => editAssolement([assolement.id], { ...values, plancheId: assolement.planche.id })
			.then(({ assolement }) => {
				if (callbackOnSubmit) {
					callbackOnSubmit(assolement[0])
				}
				snackSuccess({id: 'planning.cultureForm.editItk.success'})
				closePanel()
			})

		const openEditItineraire = (assolement, callbackOnSubmit) => {
			const promise = props.openPanelAction || openPanel
			return promise(<FormattedMessage id="planning.cultureForm.editItk.title" />,
				<EditItineraire onSubmit={(values) => submitEditAssolement(values, assolement, callbackOnSubmit)} assolement={assolement} />, [
					<FormButton
						type="primary"
						formName={EDIT_ITK_FORM_NAME}
					>
						<FormattedMessage id="actions.validate" />
					</FormButton>
				])
		}

		return <WrappedComponent {...props} openEditItineraire={React.useCallback(openEditItineraire, [])} />
	}

	const actions = {
		editAssolement
	}

	EditItineraireInjector.propTypes = {
		submitEdit: PropTypes.func,
		editAssolement: PropTypes.func
	}

	return compose(
		connect(undefined, actions)
	)(EditItineraireInjector)
}
