import { fetchFactory } from 'isotope-client'

const baseUri = '/besoinsProduction'

export const getDemandeModificationDate = (idFerme) => fetchFactory(`${baseUri}/demande/${idFerme}`)

export const getBesoinsProduction = (idCampagne) => fetchFactory(`${baseUri}/${idCampagne}`)

export const createOrUpdateBesoinsProduction = (values) => fetchFactory(`${baseUri}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const exportBesoinsProduction = (idCampagne) =>
	fetchFactory(`${baseUri}/exportBesoin/${idCampagne}`, {
		method: 'POST'
	})

export const exportEcartProduction = () =>
	fetchFactory(`${baseUri}/exportEcart`, {
		method: 'POST'
	})

export const getResultats = (filters) =>
	fetchFactory(`${baseUri}/resultat`, {
		method: 'POST',
		body: JSON.stringify(filters)
	})

export const getStats = (idCampagne) => fetchFactory(`${baseUri}/stats/${idCampagne}`)

export const getTauxRemplissage = (idCampagne) => fetchFactory(`${baseUri}/tauxRemplissage/${idCampagne}`)

export const getRepartitionFamille = (idCampagne) => fetchFactory(`${baseUri}/repartitionFamille/${idCampagne}`)

export const getFermeLongueurPlanche = () => fetchFactory(`/besoinsProduction/longueur-planche`)