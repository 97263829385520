import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {change, Field, FieldArray, formValueSelector, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import Detail from '../../planning/component/detail/Detail'
import {colors, ETAT_TACHE_UPPERCASE, SUIVI_DE_TACHE_FORM} from '../../../../utils/constants'
import {getAllUsers, getDetailsRecolte, getDetailsTri} from '../gestionTachesApi'
import moment from 'moment'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import {ReactComponent as Jardinier} from '../../../../icons/jardinier.svg'
import {useGestionTacheContext} from '../GestionTacheProvider'
import {getDescriptionTache} from '../utils/tacheUtils'
import {PANEL_TYPE} from '../utils/constants'
import TempsPasseUserLi from '../component/common/TempsPasseUserLi'
import {convertMinToHours, formatDuration, sortObjectByLabel} from '../../../../utils/utils'
import Input from '../../../../components/form/Input'
import {TRUE_FALSE, TYPE_TACHE} from '../../mobile/utils/constantsSuiviTache'
import Select from '../../../../components/form/Select'


const styles = () => getStyles({
	formInput: {
		'& > div': {
			padding: 0
		},
		'& label': {
			fontSize: '0.75em'
		},
		'&:last-child': {
			marginBottom: 0
		},
		'& > p': {
			marginBottom: 5
		}
	},
	form: {
		width: '100%',
		marginLeft: 15,
		marginRight: 15
	},
	details: {
		width: 'initial !important'
	},
	iconTachePlanifier: {
		color: colors.tacheAPlanifier,
		width: 24,
		height: 24
	},
	iconTacheRealiser: {
		color: colors.tacheARealiser,
		width: 24,
		height: 24
	},
	iconTacheEnCoursOuTerminee: {
		color: colors.tacheEnCoursOuTerminee,
		width: 24,
		height: 24
	},
	textPlanifier: {
		backgroundColor: colors.tacheAPlanifier,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	textRealiser: {
		backgroundColor: colors.tacheARealiser,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	textEnCoursOuTerminee: {
		backgroundColor: colors.tacheEnCoursOuTerminee,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	infoTache: {
		verticalAlign: 'sub'
	},
	description: {
		textAlign: 'justify'
	},
	instructions: {
		marginTop: 10,
		marginBottom: 10,
		textAlign: 'center'
	},
	icon: {
		width: 24,
		height: 24
	},
	ajoutSuivi: {
		marginTop: 30,
		textAlign: 'center'
	},
	detailsTache: {
		marginTop: 15
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				'& > span': {
					fontSize: '14px'
				}
			}
		}
	},
	ulContainer: {
		marginTop: 35
	},
	container: {
		width: 'auto',
		marginBottom: 8,
		fontFamily: 'Lato',
		height: 68

	},
	content: {
		fontSize: 19,
		padding: 0
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	labeluser: {
		height: 72
	}
})

const fieldsSuiviAvancementTri = {
	QUANTITE_CALIBREE: 'quantiteCalibree',
	QUANTITE_HORS_CALIBRE: 'quantiteHorsCalibre',
	QUANTITE_NON_COMMERCIALISABLE: 'quantiteNonCommercialisable'
}

const showChampRecolte = (isFromPageSuivi, currentTache) => {
	return isFromPageSuivi && currentTache.type === TYPE_TACHE.RECOLTE
}

const showChampsTri = (isFromPageSuivi, currentTache) => {
	return isFromPageSuivi && currentTache.type === TYPE_TACHE.TRI
}

const renderFieldArray = ({ fields }) => {
	return (
		fields.map((field) => (
			<TempsPasseUserLi field={field}/>
		))
	)
}

const validate = (values, {isFromPageSuivi, currentTache}) => {
	const errors = {}
	if (showChampsTri(isFromPageSuivi, currentTache) && Object.values(fieldsSuiviAvancementTri).every(field => !values[field] || values[field] === "0")) {
		Object.values(fieldsSuiviAvancementTri).forEach(field => errors[field] = { id: 'suiviAvancement.suiviRessources.panel.fields.errorTri' })
	}
	if (showChampRecolte(isFromPageSuivi, currentTache) && !values['cultureRecoltee']) {
		errors['cultureRecoltee'] = { id: 'suiviAvancement.suiviRessources.panel.fields.errorRecolte' }
	}
	return errors
}

const SuiviDeTacheForm = ({
	currentTache,
	tempsPasseField,
	valuesToConserve,
	isFromPageSuivi,
	handleSubmit,
	change,
	classes
}) => {

	const { jourSelection } = useGestionTacheContext()
	const [users, setUsers] = React.useState()

	const getUsers = () => {
		return Promise.resolve(users)
	}

	React.useEffect(() => {
		getAllUsers(moment(jourSelection).format('YYYY-MM-DD'))
			.then((res) => setUsers(sortObjectByLabel(
				res.map((ressource) => (
					{
						label: ressource.alias,
						code: ressource.user.id,
						tempsPasse: ressource.tempsPasse ?? 0
					})
				))
				)
			)
	}, [jourSelection])

	React.useEffect(() => {
		if (showChampRecolte(isFromPageSuivi, currentTache)) {
			getDetailsRecolte(currentTache.id).then(detailsRecolte => {
				if (detailsRecolte) {
					change('cultureRecoltee', detailsRecolte.flagFinRecolte ? TRUE_FALSE.TRUE.value : TRUE_FALSE.FALSE.value)
				}
			})
		}
		if (showChampsTri(isFromPageSuivi, currentTache)) {
			getDetailsTri(currentTache.id).then(detailsTri => {
				if (detailsTri) {
					change(fieldsSuiviAvancementTri.QUANTITE_CALIBREE, detailsTri.quantiteCalibree)
					change(fieldsSuiviAvancementTri.QUANTITE_HORS_CALIBRE, detailsTri.quantiteHorsCalibre)
					change(fieldsSuiviAvancementTri.QUANTITE_NON_COMMERCIALISABLE, detailsTri.quantiteNonCommercialisable)
				}
			})
		}
		if (isFromPageSuivi) {
			change("tempsPasseUser", [...currentTache.affectationLightBeanList
				.filter((ressource) => ressource.flagAffectationActive)
				.map((ressource) => {
					return ({
						label: ressource.user.preferences.ALIAS,
						code: ressource.user.id,
						tempsPasse: ressource.tempsPasse
					})
				}), ...currentTache.affectationLightBeanList
				.filter((ressource) => !ressource.flagAffectationActive && ressource.tempsPasse > 0)
				.map((ressource) => {
					return ({
						label: ressource.user.preferences.ALIAS,
						code: ressource.user.id,
						tempsPasse: ressource.tempsPasse
					})
				})
			])
		}
	}, [currentTache, isFromPageSuivi])



	const onChange = (event, value) => {
		const newUsers = value.filter(user => !tempsPasseField.some(tp => tp.code === user.code))
		/** M.A.J. arrayField avec nouvelUser sinon 1 item manquant */
		const arrayField = [...newUsers.map(user => ({
				label: user.label,
				code: user.code,
				tempsPasse: user.tempsPasse
			})
		), ...tempsPasseField]

		arrayField.forEach((user) => {
			const userPresentInInitValues = valuesToConserve.some((userToconserve) => user.code === userToconserve.code)
			const userPresentInAutocomplete = value.some((userChoisi) => user.code === userChoisi.code)
			/** Recupération de l'index de l'item à supprimer
			 * - demande de suppression dans autocomplete
			 * - et non présent dans les initialValues → suppression
			 * */
			const index = tempsPasseField.findIndex(userTiFind => userTiFind.code === user.code)

			if (!userPresentInInitValues && !userPresentInAutocomplete) {
				tempsPasseField.splice(index, 1)
			}
		})

		change('tempsPasseUser', [...newUsers.map(user => ({
				label: user.label,
				code: user.code,
				tempsPasse: user.tempsPasse
			})
		), ...tempsPasseField])
	}

	const sections = [
		getDescriptionTache(currentTache, classes, PANEL_TYPE.SUIVI_DE_PRODUCTION, isFromPageSuivi),
		{
			icon: <Jardinier className={classes.icon} />,
			title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.affectation" />,
			content:
				<Grid className={classes.formInput}>
					{
						!isFromPageSuivi &&
						<Field
							label={<FormattedMessage id="gestionTaches.ecranAffectations.panels.multiSelectLabel.suiviProd" />}
							name="usersAutocomplete"
							component={AutocompleteMulti}
							getOptions={getUsers}
							formName={SUIVI_DE_TACHE_FORM}
							forceGetOptions
							onChange={onChange}
						/>
					}

					<Grid>
						<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.estimationTempsPersonne"
							values={{ duree: <b>{isFromPageSuivi ? formatDuration(currentTache.chargeTravail) : convertMinToHours(currentTache.tempsPersonne)}</b> }}
						/>
					</Grid>

					{(currentTache.etat !== ETAT_TACHE_UPPERCASE.A_PLANIFIER && tempsPasseField.length > 0) &&
					<Grid>
						<ul className={classes.ulContainer}>
							<Grid>
								<FieldArray
									name="tempsPasseUser"
									component={renderFieldArray}
								/>
							</Grid>
						</ul>
					</Grid>
					}

					{
						showChampRecolte(isFromPageSuivi, currentTache) &&
							<Grid>
								<Field
									name="cultureRecoltee"
									label={<FormattedMessage id="suiviAvancement.suiviRessources.panel.fields.cultureRecoltee" />}
									component={Select}
								>
									{
										Object.values(TRUE_FALSE).map(type => (
											<MenuItem key={type.value} value={type.value} classes={{ selected: classes.menuItem }}>
												{type.label}
											</MenuItem>
										))
									}
								</Field>
							</Grid>
					}

					{
						showChampsTri(isFromPageSuivi, currentTache) &&
							<>
								<Grid>
									<Field
										name={fieldsSuiviAvancementTri.QUANTITE_CALIBREE}
										label={<FormattedMessage id="suiviAvancement.suiviRessources.panel.fields.quantiteCalibree" />}
										component={Input}
										type="number"
									/>
								</Grid>
								<Grid>
									<Field
										name={fieldsSuiviAvancementTri.QUANTITE_HORS_CALIBRE}
										label={<FormattedMessage id="suiviAvancement.suiviRessources.panel.fields.quantiteHorsCalibre" />}
										component={Input}
										type="number"
									/>
								</Grid>
								<Grid>
									<Field
										name={fieldsSuiviAvancementTri.QUANTITE_NON_COMMERCIALISABLE}
										label={<FormattedMessage id="suiviAvancement.suiviRessources.panel.fields.quantiteNonCommercialisable" />}
										component={Input}
										type="number"
									/>
								</Grid>
							</>
					}

				</Grid>
		}
	]

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Detail sections={sections} containerClassName={classes.details} />
		</form>
	)

}

const selector = formValueSelector(SUIVI_DE_TACHE_FORM)

SuiviDeTacheForm.propType = {
	currentTache: PropTypes.object,
	tempsPasseField: PropTypes.array,
	valuesToConserve: PropTypes.array,
	isFromPageSuivi: PropTypes.bool,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func
}

const mapStateToProps = (state, { currentTache, isFromPageSuivi }) => {
	const usersAutocomplete = currentTache.affectationLightBeanList
		.filter((ressource) => ressource.flagAffectationActive)
		.map((ressource) => {
			return ({
				label: ressource.user.preferences.ALIAS,
				code: ressource.user.id,
				tempsPasse: ressource.tempsPasse ?? 0
			})
		})

	const usersInactive = currentTache.affectationLightBeanList
		.filter((ressource) => !ressource.flagAffectationActive && ressource.tempsPasse > 0)
		.map((ressource) => {
			return ({
				label: ressource.user.preferences.ALIAS,
				code: ressource.user.id,
				tempsPasse: ressource.tempsPasse
			})
		})

	const checkedEtat = isFromPageSuivi ? currentTache.statut : currentTache.etat

	return ({
		initialValues: {
			choixEtat: checkedEtat,
			usersAutocomplete: usersAutocomplete,
			tempsPasseUser: [...usersAutocomplete, ...usersInactive],
			initialValuesToConserve: [...usersAutocomplete, ...usersInactive]
		},
		tempsPasseField: selector(state, 'tempsPasseUser') || [],
		valuesToConserve: selector(state, 'initialValuesToConserve') || []
	})
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: SUIVI_DE_TACHE_FORM,
		enableReinitialize: true,
		validate
	})
)(SuiviDeTacheForm)
