import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, { BO_FILTER_FORM } from '../BackOfficeContainer'
import {createCategorie, getCategories, updateCategorie} from './services/categoriesTechniquesApi'
import { getPreferences } from '../../common/user/services/userSelectors'
import { connect } from 'react-redux'
import BoListView from '../components/BoListView'
import CategoryIcon from '@material-ui/icons/Category';
import NoResultPage from '../../../components/NoResultPage'
import { reset, SubmissionError, change } from 'redux-form'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'
import FormButton from '../../../components/form/FormButton'
import { TYPE_DIALOG } from '../../../utils/constants'
import { DialogContext } from '../../../components/layout/dialog'
import Loader from '../../../components/layout/Loader'
import { compose } from 'redux'
import { injectFermeList } from '../ferme/services/fermeListInjector'
import CategorieForm from "./components/categorieForm";
import {getCategorieForInitValues} from "./categoriesTechniquesUtils";

export const CATEGORIE_TECHNIQUE_FORM = 'CATEGORIE_TECHNIQUE_FORM'
const ADD_CATEGORIE_TECHNIQUE_FORM = 'ADD_CATEGORIE_TECHNIQUE_FORM'

const CategoriesTechniquesPage = ({ idFerme, reset, change }) => {
	const intl = useIntl()
	const INIT_FILTERS = { idFerme }
	const [filters, setFilters] = React.useState(INIT_FILTERS)
	const [categories, setcategories] = React.useState([])
	const [selection, setSelection] = React.useState(undefined)
	const [loading, setLoading] = React.useState(false)
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)

	React.useEffect(() => {
		setInitialisation()
		setSelection(undefined)
	}, [filters])

	const setInitialisation = () => {
		setLoading(true)
		getCategories(filters)
			.then((categorieList) =>
		setcategories(categorieList.map(categorie => formatTuileCategorie(categorie)))
			)
			.finally(() => setLoading(false))
	}

	const formatTuileCategorie = (categorie) => ({
		uniqueId: `categorie-${categorie.id}`,
		icon: CategoryIcon,
		title: categorie.nom,
		data: categorie,
	})

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.categoriesTechniques.popin.title" />,
			<Grid item style={{padding: 20}}>
				<CategorieForm
					formName={ADD_CATEGORIE_TECHNIQUE_FORM}
					onSubmit={handleCreation}
					idFerme={filters.idFerme}
				/>
			</Grid>,
			[
				<FormButton
					type="primary"
					formName={ADD_CATEGORIE_TECHNIQUE_FORM}
				>
					<FormattedMessage id="actions.create" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	const handleUpdateCategorie = (values) =>
		updateCategorie(values)
			.then((newCategorie) => {
				snackSuccess({ id: 'bo.categoriesTechniques.snackbar.updateCategory' })
				setInitialisation()
				setSelection(formatTuileCategorie(newCategorie))
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})

	const handleCreation = (values) => {
		createCategorie(values)
			.then(() => {
				snackSuccess({ id: `bo.categoriesTechniques.snackbar.addCategory` })
				setSelection(undefined)
				reset(BO_FILTER_FORM)
				// Gestion du filter ferme après reset
				change(BO_FILTER_FORM, 'idFerme', filters.idFerme)
				setFilters({
					...INIT_FILTERS,
					idFerme: filters.idFerme
				})
				closeDialog()
			})
			.catch(e => {
				snackError({ id: 'global.errors.form' })
				throw new SubmissionError(e)
			})
	}

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.categoriesTechniques.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={openPopinCreation}><FormattedMessage id="bo.categoriesTechniques.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer inputLabel="bo.categoriesTechniques.inputQuery" onSubmit={setFilters} withFerme={false}>
			{loading ?
				<Loader fromBo />
				:
				categories.length > 0 ?
					<BoListView
						titleLeft={intl.formatMessage({ id: 'bo.categoriesTechniques.titleLeft' })}
						titleRight={selection?.title}
						tuileList={categories}
						selection={selection}
						handleSelection={setSelection}
						formName={CATEGORIE_TECHNIQUE_FORM}
					>
						{selection && selection.data && <>
								<CategorieForm
									formName={CATEGORIE_TECHNIQUE_FORM}
									onSubmit={handleUpdateCategorie}
									selection={selection}
									initialValues={getCategorieForInitValues(selection.data)}
								/>
						</>}
					</BoListView>
					: <NoResultPage />
			}
		</BackOfficeContainer>
	</>)
}

CategoriesTechniquesPage.propTypes = {
	idFerme: PropTypes.string,
	reset: PropTypes.func,
}

const mapStateToProps = (state) => ({
	idFerme: (getPreferences(state).FERME_ID || '0')
})

const actions = {
	reset,
	change
}

export default compose(
	connect(mapStateToProps, actions),
	injectFermeList
)(CategoriesTechniquesPage)

