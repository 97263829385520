import React from 'react'
import Loader from 'react-loader-advanced'
import CircularProgress from '@material-ui/core/CircularProgress'
import DataTable from 'isotope-client/components/datatable/DataTable'

/**
 * Loader pour la datatable
 */
const DataTableWithLoader = ({ loading, loaderStyle = {}, ...props }) => {
    return <Loader
        show={loading}
        message={<CircularProgress />}
        backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
        hideContentOnLoad
        style={{width: '100%', paddingLeft: '1%', paddingRight: '1%', ...loaderStyle }}
    >
        <DataTable {...props} />
    </Loader>

}

export default DataTableWithLoader
