import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { CODE_ERROR, colors, TYPE_PLANCHE, TYPE_SURFACE_ZONE } from '../../../../../utils/constants'

const styles = () => getStyles({
	container: {
		margin: '1.5em',
		fontFamily: 'Lato'
	},
	sectionTitle: {
		fontSize: 16,
		color: colors.text
	},
	redBold: {
		color: '#b70f0a'
	}
})

const PopinDeplacement = ({ classes, errors, warnings, surfaceDestination }) => {

	let surfaceDestination1
	if (surfaceDestination.zone !== TYPE_SURFACE_ZONE.BUTTE) {
		surfaceDestination1 = <li style={{ paddingBottom: '5px' }}>
			<FormattedMessage id="listAssolement.popinAlerte.content.surfaceDestination1"
			                  values={{
				                  zone: surfaceDestination.zone === TYPE_SURFACE_ZONE.SERRE
					                  ? <FormattedMessage id="enums.typeSurface.SER" />
					                  : <FormattedMessage id="enums.typeSurface.EXT" />,
				                  typeBloc: surfaceDestination.bloc,
				                  surface: <b>Surface {surfaceDestination.nom}</b>
			                  }}
			/></li>
	} else {
		surfaceDestination1 = <li style={{ paddingBottom: '5px' }}>
			<FormattedMessage id="listAssolement.popinAlerte.content.surfaceDestination1BUT"
			                  values={{
				                  zone: <FormattedMessage id="enums.typeSurface.BUT" />,
				                  surface: <b>Surface {surfaceDestination.nom}</b>
			                  }}
			/></li>
	}

	const surfaceDestination2 = <li style={{ paddingBottom: '5px' }}>
		<FormattedMessage id="listAssolement.popinAlerte.content.surfaceDestination2"
		                  values={{
			                  typePlanche: surfaceDestination.typePlanche === TYPE_PLANCHE.PERMANENTE
				                  ? <FormattedMessage id="enums.typePlanche.PER" />
				                  : <FormattedMessage id="enums.typePlanche.DIV" />,
			                  aireSurface: surfaceDestination.surface
		                  }}
		/></li>

	let culturesIncompatibles
	let culturesNecessitantAttention

	if (errors.length > 0) {
		culturesIncompatibles = errors.map((error) =>
			<li style={{ paddingBottom: '5px' }}>
				<FormattedMessage id="listAssolement.popinAlerte.content.culturesIncompatibles"
				                  values={{
					                  nomculture: error.nomCulture,
					                  surfaceOrigine: <b className={classes.redBold}>{error.nomPlanche}</b>,
					                  aireSurface: error.surface,
					                  codeErreur: error.errorCode === CODE_ERROR.ZONE ?
						                  <b className={classes.redBold}><FormattedMessage id="enums.codeErreur.ZONE" /></b> :
						                  <b className={classes.redBold}><FormattedMessage id="enums.codeErreur.TYPE" /></b>
				                  }}
				/></li>)

		culturesNecessitantAttention = errors.map((error) =>
			<li style={{ paddingBottom: '5px' }}>
				<FormattedMessage id="listAssolement.popinAlerte.content.culturesNecessitantAttention"
				                  values={{
					                  nomculture: error.nomCulture,
					                  surfaceOrigine: <b>{error.nomPlanche}</b>,
					                  aireSurface: <b>{error.surface}</b>
				                  }}
				/></li>)
	} else {
		culturesNecessitantAttention = warnings.map((error) =>
			<li style={{ paddingBottom: '5px' }}>
				<FormattedMessage id="listAssolement.popinAlerte.content.culturesNecessitantAttention"
				                  values={{
					                  nomculture: error.nomCulture,
					                  surfaceOrigine: <b>{error.nomPlanche}</b>,
					                  aireSurface: <b>{error.surface}</b>
				                  }}
				/></li>)
	}

	return (
		<Grid className={classes.container}>


			<>
				{errors.length > 0
					? <Grid><FormattedMessage id="listAssolement.popinAlerte.sections.errorIntro" /></Grid>
					: <Grid><FormattedMessage id="listAssolement.popinAlerte.sections.warningsIntro" /></Grid>
				}
				<br/><br/>
			</>


			<b className={classes.sectionTitle}><FormattedMessage id="listAssolement.popinAlerte.sections.surfaceDestination" /></b>
			<ul>{surfaceDestination1}{surfaceDestination2}</ul>

			{errors.length > 0 &&
			<>
				<b className={classes.sectionTitle}><FormattedMessage id="listAssolement.popinAlerte.sections.culturesIncompatibles" /></b>
				<ul>{culturesIncompatibles}</ul>
			</>}

			<b className={classes.sectionTitle}><FormattedMessage id="listAssolement.popinAlerte.sections.culturesAvecAttention" /></b>
			<ul>{culturesNecessitantAttention}</ul>
		</Grid>
	)
}

PopinDeplacement.propType = {
	errors: PropTypes.array,
	surfaceDestination: PropTypes.object,
	warnings: PropTypes.array,
	classes: PropTypes.object
}


export default withStyles(styles)(PopinDeplacement)