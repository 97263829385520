import { Tooltip, IconButton as MuiIconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const IconButton = ({ tooltip, tooltipProps, ...props }) => tooltip ?
	<Tooltip title={tooltip} {...tooltipProps}>
		<MuiIconButton {...props} />
	</Tooltip> :
	<MuiIconButton {...props} />


IconButton.propType = {
	tooltip: PropTypes.string,
	tooltipProps: PropTypes.object,
	props: PropTypes.object
}

export default IconButton