import React, {useState} from 'react'
import {dataTableActions, getStyles} from 'isotope-client'
import {getCurrentCampagne} from '../../common/campagne/campagneSelector'
import {compose} from 'redux'
import {connect} from 'react-redux'
import ToolBar from './component/ToolBar/ToolBar'
import {FormattedMessage} from 'react-intl'
import moment from 'moment'
import {withStyles} from '@material-ui/core'
import {colors, DATATABLE_SORT_ORDER, ETAPE_TYPE, NOM_VUE_LISTE_ASSOLEMENT} from '../../../utils/constants'
import {injectCultureDetailPanel} from '../planning/services/actions/detail/cultureDetailPanel'
import {injectSurfacePanel} from '../planning/services/actions/detail/surfaceDetailPanel'
import {injectEtapeDetailPanel} from '../planning/services/actions/detail/etapeDetailPanel'
import {getFilterValues} from '../planning/services/toolbar/toolbarSelector'
import NoResultPage from '../../../components/NoResultPage'
import DataTableWithLoader from "../../../components/layout/DataTableWithLoader";
import {getAssolement} from "../../bo/campagne/services/campagneApi";

const styles = () => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		height: '-webkit-fill-available',
	},
	liste: {
		overflowY: 'auto',
		marginLeft: '1%',
		marginRight: '1%',
		'& .MuiTableCell-root:first-child': {
			maxWidth: 35
		}
	},
	culture: {
		fontWeight: 'bold'
	},
	centeredCell: {
		textAlign: 'center'
	}
})

const ListAssolementComponents = ({ campagne, classes, selectRows, filters }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = React.useState(undefined)
	const [selectedRows, setSelectedRows] = useState([])
	const [page, setPage] = React.useState(1)
	const [elementsPerPage, setElementsPerPage] = React.useState(50)
	const [totalElements, setTotalElements] = React.useState(0)
	const [sort, setSort] = useState({ key: 'culture.nom', order: DATATABLE_SORT_ORDER.ASC })

	//TODO -- RG_PLALIST_031 : “Déplacer les étapes Récoltes” :
	//TODO -- Aucune des lignes sélectionnées n’a été marqué comme “bon à récolter” (sprint 2)
	//TODO -- RG_PLALIST_031 : “Allonger / réduire les étapes Récoltes” :
	//TODO -- Aucune des lignes sélectionnées n’a été marqué comme “bon à récolter” (sprint 2)
	//TODO -- RG_PLALIST_031 : “Déplacer les étapes implantation”  :
	//TODO -- Aucune des lignes sélectionnées n’a une étape “en cours”, “à réaliser” ou “terminée”  liée à l’étape

	const getAssolementDate = React.useCallback((type, row) => {
		return (row.assolementEtapeMap && row.assolementEtapeMap[type] && !row.assolementEtapeMap[type].deleted && row.assolementEtapeMap[type].dateDebut)
			? moment(row.assolementEtapeMap[type].dateDebut).format('DD/MM/YYYY')
			: ' - '
	}, [])

	const filterValues = React.useCallback((filters) => {
		return Object.keys(filters).reduce((acc, curr) => ({
			...acc,
			[curr]: Object.values(filters[curr].map(n => n.code))
		}), {})
	}, [])

	const [period, setPeriod] = React.useState({
		dateDebut: undefined,
		dateFin: undefined
	})
	React.useEffect(() => setPeriod({
		dateDebut: moment().day(1).format('YYYY-MM-DD'),
		dateFin: moment().day(7).format('YYYY-MM-DD')
	}), [])

	const refreshAssolements = () => {
		if (campagne && campagne.id) {
			setSelectedRows([])
			selectRows([])
			setIsLoading(true)
			getAssolement({campagneId: campagne.id, ...filterValues(filters), ...period}, {page: page - 1, size: elementsPerPage, sort: `${sort.key},${sort.order}`})
				.then(response => {
					setData(response.content)
					setTotalElements(response.totalElements)
				})
				.finally(() => setIsLoading(false))
		}
	}

	React.useEffect(() => {
		refreshAssolements()
	}, [campagne, filters, page, elementsPerPage, sort, period])

	return (
		<div className={classes.root}>
			<ToolBar campagne={campagne} period={period} setPeriod={setPeriod} refresh={refreshAssolements} />

			<div className={classes.liste}>
				{(campagne && campagne.id) && <DataTableWithLoader
					nom="ListAssolement"
					selectable
					displaySelectAll
					data={data}
					loading={isLoading}
					showBorders={false}
					defaultPageSize={50}
					loaderStyle={{padding: 0}}
					totalElements={totalElements}
					page={page}
					pageSize={elementsPerPage}
					onPageChange={(page) => setPage(page)}
					onElementsPerPageChange={(elementsPerPage) => {
						setElementsPerPage(elementsPerPage)
						selectRows([])
						setSelectedRows([])
					}}
					tableProps={{
						stickyHeader: true
					}}
					onFilterChange={key => {
						setSort(prev => {
							if (prev.key === key) {
								return ({
									...prev,
									order: prev.order === DATATABLE_SORT_ORDER.ASC ? DATATABLE_SORT_ORDER.DESC : DATATABLE_SORT_ORDER.ASC
								})
							}
							return ({
								key,
								order: DATATABLE_SORT_ORDER.ASC
							})
						})
					}}
					onRowClick={row => setSelectedRows(prev => {
						if (prev.find(p => p.id === row.id)) {
							const selectedRows= prev.filter(p => p.id !== row.id)
							selectRows(selectedRows)
							return selectedRows
						}
						const selectedRows = [ ...prev, row ]
						selectRows(selectedRows)

						return selectedRows
					})}
					selectRows={() => {
						const rows = (selectedRows.length === data.length) ? [] : [...data]
						setSelectedRows(rows)
						selectRows(rows)
					}}
					selectedRows={selectedRows}
					noResultFragment={<><br /><br /><br /><NoResultPage /></>}
					headers={[
						{
							key: 'culture.nom',
							name: <FormattedMessage id="listAssolement.column.culture" />,
							sortable: true,
							render: row => <span className={classes.culture}>{row.culture.nom}</span>
						}, {
							key: 'planche.nom',
							name: <FormattedMessage id="listAssolement.column.surface" />,
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => row.planche.nom
						}, {
							key: 'nbRangs',
							name: <FormattedMessage id="listAssolement.column.nbRangsDensite" />,
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (row.nbRangs)
								? <FormattedMessage id="listAssolement.content.nbRangs"
								                    values={{ nbRangs: row.nbRangs }}
								/>
								: <FormattedMessage id="listAssolement.content.densite"
								                    values={{ densite: row.densite }}
								/>
						}, {
							key: 'etape.SC.dateDebut',
							name: <FormattedMessage id="listAssolement.column.dateSemisEnPlaque" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (getAssolementDate(ETAPE_TYPE.SEMI_EN_CONTENANT, row))
						}, {
							key: 'etape.P.dateDebut',
							name: <FormattedMessage id="listAssolement.column.datePreparationPlanche" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (getAssolementDate(ETAPE_TYPE.PREP_PLANCHE, row))
						}, {
							key: 'etape.I.dateDebut',
							name: <FormattedMessage id="listAssolement.column.dateImplantation" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (getAssolementDate(ETAPE_TYPE.IMPLANTATION, row))
						}, {
							key: 'etape.SD.dateDebut',
							name: <FormattedMessage id="listAssolement.column.dateSemisDirect" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (getAssolementDate(ETAPE_TYPE.SEMI_DIRECT, row))
						}, {
							key: 'etape.R.dateDebut',
							name: <FormattedMessage id="listAssolement.column.datesRecolte" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (row.assolementEtapeMap && row.assolementEtapeMap.R && row.assolementEtapeMap.R.dateDebut)
								? <>
									{moment(row.assolementEtapeMap.R.dateDebut).format('DD/MM/YYYY')} -
									{moment(row.assolementEtapeMap.R.dateFin).format('DD/MM/YYYY')}
								</>
								: ' - '
						}, {
							key: 'etape.FR.dateDebut',
							name: <FormattedMessage id="listAssolement.column.dateFinRecolte" />,
							type: 'date',
							sortable: true,
							tableHeaderColumnProps: {
								className: classes.centeredCell
							},
							render: row => (getAssolementDate(ETAPE_TYPE.FIN_RECOLTE, row))
						}
					]}
				/>
				}
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state),
	filters: getFilterValues(state)
})

const actions = {
	selectRows: (rows) => dataTableActions.selectRows(NOM_VUE_LISTE_ASSOLEMENT.LISTE_ASSOLEMENT, rows)
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	injectCultureDetailPanel,
	injectSurfacePanel,
	injectEtapeDetailPanel
)(ListAssolementComponents)
