import { withStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { ScrollSyncPane } from 'react-scroll-sync'
import { compose } from 'redux'
import { displayAssolement } from '../../../../../utils/utils'
import { getActiveRow, getAssolementById, getAssolementsWithRef, getCurrentPage } from '../../services/assolement/assolementSelector'
import { withLineSeparator } from '../../utils/utils'
import MenuLeftPanelItem from './MenuLeftPanelItem'
import LocationOffIcon from '@material-ui/icons/LocationOff';
import {colors} from "../../../../../utils/constants";

const styles = () => getStyles({
	container: {
		width: '20rem'
	},
	assolementList: {
		paddingBottom: 0,
		paddingTop: 0,
		height: 50
	},
	assolementListItem: {
		margin: 0,
		'& span': {
			display: 'flex',
			'& p': {
				textAlign: 'center'
			}
		}
	},
	planche: {
		width: '4.5rem'
	},
	culture: {
		width: '100%'
	},
	divider: {
		width: 2
	},
	colWidthSource: {
		width: 100
	},
	sourceBody1: {
		padding: 0,
		borderBottomWidth: 0,
		width: 100,
		textAlign: 'center'
	},
	sourceBody2: {
		padding: 0,
		width: 'auto',
		textAlign: 'center'
	},
	scrollBody1: {
		overflow: 'hidden scroll',
		margin: 0,
		height: 'calc(100vh - 235px)',
		marginRight: 0,
		paddingRight: 0
	},
	withoutBorder: {
		borderRight: '0 !important'
	},
	names: {
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
		margin: '0 .5rem',
		lineHeight: 'initial'
	},
	cellTextInactive: {
		color: colors.placeholder,
	}
})

const moveToPosition = (ref, index, orLast, block = 'start') => {
	if (!ref || !ref.current) {
		return
	}
	const allTh = ref.current.querySelectorAll('th.widget-content-with-data')
	let element = allTh[Math.min(index, allTh.length - 1)]

	if (!element || !allTh.length || (allTh.length < index && !orLast)) {
		return
	}
	element.scrollIntoView({ behavior: 'instant', block: block })
}

const PlanningLeftPanel = ({
	activeRow,
	setActiveRow,
	campagne,
	isCompare,
	lineSeparator,
	assolements,
	isLoading,
	isScrollingBottom,
	currentPage,
	classes
}) => {

	const isActive = (id) => id === activeRow
	const rowClicked = assolementId => () => {
		if (!isActive(assolementId)) {
			setActiveRow(assolementId)
		}
	}

	const rowRef = React.useRef()

	React.useEffect(() => {
		if (!isLoading) {
			const scrollPosition = isScrollingBottom ? 'end' : 'start'
			const position = isScrollingBottom ? currentPage.size - 1 : currentPage.size
			moveToPosition(rowRef, position, false, scrollPosition)
		}
	}, [isLoading])

	React.useEffect(() => {
		if (currentPage.position) {
			moveToPosition(rowRef, currentPage.position, true, 'center')
		}
	}, [currentPage.position])

	return (
		<TableCell className="source-area widget-content">
			<TableContainer>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell className={classes.sourceBody1}>
								<div className="scroller-clip">
									<ScrollSyncPane>
										<div className={classnames(classes.scrollBody1, 'scroller')}>
											<div className="scroller-canvas">
												<div className="scroller-content">
													<div className="sroller-rows">
														<table ref={rowRef}>
															<colgroup>
																<col className={classes.colWidthSource} />
															</colgroup>
															<tbody>
															{assolements.map((assolement, index) => <React.Fragment
																	key={`assolement-planche-${assolement.planche.id}-${assolement.id}`}>
																	{lineSeparator && withLineSeparator(assolement, index, assolements) &&
																	<tr>
																		<th className="widget-content" style={{height: '30px'}}>
																			<div className="cell-content">
																					<span className={`${assolement?.planche?.inactive ? classes.cellTextInactive : 'cell-text'}`}>
																						<div className="source" style={{height: '30px'}}/>
																					</span>
																			</div>
																		</th>
																	</tr>}
																	{displayAssolement(isCompare, assolement, campagne.id) &&
																	<tr key={`assolement-planche-assolement-${assolement.id}`}>
																		<th
																			className={`${isCompare ? 'widget-content-compare' : 'widget-content'} widget-content-with-data`}>
																			<div className="cell-content">
																				<span className={`${assolement?.planche?.inactive ? classes.cellTextInactive : 'cell-text'}`}>
																					<div
																						className={isCompare ? `source-compare border ${isActive(assolement.id) ? 'flag active' : ''}` : `source border ${isActive(assolement.id) ? 'flag active' : ''}`}
																						style={{ justifyContent: `${assolement?.planche?.inactive && 'space-between'}`, paddingInline: 15}}
																						onClick={rowClicked(assolement.id)}>
																						<span className={`text-compare ${classes.names}`}>
																							{assolement.planche.nom}
																						</span>
																						{assolement?.planche?.inactive && <LocationOffIcon className={classes.cellTextInactive}/>}
																					</div>
																				</span>
																			</div>
																		</th>
																	</tr>}
																</React.Fragment>
															)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</ScrollSyncPane>
								</div>
							</TableCell>
							<TableCell className={classes.sourceBody2}>
								<div className="scroller-clip">
									<ScrollSyncPane>
										<div className={classnames(classes.scrollBody1, 'scroller')}>
											<div className="scroller-canvas">
												<div className="scroller-content">
													<div className="sroller-rows">
														<table>
															<colgroup>
																<col />
															</colgroup>
															<tbody>
															{assolements.map((assolement, index) => <React.Fragment
																	key={`assolement-culture-planche-${assolement.planche.id}-${assolement.id}`}>
																	{lineSeparator && withLineSeparator(assolement, index, assolements) &&
																	<tr>
																		<th key={`assolement-culture-planche-empty-${assolement.planche.id}`}
																		    className="widget-content" style={{height: '30px'}}>
																				<span className={`${assolement?.planche?.inactive ? classes.cellTextInactive : 'cell-text'}`}>
																					<div className="source" style={{height: '30px'}}/>
																				</span>
																		</th>
																	</tr>}
																{displayAssolement(isCompare, assolement, campagne.id) &&
																	<tr key={`assolement-culture-${assolement.id}`}>
																		<th
																			className={classnames(classes.withoutBorder, isCompare ? 'widget-content-compare' : 'widget-content')}>
																					<span className={`${assolement?.planche?.inactive ? classes.cellTextInactive : 'cell-text'}`}>
																						<div
																							className={isCompare ? `source-compare ${isActive(assolement.id) ? 'active' : ''}` : `source ${isActive(assolement.id) ? 'active' : ''}`}
																							onClick={rowClicked(assolement.id)}>
																							<MenuLeftPanelItem
																								isCompare={isCompare}
																								assolementId={assolement.id}
																								campagne={campagne}
																							/>
																						</div>
																					</span>
																		</th>
																	</tr>}
																</React.Fragment>
															)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</ScrollSyncPane>
								</div>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</TableCell>
	)
}

const mapStateToProps = (state, { activeId }) => ({
	activeAssolement: getAssolementById(state, activeId),
	assolements: getAssolementsWithRef(state),
	activeRow: getActiveRow(state),
	currentPage: getCurrentPage(state)
})

PlanningLeftPanel.propTypes = {
	campagne: PropTypes.object,
	activeRow: PropTypes.string,
	setActiveRow: PropTypes.func,
	isCompare: PropTypes.bool
}

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(PlanningLeftPanel)
