import React from 'react'
import PropTypes from 'prop-types'
import {Grid, withStyles} from '@material-ui/core'
import {FormattedMessage} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import {Field, reset, SubmissionError} from 'redux-form'
import Button from '../../../components/Button'
import BackOfficeContainer, {BO_FILTER_FORM} from '../BackOfficeContainer'
import Checkbox from '../../../components/form/Checkbox'
import {dataTableActions, getStyles} from 'isotope-client'
import NoResultPage from '../../../components/NoResultPage'
import {colors, ROLE_WEIGHT, TYPE_DIALOG} from '../../../utils/constants'
import {compose} from 'redux'
import PopinUserEdition from './PopinUserEdition'
import FormButton from '../../../components/form/FormButton'
import {DialogContext} from '../../../components/layout/dialog'
import {creerUtilisateur, deleteUtilisateur, updateUtilisateur} from './utilisateurApi'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import {connect} from 'react-redux'
import * as userSelectors from '../../common/user/services/userSelectors'
import {getPreferences} from '../../common/user/services/userSelectors'
import {hasRole} from '../../../components/security/RoleChecker'
import {getUser} from '../../fo/login/services/loginActions'
import SpringDataTableWithLoader from '../../../components/layout/SpringDataTableWithLoader'

const styles = () => getStyles({
	labelBox: {
		fontSize: 14,
		cursor: 'pointer',
		marginLeft: 5
	},
	utilisateurCell: {
		fontWeight: 'bold'
	},
	liste: {
		maxHeight: 'calc(100vh - 410px)',
		flex: '0 0 auto',
		display: 'block',
		overflowY: 'scroll',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'& .MuiTableCell-body': {
			height: 40,
			fontSize: 14
		},
		'& .MuiTableCell-head': {
			textAlign: 'center'
		},
		'& .MuiTableCell-stickyHeader': {
			backgroundColor: 'unset',
			fontSize: '14px'
		},
		'& .MuiTableRow-head': {
			backgroundColor: colors.backgroundSuiviTacheAvancement
		},
		'& .MuiTableHead-root': {
			height: 50
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073'
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		},
		margin: '0px 32px 0px 32px'
	},
	titleList: {
		marginBottom: 20,
		fontSize: 18,
		fontFamily: 'Lato',
		marginLeft: 32
	}
})

export const GESTION_UTILISATEURS_TABLE = 'GESTION_UTILISATEURS_TABLE'

export const USER_EDITION_FORM = 'USER_EDITION_FORM'

const openPopinUserEdition = (selectedUser, openDialog, handleSubmit, deleteUser) => {
	openDialog(
		selectedUser ? <FormattedMessage id="bo.utilisateur.form.titleUpdate" /> : <FormattedMessage id="bo.utilisateur.form.titleNew" />,
		<PopinUserEdition onSubmit={handleSubmit} selectedUser={selectedUser} deleteUser={deleteUser}/>,
		[
			<FormButton type="primary" formName={USER_EDITION_FORM}>
				<FormattedMessage id="bo.utilisateur.form.submit" />
			</FormButton>
		],
		TYPE_DIALOG.ACTION
	)
}


const UtilisateurPage = ({ isSuperviseur, userIdFerme, classes, refreshTable, reset, getUser, googleId }) => {
	const [filters, setFilters] = React.useState({ idFerme: isSuperviseur ? '0' : userIdFerme })
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const { snackError, snackSuccess } = useSnackbar()

	const onSubmit = (values) => {
		let data = {...values}
		if(values.id){
			return updateUtilisateur(data)
				.then(user => {
					if (user.me) {
						getUser(googleId)
					}
				})
				.then(() => snackSuccess({ id: 'snackbar.update.updateUtilisateur' }))
				.then(refreshTable())
				.then(closeDialog)
				.then(() => setFilters({}))
				.then(reset(BO_FILTER_FORM))
				.catch((e) => {
					snackError({ id: 'snackbar.error.updateUtilisateur' })
					throw new SubmissionError(e)
				})
		}else{
			return creerUtilisateur(data).then(() => snackSuccess({ id: 'snackbar.update.ajoutUtilisateur' }))
				.then(refreshTable())
				.then(closeDialog)
				.then(() => setFilters({}))
				.then(reset(BO_FILTER_FORM))
				.catch((e) => {
					snackError({ id: 'snackbar.error.ajoutUtilisateur' })
					throw new SubmissionError(e)
				})
		}
	}

	const deleteUser = (selectedUser) => {
		deleteUtilisateur(selectedUser.user.id)
			.then(closeDialog)
			.then(refreshTable)
			.then(reset(BO_FILTER_FORM))
			.then(() => setFilters({}))
			.then(() => snackSuccess({id:'snackbar.update.suppressionUtilisateur'}))
			.catch(() => snackError({id: 'snackbar.error.suppressionUtilisateur'}))
	}

	const dialogCreateUser = () => openPopinUserEdition(null, openDialog, onSubmit, deleteUser)

	const dialogUpdateUser = (selectedUser) => {
		openPopinUserEdition(selectedUser, openDialog, onSubmit, deleteUser)
	}

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.utilisateur.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={dialogCreateUser}><FormattedMessage id="bo.utilisateur.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer
			inputLabel="bo.utilisateur.inputQuery"
			onSubmit={setFilters}
			displayAll
			defaultAllFerme
			extraField={<Grid item><Field
				name="onlyActif"
				component={Checkbox}
				label={<span className={classes.labelBox}><FormattedMessage id="bo.utilisateur.onlyActif" /></span>}
			/></Grid>}
		>
			<Typography className={classes.titleList}><FormattedMessage id="bo.utilisateur.liste" /></Typography>
			<div className={classes.liste}>
				<SpringDataTableWithLoader
					nom={GESTION_UTILISATEURS_TABLE}
					showBorders={false}
					apiUrl="/bo/utilisateurs"
					defaultPageSize={50}
					filters={filters}
					onRowClick={event => {
						dialogUpdateUser(event)
					}}
					noResultFragment={<><br /><br /><br /><NoResultPage /></>}
					headers={[
						{
							key: 'utilisateur',
							name: <FormattedMessage id="bo.utilisateur.headers.utilisateur" />,
							tableHeaderColumnProps: {
								width: 268,
								align: 'left'
							},
							render: row => <span className={classes.utilisateurCell}>{row.user.firstname + ' ' + row.user.lastname}</span>
						},
						{
							key: 'alias',
							name: <FormattedMessage id="bo.utilisateur.headers.alias" />,
							tableHeaderColumnProps: {
								width: 143,
								align: 'center'
							},
							render: row => row.user.preferences.ALIAS
						},
						{
							key: 'profil',
							name: <FormattedMessage id="bo.utilisateur.headers.profil" />,
							tableHeaderColumnProps: {
								width: 268,
								align: 'center'
							},
							render: row => <FormattedMessage id={'bo.utilisateur.codeProfil.' + row.profil.code.toUpperCase()} />
						},
						{
							key: 'ferme',
							name: <FormattedMessage id="bo.utilisateur.headers.ferme" />,
							tableHeaderColumnProps: {
								width: 268,
								align: 'center'
							},
							render: row => `${row.ferme.raisonSociale} - ${row.ferme.codePostal}`
						},
						{
							key: 'etat',
							name: <FormattedMessage id="bo.utilisateur.headers.etat" />,
							tableHeaderColumnProps: {
								align: 'center'
							},
							render: row => row.etat ? <FormattedMessage id="bo.utilisateur.headers.etatActif" /> : <FormattedMessage id={'bo.utilisateur.headers.etatInactif'} />
						}
					]}
				/>
			</div>

		</BackOfficeContainer>
	</>)
}

UtilisateurPage.propTypes = {
	userIdFerme: PropTypes.string,
	isSuperviseur: PropTypes.bool,
	refreshTable: PropTypes.func,
	reset: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state) => {
	const preferences = getPreferences(state)
	const userAuthorities = userSelectors.getAuthorities(state)
	const googleId = userSelectors.getUser(state).googleId
	return {
		userIdFerme: (preferences.FERME_ID || '0'),
		isSuperviseur: hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities),
		googleId
	}
}

const actions = {
	refreshTable: () => dataTableActions.refresh(GESTION_UTILISATEURS_TABLE),
	reset,
	getUser,
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(UtilisateurPage)
