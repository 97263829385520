import React from 'react'
import PropTypes from 'prop-types'
import {Fab, Grid, MenuItem, withStyles} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import {getStyles} from 'isotope-client'
import {Field, reduxForm} from 'redux-form'
import {compose} from 'redux'
import Input from '../../components/form/Input'
import {FormattedMessage, useIntl} from 'react-intl'
import Select from '../../components/form/Select'
import {injectFermeList} from './ferme/services/fermeListInjector'
import SearchIcon from '@material-ui/icons/Search'
import {connect} from 'react-redux'
import {getPreferences} from '../common/user/services/userSelectors'
import * as userSelectors from '../common/user/services/userSelectors';
import {hasRole} from '../../components/security/RoleChecker';
import {colors, ROLE_WEIGHT} from '../../utils/constants';
import SaveIcon from '@material-ui/icons/Save';
import Typography from "@material-ui/core/Typography";

export const BO_FILTER_FORM = 'BO_FILTER_FORM'

const styles = () => getStyles({
	container: {
		height: 'calc(100vh - 270px)',
		padding: '30px 0px',
		marginTop: 45
	},
	containerWithoutFilter: {
		paddingBottom: 30,
		height: 'calc(100vh - 270px)',
		marginTop: 45
	},
	form: {
		marginBottom: 35,
		padding: '0px 20px',
		display: 'flex'
	},
	searchBtn: {
		marginLeft: 10
	},
	searchIcon: {
		width: 35,
		height: 35
	},
	errorMessage: {
		fontSize: 11,
		color: colors.error,
		paddingRight: 20
	},
})

/**
 * Container principal du back office
 * Inclut les filtres génériques des différentes pages
 * @constructor
 */
const BackOfficeContainer = (
	{
		withFilter,
		withInput,
		withFerme,
		withSaveBtn = false,
		labelSaveBtn = null,
		onSave,
		displayAll,
		inputLabel,
		extraField,
		fermeList,
		children,
		userAuthorities,
		handleSubmit,
		classes
	}
) => {
	const intl = useIntl()

	// On affiche les fermes + all si enabled
	const items = [...fermeList]
	if (displayAll) {
		items.unshift({id: '0', raisonSociale: intl.formatMessage({id: 'bo.filters.allFerme'})})
	}

	return (<Paper elevation={0} className={withFilter ? classes.container : classes.containerWithoutFilter}>
		{withFilter && <form className={classes.form} onSubmit={handleSubmit}>
			<Grid container spacing={4} alignItems="center">
				{withInput && <Grid item xs={3}>
					<Field
						name="query"
						type="text"
						component={Input}
						label={<FormattedMessage id={inputLabel}/>}
						fromBo
					/>
				</Grid>}
				{ withFerme &&
				<Grid item xs={4}>
					<Field
						name="idFerme"
						component={Select}
						label={<FormattedMessage id="bo.filters.ferme"/>}
						fromBo
						disabled={!hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities)}
					>
						{
							items.map(ferme =>
								<MenuItem key={`ferme-select-${ferme.id}`} value={ferme.id}>
									{`${ferme.raisonSociale} ${ferme.codePostal ? `(${ferme.codePostal})` : ''}`}
								</MenuItem>
							)
						}
					</Field>
				</Grid>}
				{extraField}
				<Fab color="primary" className={classes.searchBtn} onClick={handleSubmit}>
					<SearchIcon className={classes.searchIcon}/>
				</Fab>
			</Grid>
			{withSaveBtn && onSave  && (
				<Grid container item xs={3} justify='flex-end' alignItems={"center"}>
					{ labelSaveBtn && (
						<Typography className={classes.errorMessage}>
							<FormattedMessage id={labelSaveBtn}/>
						</Typography>
					)}
					<Fab color="secondary" onClick={onSave}>
						<SaveIcon/>
					</Fab>
				</Grid>
			)}
		</form>}
		{children}
	</Paper>)
}

BackOfficeContainer.defaultProps = {
	withFilter: true,
	withInput: true,
	withFerme: true,
	displayAll: false,
	defaultAllFerme: false,
	extraField: <></>
}

BackOfficeContainer.propTypes = {
	withFilter: PropTypes.bool, // Section de filtre
	withInput: PropTypes.bool, // Filtre input
	withFerme: PropTypes.bool, // Filtre ferme
	displayAll: PropTypes.bool, // Set all fermes,
	defaultAllFerme: PropTypes.bool, // Init value allFerme
	inputLabel: PropTypes.string, // Label du field input
	extraField: PropTypes.element, // Extra field pour liste user
	fermeList: PropTypes.array,
	classes: PropTypes.object,
	children: PropTypes.node,
	userAuthorities: PropTypes.array,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, {defaultAllFerme}) => {
	const preferences = getPreferences(state)
	const userAuthorities = userSelectors.getAuthorities(state)
	return {
		initialValues: {
			idFerme: defaultAllFerme && hasRole(ROLE_WEIGHT.ROLE_SUPERVISEUR, userAuthorities) ? '0' : (preferences.FERME_ID || '0')
		},
		userAuthorities
	}
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: BO_FILTER_FORM,
		destroyOnUnmount: true,
		enableReinitialize: true
	}),
	injectFermeList,
	withStyles(styles)
)(BackOfficeContainer)
