import { fetchFactory } from 'isotope-client'

export const sendFertilization = (form, isUpdate) => fetchFactory('/etape/fertilization', {
	method: isUpdate ? 'PUT' : 'POST',
	body: JSON.stringify(form)
})

export const getEtapeList = (filterForm) => fetchFactory('/etape/list', {
	method: 'POST',
	body: JSON.stringify(filterForm)
})
