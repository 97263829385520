import { DateTime } from 'luxon'
import { assertNotNullOrUndefined } from '../utils'
import { LocalDate } from './local-date'
import { LocalTime } from './local-time'
import { Temporal } from './temporal'

/**
 * Class correspondant à une Date dans la locale française. <br>
 * Elle permet de facilement accéder aux méthodes utilitaires (ie : comparaison, formattage)
 */
export class LocalDateTime extends Temporal {
	static CURRENT_ZONE = DateTime.now().zone

	static now = () => new LocalDateTime(DateTime.now().setZone(Temporal.API_TIMEZONE))

	static epoch = () => new LocalDateTime(DateTime.fromSeconds(0, {zone: Temporal.API_TIMEZONE}));

	static fromAPI(temporal) {
		if (assertNotNullOrUndefined(temporal)) {
			return new LocalDateTime(DateTime.fromISO(temporal, { zone: Temporal.API_TIMEZONE }))
		}
		return temporal
	}

	static fromAPINotNullOrEpoch(temporal) {
		return this.fromAPI(temporal) ?? this.epoch()
	}

	static fromJS = (date) => new LocalDateTime(DateTime.fromJSDate(date, { zone: Temporal.API_TIMEZONE }))

	format = (dateFormat, locale) => {
		return this.toDisplayDate(dateFormat, locale)
	}

	get date() {
		return LocalDate.fromJS(this._date.toJSDate())
	}

	get time() {
		return LocalTime.fromJS(this._date.toJSDate())
	}

	/**
	 * Date dans la "timezone" française.
	 */
	get jsDate() {
		return new Date(this._safe(this._date.setZone(Temporal.DISPLAY_TIMEZONE)).toISO())
	}

	plus(amount, unit) {
		return new LocalDateTime(this._date.plus({ [unit]: amount }))
	}

	toJSON() {
		return this._date.toISO({ includeOffset: false })
	}
}
