import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import { colors } from '../../../../../../utils/constants'
import PropTypes from 'prop-types'
import Title from '../../layout/Title'
import { BOARD_UNAVAILABLE_FIELDS, BLOCK_ACTION_TYPE, BOARD_UNAVAILABLE_FORM_NAME } from '../tool/suiviParBloc.constants'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, Field } from 'redux-form'
import { getBoardsUnavailables } from '../service/suiviParBlocApi'
import { unavailableBoardsToApp } from '../service/suiviParBlocApiMapper'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import ButtonWithMenu from '../../../../../../components/ButtonWithMenu'
import Checkbox from '../../../../../../components/form/Checkbox'
import { FormattedMessage } from 'react-intl'
import Loader from '../../../../../../components/layout/Loader'
import classnames from 'classnames'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LocationOffIcon from '@material-ui/icons/LocationOff'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { EventBusy, Edit, Delete } from '@material-ui/icons'
import { injectBoardUnavailableAddForm } from '../service/boardUnavailableInjector'
import moment from 'moment'
import { DateFormat } from '../../../../../../utils/dateConstants'
import { groupBoardsUnavailableByPeriod } from '../tool/suiviParBloc.utils'

/**
 * Styles
 */
const styles = (theme) => getStyles({
	root: {
		background: colors.etapeContrastBackground,
		borderRadius: '12px',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: '12px',
		maxHeight: '-webkit-fill-available'
	},
	header: {
		rowGap: '10px',
		padding: '10px 12px'
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	action: {
		minWidth: 115,
		width: 115
	},
	filterText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: colors.text
	},
	loader: {
		height: 'calc(100vh - 564px)'
	},
	boardContainer: {
		marginTop: 10,
		gap: 10,
		height: '-webkit-fill-available'
	},
	board: {
		cursor: 'pointer',
		minHeight: 100,
		maxHeight: 132,
		padding: '10px 5px',
		borderRadius: 5,
		backgroundColor: theme.palette.primary.light,
		borderWidth: 4,
		borderStyle: 'solid',
		borderColor: 'transparent',
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	selectedBoard: {
		borderColor: colors.lightPrimary
	},
	inactiveBoard: {
		borderColor: 'transparent'
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	title: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '600',
		color: '#000'
	},
	bodyContainer: {
		overflowY: 'auto',
		marginTop: 12,
		padding: 5,
		display: 'flex',
		flexDirection: 'column',
		gap: 5
	},
	dateLabel: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#88888A'
	},
	date: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	comment: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		color: '#161616'
	}
})

/**
 * BoardsUnavailableContent
 * @returns {JSX.Element}
 */
const BoardsUnavailableContent = ({ classes, block, boardAvailable, openBoardsUnavailableAddForm, openBoardsUnavailableUpdateForm, openDeleteBoardsUnavailableDialog }) => {
	const { currentWeek } = useTourPlaineContext()
	const { closePanel } = useContext(ActionPanelContext)
	const [isLoading, setIsLoading] = useState(true)
	const [selectedBoards, setSelectedBoards] = useState([])
	const [boards, setBoards] = useState([])

	const loadData = useCallback(() => {
		setIsLoading(true)
		// reset selection
		setSelectedBoards([])
		getBoardsUnavailables(block.uniqueId, currentWeek, boardAvailable)
			.then(result => {
				setBoards(unavailableBoardsToApp(result))
			})
			.finally(() => setIsLoading(false))
	}, [block.uniqueId, currentWeek, boardAvailable])

	/**
	 * useEffect
	 */
	useEffect(() => {
		loadData()
	}, [loadData])

	return (
		<div className={classes.root}>
			<Grid container className={classes.header}>
				<Title actionBlock={BLOCK_ACTION_TYPE.BOARDS_UNAVAILABLE} />
				<Grid item container direction="row" justify="space-between" alignItems="center">
					<Grid item xs>
						<Typography className={classes.descriptionText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.description" /><br />
							<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.description2" />
						</Typography>
					</Grid>
					<Grid item>
						<ButtonWithMenu
							type="primary"
							startIcon={<MoreHorizIcon />}
							items={[
								{
									label: <Typography><FormattedMessage id="tourPlaine.actions.addUnavailableSlot" /></Typography>,
									icon: <EventBusy color="primary" />,
									onClick: () => openBoardsUnavailableAddForm(block, selectedBoards, loadData),
									disabled: selectedBoards.some(sBoard => sBoard.start !== null && sBoard.end !== null)
								},
								{
									label: <Typography><FormattedMessage id="tourPlaine.actions.updateUnavailableSlot" /></Typography>,
									icon: <Edit color="primary" />,
									onClick: () => openBoardsUnavailableUpdateForm(block, selectedBoards, loadData),
									disabled: selectedBoards.every(sBoard => sBoard.start === null && sBoard.end === null) || !groupBoardsUnavailableByPeriod(selectedBoards).length
								},
								{
									label: <Typography><FormattedMessage id={`tourPlaine.actions.deleteUnavailable${selectedBoards.length > 1 ? 's' : ''}Slot`} /></Typography>,
									icon: <Delete color="primary" />,
									onClick: () => openDeleteBoardsUnavailableDialog(selectedBoards.filter(sBoard => sBoard.start !== null && sBoard.end !== null), loadData),
									disabled: !selectedBoards.some(sBoard => sBoard.start !== null && sBoard.end !== null)
								}
							]}
							disabled={!selectedBoards.length}
							className={classes.action}
						>
							<FormattedMessage id="tourPlaine.actions.button" />
						</ButtonWithMenu>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center">
					<Grid item style={{ marginRight: 24 }}>
						<Typography className={classes.filterText}>
							<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.filter" />
						</Typography>
					</Grid>
					<Field
						name={BOARD_UNAVAILABLE_FIELDS.BOARD_AVAILABLE_FIELD}
						component={Checkbox}
						label={
							<Typography className={classes.filterText} style={{ marginLeft: 10 }}>
								<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.boardAvailable" />
							</Typography>
						}
					/>
				</Grid>
			</Grid>
			<Box display="grid" gridTemplateColumns="repeat(5, 1fr)" className={classes.boardContainer}>
				{
					isLoading ?
						(
							<Box gridColumn="span 5">
								<Loader show={isLoading} minHeight={false} style={classes.loader} />
							</Box>
						) : (
							boards.map((board, index) => {
									const isSelected = selectedBoards.find(sboard => sboard.id === board.id)
									return (
										<Box
											gridColumn="span 1"
											className={classnames(classes.board, isSelected ? classes.selectedBoard : classes.inactiveBoard)}
											key={`${index}_${board.id}`}
											onClick={() => setSelectedBoards(prev => {
												closePanel()
												if (prev.find(sboard => sboard.id === board.id)) {
													return prev.filter(sboard => sboard.id !== board.id)
												}

												return [...prev, board]
											})}
										>
											<div className={classes.titleContainer}>
												<Typography className={classes.title}>
													{board.label}
												</Typography>
												{
													(board.start !== null && board.end !== null) ?
														<LocationOffIcon style={{ color: '#B70F0A' }} />
														:
														<CheckCircleOutlineIcon style={{ color: '#1EBB6B' }} />
												}
											</div>
											{(board.start && board.end) && (
												<div className={classes.bodyContainer}>
													<Grid item container direction="row" justify="space-between">
														<Typography className={classes.dateLabel}>
															<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.start" />
														</Typography>
														<Typography className={classes.date}>
															{moment(board.start, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY)}
														</Typography>
														<Typography className={classes.dateLabel}>
															<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.date.end" />
														</Typography>
														<Typography className={classes.date}>
															{moment(board.end, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY)}
														</Typography>
													</Grid>
													<Typography className={classes.comment}>
														{board.comment}
													</Typography>
												</div>
											)}
										</Box>
									)
								}
							)
						)
				}
			</Box>
		</div>)
}

BoardsUnavailableContent.propTypes = {
	classes: PropTypes.object,
	block: PropTypes.object,
	boardAvailable: PropTypes.bool,
	openBoardsUnavailableAddForm: PropTypes.func,
	openBoardsUnavailableUpdateForm: PropTypes.func,
	openDeleteBoardsUnavailableDialog: PropTypes.func
}

const mapStateToProps = (state) => {
	const selector = formValueSelector(BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_FORM)
	return ({
		boardAvailable: selector(state, BOARD_UNAVAILABLE_FIELDS.BOARD_AVAILABLE_FIELD)
	})
}

export default compose(
	injectBoardUnavailableAddForm,
	connect(mapStateToProps, {  }),
	reduxForm({
		form: BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_FORM,
		enableReinitialize: true
	}),
	withStyles(styles)
)(BoardsUnavailableContent)