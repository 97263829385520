/**
 * Action permettant de mettre à jour un panneau latéral
 * @type {string}
 */
export const ACTION_UPDATE = 'update'
/**
 * Action permettant de réinitialiser l'état d'un panneau latéral
 * @type {string}
 */
export const ACTION_RESET = 'reset'
/**
 * Etat initial d'un panneau latéral
 * @type {{title: string, actions: Array<React.Component>, content: React.Component}}
 */