import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Tuile from './Tuile'
import Button from '../../../components/Button'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../../utils/constants'
import FormButton from '../../../components/form/FormButton'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = theme => getStyles({
	container: {
		borderTop: `1px solid ${theme.palette.borderColor}`
	},
	listTab: {
		borderRight: `1px solid ${theme.palette.borderColor}`,
		padding: '0px 20px'
	},
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary
	},
	contentLeft: {
		height: 'calc(100vh - 424px)',
		overflow: 'auto'
	},
	contentLeftWithoutFilter: {
		height: 'calc(100vh - 319px)',
		overflow: 'auto'
	},
	contentRight: {
		height: 'calc(100vh - 482px)',
		overflow: 'auto'
	},
	contentRightWithoutFilter: {
		height: 'calc(100vh - 379px)',
		overflow: 'auto'
	},
	paddingHorizontalLeft: {
		paddingLeft: 10,
		paddingRight: 20
	},
	paddingHorizontalRight: {
		paddingLeft: 30,
		paddingRight: 50
	},
	actionBar: {
		padding: '15px 20px',
		boxSizing: 'border-box',
		height: 60,
		borderTop: '1px rgba(0,0,0,0.08)solid'
	},
	actionBtn: {
		minWidth: 150
	},
	leftButton: {
		color: colors.text
	}
})

/**
 * View du backOffice sur deux colonnes
 */
const BoListView = ({
	titleRight,
	titleLeft,
	selection,
	handleSelection,
	tuileList,
	withFilter,
	children,
	classes,
	formName,
	withButton,
	resetForm
}) => {
	return (
		<Grid container className={withFilter && classes.container}>
			<Grid item container xs={5} direction="column" className={classes.listTab}>
				<Grid item container justify="flex-start" className={classnames(classes.title, classes.paddingHorizontalLeft)}>
					{titleLeft}
				</Grid>
				<Grid item container direction="column" wrap="nowrap" className={classnames(withFilter ? classes.contentLeft : classes.contentLeftWithoutFilter, classes.paddingHorizontalLeft)}>
					{tuileList.map(tuile => <Tuile key={`tuile-${tuile.uniqueId}`} tuile={tuile} handleSelection={handleSelection} selection={selection} />)}
				</Grid>
			</Grid>
			<Grid item container xs={7} direction="column">
				<Grid item container direction="column" alignItems="flex-start" className={classnames(classes.title, classes.paddingHorizontalRight)}>
					{titleRight}
				</Grid>
				<Grid item container direction="column" wrap="nowrap" className={classnames(withFilter ? classes.contentRight : classes.contentRightWithoutFilter, classes.paddingHorizontalRight)}>
					{children}
				</Grid>
				{withButton && <Grid container justify="flex-end" alignContent="center" className={classes.actionBar}>
					<Button withBorder={false} variant="text" className={classnames(classes.actionBtn, classes.leftButton)} onClick={() => resetForm(formName)}>
						<FormattedMessage id="actions.cancel" />
					</Button>
					<FormButton formName={formName} withBorder={false} variant="text" className={classes.actionBtn}>
						<FormattedMessage id="actions.save" />
					</FormButton>
				</Grid>}

			</Grid>
		</Grid>
	)
}

BoListView.defaultProps = {
	withFilter: true,
	withButton: true
}

BoListView.propTypes = {
	titleRight: PropTypes.string,
	titleLeft: PropTypes.string,
	selection: PropTypes.object,
	handleSelection: PropTypes.func,
	tuileList: PropTypes.array,
	withFilter: PropTypes.bool,
	children: PropTypes.object,
	classes: PropTypes.object,
	formName: PropTypes.string,
	withButton: PropTypes.bool
}

const actions = {
	resetForm: (formName) => dispatch => dispatch(reset(formName)),
}


export default compose(
	connect(undefined, actions),
	withStyles(styles)
)(BoListView)
