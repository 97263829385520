import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import FormButton from '../../../../../../components/form/FormButton'
import FormattedMessageWithBold from '../../../../../../components/FormattedMessageWithBold'
import FenceIcon from '../../../../../../components/icon/FenceIcon'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { TYPE_DIALOG } from '../../../../../../utils/constants'
import { DateFormat } from '../../../../../../utils/dateConstants'
import { injectPlancheIndisponible } from '../../../../../bo/blocSurface/injector/plancheIndisponibleInjector'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import BoardUnavailableAddForm from '../component/boardUnavailable/BoardUnavailableAddForm'
import BoardUnavailableUpdateForm from '../component/boardUnavailable/BoardUnavailableUpdateForm'
import { BOARD_UNAVAILABLE_FIELDS, BOARD_UNAVAILABLE_FORM_NAME } from '../tool/suiviParBloc.constants'
import { addBoardsUnavailables, checkCulture, deleteBoardsUnavailables, updateBoardsUnavailables } from './suiviParBlocApi'
import { addBoardsUnavailablesToApi, updateBoardsUnavailablesToApi } from './suiviParBlocApiMapper'

/**
 * Styles
 */
const styles = () => getStyles({
	informationText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: '#000'
	},
	ulContainer: {
		marginTop: 0,
		marginLeft: '-20px',
		marginBottom: 10
	},
})

/**
 * Inject the Weather Update Form
 */
export const injectBoardUnavailableAddForm = (WrappedComponent) => {

	const OpenBoardUnavailableFormInjector = ({ classes, getNotifications, ...props }) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)
		const { openDialog, closeDialog } = useContext(DialogContext)
		const { loadSuiviBlockNotifs } = useTourPlaineContext()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		/**
		 * Add
		 */
		const addFormAction = [
			<FormButton type="primary" formName={BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_ADD_FORM}>
				<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.submit" />
			</FormButton>
		]

		const openBoardsUnavailableAddForm = useCallback((block, boards, onSave) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.suiviParBloc.boardUnavailable.panel.title.add' },
				<BoardUnavailableAddForm
					boards={boards}
					onSubmit={values => {
						const data = addBoardsUnavailablesToApi(values)
						checkCulture([data])
							.then(() => addBoardsUnavailables(data)
								.then(() => {
									loadSuiviBlockNotifs(block.uniqueId)
									onSave()
									closePanel()
									getNotifications()
									refreshSolverInfosForCurrentCampagne()
									snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								})
								.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)))
							.catch((assolementList) => {
								if (assolementList.response.status === 400) {
									props.openPopinPlancheIndisponible(assolementList.bodyError.flat(), data, addBoardsUnavailables)
								} else {
									snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								}
							})
					}} />,
				addFormAction,
				<FenceIcon height={24} width={24} />
			)
		}, [])

		/**
		 * Update
		 */
		const updateFormAction = [
			<FormButton type="primary" formName={BOARD_UNAVAILABLE_FORM_NAME.BOARD_UNAVAILABLE_UPDATE_FORM}>
				<FormattedMessage id="tourPlaine.suiviParBloc.boardUnavailable.panel.submit" />
			</FormButton>
		]

		const openBoardsUnavailableUpdateForm = useCallback((block, boards, onSave) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.suiviParBloc.boardUnavailable.panel.title.update' },
				<BoardUnavailableUpdateForm
					boards={boards}
					onSubmit={values => {
						const data = updateBoardsUnavailablesToApi(values[BOARD_UNAVAILABLE_FIELDS.BOARDS])
						checkCulture(data)
							.then(() => updateBoardsUnavailables(data)
								.then(() => {
									loadSuiviBlockNotifs(block.uniqueId)
									onSave()
									closePanel()
									getNotifications()
									refreshSolverInfosForCurrentCampagne()
									snackSuccess(<FormattedMessage id="snackbar.update.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								})
								.catch(() => snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)))
							.catch((assolementList) => {
								if (assolementList.response.status === 400) {
									props.openPopinPlancheIndisponible(assolementList.bodyError.flat(), data, updateBoardsUnavailables)
								} else {
									snackError(<FormattedMessage id="snackbar.error.tourPlaine.addDataOnBoards" values={{ count: boards.length }} />)
								}
							})
					}} />,
				updateFormAction,
				<FenceIcon height={24} width={24} />
			)
		}, [])

		/**
		 * Delete
		 */
		const openDeleteBoardsUnavailableDialog = (boards, onSave) => openDialog({ id: boards.length > 1 ? 'tourPlaine.suiviParBloc.boardUnavailable.dialog.title' : 'tourPlaine.suiviParBloc.boardUnavailable.dialog.singleTitle' },
			<Grid style={{ margin: '1.5em' }}>
				<Grid>
					<Typography className={classes.informationText}>
						<FormattedMessage id={boards.length > 1 ? 'tourPlaine.suiviParBloc.boardUnavailable.dialog.description' : 'tourPlaine.suiviParBloc.boardUnavailable.dialog.singleDescription'} />
					</Typography>
				</Grid>
				<br />
				<Grid direction="row">
					<Typography className={classes.informationText}>
						<ul className={classes.ulContainer}>
							{
								boards.map(board => (
									<li key={board.id}>
										<FormattedMessageWithBold
											id="tourPlaine.suiviParBloc.boardUnavailable.dialog.slots"
											values={{
												block: board.label,
												start: moment(board.start, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY),
												end: moment(board.end, DateFormat.YYYY_MM_DD).format(DateFormat.DD_MM_YYYY)
											}}
										/>

									</li>
								))
							}
						</ul>
					</Typography>
				</Grid>
			</Grid>,
			[
				<Button
					type="primary"
					variant="text"
					withBorder={false}
					onClick={() => {
						deleteBoardsUnavailables(boards.map(board => board.id))
							.then(() => {
								onSave()
								refreshSolverInfosForCurrentCampagne()
								getNotifications()
								snackSuccess(<FormattedMessage id={`snackbar.update.tourPlaine.deleteBoard${boards.length > 1 ? 's' : ''}Unavailable`} />)
							})
							.catch(() => snackError(<FormattedMessage id={`snackbar.update.tourPlaine.deleteBoard${boards.length > 1 ? 's' : ''}Unavailable`} />))
							.finally(() => closeDialog())
					}}
				>
					<FormattedMessage id="actions.confirm" />
				</Button>
			],
			TYPE_DIALOG.ACTION,
			'actions.close',
			<FenceIcon height={24} width={24} />)

		return <WrappedComponent
			{...props}
			openBoardsUnavailableAddForm={openBoardsUnavailableAddForm}
			openBoardsUnavailableUpdateForm={openBoardsUnavailableUpdateForm}
			openDeleteBoardsUnavailableDialog={openDeleteBoardsUnavailableDialog}
		/>
	}

	OpenBoardUnavailableFormInjector.propTypes = {
		classes: PropTypes.object,
		openPopinPlancheIndisponible: PropTypes.func
	}

	const actions = {
		getNotifications
	}

	return compose(
		injectPlancheIndisponible,
		connect(null, actions),
		withStyles(styles)
	)(OpenBoardUnavailableFormInjector)
}
