import * as api from '../planningApi'

export const SAVE_COMMENTAIRE_SUIVI_REQUEST = 'SAVE_COMMENTAIRE_SUIVI_REQUEST'
export const SAVE_COMMENTAIRE_SUIVI_SUCCESS = 'SAVE_COMMENTAIRE_SUIVI_SUCCESS'
export const SAVE_COMMENTAIRE_SUIVI_ERROR = 'SAVE_COMMENTAIRE_SUIVI_ERROR'
export const saveCommentaireSuivi = (selectedAssolementsID, values, idCommentaire) => dispatch => {
	dispatch({ type: SAVE_COMMENTAIRE_SUIVI_REQUEST })
	const promise = typeof idCommentaire !==  'undefined' ? api.modifierCommentaireSuivi(selectedAssolementsID, values, idCommentaire) : api.creerListCommentaireSuivi(selectedAssolementsID, values)
	return promise
		.then(assolement => dispatch({
			type: SAVE_COMMENTAIRE_SUIVI_SUCCESS,
			assolement
		}))
		.catch(e => {
			dispatch({ type: SAVE_COMMENTAIRE_SUIVI_ERROR, e })
			throw e
		})
}

export const SAVE_COMMENTAIRE_PLANCHE_REQUEST = 'SAVE_COMMENTAIRE_PLANCHE_REQUEST'
export const SAVE_COMMENTAIRE_PLANCHE_SUCCESS = 'SAVE_COMMENTAIRE_PLANCHE_SUCCESS'
export const SAVE_COMMENTAIRE_PLANCHE_ERROR = 'SAVE_COMMENTAIRE_PLANCHE_ERROR'
export const saveCommentairePlanche = (idPlanche, idCampagne, values, idCommentaire) => dispatch => {
	dispatch({ type: SAVE_COMMENTAIRE_PLANCHE_REQUEST })
	const promise = typeof idCommentaire !==  'undefined' ? api.modifierCommentairePlanche(idPlanche, idCampagne, values, idCommentaire) : api.creerCommentairePlanche(idPlanche, idCampagne, values)
	return promise
		.then(commentaire => dispatch({
			type: SAVE_COMMENTAIRE_PLANCHE_SUCCESS,
			commentaire,
			idPlanche
		}))
		.catch(e => {
			dispatch({ type: SAVE_COMMENTAIRE_PLANCHE_ERROR, e })
			throw e
		})
}

export const DELETE_COMMENTAIRE_ETAPE_REQUEST = 'DELETE_COMMENTAIRE_ETAPE_REQUEST'
export const DELETE_COMMENTAIRE_ETAPE_SUCCESS = 'DELETE_COMMENTAIRE_ETAPE_SUCCESS'
export const DELETE_COMMENTAIRE_ETAPE_ERROR = 'DELETE_COMMENTAIRE_ETAPE_ERROR'
export const deleteCommentEtape = (idCommentaire) => dispatch => {
	dispatch({ type: DELETE_COMMENTAIRE_ETAPE_REQUEST })
	return api.deleteCommentEtape(idCommentaire)
		.then(assolementEtape => dispatch({
			type: DELETE_COMMENTAIRE_ETAPE_SUCCESS,
			assolementEtape,
		}))
		.catch(e => {
			dispatch({ type: DELETE_COMMENTAIRE_ETAPE_ERROR, e })
			throw e
		})
}

export const DELETE_COMMENTAIRE_PLANCHE_REQUEST = 'DELETE_COMMENTAIRE_PLANCHE_REQUEST'
export const DELETE_COMMENTAIRE_PLANCHE_SUCCESS = 'DELETE_COMMENTAIRE_PLANCHE_SUCCESS'
export const DELETE_COMMENTAIRE_PLANCHE_ERROR = 'DELETE_COMMENTAIRE_PLANCHE_ERROR'
export const deleteCommentPlanche = (idCommentaire, idPlanche) => dispatch => {
	dispatch({ type: DELETE_COMMENTAIRE_PLANCHE_REQUEST, idCommentaire, idPlanche })
	return api.deleteCommentPlanche(idCommentaire)
		.then(() => dispatch({
			type: DELETE_COMMENTAIRE_PLANCHE_SUCCESS,
			idCommentaire,
			idPlanche
		}))
		.catch(e => {
			dispatch({ type: DELETE_COMMENTAIRE_PLANCHE_ERROR, e })
			throw e
		})
}

export const DELETE_COMMENTAIRE_SUIVI_REQUEST = 'DELETE_COMMENTAIRE_SUIVI_REQUEST'
export const DELETE_COMMENTAIRE_SUIVI_SUCCESS = 'DELETE_COMMENTAIRE_SUIVI_SUCCESS'
export const DELETE_COMMENTAIRE_SUIVI_ERROR = 'DELETE_COMMENTAIRE_SUIVI_ERROR'
export const deleteCommentSuivi = (idCommentaire) => dispatch => {
	dispatch({ type: DELETE_COMMENTAIRE_SUIVI_REQUEST })
	return api.deleteCommentSuivi(idCommentaire)
		.then(assolement => dispatch({
			type: DELETE_COMMENTAIRE_SUIVI_SUCCESS,
			assolement,
		}))
		.catch(e => {
			dispatch({ type: DELETE_COMMENTAIRE_SUIVI_ERROR, e })
			throw e
		})
}
