/**
 * Map Culture object from Api to App Crop in Tour de plaine
 */

export const cropToApp = (culture, comments) => culture ? ({
	id: culture.id,
	idFarm: culture.idFerme,
	code: culture.code,
	name: culture.nom,
	family: culture.famille,
	specie: culture.espece,
	variety: culture.variete,
	cultivar: culture.cultivar,
	comment: comments,
	prodMino: culture.prodMino
}) : undefined

/**
 * Map Planche object from Api to App board in Tour de plaine
 */
export const boardToApp = (planche) => planche ? ({
	id: planche.id,
	idFarm: planche.idFerme,
	name: planche.nom,
	type: planche.typePlanche,
	bloc: planche.bloc,
	zone: planche.zone,
	surface: planche.surface,
	length: planche.longueur,
	width: planche.largeur,
	startUnavailableDate: planche.dateDebutIndisponibilite,
	endUnavailableDate: planche.dateFinIndisponibilite,
	inactive: planche.inactive,
	unavailable: planche.indisponible,
	commments: planche.commentaires
}) : undefined

/**
 * Map One In Progress Crops Api To App
 * DataTable
 */
export const inProgressCropToApp = (val) => ({
	id: val.assolement.id,
	board: boardToApp(val.assolement.planche),
	step: val.etape || 'CROISSANCE',
	startHarvestDate: val.debutRecolte,
	endHarvestDate: val.finRecolte,
	currentOrNextCrop: cropToApp(val.assolementEnCoursOuSuivant?.culture, val.assolementEnCoursOuSuivant?.commentaires || []),
	seedlingDate: val.dateSemis,
	crop: cropToApp(val.assolement.culture, val.assolement.commentaires || []),
	plannedPrepDate: val.debutPreparation,
	plannedImplantDate: val.debutImplantation,
	// For existing action, ot mapped because not used in TP
	assolement: val.assolement,
	assolementEnCoursOuSuivant: val.assolementEnCoursOuSuivant,
	assolementEtapeMap: val.assolementEtapeMap
})

/**
 * Map All In Progress Crops Api To App
 * DataTable
 */
export const inProgressCropsToApp = (values) => values.map(val => inProgressCropToApp(val))

/**
 * Map Tasks Api To App
 * DataTable
 */
export const taskToApp = (values) => values.map((val) => {
		const arbitrage = `${val.arbitrageTacheGenerique || val.arbitrageRecolte || val.arbitrageRetard}`

		return ({
			id: val.id,
			family: val.famille,
			task: val.tache,
			crop: cropToApp(val.assolement?.culture, val.assolement?.commentaires || []),
			board: boardToApp(val.assolement?.planche),
			plannedDate: val.datePrevue,
			material: val.materiels,
			seedlingDate: val.dateSemis,
			arbitrage: arbitrage,
			taskGeneric: val.arbitrageTacheGenerique,
			taskHarvest: val.arbitrageRecolte,
			taskWaiting: val.arbitrageRetard,
			initialDate: val.dateInitiale,
			culture: val.assolement?.culture.nom,
			...val
		})
	}
)
