import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {compose} from 'redux'
import {change, Field, Form, formValueSelector, isInvalid, reduxForm} from 'redux-form'
import {getInformationsTache} from '../utils/utilsMobile'
import {colors} from '../../../../utils/constants'
import Button from '../../../../components/Button'
import Input from '../../../../components/form/Input'
import MicIcon from '@material-ui/icons/Mic'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import {ACTION_TYPE, OUI_NON, TYPE_TACHE} from '../utils/constantsSuiviTache'
import Select from '../../../../components/form/Select'
import FormButton from '../../../../components/form/FormButton'
import {demarrageImpossible, finDeTache} from '../mobileApi'
import {useMobileContext} from '../MobileProvider'
import {useSnackbar} from '../../../../components/layout/snackbar/SnackbarContext'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

export const DEMARRAGE_IMPOSSIBLE_OU_TERMINE = 'DEMARRAGE_IMPOSSIBLE_OU_TERMINE'

const styles = () => getStyles({
	form: {
		backgroundColor: colors.light,
		width: '100%',
		fontFamily: 'Lato',
		fontSize: 14,
		height: 'calc(100vh - 175px)',
		overflowY: 'auto'
	},
	formKeyboard: {
		backgroundColor: colors.light,
		width: '100%',
		fontFamily: 'Lato',
		fontSize: 14
	},
	formFields: {
		width: '100%'
	},
	gridPadding: {
		padding: 20
	},
	marginTop: {
		marginTop: 8
	},
	description: {
		textAlign: 'justify',
		marginTop: 20
	},
	icon: {
		width: 17,
		height: 17,
		marginRight: 5
	},
	insideTextButtonCommentaire: {
		textAlign: 'left',
		fontSize: 16,
		width: 180,
		fontWeight: 'bold'
	},
	commentaire: {
		textAlign: 'center',
		width: 260,
		height: 80,
		borderRadius: 10,
		margin: 20
	},
	centeredGrid: {
		alignSelf: 'center',
		textAlign: '-webkit-center'
	},
	font16: {
		fontSize: 16
	},
	affectOutilsTemps: {
		marginBottom: 10,
		marginTop: 20
	},
	title: {
		fontSize: 18,
		textAlign: 'center',
		paddingTop: 20,
		marginBottom: -10
	},
	margin10: {
		marginLeft: 10,
		marginRight: 10
	},
	inputs: {
		fontSize: 16
	},
	boutonGrid: {
		backgroundColor: colors.light,
		padding: 10,
		position: 'fixed',
		bottom: 0
	},
	boutonGridKeyboard: {
		backgroundColor: colors.light,
		padding: 10
	},
	bigButtonValidate: {
		textAlign: 'center',
		width: 160,
		height: 80,
		minWidth: 160,
		borderRadius: 15,
		fontWeight: 'normal'
	},
	bigButtonCancel: {
		textAlign: 'center',
		width: 160,
		height: 80,
		minWidth: 160,
		borderRadius: 15,
		border: 'none',
		color: 'black',
		fontWeight: 'normal'
	},
	insideTextButton: {
		textAlign: 'center',
		fontSize: 16,
		width: 83
	},
	infoSurTache: {
		marginTop: 5
	},
	whiteGrid: {
		backgroundColor: 'white',
		width: '100%',
		height: 100,
		position: 'fixed',
		bottom: 0
	},
	whiteGridKeyBoard: {
		backgroundColor: 'white',
		width: '100%',
		height: 100
	},
})

const validate = (values, { actionType }) => {
	const errors = {}
	const requiredFields = [
		'recolteEntiere'
	]

	if (actionType === ACTION_TYPE.DEMARRAGE_IMPOSSIBLE) {
		requiredFields.push('commentaire')
	}
	requiredFields.forEach(field => {
		if ((!values[field]) || (values[field] && (values[field].length === 0 || values[field].trim() === ''))) {
			errors[field] = { id: 'gestionTaches.ecranAffectations.panels.validate.champVide' }
		}
	})

	const fieldsQuantite = [
		'quantiteCalibre',
		'quantiteHorsCalibre',
		'quantiteNonCommercialisable'
	]

	const everyFieldAt0OrNull = fieldsQuantite.every((field) => ((!values[field]) || (values[field] && (values[field] === '0' || values[field] <= 0))))
	const oneNegative = fieldsQuantite.some((field) => (values[field] < 0))

	if (everyFieldAt0OrNull || oneNegative) {
		fieldsQuantite.forEach(field => {
			errors[field] = { id: 'mobile.error.unDesTroisChampRequired' }
		})
	}
	return errors
}

const MobileActionView = props => {
	const { classes, handleSubmit, change, commentaireValue, actionType, tache } = props
	const { setTache, setTachesNonTerminees, setTachesTerminees, setActionType, start, setStart, initialScreenSize } = useMobileContext()
	const { snackError } = useSnackbar()
	const [recording, setRecording] = React.useState(false)
	const { transcript, resetTranscript } = useSpeechRecognition()
	const [commentaire, setCommentaire] = React.useState('')
	const [keyboardOpen, setKeyboardOpen] = React.useState(false)

	React.useEffect(() => {
		window.addEventListener('resize', () => setKeyboardOpen(window.innerHeight !== initialScreenSize));
		return () => window.removeEventListener('resize', () => setKeyboardOpen(window.innerHeight !== initialScreenSize))
	}, [])

	const onSubmit = (values) => {
		const data = {
			commentaire: values.commentaire || null,
			recolteEntiere: values.recolteEntiere || null,
			quantiteCalibre: Math.trunc(values.quantiteCalibre * 100) / 100 || null,
			quantiteHorsCalibre: Math.trunc(values.quantiteHorsCalibre * 100) / 100 || null,
			quantiteNonCommercialisable: Math.trunc(values.quantiteNonCommercialisable * 100) / 100 || null,
			tempsPasse: start ? moment().diff(start, 'minutes') : null
		}

		let promise
		if (actionType === ACTION_TYPE.DEMARRAGE_IMPOSSIBLE) {
			promise = demarrageImpossible(data, tache.id)
		} else {
			promise = finDeTache(data, tache.id)
		}

		promise.then((res) => {
				setActionType(ACTION_TYPE.NONE)
				setTachesNonTerminees(res.tachesNonTerminees)
				setTachesTerminees(res.tachesTerminees)
				setTache(res.premiereTacheARealiser)
				setStart(null)
			})
			.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
	}

	const startRecording = () => {
		resetTranscript()
		setCommentaire(commentaireValue || '')
		SpeechRecognition.startListening({
			continuous: true
		})
		setRecording(true)
	}

	const stopRecording = () => {
		SpeechRecognition.stopListening()
		setCommentaire(`${commentaire} ${transcript}`)
		setRecording(false)
	}

	const changeValueCom = React.useCallback(() => {
		change('commentaire', `${commentaire} ${transcript}`)
	}, [transcript])

	React.useEffect(() => {
		changeValueCom(transcript)
	}, [changeValueCom, transcript])

	React.useEffect(() => {
		return () => stopRecording()
	}, [])

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className={keyboardOpen ? classes.formKeyboard : classes.form}>
			<Grid className={classes.formFields}>

				{actionType === ACTION_TYPE.TERMINEE
					? <Grid className={classes.title}><FormattedMessage id="mobile.title.finDeTache" /></Grid>
					: <Grid className={classes.title}><FormattedMessage id="mobile.title.demarrageImpossible" /></Grid>
				}

				{/* Première tâche à réaliser */}
				{getInformationsTache(classes, tache, false)}

				{/* Inputs & Select */}
				{((actionType === ACTION_TYPE.TERMINEE) && (tache.type === TYPE_TACHE.RECOLTE)) &&
				<Grid className={classes.margin10}>
					<Field
						name="recolteEntiere"
						component={Select}
						label={<FormattedMessage id="mobile.fields.cultureRecoltee" />}
						containerClassName={classes.inputs}
					>
						{
							Object.values(OUI_NON).map(type => (
								<MenuItem key={type.value} value={type.value} classes={{ selected: classes.menuItem }}>
									{type.label}
								</MenuItem>
							))
						}
					</Field>
				</Grid>
				}

				{/* Si Tâche "TRI" → 3 champs de quantité */}
				{((actionType === ACTION_TYPE.TERMINEE) && (tache.type === TYPE_TACHE.TRI)) &&
				<Grid className={classes.margin10}>
					<Field
						label={<FormattedMessage id="mobile.fields.quantiteCalibre" />}
						name="quantiteCalibre"
						component={Input}
						type="number"
						containerClassName={classes.inputs}
					/>
					<Field
						label={<FormattedMessage id="mobile.fields.quantiteHorsCalibre" />}
						name="quantiteHorsCalibre"
						component={Input}
						type="number"
						containerClassName={classes.inputs}
					/>
					<Field
						label={<FormattedMessage id="mobile.fields.quantiteNonCommercialisable" />}
						name="quantiteNonCommercialisable"
						component={Input}
						type="number"
						containerClassName={classes.inputs}
					/>
				</Grid>
				}

				{/* Commentaire */}
				{/* Required si DEMARRAGE_IMPOSSIBLE et optionnel si TERMINE*/}
				<Grid className={classes.margin10}>
					<Field
						name="commentaire"
						component={Input}
						multiline
						label={actionType === ACTION_TYPE.TERMINEE
							? <FormattedMessage id="mobile.buttons.placeholderCommentTermine" />
							: <FormattedMessage id="mobile.buttons.placeholderCommentImpossible" />
						}
						containerClassName={classes.inputs}
					/>
				</Grid>

				<Grid className={classes.centeredGrid}>
					{recording
						? <Button type="secondary" className={classes.commentaire} startIcon={<GraphicEqIcon color="currentColor" style={{ width: 36, height: 36 }} />} onClick={stopRecording}>
							<Grid className={classes.insideTextButtonCommentaire}><FormattedMessage id="mobile.buttons.finSaisieCommentaire" /></Grid>
						</Button>
						: <Button type="secondary" className={classes.commentaire} startIcon={<MicIcon color="currentColor" style={{ width: 36, height: 36 }} />} onClick={startRecording}>
							<Grid className={classes.insideTextButtonCommentaire}><FormattedMessage id="mobile.buttons.saisieCommentaire" /></Grid>
						</Button>
					}
				</Grid>

				{/*Boutons formulaire*/}
				<Grid className={keyboardOpen ? classes.whiteGridKeyBoard : classes.whiteGrid}>
					<Grid className={keyboardOpen ? classes.boutonGridKeyboard : classes.boutonGrid} container direction="row" justify="space-between">
						<Button
							className={classes.bigButtonCancel}
							type="secondary"
							onClick={() => {
								setActionType(ACTION_TYPE.NONE)
							}}
						>
							<Grid className={classes.insideTextButton}>
								<FormattedMessage id="actions.cancel" />
							</Grid>
						</Button>

						<FormButton className={classes.bigButtonValidate}
						            type="primary"
						            formName={DEMARRAGE_IMPOSSIBLE_OU_TERMINE}
						            getDisabledFromState={state => isInvalid(DEMARRAGE_IMPOSSIBLE_OU_TERMINE)(state)}
						>
							<Grid className={classes.insideTextButton}>
								<FormattedMessage id="actions.validate" />
							</Grid>
						</FormButton>
					</Grid>
				</Grid>

			</Grid>
		</Form>
	)
}

MobileActionView.propType = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	commentaireValue: PropTypes.string,
	actionType: PropTypes.string,
	tache: PropTypes.object
}

const selector = formValueSelector(DEMARRAGE_IMPOSSIBLE_OU_TERMINE)

const mapStateToProps = (state, { tache, actionType }) => {
	let retour = {
		commentaireValue: selector(state, 'commentaire')
	}

	/* Rempli les champs "Quantites" uniquement s'ils sont présents */
	if (actionType === ACTION_TYPE.TERMINEE && tache.type === TYPE_TACHE.TRI) {
		retour.initialValues = {
			quantiteCalibre: tache.quantites.quantiteCalibree || 0,
			quantiteHorsCalibre: tache.quantites.quantiteHorsCalibre || 0,
			quantiteNonCommercialisable: tache.quantites.quantiteNonCommercialisable || 0
		}
	}
	return retour
}

const actions = {
	change
}
export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: DEMARRAGE_IMPOSSIBLE_OU_TERMINE,
		validate
	})
)(MobileActionView)
