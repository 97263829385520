import * as api from './api'
import * as selectors from './selectors'

export const GET_AFFECTATIONS_REQUEST = 'AFFECTATIONS/GET_AFFECTATIONS_REQUEST'
export const GET_AFFECTATIONS_SUCCESS = 'AFFECTATIONS/GET_AFFECTATIONS_SUCCESS'
export const GET_AFFECTATIONS_ERROR = 'AFFECTATIONS/GET_AFFECTATIONS_ERROR'
export const getAffectations = (jour, page, size, filtersFormattees = {}) => dispatch => {
	dispatch({ type: GET_AFFECTATIONS_REQUEST })
	return api.getAffectations(jour, page, size, filtersFormattees)
		.then(ressources => {
			dispatch({
				type: GET_AFFECTATIONS_SUCCESS,
				ressources
			})
			return ressources
		})
		.catch(e => {
			dispatch({ type: GET_AFFECTATIONS_ERROR, e })
			throw e
		})
}

export const AFFECTATIONS_RESET = 'AFFECTATIONS/RESET'
export const resetAffectationsOnglet = () => dispatch => dispatch({
	type: AFFECTATIONS_RESET
})

export const AFFECTATIONS_SET_DATA = 'AFFECTATIONS/SET_DATA'
export const setAffectationsData = (data) => dispatch => dispatch({
	type: AFFECTATIONS_SET_DATA,
	payload: data
})

export const AFFECTATIONS_SET_SELECTED_ROWS = 'AFFECTATION/SET_SELECTED_ROWS'
export const setAffectationsSelectedRows = (rows) => dispatch => dispatch({
	type: AFFECTATIONS_SET_SELECTED_ROWS,
	payload: rows
})

export const AFFECTATIONS_REFRESH_TABLE = 'AFFECTATIONS/REFRESH_TABLE'
export const refreshAffectationsTable = () => dispatch => dispatch({
	type: AFFECTATIONS_REFRESH_TABLE
})

export const GET_TACHES_ORDONNANCEMENT_REQUEST = 'ORDONNANCEMENT/GET_TACHES_REQUEST'
export const GET_TACHES_ORDONNANCEMENT_SUCCESS = 'ORDONNANCEMENT/GET_TACHES_SUCCESS'
export const GET_TACHES_ORDONNANCEMENT_ERROR = 'ORDONNANCEMENT/GET_TACHES_ERROR'
export const getTachesOrdonnancement = (jour, filtersFormattees = {}) => dispatch => {
	dispatch({ type: GET_TACHES_ORDONNANCEMENT_REQUEST })
	return api.getOrdonnancement(jour, filtersFormattees)
		.then(taches => {
			dispatch({
				type: GET_TACHES_ORDONNANCEMENT_SUCCESS,
				taches
			})
			return taches
		})
		.catch(e => {
			dispatch({ type: GET_TACHES_ORDONNANCEMENT_ERROR, e })
			throw e
		})
}

export const ORDONNANCEMENT_UPDATE_ORDER_REQUEST = 'ORDONNANCEMENT/UPDATE_ORDER_REQUEST'
export const ORDONNANCEMENT_UPDATE_ORDER_SUCCESS = 'ORDONNANCEMENT/UPDATE_ORDER_SUCCESS'
export const ORDONNANCEMENT_UPDATE_ORDER_ERROR = 'ORDONNANCEMENT/UPDATE_ORDER_ERROR'
export const ordonnancementUpdateOrder = (selected, startIndex, endIndex, jour) => (dispatch, getState) => {
	const dropId = selectors.getOrdonnancementTacheAtIndex(endIndex)(getState())
	const originalOrder = selectors.getOrdonnancementsTachesIds(getState())
	dispatch({
		type: ORDONNANCEMENT_UPDATE_ORDER_REQUEST,
		payload: { selected, startIndex, endIndex }
	})
	return api.updateOrder(selected, dropId, jour)
		.then(() => dispatch({
			type: ORDONNANCEMENT_UPDATE_ORDER_SUCCESS
		}))
		.catch((e) => {
			dispatch({
				type: ORDONNANCEMENT_UPDATE_ORDER_ERROR,
				payload: { originalOrder }
			})
			throw e
		})
}

export const SET_FILTER_PLANIFICATION_OPEN = 'SET_FILTER_PLANIFICATION_OPEN'
export const SET_FILTER_PLANIFICATION_CLOSING = 'SET_FILTER_PLANIFICATION_CLOSING'
export const SET_FILTER_PLANIFICATION_CLOSE = 'SET_FILTER_PLANIFICATION_CLOSE'

export const setFilterPlanificationOpen = value => dispatch => {
	if (value) {
		return dispatch({ type: SET_FILTER_PLANIFICATION_OPEN })
	}
	dispatch({ type: SET_FILTER_PLANIFICATION_CLOSING })
	setTimeout(() => dispatch({ type: SET_FILTER_PLANIFICATION_CLOSE }), 200)
}

export const SET_FILTER_PLANIFICATION_USED = 'SET_FILTER_USED'

export const setFilterPlanificationUsed = value => dispatch => dispatch({
	type: SET_FILTER_PLANIFICATION_USED,
	value: value
})

export const RESET_FILTER_PLANIFICATION = 'RESET_FILTER_PLANIFICATION'
export const resetFilter = value => dispatch => dispatch({
	type: RESET_FILTER_PLANIFICATION
})

export const SAVE_CURRENT_FILTER_PLANIFICATION = 'SAVE_CURRENT_FILTER_PLANIFICATION'

export const saveCurrentFilterPlanification = values => dispatch => dispatch({
	type: SAVE_CURRENT_FILTER_PLANIFICATION,
	values: values
})