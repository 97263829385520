import { ETAT_TACHE_LIBELLE, ETAT_TACHE_UPPERCASE } from '../../../../utils/constants'

export const JOURS = {
	LUNDI: 'lundi',
	MARDI: 'mardi',
	MERCREDI: 'mercredi',
	JEUDI: 'jeudi',
	VENDREDI: 'vendredi',
	SAMEDI: 'samedi',
	DIMANCHE: 'dimanche',
}

export const JOURS_CAPS = {
	LUNDI: 'LUNDI',
	MARDI: 'MARDI',
	MERCREDI: 'MERCREDI',
	JEUDI: 'JEUDI',
	VENDREDI: 'VENDREDI',
	SAMEDI: 'SAMEDI',
	DIMANCHE: 'DIMANCHE'
}

export const NUMERO_JOURS_SEMAINE = {
	LUNDI: 0,
	MARDI: 1,
	MERCREDI: 2,
	JEUDI: 3,
	VENDREDI: 4,
	SAMEDI: 5,
	DIMANCHE: 6,
}

export const PLANIFICATION_TABLE_NAME = 'PlanificationTable'

export const PANEL_TYPE = {
	TACHE_DETAIL: 'TACHE_DETAIL',
	SUIVI_DE_PRODUCTION: 'SUIVI_DE_PRODUCTION'
}

export const ETAT_SEMAINE = {
	BROUILLON: 'BROUILLON',
	VALIDE: 'VALIDE',
	REVISE: 'REVISE'
}
export const FILTER_FORM = 'FILTER_FORM'

export const STATUT_TACHE_LIST = [
	{ label: ETAT_TACHE_LIBELLE.A_PLANIFIER, code: ETAT_TACHE_UPPERCASE.A_PLANIFIER },
	{ label: ETAT_TACHE_LIBELLE.A_REALISER, code: ETAT_TACHE_UPPERCASE.A_REALISER },
	{ label: ETAT_TACHE_LIBELLE.EN_ATTENTE, code: ETAT_TACHE_UPPERCASE.EN_ATTENTE },
	{ label: ETAT_TACHE_LIBELLE.EN_COURS, code: ETAT_TACHE_UPPERCASE.EN_COURS },
	{ label: ETAT_TACHE_LIBELLE.TERMINEE, code: ETAT_TACHE_UPPERCASE.TERMINEE }
]

export const TOUTE_LA_FERME = {
	FERME : 'Toute la ferme'
}

export const PERIOD_SWITCH = [
	{
		id: 'weeks',
		label: 'gestionTaches.toolbar.popin.switch.week'
	},
	{
		id: 'date',
		label: 'gestionTaches.toolbar.popin.switch.date'
	}
]

