import React from 'react'
import PropTypes from 'prop-types'
import HeaderAffectation from './affectation/HeaderAffectation'
import AffectationTable from './affectation/AffectationTable'
import {ScrollSync} from 'react-scroll-sync'

const AffectationOnglet = () => {
	const reference = React.createRef()

	return (
		<ScrollSync>
			<>
				<HeaderAffectation reference={reference} />
				<AffectationTable reference={reference} />
			</>
		</ScrollSync>
	)
}

AffectationOnglet.propTypes = {
	classes: PropTypes.object
}

export default AffectationOnglet
