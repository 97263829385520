import { reducers as reducersIsotope } from 'isotope-client'
import { snackPermasoft } from '../components/layout/snackbar/service/reducer'
import { ferme } from '../modules/bo/ferme/services/fermeReducers'
import currentCampagne from '../modules/common/campagne/campagneReducer'
import { configuration } from '../modules/common/configuration/configurationReducer'
import notifications from '../modules/common/notification/notificationReducer'
import gestionTaches from '../modules/fo/gestionTache/services/reducer'
import taches from '../modules/fo/gestionTache/services/tache/tacheReducer'
import loginReducerFactory from '../modules/fo/login/services/loginReducers'
import assolements from '../modules/fo/planning/services/assolement/assolementReducer'
import planningToolbar from '../modules/fo/planning/services/toolbar/toolbarReducer'

export default {
	...reducersIsotope,
	user: loginReducerFactory(window.localStorage),
	configuration,
	assolements,
	planningToolbar,
	currentCampagne,
	gestionTaches,
	taches,
	ferme,
	snackPermasoft,
	notifications
}
