import React from 'react'
import { MenuItem, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import Input from '../../../../components/form/Input'
import Select from '../../../../components/form/Select'
import Autocomplete from '../../../../components/form/Autocomplete'
import { colors, EMPTY_TASK, PARAMETRES_TACHE, TYPE_PERIODE_RECURRENCE } from '../../../../utils/constants'
import { injectFamilleTacheValueList, injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'
import CheckboxGroup from '../../../../components/form/CheckboxGroup'
import { NUMERO_JOURS_SEMAINE } from '../utils/constants'
import { getAllPlanchesWithoutId, getModelesTaches, getModelesTacheTypes } from '../services/api'
import { getAssolements, getFamillesTacheByIdFerme } from '../gestionTachesApi'
import { formatPositiveInteger, getLibelleJourFromNumero, getMenuItemFromLabel, normalizeDate } from '../../../../utils/utils'
import moment from 'moment'
import { LocalDate } from '../../../../utils/date/local-date'
import { DateFormat } from '../../../../utils/date/model/date'
import AutocompleteMulti from '../../../../components/form/AutocompleteMulti'
import Typography from '@material-ui/core/Typography'
import { ArrowRight } from '@material-ui/icons'
import FormattedMessageWithBold from "../../../../components/FormattedMessageWithBold";
import DateFieldCustom from "../../../../components/form/DateFieldCustom";
import IconButton from "../../../../components/IconButton";
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import InfoIcon from '@material-ui/icons/Info'
import { displaySensibilite } from "../utils/tacheUtils";
import { getAllBlocs } from "../../../bo/modeleTache/modeleTacheApi";
import { getPreferences } from "../../../common/user/services/userSelectors";

const fields = {
	FAMILLE: { name: 'famille' },
	TYPE: { name: 'type' },
	TYPE_LABEL: { name: 'typeLabel' },
	SURFACE: { name: 'surface' },
	SURFACE_LABEL: { name: 'surfaceLabel' },
	PRIORITE: { name: 'priorite' },
	ASSOLEMENT: { name: 'assolement' },
	ASSOLEMENT_LABEL: { name: 'assolementLabel' },
	PARAMETRES: { name: 'parametres' },
	PERIODE_NOMBRE: { name: 'periodeNombre' },
	PERIODE_TYPE: { name: 'periodeType' },
	JOURS: { name: 'jours' },
	DEBUT: { name: 'debut' },
	FIN: { name: 'fin' },
	MODELE: { name: 'modele' },
	DATE: { name: 'date' },
	LOCK_DATE: { name: 'lockDate' }
}

const styles = () => getStyles({
	form: {
		width: '100%',
		margin: 15
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	twoFieldsRow: {
		marginTop: 22
	},
	leftField: {
		float: 'left',
		width: '45%',
		'& .MuiFilledInput-input': {
			height: '1.14em'
		}
	},
	rightField: {
		float: 'right',
		width: '45%'
	},
	checkbox: {
		marginLeft: 8,
		fontSize: 14
	},
	emptyLabel: {
		color: `${colors.empty} !important`
	},
	iconRotate: {
		fontSize: '2rem !important',
		transform: 'rotate(90deg)',
		transition: 'ease-in-out 0.1s',
		cursor: 'pointer'
	},
	icon: {
		transform: 'rotate(0)'
	},
	detail: {
		paddingLeft: 20,
		gap: 10,
		'& p': {
			fontSize: '12px !important'
		},
		'& ul': {
			marginTop: 6,
			marginBottom: 6
		}
	},
	title: {
		color: colors.primary,
		fontSize: '1em',
		cursor: 'pointer'
	},
	toggle: {
		color: colors.primary,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 6,
		marginTop: 12,
		marginLeft: '-8px',
		width: '100%'
	},
	lockIcon: {
		color: `${colors.primary} !important`,
		border: '1px solid #804180',
		padding: 6,

		'&:hover': {
			color: `white !important`,
			backgroundColor: colors.primary
		}
	},
	hidden: {
		display: 'none',
		padding: 0,
		margin: 0
	},
	optionDetail: {
		color: colors.placeholder,
		marginLeft: 10,
		fontSize: '0.85em',
		marginRight: 10,
		paddingRight: 25
	},
	moTacheLabel: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%'
	}
})

const requiredFields = [
	fields.FAMILLE,
	fields.TYPE,
	fields.PRIORITE,
	fields.ASSOLEMENT,
	fields.MODELE
]

const recurrenceRequiredFields = [
	fields.PERIODE_NOMBRE,
	fields.PERIODE_TYPE,
	fields.DEBUT,
	fields.FIN
]

const formattedMessageBase = 'gestionTaches.ecranPlanification.ajout.form'
export const formName = 'CreerTacheForm'

const validate = (values, { parametreValues, periodeTypeValue, semaineSelection }) => {
	const errors = {}
	requiredFields.forEach(field => {
		if (!values[field.name] || values[field.name].length === 0) {
			errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	})
	if (displayRecurrenceFields(parametreValues)) {
		recurrenceRequiredFields.forEach(field => {
			if (!values[field.name] || values[field.name].length === 0) {
				errors[field.name] = { id: `${formattedMessageBase}.errors.mandatory` }
			}
		})
		if (displayJourField(periodeTypeValue)) {
			if (!values[fields.JOURS.name] || values[fields.JOURS.name].length === 0) {
				errors[fields.JOURS.name] = { id: `${formattedMessageBase}.errors.mandatory` }
			}
		}
		if (moment(values[fields.FIN.name]).isBefore(moment(values[fields.DEBUT.name]))) {
			errors[fields.FIN.name] = { id: `${formattedMessageBase}.errors.dateBefore` }
		}
		if (moment(values[fields.DEBUT.name]).isBefore(moment(semaineSelection))) {
			errors[fields.DEBUT.name] = { id: `${formattedMessageBase}.errors.dateNotInWeek` }
		}
		if (moment(values[fields.DEBUT.name]).isAfter(moment(semaineSelection).add(6, 'days'))) {
			errors[fields.DEBUT.name] = { id: `${formattedMessageBase}.errors.dateNotInWeek` }
		}
	} else {
		if (!values[fields.DATE.name]) {
			errors[fields.DATE.name] = { id: `${formattedMessageBase}.errors.mandatory` }
		}
	}
	return errors
}

const displayRecurrenceFields = (parametreValues) => {
	return parametreValues && parametreValues.includes(PARAMETRES_TACHE.RECURRENTE)
}

const displayJourField = (periodeTypeValue) => {
	return periodeTypeValue === TYPE_PERIODE_RECURRENCE.SEMAINES.value
}

const getOptions = (planches, blocs) => [{ label: EMPTY_TASK.SURFACE_VIDE, code: '0' }]
	.concat(blocs.map(bloc => ({ label: bloc.nom, code: bloc.id })))
	.concat(planches.map(planche => ({ label: planche.nom, code: planche.id })));

export const getDureeCalculee = (modele, planches, idLieuValue) => {
	// on utilise soit le temps moyen au M2 soit le temps moyen fixe
	const temps= modele.tempsMoyM2 ? modele.tempsMoyM2 : modele.tempsMoyFixe
	// par défaut la surface est égale à 1(m²) (string car on fait un parseFloat plus loin)
	let surface = '1'
	// si le lieu correspond à une planche, alors on récupère la surface de la planche (en string, d'où le parseFloat)
	if (planches.find(planche => planche.id === idLieuValue) != null) {
		surface = planches.find(planche => planche.id === idLieuValue).surface
	}
	const time = Math.round(Number.parseFloat(temps) * Number.parseFloat(surface))
	const hours = Math.floor(time / 60)
	const minutes = time % 60
	return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
}

const CreerTache = ({
						handleSubmit,
						classes,
						familleTacheList,
						typeTacheList,
						change,
						familleValue,
						typeValue,
						surfaceValue,
						assolementValue,
						parametreValues,
						periodeTypeValue,
						semaineSelection,
						lockDateValue,
						userPreferences
					}) => {
	const [modele, setModele] = React.useState(undefined)
	const [modeleList, setModeleList] = React.useState(undefined)
	const [loading, setLoading] = React.useState(true)
	const [types, setTypes] = React.useState([])
	const [planches, setPlanches] = React.useState([])
	const [blocs, setBlocs] = React.useState([])
	const [assolements, setAssolements] = React.useState([])
	const [familles, setFamilles] = React.useState([])
	const [open, setOpen] = React.useState(true)
	const intl = useIntl()

	React.useEffect(() => {
		if (familleValue) {
			getModelesTacheTypes(familleValue).then(setTypes)
		}
	}, [familleValue])

	React.useEffect(() => {
		if (surfaceValue) {
			getAssolements(surfaceValue, LocalDate.fromString(semaineSelection).format(DateFormat.SHORT_DATE_WITH_DASH)).then(setAssolements)
		}
		change(fields.ASSOLEMENT.name, undefined)
	}, [surfaceValue])

	React.useEffect(() => {
		if (familleValue && typeValue && assolementValue !== undefined && surfaceValue !== undefined) {
			getModelesTaches(familleValue, typeValue, assolementValue, surfaceValue)
				.then(setModeleList)
				.finally(() => setLoading(false))
		}
	}, [familleValue, typeValue, assolementValue])

	const getJoursOptions = () => Promise.resolve(
		Object.values(NUMERO_JOURS_SEMAINE).map(jour => (
			{ code: jour, label: getLibelleJourFromNumero(jour) }
		))
	)

	const handleClick = () => setOpen(previousState => !previousState)

	React.useEffect(() => {
		getAllPlanchesWithoutId().then(setPlanches)
		getAllBlocs(userPreferences.FERME_ID).then(setBlocs)
		getFamillesTacheByIdFerme().then(setFamilles)
	}, [])

	const hasCategorie = (categorie) => modele[categorie] && modele[categorie].length > 0
	const hasMateriels = () => hasCategorie('materiels')
	const hasSensibilites = () => hasCategorie('sensibilites')
	const hasCompetences = () => hasCategorie('competences')

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid>
				<Field
					name={fields.FAMILLE.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.FAMILLE.name}`}/>}
					onChange={() => change(fields.TYPE.name, '')}
				>
					{
						familles.map(famille => getMenuItemFromLabel(familleTacheList, famille))
					}
				</Field>
			</Grid>
			{
				familleValue &&
				<Grid>
					<Field
						name={fields.TYPE.name}
						component={Select}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.TYPE.name}`}/>}
						onChange={(_, value) => change(fields.TYPE_LABEL.name, typeTacheList.find(typeTache => typeTache.code === value).label)}
					>
						{
							typeTacheList.filter(type => types.includes(type.code)).map(type =>
								<MenuItem key={type.code} value={type.code}>
									{type.label}
								</MenuItem>
							)
						}
					</Field>
					<Field
						name={fields.TYPE_LABEL.name}
						component={Input}
						containerClassName={classes.hidden}
					/>
				</Grid>
			}
			<Grid>
				<Field
					name={fields.SURFACE.name}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.SURFACE.name}`}/>}
					options={getOptions(planches, blocs)}
					onChange={(_, value) => {
						change(fields.ASSOLEMENT.name, '')
						value !== '0' && change(fields.SURFACE_LABEL.name, planches?.find(planche => planche?.id === value)?.nom)
					}}
				/>
				<Field
					name={fields.SURFACE_LABEL.name}
					component={Input}
					containerClassName={classes.hidden}
				/>
			</Grid>
			<Grid>
				<Field
					name={fields.ASSOLEMENT.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.ASSOLEMENT.name}`}/>}
					onChange={(_, value) => {
						change(fields.ASSOLEMENT_LABEL.name, value !== '0' && assolements?.find(assolement => assolement?.id === value)?.culture?.nom)
						change(fields.MODELE.name, '')
					}}
				>
					{
						[
							<MenuItem key={'0'} value={'0'}>
								{EMPTY_TASK.CULTURE_VIDE}
							</MenuItem>,
							...assolements?.map(assolement => (
								<MenuItem key={assolement.id} value={assolement.id}>
									{assolement.culture.nom}
								</MenuItem>
							)) || [
								<MenuItem key={'0'} value={'0'}>
									{EMPTY_TASK.CULTURE_VIDE}
								</MenuItem>
							]
						]
					}
				</Field>
				<Field
					name={fields.ASSOLEMENT_LABEL.name}
					component={Input}
					containerClassName={classes.hidden}
				/>
			</Grid>
			<Grid>
				{!loading && assolementValue !== undefined && surfaceValue !== undefined && <Field
					name={fields.MODELE.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.modele.title`}/>}
					onChange={(_, value) => {
						const modeleChoice = modeleList.find(modeles => modeles.id === value)
						setModele(modeleChoice)
						change(fields.MODELE.name, modeleChoice)
						// on passe en toString() sinon la valeur est considéré comme vide par le validate
						change(fields.PRIORITE.name, modeleChoice?.priorite.toString())
					}}
				>
					{modeleList?.map(modele => (
						<MenuItem key={modele.id} value={modele.id}>
							<div className={classes.moTacheLabel}>
								<span>{modele.itk ? modele.itk.designation : modele.codeTache} <i> <br/>{modele.categorieTechnique.nom}</i></span>
								<span><FormattedMessage id={`${formattedMessageBase}.fields.modele.label.id`} values={{ id: modele.id }}/> </span>
							</div>
						</MenuItem>
					))}
				</Field>}
			</Grid>
			<Grid>
				<Field
					name={fields.PRIORITE.name}
					component={Input}
					type="number"
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.PRIORITE.name}`}/>}
					format={formatPositiveInteger}
					inputProps={{ min: 0 }}
				/>
			</Grid>
			<Grid className={classes.checkbox}>
				<Field
					name={fields.PARAMETRES.name}
					component={CheckboxGroup}
					items={Object.values(PARAMETRES_TACHE).map(param => ({
						value: param,
						label: <FormattedMessage id={`${formattedMessageBase}.fields.${fields.PARAMETRES.name}.${param}`}/>
					}))}
					row
					fromTache
				/>
			</Grid>
			{
				displayRecurrenceFields(parametreValues) ?
					<>
						<Grid className={classes.twoFieldsRow}>
							<Field
								name={fields.PERIODE_NOMBRE.name}
								component={Input}
								type="number"
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.PERIODE_NOMBRE.name}`}/>}
								containerClassName={classes.leftField}
							/>
							<Field
								name={fields.PERIODE_TYPE.name}
								component={Select}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.PERIODE_TYPE.name}`}/>}
								containerClassName={classes.rightField}
							>
								{
									Object.values(TYPE_PERIODE_RECURRENCE).map(type => (
										<MenuItem key={type.value} value={type.value} classes={{ selected: classes.menuItem }}>
											{type.label}
										</MenuItem>
									))
								}
							</Field>
						</Grid>
						{
							displayJourField(periodeTypeValue) &&
							<Field
								name={fields.JOURS.name}
								component={AutocompleteMulti}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.JOURS.name}`}/>}
								getOptions={getJoursOptions}
								forceGetOptions
								formName={formName}
							/>
						}
						<Grid container className={classes.twoFieldsRow}>
							<Field
								name={fields.DEBUT.name}
								type="text"
								component={DateFieldCustom}
								placeholder={intl.formatMessage({ id: `${formattedMessageBase}.fields.${fields.DEBUT.name}` })}
								format={normalizeDate}
								hideFootInfo
								containerClassName={classes.leftField}
								style={{ paddingLeft: 10, paddingRight: 12 }}
								textFieldStyle={{ width: '100%' }}
								sliderCalendar
							/>
							<Field
								name={fields.FIN.name}
								type="text"
								component={DateFieldCustom}
								placeholder={intl.formatMessage({ id: `${formattedMessageBase}.fields.${fields.FIN.name}` })}
								format={normalizeDate}
								hideFootInfo
								containerClassName={classes.rightField}
								style={{ paddingLeft: 10, paddingRight: 10 }}
								textFieldStyle={{ width: '100%' }}
								sliderCalendar
							/>
						</Grid>
					</> : <Grid container direction="column">
						<Grid container direction="row">
							<Grid item xs>
								<Field
									name={fields.DATE.name}
									type="text"
									component={DateFieldCustom}
									placeholder={intl.formatMessage({ id: `${formattedMessageBase}.fields.${fields.DATE.name}` })}
									format={normalizeDate}
									hideFootInfo
									textFieldStyle={{ width: '100%' }}
									style={{ paddingLeft: 10, paddingRight: 10 }}
									sliderCalendar
								/>
							</Grid>
							{modele && modele.isTacheGenerique && !modele.idTacheGenerique && <Grid item xs style={{ paddingLeft: 10, paddingTop: 10, maxWidth: 60 }}>
								<IconButton onClick={() => change(fields.LOCK_DATE.name, !lockDateValue)} className={classes.lockIcon}>
									{lockDateValue ? <LockIcon/> : <LockOpenIcon/>}
								</IconButton>
							</Grid>}
							<Field
								name={fields.LOCK_DATE.name}
								component={Input}
								containerClassName={classes.hidden}
							/>
						</Grid>
						{lockDateValue && <Typography style={{ paddingLeft: 10, fontSize: 10, fontStyle: 'italic', fontWeight: 700 }}>
							<Grid container alignItems="center" style={{ gap: 8 }}>
								<InfoIcon color='primary'/>
								<FormattedMessage id={`${formattedMessageBase}.fields.${fields.LOCK_DATE.name}`}/>
							</Grid>
						</Typography>}
					</Grid>
			}
			{
				modele &&
				<div>
					<div onClick={handleClick} className={classes.toggle}>
						<ArrowRight className={`${classes.iconRotate} ${open ? '' : classes.icon}`}/>
						<Typography variant="body1" className={classes.title}><FormattedMessage id={`${formattedMessageBase}.detail.title`}/></Typography>
					</div>
					{open && <Grid container direction="column" className={classes.detail}>
						<Grid>
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.ressources`} values={{ nbPersonnes: modele.nbPersonnes, flagDivisible: modele.flagDivisible }}/>
							</Typography>
						</Grid>
						<Grid>
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.duree`} values={{ duree: surfaceValue !== '0' ? getDureeCalculee(modele, planches, surfaceValue) : ' ' }}/>
							</Typography>
						</Grid>
						{modele.categorieTechnique && hasMateriels() && hasSensibilites() && hasCompetences() && <Grid>
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.categorie`} values={{ categorie: `${modele.categorieTechnique.code} - ${modele.categorieTechnique.nom}` }}/>
							</Typography>
						</Grid>}
						{hasMateriels() && <Grid container item direction="column">
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.materiel`}/>
							</Typography>
							<ul>
								{modele.materiels.map(materiel => <li><Typography>{materiel.nom}</Typography></li>)}
							</ul>
						</Grid>}
						{hasSensibilites() && <Grid container item direction="column">
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.sensibilite`}/>
							</Typography>
							<ul>
								{modele.sensibilites.map(sensibilite => <li><Typography>{displaySensibilite(sensibilite, intl)}</Typography></li>)}
							</ul>
						</Grid>}
						{hasCompetences() && <Grid container item direction="column">
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.competences`}/>
							</Typography>
							<ul>
								{modele.competences.map(competence => <li><Typography>{competence}</Typography></li>)}
							</ul>
						</Grid>}
						{modele.description && <Grid>
							<Typography>
								<FormattedMessageWithBold id={`${formattedMessageBase}.detail.description`} values={{ description: modele.description }}/>
							</Typography>
						</Grid>}
					</Grid>}
				</div>
			}
		</form>
	)
}

CreerTache.propTypes = {
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	familleTacheList: PropTypes.array,
	typeTacheList: PropTypes.array,
	familleValue: PropTypes.string,
	typeValue: PropTypes.string,
	surfaceValue: PropTypes.string,
	assolementValue: PropTypes.string,
	parametreValues: PropTypes.array,
	periodeTypeValue: PropTypes.string,
	materielValueList: PropTypes.array,
	change: PropTypes.func,
	semaineSelection: PropTypes.string
}

const selector = formValueSelector(formName)
const mapStateToProps = state => ({
	userPreferences: getPreferences(state),
	familleValue: selector(state, fields.FAMILLE.name),
	typeValue: selector(state, fields.TYPE.name),
	surfaceValue: selector(state, fields.SURFACE.name),
	assolementValue: selector(state, fields.ASSOLEMENT.name),
	parametreValues: selector(state, fields.PARAMETRES.name),
	periodeTypeValue: selector(state, fields.PERIODE_TYPE.name),
	lockDateValue: selector(state, fields.LOCK_DATE.name),
	initialValues: {
		[fields.LOCK_DATE.name]: false
	}
})

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	injectFamilleTacheValueList,
	injectTypeTacheValueList,
	withStyles(styles),
	reduxForm({
		form: formName,
		destroyOnUnmount: false,
		enableReinitialize: true,
		validate
	})
)(CreerTache)
