import {
	GET_AFFECTATIONS_REQUEST,
	GET_AFFECTATIONS_SUCCESS,
	GET_AFFECTATIONS_ERROR,
	AFFECTATIONS_RESET,
	AFFECTATIONS_SET_DATA,
	AFFECTATIONS_SET_SELECTED_ROWS,
	AFFECTATIONS_REFRESH_TABLE,
	GET_TACHES_ORDONNANCEMENT_REQUEST,
	GET_TACHES_ORDONNANCEMENT_SUCCESS,
	GET_TACHES_ORDONNANCEMENT_ERROR,
	ORDONNANCEMENT_UPDATE_ORDER_ERROR,
	ORDONNANCEMENT_UPDATE_ORDER_REQUEST,
	SET_FILTER_PLANIFICATION_OPEN,
	SAVE_CURRENT_FILTER_PLANIFICATION,
	SET_FILTER_PLANIFICATION_CLOSE,
	SET_FILTER_PLANIFICATION_CLOSING,
	RESET_FILTER_PLANIFICATION,
	SET_FILTER_PLANIFICATION_USED
} from './actions'
import { combineReducers } from 'redux'
import { multiDragAwareReorder } from '../utils/dragUtils'

const initialAffectationLoading = true
const affectationDataLoading = (state = initialAffectationLoading, action) => {
	switch (action.type) {
		case GET_AFFECTATIONS_REQUEST:
			return true
		case GET_AFFECTATIONS_SUCCESS:
		case GET_AFFECTATIONS_ERROR:
			return false
		default:
			return state
	}
}

const initialAffectationsData = {
	page: 0,
	size: 50,
	total: 0,
	content: []
}
const affectationsData = (state = initialAffectationsData, action) => {
	switch (action.type) {
		case AFFECTATIONS_SET_DATA:
			return action.payload
		case AFFECTATIONS_RESET:
			return initialAffectationsData
		default:
			return state
	}
}

const affectationsShouldRefresh = (state = false, action) => {
	switch (action.type) {
		case AFFECTATIONS_SET_DATA:
		case AFFECTATIONS_RESET:
			return false
		case AFFECTATIONS_REFRESH_TABLE:
			return true
		default:
			return state
	}
}

const affectationsSelectedRows = (state = [], action) => {
	switch (action.type) {
		case AFFECTATIONS_SET_SELECTED_ROWS:
			return action.payload
		case AFFECTATIONS_SET_DATA:
		case AFFECTATIONS_RESET:
			return []
		default:
			return state
	}
}

const ordonnancementLoading = (state = false, action) => {
	switch (action.type) {
		case GET_TACHES_ORDONNANCEMENT_REQUEST:
			return true
		case GET_TACHES_ORDONNANCEMENT_SUCCESS:
		case GET_TACHES_ORDONNANCEMENT_ERROR:
			return false
		default:
			return state
	}
}

const ordonnancementTachesParId = (state = {}, action) => {
	switch (action.type) {
		case GET_TACHES_ORDONNANCEMENT_SUCCESS:
			return {
				...state,
				...action.taches.reduce((acc, tache) => ({
					...acc,
					[tache.id]: tache
				}), {})
			}
		default:
			return state
	}
}

const ordonnancementTachesIds = (state = [], action) => {
	switch (action.type) {
		case GET_TACHES_ORDONNANCEMENT_SUCCESS:
			return action.taches.sort((tache1, tache2) => tache1.ordre - tache2.ordre).map(tache => tache.id)
		case ORDONNANCEMENT_UPDATE_ORDER_REQUEST: {
			const { selected, startIndex, endIndex } = action.payload
			return multiDragAwareReorder(state, selected, startIndex, endIndex)
		}
		case ORDONNANCEMENT_UPDATE_ORDER_ERROR: {
			return action.payload.originalOrder
		}
		default:
			return state
	}
}

export const initialFilter = {}
const initialFilterState = {
	open: false,
	closing: false,
	used: false,
	currentFilter: initialFilter
}

const filter = (state = {}, action) => {
	switch (action.type) {
		case SET_FILTER_PLANIFICATION_OPEN:
			return {
				...state,
				open: !state.closing
			}
		case SAVE_CURRENT_FILTER_PLANIFICATION:
			return {
				...state,
				currentFilter: action.values
			}
		case SET_FILTER_PLANIFICATION_CLOSE:
			return {
				...state,
				closing: false
			}
		case SET_FILTER_PLANIFICATION_CLOSING:
			return {
				...state,
				open: false,
				closing: true
			}
		case SET_FILTER_PLANIFICATION_USED:
			return {
				...state,
				used: action.value
			}
		case RESET_FILTER_PLANIFICATION:
			return initialFilterState
		default:
			return state
	}
}

export default combineReducers({
	affectations: combineReducers({
		data: affectationsData,
		shouldRefresh: affectationsShouldRefresh,
		selectedRows: affectationsSelectedRows,
		loading: affectationDataLoading
	}),
	ordonnancement: combineReducers({
		parId: ordonnancementTachesParId,
		ids: ordonnancementTachesIds,
		loading: ordonnancementLoading
	}),
	filter: filter
})
