import { fetchFactory, urlUtils } from 'isotope-client'

/**
 * Récupération de la liste des categories techniques
 */
export const getCategories = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/categorie-technique', filters))

/**
 * Mise à jour d'une categorie
 */
export const updateCategorie = (values) => fetchFactory('/bo/categorie-technique', {
	method: 'PUT',
	body: JSON.stringify({
		...values
	})
})

/**
 * Création d'une categorie
 */
export const createCategorie = (values) => fetchFactory('/bo/categorie-technique', {
	method: 'POST',
	body: JSON.stringify({
		...values
	})
})