import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const ComparaisonIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height}>
			<path
				d="M446.606 335.394c-5.857-5.858-15.355-5.858-21.213 0L361 399.787l-23.611-23.612c-5.856-5.858-15.354-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213l34.218 34.218a14.995 14.995 0 0021.212.001l75-75c5.858-5.858 5.858-15.355 0-21.213zM136 0C61.354 0 0 61.379 0 136c0 74.439 61.009 135 136 135 74.439 0 135-60.561 135-135C271 61.009 210.439 0 136 0zm0 241c-58.449 0-106-47.103-106-105C30 77.551 77.551 30 136 30c57.897 0 105 47.551 105 106 0 57.897-47.103 105-105 105z"
				fill={color}
			/>
			<path
				d="M376 241c-74.439 0-135 60.561-135 135 0 74.991 60.561 136 135 136 74.509 0 136-61.226 136-136 0-74.439-61.01-135-136-135zm0 241c-57.897 0-105-47.552-105-106 0-57.897 47.103-105 105-105 58.448 0 106 47.103 106 105 0 58.448-47.552 106-106 106zM176.607 155.394L151 129.785V76.001c0-8.284-6.715-15-15-15.001-8.284 0-15 6.716-15 14.999v60c0 3.776 1.503 7.732 4.419 10.633l29.975 29.975c5.857 5.858 15.355 5.858 21.213 0 5.858-5.858 5.858-15.355 0-21.213zM211 451H106c-8.271 0-15-6.729-15-15v-83.787l4.394 4.394c5.857 5.857 15.355 5.858 21.213 0 5.858-5.858 5.858-15.355 0-21.213l-30-30c-5.857-5.858-15.355-5.858-21.213 0l-30 30c-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0L61 352.213V436c0 24.813 20.187 45 45 45h105c8.284 0 15-6.716 15-15s-6.716-15-15-15zM476.606 155.393c-5.857-5.858-15.355-5.858-21.213 0L451 159.787V76c0-24.813-20.187-45-45-45H301c-8.284 0-15 6.716-15 15s6.716 15 15 15h105c8.271 0 15 6.729 15 15v83.787l-4.394-4.394c-5.857-5.858-15.355-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213l30 30c5.857 5.857 15.355 5.858 21.213 0l30-30c5.858-5.858 5.858-15.355 0-21.213z"
				fill={color}
			/>
		</svg>
	)
}

ComparaisonIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

ComparaisonIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default ComparaisonIcon
