import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import classnames from 'classnames'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { joinNode, toI18N } from '../../utils/utils'
import mapError from './mapError'

const styles = () => getStyles({
	select: {
		width: '100%'
	},
	container: {
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 15
	},
	input: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
	},
	bo: {
		backgroundColor: '#f7f6f6 !important',
		'&:focus': {
			backgroundColor: '#f7f6f6 !important'
		}
	}
})

const Select = ({ input: { value, active, ...inputProps }, meta: { error, touched }, id, label, children, disabled, classes, multiple, containerClassName, selectClassName, fromBo }) => {
	// look into the children for the selected option and get it's label
	const getLabel = (value) => {
		const child = children && children.find(c => c.props.value === value)
		return child && child.props.children
	}

	return (<div className={classnames(classes.container, containerClassName)}>
		<FormControl variant="filled" className={classes.select} error={touched && !!error}>
			{!!label && <InputLabel disabled={disabled} htmlFor={id}>{label}</InputLabel>}
			<MuiSelect
				value={multiple ? value || [] : value}
				disableUnderline={disabled}
				disabled={disabled}
				input={<FilledInput
					{...inputProps}
					className={classes.input}
					classes={{ input: fromBo ? classes.bo : selectClassName }}
				/>}
				id={id}
				variant="filled"
				multiple={multiple}
				renderValue={value => {
					if(!value){
						return ""
					}
					if (!multiple) {
						return getLabel(value)
					}
					return joinNode(value.map(getLabel), ', ')
				}}

				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left'
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left'
					},
					getContentAnchorEl: null
				}}
			>
				{children}
			</MuiSelect>
			{typeof error !== 'undefined' && touched && <FormHelperText>{toI18N(error)}</FormHelperText>}
		</FormControl>
	</div>)
}

Select.propTypes = {
	input: PropTypes.object,
	meta: PropTypes.object,
	classes: PropTypes.object,
	containerClassName: PropTypes.string,
	selectClassName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	id: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool
}

Select.defaultProps = {
	containerClassName: '',
	selectClassName: ''
}

export default compose(
	mapError,
	withStyles(styles)
)(Select)

