import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'
import IconButton from '@material-ui/core/IconButton'
import { default as SnackbarUI } from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import withStyles from '@material-ui/core/styles/withStyles'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Close from '@material-ui/icons/Close'
import Error from '@material-ui/icons/Error'
import Info from '@material-ui/icons/Info'
import Warning from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from 'isotope-client'
import messageShape from 'isotope-client/components/i18n/messageShape'
import toI18N from 'isotope-client/components/i18n/toI18N'
import { connect } from 'react-redux'

const styles = (theme) => getStyles({
	root: {
		color: theme.palette.common.white
	},
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	info: {
		backgroundColor: theme.palette.primary.dark
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 480
	},
	button: {
		color: theme.palette.common.white
	}
})

export const DEFAULT_SNACK_DURATION = 10000 // 10 secondes

export const SnackbarType = {
	SUCCESS: 'success',
	ERROR: 'error',
	INFO: 'info',
	WARNING: 'warning'
}

const variantIcon = {
	[SnackbarType.SUCCESS]: CheckCircle,
	[SnackbarType.WARNING]: Warning,
	[SnackbarType.ERROR]: Error,
	[SnackbarType.INFO]: Info
}

export const Snackbar = withStyles(styles, { name: 'snackbar' })(({ visible, hideSnack, delay, message, type, actions, classes, className }) => {
	const Icon = variantIcon[type]

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		hideSnack()
	}

	return (
		<SnackbarUI
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			onClose={handleClose}
			open={visible}
			autoHideDuration={delay}
			disableWindowBlurListener
		>
			<SnackbarContent
				className={classNames(classes[type], classes.root)}
				message={<span id="client-snackbar" className={classes.message}>
					<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{toI18N(message)}
				</span>}
				action={[
					...actions.map(action => <Button
						onClick={action.onClick}
						className={classes.button}
						variant="text"
						color="secondary"
					>
						{toI18N(action.label)}
					</Button>),
					<IconButton
						key="close"
						color="inherit"
						className={classes.close}
						onClick={handleClose}
					>
						<Close className={classes.icon} />
					</IconButton>
				]}
			/>
		</SnackbarUI>
	)
})


Snackbar.propTypes = {
	visible: PropTypes.bool.isRequired,
	hideSnack: PropTypes.func.isRequired,
	delay: PropTypes.number,
	message: PropTypes.oneOfType([
		PropTypes.string,
		messageShape,
		PropTypes.element
	]).isRequired,
	type: PropTypes.oneOf(Object.values(SnackbarType)).isRequired,
	actions: PropTypes.arrayOf(PropTypes.shape({
		onClick: PropTypes.func,
		label: PropTypes.oneOfType([
			PropTypes.string,
			messageShape
		])
	}))
}

Snackbar.defaultProps = {
	visible: false,
	delay: DEFAULT_SNACK_DURATION,
	type: SnackbarType.SUCCESS,
	actions: []
}

const mapStateToProps = state => ({
	...state.snackPermasoft
})

export default connect(mapStateToProps)(Snackbar)
