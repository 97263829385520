import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const ExportIcon = ({ height, width, color }) => {
	return (
		<svg viewBox="0 -28 453.998 453" width={width} height={height}>
			<path
				d="M345.375 3.41a10.001 10.001 0 00-17.055 7.09v53.36c-54.011 2.148-81.058 24.538-85.191 28.261a151.604 151.604 0 00-52.613 88.121 155.24 155.24 0 00-1.786 50.781l.008.059c.02.148.043.3.067.45l2.125 12.214a9.998 9.998 0 0018.426 3.43l6.367-10.613c19.562-32.528 43.941-54.09 72.468-64.086a105.029 105.029 0 0140.13-5.883v55.265a9.999 9.999 0 0017.09 7.051L451.085 122.7c3.894-3.915 3.879-10.247-.035-14.141zm2.95 194.215v-40.027a10 10 0 00-8.41-9.871c-8.556-1.38-31.372-3.57-58.337 5.878-28.765 10.079-53.652 29.91-74.148 59.051a142.449 142.449 0 012.707-28.527c6.078-30.73 21.515-56.543 45.879-76.711.214-.176.418-.36.617-.555.699-.648 26.097-23.578 81.61-23.164h.073c5.524 0 10.004-4.48 10.008-10.004V34.56l81.535 81.125zm0 0"
				fill={color}
			/>
			<path
				d="M417.352 294.953c-5.52 0-10 4.477-10 10v42.262c-.016 16.562-13.438 29.98-30 30H50c-16.563-.02-29.98-13.438-30-30V108.973c.02-16.563 13.438-29.98 30-30h69.16c5.524 0 10-4.477 10-10 0-5.524-4.476-10-10-10H50c-27.602.03-49.969 22.398-50 50v238.242c.031 27.601 22.398 49.969 50 50h327.352c27.601-.031 49.968-22.399 50-50v-42.262c0-5.523-4.477-10-10-10zm0 0"
				fill={color}
			/>
		</svg>
	)
}

ExportIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

ExportIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default ExportIcon
