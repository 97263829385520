import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import NoResultPage from '../../../../../components/NoResultPage'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import classnames from 'classnames'
import { colors } from '../../../../../utils/constants'
import InfoTache from '../../../../common/information/InfoTache'
import FormattedTacheLieuMessage from '../../../gestionTache/component/common/FormattedTacheLieuMessage'
import TacheStatutIcon from '../../../gestionTache/component/common/TacheStatutIcon'
import { convertMinToHours, formatDuration } from '../../../../../utils/utils'
import SpringDataTableWithLoader from '../../../../../components/layout/SpringDataTableWithLoader'

const styles = () => getStyles({
	red: {
		color: colors.error
	},
	orange: {
		color: colors.warning
	},
	green: {
		color: colors.valid
	},
	lightGreen: {
		color: colors.tacheEnCoursOuTerminee
	},
	table: {
		marginTop: 30,
		marginLeft: 97,
		width: '85%'
	},
	divTable: {
		'& .MuiTableCell-root:first-child': {
			display: 'none'
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073'
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		},
		'& .MuiTableRow-root': {
			height: 30
		},
		'& .MuiTableRow-head': {
			backgroundColor: colors.backgroundSuiviTacheAvancement
		},
		'& .MuiTableCell-stickyHeader': {
			backgroundColor: '#F5F2F8'
		},
		'& .MuiTableHead-root': {
			height: 40
		}
	}
})

export const SUIVI_RESSOURCE_TABLE = 'SuiviRessourceTable'

const DataTableRessource = ({
	classes,
	idRessource
}) => {
	const today = React.useMemo(() => moment().format('YYYY-MM-DD'), [])

	return (
		<div className={classes.divTable}>
			<SpringDataTableWithLoader
				nom={SUIVI_RESSOURCE_TABLE}
				showBorders={false}
				apiUrl="/suiviTaches/byRessource"
				defaultPageSize={50}
				filters={{ idRessource: idRessource, jour: today }}
				selectable
				tableProps={{
					stickyHeader: true,
					className: classes.table
				}}
				noResultFragment={<><br /><br /><br /><NoResultPage /></>}
				headers={[
					{
						key: 'tache',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.tache" />,
						render: row => <InfoTache tache={{ type: row.type, culture: row.culture }} inTable />
					},
					{
						key: 'lieu',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.lieu" />,
						tableHeaderColumnProps: {
							width: '15%',
							align: 'center'
						},
						render: row => <FormattedTacheLieuMessage typeLieu={row.typeLieu} lieu={row.lieu} />
					},
					{
						key: 'affectations',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.affectations" />,
						tableHeaderColumnProps: {
							width: '20%',
							align: 'center'
						},
						render: row => row.affectationLightBeanList.map(affectation => affectation.user.preferences.ALIAS).join(', ')
					},
					{
						key: 'statut',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.statut" />,
						tableHeaderColumnProps: {
							width: '15%',
							align: 'center'
						},
						render: row => <TacheStatutIcon statut={row.statut} isEnRetard={row.enRetard} fromSuivi />
					},
					{
						key: 'charge',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.charge" />,
						tableHeaderColumnProps: {
							width: '12%',
							align: 'center'
						},
						render: row => formatDuration(row.chargeTravail)
					},
					{
						key: 'temps',
						name: <FormattedMessage id="suiviAvancement.suiviRessources.header.temps" />,
						tableHeaderColumnProps: {
							width: '10%',
							align: 'center'
						},
						render: row => <span
							className={classnames({
								[classes.red]: row.tempsPasse - moment.duration(row.chargeTravail).asMinutes() > 30 && row.tempsPasse !== 0,
								[classes.orange]: row.tempsPasse - moment.duration(row.chargeTravail).asMinutes() < 30 && row.tempsPasse - moment.duration(row.chargeTravail).asMinutes() > 0 && row.tempsPasse !== 0,
								[classes.green]: moment.duration(row.chargeTravail).asMinutes() - row.tempsPasse > 30 && row.tempsPasse !== 0,
								[classes.lightGreen]: moment.duration(row.chargeTravail).asMinutes() - row.tempsPasse < 30 && moment.duration(row.chargeTravail).asMinutes() - row.tempsPasse > 0 && row.tempsPasse !== 0
							})}
						>
							{convertMinToHours(row.tempsPasse)}
						</span>
					}
				]}
			/>
		</div>
	)
}

DataTableRessource.propTypes = {
	classes: PropTypes.object,
	idRessource: PropTypes.string
}

export default compose(
	withStyles(styles)
)(DataTableRessource)
