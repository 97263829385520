import { UPDATE_SNACK } from './action'

export const initialState = {
	message: '',
	type: 'info',
	delay: 10000,
	actions: [],
	visible: false
}

/**
 * Manipulation de la snack via un réducer local
 * @param state état actuel du panneau
 * @param action action à traiter
 */
export const snackPermasoft = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SNACK:
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}
