import { Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage} from 'react-intl'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { PANEL_FORM, RECIPENT_VALUE_FIELD, REMINDER_FORM_FIELD } from '../../tool/tourPlaine.constants'
import Input from '../../../../../components/form/Input'
import CheckboxGroup from '../../../../../components/form/CheckboxGroup'
import { toI18N } from '../../../../../utils/utils'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25,
		rowGap: 25
	},
	descriptionText: {
		fontStyle: 'italic',
		fontWeight: 300,
		fontSize: 12,
		lineHeight: '15px',
		textAlign: 'center'
	},
	fields: {
		padding: '0px 15px',
		rowGap: 25
	},
	fieldLabel: {
		fontWeight: 800,
		fontSize: 14,
		lineHeight: '15px',
		textAlign: 'center',
		color: '#000',
		marginBottom: 15
	},
	checkbox: {
		padding: '0px 20px',
		alignContent: 'space-between'
	},
	container: {
		width: '100%'
	}
})

/**
 * ReminderForm
 * @param handleSubmit
 * @param classes
 * @param inputLabel
 * @returns {JSX.Element}
 * @constructor
 */
const ReminderForm = ({ handleSubmit, classes, inputLabel }) => {
	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.form}>
				<Typography className={classes.descriptionText}>
					<FormattedMessage id="tourPlaine.form.reminder.description" />
				</Typography>
				<Grid container className={classes.fields}>
					<Grid item container>
						<Grid item>
							<Typography className={classes.fieldLabel}>
								<FormattedMessage id="tourPlaine.form.reminder.recipient" />
							</Typography>
						</Grid>
						<Grid item container className={classes.checkbox}>
							<Field
								name={REMINDER_FORM_FIELD.RECIPENT}
								component={CheckboxGroup}
								items={Object.values(RECIPENT_VALUE_FIELD).map(val => ({
									value: val,
									label: <Typography><FormattedMessage id={`tourPlaine.form.reminder.${val}`} /></Typography>
								}))}
								row
							/>
						</Grid>
					</Grid>
					<Field
						name={REMINDER_FORM_FIELD.REMINDER}
						component={Input}
						label={toI18N(inputLabel)}
						multiline
						containerClassName={classes.container}
					/>
				</Grid>
			</Grid>
		</form>
	)

}

ReminderForm.propType = {
	inputLabel: PropTypes.string,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}


export default compose(
	reduxForm({
		form: PANEL_FORM.REMINDER,
		enableReinitialize: true
	}),
	withStyles(styles)
)
(ReminderForm)
