import { Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { DAY_PERIOD, WEATHER_TILE_TYPE } from '../tool/contextePedoclimatique.constants'
import { getHumidityIcon, getSoilIcon, getTemperatureIcon, getWeatherIcon, getWindIcon } from '../tool/contextePedoclimatique.utils'
import { colors } from '../../../../../../utils/constants'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '../../../../../../components/IconButton'
import { injectWeatherUpdateForm } from '../service/weatherUpdateFormInjector'
import { ZONE_TYPE } from '../../../tool/tourPlaine.constants'

/**
 * Styles
 */
const styles = () => getStyles({
	content: {
		height: 125,
		borderRadius: 10,
		border: `1px solid ${colors.lightPrimary}`,
		rowGap: 5
	},
	title: {
		fontWeight: 400,
		lineHeight: '14px',
		fontStyle: 'italic',
		fontSize: 10,
		color: colors.primary,
		textAlign: 'center',
		verticalAlign: 'bottom',
		padding: '0 7px'
	},
	iconBtn: {
		height: 25,
		width: 25,
		border: `1px solid ${colors.primary}`
	},
	iconBtnContainer: {
		height: 35,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex'
	},
	icon: {
		height: 14,
		width: 14
	}
})

/**
 * WeatherTile
 * @param classes
 * @param dayKey id
 * @param type type de tuile, meteo, humidité, vent, température ou nature du sol
 * @param zone serre ou exterieur
 * @param isdayKeySplitted if true show weather icon for AM and PM
 * @param data -> data ou on va chercher la donnée
 * @param weekMode -> should be small or not
 * @returns {JSX.Element}
 * @constructor
 */
const WeatherTile = ({
	classes,
	dayKey,
	type,
	zone,
	isDaySplitted,
	data,
	weekMode,
	openWeatherUpdateForm,
	isDashboard
}) => {

	const getPedoclimatiqueValue = (dayPeriod) => data[dayPeriod][zone] ? data[dayPeriod][zone][type] : undefined

	/**
	 * Manage the row width
	 * @returns {number}
	 */
	const computeRowWidth = () => {
		if (weekMode) {
			return zone === ZONE_TYPE.INSIDE ? 94.25 : 74.4
		}
		return zone === ZONE_TYPE.INSIDE ? ( isDashboard ? 88.25 : 102.25 ) : ( isDashboard ? 70 : 80.8 )
	}

	const renderIcon = (dayPeriod) => {
		const pedoclimatiqueValue = getPedoclimatiqueValue(dayPeriod)
		if (!pedoclimatiqueValue) {
			return null
		}

		switch (type) {
			case WEATHER_TILE_TYPE.WEATHER:
				return getWeatherIcon(pedoclimatiqueValue)
			case WEATHER_TILE_TYPE.HUMIDITY:
				return getHumidityIcon(pedoclimatiqueValue)
			case WEATHER_TILE_TYPE.WIND:
				return getWindIcon(pedoclimatiqueValue)
			case WEATHER_TILE_TYPE.TEMPERATURE:
				return getTemperatureIcon(pedoclimatiqueValue)
			case WEATHER_TILE_TYPE.SOIL:
				return getSoilIcon(pedoclimatiqueValue)
			default:
				return null
		}
	}

	const renderIconButton = (onClick) => (
		<div className={classes.iconBtnContainer}>
			<IconButton className={classes.iconBtn} onClick={onClick}>
				<EditIcon style={{ width: '14px', height: '14px', color: colors.primary }} />
			</IconButton>
		</div>
	)

	return (
		<Grid container justify="center" className={classes.content} style={{ width: computeRowWidth() }}>
			<Grid item container justify="center" alignItems="flex-start" style={{ height: isDaySplitted ? '28%' : '40%', paddingTop: 15 }}>
				<Typography className={classes.title}>
					<FormattedMessage id={`tourPlaine.contextePedoclimatique.tileType.${type}`} />
				</Typography>
			</Grid>
			<Grid item container justify="center" alignItems="flex-start" style={{ height: isDaySplitted ? undefined : '45%' }}>
				<Grid item container direction="row" justify="center" alignItems="center" style={{ columnGap: zone === ZONE_TYPE.INSIDE ? 10 : 5 }}>
					{renderIcon(DAY_PERIOD.AM)}
					{!isDashboard && renderIconButton(() => openWeatherUpdateForm(dayKey, DAY_PERIOD.AM, zone, type, getPedoclimatiqueValue(DAY_PERIOD.AM), isDaySplitted ? false : true ))}
				</Grid>
			</Grid>
			{isDaySplitted && (
				<Grid item container justify="center" alignItems="flex-start">
					<Grid item container direction="row" justify="center" alignItems="center" style={{ columnGap: zone === ZONE_TYPE.INSIDE ? 10 : 5 }}>
						{renderIcon(DAY_PERIOD.PM)}
						{!isDashboard && renderIconButton(() => openWeatherUpdateForm(dayKey, DAY_PERIOD.PM, zone, type, getPedoclimatiqueValue(DAY_PERIOD.PM), false))}
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default compose(
	injectWeatherUpdateForm,
	withStyles(styles)
)(WeatherTile)