import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FormButton from '../../../../components/form/FormButton'
import { FilterPanelContext } from '../../../../components/layout/rightPanels'
import { getPreferences } from '../../../common/user/services/userSelectors'
import { saveCurrentFilter, setFilterOpen, setFilterUsed } from '../../planning/services/toolbar/toolbarAction'
import { getFilterToolbarState } from '../../planning/services/toolbar/toolbarSelector'
import FilterForm from '../form/FilterForm'
import { useGestionTacheContext } from '../GestionTacheProvider'
import { FILTER_FORM } from '../utils/constants'

/**
 * Injecteur pour l'ouverture du filtre de planification des tâches
 */
export const injectPlanificationFilter = (WrappedComponent) => {
	const FilterPlanificationInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(FilterPanelContext)
		const { onglet, jourSelection, refreshHeaderData } = useGestionTacheContext()
		const { filterState, setFilterOpen, setFilterUsed, saveCurrentFilter, userPreferences } = props
		let title = <FormattedMessage id="gestionTaches.ecranPlanification.filter.title" />

		const onSubmit = (values) => {
			const notEmpty = Object.values(values).some(value => !!value && (value.length || value === true))

			setFilterUsed(notEmpty)
			setFilterOpen(false)
			closePanel()
			saveCurrentFilter(values)

			/** Chaque table a accès aux filtres et un useEffect en fonction de ces derniers
			 *   →   Pas besoin de call refreshTable ici
			 */
			refreshHeaderData(jourSelection)
		}
		const buttons = [
			<FormButton type="primary" formName={FILTER_FORM}>
				<FormattedMessage id="planning.filter.form.submit" />
			</FormButton>
		]

		React.useEffect(() => {
			if (filterState.open) {
				openPanel(
					title,
					<FilterForm onSubmit={onSubmit} idFerme={userPreferences.FERME_ID} onglet={onglet} jourSelection={jourSelection} />,
					buttons
				)
			}
		}, [filterState.open])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		filterState: getFilterToolbarState(state),
		userPreferences: getPreferences(state)
	})

	const actions = {
		setFilterOpen,
		setFilterUsed,
		saveCurrentFilter
	}

	return compose(
		connect(mapStateToProps, actions)
	)(FilterPlanificationInjector)
}
