import React from 'react'
import {getSolverStatus} from "../../common/solver/solverApi";
import {getCurrentCampagne} from "../../common/campagne/campagneSelector";
import {connect} from "react-redux";

const solverStatusContextType = {
	/**
	 * @type {{
	 * status: string|null,
	 * userGeneration: string|null,
	 * dateGeneration: string|null,
	 * userSauvegarde: string|null,
	 * dateSauvegarde: string|null,
	 * dateDerniereSauvegardeDemande: string|null
	 * dateValidationTDP: string|null
	 * }|null}
	 */
	solverInfos: null,
	/**
	 * @type {() => void}
	 */
	refreshSolverInfosForCurrentCampagne: () => {}
}

export const SolverStatusContext = React.createContext(solverStatusContextType)

export const useSolverStatusContext = () => React.useContext(SolverStatusContext)

const SolverStatusContextProvider = ({ children }) => {

	const [solverInfos, setSolverInfos] = React.useState(null)

	const refreshSolverInfosForCurrentCampagne = React.useCallback(
		() => {
			return getSolverStatus().then((response) => setSolverInfos(response))
		},
		[]
	)

	React.useEffect(() => { refreshSolverInfosForCurrentCampagne() }, [refreshSolverInfosForCurrentCampagne])

	const contextValue = React.useMemo(() => ({
		solverInfos,
		refreshSolverInfosForCurrentCampagne
	}))

	return (
		<SolverStatusContext.Provider value={contextValue}>
			{children}
		</SolverStatusContext.Provider>
	)
}



const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

export default connect(mapStateToProps)(SolverStatusContextProvider)
