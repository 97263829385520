import React from 'react'

/**
 * @callback openDialogType
 * @param string le titre
 * @param React.Component
 * @param Array<React.Component>
 * @param string le type de popin
 * @return void
 */
/**
 * @callback closeDialogType
 * @return void
 */
/**
 * Définition des types des contextes relatifs aux popins
 * @type {{openDialog: openDialogType, title: string, actions: Array<React.Component>, content: React.Component, type: string closeDialog: closeDialogType}}
 */
const contextType = {
	/**
	 * Titre de la popin
	 * @type string
	 */
	title: '',
	/**
	 * Contenu de la popin
	 * @type React.Component
	 */
	content: <React.Fragment />,
	/**
	 * Liste des actions de la popin
	 * @type Array<React.Component>
	 */
	actions: [],
	/**
	 * Type de la popin
	 * @type string
	 */
	type: '',
	/**
	 * Méthode permettant d'ouvrir la popin
	 * @type openDialogType
	 */
	openDialog: (title, content, actions, type, labelClose, titleIcon, isWidthLg) => {},
	/**
	 * Méthode permettant de fermer la popin
	 * @type closeDialogType
	 */
	closeDialog: () => {}
}

/**
 * Contexte relatif aux popins
 * @type {React.Context<{openDialog: openDialogType, title: string, actions: Array<React.Component>, content: React.Component, type: string closeDialog, closeDialog: closeDialogType}>}
 */
export const DialogContext = React.createContext(contextType)