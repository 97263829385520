import React from 'react'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { Field, formValueSelector } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import Input from '../../../../../components/form/Input'
import { FormattedMessage } from 'react-intl'
import {formatPositiveInteger} from '../../../../../utils/utils'
import {connect} from 'react-redux'
import {SUIVI_DE_TACHE_FORM} from '../../../../../utils/constants'

const styles = () => getStyles({
	container: {
		width: 'auto',
		marginBottom: 8,
		fontFamily: 'Lato',
		height: 68
	},
	content: {
		fontSize: 19,
		padding: 0
	},
	labeluser: {
		height: 72
	},
	contentLabel: {
		marginTop: 12
	}
})

const TempsPasseUserLi = ({ field, classes, label }) => {
	return (
		<li>
			<Grid container direction="row" className={classes.container} justify='space-between'>

				<Grid item className={classes.content}>
					<Grid className={classes.contentLabel}>
						<label className={classes.labeluser}>{label} :</label>
					</Grid>
				</Grid>

				<Grid item className={classes.content}>
					<Field
						label={<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.tempsPasse" />}
						name={`${field}.tempsPasse`}
						component={Input}
						type="number"
						format={formatPositiveInteger}
						adornment={
						<span style={{fontSize: 12}}>
							<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.tempsPasseAdornement" />
						</span>
						}
						style={{ width: 195 }}
					/>
				</Grid>

			</Grid>
		</li>
	)

}

const selector = formValueSelector(SUIVI_DE_TACHE_FORM)

TempsPasseUserLi.propTypes = {
	field: PropTypes.object,
	label: PropTypes.string,
	classes: PropTypes.object
}

const mapStateToProps = (state, { field }) => {
	return ({
		label: (field && selector(state, `${field}.label`)) || "",
	})
}

export default compose(
	connect(mapStateToProps),
	injectIntl,
	withStyles(styles)
)(TempsPasseUserLi)