import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { injectZoneSurfaceValueList } from '../../../../common/valueLists/valueListInjectors'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { localCompare } from '../../../../../utils/utils'
import moment from 'moment'
import { LIBELLE_SURFACE, TYPE_PLANCHE } from '../../../../../utils/constants'

const styles = () => getStyles({
	contenu: {
		margin: '1.5em',
		marginTop: 4
	},
	titre: {
		marginTop: 10,
		fontWeight: 'bold'
	},
	bloc: {
		marginTop: 22
	},
	liste: {
		marginTop: -6,
		marginLeft: -8
	}
})

const getDateDebutAssolement = (assolement) => assolement.assolementEtapeList.sort(localCompare('dateDebut'))[0].dateDebut

const getDateFinAssolement = (assolement) => assolement.assolementEtapeList.sort(localCompare('dateFin', true))[0].dateFin

const getLibelleSurface = (surface) => {
	switch (surface) {
		case TYPE_PLANCHE.PERMANENTE:
			return LIBELLE_SURFACE.PERMANENTE
		default:
			return LIBELLE_SURFACE.HETEROGENE
	}
}

const ContenuPopinAjoutGroupeCulture = ({ formattedMessageBase, values, classes, zoneSurfaceList, assolements, surface }) => {
	return (
		<Grid className={classes.contenu}>
			<Grid>
				<FormattedMessage id={`${formattedMessageBase}.message`} />
			</Grid>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					{values.nom}
				</Grid>
				<Grid>
					{values.description}
				</Grid>
			</Grid>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					<FormattedMessage id={`${formattedMessageBase}.cultures.title`} />
				</Grid>
				<Grid className={classes.liste}>
					<ul>
						{
							values.assolement.map(culture => assolements[culture]).map((culture, index) => {
								const debutAssolement = getDateDebutAssolement(culture)
								const finAssolement = getDateFinAssolement(culture)

								return (
									<li key={index}>
										<FormattedMessageWithBold
											id={`${formattedMessageBase}.cultures.text`}
											values={{ nom: culture.culture.nom, debut: moment(debutAssolement).format('DD/MM/YYYY'), fin: moment(finAssolement).format('DD/MM/YYYY') }}
										/>
									</li>
								)
							})
						}
					</ul>
				</Grid>
			</Grid>
			<Grid className={classes.bloc}>
				<Grid className={classes.titre}>
					<FormattedMessage id={`${formattedMessageBase}.other.title`} />
				</Grid>
				<Grid className={classes.liste}>
					<ul>
						<li>
							<FormattedMessageWithBold
								id={`${formattedMessageBase}.other.dates`}
								values={{ debut: values.dateDebut, fin: values.dateFin }}
							/>
						</li>
						<li>
							<FormattedMessageWithBold
								id={`${formattedMessageBase}.other.zones`}
								values={{ zones: values.zones.map(zone => zoneSurfaceList.find(element => element.code === zone).label.trim()).join(', ') }}
							/>
						</li>
						<li>
							<FormattedMessageWithBold
								id={`${formattedMessageBase}.other.surface`}
								values={{ surface: getLibelleSurface(surface) }}
							/>
						</li>
					</ul>
				</Grid>
			</Grid>
		</Grid>
	)
}

ContenuPopinAjoutGroupeCulture.propTypes = {
	formattedMessageBase: PropTypes.string,
	values: PropTypes.object,
	classes: PropTypes.object,
	zoneSurfaceList: PropTypes.array,
	assolements: PropTypes.object,
	surface: PropTypes.string
}

export default compose(
	injectZoneSurfaceValueList,
	withStyles(styles)
)(ContenuPopinAjoutGroupeCulture)
