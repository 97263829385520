import React, {useEffect} from 'react'
import {getStyles} from 'isotope-client'
import {colors} from '../../../utils/constants'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core'
import {useTourPlaineContext} from './TourPlaineContext'
import ToolBar from './component/ToolBar'
import {TOUR_PLAINE_TABS} from './tool/tourPlaine.constants'
import ContextePedoclimatique from './component/contextePedoclimatique/ContextePedoclimatique'
import Loader from '../../../components/layout/Loader'
import SuiviParBloc from './component/suiviParBloc/SuiviParBloc'
import SuiviPepiniere from './component/suiviPepiniere/SuiviPepiniere'
import StockMateriel from './component/stockMateriel/StockMateriel'
import {getAllCampagnes} from '../planning/services/planningApi'
import moment from 'moment'
import {setActiveCampagne} from '../../common/campagne/campagneAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeBackground,
		minHeight: 'calc(100vh - 92px)',
		padding: '14px 24px',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'&::-webkit-scrollbar': {
			width: 0
		}
	}
})

/**
 * TourPlaineContent
 * @returns {JSX.Element}
 */
const TourPlaineContent = ({ classes, setActiveCampagne }) => {

	const { isLoading, currentTab, currentWeek } = useTourPlaineContext()

	const renderToolBar = () => {
		return <ToolBar currentWeek={currentWeek} currentTab={currentTab} />
	}
	const renderBody = (tab) => {
		switch (tab) {
			case TOUR_PLAINE_TABS.SUIVI_PEPINIERE.code:
				return <SuiviPepiniere />
			case TOUR_PLAINE_TABS.SUIVI_BLOC.code:
				return <SuiviParBloc />
			case TOUR_PLAINE_TABS.SUIVI_MATERIELS.code:
				return <StockMateriel />
			case TOUR_PLAINE_TABS.CONTEXTE_PEDOCLIMATIQUE.code:
			default:
				return <ContextePedoclimatique isDashboard={false} />
		}
	}

	useEffect(() => {
		getAllCampagnes().then(campagnes => {
			const today = moment()
			const campagneActuelle = campagnes.filter(c => moment(c.dateDebut).isSameOrBefore(today) && moment(c.dateFin).isSameOrAfter(today))
			const campagne = campagneActuelle.length > 0 ? campagneActuelle[0] : campagnes[campagnes.length - 1]
			if (campagne && campagne.id) {
				setActiveCampagne(campagne)
			}
		})
	}, [])

	return (
		<div className={classes.root}>
			{renderToolBar()}
			{
				isLoading
					? (
						<Loader show={isLoading} />
					) :
					renderBody(currentTab)
			}
		</div>
	)
}

TourPlaineContent.propTypes = {
	classes: PropTypes.object,
	setActiveCampagne: PropTypes.func
}

const action = {
	setActiveCampagne
}

export default compose(
	connect(undefined, action),
	withStyles(styles)
)(TourPlaineContent)
