import { Button, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { localCompare } from '../utils/utils'
import CommentSuivi from './CommentSuivi'


const styles = (theme) => getStyles({
	commentContainer: {
		overflow: 'hidden',
		position: 'relative'
	},
	comment: {
		height: 'min-content'
	},
	seeButton: {
		float: 'right',
		color: theme.palette.primary.main,
		'& span': {
			textTransform: 'initial'
		}
	},
	more: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		background: 'inherit'
	}
})


const CommentSuiviList = ({
	comments,
	maxLines,
	noEdit = false,
	classes,
	editComment,
	deleteComment
}) => {
	const [open, setOpen] = React.useState(false)
	const [tooLong, setTooLong] = React.useState(false)
	React.useEffect(() => {
		if (!tooLong && comments.length > 1) {
			setTooLong(true)
		}
	}, [tooLong, comments])

	if (!comments || comments.length === 0) {
		return null
	}

	const commentsVisible = comments
		.sort(localCompare('dateModification', true))
		.slice(0, (tooLong && !open) ? 1 : comments.length)

	const toggleOpen = () => setOpen(previousState => !previousState)

	return (
		<div>
			<div className={classes.commentContainer}>
				{commentsVisible.map((comment, index) => {
					const props = {
						comment,
						editComment,
						deleteComment,
						setTooLong
					}
					if (index === 0) {
						props.maxLines = maxLines
						props.open = open
					}
					return <CommentSuivi key={index} noEdit={noEdit} {...props} />
				})}
			</div>
			{tooLong &&
			<Button className={classes.seeButton} onClick={toggleOpen}>
				<FormattedMessage id={`planning.buttons.see${open ? 'Less' : 'More'}`} />
			</Button>
			}
			{tooLong && <br />}
		</div>

	)
}

CommentSuiviList.propType = {
	comments: PropTypes.objectOf(PropTypes.instanceOf(CommentSuivi)),
	maxLines: PropTypes.integer,
	noEdit: PropTypes.bool,
	editComment: PropTypes.func,
	deleteComment: PropTypes.func
}

CommentSuiviList.defaultProps = {
	maxLines: 2
}

export default withStyles(styles)(CommentSuiviList)
