import { getStyles } from 'isotope-client'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import {connect} from 'react-redux'
import {compose} from 'redux'
import { colors } from '../../../../utils/constants'
import { getCurrentCampagne } from '../../../common/campagne/campagneSelector'
import classnames from 'classnames'

const styles = () => getStyles({
	delimiter: {
		position: 'absolute',
		width: 2,
		height: '100%',
		zIndex: 100,
		background: `linear-gradient(${colors.primary} 33%, rgba(255,255,255,0) 0%)`,
		backgroundPosition: 'right',
		backgroundSize: '2px 16px',
		backgroundRepeat: 'repeat-y'
	},
	header: {
		height: '40%',
		bottom: 0
	}
})

const Delimiter = ({campagne, header, colWidth, classes}) => {
	const weekBeforeCampagne = moment(campagne.dateDebut).startOf('isoWeeks').diff(campagne.dateDebutVisible, "weeks")
	const weekAfterCampagne = moment(campagne.dateFin).endOf('isoWeeks').diff(campagne.dateDebutVisible, "weeks")

	return (
		<>
			<div className={classnames(classes.delimiter, {[classes.header]: header})} style={{left: weekBeforeCampagne * colWidth}}/>
			<div className={classnames(classes.delimiter, {[classes.header]: header})} style={{left: weekAfterCampagne * colWidth}}/>
		</>
	)
}

Delimiter.propType = {
	colWidth: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(Delimiter)