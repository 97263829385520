import React from 'react'
import {Tooltip} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {colors} from '../utils/constants'

const CustomTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: colors.etapeBackground,
		color: 'black',
		width: 300,
		height: 120,
		borderRadius: 10,
		boxShadow: '0px 2px 4px gray'
	},
	arrow: {
		color: colors.etapeBackground
	}
}))(Tooltip)

const FieldWithTooltip = ({contenuField, contenuTooltip}) => {
	return (
		<>{contenuTooltip ? <CustomTooltip
			title={contenuTooltip}
			placement="top-start"
		>
			{contenuField}
		</CustomTooltip> : <>{contenuField}</>}</>
	)
}

export default FieldWithTooltip