import PropTypes from 'prop-types'
import { injectEtapeTypeValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'

/** Composant qui permet l'affichage du type de étape **/

const TypeEtape = ({ typeEtape, etapeTypeList }) => {
	return (etapeTypeList.find(type => type.code === typeEtape) || {}).label || DEFAULT_LABEL
}

TypeEtape.propTypes = {
	typeEtape: PropTypes.string,
	etapeTypeList: PropTypes.array
}

export default injectEtapeTypeValueList(TypeEtape)
