import { checkExport } from '../modules/common/export/exportApi'

const createSheetLinkFromId = sheetId => `https://docs.google.com/spreadsheets/d/${sheetId}`

export const openGSheet = (sheetId, target = '_blank') => window.open(createSheetLinkFromId(sheetId), target)

const checkSheetCreation = (id, intervalId, snackSuccess) => {
	checkExport(id).then(
		exportSheet => {
			if (exportSheet.created) {
				clearInterval(intervalId)
				snackSuccess({
					id: 'snackbar.update.exportProduction.message'
				}, [{
					onClick: () => openGSheet(exportSheet.sheetId),
					label: { id: 'snackbar.update.exportProduction.button' }
				}])
			}
		}
	)
}

/**
 * Verifie a interval regulier si le document est crée
 * On passe l'intervalId pour pouvoir arreter le setInterval
 * @param snackSuccess
 * @return {function(*): void}
 */
export const checkExportStateUntilFound = snackSuccess => exportSheet => {
	snackSuccess({ id: 'snackbar.update.exportRequest' })
	let intervalId 
	intervalId = setInterval(() => checkSheetCreation(exportSheet.id, intervalId, snackSuccess), 5000)
}