import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { TYPE_COMMENTAIRE_SUIVI, TYPE_VARIATION_IMPACT_PROD } from '../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import CommentForm from './component/CommentForm'
import { COMMENTAIRE_SUIVI_FORM_NAME, COMMENT_FIELDS } from '../../utils/constant'

const validate = (values, { campagne }) => {
	const errors = {}
	if (values.typeCommentaire === TYPE_COMMENTAIRE_SUIVI.IMPACT_PRODUCTION) {
		if (!values.typeVariation) {
			errors.typeVariation = { id: 'commentForm.errors.mandatory' }
		}
		if (!values.variation) {
			if (values.typeVariation && values.typeVariation !== TYPE_VARIATION_IMPACT_PROD.NULLE) {
				errors.variation = { id: 'commentForm.errors.mandatory' }
			}
		} else if (values.variation > 100 && values.typeVariation === TYPE_VARIATION_IMPACT_PROD.PERTE) {
			errors.variation = { id: 'commentForm.errors.negativeProduction' }
		}
		const dateApplication = values[COMMENT_FIELDS.DATE_APPLICATION]
		if (!dateApplication) {
			errors[COMMENT_FIELDS.DATE_APPLICATION] = { id: 'commentForm.errors.dateApplicationMandatory' }
		}
		if (!moment(dateApplication).isBetween(moment(campagne.dateDebut), moment(campagne.dateFin))) {
			errors[COMMENT_FIELDS.DATE_APPLICATION] = { id: 'commentForm.errors.dateApplication' }
		}
	} else {
		if (!values.commentaire) {
			errors.commentaire = { id: 'commentForm.errors.commentaireMandatory' }
		}
	}
	return errors
}

const CommentaireSuivi = ({ handleSubmit, clearFields }) => {
	return (
		<form onSubmit={handleSubmit}>
			<CommentForm formName={COMMENTAIRE_SUIVI_FORM_NAME} clearFields={clearFields} />
		</form>
	)
}


CommentaireSuivi.propTypes = {
	handleSubmit: PropTypes.func,
	clearFields: PropTypes.func
}

const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state)
	}
)
export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: COMMENTAIRE_SUIVI_FORM_NAME,
		validate
	})
)(CommentaireSuivi)
