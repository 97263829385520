import withStyles from '@material-ui/core/styles/withStyles'
import classnames from 'classnames'
import {getStyles} from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Rnd} from 'react-rnd'
import {compose} from 'redux'
import {ASSOLEMENT_ETAT, ETAPE_TYPE} from '../../../../../utils/constants'
import {compareDateSemis} from '../../../../../utils/utils'
import {editAssolementPeriod, editEtapeListPeriod} from '../../services/assolement/assolementAction'
import {getAssolementById, isActiveRow} from '../../services/assolement/assolementSelector'
import {ACTION_TYPE} from '../../utils/constant'
import {hasCampagneChanged} from '../../utils/utils'
import AssolementRowItem from './AssolementRowItem'
import {maxPositionDragRight, minPositionDragLeft, moveTaskBehind} from './rowUtils'
import {useSnackbar} from '../../../../../components/layout/snackbar/SnackbarContext'
import {getNotifications} from '../../../../common/notification/notificationActions'
import {useSolverStatusContext} from '../../../besoinsProduction/SolverStatusContextProvider'
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat} from "../../../../../utils/dateConstants";

const styles = () => getStyles({
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '-5',
		cursor: 'initial !important'
	},
	moving: {
		cursor: 'move !important'
	},
	containerMoveItem: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		height: 40
	}
})

const INIT_CONTAINER_OFFSET = { left: 0, right: 0 }

const ACTIONS_ROW_TYPE = {
	DRAG: 'DRAG',
	RESIZE: 'RESIZE'
}

const MOVE_TYPE = {
	ROW: 'ROW',
	ITK: 'ITK'
}

const AssolementRow = ({
	campagne,
	assolement,
	gridDimension,
	etat,
	isActive,
	isCompare,
	editPeriod,
	editEtapeListPeriod,
	classes,
	getNotifications
}) => {
	if (!assolement) {
		return null
	}

	const isAssolementValide = etat === ASSOLEMENT_ETAT.VALIDE
	if ((isAssolementValide && !assolement.assolementReference && assolement.etat !== ASSOLEMENT_ETAT.VALIDE) || (!isAssolementValide && (assolement.deleted || assolement.etat === ASSOLEMENT_ETAT.VALIDE))) {
		return null
	}

	return <AssolementRowFiltered
		campagne={campagne}
		assolement={assolement}
		gridDimension={gridDimension}
		etat={etat}
		isActive={isActive}
		isCompare={isCompare}
		editPeriod={editPeriod}
		editEtapeListPeriod={editEtapeListPeriod}
		classes={classes}
		getNotifications={getNotifications}
	/>
}

/**
 * Row représentant l'assolement complet
 */
const AssolementRowFiltered = ({
	campagne,
	assolement,
	gridDimension,
	etat,
	isActive,
	isCompare,
	editPeriod,
	editEtapeListPeriod,
	classes,
	getNotifications
}) => {
	const { snackSuccess, snackError, snackWarning } = useSnackbar()
	const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()
	const isAssolementValide = etat === ASSOLEMENT_ETAT.VALIDE
	const lastDragAction = React.useRef({
		type: undefined,
		editedAssolement: undefined,
		diffWeeks: undefined
	})
	const cancelEdit = React.useCallback(() =>{
		if (lastDragAction.current.type === MOVE_TYPE.ITK) {
			const editedAssolement = {
				...lastDragAction.current.editedAssolement,
				etapeList: lastDragAction.current.editedAssolement.etapeList.map(etape => ({
					...etape,
					nbWeeks: -1 * etape.nbWeeks
				}))
			}
			editEtapeListPeriod(editedAssolement, campagne.id, assolement.id)
				.then(() => {
					getNotifications()
					refreshSolverInfosForCurrentCampagne()
					snackSuccess({id: 'snackbar.cancel'})
				})
		} else if (lastDragAction.current.type === MOVE_TYPE.ROW) {
			editPeriod(assolement.id, -1 * lastDragAction.current.diffWeeks, campagne.id)
				.then(() => {
					getNotifications()
					refreshSolverInfosForCurrentCampagne()
					snackSuccess({id: 'snackbar.cancel'})
				})
		}
	}, [lastDragAction, campagne, assolement, snackSuccess, editEtapeListPeriod, editPeriod])

	const checkDatesItk = (newAssolement, keySuccess, keyWarning) => {
		const etape = newAssolement.assolementEtapeList.find(detail => detail.type === ETAPE_TYPE.SEMI_EN_CONTENANT || detail.type === ETAPE_TYPE.SEMI_DIRECT)
		if (etape) {
			const result = compareDateSemis(newAssolement, etape.dateDebut, etape.dateFin)
			if (result) {
				snackWarning({ id: keyWarning }, [{ onClick: cancelEdit, label: { id: 'actions.cancel' } }])
				return
			}
		}
		snackSuccess({ id: keySuccess }, [{ onClick: cancelEdit, label: { id: 'actions.cancel' } }])
	}

	const handleMovingTask = (taskId, position) => {
		// A l'activation du déplacement d'une étape:
		// - Passage en drag
		// - Set de la position
		// - Calcul de l'intervalle de déplacement possible (set dans la div moveItem)
		if (taskId) {
			setEnableMovingRow(false)
			resetResizeTask()
			setMovingTask(taskId)
			setInitXTask(position)
			setXTask(position)
			const etape = assolement.assolementEtapeList.find(task => task.id === taskId)

			const offsetLeft = minPositionDragLeft(assolement.assolementEtapeList, taskId, campagne.dateDebutVisible, gridDimension.width)
			const offsetRight = maxPositionDragRight(assolement.assolementEtapeList, taskId, campagne.dateFinVisible, gridDimension.width)

			const offset = { left: offsetLeft, right: offsetRight }
			if (etape.type === ETAPE_TYPE.FERTILISATION) {
				offset.left += moment(campagne.dateDebut).diff(campagne.dateDebutVisible, 'weeks') * gridDimension.width
				offset.right += campagne.dateFinVisible.diff(moment(campagne.dateFin), 'weeks') * gridDimension.width
			}
			setContainerOffset(offset)
		} else {
			// On sauvegarde le déplacement des étapes si celles-ci ont bougé
			saveTasks(ACTIONS_ROW_TYPE.DRAG)
		}
	}

	const handleResizingTask = (taskId, position) => {
		// A l'activation du resizing d'une étape
		// - Passage en resize
		// - Set de la position
		// - Calcul de l'intervalle de resize possible (set dans la div moveItem)
		if (taskId) {
			setEnableMovingRow(false)
			resetMovingTask()
			setResizingTask(taskId)
			setInitXTask(position)
			setXTask(position)

			const offsetLeft = minPositionDragLeft(assolement.assolementEtapeList, taskId, campagne.dateDebutVisible, gridDimension.width, true)
			const offsetRight = maxPositionDragRight(assolement.assolementEtapeList, taskId, campagne.dateFinVisible, gridDimension.width)
			setContainerOffset({ left: offsetLeft, right: offsetRight })
		} else {
			// On sauvegarde le déplacement des étapes si celles-ci ont bougé + le resize de l\'étape
			saveTasks(ACTIONS_ROW_TYPE.RESIZE)
		}
	}

	const saveTasks = (type) => {
		const modifiedTasks = []
		// Si nous avons effectué un déplacement
		const tasksAreModified = type === ACTIONS_ROW_TYPE.DRAG ? !!xTask && xTask !== 0 : xTaskWidth.width !== 0
		if (tasksAreModified) {
			assolement.assolementEtapeList.forEach(etape => {
				const isImplantation = etape.type === ETAPE_TYPE.SEMI_DIRECT || etape.type === ETAPE_TYPE.SEMI_EN_CONTENANT
				let deltaPosition = 0
				// L'tape déplacée
				if (etape.id === movingTask || etape.id === resizingTask) {
					// Calcul du drag
					if (type === ACTIONS_ROW_TYPE.DRAG) {
						deltaPosition = xTask - initXTask
						// Gestion du négatif dans le cas d'un déplacement à gauche
						if (deltaPosition < 0) {
							modifiedTasks.push({
								id: etape.id,
								nbWeeks: -Math.ceil(-deltaPosition / gridDimension.width),
								isImplantation
							})
							return
						}
					} else {
						// Calcul du resize
						modifiedTasks.push({
							id: etape.id,
							isResizing: true,
							direction: xTaskWidth.direction,
							nbWeeks: xTaskWidth.width / gridDimension.width,
							isImplantation
						})
					}

				} else {
					// Les étapes impactées par le déplacement
					// On récupère la position initiale
					const position = gridDimension.width * moment(etape.dateDebut).diff(moment(startRowDate), 'weeks')

					// On regarde s'il y a un delta avec la position actuelle
					deltaPosition += moveTaskBehind(etape, position, assolement.assolementEtapeList, initXTask, xTask, xTaskWidth, movingTask || resizingTask, gridDimension.width)
				}

				if (deltaPosition !== 0) {
					modifiedTasks.push({
						id: etape.id,
						nbWeeks: Math.trunc(deltaPosition / gridDimension.width),
						isImplantation
					})
				}
			})
		}
		// On enregistre l'ensemble des étapes modifiées
		if (modifiedTasks.length > 0) {
			const assolementCampagne = assolement.campagne
			const editedAssolement = {
				idAssolement: assolement.id,
				etapeList: modifiedTasks
			}
			lastDragAction.current = {
				type: MOVE_TYPE.ITK,
				editedAssolement
			}
			editEtapeListPeriod(editedAssolement, campagne.id, assolement.id)
				.then(({ assolement }) => {
					const successMessage = ACTIONS_ROW_TYPE.DRAG ? 'snackbar.update.deplacerEtape' : 'snackbar.update.resizeEtape'
					const warningMessage = ACTIONS_ROW_TYPE.DRAG ? 'snackbar.warning.dateSemis' : 'snackbar.warning.resizeDateSemis'
					const type = ACTIONS_ROW_TYPE.DRAG ? ACTION_TYPE.MOVE : ACTION_TYPE.RESIZE
					if (!hasCampagneChanged(assolementCampagne, assolement, campagne, snackWarning, type)) {
						if (!isAssolementFertilisation && !!modifiedTasks.find(task => task.isImplantation)) {
							checkDatesItk(assolement, successMessage, warningMessage)
						} else {
							snackSuccess({ id: successMessage }, [{ onClick: cancelEdit, label: { id: 'actions.cancel' } }])
						}
					}
					// Reset de la row
					setX(gridDimension.width * moment(assolement.assolementEtapeList[0].dateDebut).diff(moment(campagne.dateDebutVisible), 'weeks'))
					resetActionsOnRowItem()
					getNotifications()
					refreshSolverInfosForCurrentCampagne()
				})
				.catch(() => {
					lastDragAction.current = {}
					resetActionsOnRowItem()
					snackError({ id: ACTIONS_ROW_TYPE.DRAG ? 'snackbar.error.deplacerEtape' : 'snackbar.error.deplacerEtape' })
				})
		} else {
			resetActionsOnRowItem()
		}
	}

	const resetActionsOnRowItem = () => {
		resetMovingTask()
		resetResizeTask()
	}

	const resetMovingTask = () => {
		setMovingTask(undefined)
		setContainerOffset(INIT_CONTAINER_OFFSET)
		setXTask(undefined)
		setInitXTask(0)
	}

	const resetResizeTask = () => {
		setResizingTask(undefined)
		setXTaskWidth({ dir: '', width: 0 })
	}

	// Gestion du déplacement de la row
	const [enableMovingRow, setEnableMovingRow] = React.useState(false)

	// Gestion du déplacement unitaire (activé ou non, position déplacée)
	const [movingTask, setMovingTask] = React.useState(undefined)
	const [resizingTask, setResizingTask] = React.useState(undefined)
	const [initXTask, setInitXTask] = React.useState(0)
	const [xTask, setXTask] = React.useState(undefined)
	const [xTaskWidth, setXTaskWidth] = React.useState({ direction: '', width: 0 })
	const [containerOffset, setContainerOffset] = React.useState(INIT_CONTAINER_OFFSET)

	// Cas particulier de la fertilisation, nous ne modifions que les étapes unitairement
	const isAssolementFertilisation = !assolement.culture
	const assolementEtapeList = !isAssolementValide ? assolement.assolementEtapeList : (assolement.assolementReference && assolement.assolementReference.assolementEtapeList) ? assolement.assolementReference.assolementEtapeList : assolement.assolementEtapeList

	const lastEtape = assolementEtapeList.length > 0 && assolementEtapeList[assolementEtapeList.length - 1]
	const startRowDate = assolementEtapeList.length > 0 && assolementEtapeList[0].dateDebut
	const rowSize = Math.ceil(moment(lastEtape.dateFin || new Date()).diff(moment(startRowDate || new Date()), 'weeks', true))

	// Config pour déplacement de la row
	let dragConfig = {
		dragAxis: 'none'
	}
	if (enableMovingRow) {
		dragConfig = {
			dragAxis: 'x',
			onDrag: (e, d) => {
				setX(d.x)
			},
			onDragStop: (e, d) => {
				// Calcul du différentiel de semaines
				const diffWeeks = Math.round((d.x - initialX) / gridDimension.width)
				if (diffWeeks !== 0) {
					const previousCampagne = assolement.campagne
					lastDragAction.current = {
						type: MOVE_TYPE.ROW,
						diffWeeks
					}
					editPeriod(assolement.id, diffWeeks, campagne.id)
						.then((result) => {
							getNotifications()
							refreshSolverInfosForCurrentCampagne()
							if (!hasCampagneChanged(previousCampagne, result.assolement, campagne, snackWarning, ACTION_TYPE.MOVE)) {
								checkDatesItk(result.assolement, 'snackbar.update.deplacerItk', 'snackbar.warning.dateSemis')
							}
						})
						.catch(() => {
							lastDragAction.current = {}
							snackError({ id: 'snackbar.error.deplacerItk' })
						})
				}
				setEnableMovingRow(false)
			}
		}
	}

	// On place notre row par rapport à la campagne

	const offsetWeek = moment(startRowDate || new Date()).diff(moment(campagne.dateDebutVisible), 'weeks')
	const initialX = gridDimension.width * offsetWeek
	const [x, setX] = React.useState(initialX)
	React.useEffect(() => {
		setX(initialX)
	}, [assolementEtapeList, gridDimension.width])

	// Si plus de étape sur la row, on n'affiche rien
	if (assolementEtapeList.length === 0) {
		return null
	}

	// Fonction qui permet la gestion du display de la période de semis
	const displayPeriodeSemis = () => {
		return ((isActive || enableMovingRow || movingTask || resizingTask) && assolement.semisDebut)
			&& !isAssolementValide
			&& LocalDate.fromAPI(campagne.dateFinVisible.endOf('month').format('YYYY-MM-DD')).gte(LocalDate.fromAPI(assolement.semisFin))
			&& LocalDate.fromAPI(campagne.dateDebutVisible.format('YYYY-MM-DD')).lte(LocalDate.fromAPI(assolement.semisDebut))
	}

	return (
		<div
			className={classnames(`moveItem-assolement-${assolement.id}`, { 'moveItemActive': movingTask || enableMovingRow || resizingTask }, classes.containerMoveItem)}
			style={containerOffset}>
			<Rnd
				className={classnames(classes.row, { [classes.moving]: enableMovingRow })}
				size={{ width: gridDimension.width * rowSize, height: gridDimension.height }}
				position={{ x: x - containerOffset.left, y: isAssolementValide ? gridDimension.height : 0 }}
				dragGrid={[gridDimension.width, gridDimension.width]}
				{...dragConfig}
				bounds="parent"
				enableResizing={{
					top: false,
					right: false,
					bottom: false,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false
				}}
			>
				{/* On place la période de semis */}
				{displayPeriodeSemis() &&
				<AssolementRowItem
					campagne={campagne}
					gridDimension={gridDimension}
					itemWidth={gridDimension.width * Math.ceil(moment(assolement.semisFin).diff(moment(assolement.semisDebut), 'weeks', true))}
					etape={{ type: ETAPE_TYPE.NM_PERIODE_SEMIS }}
					x={gridDimension.width * (LocalDate.fromAPI(assolement.semisDebut)._diff(LocalDate.fromAPI(campagne.dateDebutVisible.format(DateFormat.YYYY_MM_DD)), 'weeks')) - x}
				/>}


				{/* On place l'écart entre la première étape et la dernière */}
				{(!isAssolementFertilisation && !movingTask && !resizingTask) && <AssolementRowItem
					campagne={campagne}
					gridDimension={gridDimension}
					itemWidth={gridDimension.width * Math.ceil(moment(lastEtape.dateFin).diff(moment(assolementEtapeList[0].dateDebut), 'weeks'))}
					etape={{ type: enableMovingRow ? ETAPE_TYPE.NM_DUREE_ASSOLEMENT_SELECTED : ETAPE_TYPE.NM_DUREE_ASSOLEMENT }}
					x={0}
					assolement={assolement}
					setEnableMovingRow={() => {
						setEnableMovingRow(true)
						resetActionsOnRowItem()
					}}
					rowIsMoving={enableMovingRow}
				/>}

				{assolementEtapeList.map(etape => {
					// On place l'item par rapport à la row (qui démarre par début semis)
					let position = gridDimension.width * moment(etape.dateDebut).diff(moment(startRowDate), 'weeks')
					// La taille de l'item dépend du nombre de semaines entre le début et la fin de étape
					let width = gridDimension.width * Math.ceil(moment(etape.dateFin).diff(moment(etape.dateDebut), 'weeks', true))
					const taskIsMoving = etape.id === movingTask
					const taskIsResizing = etape.id === resizingTask

					// On regarde si l'on doit déplacer notre étape car une étape pousse celle-ci
					if (!taskIsMoving && !taskIsResizing && xTask && (movingTask || resizingTask)) {
						position += moveTaskBehind(etape, position, assolement.assolementEtapeList, initXTask, xTask, xTaskWidth, (movingTask || resizingTask), gridDimension.width)
					}

					let newPosition = taskIsMoving ? xTask : position
					if (taskIsResizing) {
						// Dans le cas du déplacement à gauche, on décale la position initiale
						newPosition = position - (xTaskWidth.direction === 'left' ? xTaskWidth.width : 0)
						width = width + xTaskWidth.width
					}

					return <AssolementRowItem
						campagne={campagne}
						key={`assolement-row-${assolement.id}-etape-${etape.id}`}
						gridDimension={gridDimension}
						itemWidth={width}
						etape={etape}
						assolement={assolement}
						initXTask={initXTask}
						x={newPosition}
						rowIsMoving={enableMovingRow}
						setEnableMovingRow={() => {
							setEnableMovingRow(true)
							resetActionsOnRowItem()
						}}
						taskIsMoving={taskIsMoving}
						taskIsResizing={taskIsResizing}
						setMovingTask={taskId => handleMovingTask(taskId, position)}
						setResizingTask={taskId => handleResizingTask(taskId, position)}
						setXTask={setXTask}
						setXTaskWidth={setXTaskWidth}
						isAssolementValide={isAssolementValide}
						isCompare={isCompare}
					/>
				})}
			</Rnd>
		</div>
	)
}

const mapStateToProps = (state, { assolementId }) => ({
	assolement: getAssolementById(state, assolementId),
	isActive: isActiveRow(state, assolementId)
})

const actions = {
	editPeriod: editAssolementPeriod,
	editEtapeListPeriod,
	getNotifications
}

AssolementRow.propTypes = {
	campagne: PropTypes.object,
	assolementId: PropTypes.string,
	assolement: PropTypes.object,
	etat: PropTypes.string,
	gridDimension: PropTypes.object,
	isActive: PropTypes.bool,
	isCompare: PropTypes.bool,
	isMovingRowItk: PropTypes.bool,
	editPeriod: PropTypes.func,
	resetMoveRow: PropTypes.func,
	editEtapeListPeriod: PropTypes.func,
	classes: PropTypes.object
}

const AssolementRowConnected = compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(AssolementRow)

export default React.memo(AssolementRowConnected, (prev, next) => {
	return prev.assolement === next.assolement && prev.campagne === next.campagne && prev.gridDimension.width === next.gridDimension.width
})
