import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {compose} from 'redux'
import {colors} from '../../../../../utils/constants'
import {getRepartitionFamille, getTauxRemplissage} from '../../service/besoinsProductionApi'
import {Pie} from 'react-chartjs-2'
import Rainbow from 'rainbowvis.js'
import StoreIcon from '@material-ui/icons/Store'
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl'
import SpringDataTableWithLoader from '../../../../../components/layout/SpringDataTableWithLoader'
import StatBloc from '../../../../common/stat/StatBloc'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation'
import TableChartIcon from '@material-ui/icons/TableChart'
import {injectZoneSurfaceValueList} from "../../../../common/valueLists/valueListInjectors";

const styles = () => getStyles({
	container: {
		marginTop: 20,
		marginLeft: '2%',
		marginRight: '2%'
	},
	title: {
		color: colors.primary,
		fontWeight: 'bold',
		marginTop: 20,
		marginBottom: 20,

		'& svg': {
			paddingLeft: 10,
			paddingRight: 20
		}
	},
	familleListe: {
		height: 400,
		fontSize: 14
	},
	surface: {
		'& .MuiTableCell-body': {
			height: 40,
			fontSize: 14
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073',

			'& span': {
				fontSize: 12,
				color: colors.placeholder,
				paddingLeft: 5,
				paddingRight: 5
			}
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		}
	}
})

const getPourcentageCouleur = (pourcentage) => {
	if (pourcentage >= 75) {
		return colors.valid
	} else if (pourcentage >= 50) {
		return colors.warning
	} else {
		return colors.error
	}
}

const DATATABLE_KEYS = {
	SEMAINES: 'nbSemaines',
	PERIODE: 'debutPeriode',
	PLANCHES: 'nbPlanches',
	ZONE: 'zone'
}

const getDegradeDeVert = (numberOfColors) => {
	const colors = []
	const rainbow = new Rainbow()
	rainbow.setSpectrum('#223f30', '#67c587', '#eaf6ed')
	rainbow.setNumberRange(1, numberOfColors > 1 ? numberOfColors : 2);
	for (let i = 1; i <= numberOfColors; i++) {
		const hexColour = rainbow.colourAt(i)
		colors.push(`#${hexColour}`)
	}
	return colors
}

const OngletResultatTauxRemplissage = ({classes, campagne, zoneSurfaceList}) => {
	const [tauxDeRemplissage, setTauxDeRemplissage] = React.useState(0)
	const [repartitionFamille, setRepartitionFamille] = React.useState({})
	const idCampagne = campagne.id
	const intl = useIntl()

	React.useEffect(() => {
		if (idCampagne) {
			getTauxRemplissage(idCampagne).then(setTauxDeRemplissage)
			getRepartitionFamille(idCampagne).then(setRepartitionFamille)
		}
	}, [campagne])

	return (
		<Grid container item direction="column" alignItems="center" spacing={4} className={classes.container}>
			<Grid container item direction="row" justify="center">
				<StatBloc
					Icon={StoreIcon}
					getPourcentageCouleur={getPourcentageCouleur}
					value={tauxDeRemplissage}
					idMessage="besoinsProduction.resultat.stats.tauxRemplissageFerme"
					isPourcentage
				/>
			</Grid>
			<Grid container item spacing={4}>
				<Grid container item xs={12} md={12} lg={6} direction="column">
					<Grid item container className={classes.title}>
						<TableChartIcon/>
						<FormattedMessage id="besoinsProduction.resultat.tauxRemplissage.repartitionFamille"/>
					</Grid>
					<Grid container item>
						<Grid container item xs={7} justify="center" alignItems="center">
							<div style={{width: 300}}>
								{Object.keys(repartitionFamille).length > 0 && <Pie
									data={{
										labels: [...Object.keys(repartitionFamille), intl.formatMessage({id: 'besoinsProduction.resultat.tauxRemplissage.empty'})],
										datasets: [
											{
												data: [...Object.values(repartitionFamille), 100 - Object.values(repartitionFamille).reduce((r1, r2) => r1 + r2, 0)],
												backgroundColor: [...getDegradeDeVert(Object.values(repartitionFamille).length), colors.empty],
												borderColor: 'white',
												borderWidth: 1
											}
										]
									}}
									options={{
										plugins: {
											legend: {
												display: false
											}
										}
									}}
								/>}
							</div>
						</Grid>
						<Grid container item xs={5} className={classes.familleListe}>
							{
								Object.entries(repartitionFamille).map(v => (
									<Grid container item>
										<Grid item xs={9}>
											<Typography variant="body2">{v[0]}</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body2"><b>{v[1]} %</b></Typography>
										</Grid>
									</Grid>
								))
							}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={12} lg={6} direction="column" className={classes.surface}>
					<Grid item container className={classes.title}>
						<NotListedLocationIcon/>
						<FormattedMessage id="besoinsProduction.resultat.tauxRemplissage.surfacesSansCultures"/>
					</Grid>
					<SpringDataTableWithLoader
						nom="SurfacesSansCultureTable"
						apiUrl={`/besoinsProduction/surfacesSansCulture/${idCampagne}`}
						defaultPageSize={20}
						headers={[
							{
								key: DATATABLE_KEYS.SEMAINES,
								name: <FormattedMessage id="besoinsProduction.resultat.table.headers.nbSemaines"/>,
								sortable: true,
								tableHeaderColumnProps: {
									align: 'center'
								},
								render: row => <>{row.nbSemaines} <span><FormattedMessage id="besoinsProduction.unites.semaines"/></span></>
							},
							{
								key: DATATABLE_KEYS.PERIODE,
								name: <FormattedMessage id="besoinsProduction.resultat.table.headers.periode"/>,
								sortable: true,
								tableHeaderColumnProps: {
									align: 'center'
								},
								render: row => <>
									<span><FormattedMessage id="besoinsProduction.date.debut"/></span>
									<FormattedDate value={row.debutPeriode}/>
									<span><FormattedMessage id="besoinsProduction.date.fin"/></span>
									<FormattedDate value={row.finPeriode}/>
								</>
							},
							{
								key: DATATABLE_KEYS.PLANCHES,
								name: <FormattedMessage id="besoinsProduction.resultat.table.headers.nbPlanches"/>,
								sortable: true,
								tableHeaderColumnProps: {
									align: 'center'
								},
								render: row => <>{row.nbPlanches} <span><FormattedMessage id="besoinsProduction.unites.planches" values={{planche: row.nbPlanches}}/></span></>
							},
							{
								key: DATATABLE_KEYS.ZONE,
								name: <FormattedMessage id="besoinsProduction.resultat.table.headers.zone"/>,
								sortable: true,
								tableHeaderColumnProps: {
									align: 'center'
								},
								render: row => zoneSurfaceList && zoneSurfaceList.find(z => z.code === row.zone.trim()).label
							}
						]}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default compose(
	injectZoneSurfaceValueList,
	withStyles(styles)
)(OngletResultatTauxRemplissage)