import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import classnames from 'classnames'

const style = theme => getStyles({
    link: {
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    disabled: {
        color: theme.palette.placeholder,
        cursor: 'default'
    }
})

/**
 * Link pour naviguer dans les différentes pages
 * Ce composant reprend les paramètres utilisés par Link de react-router (to, query, state)
 */
const Link = ({ to, state, query, onClick, children, classes, goTo, className, disabled, isReturn }) => (
    <a href={to} className={classnames(classes.link, { [classes.disabled]: disabled },  className)} onClick={(e) => {
	    e.preventDefault()
        if (!disabled) {
	        onClick(e)
	        if (to && !isReturn) {
	            goTo(to, query, state)
	        }
        }
    }}>
   {children}
   </a>
)

const actions = {
    goTo: (pathname, search, state) => dispatch => dispatch(push({
        pathname,
        search,
        state
    }))
}

Link.defaultProps = {
    to: undefined,
    query: '',
    state: {},
    className: '',
    onClick: () => {},
    isReturn: false
}

Link.propTypes = {
    to: PropTypes.string,
    query: PropTypes.string,
    state: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
	classes: PropTypes.object,
    isReturn: PropTypes.bool, // Variable pour empêcher l'interprétation du to dans le cas d'un goBack
    goTo: PropTypes.func.isRequired
}

export default compose(
    connect(null, actions),
    withStyles(style)
)(Link)
