import React from 'react'
import { dataTableValueSelectors } from 'isotope-client'
import NoResultPage from '../../../../../components/NoResultPage'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {colors, GESTION_RESSOURCES_TABLE} from '../../../../../utils/constants'
import {compose} from 'redux'
import {useGestionRessourceContext} from '../../GestionRessourceProvider'
import {makeStyles} from '@material-ui/styles'
import SpringDataTableWithLoader from '../../../../../components/layout/SpringDataTableWithLoader'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ClearIcon from '@material-ui/icons/Clear'
import BlockIcon from '@material-ui/icons/Block'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PersonIcon from '@material-ui/icons/Person'
import {Grid} from '@material-ui/core'
import {LocalDateTime} from '../../../../../utils/date/local-date-time'
import PropTypes from 'prop-types'
import {replace} from 'connected-react-router'
import {formatDate} from '../../utils/ressourceUtils';

const useStyles = makeStyles(theme => ({
	ressources: {
		width: '12,5%',
		height: 40,
		borderLeft: 'solid 1px lightgrey',
		borderRight: 'solid 1px lightgrey',
		fontSize: 14,
		paddingLeft: 19
	},
	error: {
		marginBottom: '0px',
		height: '100%',
		width: '28.58px',
		color: colors.error,
		paddingRight: '20px'
	},
	table: {
		borderCollapse: 'collapse',
		'& [class*="MuiTableCell-root"]': {
			height: 40,
			borderLeft: `1px solid ${theme.palette.border}`,
			borderRight: `1px solid ${theme.palette.border}`
		},
		'& [class*="MuiTableRow-head"]': {
			backgroundColor: theme.palette.primary.light
		},
		'&  [class*="MuiTableCell-body"]:first-child': {
			display: 'none'
		},
		'&  [class*="MuiTableCell-head"]:first-child': {
			display: 'none'
		},
		'& [class*="MuiTableCell-head"]': {
			padding: '5 5px',
			lineHeight: '1.3',
			backgroundColor: theme.palette.primary.light
		},
		'& th': {
			textAlign: 'left'
		},
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		minWidth: '100%',
		maxWidth: '100%',
		overflow: 'auto'
	}
}))

const horaireRow = (row, Icon, messageId) => {
	return (
		<Grid container alignItems="center">
			<Grid item>
				<Icon style={{ color: colors.primary, paddingRight: 5 }}/>
			</Grid>
			<Grid item>
				<Grid container direction="column">
					<Grid item>
						<FormattedMessage id={`gestionRessource.table.schedule.${messageId}`} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
};

const RessourcesTable = ({ selectedRow, goRessourceDetail }) => {
	const { selectedContrats, selectedDisponibilite, textInput } = useGestionRessourceContext()
	const classes = useStyles()

	const dynamicFilters = { contrats: [...selectedContrats],  disponibilites: [...selectedDisponibilite], textInput: textInput };

	const formatDateToCompare = (dateString) => {
		const [year, month, day] = dateString.split('-');
		return `${day}/${month}`;
	};

	const isToday = (row) => {
		const today = LocalDateTime.fromAPINotNullOrEpoch(new Date().toISOString().split('T')[0]);

		return row.assoRessourceIndisponibleLightBean.some(item => {
			const dateDebut = LocalDateTime.fromAPINotNullOrEpoch(item?.dateDebutIndispo);
			const dateFin = LocalDateTime.fromAPINotNullOrEpoch(item?.dateFinIndispo);

			return dateDebut.lte(today) && (dateFin.gt(LocalDateTime.fromAPINotNullOrEpoch(null)) ? dateFin.gte(today) : true);
		});
	}

	const isHaveAnActualContrat = (row) => {
		const today = LocalDateTime.fromAPINotNullOrEpoch(new Date().toISOString().split('T')[0]);
		const finContratDate = LocalDateTime.fromAPINotNullOrEpoch(row.ressourceContrat.finContrat)

		return !!(finContratDate.lt(today) && row.ressourceContrat.finContrat != null)
	}

	// Permet de retourner une date de fin à afficher en fonction de si elle est indisponible ou non pour chaque ressource
	const getDateFinIndispo = (row) => {
		const today = LocalDateTime.fromAPINotNullOrEpoch(new Date().toISOString().split('T')[0]);

		const currentIndispo = row.assoRessourceIndisponibleLightBean.find(item => {
			const dateDebut = LocalDateTime.fromAPINotNullOrEpoch(item?.dateDebutIndispo);
			const dateFin = LocalDateTime.fromAPINotNullOrEpoch(item?.dateFinIndispo);

			return (
				dateDebut.lte(today) && dateFin.gt(today)
			);
		});

		return currentIndispo ? formatDateToCompare(currentIndispo.dateFinIndispo) : ' - ';
	};

	const statusRow = (row) => {
		const dateFin = getDateFinIndispo(row);

		return (
			<Grid container alignItems="center">
				<Grid item>
					{((row?.assoRessourceIndisponibleLightBean.length > 0 && isToday(row)) || isHaveAnActualContrat(row))
						? <BlockIcon style={{ color: colors.error, paddingRight: 5 }} />
						: row?.active
							? <VerifiedUserIcon style={{ color: colors.valid, paddingRight: 5 }} />
							: <ClearIcon style={{ color: colors.alerte, paddingRight: 5 }} />
					}
				</Grid>
				<Grid item>
					<Grid container direction="column">
						<Grid item>
							{ row?.active
								? <FormattedMessage id={`gestionRessource.filter.statut.${((row?.assoRessourceIndisponibleLightBean.length > 0 && isToday(row)) || isHaveAnActualContrat(row)) ? 'indisponible' : 'disponible'}`} />
								: <FormattedMessage id="gestionRessource.table.headers.inactive" />
							}
						</Grid>
						{((row?.assoRessourceIndisponibleLightBean.length > 0 && isToday(row)) || isHaveAnActualContrat(row)) &&
							<Grid item>
								{
									isHaveAnActualContrat(row)
										? <FormattedMessage id="gestionRessource.table.headers.finContrat" />
										: <FormattedMessage id="gestionRessource.table.headers.dateFin" values={{ dateFin: dateFin }} />
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		);
	};


	React.useEffect(() => {
		if (selectedRow) {
			goRessourceDetail(selectedRow.id)
		}
	}, [selectedRow])

	return (
		<div className={classes.leftContainer}>
			<SpringDataTableWithLoader
				nom={GESTION_RESSOURCES_TABLE}
				apiUrl={'/ressource'}
				defaultPageSize={50}
				noResultFragment={<NoResultPage/>}
				filters={dynamicFilters}
				tableProps={{
					stickyHeader: true,
					className: classes.table
				}}
				selectable
				headers={[
					{
						key: 'nom',
						name: <FormattedMessage id="gestionRessource.table.headers.nom"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <>{row.nom}</>
					},
					{
						key: 'prenom',
						name: <FormattedMessage id="gestionRessource.table.headers.prenom"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <>{row.prenom}</>
					},
					{
						key: 'codeProfil',
						name: <FormattedMessage id="gestionRessource.table.headers.typeRessource"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <FormattedMessage id={'gestionRessource.table.codeProfil.' + row.role.toUpperCase()}/>
					},
					{
						key: 'typeContrat',
						name: <FormattedMessage id="gestionRessource.table.headers.typeContrat"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <>{row.ressourceContrat.typeContrat}</>
					},
					{
						key: 'debut',
						name: <FormattedMessage id="gestionRessource.table.headers.debut"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <>{formatDate(row.ressourceContrat?.debutContrat)}</>
					},
					{
						key: 'fin',
						name: <FormattedMessage id="gestionRessource.table.headers.fin"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => <>{formatDate(row.ressourceContrat?.finContrat) || '-'}</>
					},
					{
						key: 'statut',
						name: <FormattedMessage id="gestionRessource.table.headers.statut"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => statusRow(row)
					},
					{
						key: 'horaire',
						name: <FormattedMessage id="gestionRessource.table.headers.horaire"/>,
						tableHeaderColumnProps: {className: classes.ressources},
						render: row => horaireRow(row, row?.ressourceContrat?.scheduleFromFerme ? StorefrontIcon : PersonIcon, row?.ressourceContrat?.scheduleFromFerme ? 'ferme' : 'ressource')
					}
				]}
			/>
		</div>
	)
}

RessourcesTable.propTypes = {
	selectedRow: PropTypes.object,
}

const mapStateToProps = (state) => {
	const selectedRows = dataTableValueSelectors.getDataTableSelectedRows(state)(GESTION_RESSOURCES_TABLE);
	return {
		selectedRow: selectedRows?.length > 0 ? selectedRows[0] : null
	};
};

const actions = {
	goRessourceDetail: (ressourceId) => dispatch => dispatch(replace(`/detail-ressource/${ressourceId}`)),
}

export default compose(
	connect(mapStateToProps, actions)
)(RessourcesTable)
