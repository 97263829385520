import {withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {change, formValueSelector, initialize, reduxForm, reset} from 'redux-form'
import Comment from '../../../../../components/Comment'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import ItineraireTechniqueIcon from '../../../../../components/icon/ItineraireTechniqueIcon'
import PlaceIcon from '../../../../../components/icon/PlaceIcon'
import {getCurrentCampagne} from '../../../../common/campagne/campagneSelector'
import InfoCulture from '../../../../common/information/InfoCulture'
import {getPlanchesByType} from '../../services/planningApi'
import Detail from '../detail/Detail'
import ItineraireTechniqueBase from '../detail/ItineraireTechniqueBase'
import ItineraireForm from './component/ItineraireForm'
import LocalisationMulti from './LocalisationMulti'
import {fields} from './NouvelleCulture'
import {colors} from '../../../../../utils/constants'
import PropTypes from 'prop-types'
import {listeCulturesConcernees} from '../../../../../utils/utils'


const styles = () => getStyles({
	form: {
		width: '100%',
		margin: '1.5rem'
	},
	localisation: {
		alignItems: 'center'
	},
	cultureSection: {
		margin: '0 10px 25px'
	},
	itkSection: {
		margin: 10
	},
	detail: {
		margin: 0
	},
	edit: {
		margin: 0,
		'& > div': {
			padding: 0,
			paddingRight: 10
		},
		'&:last-child': {
			paddingRight: 0
		}
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.planches) {
		errors.planches = { id: `global.errors.mandatory` }
	}
	return errors
}

const DupliquerCulture = ({
	selectedAssolements,
	handleSubmit,
	classes,
	localisationsDisponibles
}) => {

	if (selectedAssolements.length === 1) {
		const { culture } = selectedAssolements[0]
		const cultureItineraireTechnique = selectedAssolements[0]
		const sections = [
			{
				icon: <CultureIcon />,
				title: culture.nom,
				path: <InfoCulture culture={culture} />,
				content: <em>{culture.cultivar}</em>,
				comment: <Comment comment={culture.commentaire} />
			},
			{
				icon: <ItineraireTechniqueIcon />,
				title: <FormattedMessage id="planning.itineraireTechnique.title" />,
				content: <>
					<ItineraireTechniqueBase assolement={cultureItineraireTechnique} withNbGraines />
					<ItineraireForm classes={{ edit: classes.edit }} assolement={cultureItineraireTechnique} editItk
					                formName={DUPLICATE_CULTURE_FORM} />
				</>
			},
			{
				icon: <PlaceIcon />,
				title: <FormattedMessage id="planning.cultureForm.fertilization.localisation" />,
				content: <LocalisationMulti
					form={DUPLICATE_CULTURE_FORM}
					plancheApiFunction={(value) => getPlanchesByType(cultureItineraireTechnique?.zone?.split(';'), cultureItineraireTechnique?.campagne?.idFerme, value)} />
			}
		]
		return (
			<form onSubmit={handleSubmit} className={classes.form}>
				<Detail sections={sections} classes={{ detail: classes.detail }} />
			</form>
		)
	} else {

		const sections = [
			{
				icon: <CultureIcon />,
				title: <FormattedMessage id="listAssolement.forms.h2.dupliquerCultures" />,
				content: <ul>{listeCulturesConcernees(selectedAssolements)}</ul>
			}
		]

		if (localisationsDisponibles.length !== 0) {
			sections.push({
				icon: <PlaceIcon />,
				title: <FormattedMessage id="listAssolement.forms.h2.nouvelleLocalisation" />,
				content: <LocalisationMulti
					form={DUPLICATE_CULTURE_FORM}
					plancheApiFunction={(value) => getPlanchesByType(localisationsDisponibles, selectedAssolements[0].culture.idFerme, value)} />
			})
		} else {
			sections.push({
				icon: <PlaceIcon />,
				title: <FormattedMessage id="listAssolement.forms.h2.nouvelleLocalisation" />,
				content: <FormattedMessage style={{ color: colors.primary }} id="listAssolement.forms.h2.pasDeLocalisation" />
			})
		}

		return (
			<form onSubmit={handleSubmit} className={classes.form}>
				<Detail sections={sections} classes={{ detail: classes.detail }} />
			</form>
		)
	}
}

DupliquerCulture.propType = {
	selectedAssolements: PropTypes.array,
	localisationsDisponibles: PropTypes.array,
	classes: PropTypes.object
}

export const DUPLICATE_CULTURE_FORM = 'DUPLICATE_CULTURE_FORM'
const selector = formValueSelector(DUPLICATE_CULTURE_FORM)

const mapStateToProps = (state) => ({
	cultureValue: selector(state, fields.CULTURE),
	campagne: getCurrentCampagne(state)
})

const actions = {
	change: change,
	initialize: initialize,
	reset: reset
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: DUPLICATE_CULTURE_FORM,
		destroyOnUnmount: true,
		validate
	})
)(DupliquerCulture)
