import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { getStyles } from 'isotope-client'
import { change, formValueSelector, initialize, reduxForm, reset } from 'redux-form'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { getAllCultures, getAllPlanches, getItkDetails, getItkFromPlancheAndCulture } from '../../services/planningApi'
import PropTypes from 'prop-types'
import PlancheForm from './component/PlancheForm'
import CultureForm from './component/CultureForm'
import ItineraireForm from './component/ItineraireForm'
import { getValidStartAndEndDate, isInvalidDateCampagne } from '../../utils/utils'
import { CHOIX_DATES, ETAPE_TYPE, TYPE_PLANCHE } from '../../../../../utils/constants'

export const fields = {
	LOCALISATION: 'localisation',
	CULTURE: 'culture',
	ITK: 'itk',
	CHOIX_DATE: 'choixDate',
	DATE: 'date',
	ESPACEMENT: 'espacement',
	DENSITE: 'densite',
	NB_RANGS: 'nbRangs',
	// Champs utiles au validate
	PLANCHES: 'planches',
	ITKS: 'itks',
	ITK_DETAIL: 'itk_detail'
}

const styles = () => getStyles({
	form: {
		width: '100%',
		margin: 15
	},
	localisation: {
		alignItems: 'center'
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

// Validation de la date selon l'itk, la campagne et le type de date (semi/récolte)
const validDate = (date, itkSelected, itkDetails, campagne, choixDate) => {
	if (!date) {
		return `${formattedMessageMenuBase}.errors.invalidDate`
	}

	// Choix de l'étape de timeline
	let etapeToSearch = [ETAPE_TYPE.SEMI_DIRECT, ETAPE_TYPE.IMPLANTATION]
	if (choixDate !== CHOIX_DATES.SEMIS) {
		etapeToSearch = [ETAPE_TYPE.RECOLTE]
	}
	const etapeFound = itkDetails.find(itk => itk.idItk === itkSelected.id && etapeToSearch.includes(itk.typeEtape)) || {}

	const nbWeek = (etapeFound.timeline || 0) + 1
	return isInvalidDateCampagne(campagne, choixDate, date, nbWeek) ? `${formattedMessageMenuBase}.errors.date` : undefined
}

const validate = (values, { campagne }) => {
	const errors = {}
	const requiredFields = [
		fields.LOCALISATION,
		fields.CULTURE,
		fields.ITK,
		fields.ESPACEMENT
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})

	// Selon le type de planche et le choix de date, nous devons valider la période
	if (!!values.choixDate) {
		const itkSelected = values.itks && values.itk && values.itks.find(itk => itk.id === values.itk)
		if (itkSelected) {
			const invalidDate = validDate(values.date, itkSelected, values.itk_detail, campagne, values.choixDate)
			if (invalidDate) {
				errors[fields.DATE] = { id: invalidDate }
			}
		}

		const plancheSelected = values.planches && values.planche && values.planches.find(planche => planche.id === values.planche)
		if (plancheSelected) {
			if (plancheSelected.typePlanche === TYPE_PLANCHE.PERMANENTE) {
				errors[fields.NB_RANGS] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
			} else {
				errors[fields.DENSITE] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
			}
		}
	}

	if (values[fields.ESPACEMENT] && values[fields.ESPACEMENT] <= 0) {
		errors[fields.ESPACEMENT] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	if (values[fields.NB_RANGS] && values[fields.NB_RANGS] <= 0) {
		errors[fields.NB_RANGS] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	if (values[fields.DENSITE] && values[fields.DENSITE] <= 0) {
		errors[fields.DENSITE] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	return errors
}

export const getAutocompleteOptions = (values, isItk = false) => {
	const options = []
	values.forEach(value => {
		options.push(formatOption(value, isItk))
	})
	return options
}

export const formatOption = (value, isItk) => {
	return { label: value.nom ? value.nom : value.designation, code: value.id, itk: isItk ? value : null }
}

const NouvelleCulture = ({ plancheValue, cultureValue, change, initialize, reset, handleSubmit, campagne, classes }) => {
	// Listing pour saisie du formulaire
	const [planches, setPlanches] = React.useState([])
	const [cultures, setCultures] = React.useState([])
	const [itks, setItks] = React.useState([])
	const [itkDetails, setItkDetails] = React.useState([])

	// Information sur les éléments sélectionnés par autocomplete
	const plancheSelected = planches && plancheValue && planches.find(planche => planche.id === plancheValue)

	const resetItkForm = (fieldName) => {
		reset()
		// Dans le cas du reset de culture, on conserve la localisation
		if (fieldName === fields.CULTURE) {
			change(fields.LOCALISATION, plancheSelected.id)
		}
		change(fields.PLANCHES, planches)
		change(fields.ITKS, itks)
		change(fields.ITK_DETAIL, itkDetails)
		setItks([])
	}

	React.useEffect(() => {
		getAllPlanches(campagne.idFerme).then(res => {
			setPlanches(res)
			change(fields.PLANCHES, res)
		})
		getAllCultures(campagne.idFerme).then(setCultures)
		getItkDetails().then(res => {
			setItkDetails(res)
			change(fields.ITK_DETAIL, res)
		})
	}, [])

	React.useEffect(() => {
		const planche = planches && plancheValue && planches.find(planche => planche.id === plancheValue)
		cultureValue && planche && getItkFromPlancheAndCulture(planche.zone, cultureValue)
			.then(itks => {
				const changedItks = itks.map(itk => getValidStartAndEndDate(itk, campagne))
				setItks(changedItks)
				change(fields.ITKS, changedItks)
			})
	}, [plancheValue, cultureValue])

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.localisation}>
				<PlancheForm resetItkForm={resetItkForm} planches={planches} formName={NEW_CULTURE_FORM_NAME}/>
			</Grid>
			{plancheValue && <Grid>
				<CultureForm resetItkForm={resetItkForm} cultures={cultures}/>
			</Grid>}
			{plancheValue && cultureValue && <Grid>
				<ItineraireForm planches={planches} itks={itks} itkDetails={itkDetails} formName={NEW_CULTURE_FORM_NAME}/>
			</Grid>}
		</form>
	)
}

NouvelleCulture.propTypes = {
	plancheValue: PropTypes.string,
	cultureValue: PropTypes.string,
	handleSubmit: PropTypes.func
}

export const NEW_CULTURE_FORM_NAME = 'NewCultureForm'
const selector = formValueSelector(NEW_CULTURE_FORM_NAME)

const mapStateToProps = (state) => ({
	plancheValue: selector(state, fields.LOCALISATION),
	cultureValue: selector(state, fields.CULTURE),
	campagne: getCurrentCampagne(state)
})

const actions = {
	change: change,
	initialize: initialize,
	reset: reset
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: NEW_CULTURE_FORM_NAME,
		destroyOnUnmount: true,
		validate
	})
)(NouvelleCulture)
