import React from 'react'
import HeaderRessources from './ressources/HeaderRessources'
import RessourcesTable from './ressources/RessourcesTable'
import {Grid} from '@material-ui/core'

const RessourcesOnglet = () => {
	const ref = React.createRef()

	return (
		<Grid style={{ paddingInline: 50, paddingBlock: 30 }}>
			<HeaderRessources reference={ref} />
			<RessourcesTable reference={ref} />
		</Grid>
	)

}

export default RessourcesOnglet