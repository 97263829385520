import React from 'react'
import {Grid} from '@material-ui/core'
import {ONGLET_RESULTAT_SOLVEUR} from '../../../../../utils/constants'
import {FormattedMessage, useIntl} from 'react-intl'
import OngletResultatEcartCulture from './OngletResultatEcartCulture'
import OngletResultatTauxRemplissage from './OngletResultatTauxRemplissage'
import StyledTabs from '../../../../../components/layout/tab/StyledTabs'
import StyledTab from '../../../../../components/layout/tab/StyledTab'
import Loader from '../../../../../components/layout/Loader'
import {setActiveCampagne} from '../../../../common/campagne/campagneAction'
import {connect} from 'react-redux'
import {getCurrentCampagne} from '../../../../common/campagne/campagneSelector'
import {getStyles} from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import {compose} from 'redux'
import Button from '../../../../../components/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import {exportEcartProduction} from '../../service/besoinsProductionApi';
import {checkExportStateUntilFound} from "../../../../../utils/exportUtils";
import {useSnackbar} from "../../../../../components/layout/snackbar/SnackbarContext";

const styles = () => getStyles({
	tabs: {
		borderBottom: '0.5px solid lightgray',
		marginLeft: '1%',
		marginRight: '1%',
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: '98%'
	},
	exportBtn: {
		minWidth: 300,
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: 'auto',
		marginBottom: 'auto'
	}
})

const OngletResultat = ({campagnes, campagne, setActiveCampagne, classes}) => {
	const [indexCampagne, setIndexCampagne] = React.useState(0)
	const [indexBloc, setIndexBloc] = React.useState(0)
	const intl = useIntl()
	const {snackSuccess, snackError} = useSnackbar()

	React.useEffect(() => {
		if (campagne && campagne.id) {
			setIndexCampagne(campagnes.findIndex(element => element.id === campagne.id))
		} else {
			const campagne = campagnes[0]
			setActiveCampagne(campagne)
		}
	}, [])

	const changeCampagne = (index) => {
		setIndexCampagne(index)
		const campagne = campagnes[index]
		setActiveCampagne(campagne)
	}
	const onExportEcartProductionClick = React.useCallback(() => {
		exportEcartProduction()
			.then(checkExportStateUntilFound(snackSuccess))
			.catch(() => snackError({id: 'snackbar.error.exportEcartProduction'}))
	}, [snackError, snackSuccess])

	return (
		<>
			{campagne ? <Grid container direction="column">
				<Grid item direction="row" justify="space-between" className={classes.tabs}>
					<StyledTabs value={indexCampagne} onChange={(_, index) => changeCampagne(index)} variant="scrollable">
						{campagnes.map(c => <StyledTab key={c.id} withoutBorder label={c.nom}/>)}
					</StyledTabs>
					<Button
						type="secondary"
						startIcon={<CloudUploadIcon/>}
						onClick={onExportEcartProductionClick}
						className={classes.exportBtn}
					>
						<FormattedMessage id="besoinsProduction.resultat.ecartParCulture.export"/>
					</Button>
				</Grid>
				<Grid container item direction="row">
					<Grid item xs={12} lg={2} md={3} style={{borderRight: '0.5px solid lightgray', marginTop: 30}}>
						<StyledTabs value={indexBloc} onChange={(_, index) => setIndexBloc(index)} orientation="vertical">
							{Object.values(ONGLET_RESULTAT_SOLVEUR)
								.map(onglet => <StyledTab key={onglet.code} className={classes.ongletVertical} withoutBorder label={intl.formatMessage({id: `besoinsProduction.resultat.tabs.${onglet.code}`})} orientation="vertical"/>)}
						</StyledTabs>
					</Grid>
					<Grid container item xs={12} lg={10} md={9}>
						{indexBloc === ONGLET_RESULTAT_SOLVEUR.ECART_CULTURE.order && <OngletResultatEcartCulture campagne={campagne}/>}
						{indexBloc === ONGLET_RESULTAT_SOLVEUR.TAUX_REMPLISSAGE.order && <OngletResultatTauxRemplissage campagne={campagne}/>}
						{/*indexBloc === ONGLET_RESULTAT_SOLVEUR.RESPECT_CONTRAINTES.order && <></>*/}
					</Grid>
				</Grid>
			</Grid> : <Loader/>}
		</>
	)
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

const actions = {
	setActiveCampagne
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(OngletResultat)