import { Grid } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import FormButton from '../../../../components/form/FormButton'
import { DialogContext } from '../../../../components/layout/dialog'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { ABSENCE_FORM, colors } from '../../../../utils/constants'
import { createAbsence, deleteAbsence, editAbsence } from '../gestionRessourceApi'
import { useGestionRessourceContext } from '../GestionRessourceProvider'
import PopinAbsence from '../popin/PopinAbsence'
import EventIcon from '@material-ui/icons/Event'
import PopinAbsenceDelete from '../popin/PopinAbsenceDelete'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'

export const injectAbsence = (WrappedComponent) => {
	const AbsenceInjector = (props) => {
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { setRefreshTab } = useGestionRessourceContext()
		const { snackSuccess, snackError } = useSnackbar()
		const onSubmit = (isEdit, absenceFonction, values) => {
			absenceFonction(values)
				.then(() => {
					snackSuccess({
						id: isEdit
							? 'gestionRessource.detailRessource.absences.snackbar.successEdit'
							: 'gestionRessource.detailRessource.absences.snackbar.successCreate'
					})
					// Ajout d'un Math.random pour permettre au tableau des abscences de se recharger grâce a la clé
					setRefreshTab(Math.random())
					closeDialog()
				})
				.catch(() => {
					snackError({
						id: isEdit
							? 'gestionRessource.detailRessource.absences.snackbar.errorEdit'
							: 'gestionRessource.detailRessource.absences.snackbar.errorCreate'
					})
				})
		}

		const onSubmitDelete = (values) => {
			deleteAbsence(values.id)
				.then(() => {
					snackSuccess({ id: 'gestionRessource.detailRessource.absences.snackbar.successDelete' })
					// Ajout d'un Math.random pour permettre au tableau des abscences de se recharger grâce a la clé
					setRefreshTab(Math.random())
					closeDialog()
				})
				.catch(() => {
					snackError({ id: 'gestionRessource.detailRessource.absences.snackbar.errorDelete' })
				})
		}

		const openPopinAbsence = (isEdit, ressourceId, initialValues) => {
			const onSubmitAction = isEdit
				? (values) => onSubmit(isEdit, editAbsence, values)
				: (values) => onSubmit(isEdit, createAbsence, { idRessource: ressourceId, ...values })

			openDialog(
				<Grid item container>
					<EventIcon style={{ color: colors.primary, marginRight: 10 }}/>
					<FormattedMessage id={isEdit
						? 'gestionRessource.detailRessource.absences.popin.titleEdit'
						: 'gestionRessource.detailRessource.absences.popin.titleAdd'}/>
				</Grid>,
				<PopinAbsence
					onSubmit={onSubmitAction}
					initialValues={isEdit ? initialValues : {}}
				/>,
				[
					<FormButton type="primary" formName={ABSENCE_FORM}>
						<FormattedMessage id={isEdit
							? 'actions.save'
							: 'actions.add'}/>
					</FormButton>
				]
			)
		}

		const openPopinAbsenceDelete = (initialValues) => {
			openDialog(
				<Grid item container>
					<DeleteSweepIcon style={{ color: colors.primary, marginRight: 10 }}/>
					<FormattedMessage id={'gestionRessource.detailRessource.absences.popin.titleDelete'}/>
				</Grid>,
				<PopinAbsenceDelete
					onSubmit={onSubmitDelete}
					initialValues={initialValues}
				/>,
				[
					<FormButton type="primary" formName={ABSENCE_FORM}>
						<FormattedMessage id={'actions.confirm'}/>
					</FormButton>
				]
			)
		}

		return <WrappedComponent {...props} openPopinAbsence={React.useCallback(openPopinAbsence, [])} openPopinAbsenceDelete={React.useCallback(openPopinAbsenceDelete, [])}/>
	}

	return (AbsenceInjector)
}