import { fetchFactory, urlUtils } from 'isotope-client'

/**
 * Récupération de la des groupes de cultures et des itks liés
 */
export const getGroupeList = (filters) => fetchFactory(urlUtils.buildUrlWithParams('/bo/groupeCulture', filters))

/**
 * Création du groupe d'assolement
 */
export const createGroupe = (values, idFerme) => fetchFactory('/bo/groupeCulture', {
	method: 'POST',
	body: JSON.stringify({ ...values, idFerme })
})

/**
 * Mise à jour du groupe d'assolement
 */
export const updateGroupe = values => fetchFactory('/bo/groupeCulture', {
	method: 'PUT',
	body: JSON.stringify(values)
})

/**
 * Suppression du groupe d'assolement
 */
export const deleteGroupe = idGroupe => fetchFactory(urlUtils.buildUrlWithParams('/bo/groupeCulture', { idGroupe }), {
	method: 'DELETE'
})

/**
 * Création de l'assolement d'un groupe
 */
export const createGroupeAssolement = (values) => fetchFactory('/bo/groupeCulture/assolement', {
	method: 'POST',
	body: JSON.stringify({ ...values, fromGroupCulture: true })
})

/**
 * Mise à jour de l'assolement d'un groupe
 */
export const updateGroupeAssolement = (values) => fetchFactory('/bo/groupeCulture/assolement', {
	method: 'PUT',
	body: JSON.stringify({ ...values, fromGroupCulture: true })
})

/**
 * Suppression de l'assolement d'un groupe
 */
export const deleteGroupeAssolement = idAssolement => fetchFactory(urlUtils.buildUrlWithParams('/bo/groupeCulture/assolement', { idAssolement }), {
	method: 'DELETE'
})
