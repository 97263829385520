import { push } from 'connected-react-router'
import { getUser as setUser } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { loadConfiguration } from '../../../common/configuration/configurationInjector'
import { getMe } from '../../../common/user/services/usersApi'
import * as actions from '../services/loginActions'
import { FormattedMessage } from 'react-intl'
import { useGoogleLogin } from '@react-oauth/google'
import Button from '@material-ui/core/Button'

const LoginForm = ({ configuration, logUser, getUser, setUser, redirect, nextPathname, setLoading }) => {
	const [userNotAuthorized, setUserNotAuthorized] = React.useState(false)

	const login = useGoogleLogin({
		onSuccess: (response) => postLogin(response),
		flow: 'auth-code',
		onError: (e) => console.log('Erreur lors de la connexion google', e),
		scope: "email profile https://www.googleapis.com/auth/drive openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
	});

	const postLogin = (response) => {
		setUserNotAuthorized(false)
		setLoading(true)
		setTimeout(() => {
			logUser({
				code: response.code
			})
				.then(getUser)
				.then(() => {
					getMe().then(me => {
						setUser({ id: me.id, accessRequest: me.accessRequest })
						redirect(nextPathname)
					})
				})
				.catch(() => {
					setUserNotAuthorized(true)
					setLoading(false)
				})
		}, 1000)
	}

	return (<>
			<div className="signGoogle">
				{configuration &&
					<Button
						onClick={login}
					>
						<FormattedMessage id="global.buttons.loginGoogle"/>
					</Button>
				}
			</div>

			{userNotAuthorized && <div style={{ color: 'red', display: 'flex', marginTop: 20 }}><FormattedMessage id="global.userNotAuthorized" /></div>}
		</>
	)
}

LoginForm.propTypes = {
	logUser: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
	setUser: PropTypes.func.isRequired,
	redirect: PropTypes.func.isRequired,
	configuration: PropTypes.object,
	nextPathname: PropTypes.string
}

const mapStateToProps = (state) => ({
	user: state.user
})

const dispatchToProps = {
	logUser: actions.logUser,
	getUser: actions.getUser,
	setUser,
	redirect: (nextPathname) => (dispatch) => {
		if (nextPathname) {
			dispatch(push(nextPathname))
		} else {
			dispatch(push('/accueil'))
		}
	}
}

export default compose(
	loadConfiguration,
	connect(mapStateToProps, dispatchToProps)
)(LoginForm)
