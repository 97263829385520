import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FormButton from '../../../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import DupliquerCulture, { DUPLICATE_CULTURE_FORM } from '../../../component/form/DupliquerCulture'
import { loadAssolement } from '../../assolement/assolementInjector'
import { duplicateCulture, duplicateListCulture } from '../../planningApi'
import { resetFilter } from '../../toolbar/toolbarAction'

export const injectCultureDuplicator = (WrappedComponent) => {
	const DuplicateCultureInjector = (props) => {
		const { openPanel, closePanel } = React.useContext(ActionPanelContext)
		const { campagne, resetFilter, refreshAssolements, getNotifications } = props
		const { snackError, snackSuccess } = useSnackbar()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const openCultureDuplicator = (assolement, refresh) => {

			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]
			const arrayZone = selectedAssolements.map((assol) => assol.planche.zone.split(';'))
			const localisationsDisponibles = _.intersection(...arrayZone)
			const selectedAssolementsID = selectedAssolements.map((assolement) => assolement.id)

			const onSubmit = (values, success, error) => {
				let promise
				if (selectedAssolements.length === 1) {
					promise = duplicateCulture(campagne.id, { ...values, planches: (values.planches || []).map(planche => planche.code), selectedAssolementsID })
				} else {
					promise = duplicateListCulture(campagne.id, { ...values, planches: (values.planches || []).map(planche => planche.code), selectedAssolementsID })
				}

				promise
					.then(() => {
						closePanel()
						snackSuccess({ id: success })
						getNotifications()
						refreshSolverInfosForCurrentCampagne()
						resetFilter()
						if (refresh) {
							refresh()
						}
						refreshAssolements()
					})
					.catch(() => snackError({ id: error }))
			}

			if (selectedAssolements.length === 1) {
				openPanel(
					<FormattedMessage id="planning.cultureForm.duplicate" />,
					<DupliquerCulture selectedAssolements={selectedAssolements}
					                  onSubmit={(values) => onSubmit(values, 'snackbar.update.duplicatedCulture', 'snackbar.error.duplicatedCulture')}
					/>,
					[<FormButton
						type="primary"
						formName={DUPLICATE_CULTURE_FORM}
					>
						<FormattedMessage id="actions.validate" />
					</FormButton>]
				)
			} else {
				openPanel(
					<FormattedMessage id="listAssolement.forms.headers.dupliquerCultures" />,
					<DupliquerCulture selectedAssolements={selectedAssolements}
					                  localisationsDisponibles={localisationsDisponibles}
					                  onSubmit={(values) => onSubmit(values, 'listAssolement.snackbar.ok.dupliquerCultures', 'listAssolement.snackbar.error.dupliquerCultures')} />,
					[<FormButton
						type="primary"
						formName={DUPLICATE_CULTURE_FORM}
						disabled={localisationsDisponibles.length === 0}
					>
						<FormattedMessage id="actions.validate" />
					</FormButton>]
				)
			}

		}
		return <WrappedComponent {...props} openCultureDuplicator={React.useCallback(openCultureDuplicator, [campagne])} />
	}

	DuplicateCultureInjector.propTypes = {
		campagne: PropTypes.object,
		resetFilter: PropTypes.func,
		refreshAssolements: PropTypes.func
	}

	const mapStateToProps = state => ({
		campagne: getCurrentCampagne(state)
	})

	const actions = {
		resetFilter,
		getNotifications
	}

	return compose(
		loadAssolement,
		connect(mapStateToProps, actions)
	)(DuplicateCultureInjector)
}
