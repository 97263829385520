import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FieldArray, getFormValues, reduxForm } from 'redux-form'
import FertilisationIcon from '../../../../../../components/icon/FertilisationIcon'
import PlaceIcon from '../../../../../../components/icon/PlaceIcon'
import { ETAPE_TYPE } from '../../../../../../utils/constants'
import { getCurrentCampagne } from '../../../../../common/campagne/campagneSelector'
import { getUnfertilizedPlanches } from '../../../services/planningApi'
import { FERTILIZATION_FORM } from '../../../utils/constant'
import SectionDetail from '../../detail/SectionDetail'
import FertilizationField from './FertilizationField'
import Localisation from './Localisation'

const validateForm = (values, { campagne, assolement }) => {
	const fertilizationValues = values[formFields.fertilization] || []
	const errors = { [formFields.fertilization]: {} }
	const fertiliationEtape = assolement.assolementEtapeList.filter(etape => etape.type === ETAPE_TYPE.FERTILISATION)

	fertilizationValues.forEach((values, index) => {
		errors[formFields.fertilization][index] = {
			date: validateInput(values, fertilizationValues, campagne, index, fertiliationEtape)
		}
	})

	return errors
}

const validateInput = (values, fertilizationValues, campagne, index, fertilizationEtape) => {
	const date = values[formFields.date]
	const comment = values[formFields.comment]

	if ((index === 0 || (comment && comment !== '')) && !date) {
		return {
			id: 'planning.cultureForm.fertilization.error.required'
		}
	}
	const dateValue = moment(date)

	const campagneDateDebut = moment(campagne.dateDebut)
	const campagneDateFin = moment(campagne.dateFin)

	if (dateValue.isBefore(campagneDateDebut) || dateValue.isAfter(campagneDateFin)) {
		return {
			id: 'planning.cultureForm.fertilization.error.outsideCampaign'
		}
	}

	for (let index in fertilizationEtape) {
		if (fertilizationEtape.hasOwnProperty(index)) {
			const etape = fertilizationEtape[index]
			if (moment(etape.dateDebut).isBefore(dateValue) && dateValue.isBefore(etape.dateFin)) {
				return {
					id: 'planning.cultureForm.fertilization.error.fertilizationAlreadyExist'
				}
			}
		}
	}
	for (let otherFertiIndex = 0; otherFertiIndex < index; otherFertiIndex++) {
		const error = {
			values: {
				firstValue: index + 1,
				secondValue: otherFertiIndex + 1
			}
		}
		const previousDate = moment(fertilizationValues[otherFertiIndex][formFields.date])
		if (previousDate.isoWeek() === dateValue.isoWeek() &&
			previousDate.year() === dateValue.year()) {
			error.id = 'planning.cultureForm.fertilization.error.wrongWeek'
			return error
		}
	}

	return undefined
}

export const formFields = {
	planches: 'planches',
	date: 'date',
	comment: 'comment',
	fertilization: 'fertilization',
	allPlanches: 'allPlanches'
}

const styles = () => getStyles({
	container: {
		margin: '1.5em',
		width: '100%'
	}
})


const FertilizationForm = ({ assolement, isUpdate, classes }) => {
	return (
		<div className={classes.container}>
			<SectionDetail
				icon={<PlaceIcon/>}
				title={isUpdate ? assolement.planche.nom :
					<FormattedMessage id="planning.cultureForm.fertilization.localisation"/>}
				content={<Localisation assolement={assolement} isUpdate={isUpdate} plancheApiFunction={getUnfertilizedPlanches}
				                       form={FERTILIZATION_FORM} allowToSelectAll/>}
			/>
			<SectionDetail
				icon={<FertilisationIcon/>}
				title={<FormattedMessage id="planning.cultureForm.fertilization.new"/>}
				content={
					<FieldArray
						name={formFields.fertilization}
						component={FertilizationField}
						isUpdate={isUpdate}
					/>
				}
			/>
		</div>
	)
}

FertilizationForm.propType = {
	assolement: PropTypes.object
}

const mapStateToProps = (state, { assolement }) => ({
	formValues: getFormValues(FERTILIZATION_FORM)(state) || {},
	campagne: getCurrentCampagne(state),
	initialValues: assolement && {
		[formFields.planches]: [assolement.planche.id]
	}
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FERTILIZATION_FORM,
		validate: validateForm,
		destroyOnUnmount: true
	}),
	withStyles(styles)
)(FertilizationForm)
