import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { getStyles } from 'isotope-client'
import { change, formValueSelector, initialize, reduxForm, reset } from 'redux-form'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { getCurrentCampagne } from '../../../../common/campagne/campagneSelector'
import { getAllPlanches } from '../../services/planningApi'
import { getAllGroupes } from '../../services/cultureApi'
import PropTypes from 'prop-types'
import PlancheForm from './component/PlancheForm'
import CultureGroupForm from './component/CultureGroupForm'
import ItineraireGroupeForm from './component/ItineraireGroupeForm'

export const fields = {
	LOCALISATION: 'localisation',
	GROUPE: 'groupe',
	CHOIX_DATE: 'choixDate',
	DATE: 'date',
	INFOS_ASSOLEMENT: 'infosAssolements',
	ID: 'id',
	ESPACEMENT: 'espacement',
	NB_RANGS: 'nbRangs',
	DENSITE: 'densite'
}

const styles = () => getStyles({
	form: {
		width: '100%',
		margin: 15
	},
	localisation: {
		alignItems: 'center'
	}
})

const formattedMessageMenuBase = 'planning.cultureGroup.addGroup'

const requiredFields = [
	fields.LOCALISATION,
	fields.GROUPE,
	fields.CHOIX_DATE,
	fields.DATE
]

const fieldsInfosAssolement = [
	fields.ESPACEMENT,
	fields.NB_RANGS,
	fields.DENSITE
]

const validateChampAssolementInfos = (values, index, field, errors) => {
	if (values[fields.INFOS_ASSOLEMENT][index] && !values[fields.INFOS_ASSOLEMENT][index][field]) {
		errors[fields.INFOS_ASSOLEMENT][index] = {
			...errors[fields.INFOS_ASSOLEMENT][index],
			[field]: { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	}
}

const validate = (values) => {
	const errors = {}

	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})

	const infosAssolementValues = values[fields.INFOS_ASSOLEMENT] || []
	errors[fields.INFOS_ASSOLEMENT]= {}
	infosAssolementValues.forEach((field, index) => {
		fieldsInfosAssolement.forEach(fieldInfo => validateChampAssolementInfos(values, index, fieldInfo, errors))
	})

	return errors
}

const NouveauGroupeDeCulture = ({ plancheValue, groupeValue, change, reset, handleSubmit, campagne, classes }) => {
	const [planches, setPlanches] = React.useState([])
	const [groupes, setGroupes] = React.useState([])

	const plancheSelected = React.useMemo(() => planches && plancheValue && planches.find(planche => planche.id === plancheValue), [planches,plancheValue])
	const groupeSelected = React.useMemo(() => groupes && groupeValue && groupes.find(groupe => groupe.id === groupeValue), [groupes, groupeValue])

	const resetItkForm = (fieldName) => {
		reset()
		if (fieldName === fields.GROUPE) {
			change(fields.LOCALISATION, plancheSelected.id)
		}
	}

	React.useEffect(() => {
		getAllPlanches(campagne.idFerme).then(setPlanches)
	}, [])

	React.useEffect(() => {
		if (plancheSelected) {
			getAllGroupes(campagne.idFerme, plancheSelected.zone, plancheSelected.typePlanche).then(setGroupes)
		}
	}, [plancheValue])

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid className={classes.localisation}>
				<PlancheForm resetItkForm={resetItkForm} planches={planches} formName={NEW_CULTURE_GROUP_FORM_NAME} />
			</Grid>
			{plancheValue && <Grid>
				<CultureGroupForm resetItkForm={resetItkForm} groupes={groupes} />
			</Grid>}
			{groupeValue && <Grid>
				<ItineraireGroupeForm groupe={groupeSelected} />
			</Grid>}
		</form>
	)
}

NouveauGroupeDeCulture.propTypes = {
	plancheValue: PropTypes.string,
	groupeValue: PropTypes.string,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	reset: PropTypes.func,
	campagne: PropTypes.object,
	classes: PropTypes.object
}

export const NEW_CULTURE_GROUP_FORM_NAME = 'NewCultureGroupForm'
const selector = formValueSelector(NEW_CULTURE_GROUP_FORM_NAME)

const mapStateToProps = (state) => ({
	plancheValue: selector(state, fields.LOCALISATION),
	groupeValue: selector(state, fields.GROUPE),
	campagne: getCurrentCampagne(state)
})

const actions = {
	change,
	initialize,
	reset
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: NEW_CULTURE_GROUP_FORM_NAME,
		destroyOnUnmount: true,
		validate
	})
)(NouveauGroupeDeCulture)
