import {withStyles} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import classnames from 'classnames'
import {getStyles} from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {logOut} from '../../../modules/fo/login/services/loginActions'
import * as userSelectors from '../../../modules/common/user/services/userSelectors'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {colors} from '../../../utils/constants'
import IconButton from '../../IconButton'

const styles = theme => getStyles({
	div: {
		height: 50,
		display: 'flex'
	},
	buttonUserMenu: {
		border: 'solid 1px',
		borderRadius: 30,
		padding: '0px 6px',
		backgroundColor: 'white',
		'&:hover': {
			backgroundColor: 'white'
		}
	},
	bigAvatar: {
		margin: 10,
		width: 30,
		height: 30
	},
	avatarColor: {
		color: theme.palette.placeholder
	},
	profile: {
		fontWeight: 300,
		color: theme.palette.placeholder
	},
	infos: {
		lineHeight: '15px',
		margin: 0,
		fontSize: 14,
		textAlign: 'left',
		textTransform: 'none'
	},
	arrow: {
		marginLeft: 10
	},
	logoutIcon: {
		width: 32,
		height: 32,
		fill : colors.logout
	}
})

export const UserMenu = ({ classes, logout, user, isBrowser }) => {
	const [anchorEl, setAnchorEl] = React.useState(undefined)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<React.Fragment>
			{isBrowser
				? <div className={classes.div}>
					<Button
						aria-owns={anchorEl ? 'simple-menu' : undefined}
						aria-haspopup="false"
						onClick={handleClick}
						classes={{
							root: classes.buttonUserMenu
						}}
						disableRipple
					>
						{user.icon ? <Avatar alt="avatar" src={user.icon} className={classes.bigAvatar} /> :
							<AccountCircleIcon className={classnames(classes.bigAvatar, classes.avatarColor)} />}
						<p className={classes.infos}>
							<span>{`${user.firstname || ''} ${user.lastname || ''}`}</span>
							<br />
							<span className={classes.profile}>{user && user.authorities && user.authorities.length > 0 && user.authorities[0].authority ?
								<FormattedMessage id={`enums.profil.${user.authorities[0].authority}`} /> : <FormattedMessage id="enums.profil.ROLE_MARAICHER" />}</span>
						</p>
						<ArrowDropDown className={classes.arrow} />
					</Button>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={logout}><FormattedMessage id="appBar.menus.logout" /></MenuItem>
					</Menu>
				</div>
				: <IconButton onClick={logout}><ExitToAppIcon className={classes.logoutIcon} /></IconButton>
			}
		</React.Fragment>
	)
}

UserMenu.propTypes = {
	user: PropTypes.object
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state)
})

const actions = {
	logout: logOut
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(UserMenu)
