import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import FormButton from '../../../../../../components/form/FormButton'
import WindMillIcon from '../../../../../../components/icon/WindMillIcon'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { ZONE_TYPE } from '../../../tool/tourPlaine.constants'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import WeatherUpdateForm from '../component/WeatherUpdateForm'
import { updateContextePedoclimatique } from '../service/contextePedoclimatiqueApi'
import { contextePedoclimatiqueToApi } from '../service/contextePedoclimatiqueApiMapper'
import { UPDATE_WEATHER_FORM_NAME } from '../tool/contextePedoclimatique.constants'

/**
 * Inject the Weather Update Form
 */
export const injectWeatherUpdateForm = (WrappedComponent) => {

	const OpenWeatherUpdateFormInjector = ({ dayPeriod, ...props }) => {

		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)
		const { saveWeatherContextePedoclimatique, shouldDuplicateWeatherContextePedoclimatique } = useTourPlaineContext()

		const formActions = [
			<FormButton type="primary" formName={UPDATE_WEATHER_FORM_NAME}>
				<FormattedMessage id="tourPlaine.contextePedoclimatique.updatePanel.submit" />
			</FormButton>
		]

		const openWeatherUpdateForm = useCallback((dayKey, dayPeriod, zone, type, value, fullday) => {
			closePanel()
			openPanel(
				{ id: 'tourPlaine.contextePedoclimatique.updatePanel.title' },
				<WeatherUpdateForm
					dayKey={dayKey}
					dayPeriod={dayPeriod}
					zone={zone}
					type={type}
					value={value}
					fullday={fullday}
					onSubmit={values => {
						const { dayKey, dayPeriod, zone, type, value, fullday } = values
						if (value) {
							const valueApi = contextePedoclimatiqueToApi(values)
							updateContextePedoclimatique(valueApi)
								.then(() => {
									saveWeatherContextePedoclimatique(dayKey, dayPeriod, zone, type, value, fullday)
									if (shouldDuplicateWeatherContextePedoclimatique(dayKey, type, fullday ? undefined : dayPeriod)) {
										// if we should duplicate the outside data to the inside
										const valueApi = contextePedoclimatiqueToApi({
											...values,
											zone: ZONE_TYPE.INSIDE
										})
										updateContextePedoclimatique(valueApi)
											.then(() => {
												saveWeatherContextePedoclimatique(dayKey, dayPeriod, ZONE_TYPE.INSIDE, type, value, fullday)
												snackSuccess({ id: 'snackbar.update.tourPlaine.saveWeather' })
											})
									} else {
										snackSuccess({ id: 'snackbar.update.tourPlaine.saveWeather' })
									}
								})
								.catch((e) => snackError({ id: 'snackbar.error.tourPlaine.saveWeather' }))
								.finally(() => closePanel())
						}
					}}
				/>,
				formActions,
				<WindMillIcon height={24} width={24} />
			)
		}, [shouldDuplicateWeatherContextePedoclimatique, saveWeatherContextePedoclimatique])

		return <WrappedComponent
			{...props}
			openWeatherUpdateForm={openWeatherUpdateForm}
		/>
	}

	return OpenWeatherUpdateFormInjector
}
