import { Grid, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography/index'
import { DeleteForever } from '@material-ui/icons'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import CreateIcon from '@material-ui/icons/Create'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { dataTableActions, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../components/Button'
import CommentSuiviList from '../../../../components/CommentSuiviList'
import FormButton from '../../../../components/form/FormButton'
import CommentaireIcon from '../../../../components/icon/CommentaireIcon'
import CultureIcon from '../../../../components/icon/CultureIcon'
import PlaceIcon from '../../../../components/icon/PlaceIcon'
import { DialogContext } from '../../../../components/layout/dialog'
import { ActionPanelContext, DetailsPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { ReactComponent as Jardinier } from '../../../../icons/jardinier.svg'
import { ReactComponent as Outils } from '../../../../icons/outils-et-ustensiles.svg'
import { colors, DEFAULT_LABEL, ETAT_TACHE_UPPERCASE, ONGLET_GESTION_TACHE, STATUTS_TACHE, TYPE_DIALOG, TYPE_PLANCHE, TYPE_SURFACE_ZONE } from '../../../../utils/constants'
import { LocalDate } from '../../../../utils/date/local-date'
import { DateFormat as LocaleDateFormat } from '../../../../utils/date/model/date'
import { convertMinToHours, listeMaterielFormatLi, listePersonneFormatLi } from '../../../../utils/utils'
import InfoCulture from '../../../common/information/InfoCulture'
import { injectTypeTacheValueList } from '../../../common/valueLists/valueListInjectors'
import Detail from '../../planning/component/detail/Detail'
import { injectTaskUpdateForm } from '../../tourPlaine/service/taskUpdateFormInjector'
import ContenuPopinSuppressionErrorTache from '../component/planification/ContenuPopinSuppressionErrorTache'
import ContenuPopinSuppressionTache, { formName } from '../component/planification/ContenuPopinSuppressionTache'
import { useGestionTacheContext } from '../GestionTacheProvider'
import { deleteTasks as deleteTasksApi } from '../gestionTachesApi'
import { injectSuiviDeTache } from '../injectors/suiviDeTacheInjector'
import { getAffectations, getTachesOrdonnancement } from '../services/actions'
import { getCurrentTache, getDetailPanelType } from '../services/tache/tacheSelector'
import { PANEL_TYPE, PLANIFICATION_TABLE_NAME } from '../utils/constants'
import { getDescriptionTache } from '../utils/tacheUtils'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	},
	form: {
		width: '100%',
		margin: 15
	},
	details: {
		width: 'initial !important'
	},
	enRetardIcon: {
		width: 24,
		height: 24,
		color: colors.error
	},
	iconTachePlanifier: {
		color: colors.tacheAPlanifier,
		width: 24,
		height: 24
	},
	iconTacheRealiser: {
		color: colors.tacheARealiser,
		width: 24,
		height: 24
	},
	iconTacheEnCoursOuTerminee: {
		color: colors.tacheEnCoursOuTerminee,
		width: 24,
		height: 24
	},
	textPlanifier: {
		backgroundColor: colors.tacheAPlanifier,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	textRealiser: {
		backgroundColor: colors.tacheARealiser,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	textEnCoursOuTerminee: {
		backgroundColor: colors.tacheEnCoursOuTerminee,
		color: 'white',
		fontSize: 14,
		width: 88,
		height: 28,
		textAlign: 'center',
		fontWeight: 'normal'
	},
	infoTache: {
		verticalAlign: 'sub'
	},
	description: {
		textAlign: 'justify'
	},
	instructions: {
		marginTop: 10,
		marginBottom: 10,
		textAlign: 'center'
	},
	icon: {
		width: 24,
		height: 24
	},
	ajoutSuivi: {
		marginTop: 30,
		textAlign: 'center'
	},
	detailsTache: {
		marginTop: 15
	}
})

/**
 * Injecteur pour l'affichage du panel de détail de tache
 */
export const injectTachePanel = (WrappedComponent) => {
	const TacheDetailInjector = ({ classes, panelType, currentTache, suiviDeTache, typeTacheList, resetSelectedRows, openTaskUpdateForm, getTachesOrdonnancement, getAffectations, ...props }) => {

		const { updatePanel: updateDetailsPanel } = React.useContext(DetailsPanelContext)
		const { closePanel: closePanelAction } = React.useContext(ActionPanelContext)
		const { onglet, jourSelection, refreshHeaderData, isResultat } = useGestionTacheContext()
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { snackError, snackSuccess } = useSnackbar()
		const intl = useIntl()

		const deleteTasks = () => {
			if (currentTache.statut === STATUTS_TACHE.TERMINEE) {
				openDialog(
					<span className={classes.spanTitlePopin}>
					<WarningRoundedIcon className={classes.error}/>
					<FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.error.title"/>
				</span>,
					<ContenuPopinSuppressionErrorTache tachesEnErreur={currentTache}/>,
					[], TYPE_DIALOG.ACTION, 'actions.close'
				)
			} else {
				openDialog(
					{ id: 'gestionTaches.toolbar.actions.delete.popin.validation.title' },
					<ContenuPopinSuppressionTache tachesRecurrentes={currentTache.recurrence && currentTache.recurrence.length > 0 ? currentTache : []} onSubmit={handleDeleteTasks}/>,
					[
						<FormButton
							type="primary"
							formName={formName}
						>
							<FormattedMessage id="actions.delete"/>
						</FormButton>
					], TYPE_DIALOG.ACTION
				)
			}
		}

		const handleDeleteTasks = (values) => {
			deleteTasksApi([currentTache.id], values.typeSuppression).then(() => {
				refreshHeaderData()
				resetSelectedRows()
				closeDialog()
				snackSuccess({ id: 'gestionTaches.toolbar.actions.delete.success' })
			}).catch(() => {
				closeDialog()
				snackError({ id: 'gestionTaches.toolbar.actions.delete.error' })
			})
		}

		React.useEffect(() => {
			closePanelAction()

			if (currentTache && panelType === PANEL_TYPE.TACHE_DETAIL) {
				const nomTache = (typeTacheList.find(type => type.code === currentTache.type) || {}).label || DEFAULT_LABEL
				const title = <span>
					{currentTache.culture
						? <FormattedMessage id="gestionTaches.ecranAffectations.panels.titre.details2"
						                    values={{
							                    nomTache,
							                    nomCulture: currentTache.culture.nom
						                    }}/>
						: <FormattedMessage id="gestionTaches.ecranAffectations.panels.titre.details1"
						                    values={{
							                    nomTache
						                    }}/>
					}
					</span>

				const buttons = isResultat ? [] : [
					<Button type="secondary"
					        onClick={() => {
						        suiviDeTache(currentTache, jourSelection)
					        }}
					        startIcon={<AnnouncementIcon color="currentColor"/>}>
						<FormattedMessage id="gestionTaches.ecranAffectations.panels.buttons.suivi"/>
					</Button>
				]

				const isOngletPlanif = onglet === ONGLET_GESTION_TACHE.PLANIFICATION
				const sections = [
					getDescriptionTache(currentTache, classes, PANEL_TYPE.TACHE_DETAIL, undefined, isOngletPlanif),
					{
						icon: <Jardinier className={classes.icon}/>,
						title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.affectation"/>,
						content:
							<Grid>
								<Grid>
									<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.estimationTempsPersonne"
									                  values={{ duree: <b>{convertMinToHours(currentTache.tempsPersonne)}</b> }}
									/>
								</Grid>
								{((currentTache.etat === ETAT_TACHE_UPPERCASE.EN_COURS) || (currentTache.etat === ETAT_TACHE_UPPERCASE.TERMINEE)) &&
									<Grid>
										<ul>{listePersonneFormatLi(currentTache.affectationLightBeanList)}</ul>
									</Grid>
								}

							</Grid>
					}
				]

				if (currentTache.culture) {
					sections.push({
						icon: <CultureIcon/>,
						title: currentTache.culture.nom,
						path: <InfoCulture culture={currentTache.culture}/>,
						content: <em>{currentTache.culture.cultivar}</em>
					})
				}

				if (currentTache.planche) {
					sections.push(
						{
							icon: <PlaceIcon/>,
							title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.surface" values={{ surface: currentTache.planche.nom }}/>,
							content:
								<Grid>
									{currentTache.planche.zone !== TYPE_SURFACE_ZONE.BUTTE
										? <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.surfaceDestination1"
										                    values={{
											                    zone: currentTache.planche.zone === TYPE_SURFACE_ZONE.SERRE
												                    ? <FormattedMessage id="enums.typeSurface.SER"/>
												                    : <FormattedMessage id="enums.typeSurface.EXT"/>,
											                    typeBloc: currentTache.planche.bloc
										                    }}
										/>
										: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.surfaceDestination1BUT"
										                    values={{
											                    zone: <FormattedMessage id="enums.typeSurface.BUT"/>
										                    }}
										/>
									}

									<br/>
									{currentTache.planche.surface
										? <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.surfaceDestination2"
										                    values={{
											                    typePlanche: currentTache.planche.typePlanche === TYPE_PLANCHE.PERMANENTE
												                    ? <FormattedMessage id="enums.typePlanche.PER"/>
												                    : <FormattedMessage id="enums.typePlanche.DIV"/>,
											                    aireSurface: currentTache.planche.surface
										                    }}
										/>
										: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.surfaceDestination2SansSuperficie"
										                    values={{
											                    typePlanche: currentTache.planche.typePlanche === TYPE_PLANCHE.PERMANENTE
												                    ? <FormattedMessage id="enums.typePlanche.PER"/>
												                    : <FormattedMessage id="enums.typePlanche.DIV"/>
										                    }}
										/>
									}

								</Grid>
						}
					)
				}

				if (currentTache.materiels.length > 0) {
					sections.push(
						{
							icon: <Outils className={classes.icon}/>,
							title: <FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.materiel"/>,
							content:
								<Grid>
									<Grid>
										<ul>{listeMaterielFormatLi(currentTache.materiels)}</ul>
									</Grid>
								</Grid>
						}
					)
				}

				const comments = currentTache.commentaires

				let lieu = intl.formatMessage({ id: 'enums.typeEntityAndValue.SURFACE'}, { planche: currentTache.assolement?.planche.nom })
				if (currentTache.assolement == null && currentTache.bloc != null) {
					lieu = intl.formatMessage({ id: 'enums.typeEntityAndValue.BLOC'}, {bloc: currentTache.bloc.nom})
				} else if (currentTache.assolement == null) {
					lieu = intl.formatMessage({ id: 'enums.typeEntityAndValue.FERME'})
				}

				if (comments.length > 0) {
					sections.unshift({
						icon: <CommentaireIcon color={colors.iconeDetail}/>,
						title: <FormattedMessage id="planning.suivi.detail.name"/>,
						content: <CommentSuiviList comments={comments} noEdit/>
					})
				}

				const content = <Grid container alignContent="flex-start">
					{!isResultat && <Grid container alignItems="center" justify="center" style={{ height: 70 }}>
						<Button
							type="primary"
							variant="text"
							withBorder={false}
							keepCase
							onClick={() => openTaskUpdateForm({ ...currentTache, materiels: currentTache.materiels.map(m => m.nom), lieu: lieu, plannedDate: currentTache.date }, () => {
								refreshHeaderData()
								resetSelectedRows()
								if (onglet === ONGLET_GESTION_TACHE.AFFECTATION) {
									getAffectations(LocalDate.fromString(jourSelection).format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
								} else if (onglet === ONGLET_GESTION_TACHE.ORDONNANCEMENT) {
									getTachesOrdonnancement(LocalDate.fromString(jourSelection).format(LocaleDateFormat.SHORT_DATE_WITH_DASH))
								}
							})}
						>
							<Grid item container direction="row" justify="center" alignItems="center" style={{ gap: 10 }}>
								<CreateIcon color="primary"/>
								<Typography color="primary">
									<FormattedMessage id="tourPlaine.form.updateTask.updateAction"/>
								</Typography>
							</Grid>
						</Button>
						<Button
							type="primary"
							variant="text"
							withBorder={false}
							keepCase
							onClick={deleteTasks}
						>
							<Grid item container direction="row" justify="center" alignItems="center" style={{ gap: 10 }}>
								<DeleteForever color="primary"/>
								<Typography color="primary">
									<FormattedMessage id="tourPlaine.form.updateTask.deleteAction"/>
								</Typography>
							</Grid>
						</Button>
					</Grid>}
					<Detail sections={sections} onglet={onglet} tache={currentTache} refreshHeaderData={refreshHeaderData} resetSelectedRows={resetSelectedRows}/>
				</Grid>

				return updateDetailsPanel(
					title,
					content,
					buttons
				)
			}

		}, [currentTache, panelType, onglet, refreshHeaderData, resetSelectedRows])

		return <WrappedComponent {...props} />
	}

	TacheDetailInjector.propTypes = {
		panelType: PropTypes.string,
		currentTache: PropTypes.object,
		typeTacheList: PropTypes.array
	}

	const mapStateToProps = state => ({
		currentTache: getCurrentTache(state),
		panelType: getDetailPanelType(state)
	})

	const actions = {
		resetSelectedRows: () => dataTableActions.selectRows(PLANIFICATION_TABLE_NAME, []),
		getTachesOrdonnancement,
		getAffectations
	}

	return compose(
		injectSuiviDeTache,
		injectTaskUpdateForm,
		injectTypeTacheValueList,
		connect(mapStateToProps, actions),
		withStyles(styles)
	)(TacheDetailInjector)
}
