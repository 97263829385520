import React from 'react'
import Button from '../../../components/Button'
import { reCalculate } from './utils/utils'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'

const Calcul = () => {
	const { snackError, snackSuccess } = useSnackbar()
	const onSuccess = (task) => {
		if(task.finished){
			snackSuccess({ id: 'calcul.success' })
			return true
		}
		return false
	}
	const onFailure = () => snackError({ id: 'calcul.failure' })
	return (
		<Button
			onClick={() => reCalculate(undefined, onSuccess, onFailure)}
		><FormattedMessage id="calcul.button" /></Button>
	)
}



export default Calcul