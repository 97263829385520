import { Box, Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import Button from '../../../../../components/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExportIcon from '../../../../../components/icon/ExportIcon'
import IconButton from '../../../../../components/IconButton'
import { capitalize } from '../../../../../utils/utils'
import { injectExportSuiviAvancement } from '../../injectors/exportPopin'
import moment from 'moment'
import { getSuiviAvancementRessource } from '../../suiviAvancementApi'
import { useSnackbar } from '../../../../../components/layout/snackbar/SnackbarContext'

const styles = () => getStyles({
	content: {
		height: 70,
		minWidth: 1150,
		alignItems: 'center'
	},
	toolbar: {
		paddingLeft: 24,
		height: '100%'
	},
	head: {
		fontSize: 24,
		width: 374
	},
	date: {
		fontSize: 16,
		borderBottom: '1px solid #444444',
		paddingBottom: 3
	}
})

const ToolBar = ({
	classes,
	refreshTable,
	idUserSelectionne,
	openPopinExportSuiviAvancement,
	setDataSuiviUser
}) => {
	const jourActuel = moment().startOf('day')
	const { snackError } = useSnackbar()

	return (
		<React.Fragment>
			<Grid container direction="row" justify="space-between" className={classes.content}>

				<Grid item container xs direction="row" justify="flex-start" alignItems="center" className={classes.toolbar}>
					<Typography variant="h1" className={classes.head}>
						<FormattedMessage id="suiviAvancement.toolbar.title" />
					</Typography>
					<Typography className={classes.date}>
						{capitalize(jourActuel.locale('fr').format('dddd DD-MM-YYYY'))}
					</Typography>
				</Grid>

				<Grid item direction="row">
					<Box p={1}>
						<Button
							type="secondary"
							startIcon={<RefreshIcon />}
							onClick={() => {
								refreshTable()
								if (idUserSelectionne) {
									getSuiviAvancementRessource(jourActuel.format('YYYY-MM-DD'), idUserSelectionne)
										.then(res => setDataSuiviUser(res))
										.catch(() => snackError({ id: 'mobile.snackbar.error.erreurReseau' }))
								}
							}}
							className={classes.refresh}
						>
							<FormattedMessage id="suiviAvancement.toolbar.refreshButton" />
						</Button>
						{idUserSelectionne
							? <React.Fragment />
							: <IconButton onClick={openPopinExportSuiviAvancement}> <ExportIcon /> </IconButton>
						}
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

ToolBar.propTypes = {
	classes: PropTypes.object
}

export default compose(
	withStyles(styles),
	injectExportSuiviAvancement
)(ToolBar)
