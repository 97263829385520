import PropTypes from 'prop-types'
import React from 'react'

const DriveIcon = ({ height, width, color }) => (
	<svg height={height} viewBox="0 0 512 512" width={width}>
		<path
			fill={color}
			d="m509.065 325.822-157.357-279.038c-2.659-4.715-7.652-7.632-13.065-7.632h-166.79c-5.269 0-10.101 2.927-12.542 7.597l-.016.03c-.001.002-.003.004-.004.005l-157.357 279.038c-2.801 4.967-2.544 11.092.664 15.807l82.033 120.71c4.041 5.946 10.764 9.506 17.953 9.506h310.281c4.994 0 9.661-2.486 12.448-6.631l83.135-123.654c.019-.029.032-.064.05-.094 3.005-4.547 3.429-10.57.567-15.644zm-179.184-256.67 140.44 249.038h-122.795l-148.738-249.038zm-17.299 249.039h-113.533l56.647-95.247zm-279.943 14.258 140.091-248.42 65.477 109.631-139.923 235.27zm372.236 109.396h-279.367l55.699-93.654h286.634z"/>
	</svg>
)

DriveIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

DriveIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: 'currentColor'
}

export default DriveIcon