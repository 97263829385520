import { ETAPE_ETAT, ETAPE_TYPE } from '../../../../../utils/constants'
import moment from 'moment'

/**
 * Calcul la position minimale que peut occuper une étape avec un drag left
 * @param taskList
 * @param taskId
 * @param dateDebutCampagne
 * @param itemWidth
 * @param isResize
 */
export const minPositionDragLeft = (taskList, taskId, dateDebutCampagne, itemWidth, isResize = false) => {
	const beforeTaskList = [...taskList].splice(0, taskList.findIndex(etape => etape.id === taskId))
	let positionLeft = 0

	// On boucle sur les étapes dans le sens inverse
	// Si une étape est terminée, on s'arrête car on ne pourra pas aller plus loin
	beforeTaskList.reverse().every(task => {
		// Calcul de la taille de l'étape
		const taskWidth = itemWidth * Math.ceil(moment(task.dateFin).diff(moment(task.dateDebut), 'weeks', true))

		// Si l'étape est terminée, à réaliser ou en cours, ou en attente pour une étape en resize on ne peut pas aller plus loin
		if (task.etat === ETAPE_ETAT.TERMINE || task.etat === ETAPE_ETAT.A_REALISER || task.etat === ETAPE_ETAT.EN_COURS
			|| (isResize && task.etat !== ETAPE_ETAT.EN_ATTENTE)) {
			// On calcule le différentiel entre la date de fin de étape et le début de la campagne auquel on ajoute la durée de cette étape
			positionLeft += itemWidth * Math.trunc(moment(task.dateDebut).diff(moment(dateDebutCampagne), 'weeks')) + taskWidth
			return false
		} else {
			// On ajoute la taille à la position Left
			positionLeft += taskWidth
		}
		return true
	})
	return positionLeft
}

/**
 * Calcul la position maximale que peut occuper une étape avec un drag right
 * @param taskList
 * @param taskId
 * @param dateFinCampagne
 * @param itemWidth
 */
export const maxPositionDragRight = (taskList, taskId, dateFinCampagne, itemWidth) => {
	const afterTaskList = [...taskList].splice(taskList.findIndex(etape => etape.id === taskId) + 1)
	let positionRight = 0

	// Si nous bougeons l'étape implantation ou semi-direct, nous conservons l'écart
	positionRight += deltaImplantationTask(taskList.find(etape => etape.id === taskId), taskList, itemWidth)

	// Si une étape est terminée, on s'arrête car on ne pourra pas aller plus loin
	afterTaskList.every(task => {
		// Calcul de la taille de l'étape
		const taskWidth = itemWidth * Math.ceil(moment(task.dateFin).diff(moment(task.dateDebut), 'weeks', true))

		// Si l'étape est terminée, on ne peut pas aller plus loin
		if (task.etat === ETAPE_ETAT.TERMINE) {
			// On calcule le différentiel entre l'étape et la fin de la campagne auquel on ajoute la durée de cette étape
			positionRight += itemWidth * Math.trunc(moment(dateFinCampagne).diff(moment(task.dateFin), 'weeks')) + taskWidth
			return false
		} else {
			// On ajoute la taille à la position Left
			positionRight += taskWidth

			// Cas particulier de l'étape Implantation ou semi-direct qui doit conserver son écart
			positionRight += deltaImplantationTask(task, taskList, itemWidth)
		}
		return true
	})
	return positionRight
}

/**
 * Fonction qui calcule la nouvelle position des items poussés
 * @param currentEtape étape dont on veut calculer la position
 * @param initialEtapePosition position initiale de l'étape en cours
 * @param taskList liste des étapes
 * @param initX position initiale de l'étape en mouvement
 * @param x décalage effectué par le drag
 * @param xWidth largeur en plus dans le cas du resize
 * @param moveTaskId id de l'étape en cours de déplacement
 * @param itemWidth taille d'un item
 * @returns {number}
 */
export const moveTaskBehind = (currentEtape, initialEtapePosition, taskList, initX, x, xWidth, moveTaskId, itemWidth) => {
	// Uniformisation de la position
	let xNormalized = Math.trunc(x / itemWidth) * itemWidth

	// On récupère l'étape en cours de déplacement et sa taille
	let taskIsMoving = taskList.find(task => task.id === moveTaskId)
	let taskWidth = 0

	// Dans le cas d'un décalage à droite
	xNormalized += xWidth.direction === 'right' ? Math.max(0, xWidth.width) : 0
	if (initX < xNormalized) {
		// Cas particulier: la récolte reste accrochée à la fin de récolte
		if (taskIsMoving.type === ETAPE_TYPE.FIN_RECOLTE && currentEtape.type === ETAPE_TYPE.RECOLTE && xWidth.width === 0) {
			return xNormalized - initX
		}

		taskWidth += deltaRecolteTask(currentEtape, taskList, itemWidth)
		// Calcul de l'écart entre l'étape que l'on déplace et l'étape courante
		const delta = itemWidth * Math.trunc(moment(currentEtape.dateDebut).diff(moment(taskIsMoving.dateFin), 'weeks'))
		// On ne déplace que les étapes après celle en mouvement
		if (delta >= 0) {
			// On calcule la taille des étapes entre celle en mouvement et la courante
			taskWidth += widthTasks(taskList, moveTaskId, currentEtape.id, itemWidth, deltaRecolteTask)

			// Si le déplacement + la taille des étapes précédent l'étape courante est supérieur au delta -> Déplacement
			if (xNormalized - initX + taskWidth > delta) {
				return Math.trunc((((xNormalized - initX) + taskWidth) - delta) / itemWidth) * itemWidth
			}
		}
	}

	xNormalized -= xWidth.direction === 'left' ? Math.max(0, xWidth.width) : 0
	// Dans le cas d'un décalage à gauche
	if (initX > xNormalized) {
		// Cas particulier: la fin de récolte reste accrochée à la récolte
		if (taskIsMoving.type === ETAPE_TYPE.RECOLTE && currentEtape.type === ETAPE_TYPE.FIN_RECOLTE && xWidth.width === 0) {
			return xNormalized - initX
		}

		// Calcul de l'écart entre l'étape que l'on déplace et l'étape courante
		let delta = itemWidth * Math.trunc(moment(taskIsMoving.dateDebut).diff(moment(currentEtape.dateFin), 'weeks'))

		if (delta >= 0) {
			// On calcule la taille des étapes entre celle en mouvement et la courante
			taskWidth += widthTasks(taskList, currentEtape.id, moveTaskId, itemWidth)

			if (initX - xNormalized + taskWidth > delta) {
				// Quand on passe en négatif, on normalize le déplacement en inversé
				const xDeplacement = x < 0 ? -Math.ceil(-x / itemWidth) * itemWidth : xNormalized
				return -Math.trunc((((initX - xDeplacement) + taskWidth) - delta) / itemWidth) * itemWidth
			}
		}
	}
	// Cas particulier du resize de la récolte à gauche: on conserve la fin de récolte accrochée
	if (taskIsMoving.type === ETAPE_TYPE.RECOLTE && currentEtape.type === ETAPE_TYPE.FIN_RECOLTE
	&& xWidth.width < 0 && xWidth.direction === 'right') {
		return xWidth.width
	}
	return 0
}

/**
 * Calcule le delta entre la récolte et l'étape précédente
 * (utilisé pour le déplacement à droite)
 * @param task
 * @param taskList
 * @param itemWidth
 * @returns {number}
 */
const deltaRecolteTask = (task, taskList, itemWidth) => {
	if (task.type === ETAPE_TYPE.RECOLTE) {
		const recolteTaskIndex = taskList.findIndex(task => task.type === ETAPE_TYPE.RECOLTE)
		// On récupère l'étape précédente
		if (recolteTaskIndex > 0) {
			const taskStart = taskList[recolteTaskIndex - 1]
			return itemWidth * Math.trunc(moment(task.dateDebut).diff(moment(taskStart.dateFin), 'weeks'))
		}
	}
	return 0
}

/**
 * Calcul du delta à conserver entre l'étape implantation et la récolte
 * (utilisé pour le calcul de la zone de déplacement et le déplacement gauche)
 * @param task
 * @param taskList
 * @param itemWidth
 * @returns {number}
 */
const deltaImplantationTask = (task, taskList, itemWidth) => {
	// Cas particulier de l'étape Implantation ou semi-direct qui doit conserver son écart
	if (task.type === ETAPE_TYPE.IMPLANTATION || task.type === ETAPE_TYPE.SEMI_DIRECT) {
		// On conserve l'écart entre cette étape et la récolte
		const taskRecolte = taskList.find(etape => etape.type === ETAPE_TYPE.RECOLTE)
		if (!!taskRecolte) {
			return itemWidth * Math.trunc(moment(taskRecolte.dateDebut).diff(moment(task.dateFin), 'weeks'))
		}
	}

	return 0
}

/**
 * Calcul de la taille des étapes présentes dans l'intervalle entre deux étapes
 * @param taskList liste des étapes
 * @param startId id de la première étape
 * @param endId id de la dernière étape
 * @param itemWidth taille des items
 * @param addDelta delta éventuel à ajouter pour l'écart entre implantation et récolte
 * @returns {number}
 */
const widthTasks = (taskList, startId, endId, itemWidth, addDelta) => {
	let taskWidth = 0
	const startIndex = taskList.findIndex(task => task.id === startId) + 1
	const endIndex = taskList.findIndex(task => task.id === endId) - 1
	if (startIndex <= endIndex) {
		taskWidth += ([...taskList].splice(startIndex, (endIndex - startIndex) + 1) || [])
			.reduce((acc, curr) => {
				let delta = 0
				if (addDelta) {
					delta = addDelta(curr, taskList, itemWidth)
				}
				return acc + (itemWidth * Math.ceil(moment(curr.dateFin).diff(moment(curr.dateDebut), 'weeks', true))) + delta
			}, 0)
	}

	return taskWidth
}
