import React from 'react'
import { Grid, MenuItem, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Select from '../../../../components/form/Select'
import ItkForm from '../../cultureItk/components/ItkForm'
import { getPreferences } from '../../../common/user/services/userSelectors'
import Autocomplete from '../../../../components/form/Autocomplete'
import classnames from 'classnames'
import GroupeCultureForm from './GroupeCultureForm'
import { getCultureItkList } from '../../cultureItk/services/cultureItkApi'
import { ETAPE_TYPE, TYPE_ITK_BO } from '../../../../utils/constants'
import { getItkForInitValues } from '../../cultureItk/cultureItkUtils'

const styles = (theme) => getStyles({
	form: {
		padding: '20px 20px 0px 20px',
		width: '100%'
	},
	row: {
		marginBottom: 15
	},
	field: {
		width: '50%'
	},
	info: {
		paddingLeft: 10
	},
	title: {
		height: 20,
		marginTop: 38,
		marginBottom: 20,
		fontSize: 18,
		fontWeight: 'normal',
		color: theme.palette.text.primary,
		paddingLeft: 10,
		paddingRight: 50
	}
})

const formattedMessageBase = 'bo.groupeCulture.form'
export const FIELDS = {
	TYPE_AJOUT: { name: 'typeAjout' },
	GROUPE_CULTURE: { name: 'idGroup' },
	CULTURE: { name: 'idCulture' },
	ITK: { name: 'idItk' }
}
const TYPE_OPTIONS = {
	GROUPE: 'groupe',
	ASSOLEMENT: 'assolement'
}

const validate = (values) => {
	const errors = {}
	if (!values[FIELDS.TYPE_AJOUT.name]) {
		errors[FIELDS.TYPE_AJOUT.name] = { id: `${formattedMessageBase}.errors.mandatory` }
	}
	return errors
}

const AddGroupeCultureForm = ({
	groupeList,
	idFerme,
	formName,
	onSubmit,
	typeAjoutSelected,
	groupeSelected,
	cultureSelected,
	itkSelected,
	classes,
	change,
	handleSubmit
}) => {
	const [cultures, setCultures] = React.useState([])
	const [itks, setItks] = React.useState([])

	React.useEffect(() => {
		getCultureItkList({ idFerme }).then((result => setCultures(
			result.map(res => ({ label: res.nom, code: res.id, itks: res.itks }))
		)))
	}, [idFerme])

	const handleChangesCulture = (ev, idCulture) => {
		const cultureFound = cultures.find(culture => culture.code === idCulture)

		if (!!cultureFound) {
			setItks(cultureFound.itks.map(itk => ({ label: itk.designation, code: itk.id, data: itk })))
		}
		change(FIELDS.ITK.name, '')
	}

	React.useEffect(() => {
		// Init des infos assolement selon la sélection d'itk
		if (!!groupeSelected && !!itkSelected) {
			const itkFound = itks.find(itk => itk.code === itkSelected)
			const isSemisD = !!itkFound.data.details.find(etape => etape.typeEtape === ETAPE_TYPE.SEMI_DIRECT)
			// Init de l'assolement via l'ITK
			const init = getItkForInitValues(itks.find(itk => itk.code === itkSelected).data)
			Object.keys(init).map(key => change(key, init[key]))
			change('surface', groupeList.find(groupe => groupe.code === groupeSelected).typeSurface)
			change('typeItk', isSemisD ? TYPE_ITK_BO.SEMID : TYPE_ITK_BO.SEMIC)
			change(isSemisD ? 'debutPreparation' : 'debutSemisC', '0')
		} else {
			change('surface', null)
			change('typeItk', null)
			change('debutPreparation', null)
			change('debutSemisC', null)
		}
	}, [groupeSelected, itkSelected])

	const getForm = () => {
		if (typeAjoutSelected === TYPE_OPTIONS.ASSOLEMENT && !!groupeSelected && !!cultureSelected && !!itkSelected) {
			return <>
				<Grid container className={classnames(classes.title, classes.row)}>
					<FormattedMessage id={`${formattedMessageBase}.titleNewAssolement`} />
				</Grid>
				<ItkForm
					isCreation
					fromGroupeCulture
					formName={formName}
					onSubmit={onSubmit}
					noDeleteOnUnmout
				/>
			</>
		}

		if (typeAjoutSelected === TYPE_OPTIONS.GROUPE) {
			return <>
				<Grid container className={classnames(classes.title, classes.row)}>
					<FormattedMessage id={`${formattedMessageBase}.titleNewGroupe`} />
				</Grid>
				<GroupeCultureForm
					isCreation
					formName={formName}
					onSubmit={onSubmit}
				/>
			</>
		}

		return <></>
	}

	return (<form onSubmit={handleSubmit} className={classes.form}>
		<Grid container className={classnames(classes.info, classes.row)}>
			<FormattedMessage id={`${formattedMessageBase}.infoAjout`} />
		</Grid>
		<Grid container className={classes.row}>
			<Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.TYPE_AJOUT.name}
					component={Select}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE_AJOUT.name}`} />}
					fromBo
				>
					{Object.values(TYPE_OPTIONS).map(type => <MenuItem key={`type-option-${type}`} value={type}>
						<FormattedMessage id={`${formattedMessageBase}.fields.ajoutOptions.${type}`} />
					</MenuItem>)}

				</Field>
			</Grid>
			{typeAjoutSelected === TYPE_OPTIONS.ASSOLEMENT && <Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.GROUPE_CULTURE.name}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.GROUPE_CULTURE.name}`} />}
					options={groupeList}
					fromBo
				/>
			</Grid>}
		</Grid>
		{typeAjoutSelected === TYPE_OPTIONS.ASSOLEMENT && <Grid container className={classes.row}>
			<Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.CULTURE.name}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.CULTURE.name}`} />}
					options={cultures}
					onChange={handleChangesCulture}
					fromBo
				/>
			</Grid>
			{!!cultureSelected && <Grid item xs={6} className={classes.field}>
				<Field
					name={FIELDS.ITK.name}
					component={Autocomplete}
					label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ITK.name}`} />}
					options={itks}
					fromBo
				/>
			</Grid>}

		</Grid>}
		{getForm()}
	</form>)
}

AddGroupeCultureForm.propTypes = {
	groupeList: PropTypes.array,
	idFerme: PropTypes.string,
	formName: PropTypes.string,
	onSubmit: PropTypes.func,
	reset: PropTypes.func,
	typeAjoutSelected: PropTypes.string,
	groupeSelected: PropTypes.string,
	cultureSelected: PropTypes.string,
	itkSelected: PropTypes.string,
	classes: PropTypes.object
}

const mapStateToProps = (state, { formName }) => {
	const formSelector = formValueSelector(formName)

	return {
		form: formName,
		typeAjoutSelected: formSelector(state, FIELDS.TYPE_AJOUT.name),
		groupeSelected: formSelector(state, FIELDS.GROUPE_CULTURE.name),
		cultureSelected: formSelector(state, FIELDS.CULTURE.name),
		itkSelected: formSelector(state, FIELDS.ITK.name),
		idFerme: (getPreferences(state).FERME_ID || '0')
	}
}

const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		enableReinitialize: true,
		validate
	}),
	withStyles(styles)
)(AddGroupeCultureForm)
