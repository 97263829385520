import React from 'react'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import GestionTacheComponents from './GestionTacheComponents'
import GestionTacheProvider from './GestionTacheProvider'
import AccessChecker from '../../../components/security/AccessChecker'
import {PROFILS} from '../../../utils/constants'
import {getAccessCheckerRole} from '../../../utils/utils'


const GestionTache = () => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.GESTION_TACHES), [])

	return (
		<AccessChecker access={getAccessCheckerRole(PROFILS.CHEF)}>
			<DialogProvider textButton>
				<DetailPanelProvider>
					<ActionPanelProvider>
						<GestionTacheProvider>
							<GestionTacheComponents/>
						</GestionTacheProvider>
					</ActionPanelProvider>
				</DetailPanelProvider>
			</DialogProvider>
		</AccessChecker>
	)
}

export default GestionTache
