import { fetchFactory, urlUtils } from 'isotope-client'
import { baseUri } from '../../../service/tourPlaineApi'

const suiviBlockBaseUri = `${baseUri}/suiviBloc`

/**
 * getSuiviBlock
 * @returns {Promise<*>}
 */
export const getFarmPlan = () => fetchFactory(`${suiviBlockBaseUri}/planFerme`)

/**
 * getNotifs
 * @param params { blocId: string, dateDebut: yyyy-MM-dd}
 * @returns {Promise<*>}
 */
export const getNotifs = (params) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/infosPastilles`, params))

/**
 * API for CROPS
 */
/**
 * getInProgressCrops
 * @param params { 	blocId: string, dateDebut: yyyy-MM-dd}
 * @returns {Promise<*>}
 */
export const getInProgressCrops = (params) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/culturesEnCours`, params))

/**
 * API for TASKS
 * @param dateDebut
 * @param onlyRepiquage
 * @param onlyTachesEnAttenteArbitrage
 * @returns {Promise<*>}
 */
export const getTasks = (idBloc, dateDebut, onlyRecolte, onlyTachesEnAttenteArbitrage) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/taches`, { idBloc, dateDebut, onlyRecolte, onlyTachesEnAttenteArbitrage }))

/**
 * API for IRRIGATION
 */

/**
 * getIrrigation
 * @param params { 	blocId: string, dateDebut: yyyy-MM-dd}
 * @returns {Promise<*>}
 */
export const getIrrigations = (params) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/aspersions`, params))

/**
 * deleteIrrigation
 * @param idList
 * @returns {Promise<*>}
 */
export const deleteIrrigation = (idList) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/aspersions`, { idList }), {
	method: 'DELETE'
})

/**
 * addIrrigation
 * @param values
 * @returns {Promise<*>}
 */
export const addIrrigations = (values) => fetchFactory(`${suiviBlockBaseUri}/aspersions`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * duplicateLastWeekIrrigations
 * @param params { blocId: string, dateDebutSemaineSelectionne: yyyy-MM-dd, dateDebutSemaineBase: yyyy-MM-dd }
 * @returns {Promise<*>}
 */
export const duplicateLastWeekIrrigations = (params) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/aspersions/duplicate`, params), {
	method: 'POST'
})

/**
 * API for BIO AGGRESSORS
 */

/**
 * getBioAggressors
 * @returns {Promise<*>}
 */
export const getBioAggressors = () => fetchFactory(`${suiviBlockBaseUri}/bioagresseurs`)

/**
 * getBoardsBioAggressors
 * @param blocId
 * @param onlyPlanchesSousPression
 * @returns {Promise<*>}
 */
export const getBoardsBioAggressors = (blocId, startDate, onlyOnPressure) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/bioagresseurs/planches`, { blocId, dateDebut: startDate, onlyPlanchesSousPression: onlyOnPressure }))

/**
 * addBioAggressors
 * @param values
 * @returns {Promise<*>}
 */
export const addBioAggressors = (values) => fetchFactory(`${suiviBlockBaseUri}/bioagresseurs`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * updateBioAggressors
 * @param values
 * @returns {Promise<*>}
 */
export const updateBioAggressors = (values) => fetchFactory(`${suiviBlockBaseUri}/bioagresseurs`, {
	method: 'PATCH',
	body: JSON.stringify(values)
})

/**
 * API for BOARDS_UNAVAILABLE
 */

/**
 * getBoardsUnavailables
 * @param blocId
 * @param dateDebut
 * @param onlyPlanchesIndisponibles
 * @returns {Promise<*>}
 */
export const getBoardsUnavailables = (blocId, dateDebut, onlyPlanchesIndisponibles) => fetchFactory(urlUtils.buildUrlWithParams(`${suiviBlockBaseUri}/planchesIndisponibles`, { blocId, dateDebut, onlyPlanchesIndisponibles }))

/**
 * addBoardsUnavailables
 * @param values
 * @returns {Promise<*>}
 */
export const addBoardsUnavailables = (values) => fetchFactory(`${suiviBlockBaseUri}/planchesIndisponibles`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * checkCulture
 * @param values
 * @returns {Promise<*>}
 */
export const checkCulture = (values) => fetchFactory(`${suiviBlockBaseUri}/checkCulture`, {
	method: 'POST',
	body: JSON.stringify(values)
})

/**
 * updateBoardsUnavailables
 * @param values
 * @returns {Promise<*>}
 */
export const updateBoardsUnavailables = (values) => fetchFactory(`${suiviBlockBaseUri}/planchesIndisponibles`, {
	method: 'PATCH',
	body: JSON.stringify(values)
})

/**
 * Delete
 * @param planchesIdList
 * @returns {Promise<*>}
 */
export const deleteBoardsUnavailables = (planchesIdList) => fetchFactory(`${suiviBlockBaseUri}/planchesIndisponibles`, {
	method: 'DELETE',
	body: JSON.stringify(planchesIdList)
})

export const confirmTachesRecolte = (idTacheList) => fetchFactory(`${suiviBlockBaseUri}/taches/confirmerRecolte`, {
	method: 'PUT',
	body: JSON.stringify(idTacheList)
})