import { TYPE_RESSOURCE } from '../../../../utils/constants'

/**
 * Formattage des values de la ressource pour l'édition
 * @param ressource
 */
export const getRessourceForInitValues = (ressource) => {
	return {
		id: ressource?.id,
		nom: ressource?.nom,
		isActive: !ressource?.active,
		prenom: ressource?.prenom,
		typeRessource: ressource?.role && Object.values(TYPE_RESSOURCE).find(type => type.nom === ressource?.role?.toUpperCase())?.id,
		typeContrat: ressource?.ressourceContrat?.typeContrat,
		debutContrat: ressource?.ressourceContrat?.debutContrat,
		finContrat: ressource?.ressourceContrat?.finContrat,
		isHourDepend: ressource?.ressourceContrat?.scheduleFromFerme,
		user: ressource?.user,
		competences: ressource?.competences?.map(competence => competence.id)?.reduce((acc, id) => {
			acc[id] = true;
			return acc;
		}, []),

		lundiDebut: ressource?.ressourceContrat?.lundiDebut,
		mardiDebut: ressource?.ressourceContrat?.mardiDebut,
		mercrediDebut:ressource?.ressourceContrat?.mercrediDebut,
		jeudiDebut: ressource?.ressourceContrat?.jeudiDebut,
		vendrediDebut: ressource?.ressourceContrat?.vendrediDebut,
		samediDebut: ressource?.ressourceContrat?.samediDebut,
		dimancheDebut: ressource?.ressourceContrat?.dimancheDebut,
		lundiTemps: ressource?.ressourceContrat?.lundi,
		mardiTemps: ressource?.ressourceContrat?.mardi,
		mercrediTemps: ressource?.ressourceContrat?.mercredi,
		jeudiTemps: ressource?.ressourceContrat?.jeudi,
		vendrediTemps: ressource?.ressourceContrat?.vendredi,
		samediTemps: ressource?.ressourceContrat?.samedi,
		dimancheTemps: ressource?.ressourceContrat?.dimanche
	}
}

export const convertMinutesToTime = (totalMinutes) => {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');

	return `${formattedHours}h${formattedMinutes}`;
}

export function formatDate(dateString) {
	if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
		const [year, month, day] = dateString.split('-');
		return `${day}/${month}/${year}`;
	} else {
		return null
	}
}

export function calculateDaysBetweenDates(date1, date2) {
	const oneDay = 24 * 60 * 60 * 1000;
	const firstDate = new Date(date1);
	const secondDate = new Date(date2);

	const differenceInTime = Math.abs(secondDate - firstDate);
	const differenceInDays = Math.ceil(differenceInTime / oneDay);

	return differenceInDays;
}

export function formatTime(timeString) {
	return timeString.length === 5 ? `${timeString}:00` : timeString;
}