import Grid from '@material-ui/core/Grid'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Select from '../../../../../components/form/Select'
import { getAllCampagnes } from '../../services/planningApi'
import { MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = () => getStyles({
	form: {
		padding: '20px 20px 0px 20px',
		width: '100%'
	},
	text: {
		marginBottom: 25,
		fontSize: 14
	},
	menuItem: {
		color: 'white !important',
		backgroundColor: 'rgba(128,65,128, 0.4) !important'
	},
	select: {
		paddingLeft: 0
	}
})

export const EXPORT_CHARGES_TRAVAIL_FORM = 'EXPORT_CHARGES_TRAVAIL_FORM'

const validate = values => {
	const errors = {}
	if (!values.campagne) {
		errors.campagne = <FormattedMessage id="planning.export.popin.error" />
	}
	return errors
}

const PopinExport = ({ campagne, classes, handleSubmit }) => {
	const [campagnes, setCampagnes] = React.useState([])

	React.useEffect(() => {
		getAllCampagnes().then(setCampagnes)
	}, [])

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid container>
				<Grid container>
					<Grid item>
						<Typography variant="body1" className={classes.text}>
							<FormattedMessage id="planning.export.popin.text" />
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="campagne"
							component={Select}
							label={<FormattedMessage id="planning.export.popin.field" />}
							fromBo
							containerClassName={classes.select}
						>
							{
								campagnes.map(campagne => (
									<MenuItem key={campagne.id} value={campagne.id} classes={{ selected: classes.menuItem }}>
										{campagne.nom}
									</MenuItem>
								))
							}
						</Field>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: EXPORT_CHARGES_TRAVAIL_FORM,
		validate
	})
)(PopinExport)
