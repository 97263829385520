import { PropTypes } from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { getStyles } from 'isotope-client'
import { normalizeNumber } from '../../../../../utils/utils'

const DATE_FORMAT = {
	month: '2-digit',
	day: '2-digit',
	year: 'numeric'
}

const styles = getStyles({
	margin: {
		marginBottom: 5,
		marginTop: 5
	}
})

const ItineraireTechniqueBase = ({ assolement, withNbGraines = false, intl, classes }) => {

	const semisDebut = intl.formatDate(new Date(assolement.semisDebut), DATE_FORMAT)
	const semisFin = intl.formatDate(new Date(assolement.semisFin), DATE_FORMAT)

	return (
		<>
			{assolement.designation &&
			<p className={classes.margin}>
				<FormattedMessageWithBold
					id="planning.itineraireTechnique.detail.designation"
					values={{ designation: assolement.designation }}
				/>
			</p>
			}
			{assolement.semisDebut && assolement.semisFin &&
			<p className={classes.margin}>
				<FormattedMessageWithBold
					id="planning.itineraireTechnique.detail.periodeSemis"
					values={{
						start: semisDebut,
						end: semisFin
					}}
				/>
			</p>
			}
			{withNbGraines && assolement.nbGraine &&
			<p className={classes.margin}>
				<FormattedMessageWithBold
					id="planning.itineraireTechnique.detail.nbGraine"
					values={{ nbGraine: normalizeNumber(assolement.nbGraine) }}
				/>
			</p>
			}
		</>
	)
}

ItineraireTechniqueBase.propType = {
	assolement: PropTypes.object.isRequired,
	classes: PropTypes.object
}

export default compose(
	injectIntl,
	withStyles(styles)
)(ItineraireTechniqueBase)
