import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Grid, withStyles } from '@material-ui/core'
import { SubmissionError } from 'redux-form'
import Button from '../../../components/Button'
import BackOfficeContainer from '../BackOfficeContainer'
import { dataTableActions, getStyles } from 'isotope-client'
import { colors, DEFAULT_LABEL, TACHE_TYPE_ENTITE, TYPE_DATE, TYPE_DIALOG } from '../../../utils/constants'
import { compose } from 'redux'
import NoResultPage from '../../../components/NoResultPage'
import { connect } from 'react-redux'
import { getPreferences } from '../../common/user/services/userSelectors'
import FormButton from '../../../components/form/FormButton'
import { DialogContext } from '../../../components/layout/dialog'
import PopinModeleTache from './PopinModeleTache'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'
import { saveMoTache } from './modeleTacheApi'
import { OUI_NON } from '../../fo/mobile/utils/constantsSuiviTache'
import SpringDataTableWithLoader from '../../../components/layout/SpringDataTableWithLoader'

const styles = () => getStyles({
	liste: {
		maxHeight: 'calc(100vh - 410px)',
		flex: '0 0 auto',
		display: 'block',
		overflowY: 'scroll',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'& .MuiTableCell-body': {
			height: 40,
			fontSize: 14
		},
		'& .MuiTableCell-head': {
			textAlign: 'center'
		},
		'& .MuiTableCell-stickyHeader': {
			fontSize: '14px'
		},
		'& .MuiTableRow-head': {
			backgroundColor: colors.backgroundSuiviTacheAvancement
		},
		'& .MuiTableHead-root': {
			height: 50
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073'
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		},
		margin: '0px 32px 0px 32px'
	},
	titleList: {
		marginBottom: 20,
		fontSize: 18,
		fontFamily: 'Lato',
		marginLeft: 32,
		letterSpacing: 2
	}
})

const PARAMETRAGE_MODELE_TACHE = 'PARAMETRAGE_MODELE_TACHE'

export const MODELE_TACHE_EDITION_FORM = 'MODELE_TACHE_EDITION_FORM'

const openPopinModeleTacheEdition = (selectedMoTache, openDialog, handleSubmit, filters) => {
	openDialog(
		selectedMoTache ? <FormattedMessage id="bo.modeleTache.form.titleUpdate" values={{moTacheId: selectedMoTache.moTacheLightBean.id}} /> : <FormattedMessage id="bo.modeleTache.form.titleNew" />,
		<PopinModeleTache onSubmit={handleSubmit} selectedMoTache={selectedMoTache} idFerme={filters.idFerme}/>,
		[
			<FormButton type="primary" formName={MODELE_TACHE_EDITION_FORM}>
				<FormattedMessage id="bo.utilisateur.form.submit" />
			</FormButton>
		],
		TYPE_DIALOG.ACTION,
		undefined,
		undefined,
		true
	)
}

const ModeleTachePage = ({ classes, userPreferences, refreshTable }) => {
	const [filters, setFilters] = React.useState({ idFerme: userPreferences.FERME_ID })
	const { openDialog, closeDialog } = React.useContext(DialogContext)
	const { snackError, snackSuccess } = useSnackbar()

	const onSubmit = (values) => {
		const data = {...values}
		if(!!data.blocs){
			data.blocs = data.blocs.map(val => val.code)
		}
		if(!!data.surfaces){
			data.surfaces = data.surfaces.map(val => val.code)
		}
		if(!!data.materiels){
			data.materiels = data.materiels.map(val => val.code)
		}
		data.idFerme = filters.idFerme
		data.flagRecurrence = values.flagRecurrence === OUI_NON.OUI.value
		data.flagCreation = values.flagCreation === OUI_NON.OUI.value
		data.flagCreationCampagne = false
		if (values.flagCreationCampagne && values.flagCreationCampagne === OUI_NON.OUI.value) {
			data.flagCreationCampagne = true
			data.creationSemaineN = values.creationSemaineN
		}
		data.flagDivisible = values.flagDivisible === OUI_NON.OUI.value
		data.flagAnticipable = values.flagAnticipable === OUI_NON.OUI.value
		data.flagControleEtape = values.flagControleEtape && values.flagControleEtape === OUI_NON.OUI.value
		data.debutFin = values.debutFin && values.debutFin === TYPE_DATE.DEBUT
		if (values.id) {
			return saveMoTache(data).then((data) => snackSuccess({ id: 'snackbar.update.updateModeleTache', values: { moTacheId: data.id } }))
				.then(refreshTable())
				.then(closeDialog)
				.catch((e) => {
					snackError({ id: 'snackbar.error.updateModeleTache' })
					throw new SubmissionError(e)
				})
		} else {
			return saveMoTache(data).then((data) => snackSuccess({ id: 'snackbar.update.ajoutModeleTache', values: { moTacheId: data.id } }))
				.then(refreshTable())
				.then(closeDialog)
				.catch((e) => {
					snackError({ id: 'snackbar.error.ajoutModeleTache' })
					throw new SubmissionError(e)
				})
		}
	}

	const dialogCreateMoTache = () => openPopinModeleTacheEdition(null, openDialog, onSubmit, filters)

	const dialogUpdateMoTache = (selectedMoTache) => openPopinModeleTacheEdition(selectedMoTache, openDialog, onSubmit, filters)

	return (<>
		<Grid container justify="space-between">
			<Typography variant="h2">
				<FormattedMessage id="bo.modeleTache.title" />
			</Typography>
			<Button type="secondary" keepCase onClick={dialogCreateMoTache}><FormattedMessage id="bo.modeleTache.newBtn" /></Button>
		</Grid>
		<BackOfficeContainer inputLabel="bo.modeleTache.inputQuery" onSubmit={setFilters}>
			<Typography className={classes.titleList}><FormattedMessage id="bo.modeleTache.liste" /></Typography>
			<div className={classes.liste}>
				<SpringDataTableWithLoader
					nom={PARAMETRAGE_MODELE_TACHE}
					showBorders={false}
					apiUrl="/bo/modeleTache"
					defaultPageSize={50}
					filters={filters}
					onRowClick={event => dialogUpdateMoTache(event)}
					noResultFragment={<><br /><br /><br /><NoResultPage /></>}
					tableProps={{
						stickyHeader: true
					}}
					headers={[
						{
							key: 'famille',
							name: <FormattedMessage id="bo.modeleTache.headers.famille" />,
							tableHeaderColumnProps: {
								width: 248,
								align: 'center'
							},
							render: row => row.famille.label || DEFAULT_LABEL
						},
						{
							key: 'type',
							name: <FormattedMessage id="bo.modeleTache.headers.type" />,
							tableHeaderColumnProps: {
								width: 150,
								align: 'center'
							},
							render: row => row.type.label || DEFAULT_LABEL
						},
						{
							key: 'categorieTechnique',
							name: <FormattedMessage id="bo.modeleTache.headers.categorieTechnique" />,
							tableHeaderColumnProps: {
								width: 254,
								align: 'center'
							},
							render: row => row.moTacheLightBean?.categorieTechnique?.nom ||  <FormattedMessage id={'bo.modeleTache.emptyCategory'} />
						},
						{
							key: 'typeEntity',
							name: <FormattedMessage id="bo.modeleTache.headers.typeEntity" />,
							tableHeaderColumnProps: {
								width: 254,
								align: 'center'
							},
							render: row => <FormattedMessage id={`enums.typeEntity.${row.moTacheLightBean.typeEntity}`} />
						},
						{
							key: 'culture',
							name: <FormattedMessage id="bo.modeleTache.headers.culture" />,
							tableHeaderColumnProps: {
								width: 244,
								align: 'center'
							},
							render: row => {
								if (row.moTacheLightBean.typeEntity === TACHE_TYPE_ENTITE.ASSOLEMENT) {
									return row.moTacheLightBean.culture
								}
								if (row.moTacheLightBean.typeEntity === TACHE_TYPE_ENTITE.ETAPE) {
									if(row.moTacheLightBean.itk && row.moTacheLightBean.itk.designation){
										return row.moTacheLightBean.itk.designation
									}
								}
								return '-'
							}
						},
						{
							key: 'etape',
							name: <FormattedMessage id="bo.modeleTache.headers.etape" />,
							tableHeaderColumnProps: {
								align: 'center'
							},
							render: row => row.moTacheLightBean.etape ? <FormattedMessage id={`enums.etape.${row.moTacheLightBean.etape}`} /> : '-'
						}
					]}
				/>
			</div>

		</BackOfficeContainer>
	</>)
}

const mapStateToProps = (state) => ({
	userPreferences: getPreferences(state)
})

const actions = {
	refreshTable: () => dataTableActions.refresh(PARAMETRAGE_MODELE_TACHE)
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(ModeleTachePage)
