import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectTypeSurfaceValueList, injectZoneSurfaceValueList } from '../valueLists/valueListInjectors'
import { DEFAULT_LABEL } from '../../../utils/constants'

/** Composant qui permet l'affichage de la zone et la surface d'un itk **/

const ZoneItk = ({ itk, zoneSurfaceList, typeSurfaceList }) => {
	const zones = itk.zone.split(';')
		.map(zoneItk => (zoneSurfaceList.find(zone => zone.code === zoneItk) || {}).label || DEFAULT_LABEL)
		.join(', ')
	const typeSurface = (typeSurfaceList.find(type => type.code === itk.typeSurface) || {}).label || DEFAULT_LABEL

	return <>{`${zones} - ${typeSurface}`}</>
}

ZoneItk.propTypes = {
	itk: PropTypes.object,
	zoneSurfaceList: PropTypes.array,
	typeSurfaceList: PropTypes.array
}

export default compose(
	injectZoneSurfaceValueList,
	injectTypeSurfaceValueList
)(ZoneItk)
