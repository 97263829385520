import {Box, Grid, Typography, withStyles} from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import {compose} from "redux";
import { FormattedMessage } from 'react-intl'
import Button from "../../../../components/Button";
import {colors} from "../../../../utils/constants";
import { push } from 'connected-react-router'
import SpaIcon from '@material-ui/icons/Spa'
import ListAlt from '@material-ui/icons/ListAlt'
import {connect} from "react-redux";


const styles = () => getStyles({
	root: {
		zIndex: 100,
		paddingTop: 20
	},
	container: {
		fontFamily: 'Lato',
		fontStyle: "italic",
		textAlign: 'left',
		fontSize: 14,
		padding: 0,
	},
	actionBtn: {
		borderRadius: 15,
		minWidth: 50,
		width: '45%',
		height: 108,
		backgroundColor: colors.light,
		color: colors.text,
		'&:hover': {
			backgroundColor: colors.light
		},
		fontWeight: 400,
		fontSize: 15,
	},
	buttonContent: {
		padding: '10px',
		rowGap: 10,
		height: '100%',
	},
	icon: {
		color: colors.primary,
		height: 40,
		width: 40
	},
	text: {
		paddingBottom: 15
	}
})

const PlanningBloc = ({ classes, goToPage }) => {
	return(
	<Box container className={classes.root}>
		<Grid container justify={"flex-start"} alignItems={"center"} direction={"row"} className={classes.container}>
			<Typography className={classes.text} variant="p">
				<FormattedMessage id="tdb.blocs.planning.description"/>
			</Typography>
			<Grid container justify={"space-around"} alignItems={"center"} direction={"row"}>
				<Button
					type="secondary"
					variant="text"
					keepCase
					withBorder={false}
					className={classes.actionBtn}
					onClick={() => goToPage('/planning')}
				>
					<Grid container direction={"column"} justify={"space-between"} alignItems={"center"} className={classes.buttonContent}>
						<SpaIcon className={classes.icon}/>
						<Typography variant="p">
							<FormattedMessage id="tdb.blocs.planning.blocs.culture"/>
						</Typography>
					</Grid>
				</Button>
				<Button
					type="secondary"
					variant="text"
					keepCase
					withBorder={false}
					className={classes.actionBtn}
					onClick={() => goToPage('/planification-taches')}
				>
					<Grid container direction={"column"} justify={"space-between"} alignItems={"center"} className={classes.buttonContent}>
						<ListAlt className={classes.icon}/>
						<Typography variant="p">
							<FormattedMessage id="tdb.blocs.planning.blocs.taches"/>
						</Typography>
					</Grid>
				</Button>
			</Grid>
		</Grid>
	</Box>
)}

const actions = {
	goToPage: (url) => dispatch => dispatch(push(url))
}

export default compose(
	connect(null, actions),
	withStyles(styles))(PlanningBloc)