import { DateTime } from 'luxon'
import { assertNotNullOrUndefined } from '../utils'
import { Temporal } from './temporal'

/**
 * Méthode permettant de manipuler uniquement la partie time d'un objet {@link Date} js. <br>
 * Cette classe est sérializable, donc elle peut directement être envoyée au back.
 */
export class LocalTime extends Temporal {
	static fromAPI = (temporal) => {
		if (assertNotNullOrUndefined(temporal)) {
			return new LocalTime(DateTime.now().setZone(Temporal.API_TIMEZONE).set(Temporal._splitTime(temporal)))
		}
		return temporal
	}

	static fromJS = (date) => new LocalTime(DateTime.fromJSDate(date, { zone: Temporal.API_TIMEZONE }))

	static fromCalendar = (date) => {
		if (date instanceof Date) {
			return LocalTime.fromJS(date)
		}
		if (typeof date === 'string') {
			return LocalTime.fromAPI(date)
		}
		if (date instanceof LocalTime) {
			return date
		}
		console.error('Type inconnu', date)
		throw new Error(`Type inconnu ${typeof date}`)
	}

	static now = () => new LocalTime(DateTime.now())

	format = (timeFormat) => this.toDisplayDate(timeFormat, undefined)

	today = () => this._date.toJSDate()

	toJSON = () => {
		return this.format()
	}
}
