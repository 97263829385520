import {Grid, Typography, withStyles} from '@material-ui/core'
import {getStyles} from 'isotope-client'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import EmptyIcon from './icon/EmptyIcon'
import {colors} from '../utils/constants'
import PropTypes from 'prop-types'

const styles = () => getStyles({
	container: {
		height: '100%',
		overflow: 'hidden',
		'& span': {
			color: colors.empty
		},
		'& > div': {
			padding: '0.75rem'
		}
	},
	content: {
		'& span': {
			display: 'block',
			whiteSpace: 'break-spaces',
			textAlign: 'center',
			color: colors.empty
		}
	},
	mobile:{
		fontFamily:'Lato',
		fontSize:14,
		color: colors.empty
	}
})

const NoResultPage = ({ classes, mobileListe, emptyStateMessageId }) => (
	<Grid
		container
		justify="center"
		alignItems="center"
		direction="column"
		spacing={0}
		className={classes.container}
	>
		<Grid item>
			<EmptyIcon height="3.5em" width="3.5em" />
		</Grid>

		{!mobileListe &&
		<Grid item>
			<Typography variant="h3">
				<FormattedMessage id="planning.filter.form.empty.title" />
			</Typography>
		</Grid>
		}

		<Grid item>
			<Typography variant="body1" className={!mobileListe ? classes.content : classes.mobile}>
				{!mobileListe
					? <FormattedMessage id={emptyStateMessageId || 'planning.filter.form.empty.content'} />
					: <FormattedMessage id={`planning.filter.form.empty.${mobileListe}`} />
				}
			</Typography>
		</Grid>
	</Grid>
)

NoResultPage.propTypes = {
	classes: PropTypes.object,
	mobileListe: PropTypes.bool,
	emptyStateMessageId: PropTypes.string
}

export default withStyles(styles)(NoResultPage)
