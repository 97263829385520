import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'

const FormattedMessageWithBold = ({id, values, style}) => (
	<FormattedMessage style={style} id={id} values={{...values, b: (chunks) => <b>{chunks}</b>}}/>
)

FormattedMessageWithBold.propType = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object,
	style: PropTypes.object
}

export default FormattedMessageWithBold