import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Checkbox from '../../../../../components/form/Checkbox'
import FormattedMessageWithBold from '../../../../../components/FormattedMessageWithBold'
import { colors } from '../../../../../utils/constants'
import { TOUR_PLAINE_LAUNCH_SOLVER_VALUE, TOUR_PLAINE_VALIDATION_FORM } from '../../tool/tourPlaine.constants'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		rowGap: 20,
		overflowY: 'hidden',
		maxHeight: 'calc(100vh - 220px)'
	},
	text: {
		fontSize: 14,
		lineHeight: '17px',
		fontWeight: '400',
		color: colors.text
	}
})

/**
 * SaveTourDialogForm
 * @param handleSubmit
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const SaveTourDialogForm = ({ handleSubmit, classes }) => {

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Grid style={{ margin: '1.5em' }}>
				<Grid><Typography className={classes.text}><FormattedMessage id="tourPlaine.dialog.canSaveTP.description" /></Typography></Grid><br />
				<Grid><Typography className={classes.text}><FormattedMessageWithBold id="tourPlaine.dialog.canSaveTP.reminder" /></Typography></Grid><br />
				<Field
					name={TOUR_PLAINE_LAUNCH_SOLVER_VALUE}
					component={Checkbox}
					label={<Typography className={classes.text}>
						<FormattedMessage id="tourPlaine.dialog.canSaveTP.checkbox" />
					</Typography>
					}
				/>
			</Grid>
		</form>
	)
}

SaveTourDialogForm.propType = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, { ...props }) => {
	return ({
		initialValues: {
			TOUR_PLAINE_LAUNCH_SOLVER_VALUE: true
		},
		...props
	})
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: TOUR_PLAINE_VALIDATION_FORM,
		destroyOnUnmount: true,
		enableReinitialize: true
	}),
	withStyles(styles)
)
(SaveTourDialogForm)
