import { fetchFactory } from 'isotope-client'
import { baseUri } from '../../../service/tourPlaineApi'

const contextePedoclimatiqueBaseUri = `${baseUri}/contextePedoclimatique`

/**
 * getContextePedoclimatique
 * @param dateDebut
 * @returns {Promise<*>}
 */
export const getContextePedoclimatique = (dateDebut) => fetchFactory(`${contextePedoclimatiqueBaseUri}/${dateDebut}`)

/**
 * createOrUpdate Weather for a day
 * @param values
 * @returns {Promise<*>}
 */
export const updateContextePedoclimatique = (values) => fetchFactory(contextePedoclimatiqueBaseUri, {
    method: 'POST',
    body: JSON.stringify(values)
})