import React from 'react'
import Button from '../../../components/Button'
import { FormattedMessage } from 'react-intl'
import { DialogContext } from '../../../components/layout/dialog'
import { TYPE_DIALOG } from '../../../utils/constants'
import DialogCookies from './DialogCookies'

/**
 * Injecteur pour l'ouverture de la popin de cookies
 */
export const injectDialogCookies = (WrappedComponent) => {
	const DialogCookiesInjector = (props) => {
		const { openDialog, closeDialog } = React.useContext(DialogContext)

		const openDialogCookies = (handleClick) => openDialog({ id: 'cookies.dialog.title' },
			<DialogCookies/>,
			[
				<Button
					type="primary"
					onClick={() => {
						handleClick()
						closeDialog()
					}}
				>
					<FormattedMessage id="actions.accept" />
				</Button>
			], TYPE_DIALOG.COOKIE)

		return <WrappedComponent {...props} openDialogCookies={openDialogCookies} />
	}

	return (DialogCookiesInjector)
}
