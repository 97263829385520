import { withStyles } from '@material-ui/styles'
import { TableCell as MuiTableCell } from '@material-ui/core'

const TableCell =  withStyles({
	root: {
		borderRight: '1px solid #c0c0c0',
		textAlign: 'center'
	},
	head: {
		fontWeight: 600
	},
	body: {
		height: 40
	}
})(MuiTableCell)

export default TableCell