import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import Buttons from './components/Buttons'
import Content from './components/Content'
import ToolBar from './components/ToolBar'

const styles = () => getStyles({
	container: {
		backgroundColor: '#FFF',
		minHeight: 'calc(100vh - 64px)'
	},
	img: {
		width: '100%'
	}
})

const AccueilPage = ({ classes }) => (
	<Grid
		container
		direction="column"
		wrap="nowrap"
		className={classes.container}
	>
		<Grid item>
			<ToolBar/>
		</Grid>
		<Grid>
			<img className={classes.img} src="/img/accueil/bandeau.jpg" alt="bandeau"/>
		</Grid>
		<Grid>
			<Content/>
		</Grid>
		<Grid>
			<Buttons/>
		</Grid>
	</Grid>
)

export default withStyles(styles)(AccueilPage)
