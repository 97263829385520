import React, { useEffect, useState } from 'react'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../../../../../utils/constants'
import { useTourPlaineContext } from '../../../TourPlaineContext'
import Loader from '../../../../../../components/layout/Loader'
import SimpleListView from '../../../../../../components/layout/simpleListView/SimpleListView'
import PropTypes from 'prop-types'

/**
 * Styles
 */
const styles = () => getStyles({
	root: {
		background: colors.etapeBackground,
		marginTop: '20px'
	},
	header: {
		rowGap: '10px',
		padding: '10px 13px'
	},
	titleText: {
		lineHeight: '25px',
		fontSize: 18,
		fontWeight: '600'
	},
	descriptionText: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '300',
		fontStyle: 'italic',
		color: colors.text
	},
	body: {
		columnGap: 55,
		marginTop: 15,
		height: 'calc(100vh - 334px)'
	},
	placeholder: {
		paddingLeft: 13
	}
})

/**
 * PlanSpatial
 * @returns {JSX.Element}
 */
const PlanSpatial = ({ classes, onSelect }) => {
	const { loadSuiviBlock, data: { suiviBlock } } = useTourPlaineContext()

	const [isLoading, setIsLoading] = useState(true)

	/**
	 * UseEffect
	 */
	useEffect(() => {
		setIsLoading(true)
		loadSuiviBlock().finally(() => setIsLoading(false))
	}, [])

	return (<>
			<Grid container direction="column" className={classes.header}>
				<Typography className={classes.titleText}>
					<FormattedMessage id="tourPlaine.suiviParBloc.title" />
				</Typography>
				<Typography className={classes.descriptionText}>
					<FormattedMessage id="tourPlaine.suiviParBloc.description" />
				</Typography>
			</Grid>
			{
				isLoading ?
					(
						<Loader show={isLoading} />
					) : (
						<Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.body}>
							<Grid item container xs={6} justify="center" >
								{
									suiviBlock.imageUri ?
										(
											<img
												alt="plan-ferme"
												height="100%"
												width="100%"
												src={suiviBlock.imageUri}
												style={{
													maxHeight: 500,
													width: 'auto'
												}}
											/>
										) : (
											<Typography variant="body1" className={classes.placeholder}>
												<FormattedMessage id="tourPlaine.suiviParBloc.plan.placeholder" />
											</Typography>
										)
								}
							</Grid>
							<Grid item xs>
								<SimpleListView
									datas={(suiviBlock.listBlock || [])}
									handleSelection={onSelect}
									maxHeight={'calc(100vh - 334px)'}
								/>
							</Grid>
						</Grid>
					)
			}
		</>
	)
}

PlanSpatial.propTypes = {
	onSelect: PropTypes.func,
	classes: PropTypes.object
}
export default compose(
	withStyles(styles)
)(PlanSpatial)