import React from 'react'
import { ETAT_TACHE_LIBELLE, ETAT_TACHE_UPPERCASE, TYPE_RECURRENCE } from '../../../../utils/constants'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import { convertMinToHours, getJoursRecurrenceTache, listeJoursDeRecurrenceFormatString } from '../../../../utils/utils'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Alert from '@material-ui/icons/NotificationImportant'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/Button'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { PANEL_TYPE } from './constants'
import Input from '../../../../components/form/Input'
import { Field } from 'redux-form'
import Radio from '../../../../components/form/Radio'
import { FILTER_FIELDS } from '../form/FilterForm'
import InfoTache from '../../../common/information/InfoTache'
import FormattedMessageWithBold from "../../../../components/FormattedMessageWithBold";
import { WEATHER_TILE_TYPE_API } from "../../tourPlaine/component/contextePedoclimatique/service/contextePedoclimatiqueApiMapper";

/** Méthode permettant de :
 * → Générer la première partie de section des panels :
 * →→ tacheDetailPanel
 * →→ SuiviDeTacheForm
 **/
export const getDescriptionTache = (currentTache, classes, panelType, isFromPageSuivi, isOngletPlanif) => {
	const infosRecurrence = currentTache.tacheRecurrence
	const checkedEtat = isFromPageSuivi ? currentTache.statut : currentTache.etat
	const actions = [
		<Button
			type="secondary"
			startIcon={<PictureAsPdfIcon color="currentColor" />}
			onClick={() => window.open(currentTache.doc, '_blank')
			}
		>
			<FormattedMessage id="gestionTaches.ecranAffectations.panels.buttons.instructions" />
		</Button>
	]

	return (
		{
			icon: currentTache.enRetard ? <Alert className={classes.enRetardIcon}/> // PRM290
				: checkedEtat === ETAT_TACHE_UPPERCASE.A_PLANIFIER
				? <FiberManualRecordIcon className={classes.iconTachePlanifier} />
				: checkedEtat === ETAT_TACHE_UPPERCASE.A_REALISER
					? <FiberManualRecordIcon className={classes.iconTacheRealiser} />
					: checkedEtat === ETAT_TACHE_UPPERCASE.EN_COURS
						? <PlayCircleFilledIcon className={classes.iconTacheEnCoursOuTerminee} />
						: <CheckCircleIcon className={classes.iconTacheEnCoursOuTerminee} />,
			title:
				<Grid container item direction={'row'} justify='space-between' alignItems='center'>
					<Grid>
						<InfoTache tache={currentTache} />
					</Grid>
					<Grid className={checkedEtat === ETAT_TACHE_UPPERCASE.A_PLANIFIER
						? classes.textPlanifier
						: checkedEtat === ETAT_TACHE_UPPERCASE.A_REALISER
							? classes.textRealiser
							: classes.textEnCoursOuTerminee}>

						<span className={classes.infoTache}>
							<FormattedMessage id="gestionTaches.ecranAffectations.panels.sections.infoTache"
							                  values={{
								                  etat: checkedEtat === ETAT_TACHE_UPPERCASE.A_PLANIFIER
									                  ? ETAT_TACHE_LIBELLE.A_PLANIFIER
									                  : checkedEtat === ETAT_TACHE_UPPERCASE.A_REALISER
										                  ? ETAT_TACHE_LIBELLE.A_REALISER
										                  : checkedEtat === ETAT_TACHE_UPPERCASE.EN_COURS
											                  ? ETAT_TACHE_LIBELLE.EN_COURS
											                  : ETAT_TACHE_LIBELLE.TERMINEE
							                  }}
							/>
						</span>
					</Grid>
				</Grid>,

			content:
				<Grid>
					{currentTache.description &&
					<Grid className={classes.description}>
						{(currentTache.description.length > 0) &&
						<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.descriptionTache"
						                  values={{
							                  description: currentTache.description
						                  }}
						/>}
					</Grid>
					}

					{(currentTache.doc && currentTache.doc.trim() !== '' && (panelType === PANEL_TYPE.TACHE_DETAIL)) &&
					<Grid className={classes.instructions}>{actions[0]}</Grid>
					}

					<Grid className={classes.detailsTache}>
						<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.realisation"
						                  values={{ date: <b>{moment(currentTache.date).format('DD/MM/YYYY')}</b> }} />
						<br />

						{infosRecurrence ?
							infosRecurrence.periodeType === TYPE_RECURRENCE.SEMAINE
								? <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.recurrenceSemaine"
								                    values={{
									                    jours: <b>{listeJoursDeRecurrenceFormatString(getJoursRecurrenceTache(infosRecurrence))}</b>,
									                    periode: <b>{infosRecurrence.periodeNb}</b>,
									                    dateDebut: <b>{moment(infosRecurrence.recurrenceDebut).format('DD/MM/YYYY')}</b>,
									                    dateFin: <b>{moment(infosRecurrence.recurrenceFin).format('DD/MM/YYYY')}</b>
								                    }} />
								: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.recurrenceMois"
								                    values={{
									                    periode: <b>{infosRecurrence.periodeNb}</b>,
									                    dateDebut: <b>{moment(infosRecurrence.recurrenceDebut).format('DD/MM/YYYY')}</b>,
									                    dateFin: <b>{moment(infosRecurrence.recurrenceFin).format('DD/MM/YYYY')}</b>
								                    }} />
							: <></>
						}
						{infosRecurrence && <br />}

						{currentTache.flagDivisible
							? <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.infosTacheDivisible"
							                    values={{ personnes: <b>{currentTache.nbPersonnes} personne(s)</b> }} />
							: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.infosTacheIndivisible"
							                    values={{ personnes: <b>{currentTache.nbPersonnes} personne(s)</b> }} />
						}
						<br />

						<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.estimationTempsTache"
						                  values={{ duree: <b>{convertMinToHours(currentTache.tempsMoyFixe)}</b> }}
						/>

						{
							isOngletPlanif && <>
								<br/><br/>
								<FormattedMessageWithBold id="gestionTaches.ecranAffectations.panels.content.priorite"
														  values={{priorite: currentTache.priorite}}
								/>
							</>
						}

						{(panelType === PANEL_TYPE.SUIVI_DE_PRODUCTION) &&
						<Grid className={classes.formInput}>
							<br />
							{(checkedEtat !== ETAT_TACHE_UPPERCASE.A_PLANIFIER) &&
							<Grid>
								<span className={classes.text}><FormattedMessage id="gestionTaches.ecranAffectations.panels.content.etat" /></span>
								<br />

								{checkedEtat === ETAT_TACHE_UPPERCASE.A_REALISER
									? <Field name="choixEtat" component={Radio}
									         choices={[{
										         value: ETAT_TACHE_UPPERCASE.A_REALISER,
										         label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.checkboxRealiser" />
									         }, {
										         value: ETAT_TACHE_UPPERCASE.TERMINEE,
										         label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.checkboxTermine" />
									         }]}
									         column
									         style={{ justifyContent: 'space-between' }}
									         containerClass={classes.radio}
									/>
									: <Field name="choixEtat" component={Radio}
									         choices={[{
										         value: ETAT_TACHE_UPPERCASE.EN_COURS,
										         label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.checkboxEnCours" />
									         }, {
										         value: ETAT_TACHE_UPPERCASE.TERMINEE,
										         label: <FormattedMessage id="gestionTaches.ecranAffectations.panels.content.checkboxTermine" />
									         }]}
									         column
									         style={{ justifyContent: 'space-between' }}
									         containerClass={classes.radio}
									/>
								}

								<br />
							</Grid>
							}

							<Field
								name="commentaire"
								component={Input}
								multiline
								label={<FormattedMessage id="gestionTaches.ecranAffectations.panels.content.commentaireLabel" />}
							/>
						</Grid>

						}
					</Grid>
				</Grid>
		})
}

/** Méthode permettant de :
 * → Formatter les values des panels de filtres (gestionTaches)
 * Utilisation dans :
 *      → AffectationTable.jsx
 *      → PlanificationTable.jsx
 *      → OrdonnancementTable.jsx
 **/
export const formatFilters = (filters) => {
	return Object.keys(filters).reduce((acc, curr) => {
		if (curr === FILTER_FIELDS.ONLYNONAFFECTE.name || curr === FILTER_FIELDS.RETARD.name) {
			return ({
				...acc,
				[curr]: filters[curr]
			})
		}

		if (curr === FILTER_FIELDS.STATUT.name) {
			/** Ici le multi select 'status' envoi directement l'information formatée */
			return ({
				...acc,
				[curr]: Object.values(filters[curr].map(statusTache => statusTache))
			})
		}

		if ((curr === FILTER_FIELDS.CHARGEINF.name || curr === FILTER_FIELDS.CHARGESUP.name)) {
			/** Valeurs des champs chargeInf et chargeSup */
			if (filters[curr] !== null) {
				return ({
					...acc,
					[curr]: filters[curr]
				})
			}
			return ({
				...acc,
				[curr]: null
			})

		}

		/** Les autres valeurs du form sous forme d'objet */
		return ({
			...acc,
			[curr]: Object.values(filters[curr].map(n => n.code))
		})
	}, {})
}

export const displaySensibilite = (sensibilite, intl) => {
	const fields = sensibilite.split('-')
	const categorie = Object.entries(WEATHER_TILE_TYPE_API).find(([_, value]) => value.toLowerCase() === fields[0].replace('_', '').toLowerCase())[0].toLowerCase()
	const statut = fields[1].toLowerCase()
	const impact = fields[2]
	return `${intl.formatMessage({id: `tourPlaine.contextePedoclimatique.tileType.${categorie}`})} - ${intl.formatMessage({id: `tourPlaine.contextePedoclimatique.icons.${categorie}.${statut}.name`})} - ${intl.formatMessage({id: `tourPlaine.contextePedoclimatique.impact.${impact}`})}`
}
