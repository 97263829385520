import Brightness1Icon from '@material-ui/icons/Brightness1'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PropTypes from 'prop-types'
import React from 'react'
import Comment from '../../../../../components/Comment'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import PlaceIcon from '../../../../../components/icon/PlaceIcon'
import { colors, ETAPE_ETAT } from '../../../../../utils/constants'
import { SUIVI_ITK_DETAIL } from '../../utils/constant'
import { getEtapeDetailTitle } from '../../utils/utils'
import Detail from '../detail/Detail'
import EtapeAssolement from '../detail/EtapeAssolement'
import SuiviEtapeForm from './component/SuiviEtapeForm'
import TypeSurface from '../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../common/information/ZoneSurface'
import InfoCulture from '../../../../common/information/InfoCulture'

const SuiviEtape = ({ assolement, assolementEtape, comment, onSubmit }) => {
	const { culture, planche } = assolement

	let sections = []
	if (culture) {
		sections = sections.concat([
			{
				icon: <CultureIcon />,
				title: culture.nom,
				path: <InfoCulture culture={culture} />,
				content: <em>{culture.cultivar}</em>,
				comment: <Comment comment={culture.commentaire} />
			}
		])
	}

	sections = sections.concat([
		{
			icon: <PlaceIcon />,
			title: planche.nom,
			path: <ZoneSurface planche={planche} />,
			content: <TypeSurface planche={planche} />
		},
		{
			icon: assolementEtape.etat === ETAPE_ETAT.TERMINE ? <CheckCircleIcon style={{ color: colors.iconeDetail }} /> :
				<Brightness1Icon style={{ color: colors.toDo }} />,
			title: getEtapeDetailTitle(assolementEtape),
			content: <>
				<EtapeAssolement assolement={assolement} assolementEtape={assolementEtape} editEtape />
				<SuiviEtapeForm assolement={assolement} formName={SUIVI_ITK_DETAIL} assolementEtape={assolementEtape}
				                comment={comment} onSubmit={onSubmit} />
			</>
		}
	])

	return <Detail sections={sections} />
}


SuiviEtape.propTypes = {
	plancheValue: PropTypes.string,
	handleSubmit: PropTypes.func
}

export default SuiviEtape
