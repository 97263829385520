import {fetchFactory, postMultipart, serializeParts} from 'isotope-client'

export const getFermeList = () => fetchFactory('/bo/ferme')

export const updateFerme = (values) => {
	return postMultipart('/bo/ferme', {
		method: 'PUT',
		body: serializeParts([{ key: 'fermeForm', value: values }, { key: 'file', value: values.file }, { key: 'matriceFile', value: values.matriceFile }])
	});
};
export const getFermeData = (idFerme) => fetchFactory(`/bo/ferme/${idFerme}`)

export const createFerme = values => fetchFactory('/bo/ferme', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const exportMatriceProximite = (idFerme, values) =>
	fetchFactory(`/bo/ferme/matriceProximite/${idFerme}`, {
		method: 'POST',
		body: JSON.stringify(values)
	})