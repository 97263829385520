import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid/index'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import TypeEtape from '../../../common/information/TypeEtape'

const styles = () => getStyles({
	container: {
		margin: '1.5em'
	}
})

const DeleteEtape = ({ assolementEtape, classes }) => {
	const etapeExist = assolementEtape && assolementEtape.type
	return (
		etapeExist && <Grid className={classes.container}>
			<Grid><FormattedMessage id="planning.etapesAssolement.deleteEtape.text" values={{ etape: <TypeEtape typeEtape={assolementEtape.type} /> }} /></Grid><br />
			<Grid><FormattedMessage id="planning.cultureForm.deleteCulture.warning" /></Grid>
		</Grid>)
}

DeleteEtape.propType = {
	assolementEtape: PropTypes.array
}

export default withStyles(styles)(DeleteEtape)
