import React from 'react'
import { getCurrentCampagne } from '../../common/campagne/campagneSelector'
import Calendar from './component/Calendar'
import ToolBar from './component/ToolBar/ToolBar'
import { initAssolementList } from './services/assolement/assolementInjector'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectCultureDetailPanel } from './services/actions/detail/cultureDetailPanel'
import { injectSurfacePanel } from './services/actions/detail/surfaceDetailPanel'
import { injectEtapeDetailPanel } from './services/actions/detail/etapeDetailPanel'
import { injectCreateCultureGroupPanel } from './services/actions/form/createCultureGroupPanel'

const PlanningComponents = ({ campagne }) => (
	<>
		<ToolBar campagne={campagne} />
		<Calendar campagne={campagne} />
	</>
)

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

export default compose(
	connect(mapStateToProps),
	initAssolementList,
	injectCultureDetailPanel,
	injectSurfacePanel,
	injectEtapeDetailPanel,
	injectCreateCultureGroupPanel
)(PlanningComponents)
