import React from 'react'
import PropTypes from 'prop-types'

const NotRainIcon = ({ height, width}) => (
	<svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_3883_79753)">
			<path d="M10.7079 26.2156C10.7079 27.3351 9.80031 28.2426 8.68086 28.2426C7.56141 28.2426 6.65387 27.3351 6.65387 26.2156C6.65387 25.1661 7.71419 23.7408 8.30106 23.0324C8.49855 22.794 8.86318 22.794 9.06067 23.0324C9.6476 23.7408 10.7079 25.1661 10.7079 26.2156Z" fill="#8AC9FE"/>
			<path d="M19.5269 26.2156C19.5269 27.3351 18.6194 28.2426 17.5 28.2426C16.3805 28.2426 15.473 27.3351 15.473 26.2156C15.473 25.1661 16.5333 23.7408 17.1201 23.0324C17.3176 22.794 17.6823 22.794 17.8798 23.0324C18.4666 23.7408 19.5269 25.1661 19.5269 26.2156Z" fill="#8AC9FE"/>
			<path d="M28.346 26.2156C28.346 27.3351 27.4385 28.2426 26.319 28.2426C25.1996 28.2426 24.2921 27.3351 24.2921 26.2156C24.2921 25.1661 25.3524 23.7408 25.9392 23.0324C26.1367 22.794 26.5014 22.794 26.6989 23.0324C27.2857 23.7408 28.346 25.1661 28.346 26.2156Z" fill="#8AC9FE"/>
			<path d="M15.1174 32.9713C15.1174 34.0907 14.2099 34.9983 13.0904 34.9983C11.971 34.9983 11.0634 34.0907 11.0634 32.9713C11.0634 31.9217 12.1237 30.4964 12.7106 29.788C12.9081 29.5496 13.2727 29.5496 13.4702 29.788C14.0571 30.4964 15.1174 31.9217 15.1174 32.9713Z" fill="#8AC9FE"/>
			<path d="M23.9365 32.9713C23.9365 34.0907 23.029 34.9983 21.9095 34.9983C20.79 34.9983 19.8825 34.0907 19.8825 32.9713C19.8825 31.9217 20.9428 30.4964 21.5297 29.788C21.7272 29.5496 22.0918 29.5496 22.2893 29.788C22.8762 30.4964 23.9365 31.9217 23.9365 32.9713Z" fill="#8AC9FE"/>
			<path d="M9.36456 28.1243C9.1506 28.2009 8.92091 28.2426 8.68097 28.2426C7.56124 28.2426 6.65411 27.3354 6.65411 26.2157C6.65411 25.1664 7.71437 23.7411 8.30089 23.0322C8.49845 22.7936 8.86349 22.7936 9.06105 23.0322C9.15265 23.1429 9.25587 23.2715 9.36456 23.413C8.77599 24.1772 8.0213 25.3304 8.0213 26.2157C8.0213 27.0955 8.58117 27.844 9.36456 28.1243Z" fill="#60B7FF"/>
			<path d="M18.1836 28.1243C17.9696 28.2009 17.7399 28.2426 17.5 28.2426C16.3803 28.2426 15.4731 27.3354 15.4731 26.2157C15.4731 25.1664 16.5334 23.7411 17.1199 23.0322C17.3175 22.7936 17.6825 22.7936 17.8801 23.0322C17.9717 23.1429 18.0749 23.2715 18.1836 23.413C17.595 24.1772 16.8403 25.3304 16.8403 26.2157C16.8403 27.0955 17.4002 27.844 18.1836 28.1243Z" fill="#60B7FF"/>
			<path d="M27.0026 28.1243C26.7887 28.2009 26.559 28.2426 26.319 28.2426C25.1993 28.2426 24.2922 27.3354 24.2922 26.2157C24.2922 25.1664 25.3524 23.7411 25.939 23.0322C26.1365 22.7936 26.5016 22.7936 26.6991 23.0322C26.7907 23.1429 26.8939 23.2715 27.0026 23.413C26.4141 24.1772 25.6594 25.3304 25.6594 26.2157C25.6594 27.0955 26.2192 27.844 27.0026 28.1243Z" fill="#60B7FF"/>
			<path d="M13.7737 34.8802C13.5605 34.9568 13.3301 34.9985 13.0901 34.9985C11.9711 34.9985 11.0633 34.0907 11.0633 32.9709C11.0633 31.9216 12.1235 30.4963 12.7108 29.7881C12.9083 29.5495 13.2727 29.5495 13.4702 29.7881C13.5618 29.8989 13.6651 30.0267 13.7744 30.1675C13.1859 30.9318 12.4305 32.085 12.4305 32.9709C12.4305 33.8507 12.991 34.5999 13.7737 34.8802Z" fill="#60B7FF"/>
			<path d="M22.5935 34.8802C22.3795 34.9568 22.1498 34.9985 21.9099 34.9985C20.7901 34.9985 19.8823 34.0907 19.8823 32.9709C19.8823 31.9216 20.9426 30.4963 21.5298 29.7881C21.7273 29.5495 22.0917 29.5495 22.2893 29.7881C22.3809 29.8989 22.4841 30.0267 22.5935 30.1675C22.0049 30.9318 21.2495 32.085 21.2495 32.9709C21.2495 33.8507 21.8101 34.5999 22.5935 34.8802Z" fill="#60B7FF"/>
			<path d="M29.6647 9.24009H27.2647C26.8976 4.07656 22.5929 0.00152588 17.336 0.00152588C13.2278 0.00152588 9.70129 2.49029 8.18084 6.04224C7.79235 5.97525 7.393 5.93997 6.98537 5.93997C3.12744 5.9399 0 9.06735 0 12.9253C0 16.7832 3.12744 19.9106 6.98537 19.9106H29.6647C32.6113 19.9106 35 17.522 35 14.5753C35 11.6288 32.6113 9.24009 29.6647 9.24009Z" fill="#ECF6FE"/>
			<path d="M19.0972 0.157112C15.7613 0.751497 13 3.0092 11.7022 6.04231C11.3134 5.97538 10.9141 5.94018 10.5064 5.94018C6.6487 5.94018 3.52119 9.06762 3.52119 12.9254C3.52119 16.7832 6.64863 19.9106 10.5064 19.9106H6.9853C3.12744 19.9106 0 16.7832 0 12.9255C0 9.06776 3.12744 5.94025 6.98523 5.94025C7.393 5.94025 7.79229 5.97545 8.18098 6.04238C9.70143 2.49022 13.2282 0.00152588 17.3359 0.00152588C17.9366 0.00152588 18.5253 0.0550513 19.0972 0.157112Z" fill="#DBEBFC"/>
		</g>
		<line x1="3.70711" y1="3" x2="33" y2="32.2929" stroke="#B70F0A" stroke-linecap="round"/>
		<line x1="3" y1="32.2929" x2="32.2929" y2="3" stroke="#B70F0A" stroke-linecap="round"/>
		<defs>
			<clipPath id="clip0_3883_79753">
				<rect width="35" height="35" fill="white"/>
			</clipPath>
		</defs>
	</svg>
)


NotRainIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

NotRainIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default NotRainIcon