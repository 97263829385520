import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../../../../../components/Button'
import { DialogContext } from '../../../../../../components/layout/dialog'
import { PAGE, usePageContext } from '../../../../../../components/layout/PageContext'
import { DetailsPanelContext } from '../../../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'
import { TYPE_DIALOG } from '../../../../../../utils/constants'
import { getNotifications } from '../../../../../common/notification/notificationActions'
import { useSolverStatusContext } from '../../../../besoinsProduction/SolverStatusContextProvider'
import DeleteCulture from '../../../component/DeleteCulture'
import { deleteAssolement } from '../../assolement/assolementAction'
import { loadAssolement } from '../../assolement/assolementInjector'

/**
 * Injecteur pour l'ouverture de la popin de suppression de culture
 */
export const injectDeleteCulture = (WrappedComponent) => {

	const CultureDeleteInjector = (props) => {
		const { openDialog, closeDialog } = React.useContext(DialogContext)
		const { closePanel } = React.useContext(DetailsPanelContext)
		const { deleteAssolement, refreshAssolements, getNotifications } = props
		const { page } = usePageContext()
		const { snackError, snackSuccess } = useSnackbar()
		const {refreshSolverInfosForCurrentCampagne} = useSolverStatusContext()

		const dialogSuppression = (title, messageOkSnackbar, listeSelectedAssolement, onSuccess) => {
			const selectedAssolementsID = listeSelectedAssolement.map((assolement) => assolement.id)

			openDialog({ id: title },
				<DeleteCulture selectedAssolements={listeSelectedAssolement} />, [
					<Button
						type="primary"
						onClick={() => {
							return deleteAssolement(selectedAssolementsID)
								.then(() => {
									closeDialog()
									closePanel()
									snackSuccess({ id: messageOkSnackbar })
									getNotifications()
									refreshSolverInfosForCurrentCampagne()
									if (refreshAssolements) {
										refreshAssolements()
									}
									if (onSuccess) {
										onSuccess(true)
									}
								})
								.catch(() => {
									snackError({ id: 'snackbar.error.suppression' })
								})
						}}
					>
						<FormattedMessage id="actions.delete" />
					</Button>
				], TYPE_DIALOG.ACTION)
		}

		const openCultureDelete = (assolement, onSuccess) => {
			const selectedAssolements = Array.isArray(assolement) ? assolement : [assolement]

			if (page === PAGE.PLANNING) {
				dialogSuppression('planning.cultureForm.deleteCulture.title', 'snackbar.update.suppressionCulture', selectedAssolements)
			} else {
				dialogSuppression('listAssolement.popup.headers.supprimerCultures', `listAssolement.snackbar.ok.${selectedAssolements.length > 1 ? 'suppression' : 'suppressionSingle'}`, selectedAssolements, onSuccess)
			}
		}
		return <WrappedComponent {...props} openCultureDelete={React.useCallback(openCultureDelete, [])} />
	}

	const actions = {
		deleteAssolement,
		getNotifications
	}

	CultureDeleteInjector.propTypes = {
		deleteAssolement: PropTypes.func,
		refreshAssolements: PropTypes.func
	}

	return compose(
		loadAssolement,
		connect(undefined, actions)
	)(CultureDeleteInjector)
}
