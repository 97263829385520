import { IconButton } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CalendarIcon from '../../../../../components/icon/CalendarIcon'
import MenuActions from '../../../../../components/MenuActions'
import { colors, PROFILS } from '../../../../../utils/constants'
import { getAccessCheckerRole } from '../../../../../utils/utils'
import { setPlanningSize } from '../../services/toolbar/toolbarAction'
import { getPlanningSize } from '../../services/toolbar/toolbarSelector'
import { MIN_ROW_SIZE, PLANNING_VISIBLE_WIDTH } from '../../utils/constant'

const styles = () => getStyles({
	icon: {
		marginLeft: 12,
		marginRight: 12,
		padding: '0.3125rem 1.5rem',
		borderRadius: 5000,
		backgroundColor: colors.selected,
		color: colors.primary,
	},
	additionalText: {
		marginLeft: "0.75rem",
		fontSize: '1rem'
	}
})

const PLANNING_RANGE = [3, 6, 9, 12]
const accessMaraicher = getAccessCheckerRole(PROFILS.MARAICHER)

const rangeFilter = (range) => {
	const colWidth = PLANNING_VISIBLE_WIDTH / (52 * range / 12)
	return colWidth > MIN_ROW_SIZE
}

const PlanningSizeSelector = ({setPlanningSize, planningSize, classes}) => {

	const onClick = (event, category, item) => {
		setPlanningSize(item.value)
	}

	const items = PLANNING_RANGE.filter(rangeFilter).map(range => ({
		name: 'month',
		action: range,
		value: range,
		access: accessMaraicher
	}))

	return (
			<MenuActions
				items={{ planningSize: items }}
				onClick={onClick}
				formattedMessageId="planning"
				placement="bottom"
			>
				<IconButton className={classes.icon}>
					<CalendarIcon />
					<div className={classes.additionalText}>
						<FormattedMessage id={`planning.planningSize.month`} values={{value: planningSize}}/>
					</div>
				</IconButton>
			</MenuActions>
	)
}

PlanningSizeSelector.propType = {}

const mapStateToProps = state => ({
	planningSize: getPlanningSize(state)
})

const actions = {
	setPlanningSize
}
export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(PlanningSizeSelector)