import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as userSelectors from '../../modules/common/user/services/userSelectors'

/**
 * Vérifie si l'utilisateur a accès à la fonctionnalité
 * @param access
 * @param userAuthorities
 * @returns {*|boolean}
 */
export const hasAccess = (access, userAuthorities) => userAuthorities && userAuthorities.some(userAuth => userAuth.authority === access.role)

/**
 * Access checker qui affiche ou non le children selon les accès spécifiés
 * @param access
 * @param userAuthorities
 * @param children
 * @returns {*}
 * @constructor
 */
export const AccessChecker = ({ access, userAuthorities, children }) => {
	if (typeof access === 'undefined') {
		return children
	}

	let accessArray = access
	if (!Array.isArray(accessArray)) {
		accessArray = [access]
	}

	if (accessArray.every(acc => !hasAccess(acc, userAuthorities))) {
		return <React.Fragment/>
	}
	return children
}

AccessChecker.propTypes = {
	userAuthorities: PropTypes.arrayOf(PropTypes.shape({
		authority: PropTypes.string
	})),
	access: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	children: PropTypes.node
}

const mapStateToProps = state => ({
	userAuthorities: userSelectors.getAuthorities(state),
})

export default connect(mapStateToProps)(AccessChecker)
