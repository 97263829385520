import { Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import Comment from '../../../../../components/Comment'
import CultureIcon from '../../../../../components/icon/CultureIcon'
import ItineraireTechniqueIcon from '../../../../../components/icon/ItineraireTechniqueIcon'
import PlaceIcon from '../../../../../components/icon/PlaceIcon'
import { EDIT_ITK_FORM_NAME } from '../../utils/constant'
import Detail from '../detail/Detail'
import ItineraireTechniqueBase from '../detail/ItineraireTechniqueBase'
import ItineraireForm from './component/ItineraireForm'
import { fields } from './NouvelleCulture'
import TypeSurface from '../../../../common/information/TypeSurface'
import ZoneSurface from '../../../../common/information/ZoneSurface'
import InfoCulture from '../../../../common/information/InfoCulture'

const styles = () => getStyles({
	form: {
		width: '94%'
	},
	margin: {
		margin: '1.5em'
	}
})

const formattedMessageMenuBase = 'planning.cultureForm'

const validate = values => {
	const errors = {}
	const requiredFields = [
		fields.ESPACEMENT,
		fields.NB_RANGS
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = { id: `${formattedMessageMenuBase}.errors.mandatory` }
		}
	})
	if (values[fields.ESPACEMENT] && values[fields.ESPACEMENT] <= 0) {
		errors[fields.ESPACEMENT] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	if (values[fields.NB_RANGS] && values[fields.NB_RANGS] <= 0) {
		errors[fields.NB_RANGS] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	if (values[fields.DENSITE] && values[fields.DENSITE] <= 0) {
		errors[fields.DENSITE] = { id: `${formattedMessageMenuBase}.errors.negative` }
	}
	return errors
}

const EditItineraire = ({ assolement, handleSubmit, classes }) => {
	const { culture, planche } = assolement

	const sections = [
		{
			icon: <CultureIcon/>,
			title: culture.nom,
			path: <InfoCulture culture={culture}/>,
			content: <em>{culture.cultivar}</em>,
			comment: <Comment comment={culture.commentaire}/>
		},
		{
			icon: <PlaceIcon/>,
			title: planche.nom,
			path: <ZoneSurface planche={planche}/>,
			content: <TypeSurface planche={planche}/>
		},
		{
			icon: <ItineraireTechniqueIcon/>,
			title: <FormattedMessage id="planning.itineraireTechnique.title"/>,
			content: <ItineraireTechniqueBase assolement={assolement}/>
		}
	]

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<Detail sections={sections}/>
			<Grid className={classes.margin}>
				<ItineraireForm editItk={true} assolement={assolement} formName={EDIT_ITK_FORM_NAME}/>
			</Grid>
		</form>
	)
}


EditItineraire.propTypes = {
	plancheValue: PropTypes.string,
	handleSubmit: PropTypes.func
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: EDIT_ITK_FORM_NAME,
		destroyOnUnmount: true,
		validate
	})
)(EditItineraire)
