import Grid from '@material-ui/core/Grid'
import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import {change, Field, formValueSelector, reduxForm} from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Input from '../../../components/form/Input'
import Select from '../../../components/form/Select'
import {MenuItem} from '@material-ui/core'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {MODELE_TACHE_EDITION_FORM} from './ModeleTachePage'
import {injectFamilleTacheValueList, injectTypeTacheValueList} from '../../common/valueLists/valueListInjectors'
import {BLOC_OU_SURFACE_CAPS, TACHE_TYPE_ENTITE, TYPE_DATE, TYPE_ENTITE_TACHE, TYPE_PERIODE_RECURRENCE} from '../../../utils/constants'
import Autocomplete from '../../../components/form/Autocomplete'
import {getAllItks, getAllPlanches, getItkDetail} from '../../fo/planning/services/planningApi'
import AutocompleteMulti from '../../../components/form/AutocompleteMulti'
import {sortObjectByLabel} from '../../../utils/utils'
import {JOURS_CAPS} from '../../fo/gestionTache/utils/constants'
import {getAllBlocs} from './modeleTacheApi'
import { OUI_NON, TYPE_MO_TACHE } from '../../fo/mobile/utils/constantsSuiviTache'
import {useSnackbar} from '../../../components/layout/snackbar/SnackbarContext'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from "../../../components/form/Radio";
import FormattedMessageWithBold from "../../../components/FormattedMessageWithBold";
import {getAllModeleTache} from "../../fo/gestionTache/services/api";
import {getCategories} from "../categoriesTechniques/services/categoriesTechniquesApi";
import {getMateriels} from "../reglagesMateriels/services/reglagesMaterielsApi";


const styles = () => getStyles({
	expandPanel: {
		width: '100%',
		paddingLeft: 30,
		paddingRight: 30,
		'&.MuiPaper-elevation1': {
			boxShadow: 'none'
		},
		'& .MuiExpansionPanelSummary-root': {
			paddingInline: 12
		}
	},
	littleRow: {
		width: '31%',
	},
	mediumRow: {
		width: '63.5%',
	},
	bigRow: {
		width: '96.3%'
	},
	label: {
		fontWeight: 'bold'
	},
	infoGenerales: {
		fontSize: 18,
		marginTop: 15,
		fontFamily: 'Lato',
		letterSpacing: 2
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: '32%'
			}
		}
	},
	header: {
		fontWeight: 700,
		fontSize: 16
	},
	insideHeader: {
		fontWeight: 700,
		fontSize: 16,
		paddingLeft: 12,
		paddingBottom: 20
	},
	dependance: {
		paddingRight: 20
	}
})

const FIELDS = {
	TYPE: {name: 'type'},
	FAMILLE: {name: 'famille'},
	FLAG_CREATION: {name: 'flagCreation'},
	TYPE_ENTITY: {name: 'typeEntity'},
	ITK: {name: 'itk'},
	FLAG_ETAPE: {name: 'flagControleEtape'},
	ETAPE_ASSOCIEE: {name: 'idItkDetail'},
	TIMELINE: {name: 'timeline'},
	DEBUT_FIN: {name: 'debutFin'},
	DESCRIPTION: {name: 'description'},
	URL_DOCU_INSTRUCTIONS: {name: 'urlDocInstruction'},
	NB_PERSONNES: {name: 'nbPersonnes'},
	FLAG_DIVISIBLE: {name: 'flagDivisible'},
	TEMPS_MOY_M2: {name: 'tempsMoyM2'},
	TEMPS_MOY_FIXE: {name: 'tempsMoyFixe'},
	FLAG_RECURRENCE: {name: 'flagRecurrence'},
	DUREE_RECURRENCE: {name: 'dureeRecurrence'},
	PERIODE_NB: {name: 'periodeNb'},
	PERIODE_TYPE: {name: 'periodeType'},
	JOURS: {name: 'jours'},
	FLAG_CREATION_CAMPAGNE: {name: 'flagCreationCampagne'},
	SURFACES_CREATION_CAMPAGNE: {name: 'surfaces'},
	BLOCS_CREATION_CAMPAGNE: {name: 'blocs'},
	CREATION_SEMAINE_N: {name: 'creationSemaineN'},
	PRIORITE: {name: 'priorite'},
	CATEGORIE_TECHNIQUE: {name: 'categorieTechnique'},
	FLAG_DEPENDANCE: {name: 'flagDependance'},
	REALISEE_AVANT: {name: 'realiseeAvant'},
	MODELE: {name: 'modele'},
	ID_TACHE_GENERIQUE: {name: 'idTacheGenerique'},
	IS_TACHE_GENERIQUE: {name: 'isTacheGenerique'},
	FLAG_ANTICIPABLE: {name: 'flagAnticipable'},
	JOURS_ANTICIPABLE: {name: 'joursAnticipable'},
	REGLAGES: {name: 'reglages'},
}

const champRequis = [FIELDS.TYPE.name,
	FIELDS.FAMILLE.name,
	FIELDS.FLAG_CREATION.name,
	FIELDS.TYPE_ENTITY.name,
	FIELDS.NB_PERSONNES.name,
	FIELDS.FLAG_DIVISIBLE.name,
	FIELDS.FLAG_RECURRENCE.name,
	FIELDS.FLAG_ANTICIPABLE.name,
	FIELDS.FLAG_CREATION_CAMPAGNE.name,
	FIELDS.CREATION_SEMAINE_N.name,
	FIELDS.CATEGORIE_TECHNIQUE.name,
	FIELDS.PRIORITE
]

const champEntierPositif = [
	FIELDS.NB_PERSONNES.name,
	FIELDS.PERIODE_NB.name,
	FIELDS.DUREE_RECURRENCE.name,
	FIELDS.TEMPS_MOY_FIXE.name,
	FIELDS.TEMPS_MOY_M2.name,
	FIELDS.PRIORITE,
	FIELDS.JOURS_ANTICIPABLE.name
]
const validate = values => {
	const errors = {}
	if (displayItk(values.typeEntity)) {
		if (!values[FIELDS.ITK.name]) {
			errors[FIELDS.ITK.name] = {id: 'global.errors.mandatory'}
		}
	}
	if (displayEtape(values.typeEntity)) {
		if (!values[FIELDS.ETAPE_ASSOCIEE.name]) {
			errors[FIELDS.ETAPE_ASSOCIEE.name] = {id: 'global.errors.mandatory'}
		}
		if (!values[FIELDS.FLAG_CREATION.name]) {
			errors[FIELDS.FLAG_CREATION.name] = {id: 'global.errors.mandatory'}
		}
	}
	if (values[FIELDS.FLAG_CREATION.name]) {
		if (!values[FIELDS.FLAG_ETAPE.name]) {
			errors[FIELDS.FLAG_ETAPE.name] = {id: 'global.errors.mandatory'}
		}
		if (!values[FIELDS.TIMELINE.name] && `${values[FIELDS.TIMELINE.name]}` !== '0') {
			errors[FIELDS.TIMELINE.name] = {id: 'global.errors.mandatory'}
		} else if (values[FIELDS.TIMELINE.name] < 0 && values[FIELDS.DEBUT_FIN.name] === TYPE_DATE.DEBUT && values[FIELDS.FLAG_ETAPE.name] === OUI_NON.OUI.value) {
			errors[FIELDS.TIMELINE.name] = {id: 'global.errors.zeroOrPositive'}
		} else if (values[FIELDS.TIMELINE.name] > 0 && values[FIELDS.DEBUT_FIN.name] === TYPE_DATE.FIN && values[FIELDS.FLAG_ETAPE.name] === OUI_NON.OUI.value) {
			errors[FIELDS.TIMELINE.name] = {id: 'global.errors.zeroOrNegative'}
		}
		if (!values[FIELDS.DEBUT_FIN.name]) {
			errors[FIELDS.DEBUT_FIN.name] = {id: 'global.errors.mandatory'}
		}
	}
	if (displayEtape(values[FIELDS.TYPE_ENTITY.name]) || displaySurface(values[FIELDS.TYPE_ENTITY.name])) {
		if (!values[FIELDS.TEMPS_MOY_M2.name]) {
			errors[FIELDS.TEMPS_MOY_M2.name] = {id: 'global.errors.mandatory'}
		}
	} else if (!values[FIELDS.TEMPS_MOY_FIXE.name]) {
		errors[FIELDS.TEMPS_MOY_FIXE.name] = {id: 'global.errors.mandatory'}
	}
	if (values[FIELDS.FLAG_RECURRENCE.name]) {
		if (!values[FIELDS.DUREE_RECURRENCE.name]) {
			errors[FIELDS.DUREE_RECURRENCE.name] = {id: 'global.errors.mandatory'}
		}
		if (!values[FIELDS.PERIODE_NB.name]) {
			errors[FIELDS.PERIODE_NB.name] = {id: 'global.errors.mandatory'}
		}
		if (!values[FIELDS.PERIODE_TYPE.name]) {
			errors[FIELDS.PERIODE_TYPE.name] = {id: 'global.errors.mandatory'}
		} else if (values[FIELDS.PERIODE_TYPE.name] === TYPE_PERIODE_RECURRENCE.SEMAINES.value) {
			if (!values[FIELDS.JOURS.name]) {
				errors[FIELDS.JOURS.name] = {id: 'global.errors.mandatory'}
			}
		}
	}
	if (!displayAssolement(values[FIELDS.TYPE_ENTITY.name])) {
		if (!values[FIELDS.FLAG_CREATION_CAMPAGNE.name]) {
			errors[FIELDS.FLAG_CREATION_CAMPAGNE.name] = {id: 'global.errors.mandatory'}
		} else if (values[FIELDS.FLAG_CREATION_CAMPAGNE.name] && !values[FIELDS.SURFACES_CREATION_CAMPAGNE.name]) {
			errors[FIELDS.SURFACES_CREATION_CAMPAGNE.name] = {id: 'global.errors.mandatory'}
		} else if (values[FIELDS.FLAG_CREATION_CAMPAGNE.name] && !values[FIELDS.BLOCS_CREATION_CAMPAGNE.name]) {
			errors[FIELDS.BLOCS_CREATION_CAMPAGNE.name] = {id: 'global.errors.mandatory'}
		}
	}
	if (values[FIELDS.DESCRIPTION.name]) {
		if (values[FIELDS.DESCRIPTION.name].length > 249) {
			errors[FIELDS.DESCRIPTION.name] = <FormattedMessage id="bo.modeleTache.form.descriptionTropLongue"/>
		}
	}

	if (values[FIELDS.CREATION_SEMAINE_N.name] > 52) {
		errors[FIELDS.CREATION_SEMAINE_N.name] = <FormattedMessage id="bo.modeleTache.form.semaineTropGrande"/>
	}
	champRequis.forEach(
		champ => {
			if (!values[champ]) {
				errors[champ] = {id: 'global.errors.mandatory'}
			}
		}
	)
	champEntierPositif.forEach(
		champ => {
			if (!(values[champ]) || (values[champ] && values[champ] < 0)) {
				errors[champ] = {id: 'global.errors.zeroOrPositive'}
			}
		}
	)
	return errors
}

const formattedMessageBase = 'bo.modeleTache.form'

const getTypeEtapeOptions = (itk) => getItkDetail(itk).then(res => res.map(value => ({label: value.typeEtape, value: value.id})))

const getSurfacesOptions = (idFerme) => getAllPlanches(idFerme).then(res => res.map(planche => ({label: planche.nom, code: planche.id}))).then(sortObjectByLabel)

const getBlocsOptions = (idFerme) => getAllBlocs(idFerme).then(res => res.map(bloc => ({label: bloc.nom, code: bloc.id}))).then(sortObjectByLabel)

const displayItk = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ASSOLEMENT || typeEntity === TACHE_TYPE_ENTITE.ETAPE

const displayEtape = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ETAPE

const displaySurface = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.SURFACE

const displayBlocs = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.BLOC

const displayAssolement = (typeEntity) => typeEntity === TACHE_TYPE_ENTITE.ASSOLEMENT

const initializeJours = (selectedMoTache) => {
	let jours = []
	if (selectedMoTache.recurrenceLightBean != null) {
		Object.values(JOURS_CAPS).forEach((value) => {
			if (selectedMoTache.recurrenceLightBean[value.toLowerCase()] === true) {
				jours.push(value)
			}
		})
	}
	return jours
}

const initializeSurfaces = (selectedMoTache) => selectedMoTache.mo_asso_auto_tache_entity.filter(asso => asso.typeEntity === BLOC_OU_SURFACE_CAPS.SURFACE).map(surface => ({label: surface.nom, code: `${surface.idEntity}`}))
const initializeBlocs = (selectedMoTache) => selectedMoTache.mo_asso_auto_tache_entity.filter(asso => asso.typeEntity === BLOC_OU_SURFACE_CAPS.BLOC).map(bloc => ({label: bloc.nom, code: `${bloc.idEntity}`}))

const PopinModeleTache = ({
							  classes,
							  handleSubmit,
							  selectedMoTache,
							  typeTacheList,
							  familleTacheList,
							  flagCreation,
							  idFerme,
							  typeEntity,
							  flagRecurrence,
							  flagCreationCampagne,
							  itk,
							  modele,
							  periodeType,
							  flagDependance,
							  etapeAssocie,
							  flagAnticipable,
							  famille,
							  intl,
							  error,
							  change,
							  categorie
						  }) => {
	const [itkOptions, setItkOptions] = React.useState([])
	const [categorieTechnique, setCategorieTechnique] = React.useState([])
	const [moTacheListDependance, setMoTacheListDependance] = React.useState([])
	const [moTacheListVariante, setMoTacheListVariante] = React.useState([])

	const {snackError} = useSnackbar()
	const getTypeEntityOptions = () => sortObjectByLabel(Object.values(TYPE_ENTITE_TACHE)
		.map(val => ({label: intl.formatMessage({id: `enums.typeEntity.${val}`}), value: val})))
		.map((val, idx) => <MenuItem key={idx} value={val.value}>{val.label}</MenuItem>)
	const [etapeAssocieeOptions, setEtapeAssocieeOptions] = React.useState([])
	const getItkOptions = React.useCallback(() => new Promise((resolve) => resolve(getAllItks(idFerme).then(res => {
		return res.map(value => ({label: value.designation, code: `${value.id}`, value: value.id, key: value.id}))
	}))), [idFerme])

	React.useEffect(() => {
		if (itk) {
			getTypeEtapeOptions(itk).then(setEtapeAssocieeOptions)
		}
		getItkOptions().then(setItkOptions)
	}, [getItkOptions, itk])

	React.useEffect(() => {
		// Permet de mettre à null le champ idTacheGenerique lorsque le type de tache change
		if (modele !== TYPE_MO_TACHE.VARIANTE) {
			change(FIELDS.ID_TACHE_GENERIQUE.name, null)
		}
	}, [modele])

	React.useEffect(() => {
		// Permet de charger les motaches du selecteur de la liste des dependances
		if (itk) {
			getAllModeleTache(idFerme, null, itk, etapeAssocieeOptions.find(etape => etape.value === etapeAssocie)?.label).then(setMoTacheListDependance)
		}
		// Permet de charger les motaches du selecteur de la liste variante
		if (modele === TYPE_MO_TACHE.VARIANTE) {
			getAllModeleTache(idFerme, true, itk, null, typeEntity, selectedMoTache?.moTacheLightBean?.id).then(setMoTacheListVariante)
		}
		getCategories({idFerme}).then(setCategorieTechnique)
	}, [itk, etapeAssocieeOptions, etapeAssocie, typeEntity, modele])

	React.useEffect(() => {
		if (modele === TYPE_MO_TACHE.GENERIQUE) {
			change(FIELDS.IS_TACHE_GENERIQUE.name, true)
		} else {
			change(FIELDS.IS_TACHE_GENERIQUE.name, false)
		}
	}, [modele])

	React.useEffect(() => {
		change(FIELDS.PRIORITE.name, familleTacheList.find(familleTache => familleTache.id === famille)?.priority)
	}, [famille])

	React.useEffect(() => error && snackError(<FormattedMessage id={`${formattedMessageBase}.invalidCodeTache`}/>), [error, snackError])

	const getReglages = () => {
		return getMateriels({idFerme, categorieTechniqueId: categorie})
			.then(materiels => {
				if (!materiels || !Array.isArray(materiels)) return [];
				return materiels.flatMap(materiel =>
					(materiel?.reglages || []).map(reglage => ({
						code: reglage?.id,
						label: `${materiel?.nom} - ${reglage?.nom}`
					}))
				);
			});
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container>
				<ExpansionPanel className={classes.expandPanel} defaultExpanded={true}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.header}

					>
						<FormattedMessage id={`${formattedMessageBase}.caracteristiquesGenerales`}/>
					</ExpansionPanelSummary>
					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.FAMILLE.name}
							component={Autocomplete}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FAMILLE.name}`}/>}
							fromBo
							options={
								familleTacheList.map(famille => ({
									code: famille.id,
									label: famille.label
								}))
							}
						/>
						<Field
							name={FIELDS.TYPE.name}
							component={Autocomplete}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE.name}`}/>}
							fromBo
							options={
								typeTacheList.map(typeTache => ({
									code: typeTache.id,
									label: typeTache.label
								}))
							}
						/>
						<Field
							name={FIELDS.PRIORITE.name}
							component={Input}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PRIORITE.name}`}/>}
							value={34}
							fromBo
						/>
					</Grid>
					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.TYPE_ENTITY.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TYPE_ENTITY.name}`}/>}
							fromBo
						>
							{getTypeEntityOptions()}
						</Field>
						{displayItk(typeEntity) && itkOptions.length > 0 ?
							<Field
								name={FIELDS.ITK.name}
								component={Autocomplete}
								containerClassName={classes.littleRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ITK.name}`}/>}
								options={itkOptions}
								defaultValue={itk && {label: itkOptions.find(value => value.code === itk), code: itk}}
								fromBo
							/>
							:
							<React.Fragment/>
						}
						{(itk && displayEtape(typeEntity)) ?
							<Field
								name={FIELDS.ETAPE_ASSOCIEE.name}
								component={Select}
								containerClassName={classes.littleRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ETAPE_ASSOCIEE.name}`}/>}
								fromBo
							>
								{etapeAssocieeOptions.map((etape, index) => (<MenuItem key={index} value={etape.value}>
									{<FormattedMessage id={`enums.etape.${etape.label}`}/>}
								</MenuItem>))}
							</Field>
							:
							<React.Fragment/>}
					</Grid>
					<Grid container item justify={"flex-start"}>

						{
							(itk && displayEtape(typeEntity)) ?
								<Field
									name={FIELDS.FLAG_CREATION.name}
									component={Select}
									containerClassName={classes.littleRow}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_CREATION.name}`}/>}
									fromBo
								>
									{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
										{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
									</MenuItem>)}
								</Field>
								: <React.Fragment/>
						}

					</Grid>
					{
						flagCreation === OUI_NON.OUI.value
							? <Grid container item justify={'flex-start'}>
								<Field
									name={FIELDS.TIMELINE.name}
									component={Input}
									type="number"
									containerClassName={classes.littleRow}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TIMELINE.name}`}/>}
									fromBo
								/>
								<Field
									name={FIELDS.DEBUT_FIN.name}
									component={Select}
									containerClassName={classes.littleRow}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DEBUT_FIN.name}`}/>}
									fromBo
								>
									<MenuItem key={1} value={TYPE_DATE.DEBUT}>
										{<FormattedMessage id={`${formattedMessageBase}.debutEtape`}/>}
									</MenuItem>
									<MenuItem key={2} value={TYPE_DATE.FIN}>
										{<FormattedMessage id={`${formattedMessageBase}.finEtape`}/>}
									</MenuItem>
								</Field>
								<Field
									name={FIELDS.FLAG_ETAPE.name}
									component={Select}
									containerClassName={classes.littleRow}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_ETAPE.name}`}/>}
									fromBo
								>
									{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
										{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
									</MenuItem>)}
								</Field>
							</Grid>
							: <React.Fragment/>
					}
					<Grid container item justify={"flex-start"}>
						{categorieTechnique?.length > 0 && <Field
							name={FIELDS.CATEGORIE_TECHNIQUE.name}
							component={Autocomplete}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.CATEGORIE_TECHNIQUE.name}`}/>}
							fromBo
							options={
								categorieTechnique?.map(categorie => ({
									code: categorie.id,
									label: `${categorie.nom} (${categorie.code})`
								}))
							}
						/>}
						{categorie && <Field
							name={FIELDS.REGLAGES.name}
							component={AutocompleteMulti}
							containerClassName={classes.mediumRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.REGLAGES.name}`}/>}
							fromBo
							forceGetOptions
							formName={MODELE_TACHE_EDITION_FORM}
							getOptions={() => getReglages()}
						/>}
					</Grid>
				</ExpansionPanel>
				<ExpansionPanel className={classes.expandPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.header}
					>
						<FormattedMessage id={`${formattedMessageBase}.informationsPratiques`}/>
					</ExpansionPanelSummary>
					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.DESCRIPTION.name}
							component={Input}
							containerClassName={classes.bigRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DESCRIPTION.name}`}/>}
							fromBo
						/>
					</Grid>
					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.URL_DOCU_INSTRUCTIONS.name}
							component={Input}
							containerClassName={classes.bigRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.URL_DOCU_INSTRUCTIONS.name}`}/>}
							fromBo
						/>
						<Grid container item justify={"flex-start"}>
							<Field
								name={FIELDS.NB_PERSONNES.name}
								component={Input}
								containerClassName={classes.littleRow}
								type="number"
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.NB_PERSONNES.name}`}/>}
								fromBo
							/>
							{
								(displaySurface(typeEntity) || displayEtape(typeEntity))
									? <Field
										name={FIELDS.TEMPS_MOY_M2.name}
										component={Input}
										containerClassName={classes.littleRow}
										type="number"
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TEMPS_MOY_M2.name}`}/>}
										fromBo
									/>
									: <Field
										name={FIELDS.TEMPS_MOY_FIXE.name}
										component={Input}
										containerClassName={classes.littleRow}
										type="number"
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.TEMPS_MOY_FIXE.name}`}/>}
										fromBo
									/>
							}
							<Field
								name={FIELDS.FLAG_DIVISIBLE.name}
								component={Select}
								containerClassName={classes.littleRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_DIVISIBLE.name}`}/>}
								fromBo
							>
								{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
									{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
								</MenuItem>)}
							</Field>
						</Grid>
					</Grid>

					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.FLAG_ANTICIPABLE.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_ANTICIPABLE.name}`}/>}
							fromBo
						>
							{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
							</MenuItem>)}
						</Field>
						{flagAnticipable === OUI_NON.OUI.value && (
							<Field
								name={FIELDS.JOURS_ANTICIPABLE.name}
								component={Input}
								containerClassName={classes.littleRow}
								type="number"
								adornment={<FormattedMessage id={`${formattedMessageBase}.JOURS`}/>}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.JOURS_ANTICIPABLE.name}`}/>}
								fromBo
							/>)
						}
					</Grid>
				</ExpansionPanel>

				<ExpansionPanel className={classes.expandPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.header}
					>
						<FormattedMessage id={`${formattedMessageBase}.recurrence`}/>
					</ExpansionPanelSummary>
					<Grid container item justify={"flex-start"}>
						<Field
							name={FIELDS.FLAG_RECURRENCE.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_RECURRENCE.name}`}/>}
							fromBo
						>
							{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
							</MenuItem>)}
						</Field>
					</Grid>
					{
						flagRecurrence === OUI_NON.OUI.value
							? <Grid container item justify={"flex-start"}>
								<Field
									name={FIELDS.DUREE_RECURRENCE.name}
									component={Input}
									containerClassName={classes.littleRow}
									type="number"
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.DUREE_RECURRENCE.name}`}/>}
									fromBo
								/>
								<Field
									name={FIELDS.PERIODE_NB.name}
									component={Input}
									containerClassName={classes.littleRow}
									type="number"
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PERIODE_NB.name}`}/>}
									fromBo
								/>
								<Field
									name={FIELDS.PERIODE_TYPE.name}
									component={Select}
									containerClassName={classes.littleRow}
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.PERIODE_TYPE.name}`}/>}
									fromBo
								>
									{Object.entries(TYPE_PERIODE_RECURRENCE).map(([key, typePeriode]) => <MenuItem key={key} value={typePeriode.value}>
										{<FormattedMessage id={`${formattedMessageBase}.${typePeriode.value}`}/>}
									</MenuItem>)}
								</Field>
							</Grid>
							: <React.Fragment/>
					}
					{
						flagRecurrence === OUI_NON.OUI.value && periodeType === TYPE_PERIODE_RECURRENCE.SEMAINES.value
							? <Grid container item justify={"flex-start"}>
								<Field
									name={FIELDS.JOURS.name}
									component={Select}
									containerClassName={classes.littleRow}
									multiple
									label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.JOURS.name}`}/>}
									fromBo
								>
									{
										Object.values(JOURS_CAPS).map((jour, index) => (
											<MenuItem key={index} value={jour} classes={{selected: classes.menuItem}}>
												{<FormattedMessage id={`enums.jours.${jour}`}/>}
											</MenuItem>
										))
									}
								</Field>
							</Grid>
							: <React.Fragment/>
					}
					{
						typeEntity && !displayAssolement(typeEntity) && !displayEtape(typeEntity)
							? <div>
								<Grid container item className={classes.insideHeader}>
									<FormattedMessage id={`${formattedMessageBase}.creationAutomatique`}/>
								</Grid>
								<Grid container justify={"flex-start"}>
									<Field
										name={FIELDS.FLAG_CREATION_CAMPAGNE.name}
										component={Select}
										containerClassName={classes.littleRow}
										label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_CREATION_CAMPAGNE.name}`}/>}
										fromBo
									>
										{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
											{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
										</MenuItem>)}
									</Field>
									{
										flagCreationCampagne === OUI_NON.OUI.value
											? <Field
												name={FIELDS.CREATION_SEMAINE_N.name}
												component={Input}
												containerClassName={classes.mediumRow}
												type="number"
												label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.CREATION_SEMAINE_N.name}`}/>}
												formName={MODELE_TACHE_EDITION_FORM}
												fromBo
											/>
											: <React.Fragment/>
									}
									{
										flagCreationCampagne === OUI_NON.OUI.value && displaySurface(typeEntity)
											? <Field
												name={FIELDS.SURFACES_CREATION_CAMPAGNE.name}
												component={AutocompleteMulti}
												containerClassName={classes.mediumRow}
												label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.SURFACES_CREATION_CAMPAGNE.name}`}/>}
												getOptions={() => getSurfacesOptions(idFerme)}
												formName={MODELE_TACHE_EDITION_FORM}
												fromBo
											/>
											: <React.Fragment/>
									}
									{
										flagCreationCampagne === OUI_NON.OUI.value && displayBlocs(typeEntity)
											? <Field
												name={FIELDS.BLOCS_CREATION_CAMPAGNE.name}
												component={AutocompleteMulti}
												containerClassName={classes.mediumRow}
												label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.BLOCS_CREATION_CAMPAGNE.name}`}/>}
												getOptions={() => getBlocsOptions(idFerme)}
												forceGetOptions
												formName={MODELE_TACHE_EDITION_FORM}
												fromBo
											/>
											: <React.Fragment/>
									}
								</Grid>
							</div>
							: <React.Fragment/>
					}
				</ExpansionPanel>
				<ExpansionPanel className={classes.expandPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.header}
					>
						<FormattedMessage id={`${formattedMessageBase}.dependance`}/>
					</ExpansionPanelSummary>
					<Grid container item justify={"flex-start"} alignItems={"center"}>
						<Field
							name={FIELDS.FLAG_DEPENDANCE.name}
							component={Select}
							containerClassName={classes.littleRow}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.FLAG_DEPENDANCE.name}`}/>}
							fromBo
						>
							{Object.entries(OUI_NON).map(([key, ouiNon]) => <MenuItem key={key} value={ouiNon.value}>
								{<FormattedMessage id={`${formattedMessageBase}.${ouiNon.value}`}/>}
							</MenuItem>)}
						</Field>
						{moTacheListDependance.length > 0 ? flagDependance === OUI_NON.OUI.value && typeTacheList && (
							<Field
								name={FIELDS.REALISEE_AVANT.name}
								component={Autocomplete}
								containerClassName={classes.mediumRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.REALISEE_AVANT.name}`}/>}
								fromBo
								options={
									moTacheListDependance.map(tache => ({
										code: tache.id,
										label: typeTacheList.find(type => type.code === tache?.typeTache)?.label
									}))
								}
							/>) : flagDependance === OUI_NON.OUI.value &&
							<Grid item className={classes.dependance}>
								<FormattedMessage id={`${formattedMessageBase}.aucuneDependance`}/>
							</Grid>
						}
					</Grid>
				</ExpansionPanel>
				<ExpansionPanel className={classes.expandPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.header}
					>
						<FormattedMessage id={`${formattedMessageBase}.typeTache`}/>
					</ExpansionPanelSummary>
					<Grid container item justify={"flex-start"}>
						<Field name={FIELDS.MODELE.name}
							   component={Radio}
							   choices={[{
								   value: TYPE_MO_TACHE.INDEPENDANTE,
								   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.independante`}/>
							   }, {
								   value: TYPE_MO_TACHE.GENERIQUE,
								   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.generique`}/>
							   }, {
								   value: TYPE_MO_TACHE.VARIANTE,
								   label: <FormattedMessageWithBold id={`${formattedMessageBase}.fields.modele.variante`}/>
							   }]}
							   row
							   style={{justifyContent: 'space-between'}}
							   containerClass={classes.radio}
						/>
					</Grid>
					{moTacheListVariante.length > 0 ? modele === TYPE_MO_TACHE.VARIANTE && (
						<Grid container item justify={"flex-start"}>
							<Field
								name={FIELDS.ID_TACHE_GENERIQUE.name}
								component={Autocomplete}
								containerClassName={classes.bigRow}
								label={<FormattedMessage id={`${formattedMessageBase}.fields.${FIELDS.ID_TACHE_GENERIQUE.name}`}/>}
								fromBo
								options={
									moTacheListVariante.map(tache => ({
										code: tache.id,
										label: intl.formatMessage({ id: 'bo.modeleTache.form.fields.modele.selector'}, {
											famille: familleTacheList.find(famille => famille.code === tache?.familleTache)?.label ,
											tache: typeTacheList.find(type => type.code === tache?.typeTache)?.label,
											categorie: tache?.categorieTechnique?.nom,
											moTache: tache.id
										})
									}))
								}
							/>
						</Grid>) : modele === TYPE_MO_TACHE.VARIANTE &&
						<Grid container item justify={"center"}>
							<FormattedMessage id={`${formattedMessageBase}.aucuneVariante`}/>
						</Grid>
					}
				</ExpansionPanel>
			</Grid>
		</form>
	)
}

PopinModeleTache.propType = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	selectedMoTache: PropTypes.object,
	typeTacheList: PropTypes.object,
	familleTacheList: PropTypes.object,
	flagCreation: PropTypes.bool,
	flagDependance: PropTypes.bool,
	flagAnticipable: PropTypes.bool,
	famille: PropTypes.number,
	etapeAssocie: PropTypes.object,
	modele: PropTypes.string,
	idFerme: PropTypes.number,
	typeEntity: PropTypes.string,
	itk: PropTypes.number,
	categorie: PropTypes.string
}

const mapStateToProps = (state, {selectedMoTache}) => {
	if (selectedMoTache) {
		return ({
			form: MODELE_TACHE_EDITION_FORM,
			initialValues: {
				id: selectedMoTache.moTacheLightBean?.id,
				famille: selectedMoTache.famille?.id,
				type: selectedMoTache.type?.id,
				flagCreation: selectedMoTache.moTacheLightBean?.flagCreationAssolement ? OUI_NON.OUI.value : OUI_NON.NON.value,
				typeEntity: selectedMoTache.moTacheLightBean?.typeEntity,
				itk: selectedMoTache.moTacheLightBean?.itk && `${selectedMoTache.moTacheLightBean?.itk.id}`,
				flagControleEtape: selectedMoTache.moTacheLightBean?.flagControleEtape && selectedMoTache.moTacheLightBean?.flagControleEtape ? OUI_NON.OUI.value : OUI_NON.NON.value,
				etapeAssociee: selectedMoTache.moTacheLightBean?.etapeAssociee,
				timeline: selectedMoTache.moTacheLightBean?.timeline,
				debutFin: selectedMoTache.moTacheLightBean?.debutFin && selectedMoTache.moTacheLightBean?.debutFin ? TYPE_DATE.DEBUT : TYPE_DATE.FIN,
				description: selectedMoTache.moTacheLightBean?.description,
				flagDivisible: selectedMoTache.moTacheLightBean?.flagDivisible && selectedMoTache.moTacheLightBean?.flagDivisible ? OUI_NON.OUI.value : OUI_NON.NON.value,
				tempsMoyM2: selectedMoTache.moTacheLightBean?.tempsMoyM2?.toFixed(2),
				tempsMoyFixe: selectedMoTache.moTacheLightBean?.tempsMoyFixe,
				flagRecurrence: selectedMoTache.moTacheLightBean?.flagRecurrence && selectedMoTache.moTacheLightBean?.flagRecurrence ? OUI_NON.OUI.value : OUI_NON.NON.value,
				dureeRecurrence: selectedMoTache?.recurrenceLightBean && selectedMoTache.recurrenceLightBean?.dureeRecurrence,
				periodeNb: selectedMoTache?.recurrenceLightBean && selectedMoTache.recurrenceLightBean?.periodeNb,
				periodeType: selectedMoTache?.recurrenceLightBean && selectedMoTache.recurrenceLightBean?.periodeType,
				jours: selectedMoTache?.recurrenceLightBean && initializeJours(selectedMoTache),
				flagCreationCampagne: selectedMoTache.moTacheLightBean?.flagCreationCampagne && selectedMoTache.moTacheLightBean?.flagCreationCampagne ? OUI_NON.OUI.value : OUI_NON.NON.value,
				creationSemaineN: selectedMoTache.moTacheLightBean?.creationSemaineN || null,
				blocs: selectedMoTache?.mo_asso_auto_tache_entity && initializeBlocs(selectedMoTache),
				surfaces: selectedMoTache?.mo_asso_auto_tache_entity && initializeSurfaces(selectedMoTache),
				nbPersonnes: selectedMoTache.moTacheLightBean?.nbPersonnes,
				idItkDetail: selectedMoTache.moTacheLightBean?.idItkDetail,
				urlDocInstruction: selectedMoTache.moTacheLightBean?.urlDocInstruction,
				priorite: selectedMoTache.moTacheLightBean?.priorite,
				categorieTechnique: selectedMoTache.moTacheLightBean?.categorieTechnique?.id,
				isTacheGenerique: selectedMoTache.moTacheLightBean?.isTacheGenerique,
				idTacheGenerique: selectedMoTache.moTacheLightBean?.idTacheGenerique,
				modele: selectedMoTache.moTacheLightBean?.isTacheGenerique ? TYPE_MO_TACHE.GENERIQUE : (selectedMoTache.moTacheLightBean?.idTacheGenerique != null ? TYPE_MO_TACHE.VARIANTE : TYPE_MO_TACHE.INDEPENDANTE),
				flagDependance: selectedMoTache.assoMoTachePrecedenteLightBean && selectedMoTache.assoMoTachePrecedenteLightBean ? OUI_NON.OUI.value : OUI_NON.NON.value,
				realiseeAvant: selectedMoTache.assoMoTachePrecedenteLightBean?.idMoTachePrecedente,
				flagAnticipable: selectedMoTache.moTacheLightBean?.flagAnticipable && selectedMoTache.moTacheLightBean?.flagAnticipable ? OUI_NON.OUI.value : OUI_NON.NON.value,
				joursAnticipable: selectedMoTache.moTacheLightBean?.joursAnticipable,
				reglages: selectedMoTache.moTacheLightBean?.reglages
			},
			flagCreation: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_CREATION.name),
			flagDependance: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_DEPENDANCE.name),
			etapeAssocie: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.ETAPE_ASSOCIEE.name),
			modele: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.MODELE.name),
			typeEntity: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.TYPE_ENTITY.name),
			itk: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.ITK.name),
			flagRecurrence: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_RECURRENCE.name),
			flagCreationCampagne: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_CREATION_CAMPAGNE.name),
			periodeType: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.PERIODE_TYPE.name),
			famille: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FAMILLE.name),
			realiseeAvant: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.REALISEE_AVANT.name),
			idTacheGenerique: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.ID_TACHE_GENERIQUE.name),
			flagAnticipable: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_ANTICIPABLE.name),
			categorie: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.CATEGORIE_TECHNIQUE.name),
		})
	}
	return ({
		form: MODELE_TACHE_EDITION_FORM,
		flagCreation: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_CREATION.name),
		typeEntity: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.TYPE_ENTITY.name),
		itk: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.ITK.name),
		flagRecurrence: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_RECURRENCE.name),
		famille: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FAMILLE.name),
		modele: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.MODELE.name),
		flagDependance: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_DEPENDANCE.name),
		flagAnticipable: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_ANTICIPABLE.name),
		flagCreationCampagne: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.FLAG_CREATION_CAMPAGNE.name),
		periodeType: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.PERIODE_TYPE.name),
		categorie: formValueSelector(MODELE_TACHE_EDITION_FORM)(state, FIELDS.CATEGORIE_TECHNIQUE.name),
	})

}

const actions = {
	change
}


export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	injectTypeTacheValueList,
	injectFamilleTacheValueList,
)(PopinModeleTache)
