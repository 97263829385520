import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import Button from '../../../components/Button'
import BackOfficeContainer, { BO_FILTER_FORM } from '../BackOfficeContainer'
import BoListView from '../components/BoListView'
import { cloturerCampagne, createCampagne, getCampagnesList } from './services/campagneApi'
import { useSnackbar } from '../../../components/layout/snackbar/SnackbarContext'
import { DialogContext } from '../../../components/layout/dialog'
import { ETAT_CAMPAGNE, FIN_CAMPAGNE, TYPE_DIALOG } from '../../../utils/constants'
import { EventAvailable } from '@material-ui/icons'
import { EventBusy } from '@material-ui/icons'
import { connect } from 'react-redux'
import { getPreferences } from '../../common/user/services/userSelectors'
import PropTypes from 'prop-types'
import NoResultPage from '../../../components/NoResultPage'
import moment from 'moment'
import { compose } from 'redux'
import { getStyles } from 'isotope-client'
import { withStyles } from '@material-ui/core'
import FormButton from '../../../components/form/FormButton'
import { FORM_NAME_CREATION } from './services/CampagneForm'
import CampagneForm from './services/CampagneForm'
import { change } from 'redux-form'

const styles = () => getStyles({
	popinText: {
		padding: 20
	},
	blocButton: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 30
	},
	button: {
		padding: '0 20px 0 20px'
	}
})

const formatTuiles = (campagnes) =>
	campagnes.map(campagne => ({
		uniqueId: `campagne-${campagne.id}`,
		icon: campagne.finished ? EventBusy : EventAvailable,
		title: campagne.nom,
		description: campagne.finished ? FIN_CAMPAGNE.CLOTUREE.label : FIN_CAMPAGNE.EN_COURS.label,
		data: campagne
	}))


const CampagnePage = ({
	idFerme,
	classes,
	change
}) => {
	const [filters, setFilters] = React.useState({ idFerme })
	const [selection, setSelection] = React.useState(undefined)
	const [campagnes, setCampagnes] = React.useState([])
	const { snackSuccess, snackError } = useSnackbar()
	const { openDialog, closeDialog } = React.useContext(DialogContext)

	React.useEffect(() => {
		getCampagnesList(filters).then((campagnes) => {
			const newCampagnes = formatTuiles(campagnes)
			setCampagnes(newCampagnes)
			const now = moment()
			const campagneActuelle = newCampagnes.find(campagne => moment(campagne.data.dateDebut).isBefore(now) && moment(campagne.data.dateFin).isAfter(now))
			if (!filters.disableSelection) {
				setSelection(campagneActuelle || newCampagnes[0])
			}
		})
	}, [filters])

	const campagnesPrecedentesCloturees = (idCampagne) => {
		const index = campagnes.findIndex(campagne => campagne.data.id === idCampagne)
		const campagnesPrecedentes = campagnes.slice().splice(index+1, campagnes.length)
		return campagnesPrecedentes.every(campagne => campagne.data.finished)
	}

	const handleCloture = (idCampagne) => {
		return cloturerCampagne(idCampagne).then(campagne => {
			snackSuccess({ id: 'bo.campagne.snackbar.cloture.success' })
			const newCampagne = formatTuiles([campagne])[0]
			setCampagnes(state => {
				const newCampagnes = [...state]

				const index = newCampagnes.findIndex(campagne => campagne.data.id === idCampagne)
				if (index !== -1) {
					newCampagnes[index] = newCampagne
				}

				return newCampagnes
			})
			setSelection(newCampagne)
		}).catch(() => snackError({ id: 'bo.campagne.snackbar.cloture.error' }))
	}

	const openPopinCloture = (campagne, nom) => {
		if (!campagnesPrecedentesCloturees(campagne.id)) {
			snackError({ id: 'bo.campagne.snackbar.cloture.impossible' })
		} else if (campagne.etat === ETAT_CAMPAGNE.BROUILLON) {
			snackError({ id: 'bo.campagne.snackbar.cloture.etat' })
		} else {
			openDialog(
				<FormattedMessage id="bo.campagne.popin.cloture.title" />,
				<FormattedMessage id="bo.campagne.popin.cloture.text" values={{ nom }}>
					{text => <div className={classes.popinText}>{text}</div>}
				</FormattedMessage>,
				[
					<Button
						type="primary"
						onClick={() => {
							handleCloture(campagne.id)
							closeDialog()
						}}
					>
						<FormattedMessage id="bo.campagne.popin.cloture.button" />
					</Button>
				], TYPE_DIALOG.ACTION
			)
		}
	}

	const handleCreation = values => {
		return createCampagne({ ...values, debut: moment(values.debut, 'DD/MM/YYYY').format('YYYY-MM-DD'), fin: moment(values.fin, 'DD/MM/YYYY').format('YYYY-MM-DD') })
			.then((campagne) => {
				closeDialog()
				snackSuccess({ id: 'bo.campagne.snackbar.creation.success' })
				if (campagne.idFerme !== filters.idFerme) {
					setFilters({ idFerme: campagne.idFerme, disableSelection: true })
					change(BO_FILTER_FORM, 'idFerme', campagne.idFerme)
				}
				const newTuile = formatTuiles([campagne])[0]
				campagnes.unshift(newTuile)
				setCampagnes(campagnes)
				setSelection(newTuile)
			}).catch((e) => snackError({ id: 'bo.campagne.snackbar.creation.error' }))
	}

	const openPopinCreation = () => {
		openDialog(
			<FormattedMessage id="bo.campagne.popin.creation.title" />,
			<CampagneForm
				onSubmit={handleCreation}
				idFerme={filters.idFerme}
				campagnesList={campagnes}
			/>,
			[
				<FormButton
					type="primary"
					formName={FORM_NAME_CREATION}
				>
					<FormattedMessage id="bo.campagne.popin.creation.button" />
				</FormButton>
			], TYPE_DIALOG.ACTION
		)
	}

	return (
		<>
			<Grid container justify="space-between">
				<Typography variant="h2">
					<FormattedMessage id="bo.campagne.title" />
				</Typography>
				<Button type="secondary" keepCase onClick={openPopinCreation}><FormattedMessage id="bo.campagne.newBtn" /></Button>
			</Grid>
			<BackOfficeContainer inputLabel="bo.campagne.inputQuery" onSubmit={setFilters} withInput={false}>
				{
					campagnes.length > 0 ?
						<BoListView
							titleLeft={<FormattedMessage id="bo.campagne.title" />}
							titleRight={selection && selection.data && selection.data.nom}
							tuileList={campagnes}
							selection={selection}
							handleSelection={setSelection}
							withButton={false}
						>
							{
								selection && selection.data &&
									<>
										<Grid item>
											<FormattedMessage
												id="bo.campagne.infosCampagne"
												values={{ debut: moment(selection.data.dateDebut).format("DD/MM/YYYY"), fin: moment(selection.data.dateFin).format("DD/MM/YYYY") }}
											/>
										</Grid>
										{
											!selection.data.finished &&
												<Grid item className={classes.blocButton}>
													<Button
														type="secondary"
														onClick={() => openPopinCloture(selection.data, selection.data.nom)}
														className={classes.button}
														keepCase
													>
														<FormattedMessage id="bo.campagne.buttonCloture" />
													</Button>
												</Grid>
										}
									</>
							}
						</BoListView>
					: <NoResultPage emptyStateMessageId="bo.campagne.emptyMessage" />
				}
			</BackOfficeContainer>
		</>
	)
}

CampagnePage.propTypes = {
	idFerme: PropTypes.string,
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	idFerme: getPreferences(state).FERME_ID
})

const actions = {
	change
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(CampagnePage)
