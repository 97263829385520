import { fetchFactory, urlUtils } from 'isotope-client'

export const getCampagnesList = filters => fetchFactory(urlUtils.buildUrlWithParams('/bo/campagne', filters))

export const cloturerCampagne = (idCampagne) => fetchFactory(urlUtils.buildUrlWithParams('/bo/campagne', { idCampagne }), {
	method: 'PUT'
})

export const createCampagne = values => fetchFactory('/bo/campagne', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getAssolement = (filters, pageable) => fetchFactory(urlUtils.buildUrlWithParams('/assolement/list', pageable), {
	method: 'POST',
	body: JSON.stringify(filters)
})