import { Grid } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import FormButton from '../../../../components/form/FormButton'
import { ActionPanelContext } from '../../../../components/layout/rightPanels'
import { useSnackbar } from '../../../../components/layout/snackbar/SnackbarContext'
import { updateTache } from '../../gestionTache/services/api'
import UpdateTaskForm from '../component/panel/UpdateTaskForm'
import { PANEL_FORM } from '../tool/tourPlaine.constants'

/**
 * Inject the actions Form
 */
export const injectTaskUpdateForm = (WrappedComponent) => {

	const TaskUpdateFormInjector = ({
		...props
	}) => {
		const { snackError, snackSuccess } = useSnackbar()
		const { openPanel, closePanel } = useContext(ActionPanelContext)

		const formActions = [
			<FormButton type="primary" formName={PANEL_FORM.UPDATE_TASK}>
				<FormattedMessage id="tourPlaine.form.submit" />
			</FormButton>
		]
		const openTaskUpdateForm = (task, onSuccess) => {
			closePanel()
			openPanel(
				<Grid container alignItems="center" style={{ gap: 20 }}>
					<EditIcon color="primary" />
					<FormattedMessage id='tourPlaine.form.updateTask.title'/>
				</Grid>,
				<UpdateTaskForm
					task={task}
					onSuccess={onSuccess}
					onSubmit={values => {
						updateTache(task.id, { ...values, ressources: values.ressources.map(r => r.code) })
							.then(() => {
								onSuccess(true)
								closePanel()
								snackSuccess({ id: 'snackbar.update.tourPlaine.generic' })
							})
							.catch(() => snackError({ id: 'snackbar.error.tourPlaine.generic' }))
						closePanel()
					}}
				/>,
				formActions
			)
		}

		return <WrappedComponent
			{...props}
			openTaskUpdateForm={openTaskUpdateForm}
		/>
	}

	return TaskUpdateFormInjector
}
