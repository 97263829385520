import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {getStyles} from 'isotope-client'
import {FormattedDate, FormattedMessage} from 'react-intl'
import {colors} from '../../../../../utils/constants'
import {ClickAwayListener, Grid, IconButton, Popper, Typography} from '@material-ui/core'
import CalendarIcon from '../../../../../components/icon/CalendarIcon'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Button from '../../../../../components/Button'
import DateField from '../../../../../components/form/DateField'
import classnames from "classnames";
import {LocalDate} from "../../../../../utils/date/local-date";

const styles = () => getStyles({
	icon: {
		padding: '0.3125rem 1.5rem',
		borderRadius: 100,
	},
	iconNoInput: {
		backgroundColor: colors.selected,
		color: colors.primary,
	},
	iconInput: {
		backgroundColor: colors.lightGrey,
		color: colors.text,
	},
	additionalText: {
		marginLeft: '0.75rem',
		fontSize: '14px',
		fontFamily: 'Lato'
	},
	popper: {
		background: 'white',
		border: '1px solid rgb(214, 214, 214)',
		padding: '1em',
		zIndex: 2000,
	},
	emptyPeriodDate: {
		letterSpacing: 2
	},
	errorMessage: {
		fontSize: 11
	},
	disabled: {
		'& > span': {
			marginLeft: 25,

			'& > span': {
				fontSize: 11,
				color: colors.empty,
				margin: 6
			},

			'& > b': {
				fontSize: 14
			}
		}
	}
})

const PeriodSelector = (
	{
		classes,
		period: {dateDebut, dateFin},
		meta,
		change,
		reset,
		handleSubmit,
		currentValues: {dateDebut: currentDebut, dateFin: currentFin},
		bornes,
		disabled,
		withTitle,
		isInput,
		fromBesoinsProduction,
		periodsItk
	}
) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClose = React.useCallback(() => {
		setAnchorEl(null)
		reset()
	}, [reset, setAnchorEl])

	const submit = React.useCallback(() => {
		handleSubmit()
		handleClose()
	}, [handleSubmit, handleClose])

	const shouldDisableDate = React.useCallback((date, isDebut) => {
		if (bornes) {
			const day = date.getDay()
			const localDate = LocalDate.fromJSWithTimezone(date, 'UTC+2')
			const isDateNotBetweenBornes = localDate?.lt(LocalDate.fromAPI(bornes?.dateDebut)) || localDate?.gt(LocalDate.fromAPI(bornes?.dateFin))
			return isDateNotBetweenBornes || (fromBesoinsProduction && ((!isDebut && day !== 0) || (isDebut && day !== 1)))
		}
		return false
	}, [bornes])
	const borneDebut = React.useMemo(() => bornes?.dateDebut && LocalDate.fromAPI(bornes?.dateDebut)?._date.toJSDate(), [bornes])
	const borneFin = React.useMemo(() => bornes?.dateFin && LocalDate.fromAPI(bornes?.dateFin)?._date.toJSDate(), [bornes])

	const emptyPeriodDate = <span className={classes.emptyPeriodDate}><FormattedMessage id="emptyPeriodDate"/></span>

	return <>
		<Grid container alignItems={"center"} direction="column">
			{disabled ?
				<Typography className={classes.disabled}>
						<span>
							<span><FormattedMessage id="besoinsProduction.date.debut"/></span> <b><FormattedDate value={dateDebut}/></b> <span><FormattedMessage id="besoinsProduction.date.fin"/></span> <b><FormattedDate value={dateFin}/></b>
						</span>
				</Typography>
				: <IconButton className={classnames((isInput ? classes.iconInput : classes.iconNoInput), classes.icon)} onClick={event => setAnchorEl(event.currentTarget)}>
					<CalendarIcon/>
					<div className={classes.additionalText}>
						{dateDebut ? <FormattedDate value={dateDebut}/> : emptyPeriodDate}
						-
						{dateFin ? <FormattedDate value={dateFin}/> : emptyPeriodDate}
					</div>
				</IconButton>
			}
			{meta && meta.error && (
				<Typography className={classes.errorMessage} variant="colorError">
					<FormattedMessage id="bo.blocSurface.error"/>
				</Typography>
			)}
		</Grid>
		<Popper
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			className={classes.popper}
			placement="bottom"
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: true,
					boundariesElement: 'scrollParent',
				},
				offset: {
					offset: '0,0'
				}
			}}
		>
			<ClickAwayListener
				mouseEvent="onMouseDown"
				onClickAway={handleClose}
			>
				<Grid container direction="column">
					{withTitle && <Grid item container direction="row" justify="center">
						<FormattedMessage id="listAssolement.dateFilter.title">
							{text => <Typography variant="h6" gutterBottom>{text}</Typography>}
						</FormattedMessage>
					</Grid>}
					<Grid item container direction="row">
						<Grid item xs={6} container direction="column" alignItems="center">
							<FormattedMessage id="listAssolement.dateFilter.start">
								{text => <Typography variant="body1" gutterBottom>{text}</Typography>}
							</FormattedMessage>
							<Field
								name="dateDebut"
								component={DateField}
								variant="static"
								onInputChange={value => {
									if (value > currentFin) {
										change('dateFin', value)
									}
								}}
								shouldDisableDate={shouldDisableDate}
								minDate={borneDebut}
								maxDate={borneFin}
								periods={periodsItk}
								isDebut
							/>
						</Grid>
						<Grid item xs={6} container direction="column" alignItems="center">
							<FormattedMessage id="listAssolement.dateFilter.end">
								{text => <Typography variant="body1" gutterBottom>{text}</Typography>}
							</FormattedMessage>
							<Field
								name="dateFin"
								component={DateField}
								variant="static"
								onInputChange={value => {
									if (value < currentDebut) {
										change('dateDebut', value)
									}
								}}
								shouldDisableDate={shouldDisableDate}
								minDate={borneDebut}
								maxDate={borneFin}
								periods={periodsItk}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" justify="flex-end">
						<Button onClick={submit}>
							<FormattedMessage id="actions.validate"/>
						</Button>
					</Grid>
				</Grid>
			</ClickAwayListener>
		</Popper>
	</>
}

PeriodSelector.propTypes = {
	classes: PropTypes.object,
	meta: PropTypes.object,
}

const mapStateToProps = (state, {formName}) => ({
	form: formName,
	currentValues: formValueSelector(formName)(state, 'dateDebut', 'dateFin')
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		enableReinitialize: true
	}),
	withStyles(styles)
)(PeriodSelector)
