/** CONSTANTES TECHNIQUES **/

export const ROOT_BO = '/bo'
export const TIMEOUT_PANEL = 500
export const DEFAULT_LABEL = 'N/A' // Si nous n'avons pas de valeur pour une entrée de liste
export const DEFAULT_LANGUAGE_ID = '1' /// Français par défaut
export const DEFAULT_CODE = 'N/A' //Code du MenuItem s'il n'y a pas le label recherché dans la liste de valeur
export const DEFAULT_VALUE_LIST_ITEM = {
	label: 'N/A',
	code: 'N/A'
}

export const TYPE_DIALOG = {
	DETAILS: 'DETAILS',
	ACTION: 'ACTION',
	COOKIE: 'COOKIE'
}

export const colors = {
	primary: '#804180',
	primaryResultat: '#2374AB',
	lightPrimary: '#DBC7DE',
	lightPrimaryResultat: '#C1D7E5',
	secondary: '#B29F9F',
	input: 'white',
	placeholder: '#88888a',
	icon: '#88888a',
	commentHeader: '#88888a',
	text: '#161617',
	foBackgroundColorPage: '#e2e8e8',
	appBarBackground: '#B29FB0',
	appBarColor: '#000000',
	emptyColor: 'rgba(255, 255, 255, 0.7)',
	iconeDetail: '#36A964',
	toDo: '#ffc957',
	iconRevised: '#d9d1c8',
	etapeBackground: '#F7F5F8',
	etapeContrastBackground: '#FFF',
	borderPlan: '#D6D6D6',
	link: '#804180',
	empty: '#929292',
	selected: '#D8C7DE',
	selectedWithOpacity: 'rgba(216, 199, 222, 0.5)',
	warning: '#ffa834',
	error: '#e61610',
	selectedRow: '#d4cbdb',
	border: '#cccccc',
	blue: '#75a9f9',
	valid: '#36A964',
	light: 'rgba(225,214,233,0.32)',
	tacheAPlanifier: '#73a6f7',
	tacheARealiser: '#ffc957',
	tacheEnCoursOuTerminee: '#3fd53c',
	backgroundSuiviTacheAvancement: 'rgba(225,214,233,0.32)',
	logout: '#ffffff',
	whiteSwitch: '#fafafa',
	lightGrey: '#f7f6f6',
	alerte: '#CA500F'
}

// Profils de l'application
export const PROFILS = {
	MANAGER: { nom: 'MANAGER', id: 5, role: 'ROLE_MANAGER' },
	MARAICHER: { nom: 'MARAICHER', id: 4, role: 'ROLE_MARAICHER' },
	CHEF: { nom: 'CHEF_CULTURE', id: 3, role: 'ROLE_CHEF_CULTURE' },
	SUPERVISEUR: { nom: 'SUPERVISEUR', id: 2, role: 'ROLE_SUPERVISEUR' },
	ADMIN: { nom: 'ADMIN', id: 1, role: 'ROLE_ADMIN' }
}

export const TYPE_RESSOURCE = {
	MANAGER: { nom : 'MANAGER', id : 5 },
	MARAICHER: { nom : 'MARAICHER', id : 4 },
	CHEF: { nom : 'CHEF_CULTURE', id : 3 },
	SUPERVISEUR: { nom : 'SUPERVISEUR', id : 2 }
}

export const TYPE_ABSENCE = {
	MALADIE: 'MALADIE',
	CONGES: 'CONGES',
	AUTRES: 'AUTRES'
}

// Poids des rôles utilisateurs
// Utile pour savoir si un rôle a accès à une page
export const ROLE_WEIGHT = {
	ROLE_ADMIN: 5,
	ROLE_SUPERVISEUR: 4,
	ROLE_MANAGER: 3,
	ROLE_CHEF_CULTURE: 2,
	ROLE_MARAICHER: 1
}

// Préférences utilisateur
export const USER_PREFERENCES = {
	LANGUE: 'LANGUE'
}

// Listes de valeurs de l'application
export const VALUE_LIST_SHORTCUTS = {
	TYPE_SURFACE: 'TYPE_SURFACE',
	ZONE_SURFACE: 'ZONE_SURFACE',
	FAMILLE_CULTURE: 'FAMILLE_CULTURE',
	ESPECE_CULTURE: 'ESPECE_CULTURE',
	VARIETE_CULTURE: 'VARIETE_CULTURE',
	TYPE_ETAPE: 'TYPE_ETAPE',
	TYPE_COMMENTAIRE_SUIVI: 'TYPE_COMMENTAIRE_SUIVI',
	TYPE_VARIATION_IMPACT_PROD: 'TYPE_VARIATION_IMPACT_PROD',
	FAMILLE_TACHE: 'FAMILLE_TACHE',
	TYPE_TACHE: 'TYPE_TACHE',
	TYPE_CONTRAT: 'TYPE_CONTRAT'
}

/** CONSTANTES METIERS **/

	// Type des étapes d'un assolement
export const ETAPE_TYPE = {
		NM_PERIODE_SEMIS: 'PS', // Étape non métier représentant la période de semi possible
		SEMI_DIRECT: 'SD',
		SEMI_EN_CONTENANT: 'SC',
		PREP_PLANCHE: 'P',
		IMPLANTATION: 'I',
		RECOLTE: 'R',
		FIN_RECOLTE: 'FR',
		FERTILISATION: 'F',
		NM_DUREE_ASSOLEMENT: 'DA', // Étape non métier représentant la durée totale de l'assolement
		NM_DUREE_ASSOLEMENT_SELECTED: 'DAS',
		CROISSANCE: 'C'
	}

// Etat d'une étape
export const ETAPE_ETAT = {
	A_PLANIFIER: 'A_PLANIFIER',
	A_REALISER: 'A_REALISER',
	EN_COURS: 'EN_COURS',
	TERMINE: 'TERMINE',
	EN_ATTENTE: 'EN_ATTENTE'
}

// Etat d'une tache (sprint 2)
export const ETAT_TACHE_UPPERCASE = {
	A_PLANIFIER: 'A_PLANIFIER',
	A_REALISER: 'A_REALISER',
	EN_COURS: 'EN_COURS',
	TERMINEE: 'TERMINEE',
	EN_ATTENTE: 'EN_ATTENTE',
	EN_PAUSE: 'EN_PAUSE',
	EN_RETARD: 'EN_RETARD',
	SUPPRIMEE: 'SUPPRIMEE'
}

export const ETAT_TACHE_LIBELLE = {
	A_PLANIFIER: 'À planifier',
	A_REALISER: 'À réaliser',
	EN_COURS: 'En cours',
	TERMINEE: 'Terminée',
	EN_ATTENTE: 'En attente',
	EN_PAUSE: 'En pause',
	EN_RETARD: 'En retard',
	SUPPRIMEE: 'Supprimée'
}

export const ETAT_TACHE_UPPERCASE_VALUES = [
	ETAT_TACHE_UPPERCASE.A_PLANIFIER,
	ETAT_TACHE_UPPERCASE.A_REALISER,
	ETAT_TACHE_UPPERCASE.EN_COURS,
	ETAT_TACHE_UPPERCASE.EN_PAUSE,
	ETAT_TACHE_UPPERCASE.TERMINEE,
	ETAT_TACHE_UPPERCASE.EN_ATTENTE,
	ETAT_TACHE_UPPERCASE.SUPPRIMEE,
	ETAT_TACHE_UPPERCASE.EN_RETARD
]

// Etat d'un assolement
export const ASSOLEMENT_ETAT = {
	BROUILLON: 'BROUILLON',
	VALIDE: 'VALIDE',
	REVISE: 'REVISE'
}

// Choix du type de date saisie pour une nouvelle culture
export const CHOIX_DATES = {
	SEMIS: 'SEMIS',
	RECOLTE: 'RECOLTE'
}

export const TYPE_DATE = {
	DEBUT: 'DEBUT',
	FIN: 'FIN'
}

// Commentaire associé à quelle type d'entité ?
export const COMMENTAIRE_TYPE_ENTITY = {
	ETAPE: 'etape',
	PLANCHE: 'planche',
	CULTURE_PLANCHE: 'culture',
	TACHE: 'tache'
}

// Type de commentaire de suivi
export const TYPE_COMMENTAIRE_SUIVI = {
	IMPACT_PRODUCTION: 'IMPACT_PRODUCTION',
	AUTRE_COMMENTAIRE: 'AUTRE',
	SUIVI_TACHE: 'SUIVI_TACHE',
	FIN_TACHE: 'FIN_TACHE',
	DEMARRAGE_IMPOSSIBLE: 'DEMARRAGE_IMPOSSIBLE'
}

// Type de variation pour l'impact de production
export const TYPE_VARIATION_IMPACT_PROD = {
	PERTE: 'PERTE',
	SURPLUS: 'SURPLUS',
	NULLE: 'NULLE'
}

// Type de surface de zone
export const TYPE_SURFACE_ZONE = {
	BUTTE: 'BUT',
	SERRE: 'SER',
	EXTERIEUR: 'EXT'
}

// Type de planche
export const TYPE_PLANCHE = {
	PERMANENTE: 'PER',
	HETEROGENE: 'DIV'
}

// Type de planche
export const CODE_ERROR = {
	ZONE: 'Zone',
	TYPE: 'Type'
}

// Choix du type de date saisie pour un deplacement d'étape de culture
export const CHOIX_DEPLACEMENT = {
	DEPLACER: 'DEPLACER',
	DECALER: 'DECALER'
}

// Choix du point de départ pour la modification de l'étape récolte
export const CHOIX_POINT_DEPART_MODIFICATION = {
	DEBUT: 'DEBUT',
	FIN: 'FIN'
}

// Choix de la modification de l'étape récolte
export const CHOIX_TYPE_MODIFICATION = {
	ALLONGER: 'ALLONGER',
	REDUIRE: 'REDUIRE'
}

export const NOM_VUE_LISTE_ASSOLEMENT = {
	LISTE_ASSOLEMENT: 'ListAssolement'
}

export const NOM_LISTE_RESSOURCES = {
	LISTE_RESSOURCES: 'PlanificationTacheRessources'
}

export const LIBELLE_SURFACE = {
	PERMANENTE: 'Planche permanente',
	HETEROGENE: 'Planche hétérogène, Forêt / jardin'
}

export const ONGLET_GESTION_TACHE = {
	SYNTHESE: 0,
	RESSOURCES: 1,
	PLANIFICATION: 2,
	AFFECTATION: 3,
	ORDONNANCEMENT: 4
}

export const ONGLET_GESTION_RESSOURCE = {
	RESSOURCE: 0
}

export const ONGLET_GESTION_DES_RESSOURCES = {
	INFORMATIONS: { code: 'INFORMATIONS', order: 0 },
	CONTRAT: { code: 'CONTRATS', order: 1 },
	COMPETENCES: { code: 'COMPETENCES', order: 2 },
	ABSENCES: { code: 'ABSENCES', order: 3 }
}

export const NOM_ONGLET_GESTION_TACHES = {
	SYNTHESE: 'Synthèse',
	RESSOURCES: 'Ressources',
	PLANIFICATION: 'Planification',
	AFFECTATION: 'Affectation',
	ORDONNANCEMENT: 'Ordonnancement'
}

export const STEP_GESTION_TACHES = {
	PLANNING: { nom: 'Planning des tâches actuel', order: 0, isResultat: false },
	RESULTATS: { nom: 'Résultats de la planification auto.', order: 1, isResultat: true }
}

export const ONGLETS_BESOINS_PRODUCTION = {
	RECUEIL: { code: 'RECUEIL', order: 0 },
	RESULTATS: { code: 'RESULTATS', order: 1 }
}

export const ONGLET_RESULTAT_SOLVEUR = {
	ECART_CULTURE: { code: 'ECART_CULTURE', order: 0 },
	TAUX_REMPLISSAGE: { code: 'TAUX_REMPLISSAGE', order: 1 }
	// RESPECT_CONTRAINTES: { code: 'RESPECT_CONTRAINTES', order: 2 }
}

export const ONGLET_PLANIFICATEUR = {
	PLANIFICATEUR_CULTURES: { code: 'PLANIFICATEUR_CULTURES', order: 0 },
	PLANIFICATEUR_TACHES: { code: 'PLANIFICATEUR_TACHES', order: 1 }
}

export const TYPE_ENTITE_TACHE = {
	ETAPE: 'ETAPE',
	ASSOLEMENT: 'ASSOLEMENT',
	SURFACE: 'SURFACE',
	BLOC: 'BLOC',
	FERME: 'FERME'
}

export const PARAMETRES_TACHE = {
	DIVISIBLE: 'divisible',
	RECURRENTE: 'recurrente'
}

export const TYPE_PERIODE_RECURRENCE = {
	SEMAINES: { value: 'SEMAINE', label: 'Semaines' },
	MOIS: { value: 'MOIS', label: 'Mois' }
}

export const LOCALISATIONS = {
	BLOC: 'bloc',
	SURFACE: 'surface',
	FERME: 'ferme'
}

export const STATUTS_TACHE = {
	EN_ATTENTE: 'EN_ATTENTE',
	A_PLANIFIER: 'A_PLANIFIER',
	A_REALISER: 'A_REALISER',
	EN_COURS: 'EN_COURS',
	TERMINEE: 'TERMINEE'
}

export const TACHE_TYPE_ENTITE = {
	SURFACE: 'SURFACE',
	ASSOLEMENT: 'ASSOLEMENT',
	ETAPE: 'ETAPE',
	BLOC: 'BLOC',
	FERME: 'FERME',
	DIVERS: 'DIVERS'

}

export const ACTION_ONGLET_AFFECTATION = {
	AFFECTER: 'Affecter',
	DESAFFECTER: 'Desaffecter'
}

export const TYPE_RECURRENCE = {
	SEMAINE: 'SEMAINE',
	MOIS: 'MOIS'
}

export const BLOC_OU_SURFACE_MAJ = {
	BLOC: 'Bloc',
	SURFACE: 'Surface'
}

export const BLOC_OU_SURFACE_CAPS = {
	BLOC: 'BLOC',
	SURFACE: 'SURFACE'
}

export const FIN_CAMPAGNE = {
	EN_COURS: { code: 0, label: 'En cours' },
	CLOTUREE: { code: 1, label: 'Clôturée' }
}

export const ETAT_CAMPAGNE = {
	BROUILLON: 'BROUILLON',
	REVISEE: 'REVISEE'
}

// Type d'itk
export const TYPE_ITK_BO = {
	SEMID: 'SEMID',
	SEMIC: 'SEMIC'
}

export const JOURS = {
	LUNDI: 'LUNDI',
	MARDI: 'MARDI',
	MERCREDI: 'MERCREDI',
	JEUDI: 'JEUDI',
	VENDREDI: 'VENDREDI',
	SAMEDI: 'SAMEDI',
	DIMANCHE: 'DIMANCHE'
}

export const onSync = {
	init: 'onSync',
	type: 'onSync'
}

export const TYPE_ASPERSION = {
	ASPERSION: 'ASPERSION',
	GAG: 'GAG',
	ASPERSION_GAG: 'ASPERSION_GAG'
}

export const Season = {
	AUTOMNE: 'AUTOMNE',
	HIVER: 'HIVER',
	PRINTEMPS: 'PRINTEMPS',
	ETE: 'ETE'
}

export const PlanView = {
	FORM: { code: 'buttonInfo', order: 0 },
	PLAN: { code: 'buttonPlan', order: 1 }
}

export const MAX_FILE_SIZE = 2 * 1024 * 1024

export const DATATABLE_SORT_ORDER = {
	ASC: 'ASC',
	DESC: 'DESC'
}

export const SOLVER_STATUS = {
	PREPARING: 'PREPARING',
	OUTDATED: 'OUTDATED',
	RUNNING: 'RUNNING',
	STOPPED: 'STOPPED',
	ERROR: 'ERROR',
	DONE: 'DONE',
	APPROVED: 'APPROVED',
	APPROVING: 'APPROVING'
}

export const CAMPAGNE_STATUS = {
	cloture: 'cloture',
	revise: 'revise',
	cours: 'cours'
}

export const DEFAULT_LEFT_CLICK = 0
export const NOT_ACTION_POINTER = new Set(['auto', 'unset', 'default'])

export const ALERTE_PAGE = {
	TOUR_PLAINE: 'TOUR_PLAINE',
	DASHBOARD_PLANNING: 'DASHBOARD_PLANNING',
	PLANIFICATEUR: 'PLANIFICATEUR'
}

export const CATEGORIE_METEO = {
	VENT: 'VENT',
	METEO: 'METEO',
	HUMIDITE_AIR: 'HUMIDITE_AIR',
	TEMPERATURE: 'TEMPERATURE',
	NATURE_SOL: 'NATURE_SOL'
}

export const STATUT_METEO = {
	NATURE_SOL: {
		SOL_DETREMPE: 'SOL_DETREMPE',
		SOL_GELE: 'SOL_GELE',
		SOL_SEC: 'SOL_SEC',
		SOL_BON: 'SOL_BON'
	},
	METEO: {
		ENSOLEILLE: 'ENSOLEILLE',
		PLUVIEUX: 'PLUVIEUX',
		NON_PLUVIEUX: 'NON_PLUVIEUX',
		NUAGEUX: 'NUAGEUX',
	},
	HUMIDITE_AIR: {
		SEC: 'SEC',
		HUMIDE: 'HUMIDE',
		MOYEN: 'MOYEN',
	},
	TEMPERATURE: {
		CHAUD: 'CHAUD',
		FROID: 'FROID',
		GEL: 'GEL',
		DOUX: 'DOUX',
	},
	VENT: {
		VENT_FAIBLE: 'VENT_FAIBLE',
		VENT_FORT: 'VENT_FORT',
	}
}

export const IMPACT_METEO = {
	BLOQUANT: 'BLOQUANT',
	INDESIRABLE: 'INDESIRABLE',
	PREFERABLE: 'PREFERABLE',
	INDISPENSABLE: 'INDISPENSABLE'
}

export const COMPETENCE = {
	MACHINISTE: 'MACHINISTE',
	PARTICIPANT: 'PARTICIPANT'
}

export const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

export const AJOUT_RESSOURCE_FORM = "AJOUT_RESSOURCE_FORM"

export const ABSENCE_FORM = 'ABSENCE_FORM'

export const EMPTY_TASK = {
	SURFACE_VIDE: 'Aucune surface',
	CULTURE_VIDE: 'Aucune culture assolée'
}

export const SUIVI_DE_TACHE_FORM = 'SUIVI_DE_TACHE_FORM'

export const GESTION_RESSOURCES_TABLE = 'GestionRessourcesTable'

export const ABSENCE_RESSOURCES_TABLE = 'AbsenceRessourcesTable'