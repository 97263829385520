import { refresh } from 'isotope-client/components/datatable/connected/services/dataTableActions'
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { ActionPanelContext } from '../../../../../../components/layout/rightPanels'
import Detail from '../../../component/detail/Detail'
import SuiviEtape from '../../../component/form/SuiviEtape'
import { SUIVI_ITK_DETAIL, SUIVI_ETAPE_FIELDS } from '../../../utils/constant'
import { editEtapeSuivi, updateTask } from '../../assolement/assolementAction'
import FormButton from '../../../../../../components/form/FormButton'
import { useSnackbar } from '../../../../../../components/layout/snackbar/SnackbarContext'

const TYPE_COMMENTAIRE_SUIVI_AUTRE = 'AUTRE'
/**
 * Injecteur pour l'ouverture du panel de suivi de etape
 */
export const injectSuiviEtape = (WrappedComponent) => {
	const EtapeSuiviInjector = (props) => {
		const { openPanel: openPanelAction, closePanel: closePanelAction } = React.useContext(ActionPanelContext)
		const { editEtapeSuivi, refresh, updateTask } = props
		const { snackError, snackSuccess } = useSnackbar()

		const submitSuiviEtape = (assolementEtape, callbackOnSubmit, comment) => values => {
			let formValues = {
				etat: values.etat
			}
			if (values.commentaire) {
				formValues = {
					...formValues,
					commentaireForm: {
						typeCommentaire: TYPE_COMMENTAIRE_SUIVI_AUTRE,
						commentaire: values.commentaire
					}
				}
			}
			if (comment) {
				formValues = {
					...formValues,
					idCommentaire: comment.id
				}
			}
			return editEtapeSuivi(assolementEtape.id, formValues)
				.then(({assolementEtape}) => {
					refresh(assolementEtape.type)
					updateTask(assolementEtape)
					if (callbackOnSubmit) {
						callbackOnSubmit()
					}
					closePanelAction()
					snackSuccess({ id: 'snackbar.update.suiviEtape' })
				})
				.catch(() => {
					snackError({ id: 'snackbar.error.suiviEtape' })
				})
		}

		const openEtapeSuivi = (assolement, assolementEtape, callbackOnSubmit, comment) => {
			if (!assolementEtape) {
				return openPanelAction('', <Detail sections={[]} />, [])
			}

			const title = <span>
				{!comment ? <FormattedMessage id="planning.etapesAssolement.suiviTitle" /> : <FormattedMessage id="planning.etapesAssolement.editSuivi" />}
			</span>

			const buttons = [
				<FormButton
					type="primary"
					formName={SUIVI_ITK_DETAIL}
					getDisabledFromState={state => {
						if (comment) {
							return formValueSelector(SUIVI_ITK_DETAIL)(state, SUIVI_ETAPE_FIELDS.COMMENTAIRE) &&
								formValueSelector(SUIVI_ITK_DETAIL)(state, SUIVI_ETAPE_FIELDS.COMMENTAIRE) === comment.commentaire &&
								formValueSelector(SUIVI_ITK_DETAIL)(state, SUIVI_ETAPE_FIELDS.ETAT) === assolementEtape.etat
						} else {
							return !formValueSelector(SUIVI_ITK_DETAIL)(state, SUIVI_ETAPE_FIELDS.COMMENTAIRE) &&
								formValueSelector(SUIVI_ITK_DETAIL)(state, SUIVI_ETAPE_FIELDS.ETAT) === assolementEtape.etat
						}
					}}
				>
					<FormattedMessage id="actions.validate" />
				</FormButton>
			]


			return openPanelAction(
				title,
				<SuiviEtape onSubmit={submitSuiviEtape(assolementEtape, callbackOnSubmit, comment)} assolement={assolement} assolementEtape={assolementEtape} comment={comment} />,
				buttons
			)
		}

		return <WrappedComponent {...props} openEtapeSuivi={React.useCallback(openEtapeSuivi, [])} />
	}

	EtapeSuiviInjector.propTypes = {
		submit: PropTypes.func,
		editEtapeSuivi: PropTypes.func
	}

	const actions = {
		editEtapeSuivi,
		updateTask,
		refresh
	}

	return connect(undefined, actions)(EtapeSuiviInjector)
}
