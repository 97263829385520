import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../utils/constants'

const SemiDirectOutlinedIcon = ({ height, width, color, ...props }) => {
	return (
		<svg viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				d="M262.39 60.777a15.009 15.009 0 00-12.779 0c-34.954 16.457-55.821 44.746-55.821 75.674v36.66c-.001 30.928 20.867 59.217 55.82 75.674a15.006 15.006 0 0012.78 0c34.954-16.457 55.821-44.746 55.821-75.674v-36.66c0-30.929-20.868-59.218-55.821-75.674zm25.821 112.333c0 17.269-11.909 33.822-32.211 45.24-20.302-11.418-32.211-27.972-32.211-45.24v-36.66c0-17.269 11.909-33.822 32.211-45.24 20.302 11.418 32.211 27.972 32.211 45.24v36.66z"
				fill={color}
			/>
			<path
				d="M497 210.475H362.885c-28.771 0-39.215 23.269-39.215 38.909 0 37.098-30.394 67.278-67.753 67.278-37.991 0-67.75-29.324-67.75-66.771-.011-15.845-10.464-39.417-39.215-39.417H15c-8.284 0-15 6.716-15 15v182.24c0 24.778 20.159 44.938 44.938 44.938h422.124c24.779 0 44.938-20.159 44.938-44.938v-182.24c0-8.283-6.716-14.999-15-14.999zm-15 197.239c0 8.236-6.701 14.938-14.938 14.938H44.938c-8.237 0-14.938-6.701-14.938-14.938v-10.079h452v10.079zm0-40.079H30v-127.16h118.951c2.196 0 8.869 0 9.215 9.594.043 26.032 10.239 50.37 28.72 68.546 18.391 18.087 42.906 28.048 69.03 28.048 26.116 0 50.649-10.069 69.079-28.354 18.451-18.304 28.63-42.716 28.675-68.754.349-9.08 6.788-9.08 9.215-9.08H482v127.16z"
				fill={color}
			/>
		</svg>
	)
}

SemiDirectOutlinedIcon.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

SemiDirectOutlinedIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: colors.icon
}

export default SemiDirectOutlinedIcon
