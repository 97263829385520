import React from 'react'
import PropTypes from 'prop-types'

const RainIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<g fill="#8ac9fe">
			<path d="M156.641 383.498c0 16.376-13.276 29.652-29.652 29.652s-29.652-13.276-29.652-29.652c0-15.353 15.511-36.203 24.096-46.567 2.889-3.487 8.223-3.487 11.112 0 8.586 10.364 24.096 31.213 24.096 46.567zM285.652 383.498c0 16.376-13.276 29.652-29.652 29.652s-29.652-13.276-29.652-29.652c0-15.353 15.511-36.203 24.096-46.567 2.889-3.487 8.223-3.487 11.112 0 8.585 10.364 24.096 31.213 24.096 46.567zM414.662 383.498c0 16.376-13.276 29.652-29.652 29.652s-29.652-13.276-29.652-29.652c0-15.353 15.511-36.203 24.096-46.567 2.889-3.487 8.223-3.487 11.112 0 8.585 10.364 24.096 31.213 24.096 46.567z" />
		</g>
		<g fill="#8ac9fe">
			<path d="M221.146 482.323c0 16.376-13.276 29.652-29.652 29.652s-29.652-13.276-29.652-29.652c0-15.353 15.511-36.203 24.096-46.567 2.889-3.487 8.223-3.487 11.112 0 8.586 10.364 24.096 31.214 24.096 46.567zM350.157 482.323c0 16.376-13.276 29.652-29.652 29.652s-29.652-13.276-29.652-29.652c0-15.353 15.511-36.203 24.096-46.567 2.889-3.487 8.223-3.487 11.112 0 8.585 10.364 24.096 31.214 24.096 46.567z" />
		</g>
		<g fill="#60b7ff">
			<path d="M136.99 411.418a29.597 29.597 0 0 1-10 1.73c-16.38 0-29.65-13.27-29.65-29.65 0-15.35 15.51-36.2 24.09-46.57 2.89-3.49 8.23-3.49 11.12 0 1.34 1.62 2.85 3.5 4.44 5.57-8.61 11.18-19.65 28.05-19.65 41 0 12.87 8.19 23.82 19.65 27.92zM266 411.418a29.597 29.597 0 0 1-10 1.73c-16.38 0-29.65-13.27-29.65-29.65 0-15.35 15.51-36.2 24.09-46.57 2.89-3.49 8.23-3.49 11.12 0 1.34 1.62 2.85 3.5 4.44 5.57-8.61 11.18-19.65 28.05-19.65 41 0 12.87 8.19 23.82 19.65 27.92zM395.01 411.418a29.597 29.597 0 0 1-10 1.73c-16.38 0-29.65-13.27-29.65-29.65 0-15.35 15.51-36.2 24.09-46.57 2.89-3.49 8.23-3.49 11.12 0 1.34 1.62 2.85 3.5 4.44 5.57-8.61 11.18-19.65 28.05-19.65 41 0 12.87 8.19 23.82 19.65 27.92z" />
		</g>
		<g fill="#60b7ff">
			<path d="M201.49 510.248a29.554 29.554 0 0 1-10 1.73c-16.37 0-29.65-13.28-29.65-29.66 0-15.35 15.51-36.2 24.1-46.56 2.89-3.49 8.22-3.49 11.11 0 1.34 1.62 2.85 3.49 4.45 5.55-8.61 11.18-19.66 28.05-19.66 41.01 0 12.87 8.2 23.83 19.65 27.93zM330.51 510.248a29.597 29.597 0 0 1-10 1.73c-16.38 0-29.66-13.28-29.66-29.66 0-15.35 15.51-36.2 24.1-46.56 2.89-3.49 8.22-3.49 11.11 0 1.34 1.62 2.85 3.49 4.45 5.55-8.61 11.18-19.66 28.05-19.66 41.01 0 12.87 8.2 23.83 19.66 27.93z" />
		</g>
		<path
			fill="#ecf6fe"
			d="M433.952 135.169h-35.109C393.474 59.634 330.502.022 253.601.022c-60.097 0-111.685 36.407-133.927 88.367a102.84 102.84 0 0 0-17.488-1.496C45.75 86.892 0 132.642 0 189.078s45.75 102.186 102.186 102.186h331.766c43.105 0 78.048-34.943 78.048-78.048 0-43.104-34.943-78.047-78.048-78.047z"
		/>
		<path
			fill="#dbebfc"
			d="M279.365 2.298c-48.8 8.695-89.193 41.722-108.179 86.092a103.015 103.015 0 0 0-17.492-1.494c-56.433 0-102.184 45.75-102.184 102.184s45.75 102.184 102.184 102.184h-51.509C45.75 291.264 0 245.514 0 189.081S45.75 86.897 102.184 86.897c5.965 0 11.806.515 17.492 1.494C141.918 36.428 193.509.022 253.6.022c8.787 0 17.399.783 25.765 2.276z"
		/>
	</svg>
)


RainIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

RainIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default RainIcon