import React from 'react'
import PropTypes from 'prop-types'

const PotIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			d="M436.231 145.006a15 15 0 0 0 0-12.621c-16.152-34.83-51.375-57.334-89.733-57.334s-73.58 22.505-89.733 57.334a14.996 14.996 0 0 0 0 12.622c16.152 34.83 51.375 57.335 89.733 57.335 38.359-.002 73.582-22.507 89.733-57.336z"
			style={{
				fill: "#6ac473",
			}}
		/>
		<path
			d="M437.624 138.695c0-2.155-.464-4.31-1.392-6.311-16.152-34.83-51.375-57.334-89.733-57.334s-73.58 22.505-89.733 57.334a14.996 14.996 0 0 0-1.392 6.311h182.25z"
			style={{
				fill: "#a9e570",
			}}
		/>
		<path
			d="M360.579 138.695c0-8.284-6.716-15-15-15H261.35a97.914 97.914 0 0 0-4.584 8.689 14.996 14.996 0 0 0 0 12.622 98.186 98.186 0 0 0 4.585 8.689h84.228c8.284 0 15-6.716 15-15z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M360.579 138.695c0-8.284-6.716-15-15-15H261.35a97.914 97.914 0 0 0-4.584 8.689 14.996 14.996 0 0 0-1.392 6.311h105.205z"
			style={{
				fill: "#57a15e",
			}}
		/>
		<path
			d="M256 0c-8.284 0-15 6.716-15 15v306.246c0 8.284 6.716 15 15 15s15-6.716 15-15V15c0-8.284-6.716-15-15-15z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M363.669 328.239c-2.848-3.23-6.946-5.239-11.253-5.239H159.584c-4.307 0-8.405 2.009-11.253 5.239-2.848 3.231-4.169 7.609-3.628 11.881l16.767 132.492C164.31 495.045 183.502 512 206.114 512h99.772c22.611 0 41.804-16.916 44.644-39.349l16.767-132.531c.541-4.272-.78-8.65-3.628-11.881z"
			style={{
				fill: "#cfa05f",
			}}
		/>
		<path
			d="M256 323h-96.416c-4.307 0-8.405 2.009-11.253 5.239-2.848 3.231-4.169 7.609-3.628 11.881l16.767 132.492C164.31 495.045 183.502 512 206.114 512H256V323z"
			style={{
				fill: "#facc8c",
			}}
		/>
		<path
			d="M349.007 284H162.993c-23.177 0-42.033 18.823-42.033 42s18.856 42 42.033 42h186.015c23.177 0 42.033-18.823 42.033-42s-18.857-42-42.034-42z"
			style={{
				fill: "#a67955",
			}}
		/>
		<path
			d="M256 284h-93.007c-23.177 0-42.033 18.823-42.033 42s18.856 42 42.033 42H256v-84z"
			style={{
				fill: "#dea06d",
			}}
		/>
		<path
			d="M75.769 181.324a15 15 0 0 0 0 12.621c16.152 34.83 51.375 57.334 89.733 57.334s73.58-22.505 89.733-57.334a14.996 14.996 0 0 0 0-12.622c-16.152-34.83-51.375-57.335-89.733-57.335-38.359.001-73.582 22.506-89.733 57.336z"
			style={{
				fill: "#6ac473",
			}}
		/>
		<path
			d="M74.376 187.634c0 2.155.464 4.31 1.392 6.311 16.152 34.83 51.375 57.334 89.733 57.334s73.58-22.505 89.733-57.334a14.996 14.996 0 0 0 1.392-6.311H74.376z"
			style={{
				fill: "#a9e570",
			}}
		/>
		<path
			d="M151.421 187.634c0 8.284 6.716 15 15 15h84.229a97.914 97.914 0 0 0 4.584-8.689 14.996 14.996 0 0 0 0-12.622 98.186 98.186 0 0 0-4.585-8.689h-84.228c-8.284 0-15 6.716-15 15z"
			style={{
				fill: "#4c8c51",
			}}
		/>
		<path
			d="M151.421 187.634c0 8.284 6.716 15 15 15h84.229a97.914 97.914 0 0 0 4.584-8.689 14.996 14.996 0 0 0 1.392-6.311H151.421z"
			style={{
				fill: "#57a15e",
			}}
		/>
	</svg>
)


PotIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

PotIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default PotIcon