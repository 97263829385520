import React from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import { ReactComponent as BasketUnfill } from '../../../../../icons/basket-unfill.svg'
import { ReactComponent as BasketFill } from '../../../../../icons/basket-fill.svg'
import classNames from 'classnames'


const useStyles = makeStyles(theme => getStyles({
	root: {
		color: ({ valeurPositive }) => valeurPositive ? theme.palette.valid.main : theme.palette.error.main,
		fontWeight: 'bold',
		justifyContent:'center'
	},
	iconContainer: {
		width: 20,
		flex: '0 0 auto'
	},
	icon: {
		fill: ({ valeurPositive }) => valeurPositive ? theme.palette.valid.main : theme.palette.error.main,
		width: 16,
		height: 16
	}
}))

const HeaderDiffCell = ({ valeur, className }) => {
	const duration = React.useMemo(() => moment.duration(valeur), [valeur])
	const valeurPositive = React.useMemo(() => duration.asMinutes() >= 0, [duration])
	const classes = useStyles({ valeurPositive })
	return <Grid item container className={classNames(classes.root, className)} alignItems="center" wrap="nowrap">
		<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
			{valeurPositive ? <BasketFill className={classes.icon}/> : <BasketUnfill className={classes.icon} />}
		</Grid>
		<span className={classes.total}>{duration.format('h[h]mm', { trim: false })}</span>
	</Grid>

}

HeaderDiffCell.propTypes = {
	valeur: PropTypes.string,
	className: PropTypes.string
}

export default HeaderDiffCell
