import React from 'react'
import { dataTableActions, getStyles } from 'isotope-client'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core'
import { colors } from '../../../../utils/constants'
import { getFilterValues } from '../../planning/services/toolbar/toolbarSelector'
import NoResultPage from '../../../../components/NoResultPage'
import Grid from '@material-ui/core/Grid'
import FilterIcon from '../../../../components/icon/FilterIcon'
import ToolBarButton from '../../planning/component/ToolBar/ToolBarButton'
import { Box, Typography } from '@material-ui/core'
import { setFilterOpen } from '../../planning/services/toolbar/toolbarAction'
import { injectConnexionsFilter } from './connexionsFilterInjector'
import { CONNEXIONS_TABLE_NAME } from './constants'
import moment from 'moment'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { fields } from './FilterConnexionsForm'
import classnames from 'classnames'
import SpringDataTableWithLoader from '../../../../components/layout/SpringDataTableWithLoader'

const styles = () => getStyles({
	content: {
		height: 70,
		minWidth: 1150,
		boxShadow: '10px 0px 5px lightgray',
		marginBottom: 20
	},
	boutons: {
		height: '100%'
	},
	head: {
		fontSize: 24,
	},
	toolbar: {
		paddingLeft: 24,
		height: '100%'
	},
	root: {
		background: colors.etapeContrastBackground,
		height: '-webkit-fill-available'
	},
	liste: {
		marginLeft: 24,
		marginRight: '1%',
		width: '70%',
		'& .MuiTableCell-root:first-child': {
			maxWidth: 35
		},
		'& .MuiTableCell-stickyHeader': {
			backgroundColor: 'unset',
			fontSize: '14px'
		},
		'& .MuiTableRow-head': {
			backgroundColor: colors.backgroundSuiviTacheAvancement
		},
		'& .MuiTableHead-root': {
			height: 50
		},
		'& .MuiTableCell-root': {
			borderTop: '1px solid #c0c0c073'
		},
		'& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
			borderBottom: '1px solid #c0c0c073'
		},
		'& .MuiTableBody-root': {
			height: 40
		}
	},
	culture: {
		fontWeight: 'bold',
		fontSize: '14px'
	},
	centeredCell: {
		textAlign: 'center',
		height: '40px',
		fontSize: '14px'
	},
	enLigne: {
		color: colors.valid,
		paddingRight: 10
	},
	horsLigne: {
		color: colors.error,
		paddingRight: 10
	},
	column40: {
		width: '30%'
	},
	column30: {
		width: '30%'
	},
	column15: {
		width: '15%'
	},
	onlineLabel:{
		width:63,
		textAlign: 'left'
	}
})

const SuiviConnexionsComponents = ({ setFilterOpen, classes, filters, refreshTable }) => {

	React.useEffect(() => {
		const interval = setInterval(() => refreshTable(), 300000);
		return () => clearInterval(interval)
	}, [refreshTable])

	const filterValues = React.useCallback((filters) => {
		return Object.keys(filters).length > 0 ? Object.keys(filters).reduce((acc, curr) => {
			if (curr === fields.TODAY) {
				return ({
					...acc,
					[curr]: filters[curr]
				})
			}
			return ({
				...acc,
				[curr]: Object.values(filters[curr].map(n => n.code))
			})
		}, {}) : { [fields.TODAY]: true }
	}, [])

	const getState = (date) => {
		const enLigne = date && moment(date).isAfter(moment().subtract(5, 'minutes'))
		return (
			<Grid container justify="center" alignItems="space-around">
				<FiberManualRecordIcon className={enLigne ? classes.enLigne : classes.horsLigne} />
				<span className={classes.onlineLabel}><FormattedMessage id={`suiviConnexions.state.${enLigne?'online':'offline'}`} /></span>
			</Grid>
		)
	}

	return (
		<Grid className={classes.root}>
			<Grid item container direction="row" justify="space-between" className={classes.content}>
				<Grid item container xs direction="row" justify="flex-start" alignItems="center" className={classes.toolbar}>
					<Typography variant="h1" className={classes.head}>
						<FormattedMessage id="suiviConnexions.title" />
					</Typography>
				</Grid>
				<Grid item container xs direction="row" justify="flex-end" alignItems="center" className={classes.boutons}>
					<Box p={1}>
						<ToolBarButton type="filter" onClick={setFilterOpen}>
							<FilterIcon color="currentColor" />
						</ToolBarButton>
					</Box>
				</Grid>
			</Grid>
			<Grid>
				<Grid>
					<Grid className={classes.liste}>
						<SpringDataTableWithLoader
							nom={CONNEXIONS_TABLE_NAME}
							mode="post"
							apiUrl="/utilisateurs/connexions"
							filters={{ ...filterValues(filters) }}
							defaultPageSize={50}
							tableProps={{
								stickyHeader: true
							}}
							noResultFragment={<><br /><br /><br /><NoResultPage/></>}
							headers={[
								{
									key: 'user',
									name: <FormattedMessage id="suiviConnexions.table.header.user" />,
									tableHeaderColumnProps: {
										className: classes.column40
									},
									render: row => <span className={classes.culture}>{row.user.firstname} {row.user.lastname}</span>
								}, {
									key: 'alias',
									name: <FormattedMessage id="suiviConnexions.table.header.alias" />,
									tableHeaderColumnProps: {
										className: classnames(classes.centeredCell, classes.column15)
									},
									render: row => row.user.preferences.ALIAS || '-'
								}, {
									key: 'state',
									name: <FormattedMessage id="suiviConnexions.table.header.state" />,
									tableHeaderColumnProps: {
										className: classnames(classes.centeredCell, classes.column30)
									},
									render: row => getState(row.lastConnexion)
								}, {
									key: 'lastConnexion',
									name: <FormattedMessage id="suiviConnexions.table.header.lastConnection" />,
									type: 'date',
									tableHeaderColumnProps: {
										className: classnames(classes.centeredCell, classes.column30)
									},
									render: row => row.lastConnexion ? <FormattedMessage
										id="suiviConnexions.table.date"
										values={{
											jour: moment(row.lastConnexion).format('DD-MM-YYYY'),
											heure: moment(row.lastConnexion).format('HH:mm')
										}}
									/> : '-'
								}
							]}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = state => ({
	filters: getFilterValues(state)
})

const actions = {
	setFilterOpen,
	refreshTable: () => dataTableActions.refresh(CONNEXIONS_TABLE_NAME),
}

export default compose(
	connect(mapStateToProps, actions),
	injectConnexionsFilter,
	withStyles(styles)
)(SuiviConnexionsComponents)
