import React from 'react'
import PropTypes from 'prop-types'

const ScheduleIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
	>
		<g data-name="Layer 2">
			<path
				fill="#d8d8d8"
				d="M22.75 5v12.25a4.504 4.504 0 0 1-4.5 4.5H4A2.748 2.748 0 0 1 1.25 19V5a2.75 2.75 0 0 1 2-2.65 3.083 3.083 0 0 1 .75-.1h16A2.748 2.748 0 0 1 22.75 5z"
			/>
			<path
				fill="#f5f5f5"
				d="M22.75 5v12.25a4.446 4.446 0 0 1-.76 2.5H9.83a6.572 6.572 0 0 1-6.58-6.58V2.35a3.083 3.083 0 0 1 .75-.1h16A2.748 2.748 0 0 1 22.75 5z"
			/>
			<path
				fill="#ea3654"
				d="M22.75 5v3.75H1.25V5a2.75 2.75 0 0 1 2-2.65c.12-.03.24-.05.37-.07A2.343 2.343 0 0 1 4 2.25h16A2.748 2.748 0 0 1 22.75 5z"
			/>
			<path
				fill="#ff4f64"
				d="M22.75 5v3.75H3.25v-6.4c.12-.03.24-.05.37-.07A2.343 2.343 0 0 1 4 2.25h16A2.748 2.748 0 0 1 22.75 5z"
			/>
			<g fill="#fff">
				<circle cx={7} cy={5} r={1.5} />
				<circle cx={12} cy={5} r={1.5} />
				<circle cx={17} cy={5} r={1.5} />
			</g>
			<g fill="#ffc84d">
				<path d="M7 5.75A.75.75 0 0 1 6.25 5V2a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75zM12 5.75a.75.75 0 0 1-.75-.75V2a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75zM17 5.75a.75.75 0 0 1-.75-.75V2a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75z" />
			</g>
			<path
				fill="#efb237"
				d="M22.75 18a4.677 4.677 0 0 1-.93 2.82 4.749 4.749 0 1 1-6.64-6.64 4.677 4.677 0 0 1 2.82-.93A4.754 4.754 0 0 1 22.75 18z"
			/>
			<path
				fill="#ffc84d"
				d="M22.75 18a4.677 4.677 0 0 1-.93 2.82 4.678 4.678 0 0 1-2.82.93A4.754 4.754 0 0 1 14.25 17a4.678 4.678 0 0 1 .93-2.82 4.677 4.677 0 0 1 2.82-.93A4.754 4.754 0 0 1 22.75 18z"
			/>
			<path
				fill="#fff"
				d="M18.999 19.75a.745.745 0 0 1-.334-.08l-1-.5a.749.749 0 0 1-.415-.67V17a.75.75 0 0 1 1.5 0v1.036l.585.293a.75.75 0 0 1-.336 1.421z"
			/>
		</g>
	</svg>
)


ScheduleIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

ScheduleIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default ScheduleIcon