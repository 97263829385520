import React from 'react'
import Snackbar, { DEFAULT_SNACK_DURATION, SnackbarType } from './Snackbar'
import { connect } from 'react-redux'
import { updateAction } from './service/action'

export const SnackbarContextType = {
	snackSuccess: (message, actions, duration) => {
	},
	snackError: (message, actions, duration) => {
	},
	snackWarning: (message, actions, duration) => {
	}
}

export const SnackbarContext = React.createContext(SnackbarContextType)

export const useSnackbar = () => React.useContext(SnackbarContext)

const SnackbarProvider = ({ children, updateAction }) => {
	const hideSnack = React.useCallback(() => {
		updateAction({ visible: false })
	}, [])

	const showSnack = React.useCallback((message, duration, type, actions = []) => {
		// On doit hide la snackbar pour reset le autohide
		hideSnack()
		updateAction({
			message,
			type: type,
			delay: duration,
			actions,
			visible: true
		})
	}, [hideSnack])

	const showSnackSuccess = React.useCallback((message = { id: 'notify.success' }, actions = [], duration = DEFAULT_SNACK_DURATION) => showSnack(message, duration, SnackbarType.SUCCESS, actions), [showSnack])
	const showSnackError = React.useCallback((message = { id: 'notify.error' }, actions = [], duration = DEFAULT_SNACK_DURATION) => showSnack(message, duration, SnackbarType.ERROR, actions), [showSnack])
	const showSnackWarning = React.useCallback((message = { id: 'notify.warning' }, actions = [], duration = DEFAULT_SNACK_DURATION) => showSnack(message, duration, SnackbarType.INFO, actions), [showSnack])

	return <SnackbarContext.Provider value={{
		snackSuccess: showSnackSuccess,
		snackError: showSnackError,
		snackWarning: showSnackWarning
	}}>
		{children}
		<Snackbar hideSnack={hideSnack} />
	</SnackbarContext.Provider>
}
const actions = {
	updateAction
}

export default connect(undefined, actions)(SnackbarProvider)

