import React from 'react'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {ActionPanelContext} from '../../../components/layout/rightPanels'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import TourPlaineProvider from './TourPlaineProvider'
import TourPlaineContent from './TourPlaineContent'

/**
 * TourPlaine entry point
 * @returns {JSX.Element}
 */
const TourPlaine = (props) => {
	const {setPage} = usePageContext()

	React.useEffect(() => setPage(PAGE.TOUR_PLAINE), [])

	return (
		<DialogProvider fromBo textButton>
			<DetailPanelProvider>
				<ActionPanelProvider Context={ActionPanelContext} level={1}>
					<TourPlaineProvider initialTab={props.history?.location.state?.tab} initialActionTab={props.history?.location.state?.actionTab} blockId={props.history?.location.state?.blockId}>
						<TourPlaineContent/>
					</TourPlaineProvider>
				</ActionPanelProvider>
			</DetailPanelProvider>
		</DialogProvider>
	)
}

export default TourPlaine
