import { DELETE_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS } from './notificationActions'

const notificationsData = (state = [], action) => {
	switch (action.type) {
		case GET_NOTIFICATIONS_SUCCESS:
			return action.data
		case DELETE_NOTIFICATIONS:
			return state.filter(notification => notification.id !== action.idNotification)
		default:
			return state
	}
}

export default notificationsData
