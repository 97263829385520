import Grid from '@material-ui/core/Grid'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FormSwitch, getStyles } from 'isotope-client'
import { compose } from 'redux'
import { change, Field, reduxForm } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Input from '../../../components/form/Input'
import { USER_EDITION_FORM } from './UtilisateurPage'
import Select from '../../../components/form/Select'
import { MenuItem } from '@material-ui/core'
import { injectFermeList } from '../ferme/services/fermeListInjector'
import { PROFILS } from '../../../utils/constants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { checkMailFormat, sortObjectByLabel } from '../../../utils/utils'
import { getAuthorities, getPreferences } from '../../common/user/services/userSelectors'
import Button from '../../../components/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const styles = () => getStyles({
	row: {
		justifyContent: 'center',
		height: 73,
		marginLeft: 35
	},
	firstRow: {
		justifyContent: 'center',
		marginTop: 10,
		marginLeft: 35,
		height: 73
	},
	lastRow: {
		justifyContent: 'center',
		marginLeft: 35,
		height: 73
	},
	finContratRow: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		height: 73
	},
	label: {
		fontWeight: 'bold'
	},
	selectContainer: {
		width: 282
	},
	contrat: {
		paddingLeft: 44,
		fontSize: 18,
		marginTop: 12,
		paddingBottom: 10,
		fontFamily: 'Lato',
		letterSpacing: 2

	},
	checkBoxContainer: {
		paddingLeft: 45,
		paddingTop: 15
	},
	labelBox: {
		marginLeft: 6
	},
	marginTitle: {
		paddingBottom: 10
	},
	infoGenerales: {
		paddingLeft: 44,
		fontSize: 18,
		marginTop: 15,
		fontFamily: 'Lato',
		letterSpacing: 2
	},
	rowSwitch: {
		marginTop: 10,
		paddingLeft: 15
	},
	switch: {
		'& .MuiIconButton-root': {
			padding: 9
		}
	},
	 deleteButton: {
		justifyContent: 'center'
	 }
})

const champRequis = ['firstname', 'lastname', 'alias', 'email', 'ferme', 'profil']

const validate = values => {
	const errors = {}
	if (values[fields.EMAIL.name] && !checkMailFormat(values[fields.EMAIL.name])) {
		errors[fields.EMAIL.name] = { id: 'global.errors.wrongFormat' }
	}
	champRequis.forEach(
		champ => {
			if (!values[champ]) {
				errors[champ] = <FormattedMessage id="bo.utilisateur.form.champRequis" />
			}
		}
	)
	return errors
}

const fields = {
	FIRSTNAME: { name: 'firstname' },
	LASTNAME: { name: 'lastname' },
	ALIAS: { name: 'alias' },
	EMAIL: { name: 'email' },
	FERME: { name: 'ferme' },
	PROFIL: { name: 'profil' },
	ACTIF: { name: 'actif' }
}

const formattedMessageBase = 'bo.utilisateur.form'

const PopinUserEdition = ({ classes, handleSubmit, fermeList, selectedUser, userAuthorities, deleteUser, preferences }) => {
	const fermes = [...fermeList]

	const isManagerAndLookSuperviseur = selectedUser
		&& userAuthorities[0].authority === PROFILS.MANAGER.role && selectedUser.profil.code === PROFILS.SUPERVISEUR.nom.toLowerCase()


	//On filtre les rôles proposés (éviter qu'un role plus bas puisse se promouvoir superviseur ou admin)
	const getProfiles = React.useCallback(() => sortObjectByLabel(Object.values(PROFILS).filter(it => {
		if (it.id === 1) {
			return false
		}
		//Manager → info tous profils sauf superviseur → pas possible de promouvoir en superviseur
		//Si Manager et infos superviseur → champ disabled et superviseur ok
		if (userAuthorities[0].authority === PROFILS.MANAGER.role) {
			if (!selectedUser || selectedUser.profil.code !== PROFILS.SUPERVISEUR.nom.toLowerCase()) {
				return it.role !== PROFILS.SUPERVISEUR.role
			}
			return true
		}
		return true
	}).map(profil => ({ label: profil.nom, code: `${profil.id}`, key: `${profil.id}` }))), [userAuthorities])

	return (
		<form onSubmit={handleSubmit}>
			<Grid container>
				<Grid container item className={classes.infoGenerales}>
					{
						selectedUser ? `${selectedUser.user.firstname} ${selectedUser.user.lastname}` : <FormattedMessage id={`${formattedMessageBase}.informationsGenerales`} />
					}
				</Grid>
				<Grid container item className={classes.firstRow}>
					<Grid item xs={6}>
						<Field
							name={fields.FIRSTNAME.name}
							component={Input}
							style={{ width: 282 }}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.FIRSTNAME.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name={fields.LASTNAME.name}
							component={Input}
							style={{ width: 282 }}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.LASTNAME.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						/>
					</Grid>
				</Grid>
				<Grid container item className={classes.row}>
					<Grid item xs={6}>
						<Field
							name={fields.ALIAS.name}
							component={Input}
							style={{ width: 282 }}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.ALIAS.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name={fields.EMAIL.name}
							component={Input}
							style={{ width: 282 }}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.EMAIL.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						/>
					</Grid>
				</Grid>
				<Grid container item className={classes.row}>
					<Grid item xs={6}>
						<Field
							name={fields.FERME.name}
							component={Select}
							containerClassName={classes.selectContainer}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.FERME.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						>
							{
								fermes.filter(ferme => {
									if (userAuthorities[0].authority === PROFILS.SUPERVISEUR.role) {
										return true
									}
									return ferme.id === preferences.FERME_ID;
								}).map(ferme =>
									<MenuItem key={ferme.id} value={ferme.id}>
										{ferme.raisonSociale}
									</MenuItem>
								)
							}
						</Field>
					</Grid>
					<Grid item xs={6}>
						<Field
							name={fields.PROFIL.name}
							component={Select}
							containerClassName={classes.selectContainer}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.PROFIL.name}`} />}
							fromBo
							disabled={isManagerAndLookSuperviseur}
						>
							{
								getProfiles().map(profil => <MenuItem key={profil.key} value={profil.key}>
										<FormattedMessage id={`bo.utilisateur.codeProfil.${profil.label}`} />
									</MenuItem>
								)
							}
						</Field>
					</Grid>
				</Grid>
				<Grid container item className={classes.row}>
					<Grid item xs={12} className={classes.rowSwitch}>
						<Field
							label={<FormattedMessage id={`${formattedMessageBase}.fields.${fields.ACTIF.name}`} />}
							name={fields.ACTIF.name}
							component={FormSwitch}
							disabled={isManagerAndLookSuperviseur}
							classes={{ root: classes.switch }}
						/>
					</Grid>
				</Grid>
				{selectedUser
					? <Grid container item className={classes.deleteButton}>
						<Button
							type="secondary"
							startIcon={<DeleteForeverIcon />}
							onClick={() => deleteUser(selectedUser)}
						>
							<FormattedMessage id="bo.utilisateur.form.deleteUser" />
						</Button>
					</Grid>
					: <React.Fragment />
				}
			</Grid>
		</form>

	)
}

PopinUserEdition.propType = {
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	fermeList: PropTypes.array,
	selectedUser: PropTypes.object,
	deleteUser: PropTypes.func,
	preferences: PropTypes.object
}

const mapStateToProps = (state, { selectedUser }) => {
	if (selectedUser) {
		return ({
			form: USER_EDITION_FORM,
			initialValues: {
				firstname: selectedUser.user.firstname,
				lastname: selectedUser.user.lastname,
				alias: selectedUser.user.preferences.ALIAS,
				email: selectedUser.user.email,
				ferme: selectedUser.ferme.id,
				profil: selectedUser.profil.id,
				actif: !selectedUser.user.disabled,
				id: selectedUser.user.id
			},
			userAuthorities: getAuthorities(state),
			preferences: getPreferences(state)
		})
	}
	return ({
		form: USER_EDITION_FORM,
		userAuthorities: getAuthorities(state),
		initialValues: {
			actif: true
		},
		preferences: getPreferences(state)
	})

}

const actions = {
	change
}


export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		validate
	}),
	injectFermeList,
)(PopinUserEdition)
