import React from 'react'
import {connect} from 'react-redux'
import {getTachesOrdonnancement, ordonnancementUpdateOrder} from '../../services/actions'
import {getOrdonnancementTaches, ordonnancementLoading} from '../../services/selectors'
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import OrdonnancementTableRow from './OrdonnancementTableRow'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import {FormattedMessage} from 'react-intl'
import moment from 'moment'
import {formatFilters} from '../../utils/tacheUtils'
import {getFilterValues} from '../../../planning/services/toolbar/toolbarSelector'
import {makeStyles, withStyles} from '@material-ui/styles'
import {getStyles} from 'isotope-client'
import {compose} from 'redux'
import LoaderAdvanced from 'react-loader-advanced'
import CircularProgress from '@material-ui/core/CircularProgress'
import {openTacheDetail} from "../../injectors/openTacheDetailsInjector";
import {injectTachePanel} from "../../form/tacheDetailPanel";
import {LocalDate} from "../../../../../utils/date/local-date";
import {DateFormat as LocaleDateFormat} from "../../../../../utils/date/model/date";

const StyledCell = withStyles(theme => ({
	root: {
		borderRight: '1px solid #c0c0c0',
		textAlign: 'center',
		height: 40,
		padding: '0 5px',
		boxSizing: 'border-box'
	},
	head: {
		height: 50,
		left: 'unset',
		lineHeight: '1.3',
		fontWeight: 'bold',
		backgroundColor: theme.palette.primary.light,
		'& span': {
			cursor: 'pointer'
		}
	}
}))(TableCell)

const useStyles = makeStyles(theme => getStyles({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	leftContainer: {
		maxHeight: 'calc(100vh - 280px)',
		flex: '0 0 auto',
		display: 'block',
		overflow: 'auto'
	}
}))

const OrdonnancementTable = (
	{
		taches,
		getTachesOrdonnancement,
		updateOrder,
		selected,
		setSelected,
		filters,
		loading,
		openTacheDetail
	}
) => {
	const { jourSelection, isResultat } = useGestionTacheContext()
	const [dragged, setDragged] = React.useState(null)
	const classes = useStyles()

	const noneSelected = React.useMemo(() => selected.length === 0, [selected])
	const handleUnselectAll = React.useCallback(() => setSelected([]), [setSelected])
	const filterValues = React.useMemo(() => formatFilters(filters), [filters])

	React.useEffect(() => {
		getTachesOrdonnancement(LocalDate.fromString(jourSelection).format(LocaleDateFormat.SHORT_DATE_WITH_DASH), filterValues)
	}, [getTachesOrdonnancement, jourSelection, filters])

	const handleDragStart = React.useCallback(start => {
		if (!selected.includes(start.draggableId)) {
			setSelected([start.draggableId])
		}
		setDragged(start.draggableId)
	}, [selected, setSelected])

	const handleDragEnd = React.useCallback(result => {
		setDragged(null)
		if (!result.destination) {
			return
		}
		if (result.destination.index === result.source.index) {
			return
		}
		updateOrder(
			selected,
			result.source.index,
			result.destination.index,
			moment(jourSelection).format('YYYY-MM-DD')
		)

	}, [updateOrder, selected, jourSelection])

	const handleSelect = React.useCallback((selectedId) => setSelected(selected => {
		if (selected.includes(selectedId)) {
			return selected.filter(id => id !== selectedId)
		}
		return [...selected, selectedId]
	}), [setSelected])

	return (
		<LoaderAdvanced
			show={loading}
			message={<CircularProgress />}
			backgroundStyle={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
			hideContentOnLoad
		>
			<div className={classes.leftContainer}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<StyledCell>
								<Checkbox
									disabled={noneSelected}
									indeterminate={true}
									onChange={handleUnselectAll}
								/>
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.tache" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranAffectations.table.headers.priorite" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.lieu" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.statut" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.materiel" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.chargeTravail" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.chargeParPersonne" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.nbPersonnes" />
							</StyledCell>
							<StyledCell>
								<FormattedMessage id="gestionTaches.ecranOrdonnancement.table.headers.affectations" />
							</StyledCell>
						</TableRow>
					</TableHead>
					<DragDropContext
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
					>
						<Droppable droppableId="listeTaches" direction="vertical">
							{({ innerRef: droppableRef, droppableProps, placeholder }) => <TableBody
								ref={droppableRef}
								{...droppableProps}
							>
								{taches.map((tache, index) => {
									const isSelected = selected.includes(tache.id)
									const isGhosting = isSelected && dragged != null && dragged !== tache.id
									return <Draggable
										key={tache.id}
										draggableId={tache.id}
										index={index}
										isDragDisabled={isResultat}
									>
										{({ innerRef: draggableRef, draggableProps, dragHandleProps }, { isDragging }) => {
											return <OrdonnancementTableRow
												tache={tache}
												isSelected={isSelected}
												isGhosting={isGhosting}
												isDragging={isDragging}
												handleSelect={handleSelect}
												numberOfDraggedElements={selected.length}
												ref={draggableRef}
												{...draggableProps}
												{...dragHandleProps}
												openTacheDetail={openTacheDetail}
											/>
										}}
									</Draggable>
								})}
								{placeholder}
							</TableBody>}
						</Droppable>
					</DragDropContext>
				</Table>
			</div>
		</LoaderAdvanced>
	)
}

const mapStateToProps = state => ({
	taches: getOrdonnancementTaches(state),
	filters: getFilterValues(state),
	loading: ordonnancementLoading(state)
})

const actions = {
	getTachesOrdonnancement,
	updateOrder: ordonnancementUpdateOrder
}

export default compose(
	openTacheDetail,
	injectTachePanel,
	connect(mapStateToProps, actions)
)(OrdonnancementTable)
