import { fetchFactory, urlUtils } from 'isotope-client'
import { buildUrlWithParams } from 'isotope-client/utils/urlUtils'

export const getAllPlanches = (idFerme) => fetchFactory(`/planche/${idFerme}`)

export const getPlanche = (idPlanche) => fetchFactory((`/planche/findOne/${idPlanche}`))

export const getAllCultures = (idFerme) => fetchFactory(`/culture/${idFerme}`)

export const getAllMateriels = (idFerme) => fetchFactory(`/materiel/${idFerme}`)

export const getAllReglages = () => fetchFactory(`/materiel/reglages`)

export const getAllCompetences = filters => fetchFactory(urlUtils.buildUrlWithParams('/competence', filters))

export const getAllFamilles = (idFerme) => fetchFactory(`/famille/${idFerme}`)

export const getAllCulturesWithItk = (idFerme) => fetchFactory(`/culture/${idFerme}/withItk`)

export const getItkFromPlancheAndCulture = (codeZone, idCulture) => fetchFactory(`/itk/${codeZone}/${idCulture}`)

export const getItk = (itkId) => fetchFactory(`/itk/${itkId}`)

export const getAllItks = (idFerme) => fetchFactory(buildUrlWithParams('/itk', {
	idFerme: idFerme
}))

export const getItkDetails = () => fetchFactory(`/itkDetails`)

export const getItkDetail = (idItk) => fetchFactory(`/itkDetails/${idItk}`)

export const creerAssolement = (idCampagne, values) => fetchFactory(`/assolement/${idCampagne}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const creerAssolementFromGroup = (idCampagne, values) => fetchFactory(`/assolement/groupe/${idCampagne}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const editListAssolement = (assolementsId, plancheId, forceValidation, values) => fetchFactory(`/assolement/list/move`, {
	method: 'PUT',
	body: JSON.stringify({ assolementsId, plancheId, forceValidation, ...values })
})

export const editAssolementPeriode = (idAssolement, nbWeeks, idCampagne) => fetchFactory(`/assolement/${idAssolement}/period/${idCampagne}`, {
	method: 'PUT',
	body: JSON.stringify({ nbWeeks })
})

export const creerListCommentaireSuivi = (assolementsId, values) => fetchFactory(`/assolement/list/commentaire`, {
	method: 'POST',
	body: JSON.stringify({...values, assolementsId})
})

export const modifierCommentaireSuivi = (idAssolement, values, idCommentaire) => fetchFactory(`/assolement/${idAssolement}/suivi/${idCommentaire}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const deleteListAssolement = (listAssolementsID) => fetchFactory(`/assolement/list/delete`, {
	method: 'DELETE',
	body: JSON.stringify(listAssolementsID)
})

export const editEtapeSuivi = (idAssolementEtape, values) => fetchFactory(`/assolementEtape/${idAssolementEtape}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const deleteAssolementEtape = (idAssolementEtape, idCampagne) => fetchFactory(`/assolementEtape/${idAssolementEtape}/${idCampagne}`, {
	method: 'DELETE'
})

export const creerCommentairePlanche = (idPlanche, idCampagne, values) => fetchFactory(`/campagne/${idCampagne}/planche/${idPlanche}/commentaire`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const modifierCommentairePlanche = (idCampagne, idPlanche, values, idCommentaire) => fetchFactory(`/campagne/${idCampagne}/planche/${idPlanche}/commentaire/${idCommentaire}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const validerPlanning = (idCampagne) => fetchFactory(`/assolement/planning/${idCampagne}`, {
	method: 'POST'
})

export const deleteCommentEtape = (idCommentaire) => fetchFactory(`/assolementEtape/commentaire/${idCommentaire}`, {
	method: 'DELETE'
})

export const deleteCommentPlanche = (idCommentaire) => fetchFactory(`/planche/commentaire/${idCommentaire}`, {
	method: 'DELETE'
})

export const deleteCommentSuivi = (idCommentaire) => fetchFactory(`/assolement/commentaire/${idCommentaire}`, {
	method: 'DELETE'
})

export const getFilterOptionsFamille = idFerme => fetchFactory(`/culture/filterOptions/famille?idFerme=${idFerme}`)

export const getFilterOptionsEspece = (idFerme, familles) => fetchFactory(`/culture/filterOptions/espece?idFerme=${idFerme}`, {
	method: 'POST',
	body: JSON.stringify(familles)
})

export const getFilterOptionsCulture = (idFerme, especes) => fetchFactory(`/culture/filterOptions/culture?idFerme=${idFerme}`, {
	method: 'POST',
	body: JSON.stringify(especes)
})

export const getFilterOptionsZone = idFerme => fetchFactory(`/planche/filterOptions/zone?idFerme=${idFerme}`)

export const getFilterOptionsBloc = (idFerme, zones) => fetchFactory(`/planche/filterOptions/bloc?idFerme=${idFerme}`, {
	method: 'POST',
	body: JSON.stringify(zones)
})

export const getFilterOptionsSurface = (idFerme, blocs) => fetchFactory(`/planche/filterOptions/surface?idFerme=${idFerme}`, {
	method: 'POST',
	body: JSON.stringify(blocs)
})

export const getAssolements = (campagneId, filterForm, sort, page) => fetchFactory(`/assolement/filter/${campagneId}/${sort}/${page}`, {
	method: 'POST',
	body: JSON.stringify(filterForm)
})

export const getAssolementsWithPosition = (campagneId, filterForm, sort, assolementId) => fetchFactory(`/assolement/find/${campagneId}/${sort}/${assolementId}`, {
	method: 'POST',
	body: JSON.stringify(filterForm)
})

export const editEtapeListPeriode = (values, idCampagne) => fetchFactory(`/assolement/moveEtapeList/${idCampagne}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const exportPlanning = (idFerme, idCampagne, typeExport) =>
	fetchFactory(`/assolement/export/${idFerme}/${idCampagne}/${typeExport}`, {
		method: 'POST'
	})

export const getState = (id) => fetchFactory(`/taskInProgress/${id}`)

export const getUnfertilizedPlanches = (idCampagne) => fetchFactory(`/planche/unfertilized/${idCampagne}`)

export const getPlanchesByType = (types, idFerme, nom) => fetchFactory(`/planche/type/${idFerme}`, {
	method: 'POST',
	body: JSON.stringify({ types, nom })
})

export const duplicateCulture = (idCampagne, values) => fetchFactory(`/assolement/duplicate/${idCampagne}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const duplicateListCulture = (idCampagne, values) => fetchFactory(`/assolement/list/duplicate/${idCampagne}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const delayEtape = (values, assolementsId) => fetchFactory(`/assolement/list/delayEtape`, {
	method: 'PUT',
	body: JSON.stringify(values, assolementsId)
})

export const modifierEtapeRecolteApi = (values, assolementsId) => fetchFactory(`/assolement/list/extendEtape`, {
	method: 'PUT',
	body: JSON.stringify(values, assolementsId)
})

export const getAllCampagnes = () => fetchFactory('/planning/campagne/list')

export const verrouilleAssolement = (idAssolement) => fetchFactory('/assolement/verrouille/' + idAssolement, { method: 'POST' })

export const deverrouilleAssolement = (idAssolement) => fetchFactory('/assolement/deverrouille/' + idAssolement, { method: 'POST' })

export const exportChargesTravail = (idCampagne, typeExport) => fetchFactory(`/taches/export/${idCampagne}/${typeExport}`, {
	method: 'POST'
})