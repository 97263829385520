import { fetchFactory, urlUtils } from 'isotope-client'

export const getAllBlocsAndSurfaceByIdFerme = (idFerme, query) => fetchFactory(urlUtils.buildUrlWithParams('/bo/blocsSurfaces', {
	idFerme,
	query
}))

export const updateBlocSurface = values => fetchFactory('/bo/blocsSurfaces', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const createBlocSurface = values => fetchFactory('/bo/blocsSurfaces', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getAllBlocsByIdFerme = (idFerme) => fetchFactory(urlUtils.buildUrlWithParams('/bo/blocsSurfaces/blocs', {
	idFerme
}))