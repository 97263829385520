import React from 'react'
import ActionPanelProvider from '../../../components/layout/contextProvider/ActionPanelProvider'
import DetailPanelProvider from '../../../components/layout/contextProvider/DetailPanelProvider'
import DialogProvider from '../../../components/layout/contextProvider/DialogProvider'
import {FilterPanelContext} from '../../../components/layout/rightPanels'
import PlanningComponents from './PlanningComponents'
import {PAGE, usePageContext} from '../../../components/layout/PageContext'
import {getCurrentCampagne} from "../../common/campagne/campagneSelector";
import {setActiveCampagne} from "../../common/campagne/campagneAction";
import {compose} from 'redux'
import {connect} from 'react-redux'

const Planning = ({setActiveCampagne, campagne}) => {
	const {setPage} = usePageContext()

	React.useEffect(() => {
		setPage(PAGE.PLANNING)
		return () => {
			setActiveCampagne({...campagne, preview: false})
		}
	}, [])

	return <DialogProvider>
		<DetailPanelProvider>
			<ActionPanelProvider Context={FilterPanelContext} level={1}>
				<ActionPanelProvider>
					<PlanningComponents/>
				</ActionPanelProvider>
			</ActionPanelProvider>
		</DetailPanelProvider>
	</DialogProvider>
}

const mapStateToProps = state => ({
	campagne: getCurrentCampagne(state)
})

const actions = {
	setActiveCampagne
}

export default compose(
	connect(mapStateToProps, actions)
)(Planning)
