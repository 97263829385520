import React from 'react'
import {Checkbox, TableRow} from '@material-ui/core'
import TableCell from '../common/TableCell'
import {makeStyles} from '@material-ui/styles'
import * as PropTypes from 'prop-types'
import {tacheShape} from '../../config/types'
import {FormattedMessage} from 'react-intl'
import {formatDuration} from '../../../../../utils/utils'
import FormattedTacheLieuMessage from '../common/FormattedTacheLieuMessage'
import TacheStatutIcon from '../common/TacheStatutIcon'
import classNames from 'classnames'
import InfoTache from '../../../../common/information/InfoTache'
import {getInfosTacheForDetailsPanel} from "../../gestionTachesApi";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: ({isSelected}) => isSelected ? theme.palette.table.selectedRow : theme.palette.common.white,
		opacity: ({isGhosting}) => isGhosting ? 0.5 : 1
	},
	ressourceWarning: {
		color: theme.palette.error.main
	}
}))

const OrdonnancementTableRow = React.forwardRef(({tache, isSelected, isGhosting, isDragging, numberOfDraggedElements, handleSelect, openTacheDetail, ...other}, ref) => {
	const classes = useStyles({isSelected, isGhosting})
	return <TableRow
		classes={{
			root: classes.root
		}}
		ref={ref}
		{...other}
	>
		{isDragging ?
			<TableCell>
				<FormattedMessage id="gestionTaches.ecranOrdonnancement.dragging.elements" values={{number: numberOfDraggedElements}}/>
			</TableCell> :
			<>
				<TableCell>
					<Checkbox
						checked={isSelected}
						onChange={() => handleSelect(tache.id)}
					/>
				</TableCell>
				<TableCell>
					<span onClick={(event) => {
						getInfosTacheForDetailsPanel(tache.id).then((res) => openTacheDetail(res))
						event.preventDefault()
						event.stopPropagation()
					}}>
						<InfoTache tache={tache} inTable/>
					</span>
				</TableCell>
				<TableCell>{tache.priorite}</TableCell>
				<TableCell><FormattedTacheLieuMessage typeLieu={tache.typeLieu} lieu={tache.lieu}/></TableCell>
				<TableCell><TacheStatutIcon statut={tache.statut} isEnRetard={tache.enRetard}/></TableCell>
				<TableCell>{tache.materiels && tache.materiels.length > 0 ? tache.materiels.join(', ') : '-'}</TableCell>
				<TableCell>{formatDuration(tache.chargeTravail)}</TableCell>
				<TableCell>{formatDuration(tache.chargeUnitaire)}</TableCell>
				<TableCell className={classNames({
					[classes.ressourceWarning]: tache.ressources.length < tache.nbPersonnes
				})}>{tache.nbPersonnes}</TableCell>
				<TableCell>
					{tache.ressources.map((ressource, index) => (
						<span key={index}>
      						{ressource?.preferences?.ALIAS + ' '}
							{ressource?.roles?.[0] && (
								<span style={{fontStyle: 'italic'}}>
        						  <FormattedMessage id={`enums.roles.${ressource.roles[0].code.toUpperCase()}`}/>
       							 </span>
							)}
							{index < tache.ressources.length - 1 && ', '}
    					</span>
					))}
				</TableCell>

			</>
		}
	</TableRow>
})

OrdonnancementTableRow.propTypes = {
	tache: tacheShape.isRequired,
	isSelected: PropTypes.bool.isRequired,
	isGhosting: PropTypes.bool.isRequired,
	isDragging: PropTypes.bool.isRequired,
	handleSelect: PropTypes.func.isRequired
}

export default OrdonnancementTableRow
