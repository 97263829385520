import React from 'react'
import {Grid, MenuItem, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {getStyles} from 'isotope-client'
import {change, Field, FieldArray, formValueSelector, reduxForm} from 'redux-form'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from '../../../../components/form/Input'
import CloseIcon from '@material-ui/icons/Close';
import PostAdd from '@material-ui/icons/PostAdd';
import Typography from "@material-ui/core/Typography";
import {getAllCompetences} from "../../../fo/planning/services/planningApi";
import Button from "../../../../components/Button";
import AutocompleteMulti from "../../../../components/form/AutocompleteMulti";
import Select from "../../../../components/form/Select";
import {CATEGORIE_METEO, colors, COMPETENCE, IMPACT_METEO, STATUT_METEO} from "../../../../utils/constants";
import classnames from "classnames";

const styles = theme => getStyles({
	field: {
		width: '50%'
	},
	fieldAll: {
		width: '100%'
	},
	littleField: {
		width: '25%'
	},
	sensibiliteField: {
		width: '30%'
	},
	buttonClose: {
		cursor: 'pointer',
		color: colors.primary,
		paddingLeft: 15
	},
	row: {
		marginBottom: 15
	},
	title: {
		fontWeight: 700,
		paddingLeft: 12,
		fontSize: 16
	},
	description: {
		fontSize: 12,
		paddingLeft: 12,
		fontStyle: 'italic'
	},
	categorieTitle: {
		fontWeight: 700,
		paddingLeft: 12,
		fontSize: 12
	},
	categorieValue: {
		paddingLeft: 25,
		fontSize: 12
	},
	space: {
		paddingTop: 15
	}
})

const formattedMessageBase = 'bo.categoriesTechniques.form'

const FIELDS = {
	NOM: { name: 'nom' },
	CODE: { name: 'code' },
	CATEGORIE: { name: 'categorieMeteo', optional: true },
	VALEUR: { name: 'statutMeteo', optional: true },
	IMPACT: { name: 'impactMeteo', optional: true },
	COMPETENCES_PARTICIPANTS: { name: 'participants', optional: true },
	COMPETENCES_SPECIALISTE: { name: 'specialistes', optional: true },
}

const validate = (values) => {
	const errors = {}

	Object.values(FIELDS).filter(field => !field.optional).forEach(field => {
		if (!values[field.name]) {
			errors[field.name] = { id: 'global.errors.mandatory' }
		}
	})

	return errors
}

const CategorieForm = ({
	classes,
	handleSubmit,
	initialValues,
	formName,
	category
}) => {

	const getCompetences= (type) => {
		return getAllCompetences().then(competence => competence.filter(competence => competence.typeCompetence === type).map(competence => ({
			code: competence.id,
			label: competence.nom
		})))
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container className={classes.row}>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.CODE.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.category.${FIELDS.CODE.name}`} />}
						fromBo
					/>
				</Grid>
				<Grid item className={classes.field}>
					<Field
						name={FIELDS.NOM.name}
						component={Input}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.category.${FIELDS.NOM.name}`} />}
						fromBo
					/>
				</Grid>
				<Grid container direction="column" alignItems="flex-start" className={classes.row}>
					<Typography className={classes.title}>
						<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.title`} />
					</Typography>
					<Typography className={classes.description}>
						<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.description`} />
					</Typography>
					<FieldArray name="sensibilite" category={category} classes={classes} component={renderSensibiliteFields} />
				</Grid>
				<Grid container direction="column" alignItems="flex-start" className={classes.row}>
					<Typography className={classes.title}>
						<FormattedMessage id={`${formattedMessageBase}.fields.category.competence.title`} />
					</Typography>
					<Typography className={classes.description}>
						<FormattedMessage id={`${formattedMessageBase}.fields.category.competence.description`} />
					</Typography>
				</Grid>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.COMPETENCES_PARTICIPANTS.name}
						component={AutocompleteMulti}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.category.competence.${FIELDS.COMPETENCES_PARTICIPANTS.name}`} />}
						getOptions={() => getCompetences(COMPETENCE.PARTICIPANT)}
						fromBo
						forceGetOptions
						formName={formName}
					/>
				</Grid>
				<Grid item className={classes.fieldAll}>
					<Field
						name={FIELDS.COMPETENCES_SPECIALISTE.name}
						component={AutocompleteMulti}
						label={<FormattedMessage id={`${formattedMessageBase}.fields.category.competence.${FIELDS.COMPETENCES_SPECIALISTE.name}`} />}
						getOptions={() => getCompetences(COMPETENCE.MACHINISTE)}
						fromBo
						forceGetOptions
						formName={formName}
					/>
				</Grid>
				{(initialValues?.sansReglage.length > 0 || initialValues?.avecReglage.length > 0) && <Grid container direction="column" alignItems="flex-start" className={classes.row}>
					<Typography className={classes.title}>
						<FormattedMessage id={`${formattedMessageBase}.fields.category.materiel.title`}/>
					</Typography>
				</Grid>}
				<Grid container direction="column" alignItems="flex-start" className={classes.row}>
					{initialValues?.sansReglage.length > 0 && <Grid>
						<Typography className={classes.categorieTitle}>
							<FormattedMessage id={`${formattedMessageBase}.fields.category.materiel.sansReglage`}/>
								</Typography>
								{initialValues?.sansReglage.map((materiel) =>
									<Typography className={classes.categorieValue}>
										<li>{materiel.label}</li>
									</Typography>
								)}
					</Grid>}
					{initialValues?.avecReglage.length > 0 && <Grid>
						<Typography className={classes.categorieTitle}>
							<FormattedMessage id={`${formattedMessageBase}.fields.category.materiel.avecReglage`}/>
						</Typography>
						{initialValues?.avecReglage.map((materiel) =>
							<Typography className={classes.categorieValue}>
								<li>{materiel.label}</li>
							</Typography>
						)}
					</Grid>}
				</Grid>
			</Grid>
		</form>
	)
}

const renderSensibiliteFields = ({ fields, category, classes }) => (
		<Grid container direction="column" alignItems="center">
			{fields.map((fieldName, index) => (
				<Grid key={index} item container direction="row" alignItems="center" justify="flex-start" style={{paddingTop: 10}}>
					<Grid item className={classes.sensibiliteField}>
						<Field
							name={`${fieldName}.${FIELDS.CATEGORIE.name}`}
							component={Select}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.${FIELDS.CATEGORIE.name}`}/>}
							fromBo
						>
							{Object.values(CATEGORIE_METEO).map(type => <MenuItem key={`type-option-${type}`} value={type}>
								<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.values.${type}`}/>
							</MenuItem>)}
						</Field>
					</Grid>
					<Grid item className={classes.sensibiliteField}>
						<Field
							name={`${fieldName}.${FIELDS.VALEUR.name}`}
							component={Select}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.${FIELDS.VALEUR.name}`}/>}
							fromBo
						>
							{category && Object.values(STATUT_METEO[category[index]?.categorieMeteo || 'TEMPERATURE']).map(type => <MenuItem key={`type-option-${type}`} value={type}>
								<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.values.${type}`}/>
							</MenuItem>)}
						</Field>
					</Grid>
					<Grid item className={classes.sensibiliteField}>
						<Field
							name={`${fieldName}.${FIELDS.IMPACT.name}`}
							component={Select}
							label={<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.${FIELDS.IMPACT.name}`}/>}
							fromBo
						>
							{Object.values(IMPACT_METEO).map(type => <MenuItem key={`type-option-${type}`} value={type}>
								<FormattedMessage id={`${formattedMessageBase}.fields.category.sensibilite.values.${type}`}/>
							</MenuItem>)}
						</Field>
					</Grid>
					<Grid item className={classes.buttonClose} onClick={() => fields.remove(index)}>
						<CloseIcon/>
					</Grid>
				</Grid>
			))}
			<Grid item style={{paddingTop: 5}}>
				<Button startIcon={<PostAdd/>} withBorder={false} variant="text" className={classnames(classes.actionBtn, classes.leftButton)}  onClick={() => fields.push({})}>
					<FormattedMessage id={`${formattedMessageBase}.ajoutSensibilite`} />
				</Button>
			</Grid>
		</Grid>
);

CategorieForm.propTypes = {
	formName: PropTypes.string,
	initialValues: PropTypes.object,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	category: PropTypes.object
}

const mapStateToProps = (state, { formName }) => {
	const formSelector = formValueSelector(formName);
	return {
		form: formName,
		category: formSelector(state, 'sensibilite'),
	}
}
const actions = {
	change
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		validate,
		enableReinitialize: true
	}),
	withStyles(styles)
)(CategorieForm)
