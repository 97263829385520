import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {dataTableActions, dataTableValueSelectors, getStyles} from 'isotope-client'
import {makeStyles} from '@material-ui/styles'
import {Grid, IconButton, Typography} from '@material-ui/core'
import moment from 'moment'
import {PLANIFICATION_TABLE_NAME} from '../../utils/constants'
import Selected from '@material-ui/icons/LibraryAddCheck'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import HeaderDiffCell from '../common/HeaderDiffCell'
import {ReactComponent as Gauge} from '../../../../../icons/gauge.svg'
import {formatDuration} from '../../../../../utils/utils'
import {useGestionTacheContext} from '../../GestionTacheProvider'
import ToolBarButton from "../../../planning/component/ToolBar/ToolBarButton";
import FilterIcon from "../../../../../components/icon/FilterIcon";
import {injectPlanificationFilter} from "../../injectors/filterInjector";
import Button from "../../../../../components/Button";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ButtonWithMenu from '../../../../../components/ButtonWithMenu'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {DeleteForever} from '@material-ui/icons'
import {DialogContext} from "../../../../../components/layout/dialog";
import {useSnackbar} from "../../../../../components/layout/snackbar/SnackbarContext";
import {STATUTS_TACHE, TYPE_DIALOG} from "../../../../../utils/constants";
import ContenuPopinSuppressionErrorTache from "./ContenuPopinSuppressionErrorTache";
import ContenuPopinSuppressionTache, {formName} from "./ContenuPopinSuppressionTache";
import FormButton from "../../../../../components/form/FormButton";
import {addInstructions, delayTache, deleteTasks as deleteTasksApi} from "../../gestionTachesApi";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import InstructionForm from "../../../tourPlaine/component/panel/InstructionForm";
import {ACTIONS_KEY, ADD_INSTRUCTION_FIELD, MOVE_TASK_FIELD, PANEL_FORM} from "../../../tourPlaine/tool/tourPlaine.constants";
import {FilterPanelContext} from "../../../../../components/layout/rightPanels";
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import MoveForm from "../../../tourPlaine/component/panel/MoveForm";
import {injectCreateTask} from "../../injectors/createTaskInjector";
import QuestionIcon from '../../../../../components/icon/QuestionIcon'
import LegendeTaches from '../LegendeTaches'

const useStyles = makeStyles(theme => getStyles({
	root: {
		marginBottom: 8,
		height: 105,
		fontFamily: 'Lato',
		maxWidth: '100%'
	},
	tableScrollable: {
		width: 'calc(100% - 20px)'
	},
	cell: {
		backgroundColor: theme.palette.primary.light,
	},
	selectedTaches: {
		width: 'calc(18.6%)'
	},
	capaciteTotale: {
		width: 'calc(23.2%)'
	},
	chargeTotale: {
		width: 'calc(17%)'
	},
	jour: {
		width: 'calc(6% - 8px)',
		justifyContent: 'center'
	},
	iconContainer: {
		width: 60,
	},
	icon: {
		fill: theme.palette.primary.main
	},
	iconBig: {
		width: 36,
		height: 36
	},
	kpiContainer: {
		width: 'unset',
		paddingLeft: 5
	},
	tachesSelectionneesDuree: {
		fontWeight: 'bold',
		fontSize: 16
	},
	total: {
		fontWeight: 'bold',
		fontSize: 20,
		color: theme.palette.primary.main
	},
	valid: {
		color: theme.palette.valid.main
	},
	error: {
		color: theme.palette.error.main
	},
	label: {
		fontSize: 13
	},
	title: {
		fontStyle: 'italic',
		fontSize: 14,
		alignContent: 'center'
	},
	bouton: {
		padding: '5px 26px',
		marginLeft: 20
	}
}))

const HeaderPlanification = ({selected, reference, nbElement, setFilterOpen, resetSelectedRows, openCreateTask}) => {
	const classes = useStyles()
	const {headerData} = useGestionTacheContext()
	const [tableScrollable, setTableScrollable] = React.useState(false)
	const {refreshHeaderData, isResultat} = useGestionTacheContext()
	const {openDialog, closeDialog} = React.useContext(DialogContext)
	const {snackError, snackSuccess} = useSnackbar()
	const {openPanel, closePanel} = React.useContext(FilterPanelContext)

	React.useEffect(() => {
		setTimeout(() => {
			setTableScrollable(reference && reference.current && reference.current.offsetHeight < reference.current.scrollHeight)
		}, 0)
	}, [nbElement])

	const chargeTachesSelectionnees = React.useMemo(
		() => selected.reduce((acc, tache) => acc.add(moment.duration(tache.chargeTravail)), moment.duration(0)),
		[selected]
	)
	const isError = React.useMemo(() => moment.duration(headerData.capaciteTotale).asMinutes() < moment.duration(headerData.chargeTravailTotale).asMinutes(), [headerData])

	const moveTasks = () => {
		openPanel(
			<Grid container alignItems="center" style={{gap: 20}}>
				<SyncAltIcon color="primary"/>
				<FormattedMessage id="gestionTaches.ecranPlanification.move.title" values={{quantity: selected.length}}/>
			</Grid>,
			<MoveForm
				isFromPlanification
				items={selected}
				type={ACTIONS_KEY.MOVE_TASK}
				onSubmit={values => delayTache(selected.map(t => t.id), values[MOVE_TASK_FIELD.DATE], null)
					.then(() => {
						closePanel()
						refreshHeaderData()
						resetSelectedRows()
						snackSuccess({id: 'listAssolement.snackbar.ok.deplacerEtapes'})
					})
					.catch(() => {
						snackError({id: 'listAssolement.snackbar.error.deplacerEtapes'})
					})}
			/>,
			[
				<Button type="secondary" onClick={closePanel}>
					<FormattedMessage id="actions.cancel"/>
				</Button>,
				<FormButton type="primary" formName={PANEL_FORM.MOVE_TASK}>
					<FormattedMessage id="tourPlaine.form.submit"/>
				</FormButton>
			]
		)
	}

	const ajouterInstructions = () => {
		openPanel(
			<Grid container alignItems="center" style={{gap: 20}}>
				<NoteAddIcon color="primary"/>
				<FormattedMessage id="tourPlaine.form.instruction.title"/>
			</Grid>,
			<InstructionForm
				isFromPlanification
				items={selected}
				onSubmit={values => addInstructions(selected.map(t => t.id), values[ADD_INSTRUCTION_FIELD.INSTRUCTION])
					.then(() => {
						closePanel()
						snackSuccess({id: 'snackbar.update.tourPlaine.generic'})
					})
					.catch(() => {
						snackError({id: 'snackbar.error.tourPlaine.generic'})
					})
				}
			/>,
			[<FormButton type="primary" formName={PANEL_FORM.ADD_INSTRUCTION}>
				<FormattedMessage id="tourPlaine.form.submit"/>
			</FormButton>]
		)
	}

	const deleteTasks = () => {
		if (selected.some(task => task.statut === STATUTS_TACHE.TERMINEE)) {
			openDialog(
				<span className={classes.spanTitlePopin}>
					<WarningRoundedIcon className={classes.error}/>
					<FormattedMessage id="gestionTaches.toolbar.actions.delete.popin.error.title"/>
				</span>,
				<ContenuPopinSuppressionErrorTache tachesEnErreur={selected.filter(tache => tache.statut === STATUTS_TACHE.TERMINEE)}/>,
				[], TYPE_DIALOG.ACTION, 'actions.close'
			)
		} else {
			openDialog(
				{id: 'gestionTaches.toolbar.actions.delete.popin.validation.title'},
				<ContenuPopinSuppressionTache tachesRecurrentes={selected.filter(tache => tache.recurrence)} onSubmit={handleDeleteTasks}/>,
				[
					<FormButton
						type="primary"
						formName={formName}
					>
						<FormattedMessage id="actions.delete"/>
					</FormButton>
				], TYPE_DIALOG.ACTION
			)
		}
	}

	const handleDeleteTasks = React.useCallback((values) => {
		deleteTasksApi(selected.map(task => task.id), values.typeSuppression).then(() => {
			refreshHeaderData()
			resetSelectedRows()
			closeDialog()
			snackSuccess({id: 'gestionTaches.toolbar.actions.delete.success'})
		}).catch(() => {
			closeDialog()
			snackError({id: 'gestionTaches.toolbar.actions.delete.error'})
		})
	}, [selected])

	return <Grid container className={classes.root}>
		<Grid container item justify="space-around" direction="row">
			<Grid container item xs={6}>
				<Typography variant={"caption"} className={classes.title}>
					<FormattedMessage id="gestionTaches.ecranOrdonnancement.header.title"/>
				</Typography>
			</Grid>
			<Grid container item xs={6} justify="flex-end" alignItems="center" direction="row">
				{!isResultat && <>
					<Button
					type="primary"
					startIcon={<PlaylistAddIcon/>}

					onClick={() => openCreateTask()}
					className={classes.bouton}
				>
					<FormattedMessage id="gestionTaches.ecranPlanification.ajout.title"/>
				</Button>
					<ButtonWithMenu
						type="secondary"
						startIcon={<MoreHorizIcon/>}
						items={[
							{
								label: <FormattedMessage id="gestionTaches.ecranPlanification.header.actions.move" values={{quantity: selected.length}}/>,
								icon: <SyncAltIcon color="primary"/>,
								onClick: () => moveTasks()
							},
							{
								label: <FormattedMessage id="gestionTaches.ecranPlanification.header.actions.addInstruction"/>,
								icon: <NoteAddIcon color="primary"/>,
								onClick: () => ajouterInstructions()
							},
							{
								label: <FormattedMessage id="gestionTaches.ecranPlanification.header.actions.delete" values={{quantity: selected.length}}/>,
								icon: <DeleteForever color="primary"/>,
								onClick: () => deleteTasks()
							}
						]}
						disabled={!selected.length}
						className={classes.bouton}
					>
						<FormattedMessage id="actions.other"/>
					</ButtonWithMenu>
				</>}
				<ToolBarButton type="filter" onClick={setFilterOpen}>
					<FilterIcon color="currentColor"/>
				</ToolBarButton>
				<IconButton className={classes.icon} onClick={() => openDialog({ id: 'legende.title' }, <LegendeTaches />, [], TYPE_DIALOG.DETAILS,'actions.close')}>
					<QuestionIcon />
				</IconButton>
			</Grid>
		</Grid>
		<Grid container item className={classNames({[classes.tableScrollable]: tableScrollable})} justify="space-between">
			<Grid item container className={classNames(classes.cell, classes.selectedTaches)} alignItems="center" wrap="nowrap">
				<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
					<Selected className={classes.icon}/>
				</Grid>
				<Grid item container direction="column" className={classes.kpiContainer}>
					<span className={classes.tachesSelectionneesDuree}>{chargeTachesSelectionnees.format('h[h]mm', {trim: false})}</span>
					<FormattedMessage id="gestionTaches.ecranPlanification.header.tachesSelectionnees">
						{text => <span className={classes.label}>{text}</span>}
					</FormattedMessage>
				</Grid>
			</Grid>
			<Grid item container className={classNames(classes.cell, classes.capaciteTotale)} alignItems="center" wrap="nowrap">
				<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
					<Gauge className={classNames(classes.icon, classes.iconBig)}/>
				</Grid>
				<Grid item container direction="column" className={classes.kpiContainer}>
					<span className={classes.total}>{formatDuration(headerData.capaciteTotale)}</span>
					<FormattedMessage id="gestionTaches.ecranPlanification.header.capaciteTotale">
						{text => <span className={classes.label}>{text}</span>}
					</FormattedMessage>
				</Grid>
			</Grid>
			<Grid item container className={classNames(classes.cell, classes.chargeTotale)} alignItems="center" wrap="nowrap">
				<Grid item container className={classes.iconContainer} alignContent="center" justify="center">
					<TimelapseIcon className={classNames(classes.icon, classes.iconBig)}/>
				</Grid>
				<Grid item container direction="column" className={classes.kpiContainer}>
					<span className={classNames(classes.total, {[classes.error]: isError})}>{formatDuration(headerData.chargeTravailTotale)}</span>
					<FormattedMessage id="gestionTaches.ecranPlanification.header.chargeTotale">
						{text => <span className={classes.label}>{text}</span>}
					</FormattedMessage>
				</Grid>
			</Grid>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielLundi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielMardi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielMercredi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielJeudi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielVendredi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielSamedi}/>
			<HeaderDiffCell className={classNames(classes.cell, classes.jour)} valeur={headerData.differentielDimanche}/>
		</Grid>
	</Grid>

}

HeaderPlanification.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	selected: dataTableValueSelectors.getDataTableSelectedRows(state)(PLANIFICATION_TABLE_NAME) || [],
	nbElement: dataTableValueSelectors.getDataTableTotalElements(state)(PLANIFICATION_TABLE_NAME)
})

const actions = {
	resetSelectedRows: () => dataTableActions.selectRows(PLANIFICATION_TABLE_NAME, [])
}

export default compose(
	connect(mapStateToProps, actions),
	injectPlanificationFilter,
	injectCreateTask
)(HeaderPlanification)
