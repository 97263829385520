import { fetchFactory, postMultipart, serializeParts } from 'isotope-client'
import { buildUrlWithParams } from 'isotope-client/utils/urlUtils'

export const saveMoTache = (values) => postMultipart('/bo/modeleTache', {
	body: serializeParts([{ key: 'form', value: values }, { key: 'instructions', value: values.instructions }])
})

export const getAllBlocs = (idFerme) => fetchFactory(buildUrlWithParams('/planche/blocs', { idFerme }))

export const getPdfLink = (idDoc) => fetchFactory(buildUrlWithParams('/bo/modeleTache/pdf', { idDoc }))
