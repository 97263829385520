import React from 'react'
import PropTypes from 'prop-types'

const AbsenceIcon = ({ height, width }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 512 512"
	>
		<path
			fill="#93d0fd"
			d="M28.064 350.698a227.07 227.07 0 0 1-.734-1.769c-.195-.476-3.076-.952-3.268-1.429C12.673 319.23 6.404 288.347 6.404 256c0-135.31 109.69-245 245-245s245 109.69 245 245c0 32.347-6.269 63.23-17.658 91.5-.144.357-2.635.713-2.78 1.069-.291.712-.584 1.421-.881 2.13H28.064z"
		/>
		<path
			fill="#65befc"
			d="M478.746 347.5C442.495 437.486 354.367 501 251.404 501c-46.877 0-90.679-13.165-127.91-36-2.222-1.363-3.416-5.434-5.59-6.865a246.103 246.103 0 0 1-30.436-23.646c-1.613-1.47-4.212-.286-5.785-1.798a246.465 246.465 0 0 1-22-24.138c-2.149-2.697-1.923-11.361-3.959-14.149-8.162-11.178-15.649-21.329-21.851-33.829-.72-1.452-3.497 1.262-4.189-.206a244.115 244.115 0 0 1-5.621-12.869z"
		/>
		<path
			fill="#0093fa"
			d="M111.683 442.525a2 2 0 0 0 1.739 1.983l18.133 2.384c4.613.606 8.055 4.459 8.055 9.014 0 5.023-4.16 9.095-9.292 9.095h-6.824a245.993 245.993 0 0 1-41.811-32.309V316.148a2 2 0 0 0-2-2h-18a2 2 0 0 0-2 2v92.405a245.048 245.048 0 0 1-30-48.184v-48.248c0-1.877-2.188-2.81-3.615-1.591a12.104 12.104 0 0 1-7.885 2.892c-6.627 0-12-5.258-12-11.745v-1.753a5 5 0 0 1-4.719-8.394l4.719-4.719V163.962c0-12.021 8.359-22.341 19.901-24.567l7.788-1.495 5.345 1.802v118.227c0 11.046 8.954 20 20 20h75.966v8.883c2.595 2.595 4.719 7.721 4.719 11.391v.398a5 5 0 0 1-4.719 1.323v1.753c0 6.487-5.373 11.745-12 11.745a12.1 12.1 0 0 1-7.885-2.892c-1.427-1.219-3.615-.285-3.615 1.591z"
		/>
		<path
			fill="#bb5d4c"
			d="M445.183 194.869v98.205c0 6.487-5.373 11.745-12 11.745s-12-5.258-12-11.745v-98.205c0-6.486 5.373-11.745 12-11.745s12 5.259 12 11.745zm-117-11.745c-6.627 0-12 5.258-12 11.745v98.205c0 6.487 5.373 11.745 12 11.745s12-5.258 12-11.745v-98.205c0-6.486-5.373-11.745-12-11.745z"
		/>
		<path
			fill="#c96e59"
			d="M449.902 300.602a4.997 4.997 0 0 1-4.719 1.323v1.753c0 6.486-5.373 11.745-12 11.745s-12-5.258-12-11.745v-10.04c0-6.486 5.373-11.745 12-11.745 3.329 0 6.342 1.327 8.516 3.47.129.106.254.218.375.339l7.828 7.828a5.002 5.002 0 0 1 0 7.072zm-121.719-18.709c-3.103 0-5.93 1.153-8.061 3.044a4.964 4.964 0 0 0-.51.446l-8.148 8.148a5 5 0 0 0 4.719 8.394v1.753c0 6.486 5.373 11.745 12 11.745s12-5.258 12-11.745v-10.04c0-6.487-5.373-11.745-12-11.745z"
		/>
		<path
			fill="#0066ed"
			d="M380.077 272v39.148a5 5 0 0 1-5 5h-3.394a2 2 0 0 0-2 2v124.577c0 7.414-6.011 13.425-13.425 13.425h-16.575V272a5 5 0 0 1 5-5h30.394a5 5 0 0 1 5 5z"
		/>
		<path
			fill="#0077f0"
			d="M421.683 272v184.15h-16.477c-7.468 0-13.523-6.054-13.523-13.523V318.148a2 2 0 0 0-2-2H372.43V272a5 5 0 0 1 5-5h39.253a5 5 0 0 1 5 5z"
		/>
		<path
			fill="#00429c"
			d="M369.683 442.532v15.373c0 5.023-4.16 9.095-9.292 9.095h-37.843c-5.132 0-9.292-4.072-9.292-9.095 0-4.555 3.443-8.407 8.055-9.014z"
		/>
		<path
			fill="#0055ca"
			d="M448.111 457.905c0 5.023-4.16 9.095-9.292 9.095h-37.843c-5.132 0-9.292-4.072-9.292-9.095v-15.373l48.372 6.36c4.612.606 8.055 4.458 8.055 9.013z"
		/>
		<path
			fill="#fa0"
			d="M376.875 153.293v41.576h-60.691v-28.907c0-12.021 8.359-22.341 19.901-24.567l19.911-3.903 1.551-.216c10.039-1.897 19.328 5.801 19.328 16.017z"
		/>
		<path
			fill="#ffb509"
			d="M445.183 194.869h-23.5v77.237h-82V155.644l.001-.183c.062-8.717 6.514-16.098 15.115-17.741l2.218-.424a124.322 124.322 0 0 1 11.088-1.605h.001a124.385 124.385 0 0 1 35.629 1.605l21.452 4.098c11.597 2.227 19.996 12.546 19.996 24.567z"
		/>
		<path
			fill="#bb5d4c"
			d="m392.72 130.142-.037.013v12.106c0 6.607-5.169 12.275-11.775 12.396-6.73.123-12.225-5.296-12.225-11.998v-12.504c-14.438-4.976-24.811-18.681-24.811-34.81V83.811c0-20.33 16.481-36.811 36.811-36.811 4.367 0 8.556.76 12.443 2.156 1.233.443 1.42 2.121.286 2.777-10.995 6.367-18.391 18.258-18.391 31.877v11.534c0 13.454 7.218 25.222 17.993 31.643 1.272.759 1.105 2.671-.294 3.155z"
		/>
		<path
			fill="#c96e59"
			d="M417.926 83.027v13.101c0 15.637-11.016 29.044-25.242 34.027-14.226-4.983-24-18.39-24-34.027V83.027c0-15.637 10.179-28.902 24.405-33.885 14.226 4.984 24.837 18.248 24.837 33.885z"
		/>
		<path
			fill="#00a4fb"
			d="M135.183 163.962v119.312H53.872c-11.046 0-20-8.954-20-20V137.9l11.349-2.178.006-.001 2.208-.424a123.215 123.215 0 0 1 11.035-1.605h.001a123.21 123.21 0 0 1 35.459 1.605l21.35 4.098c11.544 2.226 19.903 12.546 19.903 24.567z"
		/>
		<path
			fill="#0093fa"
			d="M107.494 81.811v11.534c0 16.128-10.373 29.833-24.811 34.81v12.504c0 6.627-5.373 12-12 12s-12-5.373-12-12v-12.504c-14.438-4.976-24.811-18.681-24.811-34.81V81.811C33.872 61.481 50.353 45 70.683 45s36.811 16.481 36.811 36.811z"
		/>
		<path
			fill="#00a4fb"
			d="M108.316 81.588v13.101c0 15.637-11.407 28.483-25.633 33.466-14.226-4.983-24-18.389-24-34.026V81.027c0-15.637 10.179-28.902 24.405-33.885 14.227 4.984 25.228 18.809 25.228 34.446z"
		/>
		<path
			fill="#ffbfab"
			d="M185.183 248.652v42.022c0 6.487-5.373 11.745-12 11.745s-12-5.258-12-11.745v-42.022c0-6.486 5.373-11.745 12-11.745s12 5.259 12 11.745zM290.183 248.652v42.022c0 6.487-5.373 11.745-12 11.745s-12-5.258-12-11.745v-42.022c0-6.486 5.373-11.745 12-11.745s12 5.259 12 11.745z"
		/>
		<path
			fill="#ffcebf"
			d="M294.902 299.602a4.997 4.997 0 0 1-4.719 1.323v1.753c0 6.486-5.373 11.745-12 11.745s-12-5.258-12-11.745v-10.04c0-6.486 5.373-11.745 12-11.745a12.08 12.08 0 0 1 9.109 4.1c.188.141.367.296.538.467l7.071 7.071a5 5 0 0 1 .001 7.071zm-121.719-18.709a12.08 12.08 0 0 0-9.109 4.1 4.916 4.916 0 0 0-.538.467l-7.071 7.071a5 5 0 0 0 4.719 8.394v1.753c0 6.486 5.373 11.745 12 11.745s12-5.258 12-11.745v-10.04c-.001-6.487-5.374-11.745-12.001-11.745z"
		/>
		<path
			fill="#0066ed"
			d="M225.077 271v39.148a5 5 0 0 1-5 5h-3.394a2 2 0 0 0-2 2v124.577c0 7.414-6.011 13.425-13.425 13.425h-16.575V271a5 5 0 0 1 5-5h30.394a5 5 0 0 1 5 5z"
		/>
		<path
			fill="#0077f0"
			d="M266.683 271v184.15h-16.477c-7.468 0-13.523-6.054-13.523-13.523V317.148a2 2 0 0 0-2-2H217.43V271a5 5 0 0 1 5-5h39.253a5 5 0 0 1 5 5z"
		/>
		<path
			fill="#00429c"
			d="M214.683 441.532v15.373c0 5.023-4.16 9.095-9.292 9.095h-37.843c-5.132 0-9.292-4.072-9.292-9.095 0-4.555 3.443-8.407 8.055-9.014z"
		/>
		<path
			fill="#0055ca"
			d="M293.111 456.905c0 5.023-4.16 9.095-9.292 9.095h-37.843c-5.132 0-9.292-4.072-9.292-9.095v-15.373l48.372 6.36c4.612.606 8.055 4.458 8.055 9.013z"
		/>
		<path
			fill="#bb5d4c"
			d="M161.183 249.681v-61.582c0-6.486 5.373-11.745 12-11.745s12 5.258 12 11.745v61.582z"
		/>
		<path
			fill="#bb5d4c"
			d="M221.875 193.869h-55.691a5 5 0 0 1-5-5v-23.907c0-12.021 8.359-22.341 19.901-24.567l20.855-4.083c.037-.007.57-.029.608-.036 10.052-1.871 19.327 5.896 19.327 16.121z"
		/>
		<path
			fill="#c96e59"
			d="M285.159 193.869h-95.457c-2.772-.003-5.019-2.24-5.019-5v-34.225l.001-.183c.062-8.717 6.514-16.098 15.115-17.741l2.218-.424a124.322 124.322 0 0 1 11.088-1.605h.002a124.385 124.385 0 0 1 35.629 1.605l21.452 4.098c11.597 2.227 19.996 12.546 19.996 24.567v23.907c-.001 2.762-2.25 5.001-5.025 5.001z"
		/>
		<path
			fill="#d78878"
			d="M266.183 249.681v-61.582c0-6.486 5.373-11.745 12-11.745s12 5.258 12 11.745v61.582z"
		/>
		<path fill="#c96e59" d="M266.683 163.446v107.659h-82V163.446z" />
		<path
			fill="#ffbfab"
			d="m237.615 129.139-.047.016v12.106c0 6.607-5.169 12.275-11.775 12.396-6.73.123-12.225-5.296-12.225-11.998v-12.504c-14.438-4.976-24.811-18.681-24.811-34.81V82.811c0-20.33 16.481-36.811 36.811-36.811 4.467 0 8.748.796 12.709 2.253 1.597.588 1.781 2.756.355 3.684-10.088 6.565-16.758 17.94-16.758 30.873v11.534c0 12.723 6.455 23.939 16.27 30.55 1.619 1.092 1.317 3.606-.529 4.245z"
		/>
		<path
			fill="#ffcebf"
			d="M262.609 82.027v13.101c0 15.637-10.814 29.044-25.04 34.027-14.226-4.983-24-18.39-24-34.027V82.027c0-15.637 10.179-28.902 24.405-33.885 14.226 4.984 24.635 18.248 24.635 33.885z"
		/>
		<path
			fill="#00c53b"
			d="M505.34 311.257C496.019 328.379 477.867 340 457.001 340 426.708 340 402 315.292 402 284.999c0-20.851 11.604-38.993 28.706-48.319.815-.444 1.764.295 1.553 1.199a60.048 60.048 0 0 0-1.556 14.64c.523 32.087 26.69 58.254 58.777 58.777a60.073 60.073 0 0 0 14.681-1.565c.888-.208 1.615.725 1.179 1.526zm-192 0C304.019 328.379 285.867 340 265.001 340 234.708 340 210 315.292 210 284.999c0-20.851 11.604-38.993 28.706-48.319.815-.444 1.764.295 1.553 1.199a60.048 60.048 0 0 0-1.556 14.64c.523 32.087 26.69 58.254 58.777 58.777a60.073 60.073 0 0 0 14.681-1.565c.888-.208 1.615.725 1.179 1.526z"
		/>
		<path
			fill="#4bcd5c"
			d="M512 285c0 9.656-2.489 18.731-6.859 26.619-7.888 4.371-16.963 6.859-26.619 6.859-30.376 0-55-24.624-55-55 0-9.656 2.489-18.731 6.859-26.619C438.269 232.489 447.344 230 457 230c30.376 0 55 24.624 55 55zm-192 0c0 9.656-2.489 18.731-6.859 26.619-7.888 4.371-16.963 6.859-26.619 6.859-30.376 0-55-24.624-55-55 0-9.656 2.489-18.731 6.859-26.619C246.269 232.489 255.344 230 265 230c30.376 0 55 24.624 55 55z"
		/>
		<path
			fill="#ff4343"
			d="M153.34 311.257C144.019 328.379 125.867 340 105.001 340 74.708 340 50 315.292 50 284.999c0-20.851 11.604-38.993 28.706-48.319.815-.444 1.764.295 1.553 1.199a60.048 60.048 0 0 0-1.556 14.64c.523 32.087 26.69 58.254 58.777 58.777a60.073 60.073 0 0 0 14.681-1.565c.888-.208 1.615.725 1.179 1.526z"
		/>
		<path
			fill="#ff5757"
			d="M160 285c0 9.656-2.489 18.731-6.859 26.619-7.888 4.371-16.963 6.859-26.619 6.859-30.376 0-55-24.624-55-55 0-9.656 2.489-18.731 6.859-26.619C86.269 232.489 95.344 230 105 230c30.376 0 55 24.624 55 55z"
		/>
		<path
			fill="#fff"
			d="m490.706 276.969-35.205 35.205c-1.953 1.952-4.512 2.929-7.071 2.929s-5.118-.977-7.071-2.929l-19.142-19.142c-3.905-3.905-3.905-10.237 0-14.143 3.906-3.905 10.236-3.905 14.143 0l12.07 12.071 28.134-28.134c3.906-3.904 10.236-3.904 14.143 0 3.904 3.905 3.904 10.237-.001 14.143zm-206.143-14.143L256.43 290.96l-12.071-12.071c-3.905-3.905-10.237-3.905-14.142 0s-3.905 10.237 0 14.142l19.142 19.142a9.968 9.968 0 0 0 7.071 2.929 9.97 9.97 0 0 0 7.071-2.929l35.205-35.205c3.905-3.905 3.905-10.237 0-14.143-3.906-3.903-10.236-3.903-14.143.001zm-152.002-5.387c-3.905-3.905-10.237-3.905-14.143 0L105 270.857l-13.418-13.418c-3.905-3.905-10.237-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.143L90.857 285l-13.418 13.418c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929L105 299.143l13.418 13.418c1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143L119.143 285l13.418-13.418c3.904-3.905 3.904-10.237 0-14.143z"
		/>
	</svg>
)


AbsenceIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string
}

AbsenceIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem'
}

export default AbsenceIcon