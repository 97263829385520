import React from 'react'
import {Grid} from '@material-ui/core'
import GestionRessourceProvider from '../GestionRessourceProvider'
import DialogProvider from '../../../../components/layout/contextProvider/DialogProvider'
import {getRessourceById, updateRessource} from '../gestionRessourceApi'
import {daysOfWeek} from '../../../../utils/constants'
import {useSnackbar} from '../../../../components/layout/snackbar/SnackbarContext'
import HeaderDetailRessource from './detailRessource/HeaderDetailRessource'
import FormRessource from './FormRessource'
import {formatTime, getRessourceForInitValues} from '../utils/ressourceUtils'

const DetailRessourceOnglet = ({ match }) => {
	const { snackSuccess, snackError } = useSnackbar()
	const [ressource, setRessource] = React.useState({});
	const [refresh, setRefesh] = React.useState()

	React.useEffect(() => {
		getRessourceById(match.params.id)
			.then(setRessource)
	}, [refresh])

	const onSubmit = (values) => {
		const { competences, ...otherValues } = values;

		daysOfWeek.forEach(day => {
			delete otherValues[`${day}Debut`];
		});

		updateRessource({
			competences : values.competences && Object.keys(values.competences)
				.filter(key => values.competences[key])
				.map(id => id),
			horaire: daysOfWeek.map(day => ({
				day,
				debut: values[`${day}Debut`] != null ? formatTime(values[`${day}Debut`]) : null,
				temps: values[`${day}Temps`]
			})),
			...otherValues
		})
			.then(() => {
				setRefesh(!refresh)
				snackSuccess({ id: 'gestionRessource.detailRessource.snackbar.success' })
			})
			.catch(() => {
				snackError({ id: 'gestionRessource.detailRessource.snackbar.error' })
			})
	}

	return (
		<DialogProvider textButton>
			<GestionRessourceProvider>
				<Grid style={{ padding: 50 }}>
					<HeaderDetailRessource />
					<FormRessource
						onSubmit={onSubmit}
						initialValues={getRessourceForInitValues(ressource)}
					/>
				</Grid>
			</GestionRessourceProvider>
		</DialogProvider>
	)

}

export default DetailRessourceOnglet