import { Divider, Grid, Typography, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { CONFIRM_HARVEST_FIELD, PANEL_FORM } from '../../tool/tourPlaine.constants'
import Detail from '../../../planning/component/detail/Detail'
import PlantOneIcon from '../../../../../components/icon/PlantOneIcon'
import { connect } from 'react-redux'
import { ArrowRight } from '@material-ui/icons'
import { colors } from '../../../../../utils/constants'
import Checkbox from '../../../../../components/form/Checkbox'
import { LocalDate } from '../../../../../utils/date/local-date'
import { DateFormat } from '../../../../../utils/date/model/date'

/**
 * Styles
 */
const styles = () => getStyles({
	form: {
		width: '100%',
		padding: 25
	},
	radioText: {
		marginBottom: 10,
		marginLeft: 10
	},
	radio: {
		width: '100%',
		'& > div': {
			'& > label': {
				width: 170
			}
		}
	},
	details: {
		width: 'initial !important'
	},
	ulContainer: {
		marginLeft: '-20px',
		marginBottom: 0
	},
	divider: {
		borderBottom: '1px #D6D6D6',
		width: '100%'
	},
	title: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: '1em',
		cursor: 'pointer'
	},
	toggleContainer: {
		padding: '0px 25px'
	},
	toggle: {
		color: colors.primary,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 6,
		marginTop: 12,
		marginLeft: '-8px'
	},
	iconRotate: {
		fontSize: '2rem !important',
		transform: 'rotate(90deg)',
		transition: 'ease-in-out 0.1s',
		cursor: 'pointer'
	},
	icon: {
		transform: 'rotate(0)'
	},
	detail: {
		marginTop: 6,
		marginLeft: '-12px'
	},
	checkboxLabel: {
		fontSize: 12,
		lineHeight: '15px',
		fontWeight: '400',
		color: colors.text,
		marginLeft: 15
	}
})

/**
 * MoveTaskForm
 * @param handleSubmit
 * @param classes
 * @param inputLabel
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmHarvestForm = ({ handleSubmit, classes }) => {
	const [open, setOpen] = useState(true)

	const handleClick = () => setOpen(previousState => !previousState)
	const renderFieldArray = ({ fields }) => {
		return (
			<Detail
				sections={
					fields.map((fieldName, index, fields) => {
						// tasksList est un tableau avec 2 élements :
						// 		le premier est la tâche sélectionnée par l'utilisateur,
						// 		le deuxième est un tableau avec les autres tâches de récolte
							const tasksList = fields.get(index).tasksList
							const firstTask = tasksList[0] // firstTask est la tâche sélectionnée depuis le tableau
							const nextTasks = tasksList[1] // nextTasks sont les prochaines tâches de récolte de l'assolement sélectionné
							return ({
								icon: <PlantOneIcon width={25} height={25} />,
								title: <FormattedMessage
									id="tourPlaine.form.confirmHarvest.sectionTitle"
									values={{
										cropName: firstTask.crop.name,
										board: firstTask.board.name
									}} />,
								content: <Grid key={index} container className={classes.fieldContainer}>
									{/* affichage de la date de la tâche sélectionnée depuis le tableau */}
									<ul className={classes.ulContainer}>
										<li key={firstTask.id}>
											<FormattedMessage
												id="tourPlaine.form.confirmHarvest.sectionDate"
												values={{
													date: LocalDate.fromAPI(firstTask.plannedDate).format(DateFormat.SHORT_DATE_2_DIGITS)
												}} />
										</li>
									</ul>
									{/* affichage des dates des autres tâches de récolte pour la culture assolée */}
									<div className={classes.toggleContainer}>
										<div onClick={handleClick} className={classes.toggle}>
											<ArrowRight className={`${classes.iconRotate} ${open ? '' : classes.icon}`} />
											<Typography variant="body1" className={classes.title}><FormattedMessage id="tourPlaine.form.confirmHarvest.otherTask" /></Typography>
										</div>
										{open && <div>
											<ul className={classes.detail}>
												{nextTasks.map(tache => (
													<li key={tache.id}>
														<FormattedMessage
															id="tourPlaine.form.confirmHarvest.sectionDate"
															values={{
																date: LocalDate.fromAPI(tache.dateRecolte).format(DateFormat.SHORT_DATE_2_DIGITS)
															}} />
														{tache.confirmed &&
															<FormattedMessage
																id="tourPlaine.form.confirmHarvest.taskAlreadyConfirmed" />
														}
													</li>
												))}
											</ul>
											<Field
												name={`${fieldName}${CONFIRM_HARVEST_FIELD.CHECKED}`}
												component={Checkbox}
												label={
													<Typography className={classes.checkboxLabel}>
														<FormattedMessage id="tourPlaine.form.confirmHarvest.confirmTask" />
													</Typography>
												}
											/>
										</div>
										}
									</div>
									<Divider className={classes.divider} style={{ marginTop: 10 }} />
								</Grid>
							})
						}
					)
				}
				containerClassName={classes.details}
			/>
		)
	}

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<FieldArray
				name={CONFIRM_HARVEST_FIELD.TASKS}
				component={renderFieldArray}
			/>
		</form>
	)

}

ConfirmHarvestForm.propType = {
	items: PropTypes.object,
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state, { items, ...props }) => {
	const groupedTaskByAssolement = items.reduce((acc, task) => {
		// on récupère les prochaines tâches de récolte pour la tâche sélectionnée
		// qu'on va ajouter à la valeur de return de groupedTaskByAssolement
		const tachesRecolteNextForAssolement = task.assolement.tachesRecoltes.filter(taskRecolte => task.id !== taskRecolte.id)
		const accObj = acc[task.assolement.id]
		if (accObj) {
			return {
				...acc,
				[task.assolement.id]: {
					tasksList: [...accObj.tasksList, task, tachesRecolteNextForAssolement],
					[CONFIRM_HARVEST_FIELD.CHECKED]: false
				}
			}
		} else {
			return {
				...acc,
				[task.assolement.id]: {
					tasksList: [task, tachesRecolteNextForAssolement],
					[CONFIRM_HARVEST_FIELD.CHECKED]: false
				}
			}
		}
	}, {})

	const arrayOfGroupedTaskByAssolement = Object.keys(groupedTaskByAssolement).map((key, index) => groupedTaskByAssolement[key])
	return ({
		initialValues: {
			[CONFIRM_HARVEST_FIELD.TASKS]: arrayOfGroupedTaskByAssolement
		},
		items,
		...props
	})
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: PANEL_FORM.CONFIRM_HARVEST,
		enableReinitialize: true
	}),
	withStyles(styles)
)
(ConfirmHarvestForm)
