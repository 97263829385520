import React from 'react'
import PropTypes from 'prop-types'

const SproutIcon = ({ height, width}) => (
	<svg
		width={width}
		height={height}
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
	>
		<path
			fill="#9bd069"
			d="M10.757 21.718C8.997 10.655 17.341.553 28.537.192 67.138-1.051 152.452 2.42 211.08 50.846c22.415 18.515 32.184 39.941 35.742 59.242 10.191 55.289-30.682 104.795-86.919 105.23-19.626.152-42.511-5.394-64.927-23.909-58.627-48.427-78.149-131.55-84.219-169.691z"
		/>
		<path
			fill="#7dbe3b"
			d="M246.822 110.088c-3.558-19.301-13.327-40.727-35.742-59.242C152.452 2.42 67.138-1.051 28.538.192 17.341.553 8.997 10.656 10.757 21.718c.442 2.778.955 5.794 1.55 9.018 42.734.268 115.931 7.793 168.045 50.838 22.415 18.515 32.184 39.941 35.742 59.242 4.174 22.644-.218 44.318-10.722 62.048 30.683-18.18 48.587-54.06 41.45-92.776z"
		/>
		<path
			fill="#9bd069"
			d="M501.019 85.096c2.229-8.952-3.902-17.833-13.063-18.923-31.584-3.757-101.831-6.957-153.369 28.621-19.704 13.602-29.239 30.49-33.526 46.075-12.282 44.643 17.746 88.16 63.858 92.503 16.092 1.516 35.263-1.412 54.968-15.015 51.537-35.578 73.448-102.397 81.132-133.261z"
		/>
		<path
			fill="#7dbe3b"
			d="M487.956 66.173a315.428 315.428 0 0 0-19.214-1.682c-9.447 33.491-31.813 91.684-78.683 124.039-19.704 13.602-38.876 16.531-54.968 15.015-10.349-.975-19.887-3.923-28.319-8.414 11.022 20.718 31.998 35.778 58.146 38.241 16.092 1.516 35.263-1.412 54.968-15.015 51.537-35.578 73.448-102.397 81.133-133.261 2.228-8.953-3.902-17.833-13.063-18.923z"
		/>
		<path
			fill="#7db723"
			d="M412.016 127.2a7.725 7.725 0 0 0-10.259-3.763c-35.142 16.278-65.734 38.537-90.928 66.157-21.729 23.822-37.838 51.822-47.629 82.106-9.044-50.212-31.847-96.883-66.425-134.793-28.467-31.208-78.819-63.553-80.949-64.914a7.726 7.726 0 0 0-10.671 2.348 7.727 7.727 0 0 0 2.347 10.672c.504.322 50.751 32.593 77.856 62.309 43.109 47.261 66.827 108.985 66.787 173.797l-.072 55.403a7.725 7.725 0 0 0 7.716 7.736h.01a7.726 7.726 0 0 0 7.726-7.716l.044-33.727c.015-.195.03-.39.029-.589-.033-53.039 19.374-103.547 54.647-142.218 23.802-26.094 52.738-47.139 86.006-62.549a7.727 7.727 0 0 0 3.765-10.259z"
		/>
		<path
			fill="#69d8e4"
			d="M50.206 363.457c-1.386-5.221 2.82-10.249 8.203-9.8 24.332 2.027 28.221 10.651 58.833 10.651 34.686 0 34.686-11.073 69.373-11.073 34.688 0 34.688 11.073 69.376 11.073 34.689 0 34.689-11.073 69.378-11.073 34.691 0 34.691 11.073 69.381 11.073 30.615 0 34.504-8.624 58.839-10.651 5.383-.448 9.589 4.58 8.203 9.8l-27.371 103.088C427.307 493.343 403.05 512 375.323 512H136.677c-27.727 0-51.984-18.657-59.099-45.455z"
		/>
		<path
			fill="#29d0df"
			d="m416.694 362.322-27.673 104.223C381.906 493.343 357.649 512 329.922 512h45.401c27.727 0 51.984-18.657 59.099-45.455l27.371-103.088c1.388-5.227-2.829-10.249-8.218-9.799-16.88 1.408-23.92 5.991-36.881 8.664z"
		/>
		<path
			fill="#a5e4ef"
			d="M394.751 364.307c-34.691 0-34.691-11.073-69.381-11.073-34.689 0-34.689 11.073-69.378 11.073-34.688 0-34.688-11.073-69.376-11.073-34.687 0-34.687 11.073-69.373 11.073-30.612 0-34.501-8.624-58.833-10.651-5.383-.448-9.589 4.58-8.203 9.8l11.129 41.916c21.427 2.504 26.094 10.359 55.366 10.359 34.822 0 34.822-11.117 69.643-11.117 34.823 0 34.823 11.117 69.647 11.117s34.824-11.117 69.648-11.117c34.826 0 34.826 11.117 69.652 11.117 29.276 0 33.941-7.856 55.373-10.359l11.129-41.916c1.386-5.221-2.82-10.249-8.203-9.8-24.336 2.027-28.225 10.651-58.84 10.651z"
		/>
		<path
			fill="#69d8e4"
			d="M453.575 353.658c-16.88 1.408-23.92 5.991-36.881 8.664l-14.134 53.232c22.971-1.18 28.582-7.901 48.104-10.181l11.129-41.916c1.388-5.227-2.829-10.249-8.218-9.799z"
		/>
	</svg>
)


SproutIcon.propType = {
	height: PropTypes.string,
	width: PropTypes.string,
	color: PropTypes.string
}

SproutIcon.defaultProps = {
	height: '1.5rem',
	width: '1.5rem',
	color: '#b7ceeb'
}

export default SproutIcon